import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import '../../../User/Booking/Booking.css';

const Appointment = ({ sched }) => {
  let start = sched.start.toString();
  let a = Date.parse(start);
  let b = moment(a).format('ddd MMM D, h:mm a');
  return (
    <Button variant="outline-info mt-2" id="appointment" disabled>
      {b}
    </Button>
  );
};

export default Appointment;
