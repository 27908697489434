import React from 'react';
import { Button, Col } from 'react-bootstrap';

const AdminHeader = () => {
  return (
    <Col>
      <Button variant="outlined" className="float-right">
        <i className="fa-solid fa-arrow-right-from-bracket"></i>
      </Button>
      <Button variant="outlined" className="float-right">
        <i className="fa-regular fa-bell"></i>
      </Button>
    </Col>
  );
};

export default AdminHeader;
