import { useState, useEffect } from 'react';
import { Button, Col, Row, Table, Form } from 'react-bootstrap';
import Floor from './Floor';

const OfficeInfo = ({ setStage }) => {
  const [officeType, setOfficeType] = useState('');
  const [stairs, setStairWays] = useState();
  const [sanitizer, setSanitizer] = useState(true);
  //office floors//
  const [floors, setFloors] = useState([
    {
      Floor: 'Ground',
      id: 0,
      hardFloor: 0,
      softFloor: 0,
      toilets: 0,
      corridors: 0,
      total: 0,
    },
  ]);
  //   officeFloors(floors);

  //ladies toilets//
  const [ladiesData, setLadiesData] = useState({
    ladies: 0,
    ladyToilet: 0,
    ladybasin: 0,
    ladyPara: 0,
  });
  const { ladies, ladyToilet, ladybasin, ladyPara } = ladiesData;
  const onLadiesChange = (e) =>
    setLadiesData({ ...ladiesData, [e.target.name]: parseInt(e.target.value) });
  useEffect(() => {
    if (ladiesData) {
      // ladiesToilets(ladiesData);
    }
  }, [ladiesData]);

  //Gents toilets//
  const [gentsData, setGentsData] = useState({
    gents: 0,
    gentToilet: 0,
    gentbasin: 0,
    urinals: 0,
    gentPara: 0,
  });
  const { gents, gentToilet, gentbasin, urinals, gentPara } = gentsData;
  useEffect(() => {});
  //gentsToilets(gentsData);
  const onGentsChange = (e) =>
    setGentsData({ ...gentsData, [e.target.name]: parseInt(e.target.value) });
  useEffect(() => {
    if (gentsData) {
      // gentsToilets(gentsData);
    }
  }, [gentsData]);

  //Add Floor//
  const addFloor = () => {
    let cid = floors.length;
    let id = cid + 1 - 1;
    let newArr = floors.concat({
      id: id,
      Floor: id,
      hardFloor: 0,
      softFloor: 0,
      toilets: 0,
      corridors: 0,
      total: 0,
    });
    setFloors(newArr);
  };

  //Remove Floor//
  const removeFloor = (id) => {
    const arr = floors.filter((floor) => {
      return floor.id !== id;
    });
    setFloors(arr);
    // officeFloors(arr);
  };

  return (
    <div>
      <h2 className="text-info">Tell us about your office...</h2>
      <h5 className="text-center mb-3">What type of Factory do you have?</h5>
      <Row className="d-flex justify-content-around">
        <Col>
          <Button
            variant={officeType === 'Office Space' ? 'info' : 'outline-info'}
            onClick={() => setOfficeType('Office Space')}
            block
          >
            Office Space
          </Button>
        </Col>
        <Col>
          <Button
            variant={officeType === 'Warehouse' ? 'info' : 'outline-info'}
            onClick={() => setOfficeType('Warehouse')}
            block
          >
            Warehouse
          </Button>
        </Col>
        <Col>
          <Button
            variant={officeType === 'Factory' ? 'info' : 'outline-info'}
            onClick={() => setOfficeType('Factory')}
            block
          >
            Factory
          </Button>
        </Col>
      </Row>
      <hr />
      <Form>
        <b className="text-center mb-5">
          Please enter the square meters of the following;
        </b>
        <hr />
        <Table bordered>
          <thead>
            <th>Floor</th>
            <th>Hard floor</th>
            <th>Soft floor</th>
            <th>Toilets</th>
            <th>Corridors</th>
            <th>
              <i onClick={addFloor} className="fa-solid fa-plus"></i>
            </th>
          </thead>
          <tbody>
            {floors.map((floor) => (
              <Floor key={floor.id} floor={floor} removeFloor={removeFloor} />
            ))}
          </tbody>
        </Table>
        <hr />
        <Row className="mb-3">
          <Col md={8}>
            <b>How many stairways do you have?</b>
          </Col>
          <Col md={4}>
            <Form.Control
              type="number"
              placeholder="Stairways"
              name="stairways"
              className="w-50"
              onChange={(e) => setStairWays(parseInt(e.target.value))}
            />
          </Col>
        </Row>
        <hr />
        <b className="mt-4">Please enter info about your toilets;</b>
        <Table bordered>
          <thead>
            <th>Toilets</th>
            <th>
              Ladies
              <i className="fa-solid fa-child-dress float-right mt-1"></i>
            </th>
            <th>Toilets</th>
            <th>Wash basin</th>
            <th>Paraplegic</th>
          </thead>
          <tbody>
            <tr>
              <td>Total count</td>
              <td>
                <Form.Control
                  type="number"
                  name="ladies"
                  value={ladies}
                  onChange={(e) => onLadiesChange(e)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="ladyToilet"
                  value={ladyToilet}
                  onChange={(e) => onLadiesChange(e)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="ladybasin"
                  value={ladybasin}
                  onChange={(e) => onLadiesChange(e)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="ladyPara"
                  value={ladyPara}
                  onChange={(e) => onLadiesChange(e)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table className="mt-4" bordered>
          <thead>
            <th>Toilets</th>
            <th>
              Gents <i className="fa-solid fa-child float-right mt-1"></i>
            </th>
            <th>Toilets</th>
            <th>Wash basin</th>
            <th>Urinals</th>
            <th>Paraplegic</th>
          </thead>
          <tbody>
            <tr>
              <td>Total count</td>
              <td>
                <Form.Control
                  type="number"
                  name="gents"
                  value={gents}
                  onChange={(e) => onGentsChange(e)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="gentToilet"
                  value={gentToilet}
                  onChange={(e) => onGentsChange(e)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="gentbasin"
                  value={gentbasin}
                  onChange={(e) => onGentsChange(e)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="urinals"
                  value={urinals}
                  onChange={(e) => onGentsChange(e)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="gentPara"
                  value={gentPara}
                  onChange={(e) => onGentsChange(e)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <hr />
        <Row>
          <Col md={8}>
            <b>Would you like auto sanitizer in all flushing units?</b>
          </Col>
          <Col md={2}>
            <Button
              variant={sanitizer ? 'info' : 'outline-info'}
              onClick={() => setSanitizer(!sanitizer)}
            >
              Y
            </Button>
          </Col>
          <Col md={2}>
            <Button
              variant={sanitizer ? 'outline-info' : 'info'}
              onClick={() => setSanitizer(!sanitizer)}
            >
              N
            </Button>
          </Col>
        </Row>
        <hr />
        <Button variant="outline-info mt-3" onClick={() => setStage(2)} block>
          Next <i className="fa-solid fa-angle-right ml-5"></i>
        </Button>
      </Form>
    </div>
  );
};

export default OfficeInfo;
