import {
  AVAILABLE_WORK,
  MY_SCHEDULE,
  CLEAR_WORK,
  MY_BOOKING,
  MY_RE_BOOKING,
  CLEAR_BOOKING,
  CLEAR_REBOOKING,
  SET_DISTANCE,
  MY_WORK,
  MY_RE_WORK,
} from '../../actions/types';

const initState = {
  work: null,
  schedule: null,
  loadingWork: false,
  booking: null,
  reBooking: null,
  distance: null,
  my_work: null,
  my_rework: null,
};

function teamWorkReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case MY_WORK:
      return {
        ...state,
        my_work: payload,
      };
    case MY_RE_WORK:
      return {
        ...state,
        my_rework: payload,
      };
    case AVAILABLE_WORK:
      return {
        ...state,
        work: payload,
      };

    case MY_SCHEDULE:
      return {
        ...state,
        schedule: payload,
      };
    case MY_BOOKING:
      return {
        ...state,
        booking: payload,
      };
    case MY_RE_BOOKING:
      return {
        ...state,
        reBooking: payload,
      };
    case CLEAR_WORK:
      return {
        ...state,
        work: null,
      };
    case CLEAR_BOOKING:
      return {
        ...state,
        booking: null,
        reBooking: null,
      };
    case CLEAR_REBOOKING:
      return {
        ...state,
        booking: null,
        reBooking: null,
      };
    case SET_DISTANCE:
      return {
        ...state,
        distance: payload,
      };
    default:
      return state;
  }
}

export default teamWorkReducer;
