import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Spinner, Table } from 'react-bootstrap';
import { getDuster, dusterRepeat } from '../../../actions/admin/team_members';
import { useParams, useNavigate } from 'react-router-dom';
import ReCleanItem from './ReCleanItem';

const DusterReOrders = ({
  getDuster,
  dusterRepeat,

  duster: { dustBuster, reorders },
}) => {
  const { dusterId } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    getDuster(dusterId);
    dusterRepeat(dusterId);
  }, [dusterId, getDuster, dusterRepeat]);

  if (dustBuster === null) {
    <Container>
      <Spinner variant="grow" animation="grow" />
    </Container>;
  }
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h5>Monthly bookings</h5>
      <hr />
      <Table>
        <thead className="thead-dark">
          <tr>
            <th>Client Name</th>
            <th>Address</th>
            <th>Booking Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {reorders.length === 0 ? (
            <tr>
              <td>No recent reorders</td>
            </tr>
          ) : (
            reorders.map((o) => <ReCleanItem key={o._id} o={o} />)
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  duster: state.team_members,
});

export default connect(mapStateToProps, { getDuster, dusterRepeat })(
  DusterReOrders
);
