import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

const Schedule = ({
  onceAWeek,
  once,
  twiceAWeek,
  twice,
  threeAWeek,
  three,
  fullTime,
  full,
}) => {
  return (
    <div>
      <Row>
        <Col md={3} xs={12}>
          <Card.Header>Once A Week</Card.Header>
          <Button
            variant={onceAWeek ? 'info' : 'outline-info'}
            onClick={once}
            block
          >
            <span style={{ fontSize: '2em' }}>
              <i className="fas fa-align-center"></i>
            </span>
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Card.Header>Twice A Week</Card.Header>
          <Button
            variant={twiceAWeek ? 'info' : 'outline-info'}
            onClick={twice}
            block
          >
            <span style={{ fontSize: '2em' }}>
              <i className="fas fa-align-center"></i>
            </span>
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Card.Header>3 times a Week</Card.Header>
          <Button
            variant={threeAWeek ? 'info' : 'outline-info'}
            onClick={three}
            block
          >
            <span style={{ fontSize: '2em' }}>
              <i className="fas fa-align-center"></i>
            </span>
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Card.Header>Full Time</Card.Header>
          <Button
            variant={fullTime ? 'info' : 'outline-info'}
            onClick={full}
            block
          >
            <span style={{ fontSize: '2em' }}>
              <i className="fas fa-align-center"></i>
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Schedule;
