import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { createAReQuery } from '../../../actions/user/query';

function CreateReQuery({ createAReQuery, reOrderId, note }) {
  const [show, setShow] = useState(false);
  const [headline, setHeadline] = useState('');
  const [desc, setDesc] = useState('');

  useEffect(() => {
    if (note.length > 0 && btn.current) {
      setHeadline('');
      setDesc('');
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();

  const create = () => {
    createAReQuery({ headline, desc, reOrderId });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div className="text-center">
      <Button variant="secondary mt-2" onClick={handleShow}>
        Create a query
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create a query</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Query headline:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Query headline"
              name="headline"
              value={headline}
              onChange={(e) => setHeadline(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Query description:</Form.Label>
            <Form.Control
              as="textarea"
              name="desc"
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              placeholder="Query description"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={create} ref={btn}>
            Submit query
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { createAReQuery })(CreateReQuery);
