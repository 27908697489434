import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Spinner,
  Col,
  Card,
  Alert,
  ListGroup,
  Button,
  Modal,
  Table,
  Container,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  GetUser,
  GetUserAddress,
  deleteUser,
  fetchFiveO,
  fetchFiveR,
  pauseUser,
  updateClient,
  resetUserPassword,
  createUserAddress,
} from '../../../../actions/admin/admin_user';
import { useNavigate } from 'react-router-dom';
import { userRate } from '../../../../actions/admin/main';
import { setNote } from '../../../../actions/note';
import OrderItem from './OrderItem';
import ReOrderItem from './ReOrderItem';
import AddressItem from './AddressItem';
import Monthly from './Monthly';
import CustomRateCard from './CustomRateCard';
import EditRateCard from './EditRateCard';
import Coupon from './Coupon';
import EditUser from './EditUser';
import { useParams } from 'react-router-dom';
import ResetUserPassword from './ResetUserPassword';
import NewUserAddress from './NewUserAddress';
import ViewUserAddresses from './ViewUserAddresses';

const AdminUser = ({
  GetUser,
  GetUserAddress,
  admin_users: { user, recent_orders, recent_repeat_orders, address },
  deleteUser,
  pauseUser,
  note,
  userRate,
  setNote,
  updateClient,
  fetchFiveO,
  fetchFiveR,
  resetUserPassword,
  createUserAddress,
}) => {
  const [nte, setMsg] = useState(note);
  const [month, SetMonth] = useState(false);
  const [custom, SetCustom] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [lastName, setLastname] = useState(null);
  const [userAddress, setUserAddress] = useState('');
  const [userNumber, setUserNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [blocked, setBlockedUser] = useState(null);
  const [userAddresses, setUserAddresses] = useState([]);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { userId } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let blockBtn = useRef();

  useEffect(() => {
    if (custom === true) {
      userRate(userId);
    }
  }, [custom, userRate, userId]);

  useEffect(() => {
    if (note && blockBtn.current) {
      GetUser(userId);
      setMsg(note);
      blockBtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte, GetUser, userId]);

  useEffect(() => {
    GetUser(userId);
    fetchFiveO(userId);
    fetchFiveR(userId);
    GetUserAddress(userId);
  }, [GetUser, fetchFiveO, fetchFiveR, GetUserAddress, userId]);
  useEffect(() => {
    if (user && address) {
      setBlockedUser(user.blocked);
      setUserAddress(user.address);
      setUserNumber(user.cellphone);
      setUserAddresses(address);
      setEmail(user.email);
      setCurrentUser(user.name);
      setLastname(user.surname);
      if (user.monthlyAcc === true) {
        SetMonth(true);
      } else {
        SetMonth(false);
      }
      if (user.customRate === true) {
        SetCustom(true);
      } else {
        SetCustom(false);
      }
    }
  }, [user, address, setCurrentUser]);

  const trashUser = () => {
    deleteUser(userId);
  };

  const blockUser = () => {
    pauseUser({ userId });
    if (blockBtn.current) {
      blockBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (
    user === null ||
    recent_orders === null ||
    address === null ||
    recent_repeat_orders === null
  ) {
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  }

  const addr = userAddress.split(',');

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <Row>
        <Col xs={1}>
          <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
        </Col>
        <Col align="left">
          <h4>
            User profile: {currentUser} {lastName}
          </h4>
        </Col>
        <Col xs={4}>
          <Button
            size="sm"
            variant="outline-success mt-2 mr-2"
            onClick={() =>
              navigate(`/admin/users/create-order-for-user/${userId}`)
            }
          >
            Create order
          </Button>
          <EditUser
            user={user}
            setNote={setNote}
            updateClient={updateClient}
            note={note}
          />
          {blocked ? (
            <Button
              variant="outline-success mr-2 mt-2"
              onClick={blockUser}
              ref={blockBtn}
              size="sm"
            >
              Un-block <i className="fas fa-pause"></i>
            </Button>
          ) : (
            <Button
              variant="outline-warning mr-2 mt-2"
              onClick={blockUser}
              ref={blockBtn}
              size="sm"
            >
              Block <i className="fas fa-pause"></i>
            </Button>
          )}
          <Button variant="outline-danger mt-2 " onClick={handleShow} size="sm">
            Delete <i className="fas fa-trash"></i>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <i className="fas fa-envelope"></i>: {email}
        </Col>
        <Col>
          <i className="fa-solid fa-house"></i>: {addr[0]} {addr[1]} {addr[2]}
        </Col>
        <Col>
          {userNumber && (
            <>
              <i className="fa-solid fa-phone"></i>: {userNumber}
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={2}>
          <Card>
            <Card.Header>User Actions</Card.Header>
            <Card.Body>
              <Coupon currentUser={currentUser} userId={userId} />
              <Monthly
                currentUser={currentUser}
                month={month}
                userId={userId}
              />
              {custom === true ? (
                <EditRateCard
                  currentUser={currentUser}
                  month={month}
                  custom={custom}
                />
              ) : (
                <CustomRateCard
                  currentUser={currentUser}
                  month={month}
                  userId={userId}
                  custom={custom}
                />
              )}
              <ListGroup variant="flush">
                {userAddresses.length === 0 ? (
                  <ListGroup.Item>Only has 1 address</ListGroup.Item>
                ) : (
                  <ViewUserAddresses userAddresses={userAddresses} />
                )}
                <NewUserAddress
                  userId={userId}
                  createUserAddress={createUserAddress}
                  note={note}
                />
                <ResetUserPassword
                  resetUserPassword={resetUserPassword}
                  userId={userId}
                  note={note}
                />
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={10} align="left">
          <Row className="justify-content-around">
            <h5>Once time bookings</h5>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>Booking Date</th>
                  <th>Address</th>
                  <th>Payment Status</th>
                  <th>Cleaner assigned</th>
                  <th>Booking Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {recent_orders.length === 0 ? (
                  <tr>
                    <td>No recent orders</td>
                  </tr>
                ) : (
                  recent_orders.map((o) => <OrderItem key={o._id} o={o} />)
                )}
              </tbody>
            </Table>
          </Row>
          <Row className="justify-content-around">
            <h5>Monthly bookings</h5>
            <hr />
            <Table>
              <thead className="thead-dark">
                <tr>
                  <th>First checkin</th>
                  <th>Address</th>
                  <th>Payment Status</th>
                  <th>Cleaner assigned</th>
                  <th>Booking Status</th>
                  <th>Next checkin</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {recent_repeat_orders.length === 0 ? (
                  <tr>
                    <td>No recent orders</td>
                  </tr>
                ) : (
                  recent_repeat_orders.map((o) => (
                    <ReOrderItem key={o._id} o={o} />
                  ))
                )}
              </tbody>
            </Table>
          </Row>
        </Col>
        <Modal align="center" show={show} onHide={handleClose}>
          <Alert variant="danger">
            <h4 className="text-secondary">
              You are about to delete {currentUser}
            </h4>
          </Alert>
          <Modal.Body>
            <h5 className="text-danger">
              <u> You cannot undo this! </u>
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={trashUser}>
              Delete User
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin_users: state.admin_users,
  note: state.note,
});

export default connect(mapStateToProps, {
  GetUser,
  GetUserAddress,
  deleteUser,
  fetchFiveO,
  fetchFiveR,
  pauseUser,
  userRate,
  setNote,
  updateClient,
  resetUserPassword,
  createUserAddress,
})(AdminUser);
