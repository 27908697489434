import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

const ChairsModal = ({
  dining_chair_base_only,
  dining_chair_base_back_arms,
  plusChairBase,
  minusChairBase,
  plusChairsEverything,
  minusChairsEverything,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Button onClick={handleShow} block variant="outline-info">
        Chairs
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Dining Chairs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={9}>
              <b>Base Only : </b> {dining_chair_base_only}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusChairBase}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusChairBase}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>Base back and arms : </b> {dining_chair_base_back_arms}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusChairsEverything}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button
                variant="outline-info ml-2"
                onClick={plusChairsEverything}
              >
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChairsModal;
