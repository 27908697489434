import { connect } from 'react-redux';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import {
  ordersThisMonth,
  missedOrders,
  notBooked,
  todaysOrders,
  validateOrders,
  runningLate,
  inProgressNum,
} from '../../../actions/admin/orders';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import OrderItem from './OrderItem';

import '../Admin.css';

const OneTimeOrderDash = ({
  ordersThisMonth,
  admin_orders: {
    ordersMonth,
    not_booked,
    missed_orders,
    todays_orders,
    require_validation,
    running_late,
    in_progress_num,
  },
  runningLate,
  missedOrders,
  notBooked,
  todaysOrders,
  validateOrders,
  inProgressNum,
}) => {
  useEffect(() => {
    ordersThisMonth();
    missedOrders();
    notBooked();
    todaysOrders();
    runningLate();
    validateOrders();
    inProgressNum();
  }, [
    ordersThisMonth,
    missedOrders,
    notBooked,
    todaysOrders,
    validateOrders,
    runningLate,
    inProgressNum,
  ]);
  return (
    <Container>
      <Row>
        <Col>
          <h4 style={{ color: '#06a5dd' }}>Manage Once off bookings</h4>
        </Col>
        <Col>
          <Button
            as={Link}
            to="/admin/order-management/once-off/not-allocated"
            variant="outline-secondary mr-2"
            size="sm"
          >
            <span className="btn-label">
              {not_booked && `${not_booked.num}`}
            </span>{' '}
            Unassigned
          </Button>
          <Button
            as={Link}
            to="/admin/order-management/once-off/missed-orders"
            variant="outline-secondary mr-2"
            size="sm"
          >
            <span className="btn-label">
              {missed_orders && `${missed_orders.num}`}
            </span>{' '}
            Missed
          </Button>
          <Button
            as={Link}
            to="/admin/order-management/once-off/require-validation"
            variant="outline-secondary mr-2"
            size="sm"
          >
            <span className="btn-label">
              {require_validation && `${require_validation.num}`}
            </span>{' '}
            Validate
          </Button>
          <Button
            as={Link}
            to="/admin/order-management/once-off/active-orders"
            variant="outline-secondary "
            size="sm"
          >
            <span className="btn-labrl">
              {in_progress_num && `${in_progress_num}`}{' '}
            </span>
            Active
          </Button>
        </Col>
      </Row>

      <hr />
      <Row>
        <Row>
          <Col>
            <h5 style={{ fontWeight: 'bold', color: 'grey' }}>
              Todays bookings
            </h5>
          </Col>
          <Col>
            {todays_orders && `${todays_orders.num}: Booking/s for today`}
            <Button
              as={Link}
              to="/admin/order-management/once-off/todays-orders"
              variant="outline-secondary"
              size="sm"
              className="float-right"
            >
              View All
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Client Name</th>
              <th>Booking Date</th>
              <th>Address</th>
              <th>Payment Status</th>
              <th>Cleaner assigned</th>
              <th>Booking Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {todays_orders === null ? (
              <Spinner animation="border" variant="primary" />
            ) : todays_orders.orders.length === 0 ? (
              'No Orders this month'
            ) : (
              todays_orders.orders.map((o) => <OrderItem key={o._id} o={o} />)
            )}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Row>
          <Col>
            <h5 style={{ fontWeight: 'bold', color: 'grey' }}>Running Late</h5>
          </Col>
          <Col className="float-right">
            {running_late && `${running_late.num}: Duster/s running late`}
            <Button
              as={Link}
              to="/admin/order-management/once-off/running-late"
              variant="outline-secondary"
              size="sm"
              className="float-right"
            >
              View All
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Client Name</th>
              <th>Booking Date</th>
              <th>Address</th>
              <th>Payment Status</th>
              <th>Cleaner assigned</th>
              <th>Booking Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {running_late === null ? (
              <Spinner animation="border" variant="primary" />
            ) : running_late.orders.length === 0 ? (
              'No Orders this month'
            ) : (
              running_late.orders.map((o) => <OrderItem key={o._id} o={o} />)
            )}
          </tbody>
        </Table>
      </Row>

      <Row>
        <Row>
          <Col>
            <h5 style={{ fontWeight: 'bold', color: 'grey' }}>For the month</h5>
          </Col>

          <Col className="float-right">
            {ordersMonth && `${ordersMonth.num}: Order/s places this month`}
            <Button
              as={Link}
              to="/admin/order-management/once-off/this-month"
              variant="outline-secondary"
              size="sm"
              className="float-right"
            >
              View All
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Client Name</th>
              <th>Booking Date</th>
              <th>Address</th>
              <th>Payment Status</th>
              <th>Cleaner assigned</th>
              <th>Booking Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {ordersMonth === null ? (
              <Spinner animation="border" variant="primary" />
            ) : ordersMonth.orders.length === 0 ? (
              'No Orders this month'
            ) : (
              ordersMonth.orders.map((o) => <OrderItem key={o._id} o={o} />)
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_orders: state.admin_orders,
});

export default connect(mapStateToProps, {
  ordersThisMonth,
  missedOrders,
  notBooked,
  todaysOrders,
  validateOrders,
  runningLate,
  inProgressNum,
})(OneTimeOrderDash);
