import {
  ALL_QUERIES,
  ALL_QUERIES_NUM,
  ASSIGNED_QUERIES,
  UN_ASSIGNED_QUERIES,
  QUERIES_IM_ON,
  ORDER_QUERY,
  SETUP_QUERY_SOCKET,
  CLEAR_QUERIES,
  CLEAR_QUERY_SOCKET,
  QUERY_TEXTS,
  ORDER_QUERY_DUSTER,
  CLOSED_QUERIES,
  CLOSED_QUERIES_NUM,
} from '../../actions/types';

const initState = {
  queries: null,
  queries_num: 0,
  closed: null,
  closedNumber: null,
  query: null,
  unassigned: null,
  assigned: null,
  mine: null,
  socket: null,
  loading: true,
  texts: null,
  duster: null,
};

function queryReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ALL_QUERIES:
      return {
        ...state,
        queries: payload,
        loading: false,
      };
    case ALL_QUERIES_NUM:
      return {
        ...state,
        queries_num: payload,
      };
    case CLOSED_QUERIES_NUM:
      return {
        ...state,
        closedNumber: payload,
      };
    case CLOSED_QUERIES:
      return {
        ...state,
        closed: payload,
      };
    case ASSIGNED_QUERIES:
      return {
        ...state,
        assigned: payload,
      };
    case UN_ASSIGNED_QUERIES:
      return {
        ...state,
        unassigned: payload,
      };
    case QUERIES_IM_ON:
      return {
        ...state,
        mine: payload,
      };
    case ORDER_QUERY:
      return {
        ...state,
        query: payload,
      };
    case SETUP_QUERY_SOCKET:
      return {
        ...state,
        socket: payload,
      };
    case CLEAR_QUERY_SOCKET:
      return {
        ...state,
        socket: null,
      };
    case CLEAR_QUERIES:
      return {
        ...state,
        query: null,
        unassigned: null,
        assigned: null,
        mine: null,
      };
    case QUERY_TEXTS:
      return {
        ...state,
        texts: payload,
      };
    case ORDER_QUERY_DUSTER:
      return {
        ...state,
        duster: payload,
      };
    default:
      return state;
  }
}

export default queryReducer;
