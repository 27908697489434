import {
  TEAM_PROFILE_ERROR,
  CHECK_PROFILE,
  CLEAR_MEMBER,
  READY_TEAM,
  READY_TEAM_NUMBER,
  FETCH_SKILLS,
  CLEAR_SKILLS,
  DUSTER_ORDERS,
  STAGE_ONE,
  STAGE_ONE_NUMBER,
  STAGE_TWO,
  STAGE_TWO_NUMBER,
  STAGE_THREE,
  STAGE_THREE_NUMBER,
  DUST_BUSTER,
  CLEAR_DUSTERS,
  INTERVIEW,
  INTERVIEW_CALENDER,
  DUSTER_BOOKINGS,
  DUSTER_RE_ORDERS,
  THE_BOOKING,
  CLEAR_THE_BOOKING,
  ADMIN_CLEAR_FILE,
  DUSTER_RATE,
  DAILY_DUSTERS,
  BLACK_LISTED,
  BLACK_LIST_DATA,
  DAILY_DUSTERS_NUM,
  DUSTER_REVIEWS,
} from '../types';
import axios from 'axios';
import { setNote } from '../note';

//Create a cleaner rate card//
export const cleanerRate =
  ({ dusterId, rate }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ rate });

    try {
      const res = await axios.post(
        `/api/admin/team-members/duster-rate/${dusterId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getRate(dusterId));
    } catch (error) {}
  };

//Fetch cleaner rate//
export const getRate = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/team-members/get-duster-rate/${dusterId}`
    );
    dispatch({ type: DUSTER_RATE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Edit Duster Rate Card//
export const changeRate =
  ({ rate, dusterId, rateId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ rate });
    try {
      const res = await axios.put(
        `/api/admin/team-members/edit-duster-rate/${rateId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getRate(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////////////
//Get Cleaners in stage one//
/////////////////////////////
export const levelOne = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/stage-one');
    dispatch({ type: STAGE_ONE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////
//Get #Cleaners in stage one//
//////////////////////////////
export const levelOneNum = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/stage-one/number');
    dispatch({ type: STAGE_ONE_NUMBER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////////
//Delete all dusters at  stage one//
////////////////////////////////////
export const deleteLevelOne = () => async (dispatch) => {
  try {
    const res = await axios.delete('/api/admin/team-members/delete-stage-one');
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(levelOneNum());
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////////
//Get Cleaners in stage two//
/////////////////////////////
export const levelTwo = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/stage-two');
    dispatch({ type: STAGE_TWO, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////////
//Get Cleaners in stage two//
/////////////////////////////
export const levelTwoNum = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/stage-two/number');
    dispatch({ type: STAGE_TWO_NUMBER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////////
//Delete all dusters at  stage one//
////////////////////////////////////
export const deleteStageTwo = () => async (dispatch) => {
  try {
    const res = await axios.delete('/api/admin/team-members/delete-stage-two');
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(levelTwoNum());
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////////
//Get Cleaners in stage three//
///////////////////////////////
export const levelThree = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/stage-three');
    dispatch({ type: STAGE_THREE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////////
//Get Cleaners in stage three//
///////////////////////////////
export const levelThreeNum = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/stage-three/number');
    dispatch({ type: STAGE_THREE_NUMBER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////////
//Delete all dusters at  stage one//
////////////////////////////////////
export const deleteStageThree = () => async (dispatch) => {
  try {
    const res = await axios.delete(
      '/api/admin/team-members/delete-stage-three'
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(levelTwo());
  } catch (error) {
    console.error(error.message);
  }
};

//Blacklist the duster//
/////////////////////
export const blacklistTheDuster =
  ({ dusterId, reason }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify({ reason });
      const res = await axios.put(
        `/api/admin/team-members/blacklist-duster/${dusterId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getDuster(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get blacklist data//
export const getBlacklistData = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/team-members/get-duster-blacklist/${dusterId}`
    );

    dispatch({ type: BLACK_LIST_DATA, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//whitelist the duster//
export const whiteListTheDuster = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/admin/team-members/white-list/${dusterId}`
    );

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(getDuster(dusterId));
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////
//Team members ready to work//
//////////////////////////////
export const readyToWork = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/ready-for-work');
    dispatch({ type: READY_TEAM, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: TEAM_PROFILE_ERROR, payload: errors });
  }
};

//get blacklist//
export const getBlacklist = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/blacklist');
    dispatch({ type: BLACK_LISTED, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////
//Team members ready to work//
//////////////////////////////
export const readyToWorkNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/team-members/ready-for-work/number'
    );
    dispatch({ type: READY_TEAM_NUMBER, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: TEAM_PROFILE_ERROR, payload: errors });
  }
};

//Get daily Dusters//
/////////////////////
export const getDailyDusters = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/daily-dusters');
    dispatch({ type: DAILY_DUSTERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get daily num//
/////////////////
export const getDailyNum = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-members/daily-duster-number');
    dispatch({ type: DAILY_DUSTERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
//Fetch Dust Buster//
/////////////////////
export const getDuster = (dusterId) => async (dispatch) => {
  dispatch({ type: CLEAR_DUSTERS });
  dispatch({ type: ADMIN_CLEAR_FILE });
  try {
    const res = await axios.get(
      `/api/admin/team-members/dustbuster/${dusterId}`
    );

    dispatch({ type: DUST_BUSTER, payload: res.data });
  } catch (error) {
    console.error(error.mesage);
  }
};

///////////////////
//Whose interview//
///////////////////
export const whoInterview = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/team-members/dustbuster/${dusterId}`
    );

    dispatch({ type: DUST_BUSTER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Null Duster//
export const nullDuster = () => (dispatch) => {
  dispatch({ type: DUST_BUSTER, payload: null });
};

///////////////////////
//Meeting Appointment//
///////////////////////
export const hrApp = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/team-members/interview/${dusterId}`
    );
    dispatch({ type: INTERVIEW, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Interview Calendar//
export const intCalendar = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/team-admin/hr-calendar');
    dispatch({ type: INTERVIEW_CALENDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
///////////////////////////////
//Fetch a team members skills//
///////////////////////////////
export const fetchSkills = (teamId) => async (dispatch) => {
  dispatch({ type: CLEAR_SKILLS });
  try {
    const res = await axios.get(`/api/admin/team-members/skills/${teamId}`);
    dispatch({ type: FETCH_SKILLS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////////
//Fetch Team Members Profiles//
///////////////////////////////
export const getUploads = (teamId) => async (dispatch) => {
  dispatch({ type: CLEAR_MEMBER });
  try {
    const res = await axios.get(`/api/admin/team-members/profile/${teamId}`);
    dispatch({ type: CHECK_PROFILE, payload: res.data });
  } catch (error) {
    const errors = error.response.data;
    console.log(errors);
    dispatch({ type: CHECK_PROFILE, payload: errors });
  }
};

//////////////////////////////
//Fetch Dust Busters Orders///
//////////////////////////////
export const dusterOrders = (teamId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/team-members/orders/${teamId}`);
    dispatch({ type: DUSTER_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//Fetch Repeat Orders//
///////////////////////
export const dusterRepeat = (teamId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/team-members/re-orders/${teamId}`);
    dispatch({ type: DUSTER_RE_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////
//Duster Bookings//
//////////////////
export const dusterBookings = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/team-members/bookings/${dusterId}`);
    dispatch({ type: DUSTER_BOOKINGS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Duster Booking//
export const checkBooking = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_THE_BOOKING });
  try {
    const res = await axios.get(
      `/api/admin/team-members/check-booking/${orderId}`
    );
    dispatch({ type: THE_BOOKING, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Duster Rebooking//
export const checkRebooking = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_THE_BOOKING });
  try {
    const res = await axios.get(
      `/api/admin/team-members/check-rebooking/${orderId}`
    );
    dispatch({ type: THE_BOOKING, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Delete all old dusting down booking//
export const deleteOldBookings = () => async (dispatch) => {
  try {
    const res = await axios.delete(
      '/api/admin/team-members/delete-all-old-bookings'
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch cleaner rating//
export const fetchCleanerRating = (teamId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/team-members/star-rating/${teamId}`
    );
    return res.data.rating;
  } catch (error) {
    console.error(error.message);
  }
};

//Give dusters blacklist var//
export const blacklistVar = () => async (dispatch) => {
  try {
    const res = await axios.put('/api/admin/team-members/assign-blacklist-var');
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

//Get Duster Reviews//
export const dusterReviews = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/team-members/duster-reviews/${dusterId}`
    );
    dispatch({ type: DUSTER_REVIEWS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
