import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  ListGroup,
  Alert,
  Spinner,
  Modal,
  Button,
} from 'react-bootstrap';
import {
  locationReCheck,
  checkInRep,
  checkOutRep,
  getReBooking,
  repeatOMW,
} from '../../../actions/team/team_work';
import ExtraItem from './ExtraItem';
import Bottom from '../../Layout/Bottom';
import ReOrderComplete from './ReOrderComplete';
import QRRScanner from './QRRScanner';
import Schedule from './Schedule';
import ConfirmReOnsite from './ConfirmReOnsite';
import CompleteRegular from './CompleteRegular';
import moment from 'moment';
import OnMyWayRepeat from './OnMyWayRepeat';
import { useParams } from 'react-router-dom';

const MyReBooking = ({
  team_work: { reBooking, distance },
  locationReCheck,
  checkInRep,
  checkOutRep,
  getReBooking,

  dusterId,
  repeatOMW,
  note,
}) => {
  const [show, setShow] = useState(false);
  const [currentLat, setCurrentLat] = useState(null);
  const [currentLon, setCurrentLng] = useState(null);
  const [currentReOrder, setCurrentOrder] = useState(null);
  const [checkedIn, setCheckedIn] = useState([]);
  const [checkedOut, setCheckedOut] = useState([]);
  const [checkingLocation, setCheckingLocation] = useState(false);
  const [cleans, SetCleans] = useState('');
  const [completedCleans, setCompletedCleans] = useState(null);
  const [whatsLeft, SetWhatsLeft] = useState(null);
  const [weekdays, SetWeekDays] = useState([]);
  const [schedule, SetSchedule] = useState([]);
  const [nextDay, SetNextDay] = useState(undefined);
  const [request, setRequest] = useState(null);
  const [validDay, setValidDay] = useState(false);
  const [omw, setOMW] = useState(null);
  const handleClose = () => setShow(false);
  const { orderId } = useParams();

  useEffect(() => {
    getReBooking(orderId);
  }, [orderId, getReBooking]);
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    setCurrentLat(crd.latitude);
    setCurrentLng(crd.longitude);
    setCheckingLocation(false);
  }
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            //If granted then you can directly call your function here
            setCheckingLocation(true);
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === 'prompt') {
            //prompt user for permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
            setShow(true);
          } else if (result.state === 'denied') {
            //If denied then you have to show instructions to enable location
            setShow(true);
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert('Geo Location is not available');
    }
  }, [navigator]);

  useEffect(() => {
    if (checkedOut) {
      setCompletedCleans(checkedOut.length);
    }
  }, [checkedOut]);

  useEffect(() => {
    if (reBooking) {
      setCurrentOrder(reBooking._id);
      setCheckedIn(reBooking.checkIn);
      setCheckedOut(reBooking.checkOut);
      SetCleans(reBooking.cleans);
      SetWeekDays(reBooking.weekdays);
      SetSchedule(reBooking.schedule);
      setRequest(reBooking.officeRequest);
      setOMW(reBooking.onMyWay);
    }
  }, [reBooking]);

  useEffect(() => {
    if (nextDay) {
      let today = moment().format().split('T').shift();
      let nextClean = nextDay.start.split('T').shift();
      if (today === nextClean) {
        setValidDay(true);
      } else {
        setValidDay(false);
      }
    }
  }, [nextDay]);

  useEffect(() => {
    if (currentLat && currentLon && reBooking) {
      let orderId = reBooking._id;
      locationReCheck({ currentLat, currentLon, orderId });
      setShow(false);
    }
  }, [currentLat, currentLon, reBooking, locationReCheck]);

  useEffect(() => {
    let jam = schedule.sort((x, y) => x.start - y.start);
    let peanut = checkedOut.length;
    let nextDay = jam[peanut];
    SetNextDay(nextDay);
  }, [schedule, checkedOut.length, SetNextDay]);

  useEffect(() => {
    let whatsLeft = cleans - completedCleans;
    SetWhatsLeft(whatsLeft);
  }, [cleans, completedCleans]);

  if (
    checkingLocation === true ||
    reBooking === null ||
    nextDay === undefined
  ) {
    return <Spinner animation="border" variant="primary" />;
  }

  const initDate = moment(reBooking.firstDate).format('ddd MMM D, h:mm a');
  const location = reBooking.address.split(',');
  const nextClean = moment(nextDay.start).format('ddd MMM D');

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Alert variant="warning text-center">Cleaning Brief:</Alert>
            <ListGroup variant="flush">
              <ListGroup.Item variant="secondary text-center">
                <b>Initial Date & Time:</b>
                <hr style={{ width: '18rem' }} />
                {initDate} | {location[0]}, {location[1]}
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left">
                  {reBooking.bedroom} Bedroom/s and {reBooking.bathroom}{' '}
                  Bathroom/s
                </p>
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {reBooking.mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  reBooking.mrx.map((x) => {
                    return (
                      <div key={x.id} style={{ width: '20rem' }}>
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  The order has {cleans} cleans weekly on
                  {weekdays &&
                    weekdays.map((w) => (
                      <div className="float-right" key={w}>
                        {w}
                      </div>
                    ))}
                </b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                On these dates:
                <hr style={{ width: '10rem' }} />
                {schedule &&
                  schedule.map((s) => <Schedule key={s.start} s={s} />)}
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left">Total Time: </p>
                <p className="float-right">{reBooking.invoiceHours} hours</p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col align="center">
            <ListGroup style={{ width: '25rem', marginBottom: '10px' }}>
              <Alert variant="secondary">Repeat Clean Info</Alert>
              <ListGroup.Item>
                You have {whatsLeft} cleans left to complete the booking
              </ListGroup.Item>
              <ListGroup.Item>
                You completed {completedCleans} clean/s;
              </ListGroup.Item>
              {checkedOut.length > 0 &&
                checkedOut.map((c) => (
                  <ListGroup.Item variant="success" key={c} c={c}>
                    Completed Clean on {moment(c).format('ddd MMM D')}
                  </ListGroup.Item>
                ))}
              <ListGroup.Item>Next clean is on: {nextClean}</ListGroup.Item>
            </ListGroup>

            {checkedOut.length === schedule.length ? (
              <ReOrderComplete />
            ) : validDay === false ? (
              <>
                <h5>Make sure you arrive on time for the next clean</h5>
              </>
            ) : distance < 0.5 ? (
              checkedIn.length <= checkedOut.length ? (
                <>
                  <QRRScanner
                    currentReOrder={currentReOrder}
                    checkInRep={checkInRep}
                    checkOutRep={checkOutRep}
                    checkedIn={checkedIn}
                    checkedOut={checkedOut}
                  />
                  <h6 className="text-center mt-4">
                    If the QR Code is unavailable please request validation
                  </h6>
                  {request ? (
                    <Button variant="outline-info" disabled>
                      Validation Request submitted!
                    </Button>
                  ) : (
                    <ConfirmReOnsite
                      currentLat={currentLat}
                      currentLon={currentLon}
                      currentReOrder={currentReOrder}
                    />
                  )}
                </>
              ) : (
                <CompleteRegular
                  dusterId={dusterId}
                  currentReOrder={currentReOrder}
                  checkOutRep={checkOutRep}
                />
              )
            ) : (
              <>
                <h5 className="text-center">
                  When you get to booking location you will be able to check-in,
                  if you are on-site click button below for validation.
                </h5>
                {request ? (
                  <Button variant="outline-info" disabled>
                    Validation Request submitted!
                  </Button>
                ) : (
                  <>
                    {omw ? (
                      <Button varinat="info" size="sm">
                        Thank you for letting us know you are on your way!
                      </Button>
                    ) : (
                      <OnMyWayRepeat
                        repeatOMW={repeatOMW}
                        currentReOrder={currentReOrder}
                        note={note}
                      />
                    )}
                    <ConfirmReOnsite
                      currentLat={currentLat}
                      currentLon={currentLon}
                      currentReOrder={currentReOrder}
                    />
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>We need to know your location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please allow us access to your location before you can see this
            page!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" block>
              You can only see this page by giving us your device location
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  team_work: state.team_work,
  dusterId: state.team.team._id,
  note: state.note,
});
export default connect(mapStateToProps, {
  locationReCheck,
  checkInRep,
  checkOutRep,
  getReBooking,
  repeatOMW,
})(MyReBooking);
