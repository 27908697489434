import {
  FILE_UPLOAD_ERROR,
  ADMIN_ID_UPLOAD,
  ADMIN_WP_UPLOAD,
  ADMIN_BL_UPLOAD,
  ADMIN_CLEAR_FILE,
  ADMIN_POA_UPLOAD,
  ADMIN_PP_UPLOAD,
  ADMIN_EC_UPLOAD,
} from '../../actions/types';

const initialState = {
  id_uploaded: null,
  wp_uploaded: null,
  bl_uploaded: null,
  poa_uploaded: null,
  pp_uploaded: null,
  ec_uploaded: null,
  loading: false,
  error: null,
};

function adminUploadReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_ID_UPLOAD:
      return {
        ...state,
        id_uploaded: payload,
        loading: false,
      };
    case ADMIN_WP_UPLOAD:
      return {
        ...state,
        wp_uploaded: payload,
        loading: false,
      };
    case ADMIN_BL_UPLOAD:
      return {
        ...state,
        bl_uploaded: payload,
        loading: false,
      };
    case ADMIN_POA_UPLOAD:
      return {
        ...state,
        poa_uploaded: payload,
      };
    case ADMIN_PP_UPLOAD:
      return {
        ...state,
        pp_uploaded: payload,
      };
    case ADMIN_EC_UPLOAD:
      return {
        ...state,
        ec_uploaded: payload,
      };
    case FILE_UPLOAD_ERROR:
      return {
        ...state,
        error: payload,
      };
    case ADMIN_CLEAR_FILE:
      return {
        ...state,
        id_uploaded: null,
        wp_uploaded: null,
        bl_uploaded: null,
        poa_uploaded: null,
        ec_uploaded: null,
        pp_uploaded: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}

export default adminUploadReducer;
