import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ordersOnAccount } from '../../../../../actions/admin/main';
import ListOrder from './ListOrder';

const OrdersOnAccount = ({ ordersOnAccount, orders }) => {
  useEffect(() => {
    ordersOnAccount();
  }, [ordersOnAccount]);
  const navigate = useNavigate();

  if (orders == null)
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );

  const goBack = () => {
    navigate('/admin/admin-accounts');
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>
      <h4>Orders on Account</h4>
      <hr />
      <Row>
        <Table></Table>
      </Row>
      {orders.orders.length === 0
        ? 'No Orders'
        : orders.orders.map((order) => (
            <ListOrder key={order._id} order={order} />
          ))}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  orders: state.main.monthEndOrders,
});

export default connect(mapStateToProps, { ordersOnAccount })(OrdersOnAccount);
