import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { removeFromReOrder } from '../../../actions/admin/orders';
import { Alert, Button, ListGroup, Modal } from 'react-bootstrap';
import ReCheckOutCleaner from './ReCheckOutCleaner';
import ReCheckInCleaner from './ReCheckInCleaner';
import moment from 'moment';

const ReDuster = ({
  duster,
  removeFromReOrder,
  orderId,
  completedCleans,
  inProgress,
  checkOutCleaner,
  checkInCleaner,
  nextClean,
  nextCheckIn,
  checkOutDuster,
  checkInDuster,
  invoiceHours,
  note,
  omw,
}) => {
  const [show, setShow] = useState(false);
  const [next, setNext] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (nextClean) {
      const next = moment(nextClean.start).format('ddd MMM D, h:mm a');
      setNext(next);
    } else {
      setNext('Complete');
    }
  }, [nextClean]);

  const remove = () => {
    removeFromReOrder(orderId);
    setShow(false);
  };

  return (
    <div>
      <Alert variant="info">This order has a Duster</Alert>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <b>Duster Name & Surname:</b>
          <p className="float-right">
            {duster.name} {duster.surname}
          </p>
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Cellphone: </b> <p className="float-right">{duster.cellphone}</p>
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Email: </b>
          <p className="float-right">{duster.email}</p>
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Completed Cleans:</b>
          <p className="float-right">{completedCleans}</p>
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Next Clean:</b>
          <p className="float-right">
            {next ? `${next}` : 'Cleaning Complete'}
          </p>
        </ListGroup.Item>

        {inProgress === true && (
          <ListGroup.Item className="text-center">
            <Button variant="warning" size="sm" disabled>
              Cleaning In Progess...
            </Button>
          </ListGroup.Item>
        )}
        {next === 'Complete'
          ? ''
          : checkInCleaner === true && (
              <ListGroup.Item className="text-center">
                <ReCheckInCleaner
                  note={note}
                  orderId={orderId}
                  duster={duster}
                  nextCheckIn={nextCheckIn}
                  checkInDuster={checkInDuster}
                />
              </ListGroup.Item>
            )}
        {inProgress === true && (
          <ListGroup.Item className="text-center">
            <ReCheckOutCleaner
              nextClean={nextClean}
              checkOutDuster={checkOutDuster}
              orderId={orderId}
              invoiceHours={invoiceHours}
              duster={duster}
              note={note}
            />
          </ListGroup.Item>
        )}
        {omw && (
          <ListGroup.Item align="center">
            <Button variant="outline-info" size="sm">
              Dust Buster is {omw}
            </Button>
          </ListGroup.Item>
        )}
      </ListGroup>
      <Button variant="danger" onClick={handleShow} block>
        Remove Duster From the Order
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove {duster.name} from order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {duster.name} from this order?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={remove}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, { removeFromReOrder })(ReDuster);
