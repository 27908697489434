import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  payingDusters,
  ordersOnAccount,
  recurringOrder,
  fetchInvoices,
} from '../../../../../actions/admin/main';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap';

const Accounts = ({
  ordersOnAccount,
  payingDusters,
  recurringOrder,
  fetchInvoices,
  main: { paying_dusters, monthEndOrders, reOrderManagement, invoices },
}) => {
  let navigate = useNavigate();
  useEffect(() => {
    payingDusters();
    ordersOnAccount();
    recurringOrder();
    fetchInvoices();
  }, [payingDusters, ordersOnAccount, recurringOrder, fetchInvoices]);

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="container">
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4>Accounts Page </h4>
      <hr />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-coins text-success mr-3"></i>
              </span>
              <Card.Title>Invoices created</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">
                  {invoices ? (
                    `${invoices.num}`
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </b>
              </Card.Subtitle>

              <Button
                as={Link}
                to="/admin/admin-accounts/invoices"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-people-arrows mr-3"></i>
              </span>
              <Card.Title>Duster payslips</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">
                  {paying_dusters ? (
                    `${paying_dusters.num}`
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </b>
              </Card.Subtitle>

              <Button
                as={Link}
                to="/admin/admin-accounts/paying-dusters"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-file-invoice mr-5"></i>
              </span>
              <Card.Title>Orders On Account</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">
                  {monthEndOrders ? (
                    `${monthEndOrders.num}`
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </b>
              </Card.Subtitle>

              <Button
                as={Link}
                to="/admin/admin-accounts/orders-on-account"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-list mr-3"></i>
              </span>
              <Card.Title>Recurring order overview</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">
                  {reOrderManagement ? (
                    `${reOrderManagement.num}`
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </b>
              </Card.Subtitle>

              <Button
                as={Link}
                to="/admin/admin-accounts/recurring-order-overview"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-list mr-3"></i>
              </span>
              <Card.Title>Day end</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">
                  {reOrderManagement ? (
                    `${reOrderManagement.num}`
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </b>
              </Card.Subtitle>

              <Button variant="outline-secondary">Closer Look...</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-list mr-3"></i>
              </span>
              <Card.Title>Month end</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">
                  {reOrderManagement ? (
                    `${reOrderManagement.num}`
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </b>
              </Card.Subtitle>

              <Button variant="outline-secondary">Closer Look...</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
});

export default connect(mapStateToProps, {
  payingDusters,
  ordersOnAccount,
  recurringOrder,
  fetchInvoices,
})(Accounts);
