import axios from 'axios';
import {
  ID_UPLOAD,
  WP_UPLOAD,
  BL_UPLOAD,
  PP_UPLOAD,
  PA_UPLOAD,
  FILE_UPLOAD_ERROR,
} from './types';
import { setNote } from './note';

//Upload ID//
export const IDUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post('/api/team_upload/id', formData, config);
    dispatch({ type: ID_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: FILE_UPLOAD_ERROR });
  }
};

//Upload proof of address//
export const PAUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post('/api/team_upload/poa', formData, config);
    dispatch({ type: PA_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: FILE_UPLOAD_ERROR });
  }
};

//upload Work Permit//
export const workPermitUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/team_upload/work_permit',
      formData,
      config
    );
    dispatch({ type: WP_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: FILE_UPLOAD_ERROR });
  }
};

//Upload Bank Letter//
export const bankLetterUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/team_upload/bank_letter',
      formData,
      config
    );
    dispatch({ type: BL_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: FILE_UPLOAD_ERROR });
  }
};

//Upload Pro Pic//
export const proPic = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post('/api/team_upload/pro-pic', formData, config);
    dispatch({ type: PP_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: FILE_UPLOAD_ERROR });
  }
};

//Delete Image//
export const deleteImage = (public_id) => async (dispatch) => {
  try {
    await axios.delete(`/api/team_upload/delete_upload/${public_id}`);
    setNote('This image has been deleted', 'warning');
  } catch (error) {
    console.error(error.message);
  }
};
