import {
  ADMIN_LOADED,
  ADMIN_LOGIN,
  ADMIN_FAIL,
  ADMIN_LOGOUT,
} from '../../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAdmin: null,
  admin: null,
  loading: true,
};

function adminReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_LOADED:
      return {
        ...state,
        isAdmin: true,
        admin: payload,
        loading: false,
      };

    case ADMIN_LOGIN:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        admin: payload,
        isAdmin: true,
        loading: false,
      };
    case ADMIN_FAIL:
      return {
        ...state,
        isAdmin: false,
        admin: null,
        loading: false,
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        token: localStorage.removeItem('token'),
        isAdmin: false,
        admin: null,
        loading: false,
      };

    default:
      return state;
  }
}

export default adminReducer;
