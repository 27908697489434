import React, { useState } from 'react';
import { Button, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import SelectAddressItem from './SelectAddressItem';

const UserAddressModal = ({ user, address, changeOrderAddress }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const selectBookingAddress = ({
    text,
    complex,
    coordinates,
    addressType,
  }) => {
    changeOrderAddress({ text, complex, coordinates, addressType });
    handleClose();
  };
  return (
    <div>
      <Button
        size="sm"
        variant="outline-warning float-right"
        onClick={handleShow}
      >
        Change Address
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup.Item>
            <Row>
              <Col xs={10}>
                {user.address} - {user.addressType}
                {user.addressType !== 'House' && ` - ${user.complex}`}
              </Col>
              <Col xs={2}>
                <Button
                  variant="outline-info"
                  onClick={() => {
                    let text = user.address;
                    let complex = user.complex;
                    let addressType = user.addressType;
                    let coordinates = user.coordinates;
                    selectBookingAddress({
                      text,
                      complex,
                      coordinates,
                      addressType,
                    });
                  }}
                >
                  <i className="fa-solid fa-chevron-right"></i>
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
          {address.length === 0
            ? 'User only has one address'
            : address.map((userAdr) => (
                <SelectAddressItem
                  key={userAdr._id}
                  userAdr={userAdr}
                  selectBookingAddress={selectBookingAddress}
                />
              ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserAddressModal;
