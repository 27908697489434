import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row, Spinner } from 'react-bootstrap';
import SideBar from '../Layout/SideBar';
import AdminHeader from '../Layout/AdminHeader';

const AdminRoute = ({
  component: Component,
  admin: { isAdmin, loading, admin },
}) => {
  if (loading) return <Spinner animation="border" variant="primary" />;
  if (isAdmin)
    return (
      <Row>
        <Col xs={2}>
          <SideBar admin={admin} />
        </Col>
        <Col xs={10}>
          <Row>
            <AdminHeader />
          </Row>
          <Row>
            <Component />
          </Row>
        </Col>
      </Row>
    );
  return <Navigate to="/admin/signin" />;
};

AdminRoute.propTypes = {
  admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps)(AdminRoute);
