import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const UserRoute = ({ component: Component, user: { isUser, loading } }) => {
  if (loading) return <Spinner animation="border" variant="primary" />;
  if (isUser) return <Component />;
  return <Navigate to="/" />;
};

UserRoute.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UserRoute);
