import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Modal,
  Spinner,
  Row,
  Table,
  Col,
  Alert,
} from 'react-bootstrap';
import { GetUsers } from '../../../../../actions/admin/admin_user';
import ListClients from './ListClients';

const ClientCoupon = ({
  users,
  GetUsers,
  coupon,
  fourPer,
  fivePer,
  sixPer,
  sevenPer,
  eightPer,
  expirydate,
  createCoupon,
  coupontype,
  note,
}) => {
  useEffect(() => {
    GetUsers();
  }, [GetUsers]);
  const [nte, setMsg] = useState(note);
  const [searchLocal, setSearchLocal] = useState('');
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const [typeOfClean, setTypeOfClean] = useState(null);
  const [error, setError] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  let btn = useRef();
  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const jamCoupon = () => {
    if (coupon.length < 5) {
      setError('Enter a valid coupon');
      return setTimeout(() => setError(null), 2000);
    } else if (fourPer === 0) {
      setError('Enter 4 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (fivePer === 0) {
      setError('Enter 5 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sixPer === 0) {
      setError('Enter 6 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sevenPer === 0) {
      setError('Enter 7 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (eightPer === 0) {
      setError('Enter 8 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (typeOfClean === null) {
      setError('Please select type of clean');
      return setTimeout(() => setError(null), 2000);
    } else {
      let userId = user._id;
      const percArray = {
        fourPer: fourPer,
        fivePer: fivePer,
        sixPer: sixPer,
        sevenPer: sevenPer,
        eightPer: eightPer,
      };
      createCoupon({
        expirydate,
        coupon,
        percArray,
        coupontype,
        typeOfClean,
        userId,
      });
      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };
  return (
    <>
      {user ? (
        <>
          <Row>
            <Col>
              Creating coupon for {user.name} {user.surname}
            </Col>

            <Col>
              <Button
                variant="outline-warning"
                size="sm"
                onClick={() => setUser(null)}
              >
                choose someone else
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Type of clean: </Col>
            <Col>
              <Button
                variant={
                  typeOfClean === 'Once Off' ? 'info' : 'outline-secondary'
                }
                onClick={() => setTypeOfClean('Once Off')}
              >
                Once Off
              </Button>
            </Col>
            <Col>
              <Button
                variant={
                  typeOfClean === 'Reapeat Clean' ? 'info' : 'outline-secondary'
                }
                onClick={() => setTypeOfClean('Reapeat Clean')}
              >
                Repeat Clean
              </Button>
            </Col>
            <Col>
              <Button
                variant={
                  typeOfClean === 'Carpert Clean' ? 'info' : 'outline-secondary'
                }
                onClick={() => setTypeOfClean('Carpert Clean')}
              >
                Carpert Clean
              </Button>
            </Col>
          </Row>
          <hr />
          {error && <Alert variant="warning">{error}</Alert>}
          <Button
            variant="outline-success"
            onClick={() => jamCoupon()}
            ref={btn}
            block
          >
            Create Coupon
          </Button>
        </>
      ) : (
        <>
          <Button className="me-2 mb-2" onClick={() => handleShow(true)}>
            Select User
          </Button>
          <Modal show={show} size="xl" onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Row>
                  <Col>List of users</Col>
                  <Col>
                    <span className="float-right">
                      <input
                        className="text-center"
                        placeholder="search by name/email"
                        style={{ border: 'none' }}
                        onChange={(e) => setSearchLocal(e.target.value)}
                      />
                    </span>
                  </Col>
                </Row>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="justify-content-around">
                <Table>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Cellphone</th>
                      <th>Address</th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users ? (
                      users.length === 0 ? (
                        'No Users'
                      ) : (
                        users
                          .filter((val) => {
                            if (searchLocal === '') {
                              return val;
                            } else if (
                              val.name
                                .toLowerCase()
                                .includes(searchLocal.toLowerCase())
                            ) {
                              return val;
                            } else if (
                              val.email
                                .toLowerCase()
                                .includes(searchLocal.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .map((user) => (
                            <ListClients
                              key={user._id}
                              user={user}
                              setUser={setUser}
                            />
                          ))
                      )
                    ) : (
                      <Spinner animation="grow" variant="primary" />
                    )}
                  </tbody>
                </Table>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.admin_users.users,
});

export default connect(mapStateToProps, { GetUsers })(ClientCoupon);
