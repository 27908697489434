import { Button } from 'react-bootstrap';

const ListClients = ({ user, setUser }) => {
  const adr = user.address.split(',').shift();
  return (
    <tr>
      <td>{user.name}</td>
      <td>{user.surname}</td>
      <td>{user.email}</td>
      <td>{user.cellphone}</td>
      <td>{adr}</td>
      <td>
        <Button
          size="sm"
          variant="outline-dark ml-4"
          onClick={() => setUser(user)}
        >
          Select
        </Button>
      </td>
    </tr>
  );
};

export default ListClients;
