import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { validateOrdersAll } from '../../../actions/admin/orders';
import { Container, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import OrderItem from './OrderItem';

const RequireValidation = ({ validateOrdersAll, orders }) => {
  useEffect(() => {
    validateOrdersAll();
  }, [validateOrdersAll]);
  const navigate = useNavigate();
  if (orders === null) {
    return (
      <Container>
        <Spinner variant="primary" animation="grow" />
      </Container>
    );
  }

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4 style={{ color: '#06a5dd' }}>Need validation</h4>
      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>Client Name</th>
            <th>Booking Date</th>
            <th>Address</th>
            <th>Payment Status</th>
            <th>Cleaner assigned</th>
            <th>Booking Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {orders.orders.length === 0
            ? 'No Orders this month'
            : orders.orders.map((o) => <OrderItem key={o._id} o={o} />)}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  orders: state.admin_orders.require_validation,
});

export default connect(mapStateToProps, { validateOrdersAll })(
  RequireValidation
);
