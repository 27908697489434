import axios from 'axios';
import { DUSTER_REVIEW } from '../types';
import { setNote } from '../note';

/////////////////
//Review Duster//
/////////////////
export const dusterReview =
  ({ review, rating, dusterId, orderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ review, rating, dusterId, orderId });

    try {
      const res = await axios.post('/api/user/reviews/create', body, config);
      dispatch({ type: DUSTER_REVIEW, payload: res.data });
    } catch (error) {
      console.error(error.message);
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

///////////////////////
///Edit Duster Review//
///////////////////////
export const editDusterReview =
  ({ review, rating, reviewId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ review, rating });
    try {
      const res = await axios.put(
        `/api/user/reviews/edit/${reviewId}`,
        body,
        config
      );
      dispatch({ type: DUSTER_REVIEW, payload: res.data });
      dispatch(setNote('You have successfullt edited your review', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////
//Fetch Order Review//
/////////////////////
export const fetchOrderReview = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/reviews/order-review/${orderId}`);

    dispatch({ type: DUSTER_REVIEW, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
