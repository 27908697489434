import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { setNote } from '../../../actions/note';
import {
  handleBed,
  handleBath,
  calcBedBath,
} from '../../../actions/user/bookings';

const BedAndBath = ({
  bookings: { bedroom, bathroom, bedroomTime, bathroomTime },
  setNote,
  handleBed,
  handleBath,
  calcBedBath,
  hours,
}) => {
  //Bed & Bath Variables//

  useEffect(() => {
    calcBedBath(bedroomTime, bathroomTime);
  }, [bedroomTime, bathroomTime, calcBedBath]);

  //////////////////////////
  //Handle Bedroom Buttons//
  //////////////////////////
  const minusBedroom = (e) => {
    e.preventDefault();
    if (bedroom === 0) {
      return setNote('You cannot have less than zero bedrooms', 'warning');
    }
    let newBeds = bedroom - 1;
    handleBed(newBeds);
  };

  const plusBedroom = (e) => {
    e.preventDefault();
    if (bedroom === 6) {
      return setNote(
        'If you would like more than 6 bedrooms please call: + 27 87 550 1147',
        'success'
      );
    }

    if (hours === 8) {
      return setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    }

    let newBeds = bedroom + 1;

    handleBed(newBeds);
  };

  ///////////////////////////
  //Handle Bathroom Buttons//
  ///////////////////////////
  const minusBathroom = (e) => {
    e.preventDefault();
    if (bathroom === 0) {
      return setNote('You cannot have less than zero bathrooms', 'warning');
    }
    let newBath = bathroom - 1;

    handleBath(newBath);
  };

  const plusBathroom = (e) => {
    e.preventDefault();
    if (bathroom === 4) {
      return setNote(
        'If you would like more than 4 bathrooms please call: + 27 11 568 8077',
        'success'
      );
    }
    if (hours === 8) {
      return setNote(
        'Please call the office to book over 8 hours: + 27 11 568 8077',
        'warning'
      );
    }
    let newBath = bathroom + 1;

    handleBath(newBath);
  };

  return (
    <div>
      <hr />
      <Row>
        <Col md={6} xs={12}>
          <Card.Title className="text-center">Bedrooms</Card.Title>
          <Card.Header className="text-center mb-2">
            <b>How many Bedrooms?</b>
          </Card.Header>
          <Card.Title align="center">
            <Button
              variant="outline-info"
              className="float-left"
              onClick={minusBedroom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {bedroom} Bedrooms
            <Button
              variant="outline-success"
              className="float-right"
              onClick={plusBedroom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title className="text-center">Bathrooms</Card.Title>
          <Card.Header className="mb-2 text-center">
            <b>How many bathrooms?</b>
          </Card.Header>
          <Card.Title align="center">
            <Button
              variant="outline-info"
              className="float-left"
              onClick={minusBathroom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {bathroom} Bathrooms
            <Button
              variant="outline-success"
              className="float-right"
              onClick={plusBathroom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
});

export default connect(mapStateToProps, {
  setNote,
  handleBed,
  handleBath,
  calcBedBath,
})(BedAndBath);
