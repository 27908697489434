import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const Calender = ({
  mySchedule,
  getBooking,
  getReBooking,
  booking: { booking, reBooking },
}) => {
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState(null);
  const [adr, setAdr] = useState('');
  const [complex, setComplex] = useState(null);
  const [go, setGo] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (booking) {
      setGo(`/team/my-booking/${booking._id}`);
      setAddress(booking.address);
      setComplex(booking.complex);
    } else if (reBooking) {
      setGo(`/team/my-rebooking/${reBooking._id}`);
      setAddress(reBooking.address);
      setComplex(reBooking.complex);
    }
  }, [booking, reBooking]);

  useEffect(() => {
    if (address) {
      setAdr(address.split(','));
    }
  }, [address]);

  const showBooking = (info) => {
    if (info.event._def.extendedProps.order) {
      let orderId = info.event._def.extendedProps.order;
      getBooking(orderId);
      handleShow();
    } else {
      let reorderId = info.event._def.extendedProps.reorder;
      getReBooking(reorderId);
      handleShow();
    }
  };

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={mySchedule}
        displayEventEnd="true"
        eventClassNames="btn btn-outline-info btn-sm"
        eventClick={showBooking}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Go to booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {adr[0]} , {adr[1]} {complex && `| ${complex}`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {go && (
            <Button variant="info" as={Link} to={`${go}`}>
              Go to clean
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  booking: state.team_work,
});

export default connect(mapStateToProps)(Calender);
