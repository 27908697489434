import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';

const BookingsCalender = ({ myBookings, b, fetchBooking }) => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      events={myBookings}
      height={450}
      headerToolbar={{
        left: 'prev,next',
        center: 'title',
        right: 'dayGridWeek,dayGridMonth',
      }}
      eventDisplay="block"
      displayEventTime={true}
    />
  );
};

export default BookingsCalender;
