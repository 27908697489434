import axios from 'axios';
import {
  ORDER_QUERIES,
  REMOVE_QUERIES,
  IN_QUERY,
  IN_QUERY_TEXTS,
  USER_QUERY_SOCKET,
  REMOVE_SOCKET,
} from '../types';
import { setNote } from '../note';
import io from 'socket.io-client';

//Create a query//
export const createQuery =
  ({ headline, desc, orderId }) =>
  async (dispatch) => {
    dispatch({ type: REMOVE_QUERIES });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ headline, desc });

    try {
      const res = await axios.post(
        `/api/user/queries/create-query/${orderId}`,
        body,
        config
      );
      dispatch(setNote('You have successfully created a query', 'success'));
      dispatch({ type: ORDER_QUERIES, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Create a requery//
export const createAReQuery =
  ({ headline, desc, reOrderId }) =>
  async (dispatch) => {
    dispatch({ type: REMOVE_QUERIES });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ headline, desc });
    try {
      const res = await axios.post(
        `/api/user/queries/create-requery/${reOrderId}`,
        body,
        config
      );
      dispatch(setNote('You have successfully created a query', 'success'));
      dispatch({ type: ORDER_QUERIES, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch order queries//
export const orderQueries = (orderId) => async (dispatch) => {
  dispatch({ type: REMOVE_QUERIES });
  try {
    const res = await axios.get(`/api/user/queries/order-queries/${orderId}`);
    dispatch({ type: ORDER_QUERIES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch reorder queries//
export const reOrderQueries = (reOrderId) => async (dispatch) => {
  dispatch({ type: REMOVE_QUERIES });
  try {
    const res = await axios.get(
      `/api/user/queries/reorder-queries/${reOrderId}`
    );
    dispatch({ type: ORDER_QUERIES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get a specific query//
export const aQuery = (queryId) => async (dispatch) => {
  dispatch({ type: REMOVE_QUERIES });
  try {
    const res = await axios.get(`/api/user/queries/order-query/${queryId}`);
    dispatch({ type: IN_QUERY, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get query texts//
export const queryTexts = (queryId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/queries/query-texts/${queryId}`);
    dispatch({ type: IN_QUERY_TEXTS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Setup and clear socket//
export const setupQuerySocket = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_SOCKET });
  const socket = io();
  try {
    socket.on('connect', () => {
      socket.emit('join_query', { queryId: id });
      dispatch({ type: USER_QUERY_SOCKET, payload: socket });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch the queries//
export const fetchQueries = () => async (dispatch) => {
  dispatch({ type: REMOVE_QUERIES });
  try {
    const res = await axios.get('/api/user/queries/my-queries');
    dispatch({ type: ORDER_QUERIES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
