import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  reCleanerAllocated,
  awaitingCleanerRe,
  doneReOrders,
  activeReOrders,
} from '../../../actions/user/order';
import { Alert, Container, Col, Row, Spinner, Button } from 'react-bootstrap';
import ReOrderItem from './ReOrderItem';

const MyReOrders = ({
  orders: {
    completedReOrders,
    needCleanerReOrders,
    allocatedReOrders,
    busyReOrders,
  },
  reCleanerAllocated,
  awaitingCleanerRe,
  doneReOrders,
  activeReOrders,
}) => {
  const [allocatedOrders, SetAllocatedOrders] = useState([]);
  const [waitingCleaner, SetAwaitingCleaner] = useState([]);
  const [completeOrder, SetCompleteOrder] = useState([]);
  const [active, SetActiveOrders] = useState([]);
  useEffect(() => {
    reCleanerAllocated();
    awaitingCleanerRe();
    doneReOrders();
    activeReOrders();
  }, [reCleanerAllocated, awaitingCleanerRe, doneReOrders, activeReOrders]);

  useEffect(() => {
    if (
      completedReOrders &&
      needCleanerReOrders &&
      allocatedReOrders &&
      busyReOrders
    ) {
      SetAllocatedOrders(allocatedReOrders);
      SetAwaitingCleaner(needCleanerReOrders);
      SetCompleteOrder(completedReOrders);
      SetActiveOrders(busyReOrders);
    }
  }, [completedReOrders, needCleanerReOrders, allocatedReOrders, busyReOrders]);

  if (
    allocatedReOrders === null ||
    busyReOrders === null ||
    needCleanerReOrders === null ||
    completedReOrders === null
  ) {
    return <Spinner animation="grow" variant="dark" />;
  }
  return (
    <div>
      <Container>
        <Alert variant="dark">
          My Repeat Orders{' '}
          <Button variant="danger float-right" size="sm">
            Unpaid Orders
          </Button>
        </Alert>
        <Row>
          <Col>
            <Alert variant="warning">Awaiting Dust Buster</Alert>
            {waitingCleaner.length === 0
              ? 'No Orders...'
              : waitingCleaner.map((order) => (
                  <ReOrderItem key={order._id} order={order} />
                ))}
          </Col>

          <Col>
            <Alert variant="info">Allocated Orders</Alert>
            {allocatedOrders.length === 0
              ? 'No Orders...'
              : allocatedOrders.map((order) => (
                  <ReOrderItem key={order._id} order={order} />
                ))}
          </Col>
          <Col>
            <Alert variant="primary">Active Orders</Alert>
            {active.length === 0
              ? 'No Orders...'
              : active.map((order) => (
                  <ReOrderItem key={order._id} order={order} />
                ))}
          </Col>
          <Col>
            <Alert variant="success">Completed Orders</Alert>
            {completeOrder.length === 0
              ? 'No Orders...'
              : completeOrder.map((order) => (
                  <ReOrderItem key={order._id} order={order} />
                ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  orders: state.orders,
});

export default connect(mapStateToProps, {
  reCleanerAllocated,
  awaitingCleanerRe,
  doneReOrders,
  activeReOrders,
})(MyReOrders);
