import React from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment';

export default function QueryTexts({ text, adminId }) {
  return (
    <div>
      {text.sentBy === adminId ? (
        <Card
          style={{ width: '18rem' }}
          className="text-left float-right mb-2 bg-info text-white"
        >
          <Card.Body>
            <Card.Title>{text.text}</Card.Title>
            <footer className="blockquote-footer text-white">
              You
              <cite title="Source Title">
                {moment(text.timestamp).calendar()}
              </cite>
            </footer>
          </Card.Body>
        </Card>
      ) : (
        <Card style={{ width: '18rem' }} className="text-left mb-2">
          <Card.Body>
            <Card.Title>{text.text}</Card.Title>
            <footer className="blockquote-footer">
              {text.sentByName}{' '}
              <cite title="Source Title">
                {moment(text.timestamp).calendar()}
              </cite>
            </footer>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
