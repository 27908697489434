import { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ReOrderItem = ({ o }) => {
  const [paid, setPaid] = useState(false);
  const [assigned, setAssigned] = useState('Needs Duster');
  const [complete, setcomplete] = useState(false);
  const [nextCheckin, setNextCheckin] = useState(new Date());
  useEffect(() => {
    if (o.code !== undefined) {
      setPaid(true);
    } else {
      setPaid(false);
    }

    if (o.team === null || o.team === undefined) {
      setAssigned('Needs Duster');
    } else {
      setAssigned(o.team.name);
    }
    if (o.cleans === o.checkOut.length) {
      setcomplete(true);
    } else {
      setcomplete(false);
    }
    let sortedSchedule = o.schedule.sort((x, y) => x.start - y.start);
    let checkInLength = o.checkIn.length;
    if (checkInLength >= sortedSchedule.length) {
      setNextCheckin(new Date());
    } else {
      let nextCheckIn = sortedSchedule[checkInLength];
      setNextCheckin(nextCheckIn.start);
    }
  }, [o, setPaid, setAssigned, setcomplete, setNextCheckin]);

  let address = o.address.split(',').shift();
  let orderTime = moment(o.firstDate).format('ddd MMM D, h:mm a');

  return (
    <tr>
      <td>{orderTime}</td>
      <td>{address}</td>
      <td>{paid ? 'Paid' : 'Not paid'}</td>
      <td>{assigned !== 'Needs Duster' ? `${assigned}` : 'Needs duster'}</td>
      <td>{complete ? 'Complete' : 'Incomplete'}</td>
      <td>{moment(nextCheckin).format('ddd MMM D')}</td>
      <td>
        <Button
          as={Link}
          to={`/admin/order-management/repeat-orders/order/${o._id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

export default ReOrderItem;
