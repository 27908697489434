import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createReview, fetchOrderReview } from '../../../actions/team/reviews';
import { Button, Modal, Form, ListGroup } from 'react-bootstrap';

const OrderComplete = ({
  createReview,
  currentOrder,
  userId,
  score,
  fetchOrderReview,
}) => {
  const [show, setShow] = useState(false);
  const [review, setReview] = useState('');
  const [rating, setRating] = useState('');
  const [reviewed, setReviewed] = useState(null);
  useEffect(() => {
    let orderId = currentOrder;
    fetchOrderReview(orderId);
  }, [fetchOrderReview, currentOrder]);
  useEffect(() => {
    if (score) {
      setReviewed(score);
    }
    return () => {
      setReviewed(null);
    };
  }, [score]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitReview = () => {
    let orderId = currentOrder;
    createReview({ review, rating, userId, orderId });
    setShow(false);
  };
  return (
    <div align="center">
      <h1>You have marked the order as complete</h1>
      {reviewed === null ? (
        <>
          <Button variant="primary" onClick={handleShow}>
            Please tell us how it was
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Write Review</Form.Label>
                  <Form.Control
                    type="text"
                    name="review"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
                <Form.Group className="text-center">
                  <Form.Label>Star Rating</Form.Label>
                  <div>
                    <Button
                      variant={
                        rating === '1' ? 'danger mr-2' : 'outline-danger mr-2'
                      }
                      value="1"
                      onClick={(e) => setRating(e.target.value)}
                      inline
                    >
                      1 <i className="far fa-star"></i>
                    </Button>
                    <Button
                      variant={
                        rating === '2' ? 'danger mr-2' : 'outline-danger mr-2'
                      }
                      value="2"
                      onClick={(e) => setRating(e.target.value)}
                      inline
                    >
                      2 <i className="far fa-star"></i>
                    </Button>
                    <Button
                      variant={
                        rating === '3' ? 'warning mr-2' : 'outline-warning mr-2'
                      }
                      value="3"
                      onClick={(e) => setRating(e.target.value)}
                      inline
                    >
                      3 <i className="far fa-star"></i>
                    </Button>
                    <Button
                      variant={
                        rating === '4' ? 'success mr-2' : 'outline-success mr-2'
                      }
                      value="4"
                      onClick={(e) => setRating(e.target.value)}
                      inline
                    >
                      4 <i className="far fa-star"></i>
                    </Button>
                    <Button
                      variant={
                        rating === '5' ? 'success mr-2' : 'outline-success mr-2'
                      }
                      value="5"
                      onClick={(e) => setRating(e.target.value)}
                      inline
                    >
                      5 <i className="far fa-star"></i>
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Exit
              </Button>
              {rating.length > 0 ? (
                <Button variant="warning text-white" onClick={submitReview}>
                  Submit Review
                </Button>
              ) : (
                <Button variant="secondary text-white">
                  Please complete review
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <div style={{ width: '25rem' }}>
          <Button block disabled>
            You have submited your review
          </Button>
          <ListGroup>
            <ListGroup.Item variant="secondary">Your Review</ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left">Review:</p>
              <b className="float-right">{reviewed.review}</b>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left">Rating:</p>
              <Button className="float-right" size="sm">
                {reviewed.rating} <i className="far fa-star"></i>
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  score: state.duster_reviews.review,
});
export default connect(mapStateToProps, { createReview, fetchOrderReview })(
  OrderComplete
);
