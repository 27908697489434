import {
  RATE_CARD,
  ALL_DUSTERS,
  ALL_USERS,
  ALL_ORDER_TOTAL,
  CLEAR_RATE,
  THE_MOTHER_CARD,
  ALL_INVOICES,
  ORDER_INVOICE,
  INVOICE,
  CLEAR_ORDER_INVOICE,
  PAYING_DUSTERS,
  PAYSLIP,
  CLEAR_PAYSLIP,
  TOTAL_HOURS,
  TOTAL_REPEAT_HOURS,
  MONTH_END_ORDERS,
  CUSTOM_PAYCYLE,
  RECURRING_ORDER_,
  CREATE_PAYSLIP,
  REMOVE_PAYSLIP_ITEM,
  UPDATE_HOURS,
  UPDATE_REHOURS,
  GET_DUSTER_PAYSLIP,
  EMPLOYEES,
  ALL_REPEAT_ORDERS,
  GET_CARPET_MOTHER,
  GLOBAL_DUSTER_RATING,
} from '../../actions/types';
const initialState = {
  loading: false,
  allDusters: null,
  allUsers: null,
  orderTotal: null,
  clientRate: null,
  mother: null,
  invoices: null,
  order_invoice: null,
  invoice: null,
  paying_dusters: null,
  payslip: null,
  onceOffHours: [],
  howManyOnceOff: null,
  howManyRepeat: null,
  totalOnceOffHours: 0,
  repeatHours: [],
  totalRepeatHours: 0,
  monthEndOrders: null,
  custom_pay_cycle: null,
  reOrderManagement: null,
  customRange: null,
  currentSlip: [],
  duster_payslip: null,
  employees: null,
  all_repeat: null,
  carpet_mother: null,
  global_duster_rate: null,
};

function mainReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_REPEAT_ORDERS:
      return {
        ...state,
        all_repeat: payload,
      };
    case EMPLOYEES:
      return {
        ...state,
        employees: payload,
      };
    case RECURRING_ORDER_:
      return {
        ...state,
        reOrderManagement: payload,
        loading: false,
      };
    case CUSTOM_PAYCYLE:
      return {
        ...state,
        custom_pay_cycle: payload,
        loading: false,
      };
    case MONTH_END_ORDERS:
      return {
        ...state,
        monthEndOrders: payload,
        loading: false,
      };
    case CLEAR_ORDER_INVOICE:
      return {
        ...state,
        order_invoice: null,
      };
    case THE_MOTHER_CARD:
      return {
        ...state,
        mother: payload,
      };
    case ALL_DUSTERS:
      return {
        ...state,
        allDusters: payload,
      };
    case ALL_USERS:
      return {
        ...state,
        allUsers: payload,
      };
    case ALL_ORDER_TOTAL:
      return {
        ...state,
        orderTotal: payload,
      };
    case RATE_CARD:
      return {
        ...state,
        clientRate: payload,
      };
    case CLEAR_RATE:
      return {
        ...state,
        clientRate: null,
      };
    case ALL_INVOICES:
      return {
        ...state,
        invoices: payload,
      };
    case ORDER_INVOICE:
      return {
        ...state,
        order_invoice: payload,
      };
    case INVOICE:
      return {
        ...state,
        invoice: payload,
      };
    case PAYING_DUSTERS:
      return {
        ...state,
        paying_dusters: payload,
      };
    case PAYSLIP:
      return {
        ...state,
        payslip: payload,
        loading: false,
      };
    case CLEAR_PAYSLIP:
      return {
        ...state,
        payslip: null,
        totalOnceOffHours: 0,
        totalRepeatHours: 0,
        onceOffHours: [],
        repeatHours: [],
        loading: true,
        currentSlip: [],
        howManyOnceOff: null,
        howManyRepeat: null,
      };
    case TOTAL_HOURS:
      state.onceOffHours.push(parseFloat(payload));
      let oo = state.onceOffHours.length;
      let total = state.onceOffHours.reduce((a, b) => a + b, 0);
      return {
        ...state,
        totalOnceOffHours: total,
        howManyOnceOff: oo,
        loading: false,
      };
    case TOTAL_REPEAT_HOURS:
      state.repeatHours.push(parseFloat(payload));
      let rh = state.repeatHours.length;
      let totalRepeat = state.repeatHours.reduce((a, b) => a + b, 0);
      return {
        ...state,
        totalRepeatHours: totalRepeat,
        howManyRepeat: rh,
        loading: false,
      };
    case CREATE_PAYSLIP:
      let arr = state.currentSlip.concat(payload);
      arr.sort((a, b) => {
        return a.checkIn - b.checkIn;
      });
      return {
        ...state,
        currentSlip: arr,
      };

    case REMOVE_PAYSLIP_ITEM:
      let juice = state.currentSlip.filter((slip) => slip.id !== payload);
      juice.sort((a, b) => {
        return a.checkIn - b.checkIn;
      });
      return {
        ...state,
        currentSlip: juice,
      };
    case UPDATE_HOURS:
      return {
        ...state,
        totalOnceOffHours: payload,
      };
    case UPDATE_REHOURS:
      return {
        ...state,
        totalRepeatHours: payload,
      };
    case GET_DUSTER_PAYSLIP:
      return {
        ...state,
        duster_payslip: payload,
      };
    case GET_CARPET_MOTHER:
      return {
        ...state,
        carpet_mother: payload,
      };
    case GLOBAL_DUSTER_RATING:
      return {
        ...state,
        global_duster_rate: payload,
      };
    default:
      return state;
  }
}

export default mainReducer;
