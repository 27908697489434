import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  adminIDUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminWorkPermitUpload,
  adminECUpload,
  adminPOAUpload,
  clearAdminUpload,
} from '../../../../actions/admin/admin_file_upload';
import { addDuster, adHocDuster } from '../../../../actions/admin/team_admin';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import AddressAutoComplete from '../../../Layout/AddressAutocomplete';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

const AddNewDuster = ({
  adminUpload: {
    id_uploaded,
    wp_uploaded,
    bl_uploaded,
    poa_uploaded,
    ec_uploaded,
    pp_uploaded,
  },
  note,
  adminIDUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminWorkPermitUpload,
  adminECUpload,
  adminPOAUpload,
  addDuster,
  adHocDuster,
  clearAdminUpload,
}) => {
  const [msg, setMsg] = useState(note);
  const [address, setAddress] = useState('');
  const [female, setFemale] = useState(true);
  const [daily, setDaily] = useState(true);
  const [local, setLocal] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    cellphone: '',
    email: '',
    password: '',
    idNum: '',
  });
  const [uploadDocs, setUploadDocs] = useState(true);

  const { name, surname, cellphone, email, password, idNum } = formData;

  let navigate = useNavigate();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  let btn = useRef();
  let IDbtn = useRef();
  let POAbtn = useRef();
  let BLbtn = useRef();
  let PPbtn = useRef();
  let WPbtn = useRef();
  let ECbtn = useRef();
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    if (note.length > 0) {
      if (note[0].noteType === 'success' && btn.current) {
        setMsg(note);
        btn.current.removeAttribute('disabled');

        setFormData({
          name: '',
          surname: '',
          cellphone: '',
          email: '',
          password: '',
          idNum: '',
        });
        setCoordinates({
          lat: null,
          lng: null,
        });
        setAddress('');
        clearAdminUpload();
      }
    }
  }, [note, clearAdminUpload]);

  useEffect(() => {
    if (note && btn.current) {
      btn.current.removeAttribute('disabled');
    }
  }, [note, btn]);

  //Upload ID
  useEffect(() => {
    if (note && IDbtn.current) {
      setMsg(note);
      IDbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadID = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminIDUpload(formData);

    if (IDbtn.current) {
      IDbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Proof Of Address//
  useEffect(() => {
    if (note && POAbtn.current) {
      setMsg(note);
      POAbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPOA = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminPOAUpload(formData);

    if (POAbtn.current) {
      POAbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Bank Letter Button//
  useEffect(() => {
    if (note && BLbtn.current) {
      setMsg(note);
      BLbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadBL = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminBankLetterUpload(formData);

    if (BLbtn.current) {
      BLbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Profile Pic Button//
  useEffect(() => {
    if (note && PPbtn.current) {
      setMsg(note);
      PPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminProPicUpload(formData);

    if (PPbtn.current) {
      PPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Work Permit Button//
  useEffect(() => {
    if (note && WPbtn.current) {
      setMsg(note);
      WPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadWP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminWorkPermitUpload(formData);

    if (WPbtn.current) {
      WPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Employment Contract Button//
  useEffect(() => {
    if (note && ECbtn.current) {
      setMsg(note);
      ECbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadEC = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminECUpload(formData);

    if (ECbtn.current) {
      ECbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  useEffect(() => {
    if (note.length > 0) {
      if (note[0].noteType === 'success' && btn.current) {
        setMsg(note);
        btn.current.removeAttribute('disabled');

        setFormData({
          name: '',
          surname: '',
          cellphone: '',
          email: '',
          password: '',
          idNum: '',
        });
        setCoordinates({
          lat: null,
          lng: null,
        });
        setAddress('');
      }
    }
  }, [setMsg, note, msg]);

  const createDuster = () => {
    if (uploadDocs) {
      console.log('Upload duster with docs');
      addDuster({
        name,
        surname,
        email,
        cellphone,
        address,
        coordinates,
        password,
        female,
        daily,
        local,
        idNum,
        id_uploaded,
        poa_uploaded,
        bl_uploaded,
        pp_uploaded,
        ec_uploaded,
        wp_uploaded,
      });
    } else {
      console.log('Upload duster without docs');
      adHocDuster({
        name,
        surname,
        email,
        cellphone,
        address,
        coordinates,
        password,
        female,
        local,
        idNum,
        daily,
      });
    }

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4>Add a new duster</h4>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Surname:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Surname"
              name="surname"
              value={surname}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Cellphone:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter cellphone"
              name="cellphone"
              value={cellphone}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Enter ID number:</Form.Label>
            <Form.Control
              type="text"
              placeholder="ID number"
              name="idNum"
              value={idNum}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Address:</Form.Label>
            <AddressAutoComplete
              value={address}
              setAddress={setAddress}
              setCoordinates={setCoordinates}
            />
          </Form.Group>
        </Col>
        <Col>
          <Row className="mt-4">
            <Col xs={4}>
              <Button
                variant={
                  female === false ? 'primary mr-1' : 'outline-secondary mr-1'
                }
                onClick={() => setFemale(false)}
              >
                Male
              </Button>
              <Button
                variant={female ? 'info' : 'outline-secondary'}
                onClick={() => setFemale(true)}
              >
                Female
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                variant={daily ? 'outline-secondary' : 'info'}
                onClick={() => setDaily(false)}
              >
                Contract
              </Button>
              <Button
                variant={daily ? 'info' : 'outline-secondary'}
                onClick={() => setDaily(true)}
              >
                Duster
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                variant={local ? 'info mr-1' : 'outline-secondary mr-1'}
                onClick={() => setLocal(true)}
              >
                Local
              </Button>
              <Button
                variant={local === false ? 'info' : 'outline-secondary'}
                onClick={() => setLocal(false)}
              >
                Foreign
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={8}>
          <h4>Upload Docs</h4>
        </Col>
        <Col xs={4}>
          <Button
            variant={uploadDocs ? 'outline-success' : 'outline-secondary'}
            className="float-right"
            onClick={() => setUploadDocs(true)}
          >
            Yes
          </Button>
          <Button
            variant={uploadDocs ? 'outline-secondary' : 'outline-success'}
            className="float-right"
            onClick={() => setUploadDocs(false)}
          >
            No
          </Button>
        </Col>
      </Row>
      {uploadDocs ? (
        <>
          <Row>
            <Col>
              {id_uploaded ? (
                <Button variant="info mb-3" disabled block>
                  ID uploaded <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <Dropzone onDrop={uploadID}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="warning mb-3"
                      {...getRootProps()}
                      ref={IDbtn}
                      block
                    >
                      <input {...getInputProps()} />
                      1. ID Document
                      <i className="fas fa-cloud-upload-alt ml-2"></i>
                    </Button>
                  )}
                </Dropzone>
              )}
            </Col>
            <Col>
              {poa_uploaded ? (
                <Button variant="info  mb-3" disabled block>
                  Proof of address uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <Dropzone onDrop={uploadPOA}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="warning mb-3"
                      {...getRootProps()}
                      ref={POAbtn}
                      block
                    >
                      <input {...getInputProps()} />
                      2. Proof of address
                      <i className="fas fa-cloud-upload-alt ml-2"></i>
                    </Button>
                  )}
                </Dropzone>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {bl_uploaded ? (
                <Button variant="info mb-3" disabled block>
                  Bank letter uploaded{' '}
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <Dropzone onDrop={uploadBL}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="warning mb-3"
                      {...getRootProps()}
                      ref={BLbtn}
                      block
                    >
                      <input {...getInputProps()} />
                      3. Bank letter
                      <i className="fas fa-cloud-upload-alt ml-2"></i>
                    </Button>
                  )}
                </Dropzone>
              )}
            </Col>
            <Col>
              {pp_uploaded ? (
                <Button variant="info mb-3" disabled block>
                  Profile picture uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <Dropzone onDrop={uploadPP}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="warning mb-3"
                      {...getRootProps()}
                      ref={PPbtn}
                      block
                    >
                      <input {...getInputProps()} />
                      4. Profile picture
                      <i className="fas fa-cloud-upload-alt ml-2"></i>
                    </Button>
                  )}
                </Dropzone>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {ec_uploaded ? (
                <Button variant="info  mb-3" disabled block>
                  Employment contract uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <Dropzone onDrop={uploadEC}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="warning mb-3"
                      {...getRootProps()}
                      ref={ECbtn}
                      block
                    >
                      <input {...getInputProps()} />
                      5. Employment contract
                      <i className="fas fa-cloud-upload-alt ml-2"></i>
                    </Button>
                  )}
                </Dropzone>
              )}
            </Col>
            <Col>
              {wp_uploaded ? (
                <Button
                  variant="info
            mb-3"
                  disabled
                  block
                >
                  Work permit uploaded{' '}
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <Dropzone onDrop={uploadWP}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="warning mb-3"
                      {...getRootProps()}
                      ref={WPbtn}
                      block
                    >
                      <input {...getInputProps()} />
                      6. Work Permit
                      <i className="fas fa-cloud-upload-alt ml-2"></i>
                    </Button>
                  )}
                </Dropzone>
              )}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      <Button variant="success" block ref={btn} onClick={createDuster}>
        Add Duster
      </Button>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  adminUpload: state.adminUpload,
});

export default connect(mapStateToProps, {
  adminIDUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminWorkPermitUpload,
  adminECUpload,
  adminPOAUpload,
  addDuster,
  adHocDuster,
  clearAdminUpload,
})(AddNewDuster);
