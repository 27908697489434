import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { setNote } from '../../../actions/note';
import { verifyUser } from '../../../actions/user';
import Bottom from '../../Layout/Bottom';
import { useParams } from 'react-router-dom';

const Verify = ({ verifyUser, note }) => {
  const { token } = useParams();
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (note.length > 0) {
      let msg = note[0];
      setResult(msg.msg);
    }
  }, [setResult, note]);

  useEffect(() => {
    if (token) {
      verifyUser({ token });
    }
  }, [token, verifyUser]);

  return (
    <div>
      <div className="container">
        {result ? (
          <Alert variant="warning text-center">{result}</Alert>
        ) : (
          <Alert variant="success text-center">Verifying...</Alert>
        )}
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { verifyUser, setNote })(Verify);
