import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  ListGroup,
  Alert,
  Button,
  Spinner,
  Modal,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RedFlags from './RedFlags';
import DeleteDuster from './DeleteDuster';
import moment from 'moment';

const StageThree = ({
  dustBuster,
  dusterId,
  skills,
  interview,
  uploads,
  readyToWork,
  deleteBtn,
  removeDuster,
  missed,
}) => {
  const [flags, setFlags] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState('password');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  useEffect(() => {
    setFlags(dustBuster.redflags);
    if (dustBuster.local === true) {
      setNationality('South African');
    } else {
      setNationality('Foreign Citizen');
    }
    if (dustBuster.female === true) {
      setGender('Female');
    } else {
      setGender('Male');
    }
  }, [dustBuster]);

  if (skills === null || interview === null || uploads === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const slot = interview.start.split('G').shift();
  const { ref1, ref2, office, hotel, home, medical, eot, retail, exp } = skills;

  const togglePassword = () => {
    if (showPassword === 'password') setShowPassword('text');
    if (showPassword === 'text') setShowPassword('password');
  };

  const confirmDuster = () => {
    readyToWork({ dusterId, password });
  };

  const goBack = () => {
    navigate(-1);
  };

  const navToId = () => {
    window.open(uploads.idDoc);
  };

  const navToProPic = () => {
    window.open(uploads.pic);
  };

  const navToBankLetter = () => {
    window.open(uploads.bankLetter);
  };

  const navToPOA = () => {
    window.open(uploads.poa);
  };

  const navToWorkPermit = () => {
    window.open(uploads.workPermit);
  };

  const navToEC = () => {
    window.open(uploads.ec);
  };

  return (
    <Row>
      <Col md={4} xs={12}>
        <Alert variant="info">
          Duster Info
          {uploads ? (
            <Button
              variant="success float-right ml-2"
              size="sm"
              onClick={handleShow}
            >
              Approve
            </Button>
          ) : (
            'UPLOAD DOCS TO APPROVE!'
          )}
          <DeleteDuster
            dusterId={dusterId}
            dustBuster={dustBuster}
            deleteBtn={deleteBtn}
            removeDuster={removeDuster}
          />
          <Button size="sm" variant="warning float-right" disabled>
            Stage {dustBuster.stage}
          </Button>
        </Alert>
        <ListGroup variant="flush">
          <ListGroup.Item>Name: {dustBuster.name}</ListGroup.Item>
          <ListGroup.Item>Surname: {dustBuster.surname} </ListGroup.Item>
          <ListGroup.Item>ID Number: {dustBuster.idNum} </ListGroup.Item>
          <ListGroup.Item>Cellphone: {dustBuster.cellphone} </ListGroup.Item>
          <ListGroup.Item>Email: {dustBuster.email} </ListGroup.Item>
          <ListGroup.Item>Address: {dustBuster.address} </ListGroup.Item>
          <ListGroup.Item>Gender: {gender} </ListGroup.Item>
          <ListGroup.Item>Nationality: {nationality} </ListGroup.Item>
          <Button variant="info float-right mt-3" disabled>
            Interview date:{' '}
            {moment.utc(slot).format('dddd, MMMM Do YYYY, h:mm:ss a')}
          </Button>
          {missed === 'Future Interview' ? (
            <Button variant="info mt-3" disabled>
              Future Interview
            </Button>
          ) : missed === true ? (
            <Button variant="danger mt-3" disabled>
              Cleaner Missed the interview
            </Button>
          ) : (
            <Button variant="success mt-3" disabled>
              Cleaner Arrived for interview
            </Button>
          )}
        </ListGroup>
        {flags
          ? flags.length > 0 && (
              <Col>
                <Alert variant="warning mt-3">
                  Duster has flags
                  <i className="fab fa-font-awesome-flag float-right"></i>
                </Alert>
              </Col>
            )
          : ''}
        {flags && flags.map((flag) => <RedFlags flag={flag} key={flag} />)}
      </Col>
      <Col md={8} xs={12}>
        <Alert variant="secondary">
          Dust Buster work experience
          <Button
            variant="secondary float-right"
            size="sm"
            onClick={() => goBack()}
          >
            back
          </Button>
        </Alert>
        <ListGroup variant="flush">
          <ListGroup.Item>Work Experience: {exp} </ListGroup.Item>
        </ListGroup>
        <Row>
          <Col>
            <Alert variant="secondary">Reference 1</Alert>
            <ListGroup variant="flush">
              <ListGroup.Item>Name: {ref1.name1}</ListGroup.Item>
              <ListGroup.Item>Contact: {ref1.contact1}</ListGroup.Item>
              <ListGroup.Item>Duration: {ref1.duration1}</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <Alert variant="secondary">Reference 2</Alert>
            <ListGroup variant="flush">
              <ListGroup.Item>Name: {ref2.name2}</ListGroup.Item>
              <ListGroup.Item>Contact: {ref2.contact2}</ListGroup.Item>
              <ListGroup.Item>Duration: {ref2.duration2}</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <Alert variant="secondary mt-4">Type Of Cleaning</Alert>
        <ListGroup variant="flush">
          {office && <ListGroup.Item>Office cleaning</ListGroup.Item>}
          {hotel && <ListGroup.Item>Hotel cleaning</ListGroup.Item>}
          {home && <ListGroup.Item>Home cleaning</ListGroup.Item>}
          {medical && <ListGroup.Item>Medical cleaning</ListGroup.Item>}
          {eot && <ListGroup.Item>End of tenancy cleaning</ListGroup.Item>}
          {retail && <ListGroup.Item>Retail cleaning</ListGroup.Item>}
        </ListGroup>
        <Alert variant="secondary mt-4">Documentation</Alert>
        <Row>
          <Col>
            {uploads.idDoc ? (
              <Row className="text-center mb-2">
                <Col>
                  <Button
                    variant="outline-info"
                    onClick={() => navToId()}
                    block
                  >
                    View ID
                  </Button>
                  <Button variant="outline-warning" block>
                    Edit ID
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button variant="outline-secondary mb-2" block>
                Add ID
              </Button>
            )}
            {uploads.pic ? (
              <Row className="text-center mb-2">
                <Col>
                  <Button
                    variant="outline-info"
                    onClick={() => navToProPic()}
                    block
                  >
                    View pro pic
                  </Button>
                  <Button variant="outline-warning" block>
                    Edit pro pic
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button variant="outline-secondary mb-2" block>
                Add pro pic
              </Button>
            )}
            {uploads.bankLetter ? (
              <Row className="text-center">
                <Col>
                  <Button
                    variant="outline-info"
                    onClick={() => navToBankLetter()}
                    block
                  >
                    View bank letter
                  </Button>
                  <Button variant="outline-warning" block>
                    Edit bank letter
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button variant="outline-secondary" block>
                Add bank letter
              </Button>
            )}
          </Col>
          <Col>
            {uploads.poa ? (
              <Row className="text-center mb-2">
                <Col>
                  <Button
                    variant="outline-info"
                    onClick={() => navToPOA()}
                    block
                  >
                    View proof of address
                  </Button>
                  <Button variant="outline-warning" block>
                    Edit proof of address
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button variant="outline-secondary mb-2" block>
                Add Proof of address
              </Button>
            )}
            {uploads.workPermit ? (
              <Row className="text-center mb-2">
                <Col>
                  <Button
                    variant="outline-info"
                    onClick={() => navToWorkPermit()}
                    block
                  >
                    View work permit
                  </Button>
                  <Button variant="outline-warning" block>
                    Edit work permit
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button variant="outline-secondary" block>
                Add work permit
              </Button>
            )}
            {uploads.ec ? (
              <Row className="text-center">
                <Col>
                  <Button variant="outline-info" onClick={() => navToEC()}>
                    View employee contract
                  </Button>
                  <Button variant="outline-warning">
                    Edit employee contract
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button variant="outline-secondary" block>
                No EC
              </Button>
            )}
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Alert variant="success">
            Create password and approve {dustBuster.name}
          </Alert>
          <Modal.Body>
            Create Password:
            <InputGroup className="mb-3">
              <FormControl
                type={showPassword}
                placeholder="Create password for Duster"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Text>
                {showPassword === 'password' ? (
                  <i className="fas fa-eye-slash" onClick={togglePassword}></i>
                ) : (
                  <i
                    className="fas fa-eye text-success"
                    onClick={togglePassword}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>
            Confirm Password:
            <InputGroup className="mb-3">
              <FormControl
                type={showPassword}
                placeholder="Confirm password for Duster"
                value={password2}
                name="password2"
                onChange={(e) => setPassword2(e.target.value)}
              />
              <InputGroup.Text>
                {showPassword === 'password' ? (
                  <i className="fas fa-eye-slash" onClick={togglePassword}></i>
                ) : (
                  <i
                    className="fas fa-eye text-success"
                    onClick={togglePassword}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              <i className="far fa-window-close"></i>
            </Button>
            {password.length > 0 ? (
              password === password2 ? (
                <Button variant="success" onClick={confirmDuster}>
                  Approve Duster
                </Button>
              ) : (
                <Button variant="secondary" disabled>
                  Match Passwords
                </Button>
              )
            ) : (
              <Button variant="secondary" disabled>
                Match Passwords
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
  );
};

export default StageThree;
