import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MotherCard from './MotherCard';
import CarpetMother from './CarpetMother';
import DusterRate from './DusterRate';

const TemplateDashboard = () => {
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Row>
        <Col xs={1}>
          <i className="fa-solid fa-chevron-left" onClick={goBack}></i>{' '}
        </Col>
        <Col>
          <h4>Site Templates</h4>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-spray-can-sparkles"></i>
              </span>
              <Card.Title>Cleaning Pricing</Card.Title>

              <Card.Text>View or edit domestic cleaning pricing</Card.Text>
              <MotherCard />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {' '}
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-broom"></i>
              </span>
              <Card.Title>Carpet cleaning pricing</Card.Title>

              <Card.Text>View or edit carpet cleaning pricing</Card.Text>
              <CarpetMother />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {' '}
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-coins"></i>
              </span>
              <Card.Title>Duster Rate</Card.Title>

              <Card.Text>View or edit default Dust Buster rate</Card.Text>
              <DusterRate />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-spray-can-sparkles"></i>
              </span>
              <Card.Title>Epuipment</Card.Title>

              <Card.Text>View or equipment pricing</Card.Text>
              <Button size="sm" variant="outline-warning float-right">
                Check thsi out
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-broom"></i>
              </span>
              <Card.Title>Pest control pricing</Card.Title>

              <Card.Text>View or edit pest control pricing</Card.Text>
              <Button size="sm" variant="outline-warning float-right">
                Check thsi out
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-coins"></i>
              </span>
              <Card.Title>Hygiene</Card.Title>

              <Card.Text>View or edit Hygiene pricing</Card.Text>
              <Button size="sm" variant="outline-warning float-right">
                Check thsi out
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TemplateDashboard;
