import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, Alert, Button, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toComplete } from '../../../actions/admin/orders';
import ReOrderItems from './ReOrderItems';

function AllocatedReOrders({ toComplete, orders }) {
  useEffect(() => {
    toComplete();
  }, [toComplete]);
  const navigate = useNavigate();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    navigate('/admin/order-management');
  };
  return (
    <div className="container">
      <Alert variant="success text-center">
        <Button
          variant="outline-success float-left"
          size="sm"
          onClick={() => goback()}
        >
          Operations
        </Button>
        Repeat Orders with assigned dusters
      </Alert>
      <ListGroup variant="flush text-center">
        {orders.length === 0 ? (
          <Alert variant="success">Repeat Orders with assigned dusters</Alert>
        ) : (
          orders.map((order) => <ReOrderItems key={order._id} order={order} />)
        )}
      </ListGroup>
    </div>
  );
}

const mapStateToProps = (state) => ({
  orders: state.admin_orders.to_complete,
});

export default connect(mapStateToProps, { toComplete })(AllocatedReOrders);
