import React from 'react';
import Bottom from '../Layout/Bottom';

const HomeCleaning = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            Dusting Down: Effortless Cleaning at Your Fingertips
          </h3>
          <div>
            <p>
              <b>1. Customized Cleaning Made Easy:</b> Experience the
              convenience of Dusting Down's website and app, where you can
              effortlessly customize your cleaning requirements. Tailor the
              services to suit your specific needs and preferences with just a
              few taps or clicks.
            </p>
            <p>
              <b>2. Trustworthy Professionals, On-Demand:</b> With Dusting Down,
              you have access to a network of trusted and experienced cleaning
              professionals, available at your convenience. Our website and app
              connect you with reliable cleaners who are ready to tackle your
              cleaning tasks.
            </p>
            <p>
              <b>3. Seamless Booking Process:</b> Our user-friendly website and
              app make booking a breeze. Simply choose the date, time, and
              duration of your cleaning session, and Dusting Down will handle
              the rest. Say goodbye to phone calls and tedious scheduling.
            </p>
            <p>
              <b>4. Efficient Cleaning Services:</b> Our dedicated cleaning
              professionals follow a meticulous cleaning process, ensuring every
              nook and cranny is thoroughly cleaned. Dusting Down's platform
              empowers them with the tools and supplies they need to deliver
              efficient and top-quality cleaning results.
            </p>
            <p>
              <b>5. Transparent Communication:</b> Stay informed and connected
              with Dusting Down's platform. From real-time updates on your
              cleaning appointment to seamless communication with your assigned
              cleaner, our website and app facilitate transparent and
              hassle-free interactions.
            </p>
            <p>
              <b>6. Reliable and Secure:</b> Rest assured that Dusting Down
              prioritizes your safety and security. Our platform carefully vets
              and screens all cleaning professionals, providing you with peace
              of mind. Your personal information and payment details are handled
              with the utmost confidentiality and security.
            </p>
            <hr />
          </div>
        </div>
        <div className="container">
          <p>
            <b>Discover the Convenience: </b> Dusting Down's website and app
            revolutionize the way you access professional cleaning services.
            Enjoy the convenience of customizing your cleaning, seamless
            bookings, and transparent communication. Experience effortless
            cleaning with Dusting Down, right at your fingertips.
          </p>
        </div>
      </div>

      <Bottom />
    </>
  );
};

export default HomeCleaning;
