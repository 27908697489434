import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, ListGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  setOrderAddress,
  createTheOrder,
  createTheReOrder,
} from '../../../../actions/admin/order_for_client';
import UserAddressModal from './UserAddressModal';
import ExtraItem from './ExtraItem';
import moment from 'moment';
import Recap from './Recap';

const CheckOutForUser = ({
  note,
  admin_users: { user, address },
  order_for_client: {
    orderTime,
    text,
    coordinates,
    complex,
    addressType,
    bedroom,
    bathroom,
    orderHours,
    mrx,
    laundreyBasket,
    regular,
    regInvoice,
    invoice,
    schedule,
    frequency,
    how,
    weekdays,
    createdOrder,
    createdReOrder,
  },
  setOrderAddress,
  createTheOrder,
  createTheReOrder,
}) => {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (note.length > 0 && btn.current) {
      btn.current.removeAttribute('disabled');
    }
  }, [note]);
  useEffect(() => {
    if (createdOrder) {
      let orderId = createdOrder._id;
      navigate(`/admin/order-management/once-off/order/${orderId}`);
    } else if (createdReOrder) {
      let orderId = createdReOrder._id;
      navigate(`/admin/order-management/repeat-orders/order/${orderId}`);
    }
  }, [createdOrder, createdReOrder, navigate]);
  const { userId } = useParams();
  let btn = useRef();

  const goBack = () => {
    navigate(-1);
  };

  const changeOrderAddress = ({ text, complex, coordinates, addressType }) => {
    setOrderAddress({ text, complex, coordinates, addressType });
  };

  const orderForUser = () => {
    let time = orderTime;
    let invoiceHours = orderHours;
    let nityGrity = how;
    let address = text;

    if (regular) {
      createTheReOrder({
        time,
        address,
        coordinates,
        bedroom,
        bathroom,
        mrx,
        weekdays,
        invoiceHours,
        invoice,
        complex,
        nityGrity,
        schedule,
        frequency,
        laundreyBasket,
        userId,
      });
    } else {
      createTheOrder({
        time,
        address,
        coordinates,
        bedroom,
        bathroom,
        mrx,
        invoiceHours,
        invoice,
        complex,
        nityGrity,
        laundreyBasket,
        userId,
      });
    }

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div className="container">
      <Row>
        <Col xs={1}>
          <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
        </Col>
        <Col>
          Order summary for: {user && user.name} {user && user.surname}
        </Col>
        <Col>
          Order Hours :<b> {orderHours} hrs</b>
        </Col>
        <Col>
          Order Invoice :<b>{regular ? `R${regInvoice}` : `R${invoice}`} </b>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={8}>
          <b>
            Booking address: {text} - {addressType}
          </b>
        </Col>
        <Col xs={4}>
          <UserAddressModal
            user={user}
            address={address}
            changeOrderAddress={changeOrderAddress}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <h3 className="text-info text-center">Review and create booking</h3>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <p className="float-left">Order Date: </p>
              <p className="float-right">
                {moment(orderTime).format('MMMM Do YYYY, h:mm:ss a')}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left">
                {bedroom} Bedroom/s and {bathroom} Bathroom/s
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left"> Included Cost</p>
              <p className="float-right">R212.00</p>
            </ListGroup.Item>
            <ListGroup.Item align="center">
              <div className="float-left">Your Extras;</div>
              {mrx.length === 0 ? (
                <span>No Extras</span>
              ) : (
                mrx.map((x) => {
                  return (
                    <div key={x} style={{ width: '20rem' }}>
                      <ExtraItem x={x} />
                    </div>
                  );
                })
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left"> Service Fee </p>
              <p className="float-right">R35.00</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left">Total Time: </p>
              <p className="float-right">{orderHours} hours</p>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={6}>
          <Alert>Available Dust Busters</Alert>
          <h4>Coming Soon...</h4>
          <hr />
          {regular && (
            <>
              <h5 className="text-center mt-4">Appointment Summary:</h5>
              <Recap schedule={schedule} />
            </>
          )}
          <Button
            variant="outline-success mt-3"
            onClick={() => orderForUser()}
            ref={btn}
            block
          >
            Create the booking
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  admin_users: state.admin_users,
  order_for_client: state.order_for_client,
});

export default connect(mapStateToProps, {
  setOrderAddress,
  createTheOrder,
  createTheReOrder,
})(CheckOutForUser);
