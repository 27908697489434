import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const ResetUserPassword = ({ resetUserPassword, userId }) => {
  const [newPassword, setNewPassword] = useState('');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const resetPassword = () => {
    const password = newPassword;
    resetUserPassword({ password, userId });
  };

  return (
    <>
      <Button variant="outline-warning" onClick={handleShow}>
        Reset password
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Title>Reset Password</Modal.Title>
        <Modal.Body>
          <Form.Group>
            <Form.Label>New password: </Form.Label>
            <Form.Control
              type="text"
              placeholder={newPassword}
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => resetPassword()}>
            Reset
          </Button>
          <Button variant="outline-warning" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResetUserPassword;
