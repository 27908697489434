import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Spinner,
  Col,
  Alert,
  Container,
  Row,
  Button,
  Card,
  ListGroup,
} from 'react-bootstrap';
import {
  getSchedule,
  getBooking,
  getReBooking,
} from '../../actions/team/team_work';
import { proPic } from '../../actions/fileupload';
import { uploadProPic } from '../../actions/team/team_profile';
import Dropzone from 'react-dropzone';
import Calender from './Working/Calender';
import './DustBuster.css';

const DustBuster = ({
  work: { schedule },
  getSchedule,
  profile,
  duster,
  note,
  proPic,
  getBooking,
  getReBooking,
  uploadProPic,
  pp_uploaded,
}) => {
  const [msg, setMsg] = useState(note);
  const [mySchedule, setMySchedule] = useState([]);
  let PPbtn = useRef();
  let updBtn = useRef();

  useEffect(() => {
    getSchedule();
  }, [getSchedule]);

  useEffect(() => {
    if (schedule) {
      setMySchedule(schedule);
    }
  }, [schedule]);

  useEffect(() => {
    if (note && updBtn.current) {
      setMsg(note);
      updBtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  //Upload Profile Picture//
  useEffect(() => {
    if (note && PPbtn.current) {
      setMsg(note);
      PPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPP = (files) => {
    let formData = new FormData();

    formData.append('file', files[0]);

    proPic(formData);

    if (PPbtn.current) {
      PPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const updatePhoto = () => {
    let dusterId = duster._id;
    let pic = pp_uploaded;
    uploadProPic({ dusterId, pic });

    if (updBtn.current) {
      updBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (duster === null || profile === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  let adr = duster.address.split(',').shift();

  return (
    <Container>
      <Row>
        <Col align="center">
          <Card style={{ width: '20rem' }}>
            <Card.Img variant="top" src={`${profile.pic}`} alt="No Picture" />
            <Col>
              {pp_uploaded ? (
                <Button
                  variant="outline-info float-right"
                  size="sm"
                  onClick={updatePhoto}
                  ref={updBtn}
                >
                  Update Photo
                </Button>
              ) : (
                <Dropzone onDrop={uploadPP}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="light float-right "
                      {...getRootProps()}
                      ref={PPbtn}
                      size="sm"
                    >
                      <input {...getInputProps()} />
                      <i className="fas fa-portrait"></i>
                    </Button>
                  )}
                </Dropzone>
              )}
            </Col>
            <Card.Body>
              <Card.Title className="text-center">
                {duster.name} {duster.surname}
              </Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>ID Number: {duster.idNum}</ListGroup.Item>
                <ListGroup.Item>Address: {adr}</ListGroup.Item>
                <ListGroup.Item>Email: {duster.email}</ListGroup.Item>
                <ListGroup.Item>Cellphone: {duster.cellphone}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Alert variant="primary text-center">Your Calender</Alert>
          <Calender
            mySchedule={mySchedule}
            getBooking={getBooking}
            getReBooking={getReBooking}
          />
          <Row>
            <Button variant="danger mt-3" block>
              Sick Days
            </Button>
            <Button variant="secondary" block>
              Book Leave
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  note: state.note,
  work: state.team_work,
  profile: state.team_profile.profile,
  duster: state.team.team,
  pp_uploaded: state.file.pp_uploaded,
});
export default connect(mapStateToProps, {
  getSchedule,
  getBooking,
  getReBooking,
  proPic,
  uploadProPic,
})(DustBuster);
