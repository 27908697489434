import React from 'react';
import { ListGroup } from 'react-bootstrap';

const CarpertCleaningReview = ({ rev }) => {
  return (
    <ListGroup.Item>
      <p className="float-left"># {rev.name}</p>
      <p className="float-right">{rev.num}</p>
    </ListGroup.Item>
  );
};

export default CarpertCleaningReview;
