import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Calendar from './Calendar';

const ReSchedule = ({
  tomorrow,
  setPrettyDay,
  setSelectedDay,
  prettyDay,
  selectedDay,
  orderId,
  changeDate,
  invoiceHours,
  duster,
}) => {
  const [calendar, setCalender] = useState(false);

  return (
    <>
      {calendar === true ? (
        <>
          <Button
            variant="warning"
            onClick={() => setCalender(!calendar)}
            block
          >
            Cancel
          </Button>
          <Calendar
            tomorrow={tomorrow}
            setPrettyDay={setPrettyDay}
            setSelectedDay={setSelectedDay}
            prettyDay={prettyDay}
            selectedDay={selectedDay}
            orderId={orderId}
            changeDate={changeDate}
            duster={duster}
            invoiceHours={invoiceHours}
          />
        </>
      ) : (
        <Button variant="info" onClick={() => setCalender(!calendar)} block>
          Re-schedule
        </Button>
      )}
    </>
  );
};

export default ReSchedule;
