import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

const CarpertCleaningDateAndShift = ({
  carpert_cleaning_date,
  morning_shift,
  toggleCarpertShift,
  setStage,
  tomorrow,
  selectCarpertCleaningDate,
  changeCleaningDate,
}) => {
  return (
    <div>
      {carpert_cleaning_date ? (
        <Row className="mb-3">
          <Col>
            <h5 className="text-center">
              Team will arrive{' '}
              {moment(carpert_cleaning_date).format('MMMM Do YYYY')}
            </h5>
          </Col>
          <Col>
            <Button
              variant="warning float-right"
              size="sm"
              onClick={changeCleaningDate}
            >
              Change date
            </Button>
          </Col>
          <hr />
        </Row>
      ) : (
        <>
          <h4 className="text-center text-info">
            When would like the team to arrive?
          </h4>
          <hr />
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
            initialView="dayGridMonth"
            dateClick={selectCarpertCleaningDate}
            validRange={{ start: tomorrow }}
            selectable={true}
          />
        </>
      )}
      <Row>
        <h4 className="text-center text-info">
          Select a carpert Cleaning Slot
        </h4>
        <hr />
        <Col xs={6}>
          <Button
            variant={morning_shift ? 'info' : 'outline-secondary'}
            block
            onClick={() => toggleCarpertShift(true)}
          >
            Morning Shift
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            variant={morning_shift === false ? 'info' : 'outline-secondary'}
            block
            onClick={() => toggleCarpertShift(false)}
          >
            Afternoon Shift
          </Button>
        </Col>

        {carpert_cleaning_date && morning_shift !== null ? (
          <Button
            variant="outline-info mt-3"
            id="next"
            onClick={() => setStage(3)}
          >
            Next
          </Button>
        ) : (
          <Button variant="outline-secondary mt-3" id="next" disabled>
            Select a carpert cleaning date & slot
          </Button>
        )}
      </Row>
    </div>
  );
};

export default CarpertCleaningDateAndShift;
