import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

const MattressModal = ({
  single_mattress,
  double_mattress,
  queen_mattress,
  king_mattress,
  plusSingleMattress,
  minusSingleMattress,
  plusDoubleMattress,
  minusDoubleMatress,
  plusQueenMattress,
  minusQueenMattress,
  plusKingMattress,
  minusKingMattress,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Button onClick={handleShow} block variant="outline-info">
        Mattress
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mattress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={9}>
              <b>Single :</b> {single_mattress}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusSingleMattress}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusSingleMattress}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>Double : </b> {double_mattress}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusDoubleMatress}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusDoubleMattress}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>Queen : </b> {queen_mattress}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusQueenMattress}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusQueenMattress}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>King : </b> {king_mattress}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusKingMattress}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusKingMattress}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MattressModal;
