import React from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';

const SelectAddressItem = ({ userAdr, selectBookingAddress }) => {
  const pickMe = () => {
    let text = userAdr.text;
    let complex = userAdr.complex;
    let coordinates = userAdr.coordinates;
    let addressType = userAdr.addressType;
    selectBookingAddress({ text, complex, coordinates, addressType });
  };
  return (
    <>
      <ListGroup.Item>
        <Row>
          <Col xs={10}>
            {userAdr.text} - {userAdr.addressType}
            {userAdr.addressType !== 'House' && ` - ${userAdr.complex}`}
          </Col>
          <Col xs={2}>
            <Button variant="outline-info" onClick={() => pickMe()}>
              <i className="fa-solid fa-chevron-right"></i>
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    </>
  );
};

export default SelectAddressItem;
