import {
  ADD_SKILLS,
  MYSKILLS,
  SKILLS_ERROR,
  CLEAR_SKILLS,
} from '../../actions/types';

const initState = {
  skills: null,
  loadingSkills: true,
};

function teamSkillsReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_SKILLS:
      return {
        ...state,
        skills: payload,
        loadingSkills: false,
      };
    case MYSKILLS:
      return {
        ...state,
        skills: payload,
        loadingSkills: false,
      };
    case SKILLS_ERROR:
      return {
        ...state,
        loadingSkills: false,
      };
    case CLEAR_SKILLS:
      return {
        ...state,
        skills: null,
        loadingSkills: true,
      };

    default:
      return state;
  }
}

export default teamSkillsReducer;
