import { MY_INVOICE, MY_ORDER_INV } from '../../actions/types';

const initialState = {
  order: null,
  invoice: null,
};

function invoiceReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MY_ORDER_INV:
      return {
        ...state,
        order: payload,
      };
    case MY_INVOICE:
      return {
        ...state,
        invoice: payload,
      };

    default:
      return state;
  }
}

export default invoiceReducer;
