import { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Container } from 'react-bootstrap';
import { resendEmailVerification } from '../../../actions/user';
import './Auth.css';

const VerifyEmail = ({ verifyEmail, resendEmailVerification, note }) => {
  const [msg, setMsg] = useState(note);

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);
  let btn = useRef();

  const sendAgain = () => {
    let email = verifyEmail;
    resendEmailVerification({ email });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <Container>
      <Card id="verifyEmail" style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>
            Please verify your email address; {verifyEmail}
          </Card.Title>
          <Card.Text>
            We have sent you an email to <b>{verifyEmail}</b> . please check
            your spam just in case. If you have not received an email please
            resend verification email
          </Card.Text>
          <Button variant="primary" ref={btn} onClick={sendAgain}>
            Re-send email verification
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  verifyEmail: state.user.verifyEmail,
  note: state.note,
});

export default connect(mapStateToProps, { resendEmailVerification })(
  VerifyEmail
);
