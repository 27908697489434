import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';
import moment from 'moment';

const ReOrderItems = ({ order }) => {
  let address = order.address.split(',');
  const int = order.checkIn.length;
  const nextCheckIn = order.schedule[int];

  if (nextCheckIn) {
    const date = nextCheckIn.start;
    let time = moment(date).format('ddd MMM D, h:mm a');

    return (
      <div>
        <ListGroup.Item>
          Address: {address[0]} {address[1]}{' '}
          {order.complex && `| ${order.complex}`} - Next check in: {time}
          <Button
            as={Link}
            to={`/admin/order-management/repeat-orders/order/${order._id}`}
            variant="outline-primary float-right"
            size="sm"
          >
            View Order
          </Button>
        </ListGroup.Item>
      </div>
    );
  } else {
    return (
      <div>
        <ListGroup.Item>
          Address: {address[0]} {address[1]}{' '}
          {order.complex && `| ${order.complex}`} - All check ins complete
          <Button
            as={Link}
            to={`/admin/order-management/repeat-orders/order/${order._id}`}
            variant="outline-primary float-right"
            size="sm"
          >
            View Order
          </Button>
        </ListGroup.Item>
      </div>
    );
  }
};

export default ReOrderItems;
