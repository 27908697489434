import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getCoupons, createCoupon } from '../../../../../actions/admin/coupon';
import {
  Alert,
  Button,
  Container,
  Spinner,
  Modal,
  Form,
  Row,
  Col,
  Table,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CouponItem from './CouponItem';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../DustBusters/AdminDusters.css';

const CouponPage = ({
  coupon: { coupons, loading },
  getCoupons,
  createCoupon,
  note,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [code, setCode] = useState('');
  const [fourPer, setFourPer] = useState(0);
  const [fivePer, setFivePer] = useState(0);
  const [sixPer, setSixPer] = useState(0);
  const [sevenPer, setSevenPer] = useState(0);
  const [eightPer, setEightPer] = useState(0);
  const [onceOff, setOnceOff] = useState(true);
  const [existingClient, setExisting] = useState(true);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();

  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const createCouponPage = () => {
    navigate('/admin/create-coupon');
  };
  useEffect(() => {
    if (note && btn.current) {
      setCode('');
      setFourPer(0);
      setFivePer(0);
      setSixPer(0);
      setSevenPer(0);
      setEightPer(0);
      handleClose();
    }
  }, [note]);
  useEffect(() => {
    getCoupons();
  }, [getCoupons]);

  if (loading) return <Spinner animation="grow" variant="primary" />;

  const jamCoupon = () => {
    if (code.length < 5) {
      setError('Enter a valid code');
      return setTimeout(() => setError(null), 2000);
    } else if (fourPer === 0) {
      setError('Enter 4 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (fivePer === 0) {
      setError('Enter 5 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sixPer === 0) {
      setError('Enter 6 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sevenPer === 0) {
      setError('Enter 7 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (eightPer === 0) {
      setError('Enter 8 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else {
      const expirydate = startDate;
      const coupon = code;
      const percArray = {
        fourPer: fourPer,
        fivePer: fivePer,
        sixPer: sixPer,
        sevenPer: sevenPer,
        eightPer: eightPer,
      };
      createCoupon({ expirydate, coupon, percArray, onceOff, existingClient });
      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };
  return (
    <Container>
      <Row>
        <Col>
          {' '}
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>{' '}
          <h4>Client coupon page</h4>
        </Col>
        <Col>
          <Button
            size="sm"
            className="float-right"
            variant="warning text-white"
            onClick={createCouponPage}
          >
            Create coupon
          </Button>
        </Col>
      </Row>
      <hr />

      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>Code</th>
            <th>Created</th>
            <th>Client</th>
            <th>Expiry date</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {coupons === null ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            coupons.map((coupon) => (
              <CouponItem key={coupon._id} coupon={coupon} />
            ))
          )}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Alert variant="success">Create a coupon</Alert>
        <Modal.Body>
          {error && <Alert variant="warning">{error}</Alert>}
          <Form.Group>
            <Row>
              <Col xs={4}>
                <Form.Label className="mt-2">Coupon Code</Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  type="text"
                  placeholder="Enter coupon code"
                  name="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Col>
            </Row>
          </Form.Group>
          <hr />
          <h5>Enter discount %</h5>
          <Row>
            <Col>4 hrs</Col>
            <Col>5 hrs</Col>
            <Col>6 hrs</Col>
            <Col>7 hrs</Col>
            <Col>8 hrs</Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder=" %"
                  name="fourPer"
                  value={fourPer}
                  onChange={(e) => setFourPer(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder=" %"
                  name="fivePer"
                  value={fivePer}
                  onChange={(e) => setFivePer(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder=" %"
                  name="sixPer"
                  value={sixPer}
                  onChange={(e) => setSixPer(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder=" %"
                  name="sevenPer"
                  value={sevenPer}
                  onChange={(e) => setSevenPer(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder=" %"
                  name="eightPer"
                  value={eightPer}
                  onChange={(e) => setEightPer(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={4}>
              <b className="mt-3">Expiry Date: </b>
            </Col>
            <Col xs={8}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Type of clean: </Col>
            <Col>
              <Button
                variant={onceOff ? 'primary mr-4' : 'outline-secondary mr-4'}
                onClick={() => setOnceOff(true)}
              >
                Once Off
              </Button>
            </Col>
            <Col>
              <Button
                variant={onceOff === false ? 'info' : 'outline-secondary'}
                onClick={() => setOnceOff(false)}
              >
                Repeat Clean
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Type of client: </Col>
            <Col>
              <Button
                variant={
                  existingClient === false
                    ? 'primary mr-4'
                    : 'outline-secondary mr-4'
                }
                onClick={() => setExisting(false)}
              >
                New client
              </Button>
            </Col>
            <Col>
              <Button
                variant={existingClient ? 'info' : 'outline-secondary'}
                onClick={() => setExisting(true)}
              >
                Existing client
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </Button>
          <Button variant="primary" onClick={jamCoupon} ref={btn}>
            Create coupon
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  coupon: state.admin_coupons,
  note: state.note,
});

export default connect(mapStateToProps, { getCoupons, createCoupon })(
  CouponPage
);
