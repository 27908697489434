import React from 'react';
import { ListGroup } from 'react-bootstrap';

const whatToDo = ({ todo }) => {
  return (
    <ListGroup.Item>
      <p className="float-left"># {todo.name}</p>
      <p className="float-right">{todo.num}</p>
    </ListGroup.Item>
  );
};

export default whatToDo;
