import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import OnceOff from './OnceOff';
import Regular from './Regular';

const Step2 = ({ setStep }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [regular, setRegular] = useState(false);
  const [tomorrow, setTomorrow] = useState(null);
  const [prettyDay, setPrettyDay] = useState(null);
  const [arrivalDay, setArrivalDay] = useState(null);
  const [arriveNow, setArriveNow] = useState(null);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(8);
    setTomorrow(date);
  }, [setTomorrow]);

  return (
    <div>
      <h4 className="text-center">
        How often would you like to schedule a clean?
      </h4>
      <Row>
        <Col className="text-center">
          <Button
            variant={regular ? 'outline-secondary' : 'info'}
            onClick={() => setRegular(false)}
          >
            Once Off
          </Button>
          <Button
            variant={regular ? 'info' : 'outline-secondary'}
            onClick={() => setRegular(true)}
          >
            Regular
          </Button>
        </Col>
      </Row>
      {regular ? (
        <Regular
          tomorrow={tomorrow}
          regular={regular}
          prettyDay={prettyDay}
          setArrivalDay={setArrivalDay}
          setPrettyDay={setPrettyDay}
          arrivalDay={arrivalDay}
          setArriveNow={setArriveNow}
          setStep={setStep}
        />
      ) : (
        <OnceOff
          tomorrow={tomorrow}
          regular={regular}
          prettyDay={prettyDay}
          setArrivalDay={setArrivalDay}
          setPrettyDay={setPrettyDay}
          arrivalDay={arrivalDay}
          setArriveNow={setArriveNow}
          arriveNow={arriveNow}
          setStep={setStep}
        />
      )}
    </div>
  );
};

export default Step2;
