import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

const SofaModal = ({
  tub_chair,
  wingback,
  one_seater_sofa,
  two_seater_sofa,
  three_seater_sofa,
  three_seater_el,
  four_seater_el,
  five_seater_el,
  plusTubChair,
  minusTubChair,
  plusWingback,
  minusWingback,
  plusOneSeaterSofa,
  minusOneSeaterSofa,
  plusTwoSeaterSofa,
  minusTwoSeaterSofa,
  plusThreeSeaterSofa,
  minusThreeSeaterSofa,
  plusThreeSeaterEl,
  minusthreeSeaterEl,
  plusFourSeaterEl,
  minusFourSeaterEl,
  plusFiveSeaterEl,
  minusFiveSeaterEl,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Button onClick={handleShow} block variant="outline-info">
        Sofas
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sofas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={9}>
              <b> Tub Chair : </b>
              {tub_chair}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusTubChair}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusTubChair}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>Wingback : </b> {wingback}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusWingback}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusWingback}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>1 Seater Sofa : </b> {one_seater_sofa}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusOneSeaterSofa}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusOneSeaterSofa}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b> 2-Seater sofa : </b> {two_seater_sofa}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusTwoSeaterSofa}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusTwoSeaterSofa}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b> 3-Seater sofa : </b> {three_seater_sofa}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusThreeSeaterSofa}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusThreeSeaterSofa}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b> L Shaped 3-Seater : </b> {three_seater_el}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusthreeSeaterEl}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusThreeSeaterEl}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>L Shaped 4-seater : </b> {four_seater_el}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusFourSeaterEl}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusFourSeaterEl}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>L shaped 5-seater : </b> {five_seater_el}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusFiveSeaterEl}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusFiveSeaterEl}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SofaModal;
