import axios from 'axios';
import { SKILLS_ERROR, MYSKILLS, ADD_SKILLS, CLEAR_SKILLS } from '../types';
import { setNote } from '../note';

//////////////////////////////
//Add Skills to your Profile//
//////////////////////////////
export const SKILLS =
  ({ residential, warehouse, office, medical, retail, carpert, ref1, ref2 }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      residential,
      warehouse,
      office,
      medical,
      retail,
      carpert,
      ref1,
      ref2,
    });
    try {
      const res = await axios.post('/api/team_skills/add', body, config);
      dispatch({ type: ADD_SKILLS, payload: res.data });
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
      dispatch({ type: SKILLS_ERROR });
    }
  };

/////////////////////
//Fetch your skills//
/////////////////////
export const getSkills = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_SKILLS });
    const res = await axios.get('/api/team_skills/my_skills');
    dispatch({ type: MYSKILLS, payload: res.data });
  } catch (err) {
    dispatch({
      type: SKILLS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//////////////////////
//Fix  Reference 1////
//////////////////////
export const fixReference1 =
  ({ ref1 }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ ref1 });
    try {
      const res = await axios.put(
        '/api/team_skills/fix-reference1',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////
//Fix  Reference 2////
//////////////////////
export const fixReference2 =
  ({ ref2 }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ ref2 });
    try {
      const res = await axios.put(
        '/api/team_skills/fix-reference2',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };
