import axios from 'axios';
import { BUSINESS_LOGIN, BUSINESS_LOADED } from '../types';
import { setNote } from '../note';
import setUserToken from '../../utils/setUserToken';

//Get current business//
export const getCurrentBusiness = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setUserToken(localStorage.token);
    }
    const res = await axios.get('/api/business');
    dispatch({ type: BUSINESS_LOADED, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//sign up business//
export const signupBusiness = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify(formData);
    const res = await axios.post('/api/business/sign-up', body, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};

//verify business//
export const verifyBusiness = (token) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/business/verify-business/${token}`);
    const msgs = res.data.message;

    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};

//sign in business//
export const signInBusiness = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/business/sign-in', body, config);
    dispatch({ type: BUSINESS_LOGIN, payload: res.data });
    dispatch(getCurrentBusiness());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};

//request business password reset//
export const requestPasswordReset =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email });
    try {
      const res = await axios.post(
        '/api/business/forgot-password',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Reset the password//
export const businessPasswordReset =
  ({ token, newPassword }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ token, newPassword });
    try {
      const res = await axios.post(
        `/api/business/reset-password/${token}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };
