const {
  CARPERT_CLEANING_INVOICE,
  SWITCH_TO_CARPERT_CLEANING,
  GET_CARPERT_CLEAN,
  JAM_MAIN_BEDROOM,
  JAM_BEDROOM,
  JAM_LONG_PASSAGE,
  JAM_SHORT_PASSAGE,
  JAM_STAIRCASE,
  JAM_LANDING,
  JAM_FAMILY_ROOM,
  JAM_LOUNGE,
  JAM_DINING_ROOM,
  JAM_STUDY,
  JAM_DRESSING_ROOM,
  JAM_OTHER_ROOM,
  JAM_SMALL_RUG,
  JAM_MEDIUM_RUG,
  JAM_LARGE_RUG,
  JAM_TUB_CHAIR,
  JAM_ONESEATER_SOFA,
  JAM_THREESEATER_EL,
  JAM_FOURSEATER_EL,
  JAM_FIVESEATER_EL,
  JAM_SINGLE_MATTRESS,
  JAM_DOUBLE_MATTRESS,
  JAM_QUEEN_MATTRESS,
  JAM_KING_MATTRESS,
  JAM_SMALL_OTTOMAN,
  JAM_MEDIUM_OTTOMAN,
  JAM_LARGE_OTTOMAN,
  JAM_CHAIRS_BASE_ONLY,
  JAM_CHAIRS_BASE_BACK_ARMS,
  JAM_WINGBACK,
  JAM_TWOSEATER_SOFA,
  JAM_THREESEATER_SOFA,
  JAM_CARPERT_SHIFT,
  JAM_CARPERT_CLEANING_DATE,
  JAM_CARPERT_GET_IN,
  JAM_CARPERT_ADDRESS,
  JAM_CARPERT_ADDRESS_TYPE,
  JAM_CARPERT_COMLEX,
  JAM_CARPERT_COORDINATES,
  GET_MY_CARPERT_CLEANS,
  CARPET_MOTHER,
} = require('../../actions/types');

const initState = {
  jamming: false,
  carpert_clean: null,
  time: null,
  carpert_address: null,
  carpert_address_type: null,
  carpert_complex: null,
  carpert_coordinates: null,
  carpert_invoice: 250,
  minimum_call_out: 995,
  address: null,
  coordinates: null,
  complex: null,
  addressType: null,
  main_bedroom: 0,
  bedroom: 0,
  stair_case: 0,
  landing: 0,
  long_passage: 0,
  short_passage: 0,
  family_room: 0,
  lounge: 0,
  dining_room: 0,
  study: 0,
  dressing_room: 0,
  other_room: 0,
  small_rug: 0,
  med_rug: 0,
  large_rug: 0,
  tub_chair: 0,
  wingback: 0,
  one_seater_sofa: 0,
  two_seater_sofa: 0,
  three_seater_sofa: 0,
  three_seater_el: 0,
  four_seater_el: 0,
  five_seater_el: 0,
  single_mattress: 0,
  double_mattress: 0,
  queen_mattress: 0,
  king_mattress: 0,
  small_ottoman: 0,
  medium_ottoman: 0,
  large_ottoman: 0,
  dining_chair_base_only: 0,
  dining_chair_base_back_arms: 0,
  morning_shift: null,
  carpert_cleaning_date: null,
  carpert_nity: null,
  my_carpert_cleans: null,
  carpet_mother: null,
};

function carpertCleaningReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case CARPET_MOTHER:
      return {
        ...state,
        carpet_mother: payload,
      };
    case SWITCH_TO_CARPERT_CLEANING:
      return {
        ...state,
        jamming: payload,
      };
    case JAM_CARPERT_ADDRESS:
      return {
        ...state,
        carpert_address: payload,
      };
    case JAM_CARPERT_ADDRESS_TYPE:
      return {
        ...state,
        carpert_address_type: payload,
      };
    case JAM_CARPERT_COMLEX:
      return {
        ...state,
        carpert_complex: payload,
      };
    case JAM_CARPERT_COORDINATES:
      return {
        ...state,
        carpert_coordinates: payload,
      };
    case CARPERT_CLEANING_INVOICE:
      return {
        ...state,
        carpert_invoice: payload,
      };
    case JAM_MAIN_BEDROOM:
      return {
        ...state,
        main_bedroom: payload,
      };
    case JAM_BEDROOM:
      return {
        ...state,
        bedroom: payload,
      };
    case JAM_LONG_PASSAGE:
      return {
        ...state,
        long_passage: payload,
      };
    case JAM_SHORT_PASSAGE:
      return {
        ...state,
        short_passage: payload,
      };
    case JAM_STAIRCASE:
      return {
        ...state,
        stair_case: payload,
      };
    case JAM_LANDING:
      return {
        ...state,
        landing: payload,
      };
    case JAM_FAMILY_ROOM:
      return {
        ...state,
        family_room: payload,
      };
    case JAM_LOUNGE:
      return {
        ...state,
        lounge: payload,
      };
    case JAM_DINING_ROOM:
      return {
        ...state,
        dining_room: payload,
      };
    case JAM_STUDY:
      return {
        ...state,
        study: payload,
      };
    case JAM_DRESSING_ROOM:
      return {
        ...state,
        dressing_room: payload,
      };
    case JAM_OTHER_ROOM:
      return {
        ...state,
        other_room: payload,
      };
    case JAM_SMALL_RUG:
      return {
        ...state,
        small_rug: payload,
      };
    case JAM_MEDIUM_RUG:
      return {
        ...state,
        med_rug: payload,
      };
    case JAM_LARGE_RUG:
      return {
        ...state,
        large_rug: payload,
      };
    case JAM_TUB_CHAIR:
      return {
        ...state,
        tub_chair: payload,
      };
    case JAM_WINGBACK:
      return {
        ...state,
        wingback: payload,
      };
    case JAM_ONESEATER_SOFA:
      return {
        ...state,
        one_seater_sofa: payload,
      };
    case JAM_TWOSEATER_SOFA:
      return {
        ...state,
        two_seater_sofa: payload,
      };
    case JAM_THREESEATER_SOFA:
      return {
        ...state,
        three_seater_sofa: payload,
      };
    case JAM_THREESEATER_EL:
      return {
        ...state,
        three_seater_el: payload,
      };
    case JAM_FOURSEATER_EL:
      return {
        ...state,
        four_seater_el: payload,
      };
    case JAM_FIVESEATER_EL:
      return {
        ...state,
        five_seater_el: payload,
      };
    case JAM_SINGLE_MATTRESS:
      return {
        ...state,
        single_mattress: payload,
      };
    case JAM_DOUBLE_MATTRESS:
      return {
        ...state,
        double_mattress: payload,
      };
    case JAM_QUEEN_MATTRESS:
      return {
        ...state,
        queen_mattress: payload,
      };
    case JAM_KING_MATTRESS:
      return {
        ...state,
        king_mattress: payload,
      };
    case JAM_SMALL_OTTOMAN:
      return {
        ...state,
        small_ottoman: payload,
      };
    case JAM_MEDIUM_OTTOMAN:
      return {
        ...state,
        medium_ottoman: payload,
      };
    case JAM_LARGE_OTTOMAN:
      return {
        ...state,
        large_ottoman: payload,
      };
    case JAM_CHAIRS_BASE_ONLY:
      return {
        ...state,
        dining_chair_base_only: payload,
      };
    case JAM_CHAIRS_BASE_BACK_ARMS:
      return {
        ...state,
        dining_chair_base_back_arms: payload,
      };
    case GET_CARPERT_CLEAN:
      return {
        ...state,
        carpert_clean: payload,
      };
    case JAM_CARPERT_SHIFT:
      return {
        ...state,
        morning_shift: payload,
      };
    case JAM_CARPERT_CLEANING_DATE:
      return {
        ...state,
        carpert_cleaning_date: payload,
      };
    case JAM_CARPERT_GET_IN:
      return {
        ...state,
        carpert_nity: payload,
      };
    case GET_MY_CARPERT_CLEANS:
      return {
        ...state,
        my_carpert_cleans: payload,
      };
    default:
      return state;
  }
}

export default carpertCleaningReducer;
