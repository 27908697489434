import axios from 'axios';
import {
  CARPERT_CLEANING_INVOICE,
  SWITCH_TO_CARPERT_CLEANING,
  GET_CARPERT_CLEAN,
  JAM_MAIN_BEDROOM,
  JAM_BEDROOM,
  JAM_LONG_PASSAGE,
  JAM_SHORT_PASSAGE,
  JAM_STAIRCASE,
  JAM_LANDING,
  JAM_FAMILY_ROOM,
  JAM_LOUNGE,
  JAM_DINING_ROOM,
  JAM_STUDY,
  JAM_DRESSING_ROOM,
  JAM_OTHER_ROOM,
  JAM_SMALL_RUG,
  JAM_MEDIUM_RUG,
  JAM_LARGE_RUG,
  JAM_TUB_CHAIR,
  JAM_ONESEATER_SOFA,
  JAM_THREESEATER_EL,
  JAM_FOURSEATER_EL,
  JAM_FIVESEATER_EL,
  JAM_SINGLE_MATTRESS,
  JAM_DOUBLE_MATTRESS,
  JAM_QUEEN_MATTRESS,
  JAM_KING_MATTRESS,
  JAM_SMALL_OTTOMAN,
  JAM_MEDIUM_OTTOMAN,
  JAM_LARGE_OTTOMAN,
  JAM_CHAIRS_BASE_ONLY,
  JAM_CHAIRS_BASE_BACK_ARMS,
  JAM_WINGBACK,
  JAM_TWOSEATER_SOFA,
  JAM_THREESEATER_SOFA,
  JAM_CARPERT_SHIFT,
  JAM_CARPERT_CLEANING_DATE,
  JAM_CARPERT_GET_IN,
  JAM_CARPERT_ADDRESS,
  JAM_CARPERT_ADDRESS_TYPE,
  JAM_CARPERT_COMLEX,
  JAM_CARPERT_COORDINATES,
  GET_MY_CARPERT_CLEANS,
  CARPET_MOTHER,
} from '../types';

export const toggleCarpetCleaning = (carpert) => (dispatch) => {
  try {
    dispatch({ type: SWITCH_TO_CARPERT_CLEANING, payload: carpert });
  } catch (error) {
    console.error(error.message);
  }
};

//Jam Carpert Adddess//
export const jamCarpertCleanLocation =
  ({ address, addressType, complex, coordinates }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_CARPERT_ADDRESS, payload: address });
      dispatch({ type: JAM_CARPERT_ADDRESS_TYPE, payload: addressType });
      dispatch({ type: JAM_CARPERT_COMLEX, payload: complex });
      dispatch({ type: JAM_CARPERT_COORDINATES, payload: coordinates });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam main b1edroom//
export const jamMainBedroom =
  ({ mainBed, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_MAIN_BEDROOM, payload: mainBed });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam bedroom//
export const jamBed =
  ({ bed, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_BEDROOM, payload: bed });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam long passgae//
export const jamLongPassage =
  ({ longPassage, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_LONG_PASSAGE, payload: longPassage });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam short passage//
export const jamShortPassage =
  ({ shortPassage, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_SHORT_PASSAGE, payload: shortPassage });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam staicase//
export const jamStairCase =
  ({ staircase, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_STAIRCASE, payload: staircase });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Landing//
export const jamLanding =
  ({ land, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_LANDING, payload: land });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Family room//
export const jamFamilyRoom =
  ({ familyRoom, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_FAMILY_ROOM, payload: familyRoom });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Lounge//
export const jamLounge =
  ({ loung, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_LOUNGE, payload: loung });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Dining room//
export const jamDiningRoom =
  ({ diningRoom, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_DINING_ROOM, payload: diningRoom });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Study
export const jamStudy =
  ({ stud, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_STUDY, payload: stud });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam dressing room//
export const jamDressingRoom =
  ({ dressingRoom, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_DRESSING_ROOM, payload: dressingRoom });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//jam other room//
export const jamOtherRoom =
  ({ otherRoom, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_OTHER_ROOM, payload: otherRoom });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam small rug//
export const jamSmallRug =
  ({ smallRug, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_SMALL_RUG, payload: smallRug });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam medium rug//
export const jamMediumRug =
  ({ mediumRug, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_MEDIUM_RUG, payload: mediumRug });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

// Jam large rug//
export const jamLargeRug =
  ({ largeRug, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_LARGE_RUG, payload: largeRug });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam tub chair//
export const jamTubChair =
  ({ tubChair, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_TUB_CHAIR, payload: tubChair });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//jam wingback//
export const jamWingback =
  ({ wing, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_WINGBACK, payload: wing });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam one seater sofa//
export const jamOneSeaterSofa =
  ({ oneSeaterSofa, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_ONESEATER_SOFA, payload: oneSeaterSofa });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam two seater sofa//
export const jamTwoSeaterSofa =
  ({ twoSeaterSofa, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_TWOSEATER_SOFA, payload: twoSeaterSofa });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam three seater sofa//
export const jamThreeSeaterSofa =
  ({ threeSeaterSofa, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_THREESEATER_SOFA, payload: threeSeaterSofa });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam three seater el//
export const jamThreeSeaterEl =
  ({ threeSeaterEl, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_THREESEATER_EL, payload: threeSeaterEl });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam four seater el//
export const jamFourSeaterEl =
  ({ fourSeaterEl, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_FOURSEATER_EL, payload: fourSeaterEl });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam five seater el//
export const jamFiveSeaterEl =
  ({ fiveSeaterEl, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_FIVESEATER_EL, payload: fiveSeaterEl });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam single matterss/
export const jamSingleMattress =
  ({ singleMattress, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_SINGLE_MATTRESS, payload: singleMattress });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam double mattress/
export const jamDoubleMattress =
  ({ doubleMattress, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_DOUBLE_MATTRESS, payload: doubleMattress });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam queen mattress/
export const jamQueenMattress =
  ({ queenMattress, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_QUEEN_MATTRESS, payload: queenMattress });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam king mattress//
export const jamKingMattress =
  ({ kingMattress, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_KING_MATTRESS, payload: kingMattress });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam small ottoman//
export const jamSmallOttoman =
  ({ smallOttoman, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_SMALL_OTTOMAN, payload: smallOttoman });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam medium ottoman//
export const jamMediumOttoman =
  ({ mediumOttoman, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_MEDIUM_OTTOMAN, payload: mediumOttoman });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam large ottman//
export const jamLargeOttoman =
  ({ largeOttoman, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_LARGE_OTTOMAN, payload: largeOttoman });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam chairs base only//
export const jamChairsBaseOnly =
  ({ chairBase, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_CHAIRS_BASE_ONLY, payload: chairBase });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam chairs everything//
export const jamChairsEverything =
  ({ chairsEverything, invoice }) =>
  (dispatch) => {
    try {
      dispatch({ type: JAM_CHAIRS_BASE_BACK_ARMS, payload: chairsEverything });
      dispatch({ type: CARPERT_CLEANING_INVOICE, payload: invoice });
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam carpert cleaning date//
export const jamCarpertCleaningDate = (date) => (dispatch) => {
  try {
    dispatch({ type: JAM_CARPERT_CLEANING_DATE, payload: date });
  } catch (error) {
    console.error(error.message);
  }
};

//Jam carpert schedule//
export const jamCarpertShift = (shift) => (dispatch) => {
  try {
    dispatch({ type: JAM_CARPERT_SHIFT, payload: shift });
  } catch (error) {
    console.error(error.message);
  }
};

//Jam carpert get in//
export const jamCarpertGetIn = (nityGrity) => (dispatch) => {
  try {
    dispatch({ type: JAM_CARPERT_GET_IN, payload: nityGrity });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Carpet Mother//
export const getCarpertMother = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/carpert-cleaning/get-carpet-mother');
    console.log(res.data);
    dispatch({ type: CARPET_MOTHER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Create carpert clean//
export const createCarpertClean =
  ({
    address,
    coordinates,
    complex,
    addressType,
    carpert_cleaning_date,
    morning_shift,
    carpert_nity,
    whatToDo,
    invoice,
    paymentMethod,
    from,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      address,
      coordinates,
      complex,
      addressType,
      carpert_cleaning_date,
      morning_shift,
      carpert_nity,
      whatToDo,
      invoice,
      paymentMethod,
      from,
    });
    try {
      const res = await axios.post(
        '/api/user/carpert-cleaning/create-carpert-clean',
        body,
        config
      );
      console.log('Order from backend: ', res.data);
      dispatch({ type: GET_CARPERT_CLEAN, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Get user carpert clean//
export const getMyCarpertCleans = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/user/carpert-cleaning/get-my-carpert-cleans/`
    );
    dispatch({ type: GET_MY_CARPERT_CLEANS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get the carpert clean//
export const getTheCarpertClean = (carpertId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/user/carpert-cleaning/get-carpert-clean/${carpertId}`
    );
    dispatch({ type: GET_CARPERT_CLEAN, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Check for payment status//
export const checkPaymentStatus =
  ({ id, carpertId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      carpertId,
    });
    try {
      const res = await axios.put(
        `/api/user/payments/carpert-peach-status/${id}`,
        body,
        config
      );
      dispatch({ type: GET_CARPERT_CLEAN, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };
