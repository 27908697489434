import axios from 'axios';
import { ADMIN_CLEAR_FILE, CLEAR_DUSTERS } from '../types';
import { getUploads, fetchSkills } from './team_members';
import { setNote } from '../note';
import { getDuster } from './team_members';

//Set the team member as local or foreign//
export const setLocal =
  ({ citizen, dusterId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ citizen, dusterId });
    try {
      await axios.put('/api/admin/team-admin/citizenship', body, config);
      dispatch(setNote('Duster Citizenship has been set', 'success'));
      dispatch(getUploads(dusterId));
      dispatch(fetchSkills(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Add stevey duster//
/////////////////////
export const adHocDuster =
  ({
    name,
    surname,
    email,
    cellphone,
    address,
    coordinates,
    password,
    female,
    local,
    idNum,
    daily,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      name,
      surname,
      email,
      cellphone,
      address,
      coordinates,
      password,
      female,
      local,
      idNum,
      daily,
    });
    try {
      const res = await axios.post(
        '/api/admin/team-admin/adhoc-duster',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//add a duster//
export const addDuster =
  ({
    name,
    surname,
    email,
    cellphone,
    address,
    coordinates,
    password,
    female,
    daily,
    local,
    idNum,
    id_uploaded,
    poa_uploaded,
    bl_uploaded,
    pp_uploaded,
    ec_uploaded,
    wp_uploaded,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      name,
      surname,
      email,
      cellphone,
      address,
      coordinates,
      password,
      female,
      daily,
      local,
      idNum,
      id_uploaded,
      poa_uploaded,
      bl_uploaded,
      pp_uploaded,
      ec_uploaded,
      wp_uploaded,
    });
    try {
      const res = await axios.post(
        '/api/admin/team-admin/add-dust-buster',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch({ type: ADMIN_CLEAR_FILE });
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Add a dust buster profile//
export const addDusterProfile =
  ({
    poa_uploaded,
    id_uploaded,
    bl_uploaded,
    pp_uploaded,
    ec_uploaded,
    wp_uploaded,
    dusterId,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      poa_uploaded,
      id_uploaded,
      bl_uploaded,
      pp_uploaded,
      ec_uploaded,
      wp_uploaded,
      dusterId,
    });
    try {
      const res = await axios.post(
        '/api/admin/team-admin/add-duster-profile',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getUploads(dusterId));
      dispatch(fetchSkills(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Update Duster Profile
export const updateDusterUploads =
  ({ idDoc, poa, bankLetter, pic, workPermit, ec, dusterId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      idDoc,
      poa,
      bankLetter,
      pic,
      workPermit,
      ec,
    });
    try {
      await axios.put(
        `/api/admin/team-admin/update-uploads/${dusterId}`,
        body,
        config
      );
      dispatch(getUploads(dusterId));
      dispatch(fetchSkills(dusterId));
      dispatch(setNote('Profile Successfully Updated!', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Add Duster Skills & References//
export const ADD_SKILLS =
  ({
    dusterId,
    residential,
    warehouse,
    office,
    medical,
    retail,
    carpert,
    ref1,
    ref2,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      dusterId,
      residential,
      warehouse,
      office,
      medical,
      retail,
      carpert,
      ref1,
      ref2,
    });
    try {
      const res = await axios.post(
        '/api/admin/team-admin/add-skills-ref',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getUploads(dusterId));
      dispatch(fetchSkills(dusterId));
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

export const dusterUpdate =
  ({
    dusterId,
    name,
    surname,
    email,
    cellphone,
    idNum,
    address,
    coordinates,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      name,
      surname,
      email,
      cellphone,
      idNum,
      address,
      coordinates,
      dusterId,
    });
    try {
      const res = await axios.put(
        `/api/admin/team-admin/update-duster/${dusterId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(getDuster(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////
//Delete Duster//
////////////////
export const deleteDuster = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `/api/admin/team-admin/delete-duster/${dusterId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////
//Delete ID document reference//
////////////////////////////////
export const deleteID = (teamId) => async (dispatch) => {
  try {
    await axios.put(`/api/admin/team-admin/idDoc/${teamId}`);
    dispatch(setNote('ID deleted successfully', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////
//Delete Proof Of Address//
///////////////////////////
export const deletePOA = (teamId) => async (dispatch) => {
  try {
    await axios.put(`/api/admin/team-admin/poa/${teamId}`);
    dispatch(setNote('Proof of address deleted successfully', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////
//Delete Bank Letter document reference//
////////////////////////////////
export const deleteBL = (teamId) => async (dispatch) => {
  try {
    await axios.put(`/api/admin/team-admin/bank-letter/${teamId}`);
    dispatch(setNote('Bank letter deleted successfully', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////
//Delete WP document reference//
////////////////////////////////
export const deleteWP = (teamId) => async (dispatch) => {
  try {
    await axios.put(`/api/admin/team-admin/work-permit/${teamId}`);
    dispatch(setNote('Work Permit deleted successfully', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////
//Delete Pro Pic reference//
////////////////////////////
export const deletePP = (teamId) => async (dispatch) => {
  try {
    await axios.put(`/api/admin/team-admin/pro-pic/${teamId}`);
    dispatch(setNote('Profile Picture deleted successfully', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////
//Delete Pro Pic reference//
////////////////////////////
export const deleteEC = (teamId) => async (dispatch) => {
  try {
    await axios.put(`/api/admin/team-admin/employment/${teamId}`);
    dispatch(setNote('Profile Picture deleted successfully', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////
// Approve Dust Buster//
////////////////////////
export const readyToWork =
  ({ dusterId, password }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_DUSTERS });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ password });
    try {
      const res = await axios.post(
        `/api/admin/team-admin/ready-for-work/${dusterId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch({ type: ADMIN_CLEAR_FILE });
      dispatch(getDuster(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };
////////////////////
//False Reference1//
////////////////////
export const falseRef1 = (teamId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/admin/team-admin/false-ref1/${teamId}`);

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
//False Reference2//
////////////////////
export const falseRef2 = (teamId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/admin/team-admin/false-ref2/${teamId}`);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////////////////
///Send Email Verification Reminder//
/////////////////////////////////////
export const sendEmail = (teamId) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.put(
      `/api/admin/team-admin/verify-email/${teamId}`,
      config
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////
//Reject Application//
//////////////////////
export const rejectApp =
  ({ teamId, emailBody }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ emailBody });
    try {
      const res = await axios.put(
        `/api/admin/team-admin/reject-app/${teamId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getUploads(teamId));
      dispatch(fetchSkills(teamId));
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////////////
//Let Move On From Stage One//
/////////////////////////////
export const completeApp =
  ({ dusterId }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_DUSTERS });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ dusterId });
    try {
      const res = await axios.post(
        '/api/admin/team-admin/complete-application',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getDuster(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Change Duster Type//
export const changeType =
  ({ dusterId, changeTo }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ changeTo });
    try {
      const res = await axios.put(
        `/api/admin/team-admin/change-duster-type/${dusterId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getDuster(dusterId));
    } catch (error) {
      console.error(error.message);
    }
  };
