import React from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import Schedule from './Schedule';
import Calender from './Calender';

const NewDateAndTime = ({
  regular,
  hideRegular,
  showRegular,
  daysLeft,
  schedule,
  weekdays,
  invoiceHours,
  days,
  Setdays,
  once,
  twice,
  three,
  full,
  open,
  setOpen,
  SetMon,
  Mon,
  SetTue,
  Tue,
  SetWed,
  Wed,
  SetThu,
  Thu,
  SetFri,
  Fri,
  SetSat,
  Sat,
  SetSun,
  Sun,
  OnceAWeek,
  SetOnceAWeek,
  TwiceAWeek,
  SetTwiceAWeek,
  ThreeAWeek,
  SetThreeAWeek,
  FullTime,
  SetFullTime,
  orderTime,
  setDate,
  arrivalTime,
  setArrivalTime,
  setStage,
  tomorrow,
  setSelectedDay,
  selectedDay,
  setPrettyDay,
  prettyDay,
  frequency,
  setNote,
  setDaysLeft,
  repeatCalender,
}) => {
  return (
    <div className="mt-4">
      <h4 className="text-center text-info mt-3">Once Off or Regular?</h4>

      <Row>
        <Col className="text-center">
          <Button
            variant={regular ? 'outline-secondary' : 'info'}
            onClick={hideRegular}
          >
            Once Off
          </Button>
          <Button
            variant={regular ? 'info' : 'outline-secondary'}
            onClick={showRegular}
            data-toggle="collapse"
          >
            Regular
          </Button>
        </Col>
      </Row>

      {regular ? (
        <>
          <Schedule
            daysLeft={daysLeft}
            schedule={schedule}
            weekdays={weekdays}
            invoiceHours={invoiceHours}
            days={days}
            Setdays={Setdays}
            once={once}
            twice={twice}
            three={three}
            full={full}
            open={open}
            regular={regular}
            setOpen={setOpen}
            SetMon={SetMon}
            Mon={Mon}
            SetTue={SetTue}
            Tue={Tue}
            SetWed={SetWed}
            Wed={Wed}
            SetThu={SetThu}
            Thu={Thu}
            SetFri={SetFri}
            Fri={Fri}
            SetSat={SetSat}
            Sat={Sat}
            SetSun={SetSun}
            Sun={Sun}
            OnceAWeek={OnceAWeek}
            SetOnceAWeek={SetOnceAWeek}
            TwiceAWeek={TwiceAWeek}
            SetTwiceAWeek={SetTwiceAWeek}
            ThreeAWeek={ThreeAWeek}
            SetThreeAWeek={SetThreeAWeek}
            FullTime={FullTime}
            SetFullTime={SetFullTime}
          />
          {frequency === weekdays.length && (
            <>
              <Alert variant="warning text-center mt-4">
                <i className="fas fa-info-circle mr-4"></i>
                Based on cleaning days selected when would you like the 1st
                clean to be?
              </Alert>
              <Calender
                setDate={setDate}
                arrivalTime={arrivalTime}
                setArrivalTime={setArrivalTime}
                regular={regular}
                weekdays={weekdays}
                setNote={setNote}
                setDaysLeft={setDaysLeft}
                daysLeft={daysLeft}
                invoiceHours={invoiceHours}
                repeatCalender={repeatCalender}
                schedule={schedule}
                setStage={setStage}
                tomorrow={tomorrow}
                orderTime={orderTime}
                setSelectedDay={setSelectedDay}
                selectedDay={selectedDay}
                setPrettyDay={setPrettyDay}
                prettyDay={prettyDay}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Alert variant="warning text-center mt-3">
            When would you like the cleaner to arrive?
          </Alert>
          <Calender
            orderTime={orderTime}
            setDate={setDate}
            arrivalTime={arrivalTime}
            setArrivalTime={setArrivalTime}
            regular={regular}
            schedule={schedule}
            setStage={setStage}
            tomorrow={tomorrow}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
            setPrettyDay={setPrettyDay}
            prettyDay={prettyDay}
            invoiceHours={invoiceHours}
          />
        </>
      )}
    </div>
  );
};

export default NewDateAndTime;
