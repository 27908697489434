import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { editRateCard } from '../../../../actions/admin/main';
import { Button, Modal, Form } from 'react-bootstrap';

const EditRateCard = ({ currentUser, rate, editRateCard, userId }) => {
  const [show, setShow] = useState(false);
  const [four, SetUserFour] = useState('');
  const [five, SetUserFive] = useState('');
  const [six, SetUserSix] = useState('');
  const [seven, SetUserSeven] = useState('');
  const [eight, SetUserEight] = useState('');
  const [nine, SetUserNine] = useState('');
  const [ten, SetUserTen] = useState('');
  const [rateCard, SetRateCard] = useState('');

  useEffect(() => {
    if (rate) {
      SetRateCard(rate._id);
      SetUserFour(rate.four);
      SetUserFive(rate.five);
      SetUserSix(rate.six);
      SetUserSeven(rate.seven);
      SetUserEight(rate.eight);
      SetUserNine(rate.nine);
      SetUserTen(rate.ten);
    }
  }, [rate]);

  const editCard = (e) => {
    e.preventDefault();
    editRateCard({ four, five, six, seven, eight, nine, ten, rateCard });
    setShow(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button variant="primary mt-3" onClick={handleShow} block>
        Edit Custom Rate Card
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={editCard}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Rate Card for {currentUser}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>4 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={four}
                name="four"
                value={four}
                onChange={(e) => SetUserFour(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={five}
                name="five"
                value={five}
                onChange={(e) => SetUserFive(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>6 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={six}
                name="six"
                value={six}
                onChange={(e) => SetUserSix(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>7 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={seven}
                name="seven"
                value={seven}
                onChange={(e) => SetUserSeven(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>8 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={eight}
                name="eight"
                value={eight}
                onChange={(e) => SetUserEight(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>9 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={nine}
                name="nine"
                value={nine}
                onChange={(e) => SetUserNine(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>10 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={ten}
                name="ten"
                value={ten}
                onChange={(e) => SetUserTen(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Edit Custom Rate
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rate: state.main.clientRate,
});

export default connect(mapStateToProps, { editRateCard })(EditRateCard);
