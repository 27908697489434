import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';

const CheckInCleaner = ({ note, orderTime, orderId, dusterCheckIn }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const checkIn = () => {
    let time = orderTime;
    dusterCheckIn({ orderId, time });
  };
  return (
    <>
      <Button variant="info float-right" size="sm" onClick={handleShow}>
        Check-in the duster <i className="fas fa-exclamation-triangle"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Duster will be checked-out at:{' '}
          <Button variant="info float-right" size="sm">
            {moment(orderTime).format('ddd MMM D, h:mm a')}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            <i className="fas fa-times-circle"></i>
          </Button>
          <Button variant="primary" onClick={checkIn}>
            Check-in Duster
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CheckInCleaner;
