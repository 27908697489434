import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getDuster, dusterOrders } from '../../../actions/admin/team_members';

import { Container, Spinner, Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import CleanItem from './CleanItem';

const DusterOrders = ({
  getDuster,
  dusterOrders,
  duster: { dustBuster, orders },
}) => {
  const { dusterId } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    getDuster(dusterId);
    dusterOrders(dusterId);
  }, [dusterId, getDuster, dusterOrders]);

  if (dustBuster === null) {
    <Container>
      <Spinner variant="grow" animation="grow" />
    </Container>;
  }

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h5>Once time bookings</h5>
      <hr />
      <Table>
        <thead className="thead-dark">
          <tr>
            <th>Client Name</th>
            <th>Booking Date</th>
            <th>Address</th>
            <th>Booking Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {orders.length === 0 ? (
            <tr>
              <td>No recent orders</td>
            </tr>
          ) : (
            orders.map((o) => <CleanItem key={o._id} o={o} />)
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  duster: state.team_members,
});

export default connect(mapStateToProps, { getDuster, dusterOrders })(
  DusterOrders
);
