import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

const OttomanModal = ({
  small_ottoman,
  medium_ottoman,
  large_ottoman,
  plusSmallOttman,
  minusSmallOttoman,
  plusMediumOttoman,
  minusMediumOttoman,
  plusLargeOttoman,
  minusLargeOttman,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Button onClick={handleShow} block variant="outline-info">
        Ottoman
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ottoman</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={9}>
              <b>Small : </b> {small_ottoman}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusSmallOttoman}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusSmallOttman}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>Medium : </b> {medium_ottoman}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusMediumOttoman}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusMediumOttoman}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <b>Large : </b> {large_ottoman}
            </Col>
            <Col xs={1}>
              <Button variant="outline-info" onClick={minusLargeOttman}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
            <Col xs={1}>
              <Button variant="outline-info ml-2" onClick={plusLargeOttoman}>
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OttomanModal;
