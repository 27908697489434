import React from 'react';
import Calender from './Calender';
import { Button, Alert } from 'react-bootstrap';
import moment from 'moment';

const OnceOff = ({
  tomorrow,
  setPrettyDay,
  regular,
  prettyDay,
  setArrivalDay,
  arrivalDay,
  setArriveNow,
  arriveNow,
  setStep,
}) => {
  const reset = () => {
    setArriveNow(null);
    setArrivalDay(null);
    setPrettyDay(null);
  };
  return (
    <div>
      {arriveNow ? (
        <>
          <Alert variant="warning mt-3">
            You have chosen{' '}
            {moment(arriveNow.toString()).format('ddd MMM D, h:mm a')} as you
            cleaning date
            <Button size="sm" variant="secondary float-right" onClick={reset}>
              Change date
            </Button>
          </Alert>
          <Button variant="outline-info mt-3" onClick={() => setStep(3)} block>
            Next <i className="fa-solid fa-angle-right ml-5"></i>
          </Button>
        </>
      ) : (
        <>
          <h4 className="text-center mt-4">
            When would you like the cleaner to arrive
          </h4>
          <Calender
            tomorrow={tomorrow}
            setPrettyDay={setPrettyDay}
            regular={regular}
            prettyDay={prettyDay}
            setArrivalDay={setArrivalDay}
            arrivalDay={arrivalDay}
            setArriveNow={setArriveNow}
          />
        </>
      )}
    </div>
  );
};

export default OnceOff;
