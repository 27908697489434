import { combineReducers } from 'redux';
import note from './note';
import team from './team';
import team_profile from './team/team_profile';
import team_skills from './team/team_skills';
import team_work from './team/team_work';
import user from './user';
import file from './fileupload';
import adminUpload from './admin/adminUpload';
import admin from './admin';
import team_members from './admin/team_members';
import admin_users from './admin/admin_user';
import bookings from './user/bookings';
import orders from './user/orders';
import coupons from './user/coupons';
import main from './admin/main';
import animate from './animate';
import user_reviews from './user/reviews';
import invoice from './user/invoice';
import duster_reviews from './team/review';
import admin_orders from './admin/admin_orders';
import admin_queries from './admin/admin_queries';
import business from './business';
import user_queries from './user/query';
import carpert_cleaning from './user/carpert_cleaning';
import admin_coupons from './admin/admin_coupons';
import order_for_client from './admin/order_for_client';
import admin_carpert_cleans from './admin/carpert_cleaning';
import affiliate from './admin/affiliate';
import clientOfficeBooking from './admin/office_booking';
export default combineReducers({
  note,
  team,
  team_profile,
  team_skills,
  team_work,
  user,
  file,
  adminUpload,
  admin,
  team_members,
  admin_users,
  bookings,
  orders,
  invoice,
  coupons,
  main,
  animate,
  duster_reviews,
  user_reviews,
  admin_orders,
  admin_queries,
  business,
  user_queries,
  admin_coupons,
  order_for_client,
  carpert_cleaning,
  admin_carpert_cleans,
  affiliate,
  clientOfficeBooking,
});
