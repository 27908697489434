import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { createAffiliate } from '../../../actions/admin/affiliate';
import { Button, Form, Modal } from 'react-bootstrap';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';

const AddAffiliate = ({ createAffiliate, note }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0 && btn.current) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [coupon, setCoupon] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  let btn = useRef();

  const addNewAffiliate = () => {
    createAffiliate({
      name,
      email,
      coupon,
      password,
      address,
      coordinates,
    });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <>
      <Button size="sm" onClick={handleShow}>
        Create Affiliate
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Affiliate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Affiliate Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Affiliate Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Affiliate Coupon</Form.Label>
            <Form.Control
              type="text"
              name="coupon"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Affiliate Password</Form.Label>
            <Form.Control
              type="text"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Affiliate Address</Form.Label>
            <AddressAutocomplete
              value={address}
              setAddress={setAddress}
              setCoordinates={setCoordinates}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button ref={btn} onClick={addNewAffiliate}>
            Create Affiliate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, { createAffiliate })(AddAffiliate);
