import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  ListGroup,
  Spinner,
  Card,
  Button,
  Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
  GetReOrder,
  fetchDuster,
  cleanDuster,
  delReOrder,
  reComment,
  fetchReComments,
} from '../../../actions/user/order';
import { peachPayment } from '../../../actions/user/payment';
import { getInvoice } from '../../../actions/user/invoice';
import { reOrderQueries } from '../../../actions/user/query';
import moment from 'moment';
import ExtraItem from '../Booking/Payments/ExtraItem';
import Bottom from '../../Layout/Bottom';
import Schedule from './Schedule';
import CompletedClean from './CompletedClean';
import DeleteReOrder from './DeleteReOrder';
import AddReComment from './AddReComment';
import CreateReQuery from './CreateReQuery';

const MyReOrder = ({
  GetReOrder,
  orders: { repeatOrder, bookedCleaner, peachLoad, paymentUrl, re_comments },
  fetchDuster,
  cleanDuster,
  delReOrder,
  note,
  peachPayment,
  reComment,
  fetchReComments,
  getInvoice,
  invoice,
  reOrderQueries,
  queries,
}) => {
  const { orderId } = useParams();
  useEffect(() => {
    GetReOrder(orderId);
    fetchReComments(orderId);
    getInvoice(orderId);
    reOrderQueries(orderId);
  }, [GetReOrder, fetchReComments, reOrderQueries, getInvoice, orderId]);
  const [show, setShow] = useState(false);
  const [nte, setMsg] = useState(note);
  const [mrx, SetMrx] = useState([]);
  const [it, SetIT] = useState('');
  const [bed, SetBed] = useState('');
  const [bath, SetBath] = useState('');
  const [inv, SetIV] = useState('');
  const [invoiceHours, SetIH] = useState('');
  const [code, SetCode] = useState('');
  const [address, SetAdress] = useState('');
  const [complex, SetComplex] = useState(null);
  const [cleans, SetCleans] = useState('');
  const [weekdays, SetWeekDays] = useState([]);
  const [schedule, SetSchedule] = useState([]);
  const [checkedIn, SetCheckedIn] = useState([]);
  const [whatsLeft, SetWhatsLeft] = useState(null);
  const [checkedOut, SetCheckedOut] = useState([]);
  const [complete, SetComplete] = useState(null);
  const [remove, setRemove] = useState(false);
  const [completedCleans, setCompletedCleans] = useState(null);
  const [assigned, SetAssigned] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [paymentRef, setPaymentRef] = useState(null);
  // let btn = useRef();
  let peachBtn = useRef();
  let delBtn = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  });

  useEffect(() => {
    if (peachLoad) {
      const script = document.createElement('script');
      script.src = `https://${process.env.REACT_APP_PEACH_BASE_URL}/v1/paymentWidgets.js?checkoutId=${peachLoad.id}`;
      document.body.append(script);

      window.scrollTo(0, 0);
      setShow(true);
    }
  }, [peachLoad]);

  useEffect(() => {
    if (note && delBtn.current) {
      setMsg(note);
      delBtn.current.removeAttribute('disabled');
      navigate('/user/my-profile');
    }
  }, [note, nte, setMsg, navigate]);

  useEffect(() => {
    if (it) {
      let tomorrow = moment().add(1, 'day').local().format();
      if (tomorrow < it) {
        setRemove(true);
      }
    }
    return () => {
      setRemove(false);
    };
  }, [it, assigned]);

  useEffect(() => {
    if (repeatOrder) {
      SetMrx(repeatOrder.mrx);
      SetIT(repeatOrder.firstDate);
      SetBath(repeatOrder.bathroom);
      SetBed(repeatOrder.bedroom);
      SetAdress(repeatOrder.address);
      SetComplex(repeatOrder.complex);
      SetCode(repeatOrder.code);
      SetIV(repeatOrder.invoice);
      SetIH(repeatOrder.invoiceHours);
      SetCleans(repeatOrder.cleans);
      SetWeekDays(repeatOrder.weekdays);
      SetSchedule(repeatOrder.schedule);
      SetAssigned(repeatOrder.team);
      SetCheckedIn(repeatOrder.checkIn);
      SetCheckedOut(repeatOrder.checkOut);
      setCompletedCleans(checkedOut.length);
      setMonthly(repeatOrder.monthEnd);
      setPaymentRef(repeatOrder.paymentRef);
    }
  }, [repeatOrder, checkedOut.length]);

  useEffect(() => {
    if (assigned) {
      let dusterId = assigned;
      fetchDuster(dusterId);
    }
    return () => {
      cleanDuster(null);
    };
  }, [assigned, fetchDuster, cleanDuster]);

  useEffect(() => {
    let whatsLeft = cleans - completedCleans;
    SetWhatsLeft(whatsLeft);
  }, [cleans, completedCleans]);

  const invoiceTime = moment(it).format('ddd MMM D, h:mm a');

  if (repeatOrder === null || re_comments === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  // //Try Ozow Payment//
  // const ozow = () => {
  //   let _id = orderId;
  //   let invoice = inv;

  //   ozowPay({ _id, invoice });
  //   if (btn.current) {
  //     btn.current.setAttribute('disabled', 'disabled');
  //   }
  // };

  //Try Peach Payment//
  const createPeach = () => {
    let amount = parseFloat(inv).toFixed(2);
    peachPayment({ amount, orderId });
    if (peachBtn.current) {
      peachBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const delOrder = () => {
    delReOrder(orderId);
  };
  const where = address.split(',');

  return (
    <div>
      <Modal show={show} backdrop="static" keyboard={false} autoFocus={true}>
        <Modal.Body>
          <form
            action={`${process.env.REACT_APP_BASE_URL}payments/card-response/`}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </Modal.Body>
      </Modal>
      <Container>
        <Row>
          <Col>
            <Alert variant="primary">
              <h5>
                Thank you for your order
                {!code ? (
                  <DeleteReOrder delOrder={delOrder} delBtn={delBtn} />
                ) : invoice ? (
                  <Button
                    as={Link}
                    to={`/user/my-order-invoice/${orderId}`}
                    variant="info float-right"
                    size="sm"
                  >
                    Invoice
                  </Button>
                ) : (
                  ''
                )}
              </h5>
            </Alert>
            <ListGroup variant="flush" className="mt-2">
              <ListGroup.Item variant="info">
                {invoiceHours}hr Order Summary!
                <p className="float-right">initial Date: {invoiceTime}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                {bed} Bedroom/s {bath} Bathroom/s
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div
                        className="float-right"
                        key={x.id}
                        style={{ width: '20rem' }}
                      >
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                Address:{' '}
                <p className="float-right">
                  {where[0]} {where[1]} {complex && `| ${complex}`}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  The order has {cleans} cleans weekly on
                  {weekdays &&
                    weekdays.map((w) => <div className="float-right">{w}</div>)}
                </b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                On these dates:
                <hr style={{ width: '10rem' }} />
                {schedule &&
                  schedule.map((s) => <Schedule key={s.start} s={s} />)}
              </ListGroup.Item>
              <ListGroup.Item variant="warning">
                Invoice Total; <b className="float-right">R{inv}</b>
              </ListGroup.Item>
            </ListGroup>
            <CreateReQuery reOrderId={orderId} />
            {queries && queries.length === 0 ? (
              <p className="text-center mt-2">No queries</p>
            ) : (
              queries.map((query) => (
                <Button
                  variant="outline-warning mt-2"
                  key={query._id}
                  as={Link}
                  to={`/user/order-query/${query._id}`}
                  block
                >
                  {query.headline}
                </Button>
              ))
            )}
            <AddReComment reComment={reComment} orderId={orderId} note={note} />
            {re_comments && re_comments.length === 0 ? (
              <p className="text-center mt-2">No instructions</p>
            ) : (
              re_comments.map((comment) => (
                <ListGroup.Item className="mt-2">{comment.text}</ListGroup.Item>
              ))
            )}
          </Col>
          <Col align="center">
            {code ? (
              <>
                {monthly && paymentRef === undefined ? (
                  <Card style={{ width: '20rem' }}>
                    <Alert variant="info">Make payment for your order</Alert>
                    <Card.Body>
                      <b>***Please Note***</b>
                      <p>
                        You will be re-directed when you select a payment
                        method.
                      </p>
                      {/* <Button variant="success" onClick={ozow} ref={btn} block>
                        Ozow Instant EFT R{inv}
                      </Button> */}
                      <Button
                        variant="success mt-2"
                        onClick={createPeach}
                        ref={peachBtn}
                        block
                      >
                        Secure Card Payment R{inv}
                      </Button>
                    </Card.Body>
                  </Card>
                ) : monthly === undefined && paymentRef === undefined ? (
                  <Card style={{ width: '20rem' }}>
                    <Alert variant="info">Make payment for your order</Alert>
                    <Card.Body>
                      <b>***Please Note***</b>
                      <p>
                        You will be re-directed when you select a payment
                        method.
                      </p>
                      {/* <Button variant="success" onClick={ozow} ref={btn} block>
                        Ozow Instant EFT R{inv}
                      </Button> */}
                      <Button
                        variant="success mt-2"
                        onClick={createPeach}
                        ref={peachBtn}
                        block
                      >
                        Secure Card Payment R{inv}
                      </Button>
                    </Card.Body>
                  </Card>
                ) : null}
                <Card style={{ width: '15rem', marginTop: '5%' }}>
                  <Card.Img variant="top" src={code} />
                  <Card.Body>
                    <Card.Title className="text-center">
                      Please download the qr code
                    </Card.Title>
                    <Button variant="primary" onClick={downloadCode}>
                      Download!
                    </Button>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <Card style={{ width: '20rem' }}>
                <Alert variant="danger">Your payment failed!</Alert>
                <Card.Body>
                  <Card.Title className="text-center">
                    <Alert variant="warning">Please try pay again</Alert>
                  </Card.Title>
                  <hr />
                  <b>***Please Note***</b>
                  <p>
                    You will be re-directed when you select a payment method.
                  </p>
                  {/* <Button variant="success" onClick={ozow} ref={btn} block>
                    Ozow Instant EFT R{inv}
                  </Button> */}
                  <Button
                    variant="success mt-2"
                    onClick={createPeach}
                    ref={peachBtn}
                    block
                  >
                    Secure Card Payment R{inv}
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
          {bookedCleaner ? (
            <Col>
              <Alert variant="info">
                Assigned Duster
                <i className="fas fa-user-check float-right"></i>
              </Alert>
              <ListGroup>
                <ListGroup.Item>
                  <b> Duster Name:</b>
                  <p className="float-right">
                    {bookedCleaner.name}
                    {bookedCleaner.surname}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Star Rating: </b>
                  <p className="float-right">3 Star Duster</p>
                </ListGroup.Item>
                <ListGroup.Item className="text-center">
                  {whatsLeft} Clean/s left on the schedule
                </ListGroup.Item>
              </ListGroup>
              {checkedIn.length > checkedOut.length && (
                <ListGroup.Item align="center">
                  <Button variant="warning" size="sm" disabled>
                    Cleaning In Progress...
                  </Button>
                </ListGroup.Item>
              )}
              {checkedOut.length > 0 &&
                checkedOut.map((c) => <CompletedClean key={c} c={c} />)}
              {complete && 'Order marked as complete'}
            </Col>
          ) : (
            <Col>
              <Alert variant="warning text-center">Cleaner not assgned</Alert>
            </Col>
          )}
        </Row>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  note: state.note,
  invoice: state.invoice.invoice,
  queries: state.user_queries.queries,
});

export default connect(mapStateToProps, {
  GetReOrder,
  fetchDuster,
  cleanDuster,
  delReOrder,
  peachPayment,
  reComment,
  fetchReComments,
  getInvoice,
  reOrderQueries,
})(MyReOrder);
