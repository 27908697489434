import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const AddReComment = ({ reComment, orderId, note }) => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  let btn = useRef();
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitInstr = async (e) => {
    e.preventDefault();
    let reOrder = orderId;
    reComment({ reOrder, text });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
    setText('');
  };

  return (
    <div className="text-center mt-2">
      <>
        <Button variant="primary" onClick={handleShow}>
          Add instructions...
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Instructions...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              as="textarea"
              name="instruction"
              onChange={(e) => setText(e.target.value)}
              value={text}
              placeholder="Type here..."
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {text.length > 4 ? (
              <Button variant="success" onClick={submitInstr} ref={btn}>
                Submit
              </Button>
            ) : (
              <Button variant="light" disabled>
                Type instruction
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default AddReComment;
