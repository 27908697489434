import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { adminLogin } from '../../actions/admin/';

const AdminSignIn = ({ isAdmin, adminLogin, note }) => {
  const [nte, setMsg] = useState(note);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  let btn = useRef();

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    adminLogin({ email, password });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (isAdmin) {
    return <Navigate to="/admin/dashboard" />;
  }
  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <Card className="card bg-primary text-white mb-2" align="center">
            <Card.Body>
              <Card.Title>Welcome To Dusting Down</Card.Title>
              <Card.Subtitle>Sign in to view the dashboard.</Card.Subtitle>
              <span style={{ fontSize: '7em' }}>
                <i className="fas fa-users-cog"></i>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card.Header className="text-white bg-primary">
            <Row>
              <Col>
                <h5 align="center">Admin Sign In:</h5>
              </Col>
            </Row>
          </Card.Header>
          <Form className="mt-2" onSubmit={onSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Button variant="primary btn-block" type="submit" ref={btn}>
              Submit <i className="fas fa-comment ml-3"></i>
            </Button>
          </Form>
          <hr />
          <Row>
            <Col>
              <Link to="/admin/forgot-password">Reset Password</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.admin.isAdmin,
  note: state.note,
});
export default connect(mapStateToProps, { adminLogin })(AdminSignIn);
