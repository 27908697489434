import { UPDATE_CLIENT_FLOOR, CLIENT_FLOORS } from '../../actions/types';
const initState = {
  floors: [],
};

function clientOfficeBooking(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLIENT_FLOORS:
      return {
        ...state,
        floors: payload,
      };
    case UPDATE_CLIENT_FLOOR:
      const array = state.floors.filter((floor) => payload.id !== floor.id);
      array.push(payload);
      array.sort((a, b) => {
        return a.id - b.id;
      });
      return {
        ...state,
        floors: array,
      };

    default:
      return state;
  }
}

export default clientOfficeBooking;
