import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Button, Alert, Form } from 'react-bootstrap';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';
import AddressButton from './Payments/AddressButton';
import { setBookingAddress } from '../../../actions/user/bookings';
import { distanceFromOffice } from '../../../actions/user/order';
import { useNavigate } from 'react-router-dom';

import './Booking.css';

const WhereYou = ({
  jamming,
  jamCarpertCleanLocation,
  user,
  myAddress,
  setBookingAddress,
  address,
  complex,
  coordinates,
  distanceFromOffice,
  addressType,
  fromOffice,
  showAddressDialog,
  toggleAddressPopUp,
  updateMyAddress,
  note,
}) => {
  const [adr, setAddress] = useState(address);
  const [cmplex, setComplex] = useState(complex);
  const [coo, setCoordinates] = useState(coordinates);
  const [typeAddress, setAddressType] = useState(addressType);
  const [complexNumber, setComplexNumber] = useState(0);
  const [complexName, setComplexName] = useState('');
  const [error, setError] = useState(null);
  const [main, setMain] = useState(true);
  const [addressId, setAddressId] = useState();
  const [nte, setMsg] = useState(note);
  const navigate = useNavigate();
  let btn = useRef();
  const handleClose = () => toggleAddressPopUp(false);
  const handleShow = () => toggleAddressPopUp(true);

  // useEffect(() => {
  //   if (!typeAddress) {
  //     console.log('No typeAddress: ', typeAddress);
  //     toggleAddressPopUp(true);
  //   }
  // }, [typeAddress, toggleAddressPopUp]);

  useEffect(() => {
    if (cmplex) {
      let jam = cmplex.split(' ');
      let check = parseInt(jam[0]);

      if (typeof check === 'number') {
        let isNotNull = (value) => value != null;

        let filteredArray = jam.filter(isNotNull);
        let arrayLength = filteredArray.slice(1).toString();

        let complexN = arrayLength.replaceAll(',', ' ');
        setComplexName(complexN);
        setComplexNumber(check);
      } else {
        setComplexName(cmplex);
      }
    }
  }, [cmplex]);

  useEffect(() => {
    if (note.length > 0 && btn.current) {
      setMsg(note);
      let address = adr;
      let coordinates = coo;
      let complex = `${complexNumber} ${complexName}`;
      let addressType = typeAddress;
      setBookingAddress({ address, coordinates, complex, addressType });

      btn.current.removeAttribute('disabled');
      toggleAddressPopUp(false);
    }
  }, [
    setMsg,
    note,
    nte,
    setBookingAddress,
    adr,
    cmplex,
    coo,
    typeAddress,
    toggleAddressPopUp,
    complexNumber,
    complexName,
  ]);

  useEffect(() => {
    if (adr && coo) {
      let address = adr;
      let coordinates = coo;
      let complex = cmplex;
      let addressType = typeAddress;
      if (jamming) {
        jamCarpertCleanLocation({ address, addressType, complex, coordinates });
      } else {
        setBookingAddress({ address, coordinates, complex, addressType });
      }
    }
  }, [
    jamming,
    jamCarpertCleanLocation,
    adr,
    coo,
    setBookingAddress,
    cmplex,
    typeAddress,
    complexNumber,
    complexName,
  ]);

  useEffect(() => {
    if (adr === null && user) {
      setAddress(user.address);
      setCoordinates(user.coordinates);
      setComplex(user.complex);
      setAddressType(user.addressType);
      setAddressId('');
      if (!user.addressType) {
        toggleAddressPopUp(true);
      } else {
        toggleAddressPopUp(false);
      }
    }
  }, [adr, user, toggleAddressPopUp, typeAddress]);

  useEffect(() => {
    if (coo) {
      let clientLat = coo.lat;
      let clientLng = coo.lng;
      distanceFromOffice({ clientLat, clientLng });
    }
  }, [coo, distanceFromOffice]);

  const ChangeAddress = (a) => {
    setAddress(a.text);
    setCoordinates(a.coordinates);
    setComplex(a.complex);
    setAddressType(a.addressType);
    setAddressId(a._id);
    setMain(false);
  };

  const defaultAddress = () => {
    setAddress(user.address);
    setCoordinates(user.coordinates);
    setComplex(user.complex);
    setAddressType(user.addressType);
    setMain(true);
  };

  const where = () => {
    if (user) {
      let text = adr;
      let coordinates = coo;
      let addressType = typeAddress;

      if (!typeAddress) {
        setError(`Please let us know what type of address this is`);
        return setTimeout(() => setError(null), 2000);
      } else if (typeAddress === 'House') {
        let complex = ``;
        updateMyAddress({
          text,
          coordinates,
          complex,
          main,
          addressId,
          addressType,
        });

        if (btn.current) {
          btn.current.setAttribute('disabled', 'disabled');
        }
      } else if (complexNumber === 0) {
        setError(`Please enter ${typeAddress} number`);
        return setTimeout(() => setError(null), 2000);
      } else if (complexName.length < 5) {
        setError(`Please enter ${typeAddress} name`);
        return setTimeout(() => setError(null), 2000);
      } else {
        let complex = `${complexNumber} ${complexName}`;
        updateMyAddress({
          text,
          coordinates,
          complex,
          main,
          addressId,
          addressType,
        });
      }

      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    } else {
      if (!typeAddress) {
        setError(`Please let us know what type of address this is`);
        return setTimeout(() => setError(null), 2000);
      } else if (typeAddress === 'House') {
        let address = adr;
        let coordinates = coo;
        let complex = ``;
        let addressType = typeAddress;

        setBookingAddress({ address, coordinates, complex, addressType });
        handleClose();
      } else if (complexNumber === 0) {
        setError(`Please enter ${typeAddress} number`);
        return setTimeout(() => setError(null), 2000);
      } else if (complexName.length < 5) {
        setError(`Please enter ${typeAddress} name`);
        return setTimeout(() => setError(null), 2000);
      } else {
        let address = adr;
        let coordinates = coo;
        let complex = `${complexNumber} ${complexName}`;
        let addressType = typeAddress;

        setBookingAddress({ address, coordinates, complex, addressType });
        handleClose();
      }
    }
  };

  const changeAddressType = (type) => {
    setAddressType(type);
  };

  const navToLogin = () => {
    navigate('/user/signin');
  };

  const navToSignUp = () => {
    navigate('/user/signup');
  };

  return (
    <div>
      <h5 className="text-center">Which Address Would You Want to Clean?</h5>

      {adr ? (
        fromOffice === false ? (
          <div className="text-center">
            <Button variant="warning mb-3" onClick={handleShow}>
              Unfortunatly we do not service your area
            </Button>
          </div>
        ) : (
          <div className="text-center">
            <h6 className="text-center" style={{ color: 'orange' }}>
              We service you area; {adr}
            </h6>
            {!addressType && (
              <Button variant="outline-danger mb-3" onClick={handleShow}>
                Please update your address
              </Button>
            )}
          </div>
        )
      ) : (
        <div className="text-center">
          <Button variant="danger mb-2" onClick={handleShow}>
            Please tell us a little about your address
          </Button>
        </div>
      )}
      {user && (
        <div>
          <Alert variant="info">
            <Row>
              <Col className="text-center">
                <p>{adr}</p>
              </Col>
              <Col>
                <AddressButton
                  myAddress={myAddress}
                  user={user}
                  ChangeAddress={ChangeAddress}
                  defaultAddress={defaultAddress}
                />
              </Col>
            </Row>
          </Alert>
        </div>
      )}

      <Modal show={showAddressDialog} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Let us know a little about your address
          </Modal.Title>
        </Modal.Header>
        {error && <Alert variant="warning">{error}</Alert>}

        <Modal.Body>
          {user ? (
            <p className="text-center text-info">
              Please let us know what type of address this {adr} is.
            </p>
          ) : (
            <>
              <p className="text-center">
                We will not save your info until your account is created
              </p>

              <AddressAutocomplete
                value={adr}
                setAddress={setAddress}
                setCoordinates={setCoordinates}
              />
            </>
          )}

          {adr ? (
            <Row className="mt-4">
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'House'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => changeAddressType('House')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-solid fa-house"></i>
                  </span>
                </Button>
                House
              </Col>
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'Townhouse/Estate'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => changeAddressType('Townhouse/Estate')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-solid fa-people-roof"></i>
                  </span>
                </Button>
                Townhouse
              </Col>
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'Apartment/Flat'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => changeAddressType('Apartment/Flat')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-solid fa-city"></i>
                  </span>
                </Button>
                Apartment/Flat
              </Col>
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'Office'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => changeAddressType('Office')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-regular fa-building"></i>
                  </span>
                </Button>
                Office
              </Col>
            </Row>
          ) : (
            ''
          )}

          {typeAddress && typeAddress !== 'House' ? (
            <>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Enter {typeAddress} number </Form.Label>
                <Form.Control
                  type="number"
                  placeholder={`Enter ${typeAddress} number`}
                  name="complexNumber"
                  value={complexNumber}
                  onChange={(e) => setComplexNumber(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Enter {typeAddress} name </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`Enter ${typeAddress} name`}
                  name="complexName"
                  value={complexName}
                  onChange={(e) => setComplexName(e.target.value)}
                />
              </Form.Group>
            </>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          {adr ? (
            <Button variant="primary" onClick={where} ref={btn} block>
              Submit
            </Button>
          ) : (
            <Button variant="warning" onClick={where} disabled block>
              Enter Address To Continune
            </Button>
          )}

          {!user && (
            <Col className="text-center">
              <Button variant="outline-info mr-3" onClick={() => navToLogin()}>
                Sign in
              </Button>
              <Button variant="outline-success" onClick={() => navToSignUp()}>
                Create account
              </Button>
            </Col>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, {
  setBookingAddress,
  distanceFromOffice,
})(WhereYou);
