import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import moment from 'moment';
import TimeSheet from './TimeSheet';

const ConfirmDateAndTime = ({
  resetDT,
  regular,
  schedule,
  orderTime,
  how,
  setHow,
  moreInfo,
}) => {
  const [note, setNote] = useState('');
  useEffect(() => {
    if (how !== null) {
      setNote(how.b);
    }
  }, [how]);
  let theDate = moment(orderTime).format('MMMM Do YYYY, h:mm:ss a');
  return (
    <div className="mt-3">
      {regular ? (
        <>
          <Alert variant="info text-center">
            <Row>
              <Col>The first clean will be on {theDate}</Col>
              <Col>
                <Button
                  variant="warning float-right"
                  size="sm"
                  onClick={resetDT}
                >
                  Change Arrival Date & Time
                </Button>
              </Col>
            </Row>
          </Alert>
          <h5 className="text-center">Appointment Summary:</h5>
          <TimeSheet schedule={schedule} />
          <h5 className="text-center" style={{ marginTop: '10px' }}>
            Please let us know how to access your property?
          </h5>
          <Row>
            <Col md={3} xs={12}>
              <Button
                variant={
                  how.a === 'Some One Is At Home' ? 'info' : 'outline-secondary'
                }
                value="Some One Is At Home"
                block
                onClick={(e) => setHow(e.target.value)}
              >
                At home
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={how.a === 'Key Safe' ? 'info' : 'outline-secondary'}
                value="Key Safe"
                block
                onClick={(e) => setHow(e.target.value)}
              >
                Key Safe
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={how.a === 'Key Hidden' ? 'info' : 'outline-secondary'}
                value="Key Hidden"
                onClick={(e) => setHow(e.target.value)}
                block
              >
                Key hidden
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={how.a === 'Other' ? 'info' : 'outline-secondary'}
                value="Other"
                onClick={(e) => setHow(e.target.value)}
                block
              >
                Other
              </Button>
            </Col>
          </Row>
          {how && how.a !== 'Some One Is At Home' && how.a !== '' && (
            <div className="mt-4" align="center">
              <h5 className="text-center">{how.a}</h5>
              <InputGroup>
                <FormControl
                  as="textarea"
                  placeholder={`${moreInfo}`}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </InputGroup>
            </div>
          )}
        </>
      ) : (
        <>
          <Alert variant="info text-center">
            <Row>
              <Col>Cleaner will arrive on {theDate}</Col>
              <Col>
                <Button
                  variant="warning float-right"
                  size="sm"
                  onClick={resetDT}
                >
                  Change Arrival Date & Time
                </Button>
              </Col>
            </Row>
          </Alert>

          <h5 className="text-center" style={{ marginTop: '10px' }}>
            Please let us know how to access your property?
          </h5>
          <Row>
            <Col md={3} xs={12}>
              <Button
                variant={
                  how.a === 'Some One Is At Home' ? 'info' : 'outline-secondary'
                }
                value="Some One Is At Home"
                block
                onClick={(e) => setHow(e.target.value)}
              >
                At home
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={how.a === 'Key Safe' ? 'info' : 'outline-secondary'}
                value="Key Safe"
                block
                onClick={(e) => setHow(e.target.value)}
              >
                Key Safe
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={how.a === 'Key Hidden' ? 'info' : 'outline-secondary'}
                value="Key Hidden"
                onClick={(e) => setHow(e.target.value)}
                block
              >
                Key hidden
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={how.a === 'Other' ? 'info' : 'outline-secondary'}
                value="Other"
                onClick={(e) => setHow(e.target.value)}
                block
              >
                Other
              </Button>
            </Col>
          </Row>
          {how && how.a !== 'Some One Is At Home' && how.a !== '' && (
            <div className="mt-4" align="center">
              <h5 className="text-center">{how.a}</h5>
              <InputGroup>
                <FormControl
                  as="textarea"
                  placeholder={`${moreInfo}`}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </InputGroup>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ConfirmDateAndTime;
