import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { deleteStageTwo } from '../../../../actions/admin/team_members';
import { Button, Modal } from 'react-bootstrap';

const FlushStageTwo = ({ deleteStageTwo, note }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (note.length > 0) {
      handleClose();
    }
  }, [note]);

  let deleteBtn = useRef();

  const flush = () => {
    deleteStageTwo();

    if (deleteBtn.current) {
      deleteBtn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <div>
      <Button variant="warning mt-2 text-white" size="sm" onClick={handleShow}>
        Flush Stage 2
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete All Stage Two Applicants</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning text-white" onClick={flush} ref={deleteBtn}>
            Flush Stage Two
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, { deleteStageTwo })(FlushStageTwo);
