import { HOW_WE_DO, CONTACT, CLEAR_ANIMATION } from '../actions/types';

const initialState = {
  how: null,
  contact: null,
};

function animationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HOW_WE_DO:
      return {
        ...state,
        how: payload,
      };
    case CONTACT:
      return {
        ...state,
        contact: payload,
      };
    case CLEAR_ANIMATION:
      return {
        ...state,
        how: null,
        contact: null,
      };
    default:
      return state;
  }
}

export default animationReducer;
