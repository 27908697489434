import React, { useEffect } from 'react';
import { Table, Dropdown, Button } from 'react-bootstrap';
import './Business.css';

const Step3 = ({ setStep }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Table bordered>
        <thead>
          <th>Activity</th>
          <th>Recommendation</th>
          <th>Your Wish</th>
        </thead>
        <tbody>
          <tr>
            <td>Sills</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Skirting</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Viz pannels</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Dust bins</td>
            <td>Monthly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Monthly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Weekly</Dropdown.Item>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Reception glazing</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Reception cabinets</td>
            <td>Bi-weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Bi-weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Cupboards</td>
            <td>Monthly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Monthly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Weekly</Dropdown.Item>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Phones</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Keyboards</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Screens</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Bins</td>
            <td>Daily</td>
            <td>Default</td>
          </tr>
          <tr>
            <td>Fridge</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Microwave</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Dishwasher</td>
            <td>Weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Bi-weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Stack dish washer</td>
            <td>not allocated</td>
            <td>Default</td>
          </tr>
          <tr>
            <td>Internal office glazing</td>
            <td>Bi-weekly</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="option">
                  Bi-weekly
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Weekly</Dropdown.Item>
                  <Dropdown.Item>Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Boardroom</td>
            <td>Daily</td>
            <td>Default</td>
          </tr>
          <tr>
            <td>Toilets</td>
            <td>Daily</td>
            <td>Default</td>
          </tr>
        </tbody>
      </Table>
      <Button variant="outline-info mt-3" onClick={() => setStep(4)} block>
        Next <i className="fa-solid fa-angle-right ml-5"></i>
      </Button>
    </div>
  );
};

export default Step3;
