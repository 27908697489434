import { useEffect } from 'react';
import { getDuster } from '../../../actions/admin/team_members';
import { connect } from 'react-redux';
import { Container, Spinner } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

const DusterHR = ({ getDuster, duster: { dustBuster } }) => {
  const { dusterId } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    getDuster(dusterId);
  }, [dusterId, getDuster]);

  if (dustBuster === null) {
    <Container>
      <Spinner variant="grow" animation="grow" />
    </Container>;
  }

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4>Duster HR</h4>
      <hr />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  duster: state.team_members,
});
export default connect(mapStateToProps, { getDuster })(DusterHR);
