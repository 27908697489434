import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  dusterOrders,
  dusterRepeat,
  checkBooking,
  checkRebooking,
  cleanerRate,
  changeRate,
  dusterReviews,
  fetchCleanerRating,
} from '../../../actions/admin/team_members';
import { changeType } from '../../../actions/admin/team_admin';
import {
  Row,
  Col,
  Alert,
  Spinner,
  Button,
  Container,
  Table,
  Modal,
  ListGroup,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CleanItem from './CleanItem';
import ReCleanItem from './ReCleanItem';
import EditDuster from './EditDuster';
import Bookings from './BookingsCalender';
import UpdateDusterDocs from './UpdateDusterDocs';
import RateCard from './RateCard';
import EditRate from './EditRate';
import SuspendDuster from './SuspendDuster';
import WhitelistDuster from './WhitelistDuster';
import Avatar from 'react-avatar';
import ChangeDusterType from './ChangeDusterType';
import moment from 'moment';

const WorkingDuster = ({
  dustBuster,
  dusterRepeat,
  dusterOrders,
  orders,
  bookings,
  reorders,
  dusterId,
  idTrash,
  poaTrash,
  blTrash,
  ppTrash,
  ecTrash,
  wpTrash,
  idDoc,
  poa,
  bankLetter,
  pic,
  ec,
  workPermit,
  idBtn,
  poaBtn,
  blBtn,
  ppBtn,
  ecBtn,
  wpBtn,
  id_uploaded,
  uploadID,
  IDbtn,
  uploadPOA,
  poa_uploaded,
  POAbtn,
  uploadBL,
  BLbtn,
  bl_uploaded,
  uploadPP,
  PPbtn,
  pp_uploaded,
  ec_uploaded,
  uploadEC,
  ECbtn,
  wp_uploaded,
  uploadWP,
  WPbtn,
  updateDusterUploads,
  update,
  setUpdate,
  getUploads,
  updateUploads,
  setUpdateUploads,
  dusterBookings,
  checkBooking,
  checkRebooking,
  theBooking,
  cleanerRate,
  rate,
  changeRate,
  note,
  blacklistDuster,
  susBtn,
  unsusBtn,
  moveFromBlack,
  dusterReviews,
  team_members: { duster_reviews },
  fetchCleanerRating,
  changeType,
}) => {
  const [nationality, setNationality] = useState(null);
  const [star, setStar] = useState(0);
  const [gender, setGender] = useState(null);
  const [myOrders, setMyOrders] = useState(orders);
  const [myReOrders, setMyReOrders] = useState(reorders);
  const [myBookings, setMyBookings] = useState(bookings);
  const [type, setType] = useState();

  const [address, setAddress] = useState(null);
  const [complex, setComplex] = useState(null);
  const [adr, setAdr] = useState('');
  const [go, setGo] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);
  const [show, setShow] = useState(false);
  let uploadBtn = useRef();
  let updateBtn = useRef();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (dustBuster) {
      if (dustBuster.local === true) {
        setNationality('South African');
      } else {
        setNationality('Foreign Citizen');
      }
      if (dustBuster.female === true) {
        setGender('Female');
      } else {
        setGender('Male');
      }
      if (dustBuster.daily === true) {
        setType('Dust Buster');
      } else {
        setType('Contract Cleaner');
      }
    }
  }, [dustBuster]);

  useEffect(() => {
    dusterOrders(dusterId);
    dusterRepeat(dusterId);
    dusterBookings(dusterId);
    dusterReviews(dusterId);
    setUpdate(false);
  }, [
    dusterOrders,
    dusterRepeat,
    dusterBookings,
    dusterId,
    setUpdate,
    dusterReviews,
  ]);

  useEffect(() => {
    if (theBooking) {
      setAddress(theBooking.address);
      setComplex(theBooking.complex);
    }
  }, [theBooking]);

  useEffect(() => {
    if (address) {
      setAdr(address.split(','));
    }
  }, [address]);

  useEffect(() => {
    if (orders) {
      setMyOrders(orders);
    }
    if (reorders) {
      setMyReOrders(reorders);
    }
    if (bookings) {
      const events = bookings.map((booking) => {
        let start = Date.parse(booking.start);
        let end = Date.parse(booking.end);

        if (booking.order) {
          let order = booking.order;
          return {
            title: 'Booking',
            start: start,
            end: end,
            orderId: order,
            order: true,
          };
        } else if (booking.reorder) {
          let reorder = booking.reorder;
          return {
            title: 'Booking',
            start: start,
            end: end,
            orderId: reorder,
            order: false,
          };
        }
      });
      setMyBookings(events);
    }
  }, [orders, reorders, bookings]);

  useEffect(() => {
    async function fetchStar() {
      let _id = dusterId;
      const star = await fetchCleanerRating(_id);
      setStar(star);
    }
    fetchStar();
  }, [fetchCleanerRating, dusterId]);

  // const refresh = () => {
  //   getUploads(dusterId);
  //   setUpdate(false);
  // };

  // const uploadsUpdate = () => {
  //   updateDusterUploads({
  //     idDoc,
  //     poa,
  //     bankLetter,
  //     pic,
  //     workPermit,
  //     ec,
  //     dusterId,
  //   });
  //   setUpdateUploads(false);
  // };
  if (myOrders === null || myReOrders === null || myBookings === null) {
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  }
  const goBack = () => {
    navigate(-1);
  };

  const fetchBooking = (b) => {
    if (b.reorder) {
      checkRebooking(b.reorder);
      setBookingDate(b.start);
      setGo(`/admin/order-management/repeat-orders/order/${b.reorder}`);
      handleShow();
    } else if (b.order) {
      checkBooking(b.order);
      setBookingDate(b.start);
      setGo(`/admin/order-management/once-off/order/${b.order}`);
      handleShow();
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>
          <h4 style={{ color: '#06a5dd' }}>Duster Admin:</h4>
        </Col>
        <Col>
          <Button
            as={Link}
            to={`/admin/team/duster-hr/${dusterId}`}
            size="sm"
            variant="outline-info mr-2"
          >
            HR
          </Button>
          <Button
            as={Link}
            to={`/admin/team/duster-orders/${dusterId}`}
            size="sm"
            variant="outline-info mr-2"
          >
            Once-time
          </Button>
          <Button
            as={Link}
            to={`/admin/team/duster-repeat/${dusterId}`}
            size="sm"
            variant="outline-info mr-2"
          >
            Repeat
          </Button>
          <Button
            as={Link}
            to={`/admin/team/duster-docs/${dusterId}`}
            size="sm"
            variant="outline-info"
          >
            Documentation
          </Button>
          {dustBuster.blacklist ? (
            <WhitelistDuster
              dustBuster={dustBuster}
              unsusBtn={unsusBtn}
              moveFromBlack={moveFromBlack}
            />
          ) : (
            <SuspendDuster
              dustBuster={dustBuster}
              susBtn={susBtn}
              blacklistDuster={blacklistDuster}
            />
          )}

          <EditDuster dustBuster={dustBuster} dusterId={dusterId} />
          {rate ? (
            <EditRate
              dusterId={dusterId}
              changeRate={changeRate}
              dustBuster={dustBuster}
              custom={rate}
              note={note}
            />
          ) : (
            <RateCard
              dusterId={dusterId}
              cleanerRate={cleanerRate}
              dustBuster={dustBuster}
              note={note}
            />
          )}
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs={12} md={5}>
          <Row className="mb-4">
            <Avatar
              name={dustBuster.name}
              size="150"
              round={true}
              color="blue"
            />
          </Row>
          <Bookings myBookings={myBookings} />
        </Col>
        <Col xs={12} md={5}>
          <Row className="mb-4">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b>Star Rating:</b>{' '}
                <i
                  className="fa-solid fa-star"
                  style={{
                    color: star >= 1 ? 'yellowGreen' : '',
                  }}
                ></i>
                <i
                  className="fa-solid fa-star"
                  style={{
                    color: star >= 2 ? 'yellowGreen' : '',
                  }}
                ></i>
                <i
                  className="fa-solid fa-star"
                  style={{
                    color: star >= 3 ? 'yellowGreen' : '',
                  }}
                ></i>
                <i
                  className="fa-solid fa-star"
                  style={{
                    color: star >= 4 ? 'yellowGreen' : '',
                  }}
                ></i>
                <i
                  className="fa-solid fa-star"
                  style={{
                    color: star === 5 ? 'yellowGreen' : '',
                  }}
                ></i>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Name:</b> {dustBuster.name} {dustBuster.surname}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Cellphone:</b> {dustBuster.cellphone}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Email:</b> {dustBuster.email}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Address:</b> {dustBuster.address}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Gender:</b> {gender}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Nationality:</b> {nationality}
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>
                    <b>Type:</b> {type}
                  </Col>
                  <Col>
                    <ChangeDusterType
                      dustBuster={dustBuster}
                      type={type}
                      changeType={changeType}
                      note={note}
                    />
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>

            <hr />

            <h5>Duster reviews:</h5>
            {duster_reviews && duster_reviews.length > 0 ? (
              'We have reviews'
            ) : (
              <div className="text-center">
                <Alert variant="dark">Duster has no reviews</Alert>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapsStateToProps = (state) => ({
  team_members: state.team_members,
});
export default connect(mapsStateToProps, {
  dusterOrders,
  dusterRepeat,
  checkBooking,
  checkRebooking,
  cleanerRate,
  changeRate,
  dusterReviews,
  fetchCleanerRating,
  changeType,
})(WorkingDuster);
