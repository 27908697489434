import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAllReOrders } from '../../../../../actions/admin/main';
import { Container, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AllRepeatOrders = ({ fetchAllReOrders, all_repeat }) => {
  useEffect(() => {
    fetchAllReOrders();
  }, [fetchAllReOrders]);
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  if (all_repeat === null)
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>
      <h4>All repeat orders</h4>
      <hr />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  all_repeat: state.main.all_repeat,
});

export default connect(mapStateToProps, { fetchAllReOrders })(AllRepeatOrders);
