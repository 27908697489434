import React from 'react';
import Bottom from '../Layout/Bottom';

const OfficeCleaning = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            Effortless Office Cleaning Solutions with Dusting Down's App and
            Website
          </h3>
        </div>
        <div>
          <p>
            <b>1. Streamlined Booking Process:</b> Experience the convenience of
            Dusting Down's app and website when booking your office cleaning
            services. Our user-friendly platform allows you to schedule the
            service at your preferred date and time, all with just a few taps or
            clicks.
          </p>
          <p>
            <b>2. Expert Office Cleaning Team:</b> Dusting Down boasts a team of
            skilled professionals specializing in office cleaning. With the
            Dusting Down app or website, you can easily access these experts who
            are equipped with the knowledge and techniques to deliver
            exceptional results.
          </p>
          <p>
            <b>3. Customized Cleaning Solutions:</b> We understand that every
            office has unique cleaning requirements. Dusting Down's app and
            website provide customization options, allowing you to specify your
            office's specific needs and any particular areas of focus.
          </p>
          <p>
            <b>4. High-Quality Equipment and Eco-Friendly Products:</b> Rest
            assured that your office is in good hands with Dusting Down. Our
            professionals utilize high-quality cleaning equipment and
            eco-friendly products that are specifically designed for effective
            and safe office cleaning, ensuring a healthy and clean workspace.
          </p>
          <p>
            <b>5. Flexible Scheduling:</b> We value your time and convenience.
            Through our app and website, you can effortlessly schedule your
            office cleaning according to your business hours and preferences.
            Say goodbye to the hassle of coordinating cleaning schedules over
            phone calls or emails.
          </p>
          <p>
            <b>6. Seamless Communication:</b> Dusting Down's app and website
            provide a seamless communication channel between you and our
            cleaning team. You can easily communicate specific instructions,
            request additional services, or address any concerns directly
            through the platform, ensuring efficient and clear communication.
          </p>
          <p>
            <b>7. Transparent Updates and Documentation:</b> Stay informed about
            your office cleaning progress with Dusting Down's app and website.
            You can receive real-time updates on cleaning activities and access
            detailed documentation, including service reports and invoices,
            providing transparency and clarity for your records.
          </p>
        </div>
        <hr />
        <div className="container">
          <p>
            <b>Discover the Convenience:</b> Dusting Down's app and website
            revolutionize the way you book and manage office cleaning services.
            Enjoy the convenience of effortless bookings, personalized cleaning
            solutions, seamless communication, and transparent updates.
            Experience a stress-free and convenient office cleaning process with
            Dusting Down, right at your fingertips.
          </p>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default OfficeCleaning;
