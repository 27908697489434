import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ReCheckOutCleaner = ({
  nextClean,
  checkOutDuster,
  orderId,
  invoiceHours,
  duster,
  note,
}) => {
  const [nextTime, setNextTime] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);
  useEffect(() => {
    if (nextClean) {
      setNextTime(nextClean.start);
    }
  }, [nextClean]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkOut = () => {
    let a = moment(nextTime).add(invoiceHours, 'hours').format();
    checkOutDuster({ orderId, a });
  };
  return (
    <>
      <Button variant="secondary" size="sm" onClick={handleShow}>
        Checkout the cleaner <i className="fas fa-exclamation-triangle"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Checkout {duster.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cleaner will be checkeout at:
          <Button variant="info float-right" size="sm" disabled>
            {moment(nextTime)
              .add(invoiceHours, 'hours')
              .format('ddd MMM D, h:mm a')}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            <i className="fas fa-times-circle"></i>
          </Button>
          <Button variant="primary" onClick={checkOut}>
            Checkout cleaner
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReCheckOutCleaner;
