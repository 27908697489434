import axios from 'axios';
import { USER_REVIEW } from '../types';

export const createReview =
  ({ review, rating, userId, orderId }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ review, rating, userId, orderId });
      const res = await axios.post('/api/team/reviews/create', body, config);
      dispatch({ type: USER_REVIEW, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
export const fetchOrderReview = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/team/reviews/order-review/${orderId}`);
    dispatch({ type: USER_REVIEW, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
