import axios from 'axios';
import {
  SET_USERS,
  SET_USER,
  USER_ORDERS,
  USER_ADDRESS,
  CLEAR_USERS,
  CLEAR_ADMIN_USER,
  CLEAR_RATE,
  RECENT_ORDERS,
  RECENT_REPEAT_ORDERS,
} from '../types';
import { setNote } from '../note';

export const createNewUser = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post(
      '/api/admin/admin_user/add-new-user',
      body,
      config
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setNote(error.msg, 'danger'));
      });
    }
  }
};

export const GetUsers = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USERS });
    dispatch({ type: CLEAR_RATE });
    const res = await axios.get('/api/admin/admin_user');
    dispatch({ type: SET_USERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get a user
export const GetUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADMIN_USER });
    const res = await axios.get(`/api/admin/admin_user/specific/${userId}`);
    dispatch({ type: SET_USER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//get user addresses
export const GetUserAddress = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/admin_user/user-address/${userId}`);
    dispatch({ type: USER_ADDRESS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Add user address//
export const createUserAddress =
  ({ userId, text, coordinates, complex, addressType }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ text, coordinates, complex, addressType });

    try {
      const res = await axios.post(
        `/api/admin/admin_user/add-user-address/${userId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(GetUser(userId));
      dispatch(GetUserAddress(userId));
    } catch (error) {
      console.error('Error creating address: ', error.message);
    }
  };

export const userOrders = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/admin_user/user-orders/${userId}`);
    dispatch({ type: USER_ORDERS, payload: res.data });
  } catch (error) {
    console.error();
  }
};

//block user//
export const pauseUser =
  ({ userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ userId });
    try {
      const res = await axios.put(
        '/api/admin/admin_user/block-user',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//Delete user//
export const deleteUser = (userId) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `/api/admin/admin_user/delete-user/${userId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

//Update user//
export const updateClient = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  let userId = formData.userId;
  try {
    const res = await axios.put(
      '/api/admin/admin_user/update_user',
      body,
      config
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(GetUser(userId));
  } catch (error) {
    console.error(error.messgae);
  }
};

//reset user password//
export const resetUserPassword =
  ({ password, userId }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ password });
      const res = await axios.put(
        `/api/admin/admin_user/reset-user-password/${userId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(GetUser(userId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch 5 recent orders//
export const fetchFiveO = (user) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/admin_user/recent-orders/${user}`);
    dispatch({ type: RECENT_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch 5 recent repeat orders//
export const fetchFiveR = (user) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/admin_user/recent-repeat-orders/${user}`
    );
    dispatch({ type: RECENT_REPEAT_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
