import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  resendEmailVerification,
  emailIsVerified,
} from '../../../actions/user';
import { Button, Modal } from 'react-bootstrap';

const EmailVerifiedModal = ({
  emailIsVerified,
  resendEmailVerification,
  email,
  note,
}) => {
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (note.length > 0) {
      handleClose();
    }
  }, [note]);

  const resendEmail = () => {
    resendEmailVerification({ email });
  };

  const emailVerified = () => {
    emailIsVerified({ email });
  };
  return (
    <div>
      <Button variant="outline-success mt-4" onClick={handleShow}>
        Your account was created click here once you have verified your email
      </Button>
      <Modal show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Have you verified your email?</Modal.Title>
        </Modal.Header>
        <Modal.Body>We sent a email verification to {email}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => emailVerified()}>
            Verified
          </Button>
          <Button variant="outline-warning" onClick={() => resendEmail()}>
            Send Again
          </Button>
          <Button variant="outline-danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, {
  resendEmailVerification,
  emailIsVerified,
})(EmailVerifiedModal);
