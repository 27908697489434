import React, { useEffect, useState, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  ListGroup,
  Col,
  Row,
  Card,
  Alert,
  Spinner,
  Modal,
} from 'react-bootstrap';
import moment from 'moment';
import { ozowPay, peachPayment } from '../../../../actions/user/payment';
import { Order, AccOrder } from '../../../../actions/user/order';
import {
  setBookingAddress,
  addTip,
  cancelTip,
} from '../../../../actions/user/bookings';
import { cancelThisCoupon } from '../../../../actions/user/coupons';
import ExtraItem from './ExtraItem';
import Coupon from './Coupon';
import Bottom from '../../../Layout/Bottom';
import AddressButton from './AddressButton';
import AddCustomTip from './AddCustomTip';

const CheckOut = ({
  bookings: {
    orderTime,
    bedbathTotal,
    extraTotal,
    invoice,
    hours,
    changeHours,
    bedroom,
    bathroom,
    nityGrity,
    address,
    coordinates,
    complex,
    whyLess,
    iconExtras,
    tip,
    dusterId,
    laundreyBasket,
  },
  Order,
  user,
  ozowPay,
  peachPayment,
  orders: { order, peachLoad, paymentUrl },
  couponTotal,
  AccOrder,
  setBookingAddress,
  addTip,
  cancelTip,
  cancelThisCoupon,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [mrx, SetMrx] = useState([]);
  const [show, setShow] = useState(false);
  const [invoiceHours, setInvoiceHours] = useState(null);
  const [coupon, SetCoupon] = useState(null);
  const [inv, SetInv] = useState(null);
  const [couponOrder, SetCouponOrder] = useState(null);
  const [couponUsed, setCouponUsed] = useState(null);
  const [freeRedirect, SetFreeRedirect] = useState(false);
  const [monthlyRedirect, SetMonthlyRedirect] = useState(false);
  const [adr, SetAddress] = useState(address);
  const [cmplex, SetComplex] = useState(complex);
  const [coupons, SetCoupons] = useState(null);
  const [coo, SetCoordinates] = useState(coordinates);
  const [monthly, SetMonthly] = useState(null);
  const [tenPerc, setTenPerc] = useState(null);
  const [twoPerc, setTwoPerc] = useState(null);
  const [threePerc, setThreePerc] = useState(null);
  const [team, setTeam] = useState(null);
  const [myTip, setMyTip] = useState(0);
  const regular = false;

  useEffect(() => {
    if (dusterId) {
      let team = dusterId.duster.dusterId;
      setTeam(team);
    } else {
      setTeam(null);
    }
  }, [dusterId]);

  useEffect(() => {
    if (tip != null) {
      let amt = tip.tip.toFixed(2);
      setMyTip(amt);
    } else {
      setMyTip(0);
    }
  }, [tip]);

  useEffect(() => {
    if (inv && tip === null) {
      let ten = inv * 0.1;
      let two = inv * 0.2;
      let three = inv * 0.3;
      setTenPerc(ten.toFixed(2));
      setTwoPerc(two.toFixed(2));
      setThreePerc(three.toFixed(2));
    }
  }, [inv, tip]);

  useEffect(() => {
    if (changeHours) {
      setInvoiceHours(changeHours);
    } else {
      setInvoiceHours(hours);
    }
  }, [changeHours, hours]);

  useEffect(() => {
    if (adr && coo) {
      let address = adr;
      let coordinates = coo;
      let complex = cmplex;
      setBookingAddress({ address, coordinates, complex });
    }
  }, [adr, coo, cmplex, setBookingAddress]);

  useEffect(() => {
    if (user) {
      SetCoupons(user.coupons);
      SetMonthly(user.monthlyAcc);
    }
  }, [user]);

  useEffect(() => {
    if (couponUsed || coupon) {
      const newTotal = couponTotal.toString();
      SetCouponOrder(coupon);
      SetInv(newTotal);
    } else {
      SetInv(invoice);
    }
  }, [couponUsed, invoice, couponTotal, coupon, SetCouponOrder, SetInv]);

  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  });

  useEffect(() => {
    if (peachLoad) {
      const script = document.createElement('script');
      script.src = `https://${process.env.REACT_APP_PEACH_BASE_URL}/v1/paymentWidgets.js?checkoutId=${peachLoad.id}`;
      document.body.append(script);

      window.scrollTo(0, 0);
      setShow(true);
    }
  }, [peachLoad]);

  useEffect(() => {
    if (order) {
      let orderId = order._id;
      let payType = order.paymentMethod;
      let invoice = order.invoice;
      let a = order.monthEnd;
      if (a === true) {
        return SetMonthlyRedirect(true);
      }

      if (invoice === 0) {
        return SetFreeRedirect(true);
      }

      if (payType === 'ozow') {
        let _id = orderId;
        ozowPay({ _id, invoice });
      }

      if (payType === 'peach') {
        let amount = parseFloat(invoice).toFixed(2);
        peachPayment({ amount, orderId });
      }
    }
  }, [order, ozowPay, peachPayment]);

  let peachBtn = useRef();
  let freeBtn = useRef();
  let monthBtn = useRef();

  const monthlyCheckOut = () => {
    let monthEnd = true;
    let address = adr;
    let coordinates = coo;
    let time = orderTime;
    let tip = myTip;
    AccOrder({
      time,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      monthEnd,
      couponOrder,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
      laundreyBasket,
    });
    if (monthBtn.current) {
      monthBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  // const createOzow = () => {
  //   let paymentMethod = 'ozow';
  //   let invoice = inv;
  //   let address = adr;
  //   let coordinates = coo;
  //   let complex = cmplex;
  //   let time = orderTime;
  //   let tip = myTip;
  //   Order({
  //     time,
  //     address,
  //     coordinates,
  //     bedroom,
  //     bathroom,
  //     mrx,
  //     invoiceHours,
  //     invoice,
  //     paymentMethod,
  //     couponOrder,
  //     complex,
  //     nityGrity,
  //     whyLess,
  //     changeHours,
  //     couponUsed,
  //     team,
  //     tip,
  //     laundreyBasket,
  //   });

  //   if (ozowBtn.current) {
  //     ozowBtn.current.setAttribute('disabled', 'disabled');
  //   }
  // };

  const createPeach = () => {
    let paymentMethod = 'peach';
    let invoice = inv;
    let address = adr;
    let coordinates = coo;
    let complex = cmplex;
    let time = orderTime;
    let tip = myTip;
    Order({
      time,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      paymentMethod,
      couponOrder,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
      laundreyBasket,
    });

    if (peachBtn.current) {
      peachBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const handleClose = () => {
    setShow(false);
    peachBtn.current.removeAttribute('disabled');
  };

  const freeOrder = () => {
    let invoice = inv;
    let address = adr;
    let coordinates = coo;
    let complex = cmplex;
    let time = orderTime;
    let tip = myTip;
    Order({
      time,
      address,
      coordinates,
      complex,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      couponOrder,
      invoice,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
      laundreyBasket,
    });

    if (freeBtn.current) {
      freeBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  useEffect(() => {
    let arr = iconExtras.filter((ex) => ex.chosen !== false);
    SetMrx(arr);
  }, [iconExtras, SetMrx]);

  const ChangeAddress = (a) => {
    SetAddress(a.text);
    SetCoordinates(a.coordinates);
    SetComplex(a.complex);
  };

  const defaultAddress = () => {
    SetAddress(user.address);
    SetCoordinates(user.coordinates);
    SetComplex(user.complex);
  };

  const cancelCoupon = async () => {
    await cancelThisCoupon(couponUsed);
    SetCoupon(null);
    setCouponUsed(null);
  };

  const invoiceTime = moment(orderTime).format('ddd MMM D, h:mm a');

  if (monthlyRedirect === true) {
    return <Navigate to="/payments/monthly-order" />;
  }

  if (freeRedirect === true) {
    return <Navigate to="/payments/free-order" />;
  }
  if (address === null) {
    return <Spinner animation="grow" variant="dark" />;
  }

  let invAddress = adr.split(',');

  const addTenTip = () => {
    let tip = {
      tip: parseFloat(tenPerc),
      perc: '10%',
    };
    let invoice = parseFloat(inv);

    addTip({ tip, invoice, regular });
  };

  const addTwoTip = () => {
    let tip = {
      tip: parseFloat(twoPerc),
      perc: '20%',
    };
    let invoice = parseFloat(inv);
    addTip({ tip, invoice, regular });
  };

  const addThreeTip = () => {
    let tip = {
      tip: parseFloat(threePerc),
      perc: '30%',
    };
    let invoice = parseFloat(inv);
    addTip({ tip, invoice, regular });
  };

  const myOwnTip = (amount) => {
    let tip = {
      tip: parseFloat(amount),
      perc: 'custom',
    };
    let invoice = parseFloat(inv);
    addTip({ tip, invoice, regular });
  };

  const removeTip = () => {
    let amt = tip.tip;
    let invoice = inv;
    cancelTip({ amt, invoice, regular });
  };

  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        autoFocus={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form
            action={`${process.env.REACT_APP_BASE_URL}payments/card-response/`}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </Modal.Body>
      </Modal>

      <div className="container">
        <Row>
          <Col xs={12} md={7}>
            <Alert variant="info">
              <Row>
                <Col>
                  <h3 className="text-info text-center">
                    Review booking and make payment
                  </h3>
                </Col>
                <Col>
                  <AddressButton
                    ChangeAddress={ChangeAddress}
                    defaultAddress={defaultAddress}
                    user={user}
                  />
                </Col>
              </Row>
            </Alert>
            <ListGroup variant="flush">
              <ListGroup.Item variant="secondary">
                {invoiceHours > 8 ? (
                  <Alert variant="info text-center">
                    Please note that this is a 2 day booking...
                  </Alert>
                ) : (
                  ''
                )}
                <p className="float-left">Time & Location </p>
                <p className="float-right">
                  {invoiceTime} | {invAddress[0]}, {invAddress[1]},{' '}
                  {invAddress[2]}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left">
                  {bedroom} Bedroom/s and {bathroom} Bathroom/s
                </p>
                <p className="float-right">R{bedbathTotal} </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left"> Included Cost</p>
                <p className="float-right">R212.00</p>
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div key={x.id} style={{ width: '20rem' }}>
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
                <p className="float-right">R{extraTotal} </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left"> Service Fee </p>
                <p className="float-right">R35.00</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left">Total Time: </p>
                <p className="float-right">{invoiceHours} hours</p>
              </ListGroup.Item>
            </ListGroup>
            <Row className="mt-3">
              <Col>
                <Link to="/user/booking">
                  <Alert variant="secondary text-center">
                    <i className="mr-3 fas fa-step-backward"></i> Edit Order!
                  </Alert>
                </Link>
              </Col>
              <Col>
                <Alert variant="info text-info text-center text-white">
                  Your total is: <b>R{inv}</b>
                  <i className="far fa-credit-card"></i>
                </Alert>
              </Col>
            </Row>
          </Col>

          <Col md={5}>
            <Card className="text-center">
              <Alert variant="success">
                <h5>
                  Pay Here: <i className="ml-3 fas fa-cash-register"></i>
                </h5>
              </Alert>
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '4em' }}>
                    <i className="fas fa-credit-card text-success"></i>
                  </span>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-success">
                  Thank you for considering our services
                </Card.Subtitle>

                <hr />

                <p>Would you like to add a tip?</p>

                {tip !== null ? (
                  <Row
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <p
                        style={{
                          marginTop: '10px',
                          color: 'green',
                          fontWeight: 'bold',
                        }}
                      >
                        {tip.perc} tip applied
                      </p>
                    </Col>
                    <Col>
                      <Button variant="outline-danger" onClick={removeTip}>
                        Cancel Tip
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <Button
                        variant="outline-info"
                        onClick={() => addTenTip(tenPerc, '10%')}
                      >
                        10%
                      </Button>
                      <p> R{tenPerc}</p>
                    </Col>
                    <Col>
                      <Button
                        variant="outline-info"
                        onClick={() => addTwoTip(twoPerc, '20%')}
                      >
                        20%
                      </Button>
                      <p>R{twoPerc}</p>
                    </Col>
                    <Col>
                      <Button
                        variant="outline-info"
                        onClick={() => addThreeTip(threePerc, '30%')}
                      >
                        30%
                      </Button>
                      <p>R{threePerc}</p>
                    </Col>
                    <Col style={{ marginRight: '25px' }}>
                      <AddCustomTip myOwnTip={myOwnTip} />
                      <p>-</p>
                    </Col>
                  </Row>
                )}
                {coupon ? (
                  <Button variant="warning" onClick={cancelCoupon} block>
                    Cancel Coupon
                  </Button>
                ) : couponUsed ? (
                  <Button variant="warning" onClick={cancelCoupon} block>
                    Cancel Coupon
                  </Button>
                ) : (
                  <Coupon
                    coupons={coupons}
                    SetCoupon={SetCoupon}
                    setCouponUsed={setCouponUsed}
                    invoiceHours={invoiceHours}
                  />
                )}

                <hr />
                <Card.Text>
                  <b>Please select your preferred payment method</b>
                </Card.Text>
                <b>***Please Note***</b>
                <p>You will be re-directed when you select a payment method.</p>
                {inv === '0' ? (
                  <Button
                    variant="success"
                    onClick={freeOrder}
                    ref={freeBtn}
                    block
                  >
                    Confirm Free Order
                  </Button>
                ) : monthly === true ? (
                  <Button
                    variant="outline-info"
                    onClick={monthlyCheckOut}
                    ref={monthBtn}
                    block
                  >
                    Confirm Monthly Acc Order
                  </Button>
                ) : (
                  <>
                    {/* <Button
                      variant="success"
                      onClick={createOzow}
                      ref={ozowBtn}
                      block
                    >
                      Ozow Instant EFT R{inv}
                    </Button> */}
                    <Button
                      variant="success mt-2"
                      onClick={createPeach}
                      ref={peachBtn}
                      block
                    >
                      Secure Card Payment R{inv}
                    </Button>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
  user: state.user.user,
  orders: state.orders,
  couponTotal: state.coupons.couponTotal,
});

export default connect(mapStateToProps, {
  Order,
  ozowPay,
  AccOrder,
  setBookingAddress,
  peachPayment,
  addTip,
  cancelTip,
  cancelThisCoupon,
})(CheckOut);
