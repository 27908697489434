import {
  CLEAR_PAY,
  HANDLE_PAYMENT,
  HANDLE_PEACH,
  CLEAR_HANDLE,
  GET_PEACH_ORDER,
} from '../types';
import axios from 'axios';

/////////////////////////////
/////Clear Peayment Params///
/////////////////////////////
export const clearPay = () => (dispatch) => {
  try {
    dispatch({ type: CLEAR_PAY });
  } catch (error) {
    console.error(error.message);
  }
};
/////////////////////////////
//////Make Ozow Payment//////
/////////////////////////////
export const ozowPay =
  ({ _id, invoice }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ _id, invoice });
    try {
      const res = await axios.post('/api/user/payments/ozow-pay', body, config);
      dispatch({ type: HANDLE_PAYMENT, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };
/////////////////
///Verify Ozow///
/////////////////
export const verifyOzow = (TransactionReference) => async (dispatch) => {
  try {
    const res = axios.get(
      '/api/user/payments/verify-ozow',
      TransactionReference
    );
    console.log(res.data);
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
//Peach Copy Pay ///
////////////////////
export const peachPayment =
  ({ orderId, amount }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ amount, orderId });
    try {
      const res = await axios.post(
        '/api/user/payments/peach-checkout',
        body,
        config
      );
      dispatch({ type: HANDLE_PEACH, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Check Payment Status//
export const checkPeachStatus = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/payments/peach-status/${id}`);
    dispatch({ type: GET_PEACH_ORDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
///Peach Payments////
/////////////////////
export const peachPay =
  ({ cardHolder, cardNumber, expMonth, expYear, cardCVV, invoice }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_HANDLE });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      cardHolder,
      cardNumber,
      expMonth,
      expYear,
      cardCVV,
      invoice,
    });
    try {
      const res = await axios.post(
        '/api/user/payments/peach-payments',
        body,
        config
      );
      dispatch({ type: HANDLE_PEACH, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////
///Cancel Peached///
////////////////////
export const cancelPeach =
  ({ peachLoad }) =>
  async (dispatch) => {
    try {
      dispatch({ type: HANDLE_PEACH, payload: peachLoad });
    } catch (error) {
      console.error('Oops', error.message);
    }
  };

export const submitCoupon =
  ({ coupon, orderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ coupon, orderId });
    try {
      const res = await axios.post('/api/user/payments/coupons', body, config);
      console.log(res.data);
    } catch (error) {
      console.error(error.message);
    }
  };
