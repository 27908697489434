import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchAffiliates } from '../../../actions/admin/affiliate';
import { useNavigate } from 'react-router-dom';
import AddAffiliate from './AddAffiliate';
import AffilaiteItem from './AffilaiteItem';

const Affilaites = ({
  fetchAffiliates,
  affiliate: { loading, affiliates },
  note,
}) => {
  useEffect(() => {
    fetchAffiliates();
  }, [fetchAffiliates]);
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Row>
        <Col>
          {' '}
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>{' '}
          <h4 style={{ color: '#06a5dd' }}>Affiliates</h4>
        </Col>
        <Col>
          <AddAffiliate note={note} />
        </Col>
      </Row>

      <hr />
      {loading ? (
        <Container>
          <Spinner animation="grow" variant="primary" />
        </Container>
      ) : (
        <Table striped bordered hover>
          <thead>
            <th>Affiliate Name</th>
            <th>Affiliate Email</th>
            <th>Affiliate Coupon</th>
            <th>Affiliate Address</th>
            <th>View</th>
          </thead>
          <tbody>
            {affiliates.length === 0 ? (
              <p>No Affiliates</p>
            ) : (
              affiliates.map((affiliate) => (
                <AffilaiteItem key={affiliate._id} affiliate={affiliate} />
              ))
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  affiliate: state.affiliate,
  note: state.note,
});

export default connect(mapStateToProps, { fetchAffiliates })(Affilaites);
