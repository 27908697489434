import { useEffect } from 'react';
import { Container, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { repeatOrdersThisMonthAll } from '../../../actions/admin/orders';
import ReOrderItem from './ReOrderItem';
import { useNavigate } from 'react-router-dom';

const ReOrdersForTheMonth = ({ repeatOrdersThisMonthAll, reOrdersMonth }) => {
  useEffect(() => {
    repeatOrdersThisMonthAll();
  }, [repeatOrdersThisMonthAll]);

  let navigate = useNavigate();

  if (reOrdersMonth === null)
    return (
      <Container>
        <Spinner variant="primary" animation="grow" />
      </Container>
    );

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4 style={{ color: '#06a5dd' }} className="mb-4 ">
        This months orders
      </h4>
      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>Client Name</th>
            <th>First checkin</th>
            <th>Address</th>
            <th>Payment Status</th>
            <th>Cleaner assigned</th>
            <th>Booking Status</th>
            <th>Next checkin</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {reOrdersMonth.orders.length === 0
            ? 'No Orders this month'
            : reOrdersMonth.orders.map((o) => (
                <ReOrderItem key={o._id} o={o} />
              ))}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  reOrdersMonth: state.admin_orders.reOrdersMonth,
});
export default connect(mapStateToProps, { repeatOrdersThisMonthAll })(
  ReOrdersForTheMonth
);
