import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const DaysOfTheWeek = ({
  Mon,
  monday,
  Tue,
  tuesday,
  Wed,
  wednesday,
  Thu,
  thursday,
  Fri,
  friday,
  Sat,
  saturday,
  Sun,
  sunday,
}) => {
  return (
    <div>
      <Row className="mt-3">
        <Col>
          <Button
            variant={Mon ? 'info' : 'outline-info'}
            onClick={monday}
            block
          >
            Mon
          </Button>
        </Col>
        <Col>
          <Button
            variant={Tue ? 'info' : 'outline-info'}
            onClick={tuesday}
            block
          >
            Tue
          </Button>
        </Col>
        <Col>
          <Button
            variant={Wed ? 'info' : 'outline-info'}
            onClick={wednesday}
            block
          >
            Wed
          </Button>
        </Col>
        <Col>
          <Button
            variant={Thu ? 'info' : 'outline-info'}
            onClick={thursday}
            block
          >
            Thu
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant={Fri ? 'info' : 'outline-info'}
            onClick={friday}
            block
          >
            Fri
          </Button>
        </Col>
        <Col>
          <Button
            variant={Sat ? 'info' : 'outline-info'}
            onClick={saturday}
            block
          >
            Sat
          </Button>
        </Col>
        <Col>
          <Button
            variant={Sun ? 'info' : 'outline-info'}
            onClick={sunday}
            block
          >
            Sun
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DaysOfTheWeek;
