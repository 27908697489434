import axios from 'axios';
import { MY_ORDER_INV, MY_INVOICE } from '../types';

export const orderInvoice = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/invoice/order-invoice/${orderId}`);
    dispatch({ type: MY_ORDER_INV, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const getInvoice = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/invoice/invoice/${orderId}`);
    dispatch({ type: MY_INVOICE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
