import React, { useEffect } from 'react';
import {
  Col,
  Container,
  Image,
  ListGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getACarpertClean } from '../../../actions/admin/carpert_cleaning';
import moment from 'moment';
import WhatToDo from './WhatToDo';

const CarpertClean = ({
  admin_carpert_cleans: { carpert_clean },
  getACarpertClean,
}) => {
  const { carpertId } = useParams();
  useEffect(() => {
    getACarpertClean(carpertId);
  }, [carpertId, getACarpertClean]);
  let navigate = useNavigate();

  if (carpert_clean === null) {
    return (
      <Container>
        <Spinner variant="primary" animation="grow" />
      </Container>
    );
  }

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Row>
        <Col xs={6}>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>{' '}
          <h4 style={{ color: '#06a5dd' }}>
            Carpet clean for {carpert_clean.user.name}
          </h4>
        </Col>
        <Col xs={6}>
          <b>Email: </b> {carpert_clean.user.email} | <b>Cellphone: </b>{' '}
          {carpert_clean.user.cellphone}
        </Col>
      </Row>

      <hr />
      <Row>
        <Col md={7}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <b> Date:</b>{' '}
              {moment(carpert_clean.orderDate).format('MMMM Do YYYY')} @{' '}
              <b>Location:</b>
              {carpert_clean.address} - {carpert_clean.addressType}{' '}
              {carpert_clean.addressType !== 'House' &&
                `- ${carpert_clean.complex}`}
            </ListGroup.Item>
            {carpert_clean.whatToDo.length < 0
              ? 'Loading your carpert clean'
              : carpert_clean.whatToDo.map((todo) => (
                  <WhatToDo key={todo.name} todo={todo} />
                ))}
          </ListGroup>
          {carpert_clean.code === undefined ? (
            <b style={{ color: 'red' }}>Order not paid for!</b>
          ) : (
            <Image
              src={carpert_clean.code}
              style={{ height: '250px', width: '250px' }}
            />
          )}
        </Col>
        <Col md={5}>Order Management</Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_carpert_cleans: state.admin_carpert_cleans,
});

export default connect(mapStateToProps, { getACarpertClean })(CarpertClean);
