import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Dropdown,
  ButtonGroup,
  Col,
  Row,
} from 'react-bootstrap';

const AdminUser = ({ createAdmin, note, fetchEmployees }) => {
  const [show, setShow] = useState(false);
  const [department, setDepartment] = useState(null);

  useEffect(() => {
    if (note.length > 0 && btn.current) {
      btn.current.removeAttribute('disabled');
      fetchEmployees();
      setFormData({
        name: '',
        email: '',
        password: '',
        password2: '',
      });
      setDepartment(null);
      setShow(false);
    }
  }, [note, fetchEmployees]);

  const handleClose = () => {
    setFormData({
      name: '',
      email: '',
      password: '',
      password2: '',
    });
    setDepartment(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
  });

  let btn = useRef();

  const { name, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [showPassword, setShowPassword] = useState('password');

  const togglePassword = () => {
    if (showPassword === 'password') setShowPassword('text');
    if (showPassword === 'text') setShowPassword('password');
  };

  const create = (e) => {
    e.preventDefault();
    createAdmin({
      name,
      email,
      password,
      department,
    });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <>
      <Button
        variant="secondary float-right ml-3"
        size="sm"
        onClick={handleShow}
      >
        Add Employee
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              validate
            />
          </Form.Group>
          <Row className="mb-3">
            <Col>
              <Form.Label>Department:</Form.Label>
            </Col>
            <Col>
              <Dropdown as={ButtonGroup}>
                <Button variant="outline-secondary float-right" size="sm">
                  {department === null ? 'Select department' : `${department}`}
                </Button>
                <Dropdown.Toggle
                  split
                  variant="outline-secondary float-right"
                ></Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setDepartment('HR')}>
                    HR
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setDepartment('Operations')}>
                    Operations
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setDepartment('Finance')}>
                    Finance
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          Create Password:
          <InputGroup className="mb-3">
            <FormControl
              type={showPassword}
              placeholder="Create password for Duster"
              value={password}
              name="password"
              onChange={(e) => onChange(e)}
            />
            <InputGroup.Text>
              {showPassword === 'password' ? (
                <i className="fas fa-eye-slash" onClick={togglePassword}></i>
              ) : (
                <i
                  className="fas fa-eye text-success"
                  onClick={togglePassword}
                ></i>
              )}
            </InputGroup.Text>
          </InputGroup>
          Confirm Password:
          <InputGroup className="mb-3">
            <FormControl
              type={showPassword}
              placeholder="Confirm password for Duster"
              value={password2}
              name="password2"
              onChange={(e) => onChange(e)}
            />
            <InputGroup.Text>
              {showPassword === 'password' ? (
                <i className="fas fa-eye-slash" onClick={togglePassword}></i>
              ) : (
                <i
                  className="fas fa-eye text-success"
                  onClick={togglePassword}
                ></i>
              )}
            </InputGroup.Text>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {password.length > 7 && password === password2 ? (
            <Button variant="success" onClick={create} ref={btn}>
              Create admin
            </Button>
          ) : (
            <Button variant="light">Fill in admin details</Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminUser;
