import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { editDusterReview } from '../../../actions/user/review';
import { Button, ListGroup, Modal, Form } from 'react-bootstrap';

const EditReview = ({ orderReview, bookedCleaner, editDusterReview }) => {
  const [show, setShow] = useState(false);
  const [review, setReview] = useState(null);
  const [rating, setRating] = useState(null);
  const [reviewId, setReviewId] = useState(null);

  useEffect(() => {
    if (orderReview) {
      setReviewId(orderReview._id);
      setReview(orderReview.review);
      setRating(orderReview.rating.toString());
    }
  }, [orderReview]);

  useEffect(() => {
    if (review === null) {
      setReview(orderReview.review);
      setRating(orderReview.rating.toString());
    }
  }, [review, orderReview]);

  const handleClose = () => {
    setReview(null);
    setRating(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const editReview = () => {
    editDusterReview({ review, rating, reviewId });
    setShow(false);
  };
  return (
    <div className="mt-2">
      <ListGroup>
        <ListGroup.Item variant="secondary">Your Review</ListGroup.Item>
        <ListGroup.Item>
          Review: <b className="float-right">{orderReview.review}</b>
        </ListGroup.Item>
        <ListGroup.Item>
          Rating:
          <Button className="float-right" size="sm">
            {orderReview.rating} <i className="far fa-star"></i>
          </Button>
        </ListGroup.Item>
        <Button variant="outline-warning" onClick={handleShow} block>
          Edit Review
        </Button>
      </ListGroup>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edite {bookedCleaner.name} {bookedCleaner.surname} review
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Write Review</Form.Label>
              <Form.Control
                type="text"
                name="review"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <Form.Group className="text-center">
              <Form.Label>Star Rating</Form.Label>
              <div>
                <Button
                  variant={
                    rating === '1' ? 'danger mr-2' : 'outline-danger mr-2'
                  }
                  value="1"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  1 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '2' ? 'danger mr-2' : 'outline-danger mr-2'
                  }
                  value="2"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  2 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '3' ? 'warning mr-2' : 'outline-warning mr-2'
                  }
                  value="3"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  3 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '4' ? 'success mr-2' : 'outline-success mr-2'
                  }
                  value="4"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  4 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '5' ? 'success mr-2' : 'outline-success mr-2'
                  }
                  value="5"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  5 <i className="far fa-star"></i>
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Exit
          </Button>
          <Button variant="warning text-white" onClick={editReview}>
            Edit Review
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, { editDusterReview })(EditReview);
