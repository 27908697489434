import {
  MY_TEAM_PROFILE,
  TEAM_PROFILE,
  ADD_TEAM_PROFILE,
  TEAM_PROFILE_ERROR,
  CLEAR_PROFILE,
  CLEAR_PROFILES,
} from '../../actions/types';

const initialState = {
  profile: null,
  profiles: null,
  loadingProfile: true,
};

function teamProfileReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MY_TEAM_PROFILE:
    case TEAM_PROFILE:
    case ADD_TEAM_PROFILE:
      return {
        ...state,
        profile: payload,
        loadingProfile: false,
      };
    case CLEAR_PROFILES:
      return {
        ...state,
        profiles: null,
        loadingProfile: false,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        loadingProfile: true,
      };
    case TEAM_PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loadingProfile: false,
      };
    default:
      return state;
  }
}

export default teamProfileReducer;
