import { ALL_COUPONS, COUPON_NUM } from '../../actions/types';
const initState = {
  loading: true,
  coupons: null,
  coupon: null,
  coupon_num: 0,
};

function adminCoupons(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_COUPONS:
      return {
        ...state,
        coupons: payload,
        loading: false,
      };
    case COUPON_NUM:
      return {
        ...state,
        coupon_num: payload,
      };
    default:
      return state;
  }
}

export default adminCoupons;
