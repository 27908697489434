import axios from 'axios';
import {
  ALL_QUERIES,
  ALL_QUERIES_NUM,
  ASSIGNED_QUERIES,
  UN_ASSIGNED_QUERIES,
  QUERIES_IM_ON,
  ORDER_QUERY,
  SETUP_QUERY_SOCKET,
  CLEAR_QUERY_SOCKET,
  CLEAR_QUERIES,
  QUERY_TEXTS,
  ORDER_QUERY_DUSTER,
  CLOSED_QUERIES,
  CLOSED_QUERIES_NUM,
} from '../types';
import io from 'socket.io-client';
import { setNote } from '../note';

//All Queries Number//
export const getQueriesNumbers = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/admin_queries/all-active-queries-numbers'
    );

    dispatch({ type: ALL_QUERIES_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//All queries//
export const allQueries = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/admin_queries/all-queries');

    dispatch({ type: ALL_QUERIES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get all closed queries//
export const closedQueries = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/admin_queries/closed-queries-number'
    );
    dispatch({ type: CLOSED_QUERIES_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
//Get all closed queries//
export const closedQueriesNum = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/admin_queries/closed-queries');
    dispatch({ type: CLOSED_QUERIES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get All Assigned Queries//
export const assignedQueries = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/admin_queries/assigned-queries');
    dispatch({ type: ASSIGNED_QUERIES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get unassigned queries//
export const unassignedQueries = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/admin_queries/un-assigned-queries');
    dispatch({ type: UN_ASSIGNED_QUERIES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get queries im on//
export const queriesImOn = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/admin_queries/queries-im-on');
    dispatch({ type: QUERIES_IM_ON, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch Query//
export const fetchQuery = (id) => async (dispatch) => {
  dispatch({ type: CLEAR_QUERIES });
  try {
    const res = await axios.get(`/api/admin/admin_queries/order-query/${id}`);

    dispatch({ type: ORDER_QUERY, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Setup and clear sockets//
export const setupQuerySocket = (id) => async (dispatch) => {
  dispatch({ type: CLEAR_QUERY_SOCKET });
  const socket = io();
  try {
    socket.on('connect', () => {
      socket.emit('join_query', { queryId: id });
      dispatch({ type: SETUP_QUERY_SOCKET, payload: socket });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch Query texts//
export const fetchQueryTexts = (order) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/admin_queries/fetch-query-texts/${order}`
    );
    dispatch({ type: QUERY_TEXTS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch query cleaner//
export const fetchTheCleaner = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/admin_queries/fetch-query-cleaner/${dusterId}`
    );
    dispatch({ type: ORDER_QUERY_DUSTER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Assign the query//
export const claimQuery =
  ({ queryId, deviceToken, email, name }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ deviceToken, email, name });
    try {
      const res = await axios.put(
        `/api/admin/admin_queries/assign-query/${queryId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//Close the query//
export const closeQuery =
  ({ queryId, deviceToken, name, email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ deviceToken, name, email });
    try {
      const res = await axios.put(
        `/api/admin/admin_queries/order-query/set-as-complete/${queryId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(fetchQuery(queryId));
    } catch (error) {
      console.error(error.message);
    }
  };
