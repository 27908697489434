import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Spinner,
  Alert,
  Button,
  ListGroup,
  Col,
  Row,
} from 'react-bootstrap';
import {
  assignedQueries,
  unassignedQueries,
  queriesImOn,
} from '../../../actions/admin/admin_queries';

const AllQueries = ({
  admin_queries: { assigned, unassigned, mine },
  assignedQueries,
  unassignedQueries,
  queriesImOn,
}) => {
  useEffect(() => {
    assignedQueries();
    unassignedQueries();
    queriesImOn();
  }, [assignedQueries, unassignedQueries, queriesImOn]);
  const navigate = useNavigate();

  if (assigned === null || unassigned === null || mine === null)
    return <Spinner variant="primary" animation="grow" />;

  const goBack = () => {
    navigate('/admin/order-management');
  };

  return (
    <Container>
      <Alert variant="secondary text-center">
        <Button onClick={goBack} size="sm" variant="light float-left">
          Operations
        </Button>
        Queries
        <Button
          size="sm"
          variant="success float-right"
          as={Link}
          to="/admin/queries/completed-queries"
        >
          Closed queries
        </Button>
      </Alert>
      <Row>
        <Col>
          <Alert variant="warning">Unassigned queries</Alert>
          <ListGroup variant="flush text-center">
            {unassigned.length === 0 ? (
              <Alert>There are no queries</Alert>
            ) : (
              unassigned.map((query) => (
                <ListGroup.Item key={query._id}>
                  Query Headline: {query.headline}
                  <Button
                    variant="outline-warning float-right"
                    to={`/admin/order-query/${query._id}`}
                    as={Link}
                    size="sm"
                  >
                    View query
                  </Button>
                </ListGroup.Item>
              ))
            )}
          </ListGroup>
        </Col>
        <Col>
          <Alert variant="info">Assigned Queries</Alert>
          <ListGroup variant="flush text-center">
            {assigned.length === 0 ? (
              <Alert>No queries</Alert>
            ) : (
              assigned.map((query) => (
                <ListGroup.Item key={query._id}>
                  Query Headline: {query.headline}
                  <Button
                    variant="outline-warning float-right"
                    to={`/admin/order-query/${query._id}`}
                    as={Link}
                    size="sm"
                  >
                    View query
                  </Button>
                </ListGroup.Item>
              ))
            )}
          </ListGroup>
        </Col>
        <Col>
          <Alert variant="light">Your Queries</Alert>
          <ListGroup variant="flush text-center">
            {mine.length === 0 ? (
              <Alert>No queries</Alert>
            ) : (
              mine.map((query) => (
                <ListGroup.Item key={query._id}>
                  Query Headline: {query.headline}
                  <Button
                    variant="outline-warning float-right"
                    to={`/admin/order-query/${query._id}`}
                    as={Link}
                    size="sm"
                  >
                    View query
                  </Button>
                </ListGroup.Item>
              ))
            )}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  admin_queries: state.admin_queries,
});
export default connect(mapStateToProps, {
  assignedQueries,
  unassignedQueries,
  queriesImOn,
})(AllQueries);
