import React, { useState } from 'react';
import { connect } from 'react-redux';
import { customRate } from '../../../../actions/admin/main';
import { Button, Form, Modal } from 'react-bootstrap';

const CustomRateCard = ({ currentUser, customRate, userId }) => {
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    four: '',
    five: '',
    six: '',
    seven: '',
    eight: '',
    nine: '',
    ten: '',
  });

  const { four, five, six, seven, eight, nine, ten } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const createCard = (e) => {
    e.preventDefault();
    customRate({ four, five, six, seven, eight, nine, ten, userId });
    setShow(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} size="sm">
        Create Custom Rate Card
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={createCard}>
          <Modal.Header closeButton>
            <Modal.Title>Custom Rate Card for {currentUser}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>4 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder="How Much?"
                name="four"
                value={four}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder="How Much?"
                name="five"
                value={five}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>6 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder="How Much?"
                name="six"
                value={six}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>7 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder="How Much?"
                name="seven"
                value={seven}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>8 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder="How Much?"
                name="eight"
                value={eight}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>9 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder="How Much?"
                name="nine"
                value={nine}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>10 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder="How Much?"
                name="ten"
                value={ten}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Create Custum Rate
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, { customRate })(CustomRateCard);
