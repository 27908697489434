import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, Container, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reNotBookedAll } from '../../../actions/admin/orders';
import ReOrderItem from './ReOrderItem';

function ReOrderNotAllocated({ reNotBookedAll, orders }) {
  useEffect(() => {
    reNotBookedAll();
  }, [reNotBookedAll]);
  const navigate = useNavigate();
  if (orders === null) {
    return (
      <Container>
        <Spinner variant="primary" animation="grow" />
      </Container>
    );
  }
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4 style={{ color: '#06a5dd' }} className="mb-4 ">
        Orders not allocated
      </h4>
      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>Client Name</th>
            <th>First checkin</th>
            <th>Address</th>
            <th>Payment Status</th>
            <th>Cleaner assigned</th>
            <th>Booking Status</th>
            <th>Next checkin</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {orders.orders.length === 0
            ? 'No Orders this month'
            : orders.orders.map((o) => <ReOrderItem key={o._id} o={o} />)}
        </tbody>
      </Table>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  orders: state.admin_orders.re_orders_notbooked,
});

export default connect(mapStateToProps, { reNotBookedAll })(
  ReOrderNotAllocated
);
