import React from 'react';
import { Alert, Button, Row, Col } from 'react-bootstrap';
import TimeSheet from './TimeSheet';

const ConfirmDateAndTime = ({
  regular,
  resetDT,
  schedule,
  setStage,
  setPage,
  invoiceTime,
}) => {
  const nextStage = () => {
    setStage(3);
    setPage(null);
  };
  return (
    <div className="mt-4">
      {regular ? (
        <>
          <Alert variant="info text-center">
            <Row>
              <Col>The first clean will be on {invoiceTime}</Col>
              <Col>
                <Button
                  variant="warning float-right"
                  size="sm"
                  onClick={resetDT}
                >
                  Change Arrival Date & Time
                </Button>
              </Col>
            </Row>
          </Alert>
          <h5 className="text-center">Appointment Summary:</h5>
          <TimeSheet schedule={schedule} />
          <Button variant="outline-info mt-3" id="next" onClick={nextStage}>
            Next Step <i className="fas fa-fast-forward ml-3"></i>
          </Button>
        </>
      ) : (
        <>
          <Alert variant="info text-center">
            <Row>
              <Col>Cleaner will arrive on {invoiceTime}</Col>
              <Col>
                <Button
                  variant="warning float-right"
                  size="sm"
                  onClick={resetDT}
                >
                  Change Arrival Date & Time
                </Button>
              </Col>
            </Row>
          </Alert>

          <Button variant="outline-info mt-3" id="next" onClick={nextStage}>
            Next Step <i className="fas fa-fast-forward ml-3"></i>
          </Button>
        </>
      )}
    </div>
  );
};

export default ConfirmDateAndTime;
