import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import AddressAutocomplete from '../../../Layout/AddressAutocomplete';

const EditUser = ({ user, updateClient, note }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0 && btn.current) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [complex, setComplex] = useState('');
  const [addressType, setAddressType] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [cellphone, setCellphone] = useState('');
  const [userId, setUserId] = useState('');
  let btn = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setSurname(user.surname);
      setEmail(user.email);
      setAddress(user.address);
      setComplex(user.complex);
      setAddressType(user.addressType);
      setCoordinates(user.coordinates);
      setCellphone(user.cellphone);
      setUserId(user._id);
    }
  }, [user]);

  const changeAddressType = (newType) => {
    setAddressType(newType);
  };

  const updateUser = () => {
    updateClient({
      name,
      surname,
      email,
      cellphone,
      address,
      addressType,
      complex,
      coordinates,
      userId,
    });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <>
      <Button
        variant="outline-primary mt-2 mr-2"
        onClick={handleShow}
        size="sm"
      >
        Edit <i className="fa-solid fa-user-pen"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name: </Form.Label>
            <Form.Control
              type="text"
              placeholder={name}
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Surname: </Form.Label>
            <Form.Control
              type="text"
              placeholder={surname}
              name="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email: </Form.Label>
            <Form.Control
              type="email"
              placeholder={email}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Cellphone: </Form.Label>
            <Form.Control
              type="number"
              placeholder={cellphone}
              name="cellphone"
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Address:</Form.Label>
            <AddressAutocomplete
              value={address}
              setAddress={setAddress}
              setCoordinates={setCoordinates}
              placeholder={address}
            />
            <Form.Text className="text-muted">
              Current user address: <b className="text-success">{address}</b>
            </Form.Text>
          </Form.Group>
          <Row className="mt-4">
            <Col xs={3}>
              <Button
                variant={
                  addressType === 'House' ? 'outline-info' : 'outline-secondary'
                }
                onClick={() => changeAddressType('House')}
              >
                <span style={{ fontSize: 50 }}>
                  <i className="fa-solid fa-house"></i>
                </span>
              </Button>
              House
            </Col>
            <Col xs={3}>
              <Button
                variant={
                  addressType === 'Townhouse/Estate'
                    ? 'outline-info'
                    : 'outline-secondary'
                }
                onClick={() => changeAddressType('Townhouse/Estate')}
              >
                <span style={{ fontSize: 50 }}>
                  <i className="fa-solid fa-people-roof"></i>
                </span>
              </Button>
              Townhouse
            </Col>
            <Col xs={3}>
              <Button
                variant={
                  addressType === 'Apartment/Flat'
                    ? 'outline-info'
                    : 'outline-secondary'
                }
                onClick={() => changeAddressType('Apartment/Flat')}
              >
                <span style={{ fontSize: 50 }}>
                  <i className="fa-solid fa-city"></i>
                </span>
              </Button>
              Apartment/Flat
            </Col>
            <Col xs={3}>
              <Button
                variant={
                  addressType === 'Office'
                    ? 'outline-info'
                    : 'outline-secondary'
                }
                onClick={() => changeAddressType('Office')}
              >
                <span style={{ fontSize: 50 }}>
                  <i className="fa-regular fa-building"></i>
                </span>
              </Button>
              Office
            </Col>
          </Row>
          {addressType !== 'House' && (
            <Form.Group>
              <Form.Label>Complex: </Form.Label>
              <Form.Control
                type="text"
                placeholder={complex}
                name="complex"
                value={complex}
                onChange={(e) => setComplex(e.target.value)}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" ref={btn} onClick={updateUser}>
            Update user
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUser;
