import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddressItem from './AddressItem';

const ViewUserAddresses = ({ userAddresses }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button variant="outline-secondary mt-2" onClick={handleShow}>
        View Addresses
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Addresses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userAddresses.map((userAdr) => (
            <AddressItem key={userAdr._id} userAdr={userAdr} />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewUserAddresses;
