import axios from 'axios';
import {
  PAID_ORDERS,
  UNPAID_ORDERS,
  UNPAID_REORDERS,
  GET_ORDER,
  GET_RE_ORDER,
  CLEAR_ORDER,
  NO_CLEANER,
  NO_RECLEANER,
  COMPLETED_ORDERS,
  COMPLETED_REORDERS,
  ACTIVE_ORDERS,
  ACTIVE_REORDERS,
  GET_ORDERS,
  GET_RE_ORDERS,
  REPEAT_ORDER,
  GET_OZOW_ORDER,
  GET_PEACH_ORDER,
  GET_REPEACH_ORDER,
  CLEANER_ALLOCATED,
  RECLEANER_ALLOCATED,
  ASSIGNED_DUSTER,
  DUSTERS_NEAR_ME,
  ALL_ORDERS,
  ALL_RE_ORDERS,
  ORDER_COMMENTS,
  RE_ORDER_COMMENTS,
  CLEAR_COMMENTS,
  WHO_CLEAN_LAST,
  DUSTER_OPTIONS,
  DUSTER_OPTION_RATING,
  FROM_THE_OFFICE,
} from '../types';
import { setNote } from '../note';

///Clean the duster//
export const cleanDuster = () => (dispatch) => {
  dispatch({ type: ASSIGNED_DUSTER, payload: null });
};

//////////////////////////////
//////Fetchhing 5 orders////
//////////////////////////////
export const profileOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders');
    dispatch({ type: GET_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
//Fetch All Orders//
////////////////////
export const myOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/all-orders');
    dispatch({ type: ALL_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//Fetch Repeat Orders//
///////////////////////
export const myReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/all-re-orders');
    dispatch({ type: ALL_RE_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

// //How far from the office//
export const distanceFromOffice =
  ({ clientLat, clientLng }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ clientLat, clientLng });

    try {
      const res = await axios.put(
        '/api/user/orders/allowed-to-order',
        body,
        config
      );
      console.log(res.data);
      dispatch({ type: FROM_THE_OFFICE, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////
//Available dusters//
/////////////////////
export const checkCleaners =
  ({ findme }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ findme });
    try {
      const res = await axios.put(
        '/api/user/orders/area-dusters',
        body,
        config
      );
      dispatch({ type: DUSTERS_NEAR_ME, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////////////////
///Fetching 5 Repeat orders////
///////////////////////////////////
export const profileReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/repeat-orders');
    dispatch({ type: GET_RE_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
///Get Paid Orders///
////////////////////
export const GetPaidOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/paid-orders');
    dispatch({ type: PAID_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////
//Orders without cleaner///
//////////////////////////
export const awaitingCleaner = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/awaiting-cleaner');
    dispatch({ type: NO_CLEANER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////
//Cleaner Allocated///
//////////////////////
export const cleanerAllocated = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/cleaner-allocated');
    dispatch({ type: CLEANER_ALLOCATED, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////
/////Active Orders////////
//////////////////////////
export const activeOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/active-orders');
    dispatch({ type: ACTIVE_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////
///Completed Orders///////
//////////////////////////
export const doneOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/completed-orders');
    dispatch({ type: COMPLETED_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////
///Get Unpaid Orders/////
/////////////////////////
export const GetUnPaidOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/unpaid-orders');
    dispatch({ type: UNPAID_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
/////Repeat Orders/////
///////////////////////
////////////////////////
//Unpain Repeat Orders//
////////////////////////
export const GetUnPaidReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/unpaid-reorders');
    dispatch({ type: UNPAID_REORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////////////
//Waiting cleaner Repeat Orders//
/////////////////////////////////
export const awaitingCleanerRe = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/awaiting-recleaner');
    dispatch({ type: NO_RECLEANER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////////
//Cleaner Allocated Repeat Orders///
////////////////////////////////////
export const reCleanerAllocated = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/recleaner-allocated');
    dispatch({ type: RECLEANER_ALLOCATED, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
///////////////////////////
//Completed Repeat Orders//
///////////////////////////
export const doneReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/completed-reorders');
    dispatch({ type: COMPLETED_REORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////
//Active Repeat Orders//
////////////////////////
export const activeReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/orders/active-reorders');
    dispatch({ type: ACTIVE_REORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////
////Get a specific order///
///////////////////////////
export const GetOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/orders/my-order/${orderId}`);
    dispatch({ type: GET_ORDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////
//Create order comment//
////////////////////////
export const createComment =
  ({ order, text }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ order, text });
    try {
      const res = await axios.post(
        '/api/user/orders/my-order/create-comment',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(fetchOrderComments(order));
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////////
//Fetch order comments//
////////////////////////
export const fetchOrderComments = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_COMMENTS });
  try {
    const res = await axios.get(
      `/api/user/orders/my-order/comments/${orderId}`
    );
    dispatch({ type: ORDER_COMMENTS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//duster on the order//
///////////////////////
export const fetchDuster = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/orders/my-order/duster/${dusterId}`);
    dispatch({ type: ASSIGNED_DUSTER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////
//Get a specific Re Order//
///////////////////////////
export const GetReOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/orders/my-re-order/${orderId}`);
    dispatch({ type: GET_RE_ORDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////
//Repeat order add comment//
////////////////////////////
export const reComment =
  ({ reOrder, text }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ reOrder, text });
    try {
      const res = await axios.post(
        '/api/user/orders/my-re-order/create-comment',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(fetchReComments(reOrder));
    } catch (error) {
      console.error(error.message);
    }
  };
//////////////////////////
//Fetch ReOrderComment//
//////////////////////////
export const fetchReComments = (reOrderId) => async (dispatch) => {
  dispatch({ type: CLEAR_COMMENTS });
  try {
    const res = await axios.get(
      `/api/user/orders/my-re-order/comments/${reOrderId}`
    );

    dispatch({ type: RE_ORDER_COMMENTS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//DeleteOrder
export const deleteOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/user/orders/my-order/${orderId}`);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.messgae);
  }
};

//Delete Repeat Order
export const delReOrder = (reOrderId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/user/orders/my-re-order/${reOrderId}`);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////
//Get available dusters//
/////////////////////////
export const getAvailableDusters =
  ({ coordinates, orderTime }) =>
  async (dispatch) => {
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      const body = JSON.stringify({ coordinates, orderTime });

      const res = await axios.put(
        '/api/user/orders/available-dust-busters',
        body,
        config
      );
      console.log(res.data);
      dispatch({ type: DUSTER_OPTIONS, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////
//Get Duster Rating//
/////////////////////
export const getDusterRating =
  ({ dusterIds }) =>
  async (dispatch) => {
    dispatch({ type: DUSTER_OPTION_RATING, payload: null });
    try {
      const promises = dusterIds.map(async (dusterId) => {
        const res = await axios.get(
          `/api/user/orders/dust-buster-rating/${dusterId}`
        );
        return res.data;
      });

      const results = await Promise.all(promises);
      dispatch({ type: DUSTER_OPTION_RATING, payload: results });
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////
//Who cleaned last//
////////////////////
export const whoCleanedLast =
  ({ regular, orderTime }) =>
  async (dispatch) => {
    try {
      //
      const body = JSON.stringify({ regular, orderTime });
      const config = { headers: { 'Content-Type': 'application/json' } };
      const res = await axios.put(
        '/api/user/orders/who-cleaned-last',
        body,
        config
      );
      dispatch({ type: WHO_CLEAN_LAST, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////////
////Monthly Account Order//
///////////////////////////
export const AccOrder =
  ({
    time,
    address,
    coordinates,
    bedroom,
    bathroom,
    mrx,
    invoiceHours,
    invoice,
    monthEnd,
    couponOrder,
    complex,
    nityGrity,
    changeHours,
    couponUsed,
    tip,
    team,
  }) =>
  async (dispatch) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let from = 'web';
    const body = JSON.stringify({
      time,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      monthEnd,
      couponOrder,
      complex,
      nityGrity,
      changeHours,
      couponUsed,
      tip,
      team,
      from,
    });
    try {
      const res = await axios.post(
        '/api/user/orders/monthly-acc-order',
        body,
        config
      );
      dispatch({ type: GET_ORDER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////
///Create A Order//////
///////////////////////
export const Order =
  ({
    time,
    address,
    coordinates,
    bedroom,
    bathroom,
    mrx,
    invoiceHours,
    invoice,
    paymentMethod,
    couponOrder,
    complex,
    nityGrity,
    whyLess,
    changeHours,
    couponUsed,
    tip,
    team,
    laundreyBasket,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let from = 'web';
    const body = JSON.stringify({
      time,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      paymentMethod,
      couponOrder,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      tip,
      team,
      from,
      laundreyBasket,
    });

    try {
      const res = await axios.post('/api/user/orders/once-off', body, config);

      dispatch({ type: GET_ORDER, payload: res.data });
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

///////////////////////////////////
///////Create Recurring Order//////
///////////////////////////////////
export const recurringOrder =
  ({
    time,
    cleans,
    address,
    coordinates,
    schedule,
    frequency,
    weekdays,
    bedroom,
    bathroom,
    mrx,
    invoiceHours,
    invoice,
    paymentMethod,
    couponOrder,
    complex,
    nityGrity,
    whyLess,
    changeHours,
    couponUsed,
    team,
    tip,
    laundreyBasket,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let from = 'web';
    const body = JSON.stringify({
      time,
      cleans,
      address,
      coordinates,
      schedule,
      frequency,
      weekdays,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      paymentMethod,
      couponOrder,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
      from,
      laundreyBasket,
    });
    try {
      const res = await axios.post('/api/user/orders/repeat', body, config);
      dispatch({ type: REPEAT_ORDER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////////
///Repeat Account Order//
//////////////////////////
export const RepeatAccOrder =
  ({
    time,
    cleans,
    address,
    coordinates,
    schedule,
    frequency,
    weekdays,
    bedroom,
    bathroom,
    mrx,
    invoiceHours,
    invoice,
    paymentMethod,
    couponOrder,
    complex,
    monthEnd,
    nityGrity,
    whyLess,
    changeHours,
    couponUsed,
    team,
    tip,
    laundreyBasket,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let from = 'web';

    const body = JSON.stringify({
      time,
      cleans,
      address,
      coordinates,
      schedule,
      frequency,
      weekdays,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      paymentMethod,
      couponOrder,
      complex,
      monthEnd,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
      from,
      laundreyBasket,
    });
    try {
      const res = await axios.post(
        '/api/user/orders/repeat-on-account',
        body,
        config
      );
      dispatch({ type: REPEAT_ORDER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////////////
///Confirm Ozow Order Payment/
//////////////////////////////
export const confirmOrder =
  ({ Status, Optional1, TransactionId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ Status, Optional1, TransactionId });
    try {
      const res = await axios.put(
        '/api/user/orders/confirm-ozow-order',
        body,
        config
      );
      dispatch({ type: GET_OZOW_ORDER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////////
///Confirm Peach Order/////
///////////////////////////
export const confirmPeach =
  ({ orderId, peachRef }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ orderId, peachRef });

    try {
      dispatch({ type: CLEAR_ORDER });
      const res = await axios.put(
        '/api/user/orders/confirm-peach-order',
        body,
        config
      );
      dispatch({ type: GET_PEACH_ORDER, payload: res.data });
    } catch (error) {
      console.error('We have a error: ', error.message);
    }
  };

//////////////////////////////
//Confirm Peach Repeat Order//
//////////////////////////////
export const confirmRePeach =
  ({ reOrderId, peachRef }) =>
  async (dispatch) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ reOrderId, peachRef });
    try {
      dispatch({ type: CLEAR_ORDER });
      const res = await axios.put(
        '/api/user/orders/confirm-peach-reorder',
        body,
        config
      );
      dispatch({ type: GET_REPEACH_ORDER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Clear Order//
export const clearLoot = () => (dispatch) => {
  dispatch({ type: CLEAR_ORDER });
};

///////////////////
//Cancel my order//
///////////////////
export const cancelOrder =
  ({ reason, orderId }) =>
  async (dispatch) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ reason });

    try {
      const res = await axios.put(
        `/api/user/orders/order-cancellation${orderId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };
