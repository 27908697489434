import React from 'react';
import Bottom from '../Layout/Bottom';
const UserAgree = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            Our Terms and Conditions for you as a user including our privacy and
            cancelation policy
          </h3>
        </div>
        <div>
          <b>Terms and conditions</b>
          <p>
            Welcome to the Dusting Down website terms and conditions for use;
          </p>
          <p>
            <b> I.</b> These Terms and Conditions apply to the use of this
            Website. By access and use of this Website and/or by making a
            Booking for Services rendered you agree to be bound by the Terms and
            Conditions set out herein. By not agreeing to the terms and
            conditions in the “I accept the Terms and Conditions” tab, your
            order will be cancelled and not processed. You are welcome to
            contact us regarding our terms and conditions on the following email
            address: legal@dustingdown.co.za
          </p>
          <b>II. DEFINITIONS</b>
          <p>
            • “Booking” means a booking for Services made by You on our Website
            or Mobile App
          </p>
          <p>
            • “Services” means any service, or advice or assistance or any other
            home, office or personal services.
          </p>
          <p>
            • “Dusting Down” means a cleaner or employee, subcontractor or
            independent contractor who may provide a service through our website
            or mobile app
          </p>
          <p> •“We/us” means Dusting Down and or FibreClean (PTY) Ltd </p>
          <p>
            • “Our Address” means Unit 7, Ferndale Mews; 355 Oak Avenue;
            Ferndale; Randburg; 2194
          </p>
          <p>
            •“Website” means the website located at
            https://www.dustingdown.co.za and/or dustingdown.com and/or
            dustingdown.co.uk and/or any subsequent URL or domain which may
            replace it.
          </p>
          <p>•You” means a user of this Website and/or Mobile App.</p>
          <p>
            • “Dust Buster” means an independent contractor who uses our
            App/Platform and/or Website to obtain contractual work or placement.
          </p>
          <b>1. USE OF OUR PLATFORM</b>
          <p>
            1.1. Access: You are provided with access to this Website in
            accordance with these Terms and Conditions, any Booking made by you
            must be placed strictly in accordance with the Terms and Conditions
            herein.
          </p>
          <p>
            1.2. By ticking the terms and condition box you thereby acknowledge
            our terms and conditions
          </p>
          <p>1.3. Registration </p>
          <p className="ml-4">
            1.3.1. By registering as a user of our website, you warrant that:
          </p>
          <p className="ml-4">
            1.3.2. The personal information provided by you upon registration
            must be true, accurate, and current. You acknowledge and accept that
            any inaccuracies in the provided information, particularly regarding
            your address, are solely your responsibility. Dusting Down shall not
            be liable for any services rendered to an incorrect address due to
            inaccurate information provided by you.1.3.3. You will notify and
            update the Dusting Down user portal immediately of any changes to
            this personal information.
          </p>
          <p className="ml-4">
            1.3.3. This update is totally and legally your responsibility, and
            services rendered to the incorrect address will not render us
            responsible.
          </p>
          <p className="ml-4">
            1.3.4. You agree not to impersonate any other person or entity or to
            use a false name(s) or a name(s) that you are not authorised to use.
          </p>
          <p className="ml-4">
            1.3.5. You also knowledge that if you change your phone number you
            will have to re-register on the platform.
          </p>
          <p className="ml-4">
            1.3.6. You also knowledge that if you change your phone number you
            will have to re-register on the platform.
          </p>
          <b>2. Data Protection</b>
          <p>
            Dusting Down is committed to protecting the privacy and personal
            information of its users in accordance with applicable data
            protection laws. By using our services, you consent to the
            collection, processing, and use of your personal data as outlined in
            our Privacy Policy. We employ industry-standard security measures to
            safeguard your information; however, no method of transmission over
            the internet or electronic storage is entirely secure. Therefore,
            while we strive to protect your personal data, we cannot guarantee
            its absolute security. Please refer to our Privacy Policy for more
            details on how we handle your data.
          </p>
          <b>3. Service Level Agreements</b>
          <p>
            Dusting Down strives to maintain high standards of service quality
            and timeliness. While we make every effort to meet the needs and
            expectations of our users, we do not provide explicit service level
            agreements (SLAs) regarding response times or service completion
            deadlines. However, we are committed to addressing any
            service-related issues or complaints promptly and effectively.
          </p>
          <b>4. Termination</b>
          <p>
            Dusting Down reserves the right to terminate or suspend user
            accounts or access to the platform at its discretion, without prior
            notice, in the event of any violation of these terms and conditions,
            fraudulent activities, or breaches of conduct deemed detrimental to
            the platform's integrity. Upon termination, users shall forfeit any
            rights or access to services provided by Dusting Down.
          </p>
          <b>5. OUR RIGHTS</b>
          <p>5.1. We reserve the right to:</p>
          <p>
            5.2. modify or withdraw, temporarily or permanently, this Website
            and/or App (or any part thereof) with or without notice to you and
            you confirm that we shall not be liable to you or any third party
            for any modification to or withdrawal of the Website/App; and/or the
            change to these Terms and Conditions from time to time, at our full
            discretion.
          </p>
          <p>
            5.3. We have the right to revise and amend these terms from time to
            time, at our discretion. You will be subject to the policies and
            terms in force at the time that you book Services from us.
          </p>
          <p>
            5.4. Dusting Down and or FibreClean (PTY) Ltd and our business
            partners may change from time to time. As a result, at times it may
            be necessary for Dusting Down to make changes to our Terms and
            Conditions.
          </p>
          <b>6. YOUR RIGHTS</b>
          <p>
            6.1. As a South African citizen, you have the right to ask us not to
            process your personal information for marketing purposes by
            contacting us.
          </p>
          <p>
            6.2. The South African Protection of Personal Information Act
            (POPI)2021 gives you the right to access information held about you.
            Any access request may be subject to an administrative fee of
            R750.00 per request.
          </p>
          <p>
            6.3.We may store information such as your name; your contact
            telephone numbers; your e-mail address; home and/or work or postal
            address that you have submitted; information about your home which
            you provide us; your payment details; your IP address; and any other
            personal information which you provide us in the sign up pages. We
            will use commercially reasonable efforts to honour your request.
          </p>
          <p>
            6.4. Your request must be presented in a formal application along
            with a certified copy of your identity document.
          </p>
          <p>
            6.5. Dusting Down does not offer any refunds for services provided,
            which is why we always encourage our customers to fully inspect the
            cleaning before your Dust Buster leaves to ensure that you are
            completely satisfied with the results.
          </p>
          <p>
            6.6. If you are dissatisfied with the service any issue must be
            reported within 24 hours of completion of the service. We will then
            attempt to rectify these issues.
          </p>
          <b>7. PRIVACY</b>
          <p>
            7.1. We process information about you in accordance with our Privacy
            Policy. By using our website, you consent to such processing, and
            you warrant that all data provided by you is accurate.
          </p>
          <p>
            7.2. We may retain an archived copy of your records as required by
            law, or for legitimate business purposes. Every effort has been made
            on this website, or mobile application to ensure that making a
            Booking is secure.
          </p>
          <p>
            7.3. The Website and/or App may be used only for lawful purposes and
            in a lawful manner. You agree to comply with all applicable laws,
            statutes and regulations regarding the Website and/or App and any
            transactions conducted on or through the Website and/or App
          </p>
          <b>8. THE DUSTING DOWN SERVICES WEB APPLICATION</b>
          <p>
            8.1. We function as a booking agency for self-employed independent
            contractors,
          </p>
          <p>
            8.1. Our contract with you is limited to provision of use of our
            website and/or app.
          </p>
          <p>
            8.2.Our contract with you is limited to provision of use of our
            website and/or app.
          </p>
          <p>
            8.3. Your Dusting Down contractor is responsible for providing the
            Services to you, as per the specifics you select on our platform.
          </p>
          <p>
            8.4. You have a direct contract between you and the Dusting Down
            contractor concerning the provision of Services..
          </p>
          <p>8.5. This contract does not include us.</p>
          <p>
            8.6. The Independent contractor has an independent contract with us
            allowing for all statutory deduction to be subtracted from his/her
            invoice to us, and we pay said amount over to said authorities.
          </p>
          <p>
            8.7. Independent Contractors are responsible for all taxes arising
            as a result of the Service.
          </p>
          <p>
            8.8. We review the application process, conduct interviews, and
            undertake other checks. The independent contractors are also
            required to attend certain training update seminars each quarter to
            remain vetted by Dusting Down.
          </p>
          <p>
            8.9. Some of these processes are reliant on information from third
            parties.
          </p>
          <p>
            8.10.We do not and cannot guarantee the accurateness or completeness
            of such checks, whether conducted by us or by third parties.
          </p>
          <p>
            8.11. You acknowledge that you use the Dusting Down’s appointed
            independent contractors at your own risk.
          </p>
          <p>
            8.12. You agree to provide a safe working environment for the team
            and to take appropriate precautions to supervise your appointed
            Collaborator.
          </p>
          <b>9. BOOKING SERVICES</b>
          <p>
            9.1. By placing an order through our website and/or app, you warrant
            that you are: legally capable of entering into binding contracts,
            and are at least 18 years old.
          </p>
          <p>9.2. All Bookings are subject to acceptance by us,</p>
          <p>
            9.3. We will confirm such acceptance to you by sending you an email
            and/or push notification.
          </p>
          <p>9.4. The Notification will include the following</p>
          <p>9.5. Contractor’s name and telephone number.</p>
          <p>9.6. Total estimate cost</p>
          <p>9.7. Date and Time the service was booked for.</p>
          <p>
            9.8. The contracts between us will only be concluded when we send
            you the booking Confirmation.
          </p>
          <b>10. ENGAGING PRIVATELY WITH THE CONTRACTOR</b>
          <p>
            10.1. You warrant that you will not seek to book any Dusting Down
            Services or contractor other than via our website or app for the
            duration of this agreement and in the subsequent 3 (Three) months
            after termination of this agreement.
          </p>
          <p>
            10.2. In the event that you offer our Team members or independent
            contractors’ alternative employment or opportunities you will be
            invoiced and agree to pay a placement fee of no less than that of
            150 hours of your average invoice amount.
          </p>
          <b>11. PRICE AND PAYMENT</b>
          <p>
            11.1. The price of any Services will be as quoted on our booking
            tool.
          </p>
          <p>
            11.2. Special cleans, deep cleans and office cleaning will be quoted
            by our office.
          </p>
          <p>
            11.3. Prices are liable to change at any time, but changes will not
            affect orders in respect of which we have already sent you a booking
            confirmation.
          </p>
          <p>
            11.4. We cannot be responsible for fraudulent use on our website or
            app or of a lost payment card.
          </p>
          <p>11.5. You must maintain a valid payment card with us.</p>
          <p>
            11.6. You must log in to the “Dusting Down portal” immediately if
            your payment card details are no longer valid.
          </p>
          <p>
            11.7. You authorise your payment on acceptance of the booking of the
            service. You also authorise us to charge your payment card in full
            on the day of the scheduled Booking should the actual work change.
          </p>
          <p>
            11.8. Any refunds due to you will be processed at the end of the
            service and will be credited to the payment card.
          </p>
          <p>
            11.9. If any amount due by you is declined, the scheduled Booking
            will be cancelled, and you will be indebted to the company for 75%
            of the clean.
          </p>
          <p>
            11.10. Your services with us will be terminated till such time as
            the payment has been made.
          </p>
          <p>
            11.11. A promotional code, credit, gift card or voucher may only be
            used by customers it is addressed to. Offer codes are based on once
            per household.
          </p>
          <p>
            11.12. Offers can only be redeemed once per customer and cannot be
            resold to or exchanged with a third party.
          </p>
          <p>
            11.13. Offers are not redeemable for cash and will not be replaced
            if lost, stolen, destroyed, or used without permission.
          </p>
          <p>
            11.14. Offers can be redeemed for a maximum of 3 working hours or at
            our sole discretion. One special offer per transaction.
          </p>
          <p>
            11.15. Dusting Down may at its sole discretion offer its own
            discounts and promotional offers (“Dusting Down Promotional Offers”)
            to potential and existing customers to promote Dusting Down’s
            business as an agent.
          </p>
          <p>
            11.16. Offers will not reduce the consideration received by the
            cleaner.
          </p>
          <b>12. BOOKING CANCELLATION BY US</b>
          <p>
            12.1. If you violate any of the Terms or Condition herein, your
            permission to use the Services and Website/App will automatically be
            terminated.
          </p>
          <p>
            12.2. We reserve the right to cancel any Booking without notice or
            cause. We will however send you such notification.
          </p>
          <b>13. BOOKING CANCELLATION BY YOU</b>
          <p>
            13.1. You may cancel or reschedule a Booking until 12:00pm the day
            before the Booking for free.
          </p>
          <p>
            13.2. For cancellations after 12:00pm the day before the Booking, or
            if your Dust Buster, arrives for the clean and is unable to gain
            entry or start the clean, you will be charged the full price of the
            Booking.
          </p>
          <b>14. INSURANCE AND DAMAGE</b>
          <p>
            14.1. We have a 10 (Ten) million rand public liability insurance
            policy underwritten by a leading insurer. There are excesses and
            terms and conditions of loss, and these can be made available in the
            event of a claim.
          </p>
          <p>14.2. All excesses payables will be at your cost.</p>
          <p>
            14.3. This policy is only able to cover extreme circumstances, for
            example, severe injury to the Team or severe damage to property such
            as fire.
          </p>
          <p>
            14.4. Other insurance is available, by clicking the “We Accept
            Insurance Tab.” When clicking this tab term and conditions will be
            attached to your invoice.
          </p>
          <p>
            14.5. The cover for insurance is only while we are on site and is
            limited to the terms and conditions of the insurer.
          </p>
          <p>
            14.6. Dusting Down does not accept any liability for Loss, Damage or
            Theft at the location of the property in the order as a result of
            the (Cleaner/s) accessing the property to perform their duties. Our
            (Cleaner/s) will do their best to ensure that utmost care is taken
            when cleaning said property, however, sometimes accidents can
            happen. In this case we highly recommend that all households have
            the relevant insurances in place to cover any loss including theft
            or damages caused as a result of the service provided.
          </p>
          <b>15. THIRD PARTY LINKS AND SECURITY</b>
          <p>
            15.1. Links to third party websites on the Website are provided
            solely for your convenience.
          </p>
          <p>
            15.2. If you use these links it is at your sole discretion. We do
            not control and are not responsible for these websites or their
            content or availability.
          </p>
          <p>
            15.3. We therefore do not endorse or make any representations about
            them, or any material found therein, or any results that may be
            obtained from their use.
          </p>
          <p>
            15.4. If you decide to access any of the third-party websites linked
            to our Website, you do so entirely at your own risk.
          </p>
          <p>
            15.5. No Internet or e-mail transmission is ever fully secure or
            error free; any transmission is at your own risk and sole
            discretion. E-mail(s) sent to or from the Services may not be
            secure. Therefore, you should take exceptional care in deciding what
            information you send to us via e-mail. Please keep this in mind when
            disclosing any Personal Data to Dusting Down via the Internet
          </p>
          <p>
            15.6. In operating the Services, we may use cookies. Dusting Down
            utilises first- and third-party cookies. This enables us to: a)
            Improve the User's experience on our website through various means
            such as storing preferences or determining aggregate usage behaviour
            through web analytics tools like Google Analytics.
          </p>
          <p>
            15.7. Most web browsers provide the option to block some or all
            cookie types should you wish to.
          </p>
          <b>16. CHILDREN</b>
          <p>
            16.1. Dusting Down does not knowingly collect Personal Data from
            children (individuals under the age of 18).
          </p>
          <p>
            16.2. If you are under the age of 18, please do not submit any
            Personal Data through our Services/Platforms. We encourage parents
            and legal guardians to monitor their children's Internet usage and
            to help enforce our Privacy Policy by instructing their children
            never to provide Personal Data on the Services/Platforms without
            their permission.
          </p>
          <p>
            16.3. If you have reason to believe that a child under the age of 18
            has provided Personal Data to Dusting Down through the Services,
            please contact us, and we will endeavour to delete that information
            from our databases.
          </p>
          <b>17. LIABILITY</b>
          <p>
            17.1. Our liability for the losses you suffer as a result of us
            breaking this agreement is strictly limited to the invoiced amount
            of the Service(s) you purchased on the previous 3 invoices.
          </p>
          <p>
            17.2. This limitation does not include or limit, in any way, our
            liability for death or personal injury caused by our negligence,
            fraud or fraudulent misrepresentation, or any matter for which it
            would be illegal for us to exclude, or attempt to exclude, our
            liability.
          </p>
          <p>
            17.3. We are not responsible for indirect losses which happen as a
            result of the main loss or damage, including but not limited to:
            loss of income or revenue; loss of business; loss of profits or
            contracts; loss of anticipated savings; loss of data; or, waste of
            management or office time however arising and whether caused by tort
            (including negligence), breach of contract or otherwise.
          </p>
          <p>
            17.4. We will take all reasonable care, in so far as it is in our
            power to do so, to keep the details of your Booking and payment
            secure, but in the absence of negligence on our part we cannot be
            held liable for any loss you may suffer if a third party procures
            unauthorised access to any data you provide when accessing or
            ordering from the Website and/or App.
          </p>
          <b>18. EVENTS OUTSIDE OUR CONTROL</b>
          <p>
            18.1. We will not be liable or responsible for any failure to
            perform, or delay in performance of any of our obligations under a
            Contract that is caused by events outside our reasonable control
            (“Force Majeure Event”).
          </p>
          <p>
            18.2. Each Party is excused from performing its obligations under
            this Agreement to the extent that such performance is prevented by
            an act or event (a "Force Majeure Event") whether or not foreseen,
            that: (i) is beyond the reasonable control of, and is not due to the
            fault or negligence of, such Party, and (ii) could not have been
            avoided by such Party's exercise of due diligence, including, but
            not limited to, a labour controversy, strike, lockout, boycott,
            transportation stoppage, action of a court or public authority,
            fire, flood, earthquake, storm, war, civil strife, terrorist action,
            epidemic, or act of God; provided that a Force Majeure Event will
            not include economic hardship, changes in market conditions, or
            insufficiency of funds. Notwithstanding the foregoing sentence, a
            Force Majeure Event does not excuse any obligation to make any
            payment required by this Agreement.
          </p>
          <p>
            18.3. Our performance under any Contract is deemed to be suspended
            for the period that the Force Majeure Event continues, and we will
            have an extension of time for performance for the duration of that
            period. We will use our reasonable endeavours to bring the Force
            Majeure Event to a close or to find a solution by which our
            obligations under the Contract may be performed despite the Force
            Majeure Event.
          </p>
          <b>19. GENERAL</b>
          <p>19.1. Intellectual property and right to use</p>
          <p>
            19.2. Unless otherwise stated, the copyright and other intellectual
            property rights in the content on the Website and/or App are owned
            by us or our licensors. Any access or use of the Website or App for
            any reason other than your personal, non-commercial use is
            prohibited.
          </p>
          <p>
            19.3. No part of the Website and/or App may be reproduced or stored
            in any other website or included in any public or private electronic
            retrieval system or Website without our prior written permission.
          </p>
          <p>
            19.4. If you publish any content on our website, such as reviews,
            comments, ratings, profiles, or listings, you grant us a worldwide,
            perpetual, non-exclusive, royalty-free license to copy, alter, adapt
            or display such Content on our Website.
          </p>
          <p>
            19.5. We reserve the right to remove any comments from our social
            pages as well as any comments on paid adverts on any social media
            sites.
          </p>
          <p>
            19.6. All winners for social media competitions are chosen at our
            discretion.
          </p>
          <p>
            19.7. We try our best to reply to any queries on our social media
            sites as quickly as possible between 7am and 7pm.
          </p>
          <p>
            19.8. For any urgent queries outside of these hours, please contact
            us directly
          </p>
          <b>20. Accessibility</b>
          <p>
            Dusting Down is committed to ensuring accessibility for all users,
            including those with disabilities. We strive to comply with
            accessibility standards such as WCAG (Web Content Accessibility
            Guidelines) to make our platform accessible to everyone. If you
            encounter any accessibility barriers or require assistance, please
            contact us for support.
          </p>
          <b>21. WRITTEN COMMUNICATIONS</b>
          <p>
            21.1. Applicable laws require that some of the information or
            communications we send to you should be in writing.
          </p>
          <p>
            21.2. When using our Website and/or App, you accept that
            communication with us will be mainly electronic.
          </p>
          <p>
            21.3. We will contact you by email with the @dustingdown.co.za
            domain. If you are unsure about an email received by us always call
            the number on the website to confirm.
          </p>
          <p>
            21.4. We will never ask you for your bank card number, pin, one time
            pin (OTP) or any such information. All banking transactions are
            concluded online.
          </p>
          <p>
            21.5. For contractual purposes, you agree to this electronic means
            of communication and you acknowledge that all contracts, notices,
            information, and other communications that we provide to you
            electronically comply with any legal requirement that such
            communications be in writing. This condition does not affect your
            statutory rights.
          </p>
          <b>22. NOTICES</b>
          <p>
            22.1. All notices given by you to us must be given to FibreClean
            (PTY) Ltd, at Unit 7; Ferndale Mews; 355 Oak Avenue; Randburg;
            Johannesburg; 2194. We may give notice to you at either the email or
            postal address you provide to us when making a Booking.
          </p>
          <p>
            22.2. Notice will be deemed received and properly served immediately
            when posted on our website, 24 hours after an email is sent.
          </p>
          <p>
            22.3. It will be sufficient to prove, in the case of an email that
            such email was sent to the specified email address of the addressee.
          </p>
          <p>
            22.4 Dusting Down reserves the right to update or modify these terms
            and conditions at any time without prior notice. Any changes will be
            effective immediately upon posting on the website or app. Users are
            encouraged to review the terms and conditions periodically for
            updates. By continuing to use our services after the posting of any
            changes, you accept and agree to be bound by the revised terms and
            conditions.
          </p>
          <b>23. TRANSFER OF RIGHTS AND OBLIGATIONS</b>
          <p>
            23.1. The contract between you and us is binding on you and us and
            on our respective successors and assigns.
          </p>
          <p>
            23.2. You may not transfer, assign, charge or otherwise dispose of a
            Contract, or any of your rights or obligations arising under it,
            without our prior written consent.
          </p>
          <p>
            23.3. We may transfer, assign, charge, sub-contract or otherwise
            dispose of a Contract, or any of our rights or obligations arising
            under it, at any time during the term of the Contract.
          </p>
          <b>24. SEVERANCE</b>
          <p>
            If any part of these Terms and Conditions be deemed unlawful, void
            or for any reason, unenforceable, then that provision shall be
            deemed to be severable from these Terms and Conditions and shall not
            affect the validity and enforceability of any of the remaining
            provisions of these Terms and Conditions.
          </p>
          <b>25. WAIVER</b>
          <p>
            No waiver by us shall be construed as a waiver of any proceeding or
            succeeding breach of any provision.
          </p>
          <b>26. ENTIRE AGREEMENT AND OR TERMS AND CONDITIONS OF USE</b>
          <p>
            These Terms and Conditions and any document expressly referred to
            herein constitute the entire agreement.
          </p>
          <b>27. THE LAW OF THE LAND</b>
          <p>
            These Terms and Conditions and the Contracts undertaken shall be
            governed by and construed in accordance with the laws of South
            Africa, and you irrevocably submit to the exclusive jurisdiction of
            the courts. Any dispute or claim arising out of or in connection
            with the Contracts or their formation (including non-contractual
            disputes or claims) shall be subject to the exclusive authority of
            the court. Updated January 2022
          </p>
          <b>Dispute Resolution</b>
          <p>
            Any disputes or claims arising out of or in connection with the use
            of Dusting Down's services or these terms and conditions shall be
            resolved through good faith negotiations between the parties. In the
            event that a resolution cannot be reached amicably, both parties
            agree to submit to mediation or arbitration proceedings conducted by
            a neutral third party. The decision of the mediator or arbitrator
            shall be final and binding.
          </p>
          <b>Updated January 2024</b>
        </div>
      </div>

      <Bottom />
    </>
  );
};

export default UserAgree;
