import { useState, useEffect, useRef } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import AddressAutocomplete from '../../../Layout/AddressAutocomplete';

const NewUserAddress = ({ userId, createUserAddress, note }) => {
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [complex, setComplex] = useState('');
  const [addressType, setAddressType] = useState('');
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();

  useEffect(() => {
    if (note.length > 0 && btn.current) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);

  const saveAddressDetails = () => {
    if (address.length < 8) {
      setError(`Please enter address`);
      return setTimeout(() => setError(null), 2000);
    } else if (addressType === 'House') {
      let text = address;
      createUserAddress({
        userId,
        text,
        coordinates,
        complex,
        addressType,
      });
      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    } else if (complex.length < 5) {
      setError(`Please enter ${addressType} name`);
      return setTimeout(() => setError(null), 2000);
    } else {
      let text = address;
      createUserAddress({
        userId,
        text,
        coordinates,
        complex,
        addressType,
      });
      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };
  return (
    <div>
      <Button variant="outline-secondary mt-2 mb-2" onClick={handleShow}>
        Add address
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Let us know a little about your address</Modal.Title>
        </Modal.Header>
        {error && <Alert variant="warning">{error}</Alert>}
        <Modal.Body>
          <AddressAutocomplete
            value={address}
            setAddress={setAddress}
            setCoordinates={setCoordinates}
          />

          {address ? (
            <Row className="mt-4">
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'House'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => setAddressType('House')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-solid fa-house"></i>
                  </span>
                </Button>
                House
              </Col>
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'Townhouse/Estate'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => setAddressType('Townhouse/Estate')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-solid fa-people-roof"></i>
                  </span>
                </Button>
                Townhouse
              </Col>
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'Apartment/Flat'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => setAddressType('Apartment/Flat')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-solid fa-city"></i>
                  </span>
                </Button>
                Apartment/Flat
              </Col>
              <Col xs={3}>
                <Button
                  variant={
                    addressType === 'Office'
                      ? 'outline-info'
                      : 'outline-secondary'
                  }
                  onClick={() => setAddressType('Office')}
                >
                  <span style={{ fontSize: 50 }}>
                    <i className="fa-regular fa-building"></i>
                  </span>
                </Button>
                Office
              </Col>
            </Row>
          ) : (
            ''
          )}

          {addressType && addressType !== 'House' ? (
            <>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Complex Details; </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="complex"
                  name="complex"
                  value={complex}
                  onChange={(e) => setComplex(e.target.value)}
                />
              </Form.Group>
            </>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            onClick={() => saveAddressDetails()}
            ref={btn}
          >
            Save Address
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewUserAddress;
