import React from 'react';
import Bottom from '../Layout/Bottom';

const CarpertCleaning = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            Effortless Carpet Cleaning at Your Fingertips with Dusting Down
          </h3>
        </div>
        <div>
          <p>
            <b>1. Convenient Booking Process:</b> Experience the convenience of
            Dusting Down's app and website when booking your carpet cleaning.
            Our user-friendly platform allows you to schedule the service at
            your preferred date and time, all with just a few taps or clicks.
          </p>
          <p>
            <b>2. Specialized Carpet Cleaning Experts:</b> Dusting Down boasts a
            team of skilled professionals specializing in carpet cleaning. With
            our app and website, you can easily access these experts who are
            equipped with the knowledge and techniques to deliver exceptional
            results.
          </p>
          <p>
            <b>3. Personalized Cleaning Solutions:</b> We understand that
            carpets have unique cleaning requirements. Dusting Down's app and
            website provide customization options, allowing you to specify your
            carpet's specific needs and any particular areas of concern.
          </p>
          <p>
            <b>4. Advanced Equipment and Premium Products:</b> Rest assured that
            your carpets are in good hands with Dusting Down. Our professionals
            utilize state-of-the-art equipment and high-quality cleaning
            products that are specifically designed for effective carpet
            cleaning, ensuring optimal results.
          </p>
          <p>
            <b>5. Flexible Scheduling:</b> We value your time and convenience.
            Through our app and website, you can effortlessly schedule your
            carpet cleaning according to your availability and preferences. Say
            goodbye to phone calls and back-and-forth arrangements.
          </p>
          <p>
            <b>6. Time-saving and Hassle-free:</b> With Dusting Down's app and
            website, managing your carpet cleaning is a breeze. You can easily
            review and modify your booking details, access cleaning progress
            updates, and communicate with our team—all in one centralized
            location.
          </p>
          <p>
            <b>7. Transparent Communication and Documentation:</b> Dusting Down
            believes in transparency. Our app and website provide seamless
            communication channels, allowing you to stay informed about your
            carpet cleaning. We also provide detailed documentation, including
            itemized receipts, for your peace of mind.
          </p>
        </div>
        <hr />
        <div className="container">
          <p>
            <b>Discover the Convenience:</b> Dusting Down's app and website
            revolutionize the way you book and manage carpet cleaning services.
            Enjoy the convenience of effortless bookings, personalized cleaning
            solutions, and transparent communication. Experience a stress-free
            and convenient carpet cleaning process with Dusting Down, right at
            your fingertips.
          </p>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default CarpertCleaning;
