import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner, Row, Col, Table, Container, Button } from 'react-bootstrap';
import { GetUsers } from '../../../../actions/admin/admin_user';
// import LocalUsers from './LocalUsers';
import ListOfUsers from './ListOfUsers';
import { useNavigate } from 'react-router-dom';

import './User.css';

const AdminUsers = ({ users, GetUsers }) => {
  useEffect(() => {
    GetUsers();
  }, [GetUsers]);
  const [searchLocal, setSearchLocal] = useState('');
  let navigate = useNavigate();

  const addUserPage = () => {
    navigate('/admin/users/add-new-user');
  };

  if (users === null) {
    return (
      <Container>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <h4 textalign="left">Dusting Down Users</h4>
        </Col>
        <Col>
          <Button
            variant="outline-info ml-3"
            size="sm"
            className="float-right"
            onClick={addUserPage}
          >
            Add new user
          </Button>
          <span className="float-right">
            <input
              className="text-center"
              placeholder="search by name/email"
              style={{ border: 'none' }}
              onChange={(e) => setSearchLocal(e.target.value)}
            />
          </span>
        </Col>
      </Row>

      <hr />
      <Row className="justify-content-around">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Cellphone</th>
              <th>Last login</th>
              <th>Address</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0
              ? 'No Users'
              : users
                  .filter((val) => {
                    if (searchLocal === '') {
                      return val;
                    } else if (
                      val.name.toLowerCase().includes(searchLocal.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.email
                        .toLowerCase()
                        .includes(searchLocal.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((user) => <ListOfUsers key={user._id} user={user} />)}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  users: state.admin_users.users,
});

export default connect(mapStateToProps, {
  GetUsers,
})(AdminUsers);
