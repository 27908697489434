import {
  BUSINESS_LOADED,
  BUSINESS_LOGIN,
  BUSINESS_LOGOUT,
} from '../../actions/types';

const initState = {
  token: localStorage.getItem('token'),
  isBusiness: null,
  business: null,
  loading: true,
};

function businessReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case BUSINESS_LOADED:
      return {
        ...state,
        isBusiness: true,
        business: payload,
        loading: false,
      };
    case BUSINESS_LOGIN:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
      };
    case BUSINESS_LOGOUT:
      return {
        ...state,
        token: localStorage.removeItem('token'),
        isBusiness: null,
        business: null,
      };
    default:
      return state;
  }
}

export default businessReducer;
