import React, { useEffect } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import UploadNow from './UploadNow';

const BookAppointment = ({ local, dusterId, stepThree, hr, app }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div align="center">
      <h5 className="text-center">3. Book Interview</h5>
      <Card>
        <Card.Body>
          <Card.Title className="text-success">
            You will need the following documentation to complete your
            application
          </Card.Title>

          <Card.Text>
            <ListGroup id="upload-info">
              <ListGroup.Item>
                <b>1. ID Documents</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>2. Proof of Address</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>3. Bank Letter</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>4. Profile Picture</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>5. Work Permit (If foreign national)</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <h5 className="text-info">
                  You can upload your documents know or when you come for the
                  interview
                </h5>
              </ListGroup.Item>
            </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card>

      <UploadNow
        local={local}
        dusterId={dusterId}
        stepThree={stepThree}
        hr={hr}
        app={app}
      />
    </div>
  );
};

export default BookAppointment;
