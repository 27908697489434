import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changeEmail } from '../../../actions/user';
import { Alert } from 'react-bootstrap';

const VerifyNewEmail = ({ changeEmail, note }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (note.length > 0) {
      let msg = note[0];
      setResult(msg.msg);
    }
  }, [setResult, note]);
  useEffect(() => {
    let url = window.location.href;
    let loot = url.split('/');
    let userId = loot[6];
    let newEmail = loot[5];
    changeEmail({ userId, newEmail });
  }, [changeEmail]);
  return (
    <div>
      <div className="container">
        {result ? (
          <Alert variant="warning text-center">{result}</Alert>
        ) : (
          <Alert variant="success text-center">Verifying...</Alert>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { changeEmail })(VerifyNewEmail);
