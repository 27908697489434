import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const CleanItem = ({ o }) => {
  const [complete, setComplete] = useState(false);
  const [clientName, setClientName] = useState(null);
  useEffect(() => {
    setClientName(o.user.name);

    if (o.checkOut === undefined) {
      setComplete(false);
    } else {
      setComplete(true);
    }
  }, [o, setComplete]);
  let address = o.address.split(',').shift();
  let orderTime = moment(o.orderTime).format('ddd MMM D, h:mm a');
  return (
    <tr>
      <td>{clientName}</td>
      <td>{orderTime}</td>
      <td>{address}</td>
      <td>{complete ? 'Complete' : 'Incomplete'}</td>
      <td>
        <Button
          as={Link}
          to={`/admin/order-management/once-off/order/${o._id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

export default CleanItem;
