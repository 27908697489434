import {
  SET_MOTHER_RATE,
  SET_ORDER_ADDRESS,
  SET_ORDER_ADDRESSTYPE,
  SET_ORDER_COMPLEX,
  SET_ORDER_COORDINATES,
  SET_ORDER_BED,
  SET_ORDER_BATH,
  SET_OVERALL_HOURS,
  SET_ORDER_INVOICE,
  CHOOSE_IRONING,
  SET_LAUNDREY_BASKET,
  CHOOSE_BEDDING,
  CHOOSE_FRIDGE,
  CHOOSE_KITCHEN,
  CHOOSE_WINDOWS,
  CHOOSE_OVEN,
  SET_USER_ORDERTIME,
  SET_HOW_TO_GETIN,
  CLEAR_ORDER_SCHEDULE,
  SET_ORDER_WEEKDAYS,
  SET_ORDER_SCHEDULE,
  TOGGLE_ORDER_TYPE,
  SET_ORDER_FREQUENCY,
  SET_ORDER_RE_INVOICE,
  SET_ORDER_EXTRAS,
  USER_CREATED_ORDER,
  USER_CREATED_REORDER,
  CLEAR_CREATED_ORDERS,
} from '../types';
import { setNote } from '../note';
import axios from 'axios';
import moment from 'moment';

//Get mother//
export const getMother = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/admin_user/mother-rate-card');
    dispatch({ type: SET_MOTHER_RATE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//////Rate Card////////
///////////////////////
export const rateCard =
  ({ invoiceHours, mother }) =>
  (dispatch) => {
    const { four, fourp, five, fivep, six, sixp, seven, sevenp, eight } =
      mother;
    try {
      //The Rate Card//
      if (invoiceHours <= 4) {
        let price = 4 * four;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 4 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 4.5) {
        let price = 4.5 * fourp;

        let invpri = (price + 35).toFixed(2);

        dispatch({ type: SET_OVERALL_HOURS, payload: 4.5 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 5) {
        let price = 5 * five;
        let invpri = (price + 35).toFixed(2);

        dispatch({ type: SET_OVERALL_HOURS, payload: 5 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 5.5) {
        let price = 5.5 * fivep;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 5.5 });

        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 6) {
        let price = 6 * six;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 6 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 6.5) {
        let price = 6.5 * sixp;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 6.5 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 7) {
        let price = 7 * seven;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 7 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 7.5) {
        let price = 7.5 * sevenp;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 7.5 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 8) {
        let price = 8 * eight;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 8 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours <= 8.5) {
        let price = 8.5 * eight;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: SET_OVERALL_HOURS, payload: 8 });
        return dispatch({ type: SET_ORDER_INVOICE, payload: invpri });
      }

      if (invoiceHours > 8) {
        return dispatch(
          setNote(
            'Please call the office to book over 8 hours: + 27 87 550 1147',
            'warning'
          )
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//Set Order Address//
export const setOrderAddress =
  ({ text, complex, coordinates, addressType }) =>
  (dispatch) => {
    try {
      dispatch({ type: SET_ORDER_ADDRESS, payload: text });
      dispatch({ type: SET_ORDER_COMPLEX, payload: complex });
      dispatch({ type: SET_ORDER_COORDINATES, payload: coordinates });
      dispatch({ type: SET_ORDER_ADDRESSTYPE, payload: addressType });
    } catch (error) {
      console.error('Error setting address: ', error.message);
    }
  };

//Add bed//
export const addBed =
  ({ bed, orderHours, mother }) =>
  (dispatch) => {
    try {
      if (bed === 1) {
        let invoiceHours = orderHours + 0.5;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 2) {
        let invoiceHours = orderHours + 0.5;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 3) {
        let invoiceHours = orderHours + 1;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 4) {
        let invoiceHours = orderHours + 1;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 5) {
        let invoiceHours = orderHours;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 6) {
        let invoiceHours = orderHours;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      }
    } catch (error) {
      console.error('Error adding bed: ', error.message);
    }
  };

//Remove Bed//
export const removeBed =
  ({ bed, orderHours, mother }) =>
  (dispatch) => {
    try {
      if (bed === 0) {
        let invoiceHours = orderHours - 0.5;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 1) {
        let invoiceHours = orderHours - 0.5;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 2) {
        let invoiceHours = orderHours - 0.5;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 3) {
        let invoiceHours = orderHours - 1;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 4) {
        let invoiceHours = orderHours - 1;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 5) {
        let invoiceHours = orderHours;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      } else if (bed === 6) {
        let invoiceHours = orderHours;
        dispatch({ type: SET_ORDER_BED, payload: bed });
        dispatch(rateCard({ invoiceHours, mother }));
      }
    } catch (error) {
      console.error('Error removing bed', error.message);
    }
  };

//add bath//
export const addBath =
  ({ bath, orderHours, mother }) =>
  (dispatch) => {
    try {
      if (bath === 1) {
        let invoiceHours = orderHours + 0.5;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
      if (bath === 2) {
        let invoiceHours = orderHours;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
      if (bath === 3) {
        let invoiceHours = orderHours + 0.5;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
      if (bath === 4) {
        let invoiceHours = orderHours + 0.25;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
    } catch (error) {
      console.error();
    }
  };

//remove bath//
export const removeBath =
  ({ bath, orderHours, mother }) =>
  (dispatch) => {
    try {
      if (bath === 0) {
        let invoiceHours = orderHours - 0.5;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
      if (bath === 1) {
        let invoiceHours = orderHours - 0.5;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
      if (bath === 2) {
        let invoiceHours = orderHours - 0.5;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
      if (bath === 3) {
        let invoiceHours = orderHours - 0.25;
        dispatch({ type: SET_ORDER_BATH, payload: bath });
        dispatch(rateCard({ invoiceHours, mother }));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam order extras//
export const jamOrderExtras = (newX) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_EXTRAS, payload: newX });
  } catch (error) {
    console.error(error.message);
  }
};

//Jam ironing//
export const ironingPlz =
  ({ newBasket, invoiceHours, ironing, mother }) =>
  (dispatch) => {
    try {
      dispatch({ type: CHOOSE_IRONING, payload: ironing });
      dispatch({ type: SET_LAUNDREY_BASKET, payload: newBasket });
      dispatch(rateCard({ invoiceHours, mother }));
    } catch (error) {
      console.error(error.message);
    }
  };

//Toggle the basket//
export const basketToggle =
  ({ invoiceHours, newKGS, mother }) =>
  (dispatch) => {
    try {
      dispatch({ type: SET_LAUNDREY_BASKET, payload: newKGS });
      dispatch(rateCard({ invoiceHours, mother }));
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Bedding//
export const changeMyBedding =
  ({ invoiceHours, bed, mother }) =>
  (dispatch) => {
    try {
      dispatch({ type: CHOOSE_BEDDING, payload: bed });
      dispatch(rateCard({ invoiceHours, mother }));
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Kitchen//
export const kitchenPlz =
  ({ kit, invoiceHours, mother }) =>
  (dispatch) => {
    try {
      dispatch({ type: CHOOSE_KITCHEN, payload: kit });
      dispatch(rateCard({ invoiceHours, mother }));
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Oven//
export const ovenPlz =
  ({ oven, invoiceHours, mother }) =>
  (dispatch) => {
    try {
      dispatch({ type: CHOOSE_OVEN, payload: oven });
      dispatch(rateCard({ invoiceHours, mother }));
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Fridge//
export const fridgePlz =
  ({ fr, invoiceHours, mother }) =>
  (dispatch) => {
    try {
      dispatch({ type: CHOOSE_FRIDGE, payload: fr });
      dispatch(rateCard({ invoiceHours, mother }));
    } catch (error) {
      console.error(error.message);
    }
  };

//Jam Windows//
export const windowsPlz =
  ({ win, invoiceHours, mother }) =>
  (dispatch) => {
    try {
      dispatch({ type: CHOOSE_WINDOWS, payload: win });
      dispatch(rateCard({ invoiceHours, mother }));
    } catch (error) {
      console.error(error.message);
    }
  };

//Set order time//
export const setOrderTime = (orderTime) => async (dispatch) => {
  try {
    dispatch({ type: SET_USER_ORDERTIME, payload: orderTime });
  } catch (error) {
    console.error(error.message);
  }
};

export const setHowTo = (how) => async (dispatch) => {
  try {
    dispatch({ type: SET_HOW_TO_GETIN, payload: how });
  } catch (error) {
    console.error(error.message);
  }
};

//Clear order schedule//
export const clearOrderSchedule = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ORDER_SCHEDULE });
  } catch (error) {
    console.error('error clearing schedule: ', error.message);
  }
};

//Set Order Weekdays//
export const setOrderWeekdays = (week) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_WEEKDAYS, payload: week });
  } catch (error) {
    console.error(error.message);
  }
};

//Set Order Schecule//
export const setOrderSchedule = (schedule) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_SCHEDULE, payload: schedule });
  } catch (error) {
    console.error(error.message);
  }
};

//change order type//
export const toggleTypeOfOrder = (data) => async (dispatch) => {
  try {
    dispatch({ type: TOGGLE_ORDER_TYPE, payload: data });
  } catch (error) {
    console.error(error.message);
  }
};

export const setOrderFrequency = (frequency) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_FREQUENCY, payload: frequency });
  } catch (error) {
    console.error(error.message);
  }
};

export const repeatCalender =
  ({ daysLeft, weekdays, hours }) =>
  async (dispatch) => {
    try {
      let data = [];
      const weekly = daysLeft.filter((dl) => {
        let string = dl.toString().split(' ');
        const match = weekdays.filter((dow) => {
          if (string[0] === dow) {
            return dl;
          }
        });
        if (match.length > 0) {
          return dl;
        }
      });
      function createSchedule(weekly, data) {
        weekly.forEach((w) => {
          let title = 'Cleaning';
          let start = moment(w).format();
          let end = moment(start).add(hours, 'hours').format();
          let event = { title, start, end };
          data.push(event);
        });
      }

      createSchedule(weekly, data);

      dispatch({ type: SET_ORDER_SCHEDULE, payload: data });
    } catch (error) {
      console.error(error.message);
    }
  };

export const fullTimeCleaner =
  ({ daysLeft, invoiceHours, invoice }) =>
  (dispatch) => {
    try {
      let data = [];
      const weekly = daysLeft.filter((dl, index) => {
        let string = dl.toString().split(' ');
        let DaysOfTheWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
        const match = DaysOfTheWeek.filter((dow, index) => {
          if (string[0] === dow) {
            return dl;
          }
        });
        if (match.length > 0) {
          return dl;
        }
      });

      function createSchedule(weekly, data) {
        weekly.forEach((w) => {
          let title = 'Cleaning';
          let start = w;
          let end = moment(start).add(invoiceHours, 'hours').format('llll');
          let event = { title, start, end };
          data.push(event);
        });
      }
      createSchedule(weekly, data);
      dispatch({ type: SET_ORDER_SCHEDULE, payload: data });
    } catch (error) {
      console.error(error.message);
    }
  };

export const setRegularInvoice = (invoice) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_RE_INVOICE, payload: invoice });
  } catch (error) {
    console.error(error.message);
  }
};

export const clearCreatedOrders = () => (dispatch) => {
  try {
    dispatch({ type: CLEAR_CREATED_ORDERS });
  } catch (error) {
    console.error(error.message);
  }
};

//Create the order//
export const createTheOrder =
  ({
    time,
    address,
    coordinates,
    bedroom,
    bathroom,
    mrx,
    invoiceHours,
    invoice,
    complex,
    nityGrity,
    laundreyBasket,
    userId,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let from = 'Admin';
    let paymentMethod = 'EFT';
    const body = JSON.stringify({
      time,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      complex,
      nityGrity,
      laundreyBasket,
      from,
      paymentMethod,
    });
    try {
      const res = await axios.post(
        `/api/admin/order-management/once-off/create-order-for-user/${userId}`,
        body,
        config
      );
      dispatch({ type: USER_CREATED_ORDER, payload: res.data });
      dispatch(setNote('Order successfully created', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Create reorder//
export const createTheReOrder =
  ({
    time,
    address,
    coordinates,
    bedroom,
    bathroom,
    mrx,
    invoiceHours,
    weekdays,
    invoice,
    complex,
    nityGrity,
    schedule,
    frequency,
    laundreyBasket,
    userId,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let from = 'Admin';
    let paymentMethod = 'EFT';
    const body = JSON.stringify({
      time,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      weekdays,
      invoice,
      complex,
      nityGrity,
      laundreyBasket,
      from,
      schedule,
      frequency,
      paymentMethod,
    });
    try {
      const res = await axios.post(
        `/api/admin/order-management/repeat-order/create-order-for-user/${userId}`,
        body,
        config
      );
      dispatch({ type: USER_CREATED_REORDER, payload: res.data });
      dispatch(setNote('Order successfully created', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };
