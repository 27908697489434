import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import moment from 'moment';

const InvoiceItem = ({ invoice }) => {
  const [complete, setComplete] = useState(false);
  const [duster, setDuster] = useState('No Duster');
  const [address, setAddress] = useState(null);
  useEffect(() => {
    if (invoice.order) {
      //Once-off order//
      //Set the address
      const address = invoice.order.address.split(',').shift();
      setAddress(address);
      //Set the duster
      if (invoice.order.team) {
        let dusterName = invoice.order.team.name;
        setDuster(dusterName);
      } else {
        setDuster('No Duster');
      }
      //Is order complete?
      if (invoice.order.checkOut) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    } else {
      //Repeat order//
      //Set the address
      const address = invoice.reorder.address.split(',').shift();
      setAddress(address);
      //Set the duster
      if (invoice.reorder.team) {
        let dusterName = invoice.reorder.team.name;
        setDuster(dusterName);
      } else {
        setDuster('No Duster');
      }
      //Is order complete?
      let checkOut = invoice.reorder.checkOut.length;
      let sched = invoice.reorder.schedule.length;
      if (checkOut === sched) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }
  }, [invoice]);

  return (
    <tr>
      <td>{invoice.invoice}</td>
      <td>{invoice.user.name}</td>
      <td>{address}</td>
      <td>{complete ? 'Complete' : 'Incomplete'}</td>
      <td>{duster === 'No Duster' ? 'No Duster' : `${duster}`}</td>
      <td>{moment(invoice.timestamp).format('LL')}</td>
      <td>
        R{' '}
        {invoice.order
          ? `${invoice.order.invoice}`
          : `${invoice.reorder.invoice}`}
      </td>
      <td>
        <Button
          as={Link}
          to={`/admin/admin-accounts/order-invoice/${invoice._id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

export default InvoiceItem;
