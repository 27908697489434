import { useEffect } from 'react';
import { Container, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ordersThisMonthAll } from '../../../actions/admin/orders';
import { useNavigate } from 'react-router-dom';
import OrderItem from './OrderItem';

const OrdersForTheMonth = ({ ordersThisMonthAll, ordersMonth }) => {
  useEffect(() => {
    ordersThisMonthAll();
  }, [ordersThisMonthAll]);

  let navigate = useNavigate();

  if (ordersMonth === null)
    return (
      <Container>
        <Spinner variant="primary" animation="grow" />
      </Container>
    );

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4 style={{ color: '#06a5dd' }}>This months orders</h4>
      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>Client Name</th>
            <th>Booking Date</th>
            <th>Address</th>
            <th>Payment Status</th>
            <th>Cleaner assigned</th>
            <th>Booking Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {ordersMonth.orders.length === 0
            ? 'No Orders this month'
            : ordersMonth.orders.map((o) => <OrderItem key={o._id} o={o} />)}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  ordersMonth: state.admin_orders.ordersMonth,
});

export default connect(mapStateToProps, { ordersThisMonthAll })(
  OrdersForTheMonth
);
