import axios from 'axios';
import { SET_USERS, CLEAR_USERS, ALL_COUPONS, COUPON_NUM } from '../types';
import { setNote } from '../note';

///////////////////
//Get all coupons//
///////////////////
export const getCoupons = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/coupons');
    dispatch({ type: ALL_COUPONS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const couponsNum = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/coupons/amount');
    dispatch({ type: COUPON_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////
//Create a coupon for everyone//
////////////////////////////////
export const GenerateCoupons =
  ({ percentage, validity }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_USERS });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ percentage, validity });
    try {
      const res = await axios.post(
        '/api/admin/coupons/all-user-token',
        body,
        config
      );
      dispatch({ type: SET_USERS, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////////////////
//Create coupon for specific user//
///////////////////////////////////
export const CreateUserCoupon =
  ({ percentage, validity, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ percentage, validity, userId });
    try {
      const res = await axios.post(
        '/api/admin/coupons/create-user-token',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////
//Create a coupon//
///////////////////
export const createCoupon =
  ({ expirydate, coupon, percArray, coupontype, typeOfClean, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      expirydate,
      coupon,
      percArray,
      coupontype,
      typeOfClean,
      userId,
    });
    try {
      const res = await axios.post(
        '/api/admin/coupons/create-client-coupon',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getCoupons());
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Create Affiliate Coupon//
export const createAffiliateCoupon =
  ({ expirydate, coupon, percArray, coupontype, typeOfClean, affiliate }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      expirydate,
      coupon,
      percArray,
      coupontype,
      typeOfClean,
      affiliate,
    });
    try {
      const res = await axios.post(
        '/api/admin/coupons/create-affiliate-coupon',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getCoupons());
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

///////////////////////////
//Create Marketing Coupon//
///////////////////////////
export const createMarketingCoupon =
  ({
    expirydate,
    coupon,
    percArray,
    coupontype,
    typeOfClean,
    promoType,
    whichClean,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      expirydate,
      coupon,
      percArray,
      coupontype,
      typeOfClean,
      promoType,
      whichClean,
    });
    try {
      const res = await axios.post(
        '/api/admin/coupons/create-marketing-coupon',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getCoupons());
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

/////////////////
//Delete coupon//
/////////////////
export const deleteCoupon = (couponId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/admin/coupons/delete/${couponId}`);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(getCoupons());
  } catch (error) {
    console.error(error.message);
  }
};
