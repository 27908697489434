import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dusterReview } from '../../../actions/user/review';
import { Form, Button, Modal } from 'react-bootstrap';

const ReviewDuster = ({ bookedCleaner, dusterReview, orderId }) => {
  const [show, setShow] = useState(false);
  const [review, setReview] = useState('');
  const [rating, setRating] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitReview = () => {
    let dusterId = bookedCleaner._id;
    dusterReview({ review, rating, dusterId, orderId });
    setShow(false);
  };
  return (
    <div>
      <Button variant="outline-primary" onClick={handleShow} block>
        Review Duster Buster
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Review {bookedCleaner.name} {bookedCleaner.surname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Write Review</Form.Label>
              <Form.Control
                type="text"
                name="review"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <Form.Group className="text-center">
              <Form.Label>Star Rating</Form.Label>
              <div>
                <Button
                  variant={
                    rating === '1' ? 'danger mr-2' : 'outline-danger mr-2'
                  }
                  value="1"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  1 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '2' ? 'danger mr-2' : 'outline-danger mr-2'
                  }
                  value="2"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  2 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '3' ? 'warning mr-2' : 'outline-warning mr-2'
                  }
                  value="3"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  3 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '4' ? 'success mr-2' : 'outline-success mr-2'
                  }
                  value="4"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  4 <i className="far fa-star"></i>
                </Button>
                <Button
                  variant={
                    rating === '5' ? 'success mr-2' : 'outline-success mr-2'
                  }
                  value="5"
                  onClick={(e) => setRating(e.target.value)}
                  inline
                >
                  5 <i className="far fa-star"></i>
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Exit
          </Button>
          <Button variant="warning text-white" onClick={submitReview}>
            Submit Review
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, { dusterReview })(ReviewDuster);
