import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Spinner, Alert, Modal, Button } from 'react-bootstrap';
import {
  Work,
  getSchedule,
  bookJob,
  getBooking,
  myWork,
  myReWork,
} from '../../../actions/team/team_work';
import AvailableWork from './AvailableWork';
import MyWork from './MyWork';
import MyReWork from './MyReWork';
import { Link } from 'react-router-dom';

const ReadyToWork = ({
  team,
  Work,
  team_work: { work, schedule, booking, my_work, my_rework },
  getSchedule,
  myWork,
  myReWork,
}) => {
  useEffect(() => {
    getSchedule();
    Work();
    myWork();
    myReWork();
  }, [getSchedule, Work, myWork, myReWork]);

  const [bookingOrderId, SetBookingOrderId] = useState(null);
  const [bookingAddress, SetBookingAddress] = useState(null);
  const [bookingTime, SetBookingTime] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [availableWork, SetAvailableWork] = useState([]);
  const [mySchedule, SetSchedule] = useState([]);
  const [booked, setBooked] = useState([]);
  const [reBooked, setReBooked] = useState([]);

  useEffect(() => {
    if (booking) {
      SetBookingOrderId(booking._id);
      SetBookingAddress(booking.address);
      SetBookingTime(booking.orderTime);
    }
  }, [booking]);

  useEffect(() => {
    if (work) {
      SetAvailableWork(work);
    }
    if (schedule) {
      SetSchedule(schedule);
    }
    if (my_work) {
      setBooked(my_work);
    }
    if (my_rework) {
      setReBooked(my_rework);
    }
  }, [
    work,
    SetAvailableWork,
    SetSchedule,
    schedule,
    setBooked,
    setReBooked,
    my_work,
    my_rework,
  ]);

  if (
    (team === null && work === null && my_work === null, my_rework === null)
  ) {
    return <Spinner animation="border" variant="primary" />;
  }

  const time = moment(bookingTime).format('ddd MMM D, h:mm a');

  return (
    <div>
      <div className="container">
        <Row align="center">
          <Col md={4} xs={12}>
            <Alert variant="success text-center">
              Available Once Off Work!
            </Alert>
            {availableWork.length === 0
              ? 'No work available in your area'
              : availableWork.map((aw) => (
                  <AvailableWork key={aw._id} aw={aw} mySchedule={mySchedule} />
                ))}
          </Col>
          <Col>
            <Row>
              <Col md={6} xs={12}>
                <Alert variant="secondary">Your Booked Work</Alert>
                {booked.length === 0 ? (
                  <h5 className="text-center">You have no booked work</h5>
                ) : (
                  booked.map((aw) => {
                    return <MyWork key={aw._id} aw={aw} />;
                  })
                )}
              </Col>
              <Col md={6} xs={12}>
                <Alert variant="secondary">Your Repeat work</Alert>
                {reBooked.length === 0 ? (
                  <h5 className="text-center">
                    You have no booked repeat orders
                  </h5>
                ) : (
                  reBooked.map((rw) => {
                    return <MyReWork key={rw._id} rw={rw} />;
                  })
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {booking === null ? (
          ''
        ) : (
          <>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Booking Time: {time}</Modal.Title>
              </Modal.Header>
              <Modal.Body>Address: {bookingAddress}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  as={Link}
                  to={`/team/my-booking/${bookingOrderId}`}
                  onClick={handleClose}
                >
                  Go To Booking
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.team.team,
  team_work: state.team_work,
});

export default connect(mapStateToProps, {
  Work,
  getSchedule,
  bookJob,
  getBooking,
  myWork,
  myReWork,
})(ReadyToWork);
