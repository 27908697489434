import {
  ORDER_QUERIES,
  REMOVE_QUERIES,
  IN_QUERY_TEXTS,
  IN_QUERY,
  USER_QUERY_SOCKET,
  REMOVE_SOCKET,
} from '../../actions/types';

const initState = {
  queries: [],
  query: null,
  texts: null,
  socket: null,
  loading: true,
};

function userQueryReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ORDER_QUERIES:
      return {
        ...state,
        queries: payload,
        loading: false,
      };
    case REMOVE_QUERIES:
      return {
        ...state,
        queries: [],
        query: null,
      };
    case IN_QUERY:
      return {
        ...state,
        query: payload,
      };
    case IN_QUERY_TEXTS:
      return {
        ...state,
        texts: payload,
      };
    case USER_QUERY_SOCKET:
      return {
        ...state,
        socket: payload,
      };
    case REMOVE_SOCKET:
      return {
        ...state,
        socket: null,
      };
    default:
      return state;
  }
}

export default userQueryReducer;
