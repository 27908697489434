import { useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ChangeDusterType = ({ dustBuster, type, changeType, note }) => {
  const [change, setChange] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();

  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);
  useEffect(() => {
    if (type === 'Dust Buster') {
      setChange('Contract Cleaner');
    } else {
      setChange('Dust Buster');
    }
  }, [type]);

  const updateduster = () => {
    const daily = dustBuster.daily;
    const dusterId = dustBuster._id;
    if (!daily) {
      let changeTo = true;
      changeType({ dusterId, changeTo });
    } else {
      let changeTo = !daily;
      changeType({ dusterId, changeTo });
    }

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <Button
        size="sm"
        variant="outline-dark"
        className="float-right"
        onClick={handleShow}
      >
        Convert
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Convert {dustBuster.name} to {change}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="outline-success"
            ref={btn}
            onClick={() => updateduster()}
          >
            Yes
          </Button>
          <Button variant="outline-danger" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangeDusterType;
