import { Row, Col, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  changeMyBedding,
  ironingPlz,
  kitchenPlz,
  ovenPlz,
  windowsPlz,
  fridgePlz,
} from '../../../actions/user/bookings';
import { setNote } from '../../../actions/note';
import bedafter from '../../../images/BedAfter.png';
import bedbefore from '../../../images/BedBefore.png';
import ironafter from '../../../images/IronAfter.png';
import ironbefore from '../../../images/IronBefore.png';
import kitchenafter from '../../../images/KitchenAfter.png';
import kitchenbefore from '../../../images/KitchenBefore.png';
import stoveafter from '../../../images/StoveAfter.png';
import stovebefore from '../../../images/StoveBefore.png';
import fridgeafter from '../../../images/FridgeAfter.png';
import fridgbefore from '../../../images/FridgeBefore.png';
import windowafter from '../../../images/WindowsAfter.png';
import windowbefore from '../../../images/WindowsBefore.png';
import './Booking.css';

const IconExtras = ({
  hours,
  setNote,
  laundreyBasket,
  basketToggle,
  bookings: {
    bedroom,
    myBedding,
    extraHours,
    ironing,
    kitchenCubs,
    insideOven,
    myWindows,
    myFridge,
  },
  changeMyBedding,
  ironingPlz,
  kitchenPlz,
  ovenPlz,
  windowsPlz,
  fridgePlz,
}) => {
  const iron = () => {
    let extraID = 1;

    if (ironing) {
      if (laundreyBasket >= 8) {
        let newHrs = parseFloat(extraHours) - 2;
        let newBasket = 0;
        let ironing = false;

        ironingPlz({ newBasket, newHrs, ironing, extraID });
      } else {
        let newHrs = parseFloat(extraHours) - 1;
        let newBasket = 0;
        let ironing = false;
        ironingPlz({ newBasket, newHrs, ironing, extraID });
      }
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let newBasket = laundreyBasket + 4;
      let newHrs = parseFloat(extraHours) + 1;
      let ironing = true;
      ironingPlz({ newBasket, newHrs, ironing, extraID });
    }
  };

  const addBasket = () => {
    if (laundreyBasket < 8) {
      if (hours >= 8) {
        setNote(
          'Please call the office to book over 8 hours: + 27 87 550 1147',
          'warning'
        );
      } else {
        let newKGS = laundreyBasket + 4;
        let newHours = extraHours + 1;
        basketToggle({ newHours, newKGS });
      }
    } else {
      setNote('8kg is the maximum', 'warning');
    }
  };

  const removeBasket = () => {
    if (laundreyBasket === 4) {
      return setNote('4kg is the minimun', 'warning');
    } else {
      let newKGS = laundreyBasket - 4;
      let newHours = extraHours - 1;
      basketToggle({ newHours, newKGS });
    }
  };

  const bed = () => {
    let id = 2;
    if (bedroom === 0) {
      return setNote(
        'Please let us know how many bedrooms need changing',
        'warning'
      );
    } else {
      if (myBedding === true) {
        let bed = false;
        let hoursBed = bedroom * 0.25;
        let newExtra = parseFloat(extraHours) - hoursBed;
        changeMyBedding({ newExtra, bed, id });
      } else {
        if (hours === 8) {
          return setNote(
            'Please call the office to book over 8 hours: + 27 11 568 8077',
            'warning'
          );
        }
        let bed = true;
        let hoursBed = bedroom * 0.25;
        let newExtra = parseFloat(extraHours) + hoursBed;
        changeMyBedding({ newExtra, bed, id });
      }
    }
  };

  const kitchen = () => {
    let id = 3;
    if (kitchenCubs) {
      let kit = false;
      let newHours = parseFloat(extraHours) - 0.5;
      kitchenPlz({ kit, newHours, id });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let kit = true;
      let newHours = parseFloat(extraHours) + 0.5;
      kitchenPlz({ kit, newHours, id });
    }
  };

  const oven = () => {
    let id = 4;
    if (insideOven) {
      let oven = false;
      let newHours = parseFloat(extraHours) - 0.5;
      ovenPlz({ oven, newHours, id });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let oven = true;
      let newHours = parseFloat(extraHours) + 0.5;
      ovenPlz({ oven, newHours, id });
    }
  };

  const fridge = () => {
    let id = 5;
    if (myFridge) {
      let fr = false;
      let newHours = parseFloat(extraHours) - 0.5;
      fridgePlz({ fr, newHours, id });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let fr = true;
      let newHours = parseFloat(extraHours) + 0.5;
      fridgePlz({ fr, newHours, id });
    }
  };

  const windows = () => {
    let id = 6;
    if (myWindows) {
      let win = false;
      let newHours = parseFloat(extraHours) - 0.5;
      windowsPlz({ win, newHours, id });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let win = true;
      let newHours = parseFloat(extraHours) + 0.5;
      windowsPlz({ win, newHours, id });
    }
  };

  return (
    <div>
      <h2 className="text-center text-info">Choose Extras</h2>
      <Row align="center">
        <Col xs={12} md={2}>
          <Card.Header> Ironing& Laundry</Card.Header>
          {ironing ? (
            <div id="selected-img">
              <img
                src={ironafter}
                width={100}
                height={100}
                alt="1"
                onClick={iron}
              />
            </div>
          ) : (
            <div id="img" onClick={iron}>
              <img src={ironbefore} width={100} height={100} alt="1" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Bedding Change</Card.Header>
          {myBedding ? (
            <div id="selected-img" onClick={bed}>
              <img src={bedafter} width={100} height={100} alt="2" />
            </div>
          ) : (
            <div id="img" onClick={bed}>
              <img src={bedbefore} width={100} height={100} alt="2" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Kitchen Cupboards</Card.Header>
          {kitchenCubs ? (
            <div id="selected-img" onClick={kitchen}>
              <img src={kitchenafter} width={100} height={100} alt="3" />
            </div>
          ) : (
            <div id="img" onClick={kitchen}>
              <img src={kitchenbefore} width={100} height={100} alt="3" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Inside Oven</Card.Header>
          {insideOven ? (
            <div id="selected-img" onClick={oven}>
              <img src={stoveafter} width={100} height={100} alt="4" />
            </div>
          ) : (
            <div id="img" onClick={oven}>
              <img src={stovebefore} width={100} height={100} alt="4" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Inside Fridge</Card.Header>
          {myFridge ? (
            <div id="selected-img" onClick={fridge}>
              <img src={fridgeafter} width={100} height={100} alt="5" />
            </div>
          ) : (
            <div id="img" onClick={fridge}>
              <img src={fridgbefore} width={100} height={100} alt="5" />
            </div>
          )}
        </Col>
        <Col md={2} xs={12}>
          <Card.Header>Internal Windows</Card.Header>
          {myWindows ? (
            <div id="selected-img" onClick={windows}>
              <img src={windowafter} width={100} height={100} alt="6" />
            </div>
          ) : (
            <div id="img" onClick={windows}>
              <img src={windowbefore} width={100} height={100} alt="6" />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        {ironing && (
          <Col md={2} xs={12}>
            <p className="text-center">
              {laundreyBasket}kg
              <i className="fa-solid fa-basket-shopping"></i>
            </p>
            <Row>
              <Col xs={4}>
                <Button
                  variant="outline-info"
                  size="sm"
                  className="float-left"
                  onClick={removeBasket}
                >
                  <i className="fas fa-minus"></i>
                </Button>
              </Col>
              <Col xs={4}>
                <p className="text-center">
                  <i className="fa-solid fa-basket-shopping"></i>
                </p>
              </Col>
              <Col xs={4}>
                <Button
                  variant="outline-success"
                  size="sm"
                  className="float-right"
                  onClick={addBasket}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
});

export default connect(mapStateToProps, {
  setNote,
  changeMyBedding,
  ironingPlz,
  kitchenPlz,
  ovenPlz,
  windowsPlz,
  fridgePlz,
})(IconExtras);
