import {
  APPLICATION,
  TEAM_LOADED,
  TEAM_FAIL,
  TEAM_REGISTERED,
  TEAM_LOGIN,
  TEAM_LOGOUT,
  CLEAR_TEAM,
  HR_APP,
  CLEAR_APP,
  MY_INT,
} from '../../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isTeam: null,
  team: null,
  app: null,
  teams: null,
  loadingTeam: true,
  int: null,
};

function teamReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HR_APP:
      return {
        ...state,
        app: payload,
      };
    case CLEAR_APP:
      return {
        ...state,
        app: null,
      };
    case MY_INT:
      return {
        ...state,
        int: payload,
      };
    case APPLICATION:
      return {
        ...state,
        team: payload,
        loadingTeam: false,
      };
    case TEAM_LOADED:
      return {
        ...state,
        isTeam: true,
        team: payload,
        loadingTeam: false,
      };
    case TEAM_REGISTERED:
      return {
        ...state,
      };
    case CLEAR_TEAM:
      return {
        ...state,
        team: null,
        loadingTeam: true,
      };
    case TEAM_LOGIN:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        team: payload,
        isTeam: true,
        loadingTeam: false,
      };

    case TEAM_FAIL:
      return {
        ...state,
        isTeam: false,
        team: null,
        loadingTeam: false,
      };
    case TEAM_LOGOUT:
      return {
        ...state,
        token: localStorage.removeItem('token'),
        isTeam: false,
        team: null,
        loadingTeam: false,
      };
    default:
      return state;
  }
}

export default teamReducer;
