import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Alert,
  Row,
  Col,
  ListGroup,
  Spinner,
} from 'react-bootstrap';
import { whatsTheTotalSQM } from '../../..//actions/user/bookings';
import Bottom from '../../Layout/Bottom';
import Sticky from 'react-sticky-el';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import StepCounter from './StepCounter';
import BusinessAddress from './BusinessAddress';

const BusinessBooking = ({
  bookings: { officeType, floors, totalSqm, stairways, ladies, gents },
  whatsTheTotalSQM,
}) => {
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (floors) {
      const jam = [];
      if (floors) {
        floors.forEach((floor) => jam.push(floor.total));
      }
      const initialValue = 0;
      const sumWithInitial = jam.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );
      whatsTheTotalSQM(sumWithInitial);
    }
  }, [floors, whatsTheTotalSQM]);

  return (
    <div>
      <Container>
        <h2>Thank you for choosing Dusting Down with your office cleaning</h2>
        <h4 className="text-center">
          To help us complete a quote for you that is accurate to your site
          please fill in the following points
        </h4>

        <Row>
          <Col>
            <Sticky
              stickyStyle={{
                top: 58,
                zIndex: 2,
                backgroundColor: 'white',
              }}
            >
              <div>
                <Alert variant="primary">
                  <i className="fas fa-shopping-cart"></i>
                  <p className="float-right">x Hours</p>
                </Alert>
                <Alert variant="info">Order Details</Alert>
                <ListGroup>
                  <ListGroup.Item>
                    Office type:{' '}
                    <p className="float-right">
                      {officeType ? `${officeType}` : `Select office type`}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Total Square meters:
                    <p className="float-right">{totalSqm} sqm</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Stairways: <p className="float-right">{stairways}</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Ladies Toilets:{' '}
                    <p className="float-right">{ladies && ladies.ladies}</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Gents Toilets:{' '}
                    <p className="float-right">{gents && gents.gents}</p>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Sticky>
          </Col>
          <Col md={8} xs={12}>
            <StepCounter step={step} setStep={setStep} />
            <BusinessAddress />
            {step === 1 ? (
              <Step1 setStep={setStep} />
            ) : step === 2 ? (
              <Step2 setStep={setStep} />
            ) : step === 3 ? (
              <Step3 setStep={setStep} />
            ) : step === 4 ? (
              <Step4 setStep={setStep} />
            ) : step === 5 ? (
              <Step5 />
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
});

export default connect(mapStateToProps, { whatsTheTotalSQM })(BusinessBooking);
