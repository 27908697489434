import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  ListGroup,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import {
  aQuery,
  queryTexts,
  setupQuerySocket,
} from '../../../actions/user/query';
import QueryTexts from './QueryTexts';
import './MyProfile.css';

const OrderQuery = ({
  queries: { query, texts, socket },
  aQuery,
  queryTexts,
  setupQuerySocket,
  user,
}) => {
  const { queryId } = useParams();
  useEffect(() => {
    aQuery(queryId);
    queryTexts(queryId);
    setupQuerySocket(queryId);
  }, [aQuery, queryTexts, setupQuerySocket, queryId]);
  const [text, setText] = useState('');
  // const [show, setShow] = useState(false);
  const [qtxt, setQtxt] = useState([]);
  const [sentBy, setSentBy] = useState(null);
  const [sentByName, setSentByName] = useState(null);
  const [queryAssigned, setQueryAssigned] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  useEffect(() => {
    if (socket) {
      socket.on('sendMsg', (msg) => {
        setQtxt((qtxt) => [...qtxt, msg]);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (query) {
      if (query.assigned) {
        setQueryAssigned(true);
      } else {
        setQueryAssigned(false);
      }
    }
  }, [query]);

  useEffect(() => {
    if (texts) {
      setQtxt(texts);
    }
  }, [texts]);

  useEffect(() => {
    if (user) {
      setSentBy(user._id);
      setSentByName(user.local.name);
    }
  }, [user]);

  useEffect(() => {
    if (qtxt) {
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  const navigate = useNavigate();
  const bottomRef = useRef(null);
  if (query === null || user === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const id = uuidv4();
    const timestamp = moment().format();
    const txt = {
      _id: id,
      query: query._id,
      sentBy: sentBy,
      sentByName: sentByName,
      text: text,
      timestamp: timestamp,
    };
    setQtxt((qtxt) => [...qtxt, txt]);

    socket.emit('sendMsg', {
      query: query._id,
      sentBy: sentBy,
      sentByName: sentByName,
      text: text,
      timestamp: timestamp,
    });
    setText('');
  };

  const navAway = () => {
    socket.emit('leave_query', { queryId: queryId });
    socket.disconnect();
    navigate(-1);
  };

  return (
    <Container>
      <Row>
        <Col className="text-center" id="query">
          <Card style={{ width: '30rem' }} xs={12} md={6}>
            <Form onSubmit={onSubmit}>
              <Card.Header className="bg-light text-info" align="center">
                <Button size="sm" variant="info float-left" onClick={navAway}>
                  <i className="fa-solid fa-angle-left"></i> Back to order
                </Button>
                {query.headline}
                {queryAssigned ? (
                  <Button size="sm" variant="success float-right" disabled>
                    Agent assigned
                  </Button>
                ) : (
                  <Button size="sm" variant="secondary float-right" disabled>
                    In review
                  </Button>
                )}
              </Card.Header>
              <ListGroup.Item>Query Description: {query.desc}</ListGroup.Item>

              <Card.Body style={{ height: '450px', overflowY: 'auto' }}>
                {qtxt.length === 0
                  ? 'Send your first message...'
                  : qtxt.map((text) => (
                      <QueryTexts key={text._id} text={text} userId={sentBy} />
                    ))}
                <div ref={bottomRef}></div>
              </Card.Body>

              <hr />
              <Form.Group className="m-2">
                <InputGroup>
                  <Form.Control
                    required
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Type Text"
                  />
                  <InputGroup.Append>
                    <Button variant="info text-white" type="submit">
                      Send<i className="far fa-paper-plane ml-2"></i>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  queries: state.user_queries,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  aQuery,
  queryTexts,
  setupQuerySocket,
})(OrderQuery);
