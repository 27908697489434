import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  recurringOrder,
  runRepeatFunctions,
} from '../../../../../actions/admin/main';
import {
  Container,
  Spinner,
  Alert,
  Button,
  Modal,
  Row,
  Col,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import ListOrder from './ListOrder';

const ReOrderOverview = ({
  recurringOrder,
  orders,
  runRepeatFunctions,
  note,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);

  let btn = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    recurringOrder();
  }, [recurringOrder]);

  const navigate = useNavigate();

  if (orders === null)
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );

  const goBack = () => {
    navigate(-1);
  };

  const runRepeat = () => {
    runRepeatFunctions();

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <Container>
      <Row>
        <Col>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>
          <h4>Recurring order overview</h4>
        </Col>
        <Col>
          <Button
            variant="outline-secondary float-right"
            size="sm"
            onClick={() => navigate('/admin/main/all-repeat-orders')}
          >
            All Orders
          </Button>
          <Button
            variant="outline-danger float-right mr-2"
            size="sm"
            onClick={handleShow}
          >
            Run Repeat
          </Button>
        </Col>
      </Row>

      <hr />

      {orders.orders.length === 0
        ? 'No Orders'
        : orders.orders.map((order) => (
            <ListOrder key={order._id} order={order} />
          ))}
      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">
          You are about to repeat orders for next month
        </Alert>
        <Modal.Body>Make it happen</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={runRepeat} ref={btn}>
            Run Repeat
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  orders: state.main.reOrderManagement,
  note: state.note,
});

export default connect(mapStateToProps, { recurringOrder, runRepeatFunctions })(
  ReOrderOverview
);
