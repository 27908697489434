import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import {
  jamMainBedroom,
  jamBed,
  jamLongPassage,
  jamShortPassage,
  jamStairCase,
  jamLanding,
  jamFamilyRoom,
  jamLounge,
  jamDiningRoom,
  jamStudy,
  jamDressingRoom,
  jamOtherRoom,
  jamSmallRug,
  jamMediumRug,
  jamLargeRug,
  jamTubChair,
  jamWingback,
  jamOneSeaterSofa,
  jamTwoSeaterSofa,
  jamThreeSeaterSofa,
  jamThreeSeaterEl,
  jamFourSeaterEl,
  jamFiveSeaterEl,
  jamSingleMattress,
  jamDoubleMattress,
  jamQueenMattress,
  jamKingMattress,
  jamSmallOttoman,
  jamLargeOttoman,
  jamMediumOttoman,
  jamChairsBaseOnly,
  jamChairsEverything,
  getCarpertMother,
} from '../../../actions/user/carpert_cleaning';
import { setNote } from '../../../actions/note';
import SofaModal from './SofaModal';
import MattressModal from './MattressModal';
import OttomanModal from './OttomanModal';
import ChairsModal from './ChairsModal';

const CarperCleaning = ({
  carpert_cleaning: {
    carpert_invoice,
    main_bedroom,
    bedroom,
    long_passage,
    short_passage,
    stair_case,
    landing,
    family_room,
    lounge,
    dining_room,
    study,
    dressing_room,
    other_room,
    small_rug,
    med_rug,
    large_rug,
    tub_chair,
    wingback,
    one_seater_sofa,
    two_seater_sofa,
    three_seater_sofa,
    three_seater_el,
    four_seater_el,
    five_seater_el,
    single_mattress,
    double_mattress,
    queen_mattress,
    king_mattress,
    small_ottoman,
    medium_ottoman,
    large_ottoman,
    dining_chair_base_only,
    dining_chair_base_back_arms,
    carpet_mother,
  },
  jamMainBedroom,
  jamBed,
  jamLongPassage,
  jamShortPassage,
  jamStairCase,
  jamLanding,
  jamFamilyRoom,
  jamLounge,
  jamDiningRoom,
  jamStudy,
  jamDressingRoom,
  jamOtherRoom,
  jamSmallRug,
  jamMediumRug,
  jamLargeRug,
  jamTubChair,
  jamWingback,
  jamOneSeaterSofa,
  jamTwoSeaterSofa,
  jamThreeSeaterSofa,
  jamThreeSeaterEl,
  jamFourSeaterEl,
  jamFiveSeaterEl,
  jamSingleMattress,
  jamDoubleMattress,
  jamQueenMattress,
  jamKingMattress,
  jamSmallOttoman,
  jamLargeOttoman,
  jamMediumOttoman,
  jamChairsBaseOnly,
  jamChairsEverything,
  setNote,
  setStage,
  getCarpertMother,
}) => {
  const [moveOn, setMoveOn] = useState(false);
  const [mainCarperts, setMainCarperts] = useState(false);
  const [rugs, setRugs] = useState(false);
  const [upholstery, setUpholstry] = useState(false);

  useEffect(() => {
    getCarpertMother();
  }, [getCarpertMother]);

  useEffect(() => {
    if (
      main_bedroom > 0 ||
      bedroom > 0 ||
      long_passage > 0 ||
      short_passage > 0 ||
      stair_case > 0 ||
      landing > 0 ||
      family_room > 0 ||
      lounge > 0 ||
      dining_room > 0 ||
      study > 0 ||
      dressing_room > 0 ||
      other_room > 0
    ) {
      setMainCarperts(true);
    } else {
      setMainCarperts(false);
    }
  }, [
    main_bedroom,
    bedroom,
    long_passage,
    short_passage,
    stair_case,
    landing,
    family_room,
    lounge,
    dining_room,
    study,
    dressing_room,
    other_room,
  ]);

  useEffect(() => {
    if (small_rug > 0 || med_rug > 0 || large_rug > 0) {
      setRugs(true);
    } else {
      setRugs(false);
    }
  }, [small_rug, med_rug, large_rug]);

  useEffect(() => {
    if (
      tub_chair > 0 ||
      wingback > 0 ||
      one_seater_sofa > 0 ||
      two_seater_sofa > 0 ||
      three_seater_sofa > 0 ||
      three_seater_el > 0 ||
      four_seater_el > 0 ||
      five_seater_el > 0 ||
      single_mattress > 0 ||
      double_mattress > 0 ||
      queen_mattress > 0 ||
      king_mattress > 0 ||
      small_ottoman > 0 ||
      medium_ottoman > 0 ||
      large_ottoman > 0 ||
      dining_chair_base_only > 0 ||
      dining_chair_base_back_arms > 0
    ) {
      setUpholstry(true);
    } else {
      setUpholstry(false);
    }
  }, [
    tub_chair,
    wingback,
    one_seater_sofa,
    two_seater_sofa,
    three_seater_sofa,
    three_seater_el,
    four_seater_el,
    five_seater_el,
    single_mattress,
    double_mattress,
    queen_mattress,
    king_mattress,
    small_ottoman,
    medium_ottoman,
    large_ottoman,
    dining_chair_base_only,
    dining_chair_base_back_arms,
  ]);

  useEffect(() => {
    if (mainCarperts || rugs || upholstery) {
      setMoveOn(true);
    } else {
      setMoveOn(false);
    }
  }, [mainCarperts, rugs, upholstery]);

  const minusMainBedroom = () => {
    if (main_bedroom === 0) {
      return setNote('You cannot have less than zero bedrooms', 'warning');
    }
    let mainBed = main_bedroom - 1;
    let invoice = carpert_invoice - carpet_mother.mainBed;
    jamMainBedroom({ mainBed, invoice });
  };
  const plusMainBedroom = () => {
    if (main_bedroom === 4) {
      return setNote('You cannot have more than 4 bedrooms', 'warning');
    }
    let mainBed = main_bedroom + 1;
    let invoice = carpert_invoice + carpet_mother.mainBed;
    jamMainBedroom({ mainBed, invoice });
  };
  const minusBedroom = () => {
    if (bedroom === 0) {
      return setNote('You cannot have less than zero bedrooms', 'warning');
    }
    let bed = bedroom - 1;
    let invoice = carpert_invoice - carpet_mother.bed;
    jamBed({ bed, invoice });
  };
  const plusBedroom = () => {
    if (bedroom === 6) {
      return setNote('You cannot have more than 6 bedrooms', 'warning');
    }
    let bed = bedroom + 1;
    let invoice = carpert_invoice + carpet_mother.bed;
    jamBed({ bed, invoice });
  };
  const minusLongPassage = () => {
    if (long_passage === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let longPassage = long_passage - 1;
    let invoice = carpert_invoice - carpet_mother.longPassage;
    jamLongPassage({ longPassage, invoice });
  };
  const plusLongPassage = () => {
    let longPassage = long_passage + 1;
    let invoice = carpert_invoice + carpet_mother.longPassage;
    jamLongPassage({ longPassage, invoice });
  };
  const minusShortPassage = () => {
    if (short_passage === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let shortPassage = short_passage - 1;
    let invoice = carpert_invoice - carpet_mother.shortPassage;
    jamShortPassage({ shortPassage, invoice });
  };
  const plusShortPassage = () => {
    let shortPassage = short_passage + 1;
    let invoice = carpert_invoice + carpet_mother.shortPassage;
    jamShortPassage({ shortPassage, invoice });
  };
  const plusStairCase = () => {
    let staircase = stair_case + 1;
    let invoice = carpert_invoice + carpet_mother.staircases;
    jamStairCase({ staircase, invoice });
  };
  const minusStairCase = () => {
    if (stair_case === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let staircase = stair_case - 1;
    let invoice = carpert_invoice - carpet_mother.staircases;
    jamStairCase({ staircase, invoice });
  };
  const plusLanding = () => {
    let land = landing + 1;
    let invoice = carpert_invoice + carpet_mother.landing;
    jamLanding({ land, invoice });
  };
  const minusLanding = () => {
    if (landing === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let land = landing - 1;
    let invoice = carpert_invoice - carpet_mother.landing;
    jamLanding({ land, invoice });
  };
  const addFamilyRoom = () => {
    let familyRoom = family_room + 1;
    let invoice = carpert_invoice + carpet_mother.famRoom;
    jamFamilyRoom({ familyRoom, invoice });
  };
  const minusFamilyRoom = () => {
    if (family_room === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let familyRoom = family_room - 1;
    let invoice = carpert_invoice - carpet_mother.famRoom;
    jamFamilyRoom({ familyRoom, invoice });
  };
  const minusLounge = () => {
    if (lounge === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let loung = lounge - 1;
    let invoice = carpert_invoice - carpet_mother.lounge;
    jamLounge({ loung, invoice });
  };
  const plusLounge = () => {
    let loung = lounge + 1;
    let invoice = carpert_invoice + carpet_mother.lounge;
    jamLounge({ loung, invoice });
  };
  const minusDiningRoom = () => {
    if (dining_room === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let diningRoom = dining_room - 1;
    let invoice = carpert_invoice - carpet_mother.diningRoom;
    jamDiningRoom({ diningRoom, invoice });
  };
  const plusDiningRoom = () => {
    let diningRoom = dining_room + 1;
    let invoice = carpert_invoice + carpet_mother.diningRoom;
    jamDiningRoom({ diningRoom, invoice });
  };
  const minusStudy = () => {
    if (study === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let stud = study - 1;
    let invoice = carpert_invoice - carpet_mother.study;
    jamStudy({ stud, invoice });
  };
  const plusStudy = () => {
    let stud = study + 1;
    let invoice = carpert_invoice + carpet_mother.study;
    jamStudy({ stud, invoice });
  };
  const plusDressRoom = () => {
    let dressingRoom = dressing_room + 1;
    let invoice = carpert_invoice + carpet_mother.dressingRoom;
    jamDressingRoom({ dressingRoom, invoice });
  };
  const minusDressRoom = () => {
    if (dressing_room === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let dressingRoom = dressing_room - 1;
    let invoice = carpert_invoice - carpet_mother.dressingRoom;
    jamDressingRoom({ dressingRoom, invoice });
  };
  const plusOtherRoom = () => {
    let otherRoom = other_room + 1;
    let invoice = carpert_invoice + carpet_mother.otherRoom;
    jamOtherRoom({ otherRoom, invoice });
  };
  const minusOtherRoom = () => {
    if (other_room === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let otherRoom = other_room - 1;
    let invoice = carpert_invoice - carpet_mother.otherRoom;
    jamOtherRoom({ otherRoom, invoice });
  };

  const plusSmallRug = () => {
    let smallRug = small_rug + 1;
    let invoice = carpert_invoice + carpet_mother.smallRug;
    jamSmallRug({ smallRug, invoice });
  };

  const minusSmallRug = () => {
    if (small_rug === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let smallRug = small_rug - 1;
    let invoice = carpert_invoice - carpet_mother.smallRug;
    jamSmallRug({ smallRug, invoice });
  };

  const plusMediumRug = () => {
    let mediumRug = med_rug + 1;
    let invoice = carpert_invoice + carpet_mother.medRug;
    jamMediumRug({ mediumRug, invoice });
  };

  const minusMediumRug = () => {
    if (med_rug === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }

    let mediumRug = med_rug - 1;
    let invoice = carpert_invoice - carpet_mother.medRug;
    jamMediumRug({ mediumRug, invoice });
  };

  const minusLargeRug = () => {
    if (large_rug === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let largeRug = large_rug - 1;
    let invoice = carpert_invoice - carpet_mother.lgRug;
    jamLargeRug({ largeRug, invoice });
  };

  const plusLargeRug = () => {
    let largeRug = large_rug + 1;
    let invoice = carpert_invoice + carpet_mother.lgRug;
    jamLargeRug({ largeRug, invoice });
  };

  const plusTubChair = () => {
    let tubChair = tub_chair + 1;
    let invoice = carpert_invoice + carpet_mother.tubChair;
    jamTubChair({ tubChair, invoice });
  };

  const minusTubChair = () => {
    if (tub_chair === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let tubChair = tub_chair - 1;
    let invoice = carpert_invoice - carpet_mother.tubChair;
    jamTubChair({ tubChair, invoice });
  };

  const plusWingback = () => {
    let wing = wingback + 1;
    let invoice = carpert_invoice + carpet_mother.wingBack;
    jamWingback({ wing, invoice });
  };

  const minusWingback = () => {
    if (wingback === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let wing = wingback - 1;
    let invoice = carpert_invoice - carpet_mother.wingBack;
    jamWingback({ wing, invoice });
  };

  const plusOneSeaterSofa = () => {
    let oneSeaterSofa = one_seater_sofa + 1;
    let invoice = carpert_invoice + carpet_mother.oneSeaterSofa;
    jamOneSeaterSofa({ oneSeaterSofa, invoice });
  };

  const minusOneSeaterSofa = () => {
    if (one_seater_sofa === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let oneSeaterSofa = one_seater_sofa - 1;
    let invoice = carpert_invoice - carpet_mother.oneSeaterSofa;
    jamOneSeaterSofa({ oneSeaterSofa, invoice });
  };

  const plusTwoSeaterSofa = () => {
    let twoSeaterSofa = two_seater_sofa + 1;
    let invoice = carpert_invoice + carpet_mother.twoSeaterSofa;
    jamTwoSeaterSofa({ twoSeaterSofa, invoice });
  };

  const minusTwoSeaterSofa = () => {
    if (two_seater_sofa === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let twoSeaterSofa = two_seater_sofa - 1;
    let invoice = carpert_invoice - carpet_mother.twoSeaterSofa;
    jamTwoSeaterSofa({ twoSeaterSofa, invoice });
  };

  const plusThreeSeaterSofa = () => {
    let threeSeaterSofa = three_seater_sofa + 1;
    let invoice = carpert_invoice + carpet_mother.threeSeaterSofa;
    jamThreeSeaterSofa({ threeSeaterSofa, invoice });
  };

  const minusThreeSeaterSofa = () => {
    if (three_seater_sofa === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let threeSeaterSofa = three_seater_sofa - 1;
    let invoice = carpert_invoice - carpet_mother.threeSeaterSofa;
    jamThreeSeaterSofa({ threeSeaterSofa, invoice });
  };

  const plusThreeSeaterEl = () => {
    let threeSeaterEl = three_seater_el + 1;
    let invoice = carpert_invoice + carpet_mother.elShapedThree;
    jamThreeSeaterEl({ threeSeaterEl, invoice });
  };

  const minusthreeSeaterEl = () => {
    if (three_seater_el === 0) {
      return setNote('You cannot have less than 0', 'warning');
    }

    let threeSeaterEl = three_seater_el - 1;
    let invoice = carpert_invoice - carpet_mother.elShapedThree;
    jamThreeSeaterEl({ threeSeaterEl, invoice });
  };

  const plusFourSeaterEl = () => {
    let fourSeaterEl = four_seater_el + 1;
    let invoice = carpert_invoice + carpet_mother.elShapedFour;
    jamFourSeaterEl({ fourSeaterEl, invoice });
  };

  const minusFourSeaterEl = () => {
    if (four_seater_el === 0) {
      return setNote('You cannot have less than 0', 'warning');
    }
    let fourSeaterEl = four_seater_el - 1;
    let invoice = carpert_invoice - carpet_mother.elShapedFour;
    jamFourSeaterEl({ fourSeaterEl, invoice });
  };

  const plusFiveSeaterEl = () => {
    let fiveSeaterEl = five_seater_el + 1;
    let invoice = carpert_invoice + carpet_mother.elShapedFive;
    jamFiveSeaterEl({ fiveSeaterEl, invoice });
  };

  const minusFiveSeaterEl = () => {
    if (five_seater_el === 0) {
      return setNote('You cannot have less than 0', 'warning');
    }
    let fiveSeaterEl = five_seater_el - 1;
    let invoice = carpert_invoice - carpet_mother.elShapedFive;
    jamFiveSeaterEl({ fiveSeaterEl, invoice });
  };

  const plusSingleMattress = () => {
    let singleMattress = single_mattress + 1;
    let invoice = carpert_invoice + carpet_mother.singleMattress;
    jamSingleMattress({ singleMattress, invoice });
  };

  const minusSingleMattress = () => {
    if (single_mattress === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let singleMattress = single_mattress - 1;
    let invoice = carpert_invoice - carpet_mother.singleMattress;
    jamSingleMattress({ singleMattress, invoice });
  };
  const plusDoubleMattress = () => {
    let doubleMattress = double_mattress + 1;
    let invoice = carpert_invoice + carpet_mother.doubleMattress;
    jamDoubleMattress({ doubleMattress, invoice });
  };

  const minusDoubleMattress = () => {
    if (double_mattress === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let doubleMattress = double_mattress - 1;
    let invoice = carpert_invoice - carpet_mother.doubleMattress;
    jamDoubleMattress({ doubleMattress, invoice });
  };

  const plusQueenMattress = () => {
    let queenMattress = queen_mattress + 1;
    let invoice = carpert_invoice + carpet_mother.queenMattress;
    jamQueenMattress({ queenMattress, invoice });
  };

  const minusQueenMattress = () => {
    if (queen_mattress === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let queenMattress = queen_mattress - 1;
    let invoice = carpert_invoice - carpet_mother.queenMattress;
    jamQueenMattress({ queenMattress, invoice });
  };

  const plusKingMattress = () => {
    let kingMattress = king_mattress + 1;
    let invoice = carpert_invoice + carpet_mother.kingMattress;
    jamKingMattress({ kingMattress, invoice });
  };

  const minusKingMattress = () => {
    if (king_mattress === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let kingMattress = king_mattress - 1;
    let invoice = carpert_invoice - carpet_mother.kingMattress;
    jamKingMattress({ kingMattress, invoice });
  };

  const plusSmallOttoman = () => {
    let smallOttoman = small_ottoman + 1;
    let invoice = carpert_invoice + carpet_mother.smallOttoman;
    jamSmallOttoman({ smallOttoman, invoice });
  };

  const minusSmallOttoman = () => {
    if (small_ottoman === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let smallOttoman = small_ottoman - 1;
    let invoice = carpert_invoice - carpet_mother.smallOttoman;
    jamSmallOttoman({ smallOttoman, invoice });
  };

  const plusMediumOttoman = () => {
    let mediumOttoman = medium_ottoman + 1;
    let invoice = carpert_invoice + carpet_mother.mediumOttoman;
    jamMediumOttoman({ mediumOttoman, invoice });
  };

  const minusMediumOttoman = () => {
    if (medium_ottoman === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let mediumOttoman = medium_ottoman - 1;
    let invoice = carpert_invoice - carpet_mother.mediumOttoman;
    jamMediumOttoman({ mediumOttoman, invoice });
  };

  const plusLargeOttoman = () => {
    let largeOttoman = large_ottoman + 1;
    let invoice = carpert_invoice + carpet_mother.largeOttoman;
    jamLargeOttoman({ largeOttoman, invoice });
  };

  const minusLargeOttoman = () => {
    if (large_ottoman === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let largeOttoman = large_ottoman - 1;
    let invoice = carpert_invoice - carpet_mother.largeOttoman;
    jamLargeOttoman({ largeOttoman, invoice });
  };

  const plusChairsBase = () => {
    let chairBase = dining_chair_base_only + 1;
    let invoice = carpert_invoice + carpet_mother.chairBase;
    jamChairsBaseOnly({ chairBase, invoice });
  };

  const minusChairBase = () => {
    if (dining_chair_base_only === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }
    let chairBase = dining_chair_base_only - 1;
    let invoice = carpert_invoice - carpet_mother.chairBase;
    jamChairsBaseOnly({ chairBase, invoice });
  };

  const plusChairsEverything = () => {
    let chairsEverything = dining_chair_base_back_arms + 1;
    let invoice = carpert_invoice + carpet_mother.chairEverything;
    jamChairsEverything({ chairsEverything, invoice });
  };

  const minusChairsEverything = () => {
    if (dining_chair_base_back_arms === 0) {
      return setNote('You cannot have less than zero', 'warning');
    }

    let chairsEverything = dining_chair_base_back_arms - 1;
    let invoice = carpert_invoice - carpet_mother.chairEverything;
    jamChairsEverything({ chairsEverything, invoice });
  };

  if (carpet_mother === null)
    return <Spinner variant="primary" animation="grow" />;
  console.log(carpert_invoice);
  return (
    <div className="mt-4">
      <h5 className="text-info text-center">
        Let us know which parts of your property require a carpet clean...
      </h5>
      <hr />
      <Row>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusMainBedroom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {main_bedroom} Main bedrooms
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusMainBedroom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusBedroom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {bedroom} Bedrooms
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusBedroom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusLongPassage}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {long_passage} Long passages
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusLongPassage}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusShortPassage}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {short_passage} Short passages
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusShortPassage}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusStairCase}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {stair_case} Staircases
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusStairCase}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusLanding}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {landing} Landing
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusLanding}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusFamilyRoom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {family_room} Family room
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={addFamilyRoom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusLounge}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {lounge} Lounge
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusLounge}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusDiningRoom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {dining_room} Dining room
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusDiningRoom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusStudy}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {study} Study
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusStudy}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusDressRoom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {dressing_room} Dressing room
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusDressRoom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusOtherRoom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {other_room} Other room
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusOtherRoom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
      <hr />
      <h5 className="text-info text-center">Would you like to add some rugs</h5>
      <Row>
        <Col xs={4}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusSmallRug}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {small_rug} Small rug
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusSmallRug}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col xs={4}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusMediumRug}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {med_rug} Med rug
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusMediumRug}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col xs={4}>
          <Card.Title align="center">
            <Button
              variant="outline-secondary"
              className="float-left"
              onClick={minusLargeRug}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {large_rug} Large rug
            <Button
              variant="outline-secondary"
              className="float-right"
              onClick={plusLargeRug}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
      <hr />
      <h5 className="text-info text-center">How about some upholstery</h5>

      <Row>
        <Col xs={3}>
          <SofaModal
            tub_chair={tub_chair}
            wingback={wingback}
            one_seater_sofa={one_seater_sofa}
            two_seater_sofa={two_seater_sofa}
            three_seater_sofa={three_seater_sofa}
            three_seater_el={three_seater_el}
            four_seater_el={four_seater_el}
            five_seater_el={five_seater_el}
            plusTubChair={plusTubChair}
            minusTubChair={minusTubChair}
            plusWingback={plusWingback}
            minusWingback={minusWingback}
            plusOneSeaterSofa={plusOneSeaterSofa}
            minusOneSeaterSofa={minusOneSeaterSofa}
            plusTwoSeaterSofa={plusTwoSeaterSofa}
            minusTwoSeaterSofa={minusTwoSeaterSofa}
            plusThreeSeaterSofa={plusThreeSeaterSofa}
            minusThreeSeaterSofa={minusThreeSeaterSofa}
            plusThreeSeaterEl={plusThreeSeaterEl}
            minusthreeSeaterEl={minusthreeSeaterEl}
            plusFourSeaterEl={plusFourSeaterEl}
            minusFourSeaterEl={minusFourSeaterEl}
            plusFiveSeaterEl={plusFiveSeaterEl}
            minusFiveSeaterEl={minusFiveSeaterEl}
          />
        </Col>
        <Col xs={3}>
          <MattressModal
            single_mattress={single_mattress}
            double_mattress={double_mattress}
            queen_mattress={queen_mattress}
            king_mattress={king_mattress}
            plusSingleMattress={plusSingleMattress}
            minusSingleMattress={minusSingleMattress}
            plusDoubleMattress={plusDoubleMattress}
            minusDoubleMatress={minusDoubleMattress}
            plusQueenMattress={plusQueenMattress}
            minusQueenMattress={minusQueenMattress}
            plusKingMattress={plusKingMattress}
            minusKingMattress={minusKingMattress}
          />
        </Col>
        <Col xs={3}>
          <OttomanModal
            small_ottoman={small_ottoman}
            medium_ottoman={medium_ottoman}
            large_ottoman={large_ottoman}
            plusSmallOttman={plusSmallOttoman}
            minusSmallOttoman={minusSmallOttoman}
            plusMediumOttoman={plusMediumOttoman}
            minusMediumOttoman={minusMediumOttoman}
            plusLargeOttoman={plusLargeOttoman}
            minusLargeOttman={minusLargeOttoman}
          />
        </Col>
        <Col xs={3}>
          <ChairsModal
            dining_chair_base_only={dining_chair_base_only}
            dining_chair_base_back_arms={dining_chair_base_back_arms}
            plusChairBase={plusChairsBase}
            minusChairBase={minusChairBase}
            plusChairsEverything={plusChairsEverything}
            minusChairsEverything={minusChairsEverything}
          />
        </Col>
      </Row>

      {moveOn ? (
        <Button
          variant="outline-info mt-3"
          id="next"
          onClick={() => setStage(2)}
        >
          Next
        </Button>
      ) : (
        <Button variant="warning mt-3" id="next" block>
          Let us know what you would like cleaned?
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  carpert_cleaning: state.carpert_cleaning,
});

export default connect(mapStateToProps, {
  jamMainBedroom,
  jamBed,
  jamLongPassage,
  jamShortPassage,
  jamStairCase,
  jamLanding,
  jamFamilyRoom,
  jamLounge,
  jamDiningRoom,
  jamStudy,
  jamDressingRoom,
  jamOtherRoom,
  jamSmallRug,
  jamMediumRug,
  jamLargeRug,
  jamTubChair,
  jamWingback,
  jamOneSeaterSofa,
  jamTwoSeaterSofa,
  jamThreeSeaterSofa,
  jamThreeSeaterEl,
  jamFourSeaterEl,
  jamFiveSeaterEl,
  jamSingleMattress,
  jamDoubleMattress,
  jamQueenMattress,
  jamKingMattress,
  jamSmallOttoman,
  jamLargeOttoman,
  jamMediumOttoman,
  jamChairsBaseOnly,
  jamChairsEverything,
  setNote,
  getCarpertMother,
})(CarperCleaning);
