import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteDuster = ({ dustBuster, removeDuster, deleteBtn }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="danger ml-2"
        className="float-right"
        size="sm"
        onClick={handleShow}
      >
        <i className="fas fa-trash"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {dustBuster.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b className="text-center">You cannot undo this!</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          <Button variant="danger" onClick={removeDuster} ref={deleteBtn}>
            Delete Duster <i className="fas fa-trash"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteDuster;
