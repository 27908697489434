import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Modal } from 'react-bootstrap';
import { bookJob } from '../../../actions/team/team_work';
import moment from 'moment';
import ExtraItem from './ExtraItem';

const AvailableWork = ({ aw, bookJob, mySchedule }) => {
  const [show, setShow] = useState(false);
  const [mrx, SetMrx] = useState([]);
  const [start, SetStart] = useState('');
  const [end, SetEnd] = useState('');
  const [orderId, SetOrderId] = useState('');
  const [available, setAvailable] = useState([]);

  useEffect(() => {
    if (aw) {
      let endTime = moment(aw.orderTime).add(aw.invoiceHours, 'hours').format();
      SetOrderId(aw._id);
      SetStart(aw.orderTime);
      SetEnd(endTime);
      SetMrx(aw.mrx);
    }
  }, [SetMrx, SetStart, SetOrderId, SetEnd, aw]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    let bookingTime = aw.orderTime;
    let jam = mySchedule.filter((ms, index) => {
      let schedule = ms.start.split('T').shift();
      let booking = bookingTime.split('T').shift();
      return schedule === booking;
    });
    setAvailable(jam);

    setShow(true);
  };

  const address = aw.address.split(',');

  const jobTime = moment(aw.orderTime).format('ddd MMM D, h:mm a');

  const booking = () => {
    bookJob({ start, end, orderId });
    setShow(false);
  };

  return (
    <div>
      <Card className="mt-3">
        <Card.Header>
          <b>Job Date:</b> {jobTime}
        </Card.Header>
        <Card.Body>
          <Card.Title>
            {aw.bedroom} Bedroom/s {aw.bathroom} Bathroom/s
            <b> in :</b> {address[1]}, {address[2]}
          </Card.Title>
          <Card.Body>
            {mrx.length === 0
              ? 'This job has no extra'
              : mrx.map((x) => {
                  return <ExtraItem x={x} key={x.id} />;
                })}
          </Card.Body>
          <div>
            <Button variant="info" disabled>
              Time: {aw.invoiceHours}/hrs
            </Button>
          </div>
          <div>
            <Button variant="warning mt-2" onClick={handleShow}>
              Book This Job!
            </Button>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Job Date:</b> {jobTime}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-right">
          <b>The Job is in :</b> {address[1]}, {address[2]} {''}
          Please make sure you will be able to complete the job before you
          confirm
          {mrx.length === 0
            ? 'This job has no extra'
            : mrx.map((x) => {
                return <ExtraItem x={x} key={x.id} />;
              })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Exit
          </Button>
          {available.length === 0 ? (
            <Button variant="success" onClick={booking}>
              Confirm Booking
            </Button>
          ) : (
            <Button variant="warning text-white" disabled>
              You already booked on this day!
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});
export default connect(mapStateToProps, { bookJob })(AvailableWork);
