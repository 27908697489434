import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import QrReader from 'react-qr-reader';

const QRRScanner = ({
  checkInRep,
  checkOutRep,
  checkedIn,
  checkedOut,
  currentReOrder,
}) => {
  const [show, setShow] = useState(false);
  const [checkin, setCheckIn] = useState(false);
  useEffect(() => {
    if (checkedIn.length <= checkedOut.length) {
      setCheckIn(true);
    }
  }, [setCheckIn, checkedIn, checkedOut]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const QRScanError = (error) => {
    console.log('Hello: ', error);
  };
  const QRScanSuccess = (result) => {
    if (result) {
      let qrOrderId = result;
      let reOrderId = currentReOrder;
      if (checkin === true) {
        checkInRep({ qrOrderId, reOrderId });
        setShow(false);
      } else {
        checkOutRep({ qrOrderId, reOrderId });
        setShow(false);
      }
    }
  };
  return (
    <div>
      {checkin === false ? (
        <Button variant="warning" onClick={handleShow}>
          Scan QR Code To Check Out
        </Button>
      ) : (
        <Button onClick={handleShow}>Scan QR Code To Check In</Button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Scan QR Code</Modal.Header>
        <Modal.Body>
          <QrReader
            delay={300}
            style={{ width: '100%' }}
            onError={QRScanError}
            onScan={QRScanSuccess}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default QRRScanner;
