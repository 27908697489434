import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetUser, GetUserAddress } from '../../../../actions/admin/admin_user';
import {
  setOrderAddress,
  addBed,
  removeBed,
  addBath,
  removeBath,
  getMother,
  ironingPlz,
  basketToggle,
  changeMyBedding,
  kitchenPlz,
  ovenPlz,
  fridgePlz,
  windowsPlz,
  setOrderTime,
  setHowTo,
  clearOrderSchedule,
  setOrderWeekdays,
  toggleTypeOfOrder,
  setOrderFrequency,
  repeatCalender,
  fullTimeCleaner,
  setRegularInvoice,
  jamOrderExtras,
  clearCreatedOrders,
} from '../../../../actions/admin/order_for_client';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import UserAddressModal from './UserAddressModal';
import BedAndBath from './BedAndBath';
import Extras from './Extras';
import { setNote } from '../../../../actions/note';
import DateAndTime from './DateAndTime';
import OfficeBooking from './OfficeBooking/OfficeBooking';

const CreateOrderForUser = ({
  getMother,
  GetUser,
  GetUserAddress,
  admin_users: { user, address },
  order_for_client: {
    loading,
    mother,
    orderHours,
    invoice,
    regInvoice,
    text,
    addressType,
    bedroom,
    bathroom,
    mrx,
    myBedding,
    ironing,
    laundreyBasket,
    kitchenCubs,
    insideOven,
    myWindows,
    myFridge,
    orderTime,
    how,
    regular,
    weekdays,
    schedule,
    frequency,
  },
  setOrderAddress,
  addBed,
  removeBed,
  setNote,
  addBath,
  removeBath,
  ironingPlz,
  basketToggle,
  changeMyBedding,
  kitchenPlz,
  ovenPlz,
  fridgePlz,
  windowsPlz,
  setOrderTime,
  setHowTo,
  clearOrderSchedule,
  setOrderWeekdays,
  toggleTypeOfOrder,
  setOrderFrequency,
  repeatCalender,
  fullTimeCleaner,
  setRegularInvoice,
  jamOrderExtras,
  clearCreatedOrders,
}) => {
  const { userId } = useParams();
  const [prettyDay, setPrettyDay] = useState('');
  const [arrivalTime, setArrivalTime] = useState(null);
  const [tomorrow, setTomorrow] = useState('');
  const [selectedDay, setSelectedDay] = useState('');

  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    clearCreatedOrders();
    getMother();
    GetUser(userId);
    GetUserAddress(userId);
  }, [userId, GetUser, GetUserAddress, getMother, clearCreatedOrders]);

  useEffect(() => {
    if (user) {
      let text = user.address;
      let complex = user.complex;
      let coordinates = user.coordinates;
      let addressType = user.addressType;
      setOrderAddress({ text, complex, coordinates, addressType });
    }
  }, [user, setOrderAddress]);

  useEffect(() => {
    if (schedule) {
      let juice = invoice;
      let a = schedule.length;
      let monthly = juice * a;
      let discount = monthly * 0.1;
      let amount = monthly - discount;
      setRegularInvoice(amount);
    }
  }, [schedule, setRegularInvoice, invoice]);

  const goBack = () => {
    navigate(-1);
  };

  const checkOutForClient = () => {
    navigate(`/admin/user/check-out-for-user/${userId}`);
  };

  if (user === null || loading) {
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  }

  const changeOrderAddress = ({ text, complex, coordinates, addressType }) => {
    setOrderAddress({ text, complex, coordinates, addressType });
  };

  return (
    <Container>
      <Row>
        <Col xs={1}>
          <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
        </Col>
        <Col xs={6}>
          <h4>
            Create order for: {user.name} {user.surname}
          </h4>
        </Col>
        <Col>
          Order Hours :<b> {orderHours} hrs</b>
        </Col>
        <Col>
          Order Invoice :<b>{regular ? `R${regInvoice}` : `R${invoice}`} </b>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={8}>
          <b>
            Booking address: {text} - {addressType}
          </b>
        </Col>
        <Col xs={4}>
          <UserAddressModal
            user={user}
            address={address}
            changeOrderAddress={changeOrderAddress}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        {addressType === 'Office' ? (
          <OfficeBooking />
        ) : (
          <>
            <BedAndBath
              bedroom={bedroom}
              bathroom={bathroom}
              addBed={addBed}
              removeBed={removeBed}
              mother={mother}
              orderHours={orderHours}
              setNote={setNote}
              addBath={addBath}
              removeBath={removeBath}
            />
            <hr />
            <Row>
              <Col xs={6}>
                <Extras
                  bedroom={bedroom}
                  myBedding={myBedding}
                  ironing={ironing}
                  kitchenCubs={kitchenCubs}
                  insideOven={insideOven}
                  myWindows={myWindows}
                  myFridge={myFridge}
                  laundreyBasket={laundreyBasket}
                  basketToggle={basketToggle}
                  setNote={setNote}
                  changeMyBedding={changeMyBedding}
                  ironingPlz={ironingPlz}
                  kitchenPlz={kitchenPlz}
                  windowsPlz={windowsPlz}
                  fridgePlz={fridgePlz}
                  ovenPlz={ovenPlz}
                  hours={orderHours}
                  mother={mother}
                  mrx={mrx}
                  jamOrderExtras={jamOrderExtras}
                />
              </Col>
              <Col xs={6}>
                <DateAndTime
                  setOrderTime={setOrderTime}
                  prettyDay={prettyDay}
                  setPrettyDay={setPrettyDay}
                  arrivalTime={arrivalTime}
                  selectedDay={selectedDay}
                  setArrivalTime={setArrivalTime}
                  orderHours={orderHours}
                  tomorrow={tomorrow}
                  setSelectedDay={setSelectedDay}
                  setNote={setNote}
                  setTomorrow={setTomorrow}
                  orderTime={orderTime}
                  how={how}
                  setHowTo={setHowTo}
                  clearOrderSchedule={clearOrderSchedule}
                  setOrderWeekdays={setOrderWeekdays}
                  setOrderFrequency={setOrderFrequency}
                  toggleTypeOfOrder={toggleTypeOfOrder}
                  repeatCalender={repeatCalender}
                  fullTimeCleaner={fullTimeCleaner}
                  regular={regular}
                  weekdays={weekdays}
                  schedule={schedule}
                  frequency={frequency}
                />
              </Col>
            </Row>
            <hr />
            {how && how.a !== '' ? (
              <>
                <Button variant="outline-info" onClick={checkOutForClient}>
                  Check out for user
                </Button>
              </>
            ) : (
              ''
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_users: state.admin_users,
  order_for_client: state.order_for_client,
});

export default connect(mapStateToProps, {
  GetUser,
  GetUserAddress,
  setOrderAddress,
  addBed,
  removeBed,
  getMother,
  setNote,
  addBath,
  removeBath,
  ironingPlz,
  basketToggle,
  changeMyBedding,
  kitchenPlz,
  ovenPlz,
  fridgePlz,
  windowsPlz,
  setOrderTime,
  setHowTo,
  clearOrderSchedule,
  setOrderWeekdays,
  toggleTypeOfOrder,
  setOrderFrequency,
  repeatCalender,
  fullTimeCleaner,
  setRegularInvoice,
  jamOrderExtras,
  clearCreatedOrders,
})(CreateOrderForUser);
