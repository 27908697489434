import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const OnMyWayRepeat = ({ repeatOMW, currentReOrder, note }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);
  let btn = useRef();
  const [eta, setEta] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitETA = () => {
    repeatOMW({ eta, currentReOrder });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <>
      <Button variant="warning mb-2" size="sm" onClick={handleShow}>
        On my way!
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you on your way?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicText">
            <Form.Label>Please let us know how far you are...</Form.Label>
            <Form.Control
              type="text"
              name="eta"
              value={eta}
              placeholder="eg; 15 minutes late"
              onChange={(e) => setEta(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="info" onClick={submitETA} ref={btn}>
            Submit ETA
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OnMyWayRepeat;
