import axios from 'axios';
import {
  MY_TEAM_PROFILE,
  CLEAR_PROFILE,
  TEAM_PROFILE_ERROR,
  CLEAR_FILE,
} from '../types';
import { teamMember } from './';
import { setNote } from '../note';

//Get Current Team Members Profile//
export const myTeamProfile = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  try {
    const res = await axios.get('/api/team_profile');
    dispatch({ type: MY_TEAM_PROFILE, payload: res.data });
  } catch (err) {
    dispatch({
      type: TEAM_PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Upload Pro Pic//
export const uploadProPic =
  ({ dusterId, pic }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_FILE });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ pic });
    try {
      const res = await axios.put(
        `/api/team_profile/pro-pic/${dusterId}`,
        body,
        config
      );
      dispatch({ type: MY_TEAM_PROFILE, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Add Team Member Profile//
export const fixTeamProfile =
  ({ idDoc, workPermit, bankLetter, clearance }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ idDoc, workPermit, bankLetter, clearance });
    try {
      const res = await axios.put('/api/team_profile/', body, config);
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(teamMember());
    } catch (error) {
      dispatch({ type: TEAM_PROFILE_ERROR });
    }
  };
