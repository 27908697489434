import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  createPayslip,
  removePayslipItem,
  updateTotalReHours,
} from '../../../../../actions/admin/main';
import { Modal, Button, Row, Form, Alert, Col } from 'react-bootstrap';
import moment from 'moment';
const RepeatSlipItem = ({
  order,
  index,
  check,
  rate,
  calculateRepeatHours,
  createPayslip,
  currentSlip,
  removePayslipItem,
  updateTotalReHours,
  totalRepeatHours,
  duster_payslip,
}) => {
  const [init, setInit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState();
  const [hours, setHours] = useState(0);
  const [expectedHours, setExpectedHours] = useState(0);
  const [pay, setPay] = useState(0);
  const [client, setClient] = useState();
  const [show, setShow] = useState(false);
  const [checkIn, setCheckIn] = useState();
  const [checkOut_, setCheckOut] = useState();
  const [newCheckIn, setNewCheckIn] = useState(null);
  const [newCheckOut, setNewCheckOut] = useState(null);
  const [approved, setApproved] = useState(false);

  const handleClose = () => {
    setNewCheckIn(null);
    setNewCheckOut(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)];
  const ref = splitAt(5, order._id);
  useEffect(() => {
    if (init === false) {
      setCheckIn(order.checkIn[index]);
      setCheckOut(check);
      setClient(order.user);
    }
  }, [order, setCheckOut, init, index, setCheckIn, check, hours]);

  useEffect(() => {
    if (checkIn || checkOut_) {
      if (update) {
        const date1 = new Date(checkOut_);
        const date2 = new Date(checkIn);
        const diffTime = Math.abs(date1 - date2);
        const hoursWorked = (diffTime / (1000 * 60 * 60)).toFixed(1);
        updateTotalReHours(totalRepeatHours, hours, hoursWorked);
        setHours(hoursWorked);
        const newPay = (hoursWorked * rate).toFixed(2);
        setPay(newPay);
        setUpdate(false);
      }
    }
  }, [
    checkIn,
    checkOut_,
    update,
    rate,
    updateTotalReHours,
    totalRepeatHours,
    hours,
  ]);

  useEffect(() => {
    if (checkIn || checkOut_) {
      if (init === false) {
        const date1 = new Date(checkOut_);
        const date2 = new Date(checkIn);
        const diffTime = Math.abs(date1 - date2);
        const hoursWorked = (diffTime / (1000 * 60 * 60)).toFixed(1);
        setHours(hoursWorked);
        setExpectedHours(hoursWorked);
        calculateRepeatHours(parseFloat(hoursWorked));
        const newPay = (hoursWorked * rate).toFixed(2);
        setPay(newPay);
        setInit(true);
      }
    }
  }, [checkIn, checkOut_, calculateRepeatHours, index, rate, init]);

  //update booking time//
  const updateTime = () => {
    if (newCheckIn) {
      let str = checkIn.split('T');
      let date = `${str[0]}T${newCheckIn}:00+02:00`;
      setCheckIn(date);
    }
    if (newCheckOut) {
      let str = checkOut_.split('T');
      let date = `${str[0]}T${newCheckOut}:00+02:00`;
      setCheckOut(date);
    }
    setUpdate(true);
    setShow(false);
  };

  //add booking to payslip//
  const approveMe = async () => {
    const id = currentSlip.length + 1;
    setId(id);
    const booking = {
      id: id,
      ref: order._id,
      client: client.name,
      rate: rate,
      checkIn: checkIn,
      checkOut: checkOut_,
      hours: parseFloat(hours),
    };

    const res = await createPayslip(booking);
    if (res) {
      setApproved(true);
    } else {
      console.log('Something went wrong');
    }
  };

  //remove booking from payslip//
  const removeMe = async () => {
    const res = await removePayslipItem(id);
    if (res === false) {
      setApproved(res);
    } else {
      console.log('Something went wrong');
    }
  };

  return (
    <>
      <tr>
        <th>{ref[0]}...</th>
        <th>{moment(check).format('MMM Do')}</th>
        <th>{client && client.name}</th>
        <th>R{rate}/hr</th>
        <th>{moment(checkIn).format('DD MMM HH:mm')}</th>
        <th>{moment(checkOut_).format('DD MMM HH:mm')}</th>
        <th>{hours}/hrs</th>
        <th>{expectedHours}/hrs</th>
        <th>N/A</th>
        <th>R {pay}</th>
        {duster_payslip === null && (
          <th>
            {approved ? (
              <>
                <i className="fa-regular fa-square-check float-right text-success"></i>
                <i
                  className="fa-solid fa-ban float-left text-danger"
                  onClick={removeMe}
                ></i>
              </>
            ) : (
              <>
                <i
                  className="fa-regular fa-pen-to-square float-left"
                  onClick={handleShow}
                ></i>
                <i
                  className="fa-regular fa-square-check float-right"
                  onClick={approveMe}
                ></i>
              </>
            )}
          </th>
        )}
      </tr>

      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">
          Edit checkin and/or checkout times {moment(check).format('MMM Do')}
        </Alert>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Label>
                  Check in time: {moment(checkIn).format('HH:mm')}
                </Form.Label>
                <Form.Control
                  placeholder={moment(checkIn).format('HH:mm')}
                  name="newCheckIn"
                  value={newCheckIn}
                  onChange={(e) => setNewCheckIn(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Label>
                  Check out time: {moment(checkOut_).format('HH:mm')}
                </Form.Label>
                <Form.Control
                  placeholder={moment(checkOut_).format('HH:mm')}
                  name="newCheckOut"
                  value={newCheckOut}
                  onChange={(e) => setNewCheckOut(e.target.value)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateTime}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, {
  createPayslip,
  removePayslipItem,
  updateTotalReHours,
})(RepeatSlipItem);
