import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Bottom from '../../Layout/Bottom';
import { signInBusiness } from '../../../actions/business';

const BusinessLogin = ({ note, signInBusiness, isBusiness }) => {
  const [nte, setMsg] = useState(note);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  let btn = useRef();

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    signInBusiness({ email, password });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  if (isBusiness) {
    return <Navigate to="/business/business-booking" />;
  }
  return (
    <div>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Card className="card bg-info text-white mb-2" align="center">
              <Card.Body>
                <Card.Title>Welcome To Dusting Down</Card.Title>
                <Card.Subtitle>This is the business login page</Card.Subtitle>
                <span style={{ fontSize: '7em' }}>
                  <i className="fa-solid fa-building"></i>
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card.Header className="text-white bg-info">
              <Row>
                <Col>
                  <h5 align="center">Business Sign In:</h5>
                </Col>
              </Row>
            </Card.Header>
            <Form className="mt-2" onSubmit={onSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Button variant="info btn-block" type="submit" ref={btn}>
                Submit <i className="fas fa-comment ml-3"></i>
              </Button>
            </Form>
            <hr />
            <Row>
              <Col>
                <Button
                  variant="outline-primary"
                  as={Link}
                  to="/business/register-business"
                  block
                >
                  Create an account
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-warning"
                  as={Link}
                  to="/business/request-business-reset"
                  block
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  isBusiness: state.business.isBusiness,
});

export default connect(mapStateToProps, { signInBusiness })(BusinessLogin);
