import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

const BedAndBath = ({
  bedroom,
  bathroom,
  addBed,
  removeBed,
  addBath,
  removeBath,
  mother,
  orderHours,
  setNote,
}) => {
  const plusBedroom = () => {
    let bed = bedroom + 1;
    if (bed === 7) {
      setNote('Cant have more than 6 bedrooms', 'warning');
    } else {
      addBed({ bed, orderHours, mother });
    }
  };
  const minusBedroom = () => {
    let bed = bedroom - 1;
    if (bed === -1) {
      setNote('Cant have less than 0 bedrooms', 'warning');
    } else {
      removeBed({ bed, orderHours, mother });
    }
  };
  const plusBathroom = () => {
    let bath = bathroom + 1;
    if (bath === 5) {
      setNote('Cant have more than 4 bathrooms', 'warning');
    } else {
      addBath({ bath, orderHours, mother });
    }
  };
  const minusBathroom = () => {
    let bath = bathroom - 1;
    if (bath === -1) {
      setNote('Cant have less than 0 bathrooms', 'warning');
    } else {
      removeBath({ bath, orderHours, mother });
    }
  };
  return (
    <div className="mb-3">
      <Row>
        <Col md={6} xs={12}>
          <Card.Title className="text-center">Bedrooms</Card.Title>
          <Card.Header className="text-center mb-2">
            <b>How many Bedrooms?</b>
          </Card.Header>
          <Card.Title align="center">
            <Button
              variant="outline-info"
              className="float-left"
              onClick={minusBedroom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {bedroom} Bedrooms
            <Button
              variant="outline-success"
              className="float-right"
              onClick={plusBedroom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
        <Col md={6} xs={12}>
          <Card.Title className="text-center">Bathrooms</Card.Title>
          <Card.Header className="mb-2 text-center">
            <b>How many bathrooms?</b>
          </Card.Header>
          <Card.Title align="center">
            <Button
              variant="outline-info"
              className="float-left"
              onClick={minusBathroom}
            >
              <i className="fas fa-minus"></i>
            </Button>
            {bathroom} Bathrooms
            <Button
              variant="outline-success"
              className="float-right"
              onClick={plusBathroom}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Card.Title>
        </Col>
      </Row>
    </div>
  );
};

export default BedAndBath;
