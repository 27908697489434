import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Row, Col } from 'react-bootstrap';

const Footer = () => {
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    const link = window.location.href;
    const compare = link.split('/');
    const string = `${compare[0]}//${compare[2]}/${compare[3]}/${compare[4]}`;
    const url = `${process.env.REACT_APP_BASE_URL}user/peach-app-payment`;

    if (string === url) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [setDisplay]);
  return display ? (
    <Row>
      <Navbar
        fixed="bottom"
        className="text-primary"
        style={{ backgroundColor: '#f5f5f5' }}
      >
        <Col>
          Copyright 2024 <i className="fas fa-copyright ml-1 mr-2"></i>
          <Link to="/" className="text-primary">
            Dusting Down
          </Link>
        </Col>
        <Col>
          <span style={{ display: 'block', float: 'right' }}>
            <Link to="/user/agreement" className="text-primary">
              Terms & Privacy Policy
            </Link>
          </span>
        </Col>
      </Navbar>
    </Row>
  ) : (
    ''
  );
};
export default Footer;
