import { useState, useEffect } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import Calender from './Calender';
import ConfirmDateAndTime from './ConfirmDateAndTime';
import moment from 'moment';
import Schedule from './Schedule';

const DateAndTime = ({
  setOrderTime,
  prettyDay,
  setPrettyDay,
  arrivalTime,
  setArrivalTime,
  orderHours,
  tomorrow,
  selectedDay,
  setSelectedDay,
  setNote,
  setTomorrow,
  orderTime,
  how,
  setHowTo,
  clearOrderSchedule,
  setOrderWeekdays,
  toggleTypeOfOrder,
  repeatCalender,
  fullTimeCleaner,
  regular,
  weekdays,
  schedule,
  frequency,
  setOrderFrequency,
}) => {
  const [moreInfo, setMoreInfo] = useState('');
  const [open, setOpen] = useState(false);
  const [OnceAWeek, SetOnceAWeek] = useState(false);
  const [TwiceAWeek, SetTwiceAWeek] = useState(false);
  const [ThreeAWeek, SetThreeAWeek] = useState(false);
  const [FullTime, SetFullTime] = useState(false);
  const [Mon, SetMon] = useState(false);
  const [Tue, SetTue] = useState(false);
  const [Wed, SetWed] = useState(false);
  const [Thu, SetThu] = useState(false);
  const [Fri, SetFri] = useState(false);
  const [Sat, SetSat] = useState(false);
  const [Sun, SetSun] = useState(false);
  const [days, Setdays] = useState(0);
  const [daysLeft, setDaysLeft] = useState([]);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(8);
    setTomorrow(date);
  }, [setTomorrow]);

  useEffect(() => {
    if (daysLeft) {
      let hours = orderHours;
      repeatCalender({ daysLeft, weekdays, hours });
    }
  }, [repeatCalender, daysLeft, weekdays, orderHours]);

  useEffect(() => {
    if (regular === true) {
      setOpen(true);
      if (frequency === 1) {
        SetOnceAWeek(true);
      }
      if (frequency === 2) {
        SetTwiceAWeek(true);
      }
      if (frequency === 3) {
        SetThreeAWeek(true);
      }
      if (frequency === 5) {
        SetFullTime(true);
      }
      weekdays.forEach((d) => {
        if (d === 'Mon') {
          SetMon(true);
        }
        if (d === 'Tue') {
          SetTue(true);
        }
        if (d === 'Wed') {
          SetWed(true);
        }
        if (d === 'Thu') {
          SetThu(true);
        }
        if (d === 'Fri') {
          SetFri(true);
        }
        if (d === 'Sat') {
          SetSat(true);
        }
        if (d === 'Sun') {
          SetSun(true);
        }
      });
    }
  }, [regular, frequency, weekdays]);

  const resetDT = () => {
    setSelectedDay(null);
    setArrivalTime(null);
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(8);
    setTomorrow(date);
    setOrderTime(null);
  };

  const setDate = (date) => {
    let loot = moment(date).format();
    setOrderTime(loot);
  };

  const hideRegular = () => {
    SetOnceAWeek(false);
    SetTwiceAWeek(false);
    SetThreeAWeek(false);
    SetFullTime(false);
    SetMon(false);
    SetTue(false);
    SetWed(false);
    SetThu(false);
    SetFri(false);
    SetSat(false);
    SetSun(false);
    toggleTypeOfOrder(false);
    setOpen(false);
    setOrderWeekdays([]);
    Setdays(0);
    clearOrderSchedule([]);
  };

  const showRegular = () => {
    SetOnceAWeek(true);
    setOrderFrequency(1);
    toggleTypeOfOrder(true);
    setOpen(true);
  };

  const once = () => {
    if (days >= 2) return setNote('You need to select less days!', 'warning');
    if (TwiceAWeek === true) SetTwiceAWeek(false);
    if (ThreeAWeek === true) SetThreeAWeek(false);
    if (FullTime === true) SetFullTime(false);
    setOrderFrequency(1);
    SetOnceAWeek(true);
  };

  const twice = () => {
    if (days >= 3) return setNote('You need to select less days!', 'warning');
    if (OnceAWeek === true) SetOnceAWeek(false);
    if (ThreeAWeek === true) SetThreeAWeek(false);
    if (FullTime === true) SetFullTime(false);
    setOrderFrequency(2);
    SetTwiceAWeek(true);
  };

  const three = () => {
    if (days >= 4) return setNote('You need to select less days!', 'warning');
    if (TwiceAWeek === true) SetTwiceAWeek(false);
    if (OnceAWeek === true) SetOnceAWeek(false);
    if (FullTime === true) SetFullTime(false);
    setOrderFrequency(3);
    SetThreeAWeek(true);
  };

  const full = () => {
    if (TwiceAWeek === true) SetTwiceAWeek(false);
    if (ThreeAWeek === true) SetThreeAWeek(false);
    if (OnceAWeek === true) SetOnceAWeek(false);
    setOrderFrequency(5);
    SetFullTime(true);
    Setdays(5);
  };

  const setHow = (how) => {
    if (how === 'Some One Is At Home') {
      setMoreInfo('Some One Is At Home');
      setHowTo({ a: 'Some One Is At Home', b: '' });
    } else if (how === 'Key Safe') {
      setMoreInfo('What is the safe pin eg: 9876');
      setHowTo({ a: 'Key Safe', b: '' });
    } else if (how === 'Key Hidden') {
      setMoreInfo('Where is the key hidden');
      setHowTo({ a: 'Key Hidden', b: '' });
    } else if (how === 'Other') {
      setMoreInfo('Please let us know how to gain access for the clean');
      setHowTo({ a: 'Other', b: '' });
    }
  };

  return (
    <div className="mt-2">
      <Row>
        <Col className="text-center">
          <Button
            variant={regular ? 'outline-secondary' : 'info'}
            onClick={() => hideRegular()}
          >
            Once Off
          </Button>
          <Button
            variant={regular ? 'info' : 'outline-secondary'}
            onClick={() => showRegular()}
            data-toggle="collapse"
          >
            Regular
          </Button>
        </Col>
      </Row>

      {orderTime ? (
        <ConfirmDateAndTime
          resetDT={resetDT}
          regular={regular}
          schedule={schedule}
          orderTime={orderTime}
          how={how}
          setHow={setHow}
          moreInfo={moreInfo}
        />
      ) : regular ? (
        <>
          <Schedule
            once={once}
            twice={twice}
            three={three}
            full={full}
            days={days}
            weekdays={weekdays}
            Setdays={Setdays}
            daysLeft={daysLeft}
            schedule={schedule}
            invoiceHours={orderHours}
            OnceAWeek={OnceAWeek}
            TwiceAWeek={TwiceAWeek}
            ThreeAWeek={ThreeAWeek}
            FullTime={FullTime}
            SetMon={SetMon}
            Mon={Mon}
            SetTue={SetTue}
            Tue={Tue}
            SetWed={SetWed}
            Wed={Wed}
            SetThu={SetThu}
            Thu={Thu}
            SetFri={SetFri}
            Fri={Fri}
            SetSat={SetSat}
            Sat={Sat}
            SetSun={SetSun}
            Sun={Sun}
            setOrderWeekdays={setOrderWeekdays}
            open={open}
            clearOrderSchedule={clearOrderSchedule}
            fullTimeCleaner={fullTimeCleaner}
            setNote={setNote}
          />
          {frequency === weekdays.length && (
            <>
              <Alert>
                <i className="fas fa-info-circle mr-4"></i>
                Based on cleaning days selected when would you like the 1st
                clean to be?
              </Alert>
              <Calender
                setDate={setDate}
                arrivalTime={arrivalTime}
                setArrivalTime={setArrivalTime}
                regular={regular}
                weekdays={weekdays}
                setNote={setNote}
                setDaysLeft={setDaysLeft}
                daysLeft={daysLeft}
                invoiceHours={orderHours}
                repeatCalender={repeatCalender}
                schedule={schedule}
                tomorrow={tomorrow}
                orderTime={orderTime}
                setSelectedDay={setSelectedDay}
                selectedDay={selectedDay}
                setPrettyDay={setPrettyDay}
                prettyDay={prettyDay}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Alert variant="warning text-center mt-3">
            When should you the duster to arrive?
          </Alert>
          <Calender
            setDate={setDate}
            prettyDay={prettyDay}
            setPrettyDay={setPrettyDay}
            arrivalTime={arrivalTime}
            setArrivalTime={setArrivalTime}
            orderHours={orderHours}
            regular={regular}
            tomorrow={tomorrow}
            weekdays={weekdays}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            setNote={setNote}
            setDaysLeft={setDaysLeft}
          />
        </>
      )}
    </div>
  );
};

export default DateAndTime;
