import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store';

//Layout Pages//
import Header from './components/Layout/Header';
import Note from './components/Layout/Note';
import Home from './components/Layout/Home';
import Footer from './components/Layout/Footer';

//Team Pages//
import SignUp from './components/Team/Auth/SignUp';
import EmailApply from './components/Team/Auth/EmailApply';
import CompleteApplication from './components/Team/Auth/CompleteApplication';
import TeamVerify from './components/Team/Auth/Verify';
import SignIn from './components/Team/Auth/SignIn';
import ForgotPassword from './components/Team/Auth/ForgotPassword';
import ResetPassword from './components/Team/Auth/ResetPassword';
import TeamRoute from './components/routing/TeamRoute';
import ReadyToWork from './components/Team/Working/ReadyToWork';
import Agreement from './components/Team/Agreement';
import MyBooking from './components/Team/Working/MyBooking';
import MyReBooking from './components/Team/Working/MyReBooking';
import DustBuster from './components/Team/DustBuster';

//Business User//
import RegisterBusiness from './components/BusinessUser/Auth/RegisterBusiness';
import VerifyBusiness from './components/BusinessUser/Auth/VerifyBusiness';
import BusinessLogin from './components/BusinessUser/Auth/BusinessLogin';
import RequestBusinessReset from './components/BusinessUser/Auth/RequestBusinessReset';
import ResetBusiness from './components/BusinessUser/Auth/ResetBusiness';
import BusinessBooking from './components/BusinessUser/Booking/BusinessBooking';

//User Pages//
import UserSignUp from './components/User/Auth/SignUp';
import FriendSignUp from './components/User/Auth/FriendSignUp';
import PromoSignUp from './components/User/Auth/PromoSignUp';
import UserSignIn from './components/User/Auth/SignIn';
import UserVerify from './components/User/Auth/Verify';
import VerifyEmail from './components/User/Auth/VerifyEmail';
import VerifyNewEmail from './components/User/Auth/VerifyNewEmail';
import ForgotUserPassword from './components/User/Auth/ForgotUserPassword';
import ResetUserPassword from './components/User/Auth/ResetUserPassword';
import UserAgree from './components/User/UserAgree';
import EndOfTenancy from './components/User/EndOfTenancy';
import HomeCleaning from './components/User/HomeCleaning';
import OfficeCleaning from './components/User/OfficeCleaning';
import AirBnB from './components/User/AirBnB';
import CarpertCleaning from './components/User/CarpertCleaning';
import UserRoute from './components/routing/UserRoute';
import Booking from './components/User/Booking/Booking';
import CheckOut from './components/User/Booking/Payments/CheckOut';
import CarpertCleaningCheckOut from './components/User/Booking/Payments/CarpertCleaningCheckOut';
import MyCarpertClean from './components/User/Profile/MyCarpertClean';
import MyCarpertCleans from './components/User/Profile/MyCarpertCleans';
import RepeatCheckOut from './components/User/Booking/Payments/RepeatCheckOut';
import OzowResponse from './components/User/Booking/Payments/OzowResponse';
import PeachResponse from './components/User/Booking/Payments/PeachResponse';
import MonthlyOrder from './components/User/Booking/Payments/MonthlyOrder';
import FreeOrder from './components/User/Booking/Payments/FreeOrder';
import MyProfile from './components/User/Profile/MyProfile';
import MyOrders from './components/User/Profile/MyOrders';
import MyReOrders from './components/User/Profile/MyReOrders';
import MyOrder from './components/User/Profile/Order';
import MyReOrder from './components/User/Profile/MyReOrder';
import OrderHistory from './components/User/Profile/OrderHistory';
import AppPeachPayment from './components/User/Booking/Payments/AppPeachPayment';
import MyInvoice from './components/User/Profile/MyInvoice';
import UserOrderQuery from './components/User/Profile/OrderQuery';
import OrderQueries from './components/User/Profile/OrderQueries';

//Admin Pages//
import AdminSignIn from './components/Admin/AdminSignin';
import AdminRoute from './components/routing/AdminRoute';
import AdminDashboard from './components/Admin/Dashboard/AdminDashboard';
import TeamProfile from './components/Admin/TeamProfile/TeamProfile';
import AdminDusters from './components/Admin/Dashboard/DustBusters/AdminDusters';
import AllReady from './components/Admin/Dashboard/DustBusters/AllReady';
import StageOne from './components/Admin/Dashboard/DustBusters/StageOne';
import StageTwo from './components/Admin/Dashboard/DustBusters/StageTwo';
import StageThree from './components/Admin/Dashboard/DustBusters/StageThree';
import DailyDusters from './components/Admin/Dashboard/DustBusters/DailyDusters';
import Blacklist from './components/Admin/Dashboard/DustBusters/Blacklist';
import DusterOrders from './components/Admin/TeamProfile/DusterOrders';
import DusterReOrders from './components/Admin/TeamProfile/DusterReOrders';
import DusterHR from './components/Admin/TeamProfile/DusterHR';
import DusterDocs from './components/Admin/TeamProfile/DusterDocs';
import AdminUsers from './components/Admin/Dashboard/Users/AdminUsers';
import AddNewUser from './components/Admin/Dashboard/Users/AddNewUser.';
import CreateOrderForUser from './components/Admin/Dashboard/Users/CreateOrderForUser';
import CheckOutForUser from './components/Admin/Dashboard/Users/CheckOutForUser';
import CarpertCleaningDash from './components/Admin/CarpertCleaning/CarpertCleaningDash';
import AdminCarpertClean from './components/Admin/CarpertCleaning/CarpertClean';
import AdminUser from './components/Admin/Dashboard/Users/AdminUser';
import Coupon from './components/Admin/Dashboard/Users/Coupon';
import Main from './components/Admin/Dashboard/Main/Main';
import Accounts from './components/Admin/Dashboard/Main/Accounting/Accounts';
import OneTimeOrderDash from './components/Admin/OrderManagement/OneTimeOrderDash';
import RepeatBookingDash from './components/Admin/OrderManagement/RepeatBookingDash';
import ManageOrders from './components/Admin/OrderManagement/ManageOrders';
import OrdersForTheMonth from './components/Admin/OrderManagement/OrdersForTheMonth';
import ReOrdersForTheMonth from './components/Admin/OrderManagement/ReOrdersForTheMonth';
import AllocatedOrders from './components/Admin/OrderManagement/AllocatedOrders';
import OrderNotAllocated from './components/Admin/OrderManagement/OrderNotAllocated';
import MissedOrders from './components/Admin/OrderManagement/MissedOrders';
import MissedReOrders from './components/Admin/OrderManagement/MissedReOrders';
import RunningLate from './components/Admin/OrderManagement/RunningLate';
import CurrentlyActive from './components/Admin/OrderManagement/CurrentlyActive';
import TodaysOrders from './components/Admin/OrderManagement/TodaysOrders';
import CompleteOrders from './components/Admin/OrderManagement/CompleteOrders';
import RequireValidation from './components/Admin/OrderManagement/RequireValidation';
import ReOrderNotAllocated from './components/Admin/OrderManagement/ReOrderNotAllocated';
import ReOrdersRunningLate from './components/Admin/OrderManagement/ReOrdersRunningLate';
import ReOrdersInProgress from './components/Admin/OrderManagement/ReOrdersInProgress';
import ReOrdersToday from './components/Admin/OrderManagement/ReOrdersToday';
import OrderAdmin from './components/Admin/OrderManagement/OrderAdmin';
import ReOrderAdmin from './components/Admin/OrderManagement/ReOrderAdmin';
import RepeatValidation from './components/Admin/OrderManagement/RepeatValidation';
import CompleteReOrders from './components/Admin/OrderManagement/CompleteReOrders';
import AllocatedReOrders from './components/Admin/OrderManagement/AllocatedReOrders';
import Invoices from './components/Admin/Dashboard/Main/Accounting/Invoices';
import Invoice from './components/Admin/Dashboard/Main/Accounting/Invoice';
import PayingDusters from './components/Admin/Dashboard/Main/Accounting/PayingDusters';
import Payslip from './components/Admin/Dashboard/Main/Accounting/Payslip';
import OrdersOnAccount from './components/Admin/Dashboard/Main/Accounting/OrdersOnAccount';
import ReOrderOverview from './components/Admin/Dashboard/Main/Accounting/ReOrderOverview';
import AllQueries from './components/Admin/OrderQuery/AllQueries';
import OrderQuery from './components/Admin/OrderQuery/OrderQuery';
import CompleteQueries from './components/Admin/OrderQuery/CompleteQueries';
import CouponPage from './components/Admin/Dashboard/Main/Coupons/CouponPage';
import CreateCoupon from './components/Admin/Dashboard/Main/Coupons/CreateCoupon';
import AddNewDuster from './components/Admin/Dashboard/DustBusters/AddNewDuster';
import ITDash from './components/Admin/ITDepartment/ITDash';
import EmployeeDash from './components/Admin/Dashboard/Main/Employees/EmployeeDash';
import AllRepeatOrders from './components/Admin/Dashboard/Main/Accounting/AllRepeatOrders';
import Affiliates from './components/Admin/Affiliates/Affiliates';
import TemplateDashboard from './components/Admin/Dashboard/Main/Temaplates/TemplateDashboard';
import NextTwoWeeks from './components/Admin/OrderManagement/NextTwoWeeks';

import 'bootstrap/dist/css/bootstrap.min.css';

import { teamMember } from './actions/team';
import { currentUser } from './actions/user';
import { adminLoaded } from './actions/admin';
import { getCurrentBusiness } from './actions/business';

import setUserToken from './utils/setUserToken';

import './App.css';

if (localStorage.token) {
  setUserToken(localStorage.token);
}

function App() {
  const [showAdmin, setShowAdmin] = useState(false);
  useEffect(() => {
    setUserToken(localStorage.token);
    store.dispatch(teamMember());
  }, []);
  useEffect(() => {
    setUserToken(localStorage.token);
    store.dispatch(currentUser());
  }, []);
  useEffect(() => {
    setUserToken(localStorage.token);
    store.dispatch(adminLoaded());
  }, []);
  useEffect(() => {
    setUserToken(localStorage.token);
    store.dispatch(getCurrentBusiness());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        {showAdmin === false && <Header setShowAdmin={setShowAdmin} />}
        <Note />
        <Footer />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/team/signup" element={<SignUp />} />
          <Route
            exact
            path="/click-email-application/:dusterId"
            element={<EmailApply />}
          />
          <Route
            exact
            path="/team/complete-application/:dusterId"
            element={<CompleteApplication />}
          />
          <Route exact path="/team/signin" element={<SignIn />} />
          <Route exact path="/verify/team/:token" element={<TeamVerify />} />
          <Route
            exact
            path="/team/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/auth/team/reset-password/:token"
            element={<ResetPassword />}
          />
          <Route
            exact
            path="/business/register-business"
            element={<RegisterBusiness />}
          />
          <Route
            exact
            path="/verify-business/:token"
            element={<VerifyBusiness />}
          />
          <Route
            exact
            path="/business/business-login"
            element={<BusinessLogin />}
          />

          <Route
            exact
            path="/business/request-business-reset"
            element={<RequestBusinessReset />}
          />

          <Route
            exact
            path="/business/password-reset/:token"
            element={<ResetBusiness />}
          />

          <Route
            exact
            path="/business/business-booking"
            element={<BusinessBooking />}
          />

          <Route
            exact
            path="/team/work-feed"
            element={<TeamRoute component={ReadyToWork} />}
          />
          <Route
            exact
            path="/team/my-booking/:orderId"
            element={<TeamRoute component={MyBooking} />}
          />
          <Route
            exact
            path="/team/my-rebooking/:reOrderId"
            element={<TeamRoute component={MyReBooking} />}
          />
          <Route
            exact
            path="/team/dust-buster-profile"
            element={<TeamRoute component={DustBuster} />}
          />
          <Route exact path="/team/agreement" element={<Agreement />} />

          <Route exact path="/admin/signin" element={<AdminSignIn />} />
          <Route
            exact
            path="/admin/dashboard"
            element={<AdminRoute component={AdminDashboard} />}
          />
          <Route
            exact
            path="/admin/it-dash"
            element={<AdminRoute component={ITDash} />}
          />
          <Route
            exact
            path="/admin/employee-dash"
            element={<AdminRoute component={EmployeeDash} />}
          />
          <Route
            exact
            path="/admin/main"
            element={<AdminRoute component={Main} />}
          />
          <Route
            exact
            path="/admin/main/all-repeat-orders"
            element={<AdminRoute component={AllRepeatOrders} />}
          />
          <Route
            exact
            path="/admin/affiliates"
            element={<AdminRoute component={Affiliates} />}
          />
          <Route
            exact
            path="/admin/template-dashboard"
            element={<AdminRoute component={TemplateDashboard} />}
          />
          <Route
            exact
            path="/admin/one-time-booking-dash"
            element={<AdminRoute component={OneTimeOrderDash} />}
          />
          <Route
            exact
            path="/admin/carpert-cleaning-dashboard"
            element={<AdminRoute component={CarpertCleaningDash} />}
          />
          <Route
            exact
            path="/admin/order-management/carpert-cleaning/:carpertId"
            element={<AdminRoute component={AdminCarpertClean} />}
          />
          <Route
            exact
            path="/admin/repeat-booking-dash"
            element={<AdminRoute component={RepeatBookingDash} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/this-month"
            element={<AdminRoute component={OrdersForTheMonth} />}
          />
          <Route
            exact
            path="/admin/order-management"
            element={<AdminRoute component={ManageOrders} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/allocated"
            element={<AdminRoute component={AllocatedOrders} />}
          />
          <Route
            exact
            path="/admin/all-queries"
            element={<AdminRoute component={AllQueries} />}
          />
          <Route
            exact
            path="/admin/queries/completed-queries"
            element={<AdminRoute component={CompleteQueries} />}
          />
          <Route
            exact
            path="/admin/order-query/:queryId"
            element={<AdminRoute component={OrderQuery} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/not-allocated"
            element={<AdminRoute component={OrderNotAllocated} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/require-validation"
            element={<AdminRoute component={RequireValidation} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/not-allocated"
            element={<AdminRoute component={ReOrderNotAllocated} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/running-late"
            element={<AdminRoute component={RunningLate} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/active-orders"
            element={<AdminRoute component={CurrentlyActive} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/todays-orders"
            element={<AdminRoute component={TodaysOrders} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/complete-orders"
            element={<AdminRoute component={CompleteOrders} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/next-two-weeks"
            element={<AdminRoute component={NextTwoWeeks} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/this-month"
            element={<AdminRoute component={ReOrdersForTheMonth} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/missed-orders"
            element={<AdminRoute component={MissedOrders} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/missed-reorders"
            element={<AdminRoute component={MissedReOrders} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/running-late"
            element={<AdminRoute component={ReOrdersRunningLate} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/in-progess"
            element={<AdminRoute component={ReOrdersInProgress} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/today"
            element={<AdminRoute component={ReOrdersToday} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/require-validation"
            element={<AdminRoute component={RepeatValidation} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/complete-orders"
            element={<AdminRoute component={CompleteReOrders} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/allocated-re-orders"
            element={<AdminRoute component={AllocatedReOrders} />}
          />
          <Route
            exact
            path="/admin/order-management/once-off/order/:orderId"
            element={<AdminRoute component={OrderAdmin} />}
          />
          <Route
            exact
            path="/admin/order-management/repeat-orders/order/:orderId"
            element={<AdminRoute component={ReOrderAdmin} />}
          />
          <Route
            exact
            path="/admin/admin-accounts"
            element={<AdminRoute component={Accounts} />}
          />
          <Route
            exact
            path="/admin/admin-dusters"
            element={<AdminRoute component={AdminDusters} />}
          />
          <Route
            exact
            path="/admin/team-member/:dusterId"
            element={<AdminRoute component={TeamProfile} />}
          />
          <Route
            exact
            path="/admin/team/stage-one"
            element={<AdminRoute component={StageOne} />}
          />
          <Route
            exact
            path="/admin/team/stage-two"
            element={<AdminRoute component={StageTwo} />}
          />
          <Route
            exact
            path="/admin/team/stage-three"
            element={<AdminRoute component={StageThree} />}
          />
          <Route
            exact
            path="/admin/team/ready-to-work"
            element={<AdminRoute component={AllReady} />}
          />

          <Route
            exact
            path="/admin/team/daily-dusters"
            element={<AdminRoute component={DailyDusters} />}
          />
          <Route
            exact
            path="/admin/team/blacklist"
            element={<AdminRoute component={Blacklist} />}
          />
          <Route
            exact
            path="/admin/admin_users/"
            element={<AdminRoute component={AdminUsers} />}
          />
          <Route
            exact
            path="/admin/users/add-new-user"
            element={<AdminRoute component={AddNewUser} />}
          />
          <Route
            exact
            path="/admin/users/create-order-for-user/:userId"
            element={<AdminRoute component={CreateOrderForUser} />}
          />
          <Route
            exact
            path="/admin/user/check-out-for-user/:userId"
            element={<AdminRoute component={CheckOutForUser} />}
          />
          <Route
            exact
            path="/admin/team/duster-orders/:dusterId"
            element={<AdminRoute component={DusterOrders} />}
          />
          <Route
            exact
            path="/admin/team/duster-repeat/:dusterId"
            element={<AdminRoute component={DusterReOrders} />}
          />
          <Route
            exact
            path="/admin/team/duster-hr/:dusterId"
            element={<AdminRoute component={DusterHR} />}
          />
          <Route
            exact
            path="/admin/team/duster-docs/:dusterId"
            element={<AdminRoute component={DusterDocs} />}
          />
          <Route
            exact
            path="/admin/admin_user/:userId"
            element={<AdminRoute component={AdminUser} />}
          />
          <Route
            exact
            path="/admin/coupons/create-coupon"
            element={<AdminRoute component={Coupon} />}
          />
          <Route
            exact
            path="/admin/admin-accounts/invoices"
            element={<AdminRoute component={Invoices} />}
          />
          <Route
            exact
            path="/admin/admin-accounts/order-invoice/:invoiceId"
            element={<AdminRoute component={Invoice} />}
          />
          <Route
            exact
            path="/admin/admin-accounts/paying-dusters"
            element={<AdminRoute component={PayingDusters} />}
          />
          <Route
            exact
            path="/admin/admin-accounts/orders-on-account"
            element={<AdminRoute component={OrdersOnAccount} />}
          />
          <Route
            exact
            path="/admin/admin-accounts/duster-payslip/:dusterId"
            element={<AdminRoute component={Payslip} />}
          />
          <Route
            exact
            path="/admin/admin-accounts/recurring-order-overview"
            element={<AdminRoute component={ReOrderOverview} />}
          />
          <Route
            exact
            path="/admin/coupon-page"
            element={<AdminRoute component={CouponPage} />}
          />
          <Route
            exact
            path="/admin/create-coupon"
            element={<AdminRoute component={CreateCoupon} />}
          />
          <Route
            exact
            path="/admin/add-new-duster"
            element={<AdminRoute component={AddNewDuster} />}
          />
          <Route exact path="/user/signup" element={<UserSignUp />} />
          <Route exact path="/user/signup/:userId" element={<FriendSignUp />} />
          <Route
            exact
            path="/user/sign-up/dusting51"
            element={<PromoSignUp />}
          />
          <Route exact path="/user/agreement" element={<UserAgree />} />
          <Route exact path="/user/end-of-tenancy" element={<EndOfTenancy />} />
          <Route exact path="/user/home-cleaning" element={<HomeCleaning />} />
          <Route exact path="/user/air-bnb-cleaning" element={<AirBnB />} />
          <Route
            exact
            path="/user/carpert-cleaning"
            element={<CarpertCleaning />}
          />
          <Route
            exact
            path="/user/office-cleaning"
            element={<OfficeCleaning />}
          />
          <Route exact path="/user/signin" element={<UserSignIn />} />
          <Route exact path="/verify/user/:token" element={<UserVerify />} />
          <Route
            exact
            path="/verify-new-email-change/user/:userEmail/:userId"
            element={<VerifyNewEmail />}
          />
          <Route
            exact
            path="/user/password-reset-request"
            element={<ForgotUserPassword />}
          />
          <Route
            exact
            path="/user/password-reset/:token"
            element={<ResetUserPassword />}
          />

          <Route exact path="/user/booking/" element={<Booking />} />
          <Route
            exact
            path="/booking/check-out"
            element={<UserRoute component={CheckOut} />}
          />
          <Route
            exact
            path="/booking/repeat-checkout"
            element={<UserRoute component={RepeatCheckOut} />}
          />
          <Route
            exact
            path="/booking/check-out-carpert-clean"
            element={<UserRoute component={CarpertCleaningCheckOut} />}
          />
          <Route
            exact
            path="/user/my-carpert-clean/:carpertId"
            element={<UserRoute component={MyCarpertClean} />}
          />
          <Route
            exact
            path="/user/carpert-cleans/"
            element={<UserRoute component={MyCarpertCleans} />}
          />
          <Route
            exact
            path="/payments/payment-response/"
            element={<UserRoute component={OzowResponse} />}
          />
          <Route
            exact
            path="/payments/card-response"
            element={<UserRoute component={PeachResponse} />}
          />
          <Route
            exact
            path="/payments/free-order"
            element={<UserRoute component={FreeOrder} />}
          />
          <Route
            exact
            path="/payments/monthly-order"
            element={<UserRoute component={MonthlyOrder} />}
          />
          <Route
            exact
            path="/user/my-orders"
            element={<UserRoute component={MyOrders} />}
          />
          <Route
            exact
            path="/user/my-repeat-orders"
            element={<UserRoute component={MyReOrders} />}
          />
          <Route
            exact
            path="/user/my-order/:orderId"
            element={<UserRoute component={MyOrder} />}
          />
          <Route
            exact
            path="/user/my-re-order/:orderId"
            element={<UserRoute component={MyReOrder} />}
          />
          <Route
            exact
            path="/user/my-profile"
            element={<UserRoute component={MyProfile} />}
          />
          <Route
            exact
            path="/user/my-order-history"
            element={<UserRoute component={OrderHistory} />}
          />
          <Route
            exact
            path="/user/my-order-invoice/:orderId"
            element={<UserRoute component={MyInvoice} />}
          />
          <Route
            exact
            path="/user/order-query/:queryId"
            element={<UserRoute component={UserOrderQuery} />}
          />
          <Route
            exact
            path="/user/show-me-queries"
            element={<UserRoute component={OrderQueries} />}
          />
          <Route
            exact
            path="/user/please-verify-email"
            element={<VerifyEmail />}
          />
          <Route
            exact
            path="/user/peach-app-payment/:id"
            element={<AppPeachPayment />}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
