import { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';

const ReCheckInCleaner = ({
  note,
  orderId,
  duster,
  nextCheckIn,
  checkInDuster,
}) => {
  const [nextTime, setNextTime] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);
  useEffect(() => {
    if (nextCheckIn) {
      setNextTime(nextCheckIn.start);
    }
  }, [nextCheckIn]);

  const checkIn = () => {
    let a = nextTime;
    checkInDuster({ orderId, a });
  };

  return (
    <>
      <Button size="sm" onClick={handleShow}>
        Check in the cleaner <i className="fas fa-exclamation-triangle"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Checkout {duster.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cleaner will be checked in at:
          <Button variant="info float-right" size="sm" disabled>
            {moment(nextTime).format('ddd MMM D, h:mm a')}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            <i className="fas fa-times-circle"></i>
          </Button>
          <Button variant="primary" onClick={checkIn}>
            Checkin cleaner
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReCheckInCleaner;
