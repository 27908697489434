import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';

const Calender = ({
  tomorrow,
  regular,
  setPrettyDay,
  setArrivalDay,
  prettyDay,
  arrivalDay,
  setArriveNow,
  setDaysLeft,
}) => {
  const [show, setShow] = useState(false);
  const [arrivalTime, setArrivalTime] = useState(null);
  const handleClose = () => {
    setShow(false);
  };
  const selectDay = (e) => {
    if (regular) {
      let day = e.date;
      let p = e.date.toString().split(' ');
      let pretty = p[0] + ' ' + p[1] + ' ' + p[2];
      setPrettyDay(pretty);
      setArrivalDay(day);

      setShow(true);
    } else {
      let day = e.date;
      let p = e.date.toString().split(' ');
      let pretty = p[0] + ' ' + p[1] + ' ' + p[2];
      setPrettyDay(pretty);
      setArrivalDay(day);
      setShow(true);
    }
  };
  const setTheDate = () => {
    let x = new Date(arrivalDay).setHours(arrivalTime);
    let date = moment(x);
    setArriveNow(date._d);
    setShow(false);
  };

  const createSchedule = () => {
    let x = new Date(arrivalDay).setHours(arrivalTime);
    let date = moment(x);

    var selected = new Date(x);
    var lastday = new Date(selected.getFullYear(), selected.getMonth() + 1, 0);
    var mytime = lastday.setDate(lastday.getDate() + 1);

    var getDaysArray = function (s, e) {
      for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
        a.push(new Date(d));
      }
      setDaysLeft(a);
    };

    getDaysArray(x, mytime);
    setArriveNow(date._d);
    setShow(false);
  };
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
        initialView="dayGridMonth"
        dateClick={selectDay}
        validRange={{ start: tomorrow }}
        selectable={true}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            What time would you like the cleaner to arrive on {prettyDay}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Button
                variant={arrivalTime === 8 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(8)}
              >
                8:00am
              </Button>
            </Col>
            <Col>
              <Button
                variant={arrivalTime === 10 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(10)}
              >
                10:00am
              </Button>
            </Col>
            <Col>
              <Button
                variant={arrivalTime === 12 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(12)}
              >
                12:00pm
              </Button>
            </Col>
            <Col>
              <Button
                variant={arrivalTime === 14 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(14)}
              >
                14:00pm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {regular ? (
            <Button variant="primary" onClick={createSchedule}>
              {arrivalTime
                ? `${arrivalTime}:00 arrival time`
                : 'Select Arrival time'}
            </Button>
          ) : (
            <Button variant="primary" onClick={setTheDate}>
              {arrivalTime
                ? `${arrivalTime}:00 arrival time`
                : 'Select Arrival time'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calender;
