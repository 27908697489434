import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { teamLogout } from '../../actions/team/';
import { userLogout } from '../../actions/user/';
import { adminLogout } from '../../actions/admin/';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';

import './Header.css';
import DustingDownLogo from '../../images/HeaderLogo.png';

const Header = ({
  team: { isTeam, team },
  user: { isUser, user },
  admin: { isAdmin, admin },
  business: { isBusiness, business },
  teamLogout,
  userLogout,

  animateToDo,
  animateToContact,
  setShowAdmin,
}) => {
  const [activeUser, SetActiveUser] = useState(null);
  const [display, setDisplay] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    if (isAdmin) {
      setShowAdmin(true);
    } else {
      setShowAdmin(false);
    }
  }, [isAdmin, setShowAdmin]);

  useEffect(() => {
    const link = window.location.href;
    const compare = link.split('/');
    const string = `${compare[0]}//${compare[2]}/${compare[3]}/${compare[4]}`;
    const url = `${process.env.REACT_APP_BASE_URL}user/peach-app-payment`;

    if (string === url) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [setDisplay]);

  useEffect(() => {
    if (user) {
      if (user) SetActiveUser(user.name);
    }
  }, [user, SetActiveUser]);

  useEffect(() => {
    if (business) {
      SetActiveUser(business.name);
    }
  }, [business]);

  const howWeWork = () => {
    let myRef = 'howItWorks';
    animateToDo(myRef);
  };
  const contact = () => {
    let myRef = 'contactUs';
    animateToContact(myRef);
  };

  const logMeOut = () => {
    userLogout();
    navigate('/');
  };

  return (
    <Navbar className="container mb-2" expand="lg">
      {isAdmin ? (
        ''
      ) : (
        <Navbar.Brand>
          <Link to="/">
            <img src={DustingDownLogo} height="50" alt="Dusting Down" />
          </Link>
        </Navbar.Brand>
      )}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end text-light">
        {isUser || isBusiness ? (
          <Nav>
            <Navbar.Text>
              <Link
                to="/user/my-profile"
                className="btn btn-md btn-outline-info text-info mr-2"
              >
                Hello: {activeUser}
              </Link>
            </Navbar.Text>

            <Navbar.Text>
              <Link
                to={isUser ? '/user/booking' : '/business/business-booking'}
                className="btn btn-md btn-outline-info text-info mr-2"
              >
                Book A Cleaner
              </Link>
            </Navbar.Text>
            <Nav.Link>
              <Button variant="outline-danger" size="md" onClick={logMeOut}>
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </Button>
            </Nav.Link>
          </Nav>
        ) : isAdmin === false ? (
          <Nav>
            <Dropdown>
              <Dropdown.Toggle id="menu-button">Book A Service</Dropdown.Toggle>

              <Dropdown.Menu id="menu-bg">
                <Dropdown.Item as={Link} to="/user/booking">
                  <i className="mr-2 fas fa-home"></i> For Home
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Navbar.Text>
              <Link
                to="/"
                className="btn btn-md mr-2"
                id="regular-btn"
                onClick={howWeWork}
              >
                <u>How we do it?</u>
              </Link>
            </Navbar.Text>
            <Dropdown>
              <Dropdown.Toggle className="mt-2" id="regular-btn">
                Our Services
              </Dropdown.Toggle>

              <Dropdown.Menu id="menu-bg">
                <Dropdown.Item as={Link} to="/user/home-cleaning">
                  Home Cleaning
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/end-of-tenancy">
                  End Of Tenancy Cleaning
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/office-cleaning">
                  Office Cleaning
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/carpert-cleaning">
                  Carpet Cleaning
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/air-bnb-cleaning">
                  Airbnb Cleaning
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    window.open('https://www.fibreclean.co.za/', '_blank')
                  }
                >
                  Deep Cleaning
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Navbar.Text>
              <Link
                to="/"
                className="btn btn-md ml-2 mr-2"
                id="regular-btn"
                onClick={contact}
              >
                <u>Contact</u>
              </Link>
            </Navbar.Text>

            <Navbar.Text>
              <Link
                to="/user/signin"
                className="btn btn-md text-white ml-2"
                id="menu-signin"
              >
                Sign In
              </Link>
            </Navbar.Text>
          </Nav>
        ) : (
          ''
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  team: state.team,
  user: state.user,
  admin: state.admin,
  business: state.business,
});
export default connect(mapStateToProps, {
  teamLogout,
  userLogout,
  adminLogout,
})(Header);
