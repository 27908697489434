import axios from 'axios';
import setUserToken from '../../utils/setUserToken';
import {
  ADMIN_LOADED,
  ADMIN_LOGIN,
  ADMIN_FAIL,
  ADMIN_LOGOUT,
  CLEAR_PROFILES,
} from '../types';
import { setNote } from '../note';

//Get current admin user//
export const adminLoaded = () => async (dispatch) => {
  if (localStorage.token) {
    setUserToken(localStorage.token);
  }
  try {
    const res = await axios.get('/api/admin');
    dispatch({ type: ADMIN_LOADED, payload: res.data });
  } catch (error) {
    dispatch({ type: ADMIN_FAIL });
  }
};

//Login Admin member//
export const adminLogin = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/admin/signin', body, config);
    dispatch({ type: ADMIN_LOGIN, payload: res.data });
    dispatch(adminLoaded());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: ADMIN_FAIL, payload: errors });
  }
};

//Logout Admin//
export const adminLogout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILES });
  dispatch({ type: ADMIN_LOGOUT });
};

//Create a admin user
export const createAdmin = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/admin/signup', body, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};
