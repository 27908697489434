import {
  FETCH_AFFILIATE,
  FETCH_AFFILIATES,
  SET_AFFILIATE_LOADING,
} from '../types';
import axios from 'axios';
import { setNote } from '../note';

//Fetch Affiliates//
export const fetchAffiliates = () => async (dispatch) => {
  dispatch({ type: SET_AFFILIATE_LOADING, payload: true });
  try {
    const res = await axios.get('/api/admin/affiliate/');
    dispatch({ type: FETCH_AFFILIATES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Create Affilite//
export const createAffiliate =
  ({ name, email, password, coupon, address, coordinates }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      name,
      email,
      password,
      coupon,
      address,
      coordinates,
    });
    try {
      const res = await axios.post(
        '/api/admin/affiliate/create-affiliate',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(fetchAffiliates());
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Fetch Affiliate//
export const fetchAffiliate = (affiliateId) => async (dispatch) => {
  dispatch({ type: SET_AFFILIATE_LOADING, payload: true });
  try {
    const res = await axios.get(`/api/admin/fetch-affiliate/${affiliateId}`);

    dispatch({ type: FETCH_AFFILIATE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
