import { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OrderItem = ({ o }) => {
  const [paid, setPaid] = useState(false);
  const [assigned, setAssigned] = useState('Needs Duster');
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    if (o.code !== undefined) {
      setPaid(true);
    } else {
      setPaid(false);
    }

    if (o.team === null || o.team === undefined) {
      setAssigned('Needs Duster');
    } else {
      setAssigned(o.team.name);
    }
    if (o.checkOut === undefined) {
      setComplete(false);
    } else {
      setComplete(true);
    }
  }, [o, setPaid, setAssigned, setComplete]);
  let address = o.address.split(',').shift();
  let orderTime = moment(o.orderTime).format('ddd MMM D, h:mm a');

  return (
    <tr>
      <td>{orderTime}</td>
      <td>{address}</td>
      <td>{paid ? 'Paid' : 'Not paid'}</td>
      <td>{assigned === 'Needs Duster' ? 'Needs Duster' : `${assigned}`}</td>
      <td>{complete ? 'Complete' : 'Incomplete'}</td>
      <td>
        <Button
          as={Link}
          to={`/admin/order-management/once-off/order/${o._id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

export default OrderItem;
