import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  Spinner,
  Button,
  ListGroup,
} from 'react-bootstrap';
import { profileOrders, profileReOrders } from '../../../actions/user/order';
import OrderItem from './OrderItem';
import ReOrderItem from './ReOrderItem';
import Address from './Address';
import Cellphone from './Cellphone';
import InviteAFriend from './InviteAFriend';
import Bottom from '../../Layout/Bottom';
import './MyProfile.css';

const MyProfile = ({
  user,
  orders: { myOrders, myReOrders },
  profileOrders,
  profileReOrders,
}) => {
  const [profile, setProfile] = useState(null);
  const [address, setAddress] = useState(null);
  const [cellphone, setCellphone] = useState(null);
  const [Orders, setMyOrders] = useState([]);
  const [ReOrders, setMyReOrders] = useState([]);
  useEffect(() => {
    profileOrders();
    profileReOrders();
  }, [profileOrders, profileReOrders]);
  useEffect(() => {
    if (user && myOrders && myReOrders) {
      if (user.local) setProfile(user.local.name);
      if (user.facebook) setProfile(user.facebook.name);
      if (user.google) setProfile(user.google.name);
      setAddress(user.address);
      setCellphone(user.cellphone);
      setMyOrders(myOrders);
      setMyReOrders(myReOrders);
    }
  }, [user, myOrders, myReOrders, setProfile]);

  if (user === null || myOrders === null || myReOrders === null) {
    return <Spinner animation="grow" variant="dark" />;
  }
  return (
    <div>
      <Container>
        <Alert id="my-profile" variant="info mb-3 text-center">
          My Profile
          <Button
            as={Link}
            variant="success float-right ml-2"
            size="sm"
            to="/user/carpert-cleans/"
          >
            Carpet clean
          </Button>
          <InviteAFriend />
          <Button
            as={Link}
            to="/user/show-me-queries"
            variant="outline-primary float-right ml-2"
            size="sm"
          >
            Queries
          </Button>
          <Button
            as={Link}
            to="/user/my-order-history"
            variant="outline-info float-right"
            size="sm"
          >
            History
          </Button>
        </Alert>

        <Row>
          <Col md={4}>
            <Alert variant="dark ">My Info</Alert>
            <Card
              style={{ width: '20rem' }}
              id="my-profile"
              className="text-center"
            >
              <Card.Header>Profile Owner: {profile}</Card.Header>
              <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">
                  Welcome to your profile
                </Card.Subtitle>
                <Card.Text>
                  Here you can edit your profile information, add addresses and
                  more...
                </Card.Text>
                <ListGroup>
                  <ListGroup.Item>
                    <span className="float-left">
                      <i className="fas fa-mobile"></i>: {cellphone}
                    </span>
                    <Cellphone cellphone={cellphone} />
                  </ListGroup.Item>
                </ListGroup>

                <Alert variant="success">
                  Your default address is: {address}
                </Alert>
              </Card.Body>
            </Card>
            <Address user={user} />
          </Col>
          <Col md={4}>
            <Alert variant="primary">My Recent Orders</Alert>
            <div id="my-profile">
              {Orders.length === 0
                ? 'No Orders...'
                : Orders.map((order) => (
                    <OrderItem key={order._id} order={order} />
                  ))}
            </div>
          </Col>
          <Col md={4}>
            <Alert variant="primary">My Repeat Orders</Alert>
            <div id="my-profile">
              {ReOrders.length === 0
                ? 'No Orders...'
                : ReOrders.map((order) => (
                    <ReOrderItem key={order._id} order={order} />
                  ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  orders: state.orders,
});

export default connect(mapStateToProps, {
  profileOrders,
  profileReOrders,
})(MyProfile);
