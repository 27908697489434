import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const ListOfUsers = ({ user }) => {
  const adr = user.address.split(',').shift();
  return (
    <tr>
      <td>{user.name}</td>
      <td>{user.surname}</td>
      <td>{user.email}</td>
      <td>{user.cellphone}</td>
      <td>{moment(user.lastlogin).format('MMM Do YY')}</td>
      <td>{adr}</td>
      <td>
        <Button
          as={Link}
          to={`/admin/admin_user/${user._id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

export default ListOfUsers;
