import { connect } from 'react-redux';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

const SideBar = ({ admin }) => {
  return (
    <div
      style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: 'inherit' }}
          >
            Dusting Down Admin
          </a>
          <p className="small">Hello {admin.name}</p>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink to="/admin/dashboard">
              <CDBSidebarMenuItem icon="chart-line">
                Dashboard
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/carpert-cleaning-dashboard">
              <CDBSidebarMenuItem icon="bars">
                Carpet Cleaning
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/one-time-booking-dash">
              <CDBSidebarMenuItem icon="bars">
                One time bookings
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/repeat-booking-dash">
              <CDBSidebarMenuItem icon="infinity">
                Repeat booking
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/admin-dusters">
              <CDBSidebarMenuItem icon="people-group">
                Dusters
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/admin_users/">
              <CDBSidebarMenuItem icon="user">Customers</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/all-queries">
              <CDBSidebarMenuItem icon="clipboard-question">
                Queries
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/analytics">
              <CDBSidebarMenuItem icon="sliders">Reports</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/main">
              <CDBSidebarMenuItem icon="list-check">
                Management
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/affiliates">
              <CDBSidebarMenuItem icon="arrows">Affiliates</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/admin/it-dash">
              <CDBSidebarMenuItem icon="gears">
                IT Department
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.admin.isAdmin,
});

export default connect(mapStateToProps)(SideBar);
