import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { deleteLevelOne } from '../../../../actions/admin/team_members';
import { Button, Modal } from 'react-bootstrap';

const FlushStageOne = ({ deleteLevelOne, note }) => {
  const [show, setShow] = useState(false);
  let deleteBtn = useRef();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (note.length > 0) {
      handleClose();
    }
  }, [note]);

  const flush = () => {
    deleteLevelOne();

    if (deleteBtn.current) {
      deleteBtn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <div>
      <Button variant="warning mt-2 text-white" size="sm" onClick={handleShow}>
        Flush Stage 1
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Flush Stage One Applicants</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning text-white" onClick={flush} ref={deleteBtn}>
            Flush Stage One
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, { deleteLevelOne })(FlushStageOne);
