import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTheCarpertCleans } from '../../../actions/admin/carpert_cleaning';
import { Container, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ACarpertClean from './ACarpertClean';

const CarpertCleaningDash = ({
  admin_carpert_cleans: { loading, carpert_cleans },
  getTheCarpertCleans,
}) => {
  useEffect(() => {
    getTheCarpertCleans();
  }, [getTheCarpertCleans]);

  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  if (loading)
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4 style={{ color: '#06a5dd' }}>Carpet cleaning</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Booking Date</th>
            <th>Address</th>
            <th>Payment Status</th>
            <th>Booking Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {carpert_cleans.orders.length === 0 ? (
            <p>No carpet cleans</p>
          ) : (
            carpert_cleans.orders.map((carpert_clean) => (
              <ACarpertClean
                key={carpert_clean._id}
                carpert_clean={carpert_clean}
              />
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_carpert_cleans: state.admin_carpert_cleans,
});

export default connect(mapStateToProps, { getTheCarpertCleans })(
  CarpertCleaningDash
);
