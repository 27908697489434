import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import '../../User/Booking/Booking.css';

const OrderSchedule = ({ orderSchedule }) => {
  return (
    <div>
      {orderSchedule.map((sched) => (
        <Button variant="outline-info mt-2" id="appointment">
          {moment(sched.start.toString()).format('ddd MMM D, h:mm a')}
        </Button>
      ))}
    </div>
  );
};

export default OrderSchedule;
