import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ReValidate = ({
  validationDistance,
  acceptReCheckingRequest,
  orderId,
  note,
}) => {
  const [show, setShow] = useState(false);
  const [nte, setMsg] = useState(note);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
      handleClose();
    }
  }, [setMsg, note, nte]);

  const reject = () => {
    console.log('peanut');
  };
  const CheckinDuster = () => {
    acceptReCheckingRequest(orderId);

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <div className="text-center mb-3">
      <Button variant="primary" onClick={handleShow}>
        Manual validation request
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Validate the order maually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Dust Buster would like to validate the order manually
          <hr />
          Duster is {validationDistance}/km away from order location
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={reject}>
            Reject
          </Button>
          <Button variant="success" onClick={CheckinDuster} ref={btn}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReValidate;
