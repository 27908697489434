import React from 'react';
import Bottom from '../Layout/Bottom';

const AirBnB = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            Effortless Cleaning Services for Your Airbnb with Dusting Down
          </h3>
        </div>
        <div>
          <p>
            <b>1. Specialized Airbnb Cleaning:</b> Dusting Down provides
            specialized cleaning services for your Airbnb rental. We understand
            the unique needs of short-term rentals and provide thorough and
            efficient cleaning to ensure that your guests have a comfortable and
            pleasant stay.
          </p>
          <p>
            <b>2. Experienced and Reliable Cleaning Team:</b> Our team of
            cleaning professionals has extensive experience in providing
            high-quality cleaning services for short-term rentals. They are
            trained to deliver exceptional results and ensure that your Airbnb
            is spotless and ready for your next guests.
          </p>
          <p>
            <b>3. Customizable Cleaning Checklist:</b> Dusting Down's cleaning
            services are tailored to meet your specific needs. Our customizable
            cleaning checklist allows you to specify the areas and tasks that
            need special attention, ensuring that your Airbnb is cleaned to your
            satisfaction.
          </p>
          <p>
            <b>4. Professional Equipment and Cleaning Products:</b> Dusting Down
            utilizes professional-grade equipment and high-quality cleaning
            products to ensure optimal results. We are equipped to handle even
            the toughest stains and grime, leaving your Airbnb in pristine
            condition.
          </p>
          <p>
            <b>5. Flexible Scheduling:</b> Our website and app enable you to
            schedule your cleaning service at a time that is convenient for you.
            We understand the importance of flexibility when it comes to
            short-term rentals, and we are committed to providing a hassle-free
            cleaning experience.
          </p>
          <p>
            <b>6. Reliable and Efficient Cleaning:</b> With Dusting Down's
            cleaning services, you can rest assured that your Airbnb will be
            cleaned to the highest standards. Our team of professionals follows
            a thorough and efficient cleaning process, ensuring that your rental
            is ready for your next guests.
          </p>
          <p>
            <b>7. Transparent Communication and Documentation:</b> Dusting Down
            believes in transparent communication and provides detailed
            documentation of the cleaning services performed. This includes
            itemized checklists and receipts, giving you peace of mind and a
            clear record of the cleaning process.
          </p>
        </div>
        <hr />
        <div className="container">
          <p>
            <b>Discover the Convenience:</b> With Dusting Down's specialized
            cleaning services for Airbnb rentals, you can focus on providing a
            comfortable and memorable experience for your guests. Our
            experienced team, customizable checklist, and user-friendly platform
            ensure a hassle-free experience tailored to meet your needs. Choose
            Dusting Down for an effortless and stress-free Airbnb cleaning
            service.
          </p>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default AirBnB;
