import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import {
  Spinner,
  Alert,
  Col,
  Container,
  Row,
  ListGroup,
  Card,
  Button,
  Modal,
} from 'react-bootstrap';
import { recurringOrder, RepeatAccOrder } from '../../../../actions/user/order';
import {
  initalDate,
  setBookingAddress,
  addTip,
  cancelTip,
} from '../../../../actions/user/bookings';
import { ozowPay, peachPayment } from '../../../../actions/user/payment';
import Recap from './Recap';
import Coupon from './Coupon';
import AddressButton from './AddressButton';
import ExtraItem from './ExtraItem';
import moment from 'moment';
import AddCustomTip from './AddCustomTip';

const RepeatCheckOut = ({
  bookings: {
    bedbathTotal,
    extraTotal,
    regInvoice,
    hours,
    changeHours,
    weekdays,
    bedroom,
    bathroom,
    schedule,
    firstDate,
    frequency,
    nityGrity,
    address,
    coordinates,
    complex,
    whyLess,
    iconExtras,
    tip,
    dusterId,
  },
  order: { reOrder, paymentUrl, peachLoad },
  recurringOrder,
  RepeatAccOrder,
  user,
  initalDate,
  setBookingAddress,
  ozowPay,
  peachPayment,
  coupons: { optionalTotal },
  addTip,
  cancelTip,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [show, setShow] = useState(false);
  const [invoiceHours, setInvoiceHours] = useState(null);
  const [mrx, SetMrx] = useState([]);
  const [adr, SetAddress] = useState(address);
  const [cmplex, SetComplex] = useState(complex);
  const [coupons, SetCoupons] = useState(null);
  const [monthly, SetMonthly] = useState(null);
  const [monthlyRedirect, SetMonthlyRedirect] = useState(null);
  const [coupon, SetCoupon] = useState(null);
  const [coo, SetCoordinates] = useState(coordinates);
  const [couponUsed, setCouponUsed] = useState(null);
  const [inv, setInv] = useState(0);
  const [cleans, SetCleans] = useState(null);
  const [tenPerc, setTenPerc] = useState(null);
  const [twoPerc, setTwoPerc] = useState(null);
  const [threePerc, setThreePerc] = useState(null);
  const [team, setTeam] = useState(null);
  const [myTip, setMyTip] = useState(0);
  const regular = true;

  useEffect(() => {
    if (dusterId) {
      let team = dusterId.duster.dusterId;
      setTeam(team);
    } else {
      setTeam(null);
    }
  }, [dusterId]);

  useEffect(() => {
    if (tip != null) {
      let amt = tip.tip.toFixed(2);
      setMyTip(amt);
    } else {
      setMyTip(0);
    }
  }, [tip]);

  useEffect(() => {
    if (inv && tip === null) {
      let ten = inv * 0.1;
      let two = inv * 0.2;
      let three = inv * 0.3;
      setTenPerc(ten.toFixed(2));
      setTwoPerc(two.toFixed(2));
      setThreePerc(three.toFixed(2));
    }
  }, [inv, tip]);

  let ozowBtn = useRef();
  let peachBtn = useRef();
  let freeBtn = useRef();
  let monthBtn = useRef();

  useEffect(() => {
    if (couponUsed) {
      setInv(optionalTotal);
    } else {
      setInv(regInvoice);
    }
  }, [couponUsed, optionalTotal, regInvoice]);

  useEffect(() => {
    if (changeHours) {
      setInvoiceHours(changeHours);
    } else {
      setInvoiceHours(hours);
    }
  }, [changeHours, hours]);

  useEffect(() => {
    if (adr && coo) {
      let address = adr;
      let coordinates = coo;
      let complex = cmplex;
      setBookingAddress({ address, coordinates, complex });
    }
  }, [adr, coo, setBookingAddress, cmplex]);

  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  });

  useEffect(() => {
    if (peachLoad) {
      const script = document.createElement('script');
      script.src = `https://${process.env.REACT_APP_PEACH_BASE_URL}/v1/paymentWidgets.js?checkoutId=${peachLoad.id}`;
      document.body.append(script);

      window.scrollTo(0, 0);
      setShow(true);
    }
  }, [peachLoad]);

  useEffect(() => {
    if (reOrder) {
      let orderId = reOrder._id;
      let payType = reOrder.paymentMethod;
      let invoice = reOrder.invoice;
      let a = reOrder.monthEnd;
      if (a === true) {
        return SetMonthlyRedirect(true);
      }

      if (payType === 'ozow') {
        let _id = orderId;
        ozowPay({ _id, invoice });
      }

      if (payType === 'peach') {
        let amount = parseFloat(invoice).toFixed(2);
        peachPayment({ amount, orderId });
      }
    }
  }, [reOrder, ozowPay, peachPayment]);

  useEffect(() => {
    initalDate(schedule);
    SetCleans(schedule.length);
  }, [initalDate, schedule]);

  useEffect(() => {
    if (user) {
      SetCoupons(user.coupons);

      SetMonthly(user.monthlyAcc);
    }
  }, [user]);

  useEffect(() => {
    let arr = iconExtras.filter((ex) => ex.chosen !== false);
    SetMrx(arr);
  }, [iconExtras, SetMrx]);

  const ChangeAddress = (a) => {
    SetAddress(a.text);
    SetCoordinates(a.coordinates);
    SetComplex(a.complex);
  };

  const defaultAddress = () => {
    SetAddress(user.address);
    SetCoordinates(user.coordinates);
    SetComplex(user.complex);
  };

  const invoiceTime = moment(firstDate).format('ddd MMM D, h:mm a');

  const monthlyCheckOut = () => {
    let monthEnd = true;
    let invoice = inv;
    let address = adr;
    let coordinates = coo;
    let complex = cmplex;
    let time = firstDate;
    let tip = myTip;

    RepeatAccOrder({
      time,
      cleans,
      address,
      coordinates,
      schedule,
      frequency,
      weekdays,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      monthEnd,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
    });

    if (monthBtn.current) {
      monthBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const createOzow = () => {
    let paymentMethod = 'ozow';
    let invoice = inv;
    let address = adr;
    let coordinates = coo;
    let complex = cmplex;
    let time = firstDate;
    let tip = myTip;
    recurringOrder({
      time,
      cleans,
      address,
      coordinates,
      schedule,
      frequency,
      weekdays,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      paymentMethod,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
    });

    if (ozowBtn.current) {
      ozowBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const createPeach = () => {
    let paymentMethod = 'peach';
    let invoice = inv;
    let address = adr;
    let coordinates = coo;
    let complex = cmplex;
    let time = firstDate;
    let tip = myTip;
    recurringOrder({
      time,
      cleans,
      address,
      coordinates,
      schedule,
      frequency,
      weekdays,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      paymentMethod,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
    });

    if (peachBtn.current) {
      peachBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const handleClose = () => {
    setShow(false);
    peachBtn.current.removeAttribute('disabled');
  };

  const freeOrder = () => {
    let invoice = inv;
    let address = adr;
    let coordinates = coo;
    let complex = cmplex;
    let time = firstDate;
    let tip = myTip;
    recurringOrder({
      time,
      cleans,
      address,
      coordinates,
      schedule,
      frequency,
      weekdays,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      complex,
      nityGrity,
      whyLess,
      changeHours,
      couponUsed,
      team,
      tip,
    });

    if (freeBtn.current) {
      freeBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const cancelCoupon = () => {
    setCouponUsed(null);
    SetCoupon(null);
  };

  if (monthlyRedirect === true) {
    return <Navigate to="/payments/monthly-order" />;
  }

  if (address === null) {
    return <Spinner animation="grow" variant="dark" />;
  }

  let invAddress = address.split(',');

  const addTenTip = () => {
    let tip = {
      tip: parseFloat(tenPerc),
      perc: '10%',
    };
    let invoice = parseFloat(inv);
    addTip({ tip, invoice, regular });
  };

  const addTwoTip = () => {
    let tip = {
      tip: parseFloat(twoPerc),
      perc: '20%',
    };
    let invoice = parseFloat(inv);
    addTip({ tip, invoice, regular });
  };

  const addThreeTip = () => {
    let tip = {
      tip: parseFloat(threePerc),
      perc: '30%',
    };
    let invoice = parseFloat(inv);
    addTip({ tip, invoice, regular });
  };

  const myOwnTip = (amount) => {
    let tip = {
      tip: parseFloat(amount),
      perc: 'custom',
    };
    let invoice = parseFloat(inv);
    addTip({ tip, invoice, regular });
  };

  const removeTip = () => {
    let amt = tip.tip;
    let invoice = inv;
    cancelTip({ amt, invoice, regular });
  };
  return (
    <Container>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        autoFocus={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form
            action={`${process.env.REACT_APP_BASE_URL}payments/card-response/`}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </Modal.Body>
      </Modal>
      <Row>
        <Col md={6}>
          <Alert variant="info text-center">
            <Row>
              <Col>
                <h3 className="text-info text-center">
                  Review booking and make payment
                </h3>
              </Col>
              <Col>
                <AddressButton
                  ChangeAddress={ChangeAddress}
                  defaultAddress={defaultAddress}
                  user={user}
                />
              </Col>
            </Row>
          </Alert>

          <ListGroup variant="flush">
            <ListGroup.Item variant="secondary">
              <p className="text-center">Time & Location </p>
              <p className="text-center">
                First clean will be <b>{invoiceTime}</b>
              </p>
              <p className="text-center">
                {invAddress[0]}, {invAddress[1]}, {invAddress[2]}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left">
                {bedroom} Bedroom/s and {bathroom} Bathroom/s
              </p>
              <p className="float-right">R{bedbathTotal} </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left"> Included Cost</p>
              <p className="float-right">R212.00</p>
            </ListGroup.Item>
            <ListGroup.Item align="center">
              <div className="float-left">Your Extras;</div>
              {mrx.length === 0 ? (
                <span>No Extras</span>
              ) : (
                mrx.map((x) => {
                  return (
                    <div key={x.id} style={{ width: '20rem' }}>
                      <ExtraItem x={x} />
                    </div>
                  );
                })
              )}
              <p className="float-right">R{extraTotal} </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left"> Service Fee </p>
              <p className="float-right">R35.00</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="float-left">Total Time: </p>
              <p className="float-right">{invoiceHours} hours</p>
            </ListGroup.Item>
            <ListGroup.Item className="text-center">
              <b>There will {cleans} cleans untill the end of the month.</b>
            </ListGroup.Item>
          </ListGroup>
          <Row className="mt-3">
            <Col>
              <Link to="/user/booking">
                <Alert variant="secondary text-center">
                  <i className="mr-3 fas fa-step-backward"></i> Edit Order!
                </Alert>
              </Link>
            </Col>
            <Col>
              <Alert variant="primary text-center" size="lg" block>
                R{inv} this month <i className="far fa-credit-card"></i>
              </Alert>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <h5 className="text-center mt-4">Appointment Summary:</h5>
          <Recap schedule={schedule} />
          <Card className="text-center mb-4 mt-4">
            <Alert variant="success">
              <h5>
                Pay Here: <i className="ml-3 fas fa-cash-register"></i>
              </h5>
            </Alert>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Title>
                    <span style={{ fontSize: '4em' }}>
                      <i className="fas fa-credit-card text-success"></i>
                    </span>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-success">
                    Thank you for considering our services
                  </Card.Subtitle>

                  <Alert variant="warning">
                    You checkout price is calculated based on the amount of
                    cleans left in the month.
                  </Alert>

                  {coupon ? (
                    <Button variant="warning" onClick={cancelCoupon} block>
                      Cancel Coupon
                    </Button>
                  ) : couponUsed ? (
                    <Button variant="warning" onClick={cancelCoupon} block>
                      Cancel Coupon
                    </Button>
                  ) : (
                    <Coupon
                      coupons={coupons}
                      SetCoupon={SetCoupon}
                      setCouponUsed={setCouponUsed}
                      invoiceHours={invoiceHours}
                      orderType={false}
                    />
                  )}
                </Col>
                <Col>
                  <p>Would you like to add a tip?</p>

                  {tip !== null ? (
                    <Row
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      <Col>
                        <p
                          style={{
                            marginTop: '10px',
                            color: 'green',
                            fontWeight: 'bold',
                          }}
                        >
                          {tip.perc} tip applied
                        </p>
                      </Col>
                      <Col>
                        <Button variant="outline-danger" onClick={removeTip}>
                          Cancel Tip
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      <Col>
                        <Button
                          variant="outline-info"
                          onClick={() => addTenTip(tenPerc, '10%')}
                          block
                        >
                          10%
                        </Button>
                        <p> R{tenPerc}</p>
                      </Col>
                      <Col>
                        <Button
                          variant="outline-info"
                          onClick={() => addTwoTip(twoPerc, '20%')}
                          block
                        >
                          20%
                        </Button>
                        <p>R{twoPerc}</p>
                      </Col>
                      <Col>
                        <Button
                          variant="outline-info"
                          onClick={() => addThreeTip(threePerc, '30%')}
                          block
                        >
                          30%
                        </Button>
                        <p>R{threePerc}</p>
                      </Col>
                      <Col style={{ marginRight: '25px' }}>
                        <AddCustomTip myOwnTip={myOwnTip} />
                        <p>-</p>
                      </Col>
                    </Row>
                  )}
                  <hr />
                  <Card.Text>
                    <b>Please select your prefered payment method</b>
                  </Card.Text>
                  <b>***Please Note***</b>
                  <p>
                    You will be re-direcrected when you select a payment method.
                  </p>
                  <hr />
                  {inv === 0 ? (
                    <Button
                      variant="success"
                      onClick={freeOrder}
                      ref={freeBtn}
                      block
                    >
                      Confirm Free Order
                    </Button>
                  ) : monthly === true ? (
                    <Button
                      variant="outline-info"
                      onClick={monthlyCheckOut}
                      ref={monthBtn}
                      block
                    >
                      Confirm Monthly Acc Order
                    </Button>
                  ) : (
                    <>
                      {/* <Button
                        variant="success"
                        onClick={createOzow}
                        ref={ozowBtn}
                        block
                      >
                        Ozow Instant EFT R{inv}
                      </Button> */}
                      <Button
                        variant="success mt-2"
                        onClick={createPeach}
                        ref={peachBtn}
                        block
                      >
                        Secure Card Payment R{inv}
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  bookings: state.bookings,
  order: state.orders,
  coupons: state.coupons,
});

export default connect(mapStateToProps, {
  recurringOrder,
  initalDate,
  RepeatAccOrder,
  setBookingAddress,
  ozowPay,
  peachPayment,
  addTip,
  cancelTip,
})(RepeatCheckOut);
