import { DUSTER_REVIEW } from '../../actions/types';
const initState = {
  review: null,
};

function reviewReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case DUSTER_REVIEW:
      return {
        ...state,
        review: payload,
      };
    default:
      return state;
  }
}

export default reviewReducer;
