import { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';

const Calender = ({
  setDate,
  prettyDay,
  setPrettyDay,
  arrivalTime,
  setArrivalTime,
  orderHours,
  regular,
  tomorrow,
  weekdays,
  selectedDay,
  setSelectedDay,
  setNote,
  setDaysLeft,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const confirmFirstDate = () => {
    let x = new Date(selectedDay).setHours(arrivalTime);
    let date = moment(x);

    var today = new Date(selectedDay);
    var lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    var mytime = lastday.setDate(lastday.getDate() + 1);

    var getDaysArray = function (s, e) {
      for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
        a.push(new Date(d));
      }
      setDaysLeft(a);
    };

    getDaysArray(x, mytime);
    setDate(date._d);
    setShow(false);
  };
  const confirmTime = () => {
    let x = new Date(selectedDay).setHours(arrivalTime);
    let date = moment(x);
    setDate(date._d);
    setShow(false);
  };

  const selectDay = (e) => {
    if (regular) {
      let valid = weekdays.filter((w) => {
        let sd = e.date.toString().split(' ').shift();
        const splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];
        let day = splitAt(3)(w);
        return sd === day[0];
      });

      if (valid.length === 0) {
        return setNote(
          'Please choose a day that matches your schedule',
          'danger'
        );
      } else {
        let day = e.date;
        let p = e.date.toString().split(' ');
        let pretty = p[0] + ' ' + p[1] + ' ' + p[2];
        setPrettyDay(pretty);
        setSelectedDay(day);
        handleShow();
      }
    } else {
      let day = e.date;
      let p = e.date.toString().split(' ');
      let pretty = p[0] + ' ' + p[1] + ' ' + p[2];
      setPrettyDay(pretty);
      setSelectedDay(day);
      handleShow();
    }
  };
  return (
    <div className="mt-3">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
        initialView="dayGridMonth"
        dateClick={selectDay}
        validRange={{ start: tomorrow }}
        selectable={true}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            What time would you like the cleaner to arrive on {prettyDay}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 8 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(8)}
                block
              >
                8:00am
              </Button>
            </Col>
            <Col md={3} xs={12}>
              {orderHours >= 8 ? (
                <Button variant="light" block>
                  10:00am
                </Button>
              ) : (
                <Button
                  variant={arrivalTime === 10 ? 'dark' : 'outline-dark'}
                  onClick={() => setArrivalTime(10)}
                  block
                >
                  10:00am
                </Button>
              )}
            </Col>
            <Col md={3} xs={12}>
              {orderHours >= 6 ? (
                <Button variant="light" disabled>
                  12:00pm
                </Button>
              ) : (
                <Button
                  variant={arrivalTime === 12 ? 'dark' : 'outline-dark'}
                  onClick={() => setArrivalTime(12)}
                  block
                >
                  12:00pm
                </Button>
              )}
            </Col>
            <Col md={3} xs={12}>
              {orderHours >= 5 ? (
                <Button variant="light" disabled>
                  14:00pm
                </Button>
              ) : (
                <Button
                  variant={arrivalTime === 14 ? 'dark' : 'outline-dark'}
                  onClick={() => setArrivalTime(14)}
                  block
                >
                  14:00pm
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          {arrivalTime ? (
            regular ? (
              <Button variant="outline-success" onClick={confirmFirstDate}>
                Confirm First Date
              </Button>
            ) : (
              <Button variant="outline-success" onClick={confirmTime}>
                Confirm Arrival Time
              </Button>
            )
          ) : (
            <Button variant="secondary" disabled>
              Select Arrival Time
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calender;
