import { useState } from 'react';
import { Row, Col, Alert, Button, Image } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
//PDF imports//

import { Document, Page } from 'react-pdf';

const UpdateDusterDocs = ({
  idTrash,
  poaTrash,
  blTrash,
  ppTrash,
  ecTrash,
  wpTrash,
  idBtn,
  poaBtn,
  blBtn,
  ppBtn,
  ecBtn,
  wpBtn,
  id_uploaded,
  uploadID,
  IDbtn,
  uploadPOA,
  poa_uploaded,
  POAbtn,
  uploadBL,
  BLbtn,
  bl_uploaded,
  uploadPP,
  PPbtn,
  pp_uploaded,
  ec_uploaded,
  uploadEC,
  ECbtn,
  wp_uploaded,
  uploadWP,
  WPbtn,
  idDoc,
  poa,
  bankLetter,
  pic,
  ec,
  nationality,
  workPermit,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          {idDoc ? (
            <>
              <Alert variant="secondary">
                ID Documents:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={idTrash}
                  ref={idBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {idDoc.split('.').pop() === 'pdf' ? (
                <div>
                  <Document file={idDoc} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                </div>
              ) : (
                <Image src={idDoc} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {id_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  1. ID Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadID}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={IDbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        1. ID Document
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
        <Col xs={12} md={6}>
          {poa ? (
            <>
              <Alert variant="secondary">
                Proof Of Address:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={poaTrash}
                  ref={poaBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {poa.split('.').pop() === 'pdf' ? (
                <div>
                  <Document file={poa} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                </div>
              ) : (
                <Image src={poa} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {poa_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  2. Proof Of Address Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadPOA}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={POAbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        2. Proof Of Address
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          {bankLetter ? (
            <>
              <Alert variant="secondary mt-3">
                Bank Letter:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={blTrash}
                  ref={blBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {bankLetter.split('.').pop() === 'pdf' ? (
                <div>
                  <Document
                    file={bankLetter}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                </div>
              ) : (
                <Image src={bankLetter} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {bl_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  3. Bank Letter Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadBL}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={BLbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        3. Bank Letter
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
        <Col xs={12} md={6}>
          {pic ? (
            <>
              <Alert variant="secondary mt-3">
                Profile Picture:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={ppTrash}
                  ref={ppBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {pic.split('.').pop() === 'pdf' ? (
                <div>
                  <Document file={pic} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                </div>
              ) : (
                <Image src={pic} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {pp_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  4. Profile Picture Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadPP}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={PPbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        4. Profile Picture
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
        <Col>
          {ec ? (
            <>
              <Alert variant="secondary mt-3">
                Employment Contract
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={ecTrash}
                  ref={ecBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {ec.split('.').pop() === 'pdf' ? (
                <div>
                  <Document file={ec} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                </div>
              ) : (
                <Image src={ec} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {ec_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  5. Employment Contract Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadEC}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={ECbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        5. Employment Contract
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>

        <Col md={6} xs={12}>
          {nationality === 'Foreign Citizen' && (
            <>
              {workPermit ? (
                <>
                  <Alert variant="secondary mt-3">
                    Work Permit
                    <Button
                      variant="danger"
                      className="float-right"
                      size="sm"
                      onClick={wpTrash}
                      ref={wpBtn}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Alert>
                  {workPermit.split('.').pop() === 'pdf' ? (
                    <div>
                      <Document
                        file={workPermit}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    </div>
                  ) : (
                    <Image src={workPermit} alt="Id Document" fluid />
                  )}
                </>
              ) : wp_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  6. Work Permit Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadWP}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={WPbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        6. Work Permit
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UpdateDusterDocs;
