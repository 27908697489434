import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createNewUser } from '../../../../actions/admin/admin_user';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { setNote } from '../../../../actions/note';
import AddressAutocomplete from '../../../Layout/AddressAutocomplete';

const AddNewUser = ({ createNewUser, setNote, note }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(note);
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    cellphone: '',
    email: '',
    password: '',
    password2: '',
  });
  const [addressType, setAddressType] = useState();
  const [complexNumber, setComplexNumber] = useState(0);
  const [complexName, setComplexName] = useState();
  const [complex, setComplex] = useState('');
  const [adrSave, setAdrSaved] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();
  let navigate = useNavigate();
  const { name, surname, cellphone, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (note.length > 0 && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
      if (note[0].noteType === 'success') {
        setFormData({
          name: '',
          surname: '',
          cellphone: '',
          email: '',
          password: '',
          password2: '',
        });
        setAddressType();
        setComplexNumber(0);
        setComplexName();
        setComplex('');
        setAdrSaved(false);
      }
    }
  }, [setMsg, note, msg]);

  const saveAddressDetails = () => {
    if (!addressType) {
      setError(`Please let us know what type of address this is`);
      return setTimeout(() => setError(null), 2000);
    } else if (addressType === 'House') {
      setAdrSaved(true);
      handleClose();
    } else if (complexNumber === 0) {
      setError(`Please enter ${addressType} number`);
      return setTimeout(() => setError(null), 2000);
    } else if (complexName.length < 5) {
      setError(`Please enter ${addressType} name`);
      return setTimeout(() => setError(null), 2000);
    } else {
      let complex = `${complexNumber} ${complexName}`;
      setComplex(complex);
      setAdrSaved(true);
      handleClose();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setNote('Passwords do not match', 'danger');
    } else {
      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
      createNewUser({
        name,
        surname,
        address,
        cellphone,
        complex,
        coordinates,
        email,
        password,
        addressType,
      });
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="container">
      <Row>
        <Col xs={1}>
          <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
        </Col>
        <Col align="left">
          <h4>Add new user</h4>
        </Col>
      </Row>
      <hr />
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Your Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter your name as it appears on your identification
            documents
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Surname:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Your Surname"
            name="surname"
            value={surname}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter your surname as it appears on your identification
            documents
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Cellphone:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Your Cellphone Number"
            name="cellphone"
            value={cellphone}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter your cellphone number
          </Form.Text>
        </Form.Group>
        <Form.Group>
          {adrSave ? (
            <Button variant="outline-info" block>
              Edit: {address}
            </Button>
          ) : (
            <Button variant="outline-secondary" onClick={handleShow} block>
              Click to enter address details{' '}
              <i className="fa-solid fa-computer-mouse"></i>
            </Button>
          )}
        </Form.Group>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Let us know a little about your address</Modal.Title>
          </Modal.Header>
          {error && <Alert variant="warning">{error}</Alert>}
          <Modal.Body>
            <AddressAutocomplete
              value={address}
              setAddress={setAddress}
              setCoordinates={setCoordinates}
            />

            {address ? (
              <Row className="mt-4">
                <Col xs={3}>
                  <Button
                    variant={
                      addressType === 'House'
                        ? 'outline-info'
                        : 'outline-secondary'
                    }
                    onClick={() => setAddressType('House')}
                  >
                    <span style={{ fontSize: 50 }}>
                      <i className="fa-solid fa-house"></i>
                    </span>
                  </Button>
                  House
                </Col>
                <Col xs={3}>
                  <Button
                    variant={
                      addressType === 'Townhouse/Estate'
                        ? 'outline-info'
                        : 'outline-secondary'
                    }
                    onClick={() => setAddressType('Townhouse/Estate')}
                  >
                    <span style={{ fontSize: 50 }}>
                      <i className="fa-solid fa-people-roof"></i>
                    </span>
                  </Button>
                  Townhouse
                </Col>
                <Col xs={3}>
                  <Button
                    variant={
                      addressType === 'Apartment/Flat'
                        ? 'outline-info'
                        : 'outline-secondary'
                    }
                    onClick={() => setAddressType('Apartment/Flat')}
                  >
                    <span style={{ fontSize: 50 }}>
                      <i className="fa-solid fa-city"></i>
                    </span>
                  </Button>
                  Apartment/Flat
                </Col>
                <Col xs={3}>
                  <Button
                    variant={
                      addressType === 'Office'
                        ? 'outline-info'
                        : 'outline-secondary'
                    }
                    onClick={() => setAddressType('Office')}
                  >
                    <span style={{ fontSize: 50 }}>
                      <i className="fa-regular fa-building"></i>
                    </span>
                  </Button>
                  Office
                </Col>
              </Row>
            ) : (
              ''
            )}

            {addressType && addressType !== 'House' ? (
              <>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Enter {addressType} number </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={`Enter ${addressType} number`}
                    name="complexNumber"
                    value={complexNumber}
                    onChange={(e) => setComplexNumber(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Enter {addressType} name </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`Enter ${addressType} name`}
                    name="complexName"
                    value={complexName}
                    onChange={(e) => setComplexName(e.target.value)}
                  />
                </Form.Group>
              </>
            ) : (
              ''
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-info" onClick={() => saveAddressDetails()}>
              Save Address
            </Button>
          </Modal.Footer>
        </Modal>
        <Form.Group>
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Please enter your email address"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            We will need to confirm you own this address, please enter the
            correct email.
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Password:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please don't make this password easy to guess"
            name="password"
            value={password}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter a password that is longer than 7 characters
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm Password:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please confirm your password"
            name="password2"
            value={password2}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please confirm your password
          </Form.Text>
        </Form.Group>

        <Button variant="success btn-block" type="submit" ref={btn}>
          Create user<i className="fas fa-users ml-3"></i>
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { createNewUser, setNote })(AddNewUser);
