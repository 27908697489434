import axios from 'axios';
import {
  CLEAR_COUPONS,
  MY_COUPONS,
  COUPON,
  COUPON_TOTAL,
  CLEAR_COUPON,
  COUPON_ERROR,
  REMOVE_COUPON_ERROR,
  COUPON_OPTIONS,
} from '../types';

//Get user coupons//
export const myCoupons = () => async (dispatch) => {
  dispatch({ type: CLEAR_COUPONS });
  try {
    const res = await axios.get('/api/user/coupons');
    dispatch({ type: MY_COUPONS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//get specific coupon//
export const getCoupon = (couponId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/coupons/specific/${couponId}`);
    dispatch({ type: COUPON, payload: res.data });
  } catch (error) {
    console.error('Error Fetching Coupon: ', error.message);
  }
};

//set the couponTotal
export const setCouponTotal = (couponTotal) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_TOTAL, payload: couponTotal });
  } catch (error) {
    console.error(error.message);
  }
};

//ClearCoupon
export const clearCoupon = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_COUPON });
  } catch (error) {
    console.log(error.message);
  }
};

//enter coupon//
export const enterCoupon =
  ({ invoiceHours, invoice, coupon, secondClean }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      invoiceHours,
      invoice,
      coupon,
      secondClean,
    });
    try {
      const res = await axios.put(
        '/api/user/coupons/submit-coupon/',
        body,
        config
      );

      dispatch({ type: COUPON_OPTIONS, payload: res.data });
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => {
          dispatch({ type: COUPON_ERROR, payload: error.msg });
          setTimeout(() => dispatch({ type: REMOVE_COUPON_ERROR }), 5000);
        });
      }
    }
  };

//Confirm Coupon use//
export const confirmCouponUse = (couponId) => async (dispatch) => {
  try {
    await axios.put(`/api/user/coupons/confirm-coupon-use/${couponId}`);
  } catch (error) {
    console.error(error.message);
  }
};

export const cancelThisCoupon = (couponId) => async (dispatch) => {
  try {
    await axios.put(`/api/user/coupons/cancel-coupon-use/${couponId}`);
  } catch (error) {
    console.error(error.message);
  }
};
