import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createAdmin } from '../../../../../actions/admin';
import { fetchEmployees } from '../../../../../actions/admin/main';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AdminUser from './AdminUser';
import EmployeeItem from './EmployeeItem';

const EmployeeDash = ({ createAdmin, note, fetchEmployees, employees }) => {
  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Row>
        <Col>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>{' '}
          <h4>Employee Dashboard</h4>
        </Col>
        <Col>
          <AdminUser
            createAdmin={createAdmin}
            note={note}
            fetchEmployees={fetchEmployees}
          />
        </Col>
      </Row>
      <hr />
      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Department</th>
            <th>Last login</th>
          </tr>
        </thead>
        <tbody>
          {employees === null ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            employees.employees.map((employee) => (
              <EmployeeItem key={employee._id} employee={employee} />
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  employees: state.main.employees,
});

export default connect(mapStateToProps, { createAdmin, fetchEmployees })(
  EmployeeDash
);
