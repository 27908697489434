import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import {
  theMotherCard,
  editMotherCard,
} from '../../../../../actions/admin/main';

const MotherCard = ({ theMotherCard, mother, editMotherCard }) => {
  useEffect(() => {
    theMotherCard();
  }, [theMotherCard]);
  const [show, setShow] = useState(false);
  const [four, SetUserFour] = useState('');
  const [fourp, SetUserFourp] = useState('');
  const [five, SetUserFive] = useState('');
  const [fivep, SetUserFivep] = useState('');
  const [six, SetUserSix] = useState('');
  const [sixp, SetUserSixp] = useState('');
  const [seven, SetUserSeven] = useState('');
  const [sevenp, SetUserSevenp] = useState('');
  const [eight, SetUserEight] = useState('');
  const [nine, SetUserNine] = useState('');
  const [ten, SetUserTen] = useState('');
  const [motherCard, SetMotherCard] = useState('');

  useEffect(() => {
    if (mother) {
      SetMotherCard(mother._id);
      SetUserFour(mother.four);
      SetUserFourp(mother.fourp);
      SetUserFive(mother.five);
      SetUserFivep(mother.fivep);
      SetUserSix(mother.six);
      SetUserSixp(mother.sixp);
      SetUserSeven(mother.seven);
      SetUserSevenp(mother.sevenp);
      SetUserEight(mother.eight);
      SetUserNine(mother.nine);
      SetUserTen(mother.ten);
    }
  }, [mother]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const editMother = (e) => {
    e.preventDefault();
    editMotherCard({
      motherCard,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
      nine,
      ten,
    });
    setShow(false);
  };

  return (
    <>
      <Button
        variant="outline-primary float-right"
        size="sm"
        onClick={handleShow}
      >
        Domestic cleaning rate
      </Button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Form onSubmit={editMother}>
          <Modal.Header closeButton>
            <Modal.Title>Domestic cleaning rate card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>4 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={four}
                    name="four"
                    value={four}
                    onChange={(e) => SetUserFour(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>4.5 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={fourp}
                    name="four"
                    value={fourp}
                    onChange={(e) => SetUserFourp(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>5 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={five}
                    name="five"
                    value={five}
                    onChange={(e) => SetUserFive(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>5.5 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={fivep}
                    name="five"
                    value={fivep}
                    onChange={(e) => SetUserFivep(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>6 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={six}
                    name="six"
                    value={six}
                    onChange={(e) => SetUserSix(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>6.5 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={sixp}
                    name="six"
                    value={sixp}
                    onChange={(e) => SetUserSixp(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>7 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={seven}
                    name="seven"
                    value={seven}
                    onChange={(e) => SetUserSeven(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>7.5 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={sevenp}
                    name="seven"
                    value={sevenp}
                    onChange={(e) => SetUserSevenp(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>8 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={eight}
                    name="eight"
                    value={eight}
                    onChange={(e) => SetUserEight(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>9 hours rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={nine}
                    name="nine"
                    value={nine}
                    onChange={(e) => SetUserNine(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>10 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={ten}
                name="ten"
                value={ten}
                onChange={(e) => SetUserTen(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Edit domestic rate
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  mother: state.main.mother,
});

export default connect(mapStateToProps, { theMotherCard, editMotherCard })(
  MotherCard
);
