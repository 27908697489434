import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { cancelOrder } from '../../../actions/user/order';
import { Button, Modal, Form } from 'react-bootstrap';

const CancelMyOrder = ({ cancelOrder, note, orderId }) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (note.length > 0 && btn.current) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let btn = useRef();

  const cancelMyOrder = () => {
    cancelOrder({ reason, orderId });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div className="text-center">
      <Button variant="warning mt-2" onClick={handleShow}>
        Cancel order
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel your order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Why would you like to cancel your order:</Form.Label>
            <Form.Control
              as="textarea"
              name="desc"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              placeholder="Please let use know why you would like to cancel your order..."
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {reason.length > 3 ? (
            <Button variant="primary" onClick={cancelMyOrder} ref={btn}>
              Submit
            </Button>
          ) : (
            <Button variant="secondary">Why are you cancelling?</Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { cancelOrder })(CancelMyOrder);
