import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getUploads, fetchSkills } from '../../../actions/admin/team_members';
import { useNavigate, useParams } from 'react-router-dom';

const DusterDocs = ({ uploads, getUploads, fetchSkills }) => {
  const { dusterId } = useParams();
  const [idDoc, setIdDoc] = useState('');
  const [proPic, setProPic] = useState('');
  const [bankLetter, setBankLetter] = useState('');
  const [poa, setPOA] = useState('');
  const [workPermit, setWorkPermit] = useState('');
  const [ec, setEC] = useState('');
  useEffect(() => {
    if (dusterId) {
      getUploads(dusterId);
      fetchSkills(dusterId);
    }
  }, [getUploads, dusterId, fetchSkills]);

  useEffect(() => {
    if (uploads) {
      setIdDoc(uploads.idDoc);
      setProPic(uploads.pic);
      setBankLetter(uploads.bankLetter);
      setPOA(uploads.poa);
      setWorkPermit(uploads.workPermit);
      setEC(uploads.ec);
    }
  }, [uploads]);

  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const navToId = () => {
    window.open(idDoc);
  };

  const navToProPic = () => {
    window.open(proPic);
  };

  const navToBankLetter = () => {
    window.open(bankLetter);
  };

  const navToPOA = () => {
    window.open(poa);
  };

  const navToWorkPermit = () => {
    window.open(workPermit);
  };

  const navToEC = () => {
    window.open(ec);
  };
  return (
    <Container>
      <Row>
        <Col>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>
          <h4 style={{ color: '#06a5dd' }}>Documentation:</h4>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {idDoc ? (
            <Row className="text-center mb-2">
              <Col>
                <Button
                  variant="outline-info"
                  onClick={() => navToId()}
                  style={{ width: '225px' }}
                >
                  View ID
                </Button>
                <Button
                  variant="outline-warning ml-3"
                  style={{ width: '225px' }}
                >
                  Edit ID
                </Button>
              </Col>
            </Row>
          ) : (
            <Button variant="outline-secondary mb-2" block>
              Add ID
            </Button>
          )}
          {proPic ? (
            <Row className="text-center mb-2">
              <Col>
                <Button
                  variant="outline-info"
                  onClick={() => navToProPic()}
                  style={{ width: '225px' }}
                >
                  View pro pic
                </Button>
                <Button
                  variant="outline-warning ml-3"
                  style={{ width: '225px' }}
                >
                  Edit pro pic
                </Button>
              </Col>
            </Row>
          ) : (
            <Button variant="outline-secondary mb-2" block>
              Add pro pic
            </Button>
          )}
          {bankLetter ? (
            <Row className="text-center">
              <Col>
                <Button
                  variant="outline-info"
                  onClick={() => navToBankLetter()}
                  style={{ width: '225px' }}
                >
                  View bank letter
                </Button>
                <Button
                  variant="outline-warning ml-3"
                  style={{ width: '225px' }}
                >
                  Edit bank letter
                </Button>
              </Col>
            </Row>
          ) : (
            <Button variant="outline-secondary" block>
              Add bank letter
            </Button>
          )}
        </Col>
        <Col>
          {poa ? (
            <Row className="text-center mb-2">
              <Col>
                <Button
                  variant="outline-info"
                  onClick={() => navToPOA()}
                  style={{ width: '225px' }}
                >
                  View proof of address
                </Button>
                <Button
                  variant="outline-warning ml-3"
                  style={{ width: '225px' }}
                >
                  Edit proof of address
                </Button>
              </Col>
            </Row>
          ) : (
            <Button variant="outline-secondary mb-2" block>
              Add Proof of address
            </Button>
          )}
          {workPermit ? (
            <Row className="text-center mb-2">
              <Col>
                <Button
                  variant="outline-info"
                  onClick={() => navToWorkPermit()}
                  style={{ width: '225px' }}
                >
                  View work permit
                </Button>
                <Button
                  variant="outline-warning ml-3"
                  style={{ width: '225px' }}
                >
                  Edit work permit
                </Button>
              </Col>
            </Row>
          ) : (
            <Button variant="outline-secondary" block>
              Add work permit
            </Button>
          )}
          {ec ? (
            <Row className="text-center">
              <Col>
                <Button
                  variant="outline-info"
                  onClick={() => navToEC()}
                  style={{ width: '225px' }}
                >
                  View employee contract
                </Button>
                <Button
                  variant="outline-warning ml-3"
                  style={{ width: '225px' }}
                >
                  Edit employee contract
                </Button>
              </Col>
            </Row>
          ) : (
            <Button variant="outline-secondary" block>
              No EC
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  uploads: state.team_members.uploads,
});

export default connect(mapStateToProps, { getUploads, fetchSkills })(
  DusterDocs
);
