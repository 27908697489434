import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Button, Alert, Collapse } from 'react-bootstrap';
import { setNote } from '../../../actions/note';
import {
  SetSchedule,
  SetWeekDays,
  regularRate,
  removeSchedule,
  fullTimeCleaner,
} from '../../../actions/user/bookings';
import DaysOfTheWeek from './DaysOfTheWeek';

const Schedule = ({
  once,
  twice,
  three,
  full,
  days,
  weekdays,
  Setdays,
  daysLeft,
  schedule,
  invoiceHours,
  OnceAWeek,
  TwiceAWeek,
  ThreeAWeek,
  FullTime,
  SetMon,
  Mon,
  SetTue,
  Tue,
  SetWed,
  Wed,
  SetThu,
  Thu,
  SetFri,
  Fri,
  SetSat,
  Sat,
  SetSun,
  Sun,
  SetWeekDays,
  open,
  removeSchedule,
  fullTimeCleaner,
  setNote,
}) => {
  useEffect(() => {
    if (FullTime) {
      SetMon(true);
      SetTue(true);
      SetWed(true);
      SetThu(true);
      SetFri(true);
      SetSat(false);
      SetSun(false);
      let newDays = [];
      newDays.push('Mon', 'Tue', 'Wed', 'Thu', 'Fri');
      SetWeekDays(newDays);
      fullTimeCleaner({ daysLeft, invoiceHours });
    }
  }, [
    FullTime,
    SetMon,
    SetTue,
    SetWed,
    SetThu,
    SetFri,
    SetSat,
    SetSun,
    SetWeekDays,
    fullTimeCleaner,
    daysLeft,
    invoiceHours,
  ]);

  //Button Validation//
  function validateWeek(addWeekDay) {
    let jam = addWeekDay;
    if (OnceAWeek) {
      if (days >= 1) {
        return setNote(
          'If you would like more than one day please change "How Often?" option',
          'warning'
        );
      }
    }
    if (TwiceAWeek) {
      if (days >= 2) {
        return setNote(
          'If you would like more than 2 days please change "How Often?" option',
          'warning'
        );
      }
    }
    if (ThreeAWeek) {
      if (days >= 3) {
        return setNote(
          'If you would like more than 3 days please choose the Full Time option',
          'warning'
        );
      }
    }
    jam.push(true);
    return addWeekDay;
  }

  //Button Functions//
  const monday = async (e) => {
    e.preventDefault();
    if (Mon) {
      let remove = 'Mon';
      let currentdays = weekdays;
      let newDays = currentdays.filter((currentday) => currentday !== 'Mon');
      SetWeekDays(newDays);
      SetMon(false);
      removeSchedule({ remove, schedule });
      return Setdays(days - 1);
    }

    let addWeekDay = [];
    validateWeek(addWeekDay);

    if (addWeekDay[0] === true) {
      let currentdays = weekdays;
      let match = 'Mon';
      currentdays.push(match);
      SetWeekDays(currentdays);
      SetMon(true);
      //createSchedule({ schedule, match, daysLeft, invoiceHours });
      return Setdays(days + 1);
    }
  };

  const tuesday = async (e) => {
    e.preventDefault();
    if (Tue) {
      let remove = 'Tue';
      let currentdays = weekdays;
      let newDays = currentdays.filter((currentday) => currentday !== 'Tue');
      SetWeekDays(newDays);
      SetTue(false);
      removeSchedule({ remove, schedule });
      return Setdays(days - 1);
    }

    let addWeekDay = [];
    validateWeek(addWeekDay);

    if (addWeekDay[0] === true) {
      let currentdays = weekdays;
      let match = 'Tue';
      currentdays.push(match);
      SetWeekDays(currentdays);
      SetTue(true);
      //createSchedule({ schedule, match, daysLeft, invoiceHours });
      Setdays(days + 1);
    }
  };

  const wednesday = () => {
    if (Wed) {
      let remove = 'Wed';
      let currentdays = weekdays;
      let newDays = currentdays.filter((currentday) => currentday !== 'Wed');
      SetWeekDays(newDays);
      SetWed(false);
      removeSchedule({ remove, schedule });
      return Setdays(days - 1);
    }

    let addWeekDay = [];
    validateWeek(addWeekDay);

    if (addWeekDay[0] === true) {
      let currentdays = weekdays;
      let match = 'Wed';
      currentdays.push(match);
      SetWeekDays(currentdays);
      SetWed(true);
      //createSchedule({ schedule, match, daysLeft, invoiceHours });
      Setdays(days + 1);
    }
  };

  const thursday = () => {
    if (Thu) {
      let remove = 'Thu';
      let currentdays = weekdays;
      let newDays = currentdays.filter((currentday) => currentday !== 'Thu');
      SetWeekDays(newDays);
      SetThu(false);
      removeSchedule({ remove, schedule });
      return Setdays(days - 1);
    }

    let addWeekDay = [];
    validateWeek(addWeekDay);

    if (addWeekDay[0] === true) {
      let currentdays = weekdays;
      let match = 'Thu';
      currentdays.push(match);
      SetWeekDays(currentdays);
      SetThu(true);
      //createSchedule({ schedule, match, daysLeft, invoiceHours });
      Setdays(days + 1);
    }
  };

  const friday = () => {
    if (Fri) {
      let remove = 'Fri';
      let currentdays = weekdays;
      let newDays = currentdays.filter((currentday) => currentday !== 'Fri');
      SetWeekDays(newDays);
      SetFri(false);
      removeSchedule({ remove, schedule });
      return Setdays(days - 1);
    }

    let addWeekDay = [];
    validateWeek(addWeekDay);

    if (addWeekDay[0] === true) {
      let currentdays = weekdays;
      let match = 'Fri';
      currentdays.push(match);
      SetWeekDays(currentdays);
      SetFri(true);
      //createSchedule({ schedule, match, daysLeft, invoiceHours });
      Setdays(days + 1);
    }
  };

  const saturday = () => {
    if (FullTime) return setNote('Full Time is 5 days!', 'warning');
    if (Sat) {
      let remove = 'Sat';
      let currentdays = weekdays;
      let newDays = currentdays.filter((currentday) => currentday !== 'Sat');
      SetWeekDays(newDays);
      SetSat(false);
      removeSchedule({ remove, schedule });
      return Setdays(days - 1);
    }

    let addWeekDay = [];
    validateWeek(addWeekDay);

    if (addWeekDay[0] === true) {
      let currentdays = weekdays;
      let match = 'Sat';
      currentdays.push(match);
      SetWeekDays(currentdays);
      SetSat(true);
      //createSchedule({ schedule, match, daysLeft, invoiceHours });
      Setdays(days + 1);
    }
  };

  const sunday = () => {
    if (FullTime) return setNote('Full Time is 5 days!', 'warning');

    if (Sun) {
      let remove = ' Sun';
      let currentdays = weekdays;
      let newDays = currentdays.filter((currentday) => currentday !== 'Sun');
      SetWeekDays(newDays);
      SetSun(false);
      removeSchedule({ remove, schedule });
      return Setdays(days - 1);
    }

    let addWeekDay = [];
    validateWeek(addWeekDay);

    if (addWeekDay[0] === true) {
      let currentdays = weekdays;
      let match = 'Sun';
      currentdays.push(match);
      SetWeekDays(currentdays);
      SetSun(!Sun);
      //createSchedule({ schedule, match, daysLeft, invoiceHours });
      Setdays(days + 1);
    }
  };

  return (
    <>
      <Collapse in={open}>
        <div>
          <Alert variant="info mt-2 text-center">
            How Often & On Which Days
          </Alert>
          <Row>
            <Col md={3} xs={12}>
              <Card.Header>Once A Week</Card.Header>
              <Button
                variant={OnceAWeek ? 'info' : 'outline-info'}
                onClick={once}
                block
              >
                <span style={{ fontSize: '2em' }}>
                  <i className="fas fa-align-center"></i>
                </span>
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Card.Header>Twice A Week</Card.Header>
              <Button
                variant={TwiceAWeek ? 'info' : 'outline-info'}
                onClick={twice}
                block
              >
                <span style={{ fontSize: '2em' }}>
                  <i className="fas fa-align-center"></i>
                </span>
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Card.Header>3 times a Week</Card.Header>
              <Button
                variant={ThreeAWeek ? 'info' : 'outline-info'}
                onClick={three}
                block
              >
                <span style={{ fontSize: '2em' }}>
                  <i className="fas fa-align-center"></i>
                </span>
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Card.Header>Full Time</Card.Header>
              <Button
                variant={FullTime ? 'info' : 'outline-info'}
                onClick={full}
                block
              >
                <span style={{ fontSize: '2em' }}>
                  <i className="fas fa-align-center"></i>
                </span>
              </Button>
            </Col>
          </Row>
          <Alert variant="info mt-3 text-center">On Which Days?</Alert>
          <DaysOfTheWeek
            Mon={Mon}
            monday={monday}
            Tue={Tue}
            tuesday={tuesday}
            Wed={Wed}
            wednesday={wednesday}
            Thu={Thu}
            thursday={thursday}
            Fri={Fri}
            friday={friday}
            Sat={Sat}
            saturday={saturday}
            Sun={Sun}
            sunday={sunday}
          />
        </div>
      </Collapse>
    </>
  );
};

export default connect(null, {
  setNote,
  SetSchedule,
  removeSchedule,

  SetWeekDays,
  regularRate,
  fullTimeCleaner,
})(Schedule);
