import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getMyCarpertCleans } from '../../../actions/user/carpert_cleaning';
import { Container, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MyCarpertCleanItem from './MyCarpertCleanItem';

const MyCarpertCleans = ({ getMyCarpertCleans, my_carpert_cleans }) => {
  useEffect(() => {
    getMyCarpertCleans();
  }, [getMyCarpertCleans]);
  let navigate = useNavigate();
  if (my_carpert_cleans === null)
    return (
      <Container>
        <Spinner variant="primary" animation="grow" />
      </Container>
    );

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4 style={{ color: '#06a5dd' }}>Carpet cleaning</h4>
      <hr />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cleaning items</th>
            <th>Booking Date</th>
            <th>Address</th>
            <th>Payment Status</th>
            <th>Booking Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {my_carpert_cleans.length === 0 ? (
            <p>No carpet cleans</p>
          ) : (
            my_carpert_cleans.map((carpert_clean) => (
              <MyCarpertCleanItem
                key={carpert_clean._id}
                carpert_clean={carpert_clean}
              />
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  my_carpert_cleans: state.carpert_cleaning.my_carpert_cleans,
});

export default connect(mapStateToProps, { getMyCarpertCleans })(
  MyCarpertCleans
);
