import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';
import Bottom from '../../Layout/Bottom';
import {
  Container,
  Form,
  Modal,
  Button,
  Row,
  Col,
  Alert,
} from 'react-bootstrap';
import { setNote } from '../../../actions/note';
import { signupBusiness } from '../../../actions/business';

const RegisterBusiness = ({ note, setNote, signupBusiness }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [msg, setMsg] = useState(note);
  const [show, setShow] = useState(false);
  const [agree, setAgree] = useState(false);
  const [address, setAddress] = useState('');
  const [addressType, setAddressType] = useState();
  const [complexNumber, setComplexNumber] = useState(0);
  const [complexName, setComplexName] = useState();
  const [error, setError] = useState(null);
  const [adrSave, setAdrSaved] = useState(false);
  const [complex, setComplex] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const [formData, setFormData] = useState({
    name: '',
    vat: '',
    contact: '',
    telephone: '',
    email: '',
    password: '',
    password2: '',
  });
  let btn = useRef();
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const { name, vat, contact, telephone, email, password, password2 } =
    formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const saveAddressDetails = () => {
    if (!addressType) {
      setError(`Please let us know what type of address this is`);
      return setTimeout(() => setError(null), 2000);
    } else if (addressType === 'House') {
      setAdrSaved(true);
      handleClose();
    } else if (complexNumber === 0) {
      setError(`Please enter ${addressType} number`);
      return setTimeout(() => setError(null), 2000);
    } else if (complexName.length < 5) {
      setError(`Please enter ${addressType} name`);
      return setTimeout(() => setError(null), 2000);
    } else {
      let complex = `${complexNumber} ${complexName}`;
      setComplex(complex);
      setAdrSaved(true);
      handleClose();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setNote('Passwords do not match', 'warning');
    } else if (agree === false) {
      setNote('Please agree to our terms of services', 'warning');
    } else {
      signupBusiness({
        name,
        vat,
        contact,
        telephone,
        email,
        password,
        address,
        coordinates,
        addressType,
        complex,
      });

      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };

  return (
    <div>
      <Container style={{ width: '40rem' }}>
        <h3 className="text-center">
          Welcome to Dusting Down for Business, Create an account to book a
          service!
        </h3>
        <h4 className="text-center text-primary">
          Are you a home or business?
        </h4>

        <Row className="text-center mt-3 mb-3">
          <Col>
            <Button variant="outline-info" as={Link} to="/user/signup">
              Home
            </Button>
          </Col>
          <Col>
            <Button variant="primary">Business</Button>
          </Col>
        </Row>
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>Business Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Business Name"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter your business name
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Vat Number:</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Business Vat Number"
              name="vat"
              value={vat}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter business vat number
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Main Contact:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Main Contact Name"
              name="contact"
              value={contact}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter your businesses main contact person
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Telephone:</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Business Telephone Number"
              name="telephone"
              value={telephone}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter business telephone number
            </Form.Text>
          </Form.Group>
          <Form.Group>
            {adrSave ? (
              <Button variant="outline-info" block>
                Edit: {address}
              </Button>
            ) : (
              <Button variant="outline-secondary" onClick={handleShow} block>
                Click to enter address details{' '}
                <i className="fa-solid fa-computer-mouse"></i>
              </Button>
            )}
          </Form.Group>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Let us know a little about your address</Modal.Title>
            </Modal.Header>
            {error && <Alert variant="warning">{error}</Alert>}
            <Modal.Body>
              <AddressAutocomplete
                value={address}
                setAddress={setAddress}
                setCoordinates={setCoordinates}
              />

              {address ? (
                <Row className="mt-4">
                  <Col xs={4}>
                    <Button
                      variant={
                        addressType === 'House'
                          ? 'outline-info'
                          : 'outline-secondary'
                      }
                      onClick={() => setAddressType('House')}
                    >
                      <span style={{ fontSize: 70 }}>
                        <i className="fa-solid fa-house"></i>
                      </span>
                    </Button>
                    House
                  </Col>
                  <Col xs={4}>
                    <Button
                      variant={
                        addressType === 'Office Park'
                          ? 'outline-info'
                          : 'outline-secondary'
                      }
                      onClick={() => setAddressType('Office Park')}
                    >
                      <span style={{ fontSize: 70 }}>
                        <i className="fa-solid fa-people-roof"></i>
                      </span>
                    </Button>
                    Office Park
                  </Col>
                  <Col xs={4}>
                    <Button
                      variant={
                        addressType === 'Business Suites'
                          ? 'outline-info'
                          : 'outline-secondary'
                      }
                      onClick={() => setAddressType('Business Suites')}
                    >
                      <span style={{ fontSize: 70 }}>
                        <i className="fa-solid fa-city"></i>
                      </span>
                    </Button>
                    Business Suites
                  </Col>
                </Row>
              ) : (
                ''
              )}

              {addressType && addressType !== 'House' ? (
                <>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Enter {addressType} number </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={`Enter ${addressType} number`}
                      name="complexNumber"
                      value={complexNumber}
                      onChange={(e) => setComplexNumber(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Enter {addressType} name </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Enter ${addressType} name`}
                      name="complexName"
                      value={complexName}
                      onChange={(e) => setComplexName(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ''
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-info"
                onClick={() => saveAddressDetails()}
              >
                Save Address
              </Button>
            </Modal.Footer>
          </Modal>

          <Form.Group>
            <Form.Label>Email Address:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Please Enter Business Email Address"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              We will need to confirm you own this address, please enter the
              correct email.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Please don't make this password easy to guess"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter a password that is longer than 7 characters
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Please confirm your password"
              name="password2"
              value={password2}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please confirm your password
            </Form.Text>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Agree to our terms & Privacy Policy"
                checked={agree}
                value={agree}
                onChange={(e) => setAgree(!agree)}
              />
              <Form.Text className="text-muted">
                By ticking this box you agree to our{' '}
                <Link to="/user/agreement">terms of service</Link>
              </Form.Text>
            </Form.Group>
            <Button variant="success btn-block" type="submit" ref={btn}>
              Join the Family! <i className="fas fa-users ml-3"></i>
            </Button>
          </Form.Group>
        </Form>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { setNote, signupBusiness })(
  RegisterBusiness
);
