import React from 'react';
import Bottom from '../Layout/Bottom';

const Agreement = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            Our Terms and Conditions for you as a independent contractor
            including our privacy and cancelation policy
          </h3>
        </div>
        <div>
          <b>Terms and conditions</b>
          <p>
            Welcome to the Dusting Down website terms and conditions for use;
          </p>
          <p>
            <b>I. (“Terms”).</b> These Terms apply to the use of this Website
            and by accessing this Website and/or making a Booking for Services
            you agree to be bound by the Terms set out below.
          </p>
          <p>
            By not agreeing the terms in the (I accept the terms tab, your order
            will be cancel and not processed. You are welcome to contact us
            regarding the terms on the following email address
            legal@dustingdown.co.za.
          </p>
          <b>II. DEFINITIONS</b>
          <p>
            • “Booking” means a booking for Services made by the client on our
            Website or mobile app
          </p>
          <p>
            • “Services” means any service, or advice or assistance or any other
            home, offices or personal services;
          </p>
          <p>
            • “Dusting Down ” means a cleaner or employee, subcontractor or
            independent operator who may provide a services through our Website
            or mobile app
          </p>
          <p>• “We/us” means Dusting Down (PTY) Ltd Reg No:2016/153521/07 </p>
          <p>
            • “Our Address” means Unit 7, 355 Oak Avenue, Ferndale, Randburg.
          </p>
          <p>
            • “Website” means the website located at
            https://www.dustingdown.co.za or any subsequent URL which may
            replace it; • “You” means a user of this Website.
          </p>
          <p>
            • “DustBuster” means an independent contractor who uses our
            app/platform and or Website.
          </p>
          <b>1. USE OF OUR PLATFORM</b>
          <p>
            1.1. Access:-You are provided with access to this Website in
            accordance with these Terms and any Booking accepted by you must be
            strictly recieved in accordance with these Terms.
          </p>
          <p>
            1.2. By ticking the terms and condition box you acknowledge our
            terms and conditions.
          </p>
          <p>
            1.3. You will receive a OTP, that confirms that the cellphone used
            to tick the box was in your possession at the time of ticking the
            box.
          </p>
          <p>1.4. Registration </p>
          <div className="ml-3">
            <p>
              1.4.1. By registering as a user of our Website, you warrant that:
            </p>
            <p>
              1.4.2. the personal information which you provide when you
              register as a user will be the legal information used when we
              confirm a booking on your behalf, is true, accurate, current and
              complete in all respects;
            </p>
            <p>
              1.4.3. you will notify update Dusting Down immediately of any
              changes to this personal information.
            </p>
            <p>
              1.4.4. This update is total and legally your responsibility and
              services renders to the incorrect address will not render us
              responsible.
            </p>
            or a name that you are not authorised to use.
            <p>
              1.4.6. You also knowledge that in the event that you change your
              phone number you will have to re register on the platform.
            </p>
          </div>
          <b>2. OUR RIGHTS</b>
          <p>2.1. We reserve the right to:</p>
          <div className="ml-3">
            2.1.1. modify or withdraw, temporarily or permanently, this Website
            (or any part thereof) with or without notice to you and you confirm
            that we shall not be liable to you or any third party for any
            modification to or withdrawal of the Website; and/or the change
            these Terms and conditions from time to time.
          </div>
          <p className="mt-2">
            2.2. We have the right to revise and amend these Terms from time to
            time. You will be subject to the policies and Terms in force at the
            time that you book Services from us.
          </p>
          <p>
            {' '}
            2.3. Dusting Down (PTY) Ltd and our business may change from time to
            time. As a result, at times it may be necessary for Dusting Down to
            make changes to our terms and conditions.
          </p>
          <b>3. YOUR RIGHTS</b>
          <p>
            3.1. As a South African citizen, you have the right to ask us not to
            process your personal information for marketing purposes by
            contacting us.
          </p>
          <p>
            3.2. The South African Protection of Personal Information Act
            (POPI)2013 gives you the right to access information held about you.
            Any access request may be subject to a administrative fee of R750.00
            per request.
          </p>
          <p>
            3.3. We may store information such as your name; your contact
            telephone numbers; your e-mail address; home and or work or postal
            address that you submitted; information about your home which you
            give us; your payment details; your IP address; and any other
            personal information which you give us in sing up pages. We will use
            commercially reasonable efforts to honour your request.
          </p>
          <p>
            3.4. Your request must be presented in a formal application along
            with a certified copy of your identity document.
          </p>
          <p>
            3.5. Dusting Down does not offer any refunds for services provided,
            which is why we always encourage our customers to fully inspect the
            cleaning before your dustbuster leaves to ensure that you are
            completely satisfied with the results.
          </p>
          <p>
            3.6. If you are dissatisfied with the service, then any issue must
            be reported within 24 hours of completion of the service. We will
            then attempt to rectify these issues.
          </p>
          <b>4. PRIVACY</b>
          <p>
            4.1. We process information about you in accordance with our Privacy
            Policy. By using our Website, you consent to such processing and you
            warrant that all data provided by you is accurate.
          </p>
          <p>
            4.2. We may retain an archived copy of your records as required by
            law or for legitimate business purposes. Every effort has been made
            on this Website, or mobile application to ensure that making a
            Booking is secure.
          </p>
          <p>
            4.3. The Website may be used only for lawful purposes and in a
            lawful manner. You agree to comply with all applicable laws,
            statutes and regulations regarding the Website and any transactions
            conducted on or through the Website
          </p>
          <b>5. THE DUSTING DOWN SERVICES WEB APPLICATION</b>
          <div className="ml-3">
            <p>
              5.1.1. Our contract with our client is limited to provision of use
              of our Website.
            </p>
            <p>
              5.1.2. Your DustingDown contractor is responsible for providing
              the Services to our client, as per the list the client selected on
              our platform.
            </p>
            <p> 5.1.3. This contract does not include us.</p>
            <p>
              5.1.4. The Independent contractor, you has acknowledged there is
              no contract with us.
            </p>
            <p>
              5.1.5. As an independent contractor you understand that all
              statutory deductions will be deducted from his/her invoice to us,
              and we pay the said amount over to the said authorities.
            </p>
          </div>
          <p>
            5.2. Independent Contractors are responsible for any taxes arising
            as a result of the Services.
          </p>
          <p>
            5.3. As an independent contractor you understand that you will have
            to belong to the national provident fund and such deductions will be
            deducted from your hourly invoiced amount
          </p>
          <p>
            5.4. We review the application process, and may conduct interviews
            and undertake other checks. The independent contractors are also
            required to attend certain training update seminars each quarter to
            remain vetted by the Dusting Down.
          </p>
          <p>
            5.5. Some of this process are reliant on information from third
            parties.
          </p>
          <p>
            5.6. We do not and cannot guarantee the accurateness or completeness
            of such checks, whether conducted by us or by third parties.
          </p>
          <b>6. BOOKING SERVICES</b>
          <p>
            6.1. By ccepting an order through our Website, you warrant that you
            are: legally capable of entering into binding contracts, and at
            least 18 years old.
          </p>
          <p>6.2. All Bookings are subject to acceptance by us, </p>
          <p>
            6.3. We will confirm such acceptance to you by sending you an email
            and or push notification.
          </p>
          <p>
            6.3.1. The Notification will include the Clients name, Total
            estimate amount for the day, and the date the service was booked
            for. 6.3.2. The contracts between us will only be concluded when we
            send you log into the site and then complete the tasks as set out on
            the device and then clock out at the end of the day.
          </p>
          <b>7. ENGAGING PRIVATELY WITH THE CONTRACTOR</b>
          <p>
            7.1. You warrant that you will not seek to book or receive any
            Dusting Down Services other than via our Website for the duration of
            this agreement and in the 3 months after termination of this
            agreement.
          </p>
          <p>
            7.2. In the event that you accept an offer from our client or
            alternative employment or opportunities you will blocked from the
            app and a penalty fee of no less than R 3000.00 will be charged for
            readmitting to our platform. The client will also be charged
            according to their agreement with us.
          </p>
          <b>8. PRICE AND PAYMENT</b>
          <p>
            8.1. The price of any Services will be as quoted on our booking
            tool.
          </p>
          <b>9. BOOKING CANCELLATION BY US</b>
          <p>
            9.1. If you violate any of the Terms, your permission to use the
            Services and Website will automatically terminated.
          </p>
          <p>
            9.2. We reserve the right to cancel any Booking without notice or
            cause. We will however send you such notification.
          </p>
          <b>10. BOOKING CANCELLATION BY YOU</b>
          <p>
            10.1. You may cancel or reschedule a Booking until 18:00 the day
            before the Booking for free.
          </p>
          <b>11. THIRD PARTY LINKS AND SECURITY</b>
          <p>
            11.1. Links to third party websites on the Website are provided
            solely for your convenience.{' '}
          </p>
          <p>
            11.2. If you use these links, We do not control and are not
            responsible for these websites or their content or availability.
          </p>
          <p>
            11.3. We therefore do not endorse or make any representations about
            them, or any material found there, or any results that may be
            obtained from using them.
          </p>
          <p>
            11.4. If you decide to access any of the third party websites linked
            to the Website, you do so entirely at your own risk.
          </p>
          <p>
            11.5. However, no Internet or e-mail transmission is ever fully
            secure or error free; any transmission is at your own risk. In
            particular, e-mail sent to or from the Services may not be secure.
            Therefore, you should take special care in deciding what information
            you send to us via e-mail. Please keep this in mind when disclosing
            any Personal Data to Dusting Down via the Internet
          </p>{' '}
          <p>
            11.6. In operating the Services, we may use cookies. Dusting Down
            utilises first and third party cookies. This enables us to: a)
            Improve the User's experience on our Website through various means
            such as storing preferences, or determining aggregate usage
            behaviour through web analytics tools like Google Analytics.
          </p>
          <p>
            11.7 Most web browsers provide the option to block some or all
            cookie types should you wish to.
          </p>
          <b>12. CHILDREN</b>
          <p>
            12.1. Dusting Down does not knowingly collect Personal Data from
            children (individuals under the age of 18).{' '}
          </p>
          <p>
            12.2. If you are under the age of 18, please do not submit any
            Personal Data through the Services. We encourage parents and legal
            guardians to monitor their children's Internet usage and to help
            enforce our Privacy Policy by instructing their children never to
            provide Personal Data on the Services without their permission.
          </p>
          <p>
            12.3. If you have reason to believe that a child under the age of 18
            has provided Personal Data to Dusting Down through the Services,
            please contact us, and we will endeavour to delete that information
            from our databases.
          </p>
          <b>13. LIABILITY</b>
          <p>
            3.1. Our liability for losses you suffer as a result of us breaking
            this agreement is strictly limited to invoiced amount of the
            Services you purchased on the previous 3 invoices.
          </p>
          <p>
            13.2. This limitation does not include or limit in any way our
            liability for death or personal injury caused by our negligence,
            fraud or fraudulent misrepresentation, or any matter for which it
            would be illegal for us to exclude, or attempt to exclude, our
            liability.
          </p>
          <p>
            13.3. We are not responsible for indirect losses which happen as a
            side effect of the main loss or damage, including but not limited:
            to loss of income or revenue; loss of business; loss of profits or
            contracts; loss of anticipated savings; loss of data; or, waste of
            management or office time however arising and whether caused by tort
            (including negligence), breach of contract or otherwise.
          </p>
          <p>
            13.4. We will take all reasonable care, in so far as it is in our
            power to do so, to keep the details of your Booking and payment
            secure, but in the absence of negligence on our part we cannot be
            held liable for any loss you may suffer if a third party procures
            unauthorised access to any data you provide when accessing or
            ordering from the Website.
          </p>
          <b>14. EVENTS OUTSIDE OUR CONTROL</b>
          <p>
            14.1. We will not be liable or responsible for any failure to
            perform, or delay in performance of, any of our obligations under a
            Contract that is caused by events outside our reasonable control
            (“Force Majeure Event”).
          </p>
          <p>
            14.2. Each Party is excused from performing its obligations under
            this Agreement to the extent that such performance is prevented by
            an act or event (a "Force Majeure Event") whether or not foreseen,
            that: (i) is beyond the reasonable control of, and is not due to the
            fault or negligence of, such Party, and (ii) could not have been
            avoided by such Party's exercise of due diligence, including, but
            not limited to, a labor controversy, strike, lockout, boycott,
            transportation stoppage, action of a court or public authority,
            fire, flood, earthquake, storm, war, civil strife, terrorist action,
            epidemic, or act of God; provided that a Force Majeure Event will
            not include economic hardship, changes in market conditions, or
            insufficiency of funds. Notwithstanding the foregoing sentence, a
            Force Majeure Event does not excuse any obligation to make any
            payment required by this Agreement.
          </p>
          <p>
            14.3. Our performance under any Contract is deemed to be suspended
            for the period that the Force Majeure Event continues, and we will
            have an extension of time for performance for the duration of that
            period. We will use our reasonable endeavours to bring the Force
            Majeure Event to a close or to find a solution by which our
            obligations under the Contract may be performed despite the Force
            Majeure Event.
          </p>
          <b>15. GENERAL</b>
          <p>15.1. Intellectual property and right to use</p>
          <p>
            15.2. Unless otherwise stated, the copyright and other intellectual
            property rights in the content on the Website are owned by us or our
            licensors. Any access or use of the Website for any reason other
            than your personal, non-commercial use is prohibited.
          </p>
          <p>
            15.3. No part of the Website may be reproduced or stored in any
            other website or included in any public or private electronic
            retrieval system or Website without our prior written permission.
          </p>
          <p>
            15.4. If you publish any content on our Website, such as reviews,
            comments, ratings, profiles or listings, you grant us a worldwide,
            perpetual, non-exclusive, royalty-free licence to copy, alter, adapt
            or display such Content on our Website.
          </p>
          <p>
            15.5. We reserve the right to remove any comments from our social
            pages as well as any comments on paid adverts on any social media
            sites.
          </p>
          <p>
            15.6. All winners for social media competitions are chosen at our
            discretion.
          </p>
          <p>
            15.7. We try our best to reply to any queries on our social media
            sites as quickly as possible between 7am and 7pm.
          </p>
          <p>
            15.8. For any urgent queries outside of these hours, please contact
            us
          </p>
          <b>16. WRITTEN COMMUNICATIONS</b>
          <p>
            16.1. Applicable laws require that some of the information or
            communications we send to you should be in writing.{' '}
          </p>
          <p>
            16.2. When using our site, you accept that communication with us
            will be mainly electronic.{' '}
          </p>
          <p>
            16.3. We will contact you by email with the
            Dustbuster@dustingdown.co.za.
          </p>
          <p>
            16.4. For contractual purposes, you agree to this electronic means
            of communication and you acknowledge that all contracts, notices,
            information and other communications that we provide to you
            electronically comply with any legal requirement that such
            communications be in writing. This condition does not affect your
            statutory rights.
          </p>
          <b>17. NOTICES</b>
          <p>
            All notices given by you to us must be given to Dusting Down PTY
            Ltd, at 2nd Floor SCY House 8 Eileen street Blairgowrie. We may give
            notice to you at either the email or postal address you provide to
            us when making a Booking. Notice will be deemed received and
            properly served immediately when posted on our website, 24 hours
            after an email is sent. It will be sufficient to prove, in the case
            of an email that such email was sent to the specified email address
            of the addressee.
          </p>
          <b>18. TRANSFER OF RIGHTS AND OBLIGATIONS</b>
          <p>
            18.1. The contract between you and us is binding on you and us and
            on our respective successors and assigns.
          </p>
          <p>
            18.2. You may not transfer, assign, charge or otherwise dispose of a
            Contract, or any of your rights or obligations arising under it,
            without our prior written consent.
          </p>
          <p>
            18.3. We may transfer, assign, charge, sub-contract or otherwise
            dispose of a Contract, or any of our rights or obligations arising
            under it, at any time during the term of the Contract.
          </p>
          <b>19. SEVERANCE</b>
          <p>
            if any part of these Terms shall be deemed unlawful, void or for any
            reason unenforceable, then that provision shall be deemed to be
            severable from these Terms and shall not affect the validity and
            enforceability of any of the remaining provisions of these Terms.
          </p>
          <b>20. WAIVER</b>
          <p>
            No waiver by us shall be construed as a waiver of any proceeding or
            succeeding breach of any provision.
          </p>
          <b>21. ENTIRE AGREEMENT AND OR TERMS AND CONDITIONS OF USE</b>
          <p>
            These Terms and any document expressly referred to in them
            constitute the entire agreement between us with respect to the
            subject matter of any contract and supersedes any previous
            communications or agreements between us.
          </p>
          <b>22. THE LAW OF THE LAND</b>
          <p>
            These Terms and the Contracts shall be governed by and construed in
            accordance with the laws of South Africa and you irrevocably submit
            to the exclusive jurisdiction of the courts. Any dispute or claim
            arising out of or in connection with the Contracts or their
            formation (including non-contractual disputes or claims) shall be
            subject to the exclusive jurisdiction of the court.
          </p>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Agreement;
