import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAddress } from '../../../../actions/user';
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Spinner,
} from 'react-bootstrap';
import AddressItem from './AddressItem';

const AddressButton = ({
  getAddress,
  myAddress,
  ChangeAddress,
  user,
  defaultAddress,
}) => {
  useEffect(() => {
    getAddress();
  }, [getAddress]);

  if (myAddress === null) {
    return <Spinner animation="grow" variant="dark" />;
  }
  let address = user.address.split(',');
  let complex = user.complex;
  return (
    <DropdownButton
      as={ButtonGroup}
      title="Change Address"
      variant="outline-info"
      className="float-right"
    >
      <>
        <Dropdown.Item onClick={defaultAddress}>
          {address[0]} {address[1]} {complex && `| ${complex}`}
        </Dropdown.Item>
        {myAddress.length === 0 ? (
          <Dropdown.Item>You only have one address</Dropdown.Item>
        ) : (
          myAddress.map((a) => (
            <AddressItem key={a._id} a={a} ChangeAddress={ChangeAddress} />
          ))
        )}
      </>
    </DropdownButton>
  );
};

const mapStateToProps = (state) => ({
  myAddress: state.user.myAddress,
});

export default connect(mapStateToProps, { getAddress })(AddressButton);
