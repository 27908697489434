import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Alert, Row, Col } from 'react-bootstrap';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';
import { checkCleaners } from '../../../actions/user/order';

const BusinessAddress = ({ business: { isBusiness, business } }) => {
  const [show, setShow] = useState(true);
  const [adr, setAddress] = useState(null);
  const [details, setDetails] = useState(null);
  const [coo, setCoordinates] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (isBusiness) {
      setShow(false);
      setCoordinates(business.coordinates);
      setAddress(business.address);
      setDetails(business.details);
    }
  }, [isBusiness]);

  const where = () => {
    const { lat, lng } = coo;
    let findme = '' + lat + ',' + lng;
    checkCleaners({ findme });
    setShow(false);
  };
  return (
    <div>
      <h5 className="text-center">Which address would youlike to clean?</h5>
      <Alert variant="info">
        <Row>
          <Col className="text-center">
            <p>{adr}</p>
          </Col>
          <Col>
            <Button className="float-right">Address options</Button>
          </Col>
        </Row>
      </Alert>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Please tell us you address...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddressAutocomplete
            value={adr}
            setAddress={setAddress}
            setCoordinates={setCoordinates}
          />
        </Modal.Body>
        <Modal.Footer>
          {adr ? (
            <Button variant="primary" onClick={where} block>
              Submit
            </Button>
          ) : (
            <Button variant="warning" onClick={where} disabled block>
              Enter Address To Continune
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps)(BusinessAddress);
