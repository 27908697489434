import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  GetUnPaidOrders,
  awaitingCleaner,
  doneOrders,
  activeOrders,
  cleanerAllocated,
} from '../../../actions/user/order';
import { myCoupons } from '../../../actions/user/coupons';
import { Alert, Spinner, Row, Col, Button } from 'react-bootstrap';

import OrderItem from './OrderItem';
import Bottom from '../../Layout/Bottom';

const MyOrders = ({
  awaitingCleaner,
  GetUnPaidOrders,
  doneOrders,
  activeOrders,
  cleanerAllocated,
  orders: { allocatedOrders, needCleanerOrders, completedOrders, busyOrders },
}) => {
  const [allocatedCleaner, SetAllocatedCleaner] = useState([]);
  const [waitingCleaner, SetAwaitingCleaner] = useState([]);
  const [completeOrder, SetCompleteOrder] = useState([]);
  const [active, SetActiveOrders] = useState([]);
  useEffect(() => {
    awaitingCleaner();
    doneOrders();
    activeOrders();
    cleanerAllocated();
  }, [
    doneOrders,
    awaitingCleaner,
    GetUnPaidOrders,
    activeOrders,
    cleanerAllocated,
  ]);

  useEffect(() => {
    if (allocatedOrders && needCleanerOrders && completedOrders && busyOrders) {
      SetAllocatedCleaner(allocatedOrders);
      SetAwaitingCleaner(needCleanerOrders);
      SetCompleteOrder(completedOrders);
      SetActiveOrders(busyOrders);
    }
  }, [allocatedOrders, needCleanerOrders, busyOrders, completedOrders]);

  if (
    allocatedOrders === null ||
    busyOrders === null ||
    needCleanerOrders === null ||
    completedOrders === null
  ) {
    return <Spinner animation="grow" variant="dark" />;
  }

  return (
    <div>
      <div className="container">
        <Alert variant="dark">
          My Orders
          <Button variant="danger float-right" size="sm">
            Unpain Orders
          </Button>
        </Alert>
        <Row>
          <Col>
            <div>
              <Alert variant="warning">Awaiting Dust Buster</Alert>
              {waitingCleaner.length === 0
                ? 'No Orders...'
                : waitingCleaner.map((order) => (
                    <OrderItem key={order._id} order={order} />
                  ))}
            </div>
          </Col>
          <Col>
            <div>
              <Alert variant="info">Dust Buster Allocated</Alert>
              {allocatedCleaner.length === 0
                ? 'No Orders...'
                : allocatedCleaner.map((order) => (
                    <OrderItem key={order._id} order={order} />
                  ))}
            </div>
          </Col>
          <Col>
            <div>
              <Alert variant="primary">Acive Orders</Alert>
              {active.length === 0
                ? 'No Orders...'
                : active.map((order) => (
                    <OrderItem key={order._id} order={order} />
                  ))}
            </div>
          </Col>
          <Col>
            <div>
              <Alert variant="success">Completed Orders</Alert>
              {completeOrder.length === 0
                ? 'No Orders...'
                : completeOrder.map((order) => (
                    <OrderItem key={order._id} order={order} />
                  ))}
            </div>
          </Col>
        </Row>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  coupons: state.coupons,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  awaitingCleaner,
  GetUnPaidOrders,
  doneOrders,
  myCoupons,
  activeOrders,
  cleanerAllocated,
})(MyOrders);
