import { useEffect, useState, useRef } from 'react';
import {
  Button,
  Modal,
  Spinner,
  Row,
  Table,
  Col,
  Alert,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchAffiliates } from '../../../../../actions/admin/affiliate';
import ListAffiliates from './ListAffiliates';

const AffiliateCoupon = ({
  affiliates,
  fetchAffiliates,
  coupon,
  fourPer,
  fivePer,
  sixPer,
  sevenPer,
  eightPer,
  expirydate,
  createAffiliateCoupon,
  coupontype,
  note,
}) => {
  useEffect(() => {
    fetchAffiliates();
  }, [fetchAffiliates]);
  const [nte, setMsg] = useState(note);
  const [searchLocal, setSearchLocal] = useState('');
  const [show, setShow] = useState(false);
  const [aflt, setAflt] = useState(null);
  const [error, setError] = useState(null);
  const [typeOfClean, setTypeOfClean] = useState(null);
  let btn = useRef();
  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const jamCoupon = () => {
    if (coupon.length < 5) {
      setError('Enter a valid coupon');
      return setTimeout(() => setError(null), 2000);
    } else if (fourPer === 0) {
      setError('Enter 4 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (fivePer === 0) {
      setError('Enter 5 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sixPer === 0) {
      setError('Enter 6 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sevenPer === 0) {
      setError('Enter 7 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (eightPer === 0) {
      setError('Enter 8 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (typeOfClean === null) {
      setError('Please select type of clean');
      return setTimeout(() => setError(null), 2000);
    } else {
      let affiliate = aflt._id;
      const percArray = {
        fourPer: fourPer,
        fivePer: fivePer,
        sixPer: sixPer,
        sevenPer: sevenPer,
        eightPer: eightPer,
      };
      createAffiliateCoupon({
        expirydate,
        coupon,
        percArray,
        coupontype,
        typeOfClean,
        affiliate,
      });
      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };
  return (
    <>
      {aflt ? (
        <>
          <Row>
            <Col>Creating coupon for {aflt.name}</Col>

            <Col>
              <Button
                variant="outline-warning"
                size="sm"
                onClick={() => setAflt(null)}
              >
                Choose someone else
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Type of clean: </Col>
            <Col>
              <Button
                variant={
                  typeOfClean === 'Once Off' ? 'info' : 'outline-secondary'
                }
                onClick={() => setTypeOfClean('Once Off')}
              >
                Once Off
              </Button>
            </Col>
            <Col>
              <Button
                variant={
                  typeOfClean === 'Reapeat Clean' ? 'info' : 'outline-secondary'
                }
                onClick={() => setTypeOfClean('Reapeat Clean')}
              >
                Repeat Clean
              </Button>
            </Col>
            <Col>
              <Button
                variant={
                  typeOfClean === 'Carpert Clean' ? 'info' : 'outline-secondary'
                }
                onClick={() => setTypeOfClean('Carpert Clean')}
              >
                Carpert Clean
              </Button>
            </Col>
          </Row>
          <hr />
          {error && <Alert variant="warning">{error}</Alert>}
          <Button
            variant="outline-success"
            onClick={() => jamCoupon()}
            ref={btn}
            block
          >
            Create Coupon
          </Button>
        </>
      ) : (
        <>
          <Button className="me-2 mb-2" onClick={() => handleShow(true)}>
            Select Affiliate
          </Button>
          <Modal show={show} size="xl" onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Row>
                  <Col>List of Affiliates</Col>
                  <Col>
                    <span className="float-right">
                      <input
                        className="text-center"
                        placeholder="search by name/email"
                        style={{ border: 'none' }}
                        onChange={(e) => setSearchLocal(e.target.value)}
                      />
                    </span>
                  </Col>
                </Row>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <thead>
                  <th>Affiliate Name</th>
                  <th>Affiliate Email</th>
                  <th>Affiliate Coupon</th>
                  <th>Affiliate Address</th>
                  <th>Select</th>
                </thead>
                <tbody>
                  {affiliates ? (
                    affiliates.length === 0 ? (
                      'No Affiliates'
                    ) : (
                      affiliates
                        .filter((val) => {
                          if (searchLocal === '') {
                            return val;
                          } else if (
                            val.name
                              .toLowerCase()
                              .includes(searchLocal.toLowerCase())
                          ) {
                            return val;
                          } else if (
                            val.email
                              .toLowerCase()
                              .includes(searchLocal.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .map((affiliate) => (
                          <ListAffiliates
                            key={affiliate._id}
                            affiliate={affiliate}
                            setAflt={setAflt}
                          />
                        ))
                    )
                  ) : (
                    <Spinner animation="grow" variant="primary" />
                  )}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  affiliates: state.affiliate.affiliates,
});

export default connect(mapStateToProps, { fetchAffiliates })(AffiliateCoupon);
