import React from 'react';
import Appointment from './Appointment';

const TimeSheet = ({ schedule }) => {
  return schedule.map((sched) => (
    <Appointment key={sched.start} sched={sched} />
  ));
};

export default TimeSheet;
