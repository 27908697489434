import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  createPayslip,
  removePayslipItem,
  updateTotalHours,
} from '../../../../../actions/admin/main';
import moment from 'moment';
import RepeatSlipItem from './RepeatSlipItem';
import { Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap';

const PayslipBookings = ({
  order,
  rate,
  calculateRepeatHours,
  calculateTotalHours,
  currentSlip,
  createPayslip,
  removePayslipItem,
  totalOnceOffHours,
  totalRepeatHours,
  updateTotalHours,
  duster_payslip,
}) => {
  const [init, setInit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [client, setClient] = useState();
  const [id, setId] = useState();
  const [hours, setHours] = useState(0);
  const [expectedHours, setExpectedHours] = useState(0);
  const [pay, setPay] = useState(0);
  const [show, setShow] = useState(false);
  const [checkIn, setCheckIn] = useState();
  const [newCheckIn, setNewCheckIn] = useState(null);
  const [newCheckOut, setNewCheckOut] = useState(null);
  const [checkOut_, setCheckOut] = useState();
  const [approved, setApproved] = useState(false);

  const handleClose = () => {
    setNewCheckIn(null);
    setNewCheckOut(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (checkIn || checkOut_) {
      if (update) {
        const date1 = new Date(checkOut_);
        const date2 = new Date(checkIn);
        const diffTime = Math.abs(date1 - date2);
        const hoursWorked = (diffTime / (1000 * 60 * 60)).toFixed(1);
        updateTotalHours(totalOnceOffHours, hours, hoursWorked);
        setHours(hoursWorked);
        const newPay = (hoursWorked * rate).toFixed(2);
        setPay(newPay);
      }
    }
  }, [
    checkIn,
    checkOut_,
    update,
    hours,
    rate,
    totalOnceOffHours,
    updateTotalHours,
  ]);

  useEffect(() => {
    if (checkIn || checkOut_) {
      if (init === false) {
        const date1 = new Date(checkOut_);
        const date2 = new Date(checkIn);
        const diffTime = Math.abs(date1 - date2);
        const hoursWorked = (diffTime / (1000 * 60 * 60)).toFixed(1);
        setHours(hoursWorked);
        setExpectedHours(hoursWorked);
        calculateTotalHours(parseFloat(hoursWorked));
        const newPay = (hoursWorked * rate).toFixed(2);
        setPay(newPay);
        setInit(true);
      }
    }
  }, [checkIn, checkOut_, calculateTotalHours, rate, init, updateTotalHours]);

  useEffect(() => {
    if (order.schedule === undefined) {
      if (init === false) {
        setCheckIn(order.checkIn);
        setCheckOut(order.checkOut);
        setClient(order.user);
      }
    }
  }, [order, setCheckIn, setCheckOut, init]);

  const schedule = order.schedule;
  const checkOut = order.checkOut;
  const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)];
  const ref = splitAt(5, order._id);

  //update booking time//
  const updateTime = () => {
    if (newCheckIn) {
      let str = checkIn.split('T');

      let date = `${str[0]}T${newCheckIn}:00+02:00`;
      setCheckIn(date);
    }
    if (newCheckOut) {
      let str = checkOut_.split('T');
      let date = `${str[0]}T${newCheckOut}:00+02:00`;
      setCheckOut(date);
    }
    setUpdate(true);
    setShow(false);
  };

  //approve booking//
  const approveMe = () => {
    const id = currentSlip.length + 1;
    setId(id);
    const booking = {
      id: id,
      ref: order._id,
      client: client.name,
      rate: rate,
      checkIn: checkIn,
      checkOut: checkOut_,
      hours: parseFloat(hours),
    };

    const res = createPayslip(booking);
    if (res) {
      setApproved(res);
    } else {
      console.log('Something went wrong');
    }
  };

  //remove booking//
  const removeMe = async () => {
    const res = await removePayslipItem(id);
    if (res === false) {
      setApproved(res);
    } else {
      console.log('Something went wrong');
    }
  };

  if (schedule === undefined) {
    return (
      <>
        <tr>
          <th>{ref[0]}...</th>
          <th>{moment(order.orderTime).format('MMM Do')}</th>
          <th>{client && client.name}</th>
          <th>R{rate}/hr</th>
          <th>{moment(checkIn).format('DD MMM HH:mm')}</th>
          <th>{moment(checkOut_).format('DD MMM HH:mm')}</th>
          <th>{hours}/hrs</th>
          <th>{expectedHours}/hrs</th>
          <th>N/A</th>
          <th>R {pay}</th>
          {duster_payslip === null && (
            <th>
              {approved ? (
                <>
                  <i className="fa-regular fa-square-check float-right text-success"></i>
                  <i
                    className="fa-solid fa-ban float-left text-danger"
                    onClick={removeMe}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    className="fa-regular fa-pen-to-square float-left"
                    onClick={handleShow}
                  ></i>
                  <i
                    className="fa-regular fa-square-check float-right"
                    onClick={approveMe}
                  ></i>
                </>
              )}
            </th>
          )}
        </tr>
        <Modal show={show} onHide={handleClose}>
          <Alert variant="warning">
            Edit checkin and/or checkout times{' '}
            {moment(order.orderTime).format('MMM Do')}
          </Alert>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Label>
                    Current check in time: {moment(checkIn).format('HH:mm')}
                  </Form.Label>
                  <Form.Control
                    placeholder={moment(checkIn).format('HH:mm')}
                    name="newCheckIn"
                    value={newCheckIn}
                    onChange={(e) => setNewCheckIn(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Label>
                    Current check out time: {moment(checkOut_).format('HH:mm')}
                  </Form.Label>
                  <Form.Control
                    placeholder={moment(checkOut_).format('HH:mm')}
                    name="newCheckOut"
                    value={newCheckOut}
                    onChange={(e) => setNewCheckOut(e.target.value)}
                  />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateTime}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        {checkOut.map((check, index) => (
          <RepeatSlipItem
            key={check}
            duster_payslip={duster_payslip}
            currentSlip={currentSlip}
            check={check}
            index={index}
            order={order}
            rate={rate}
            calculateRepeatHours={calculateRepeatHours}
            setUpdate={setUpdate}
            totalRepeatHours={totalRepeatHours}
          />
        ))}
      </>
    );
  }
};

export default connect(null, {
  createPayslip,
  removePayslipItem,
  updateTotalHours,
})(PayslipBookings);
