import {
  SET_USERS,
  CLEAR_USERS,
  SET_USER,
  USER_ORDERS,
  USER_ADDRESS,
  CLEAR_ADMIN_USER,
  RECENT_ORDERS,
  RECENT_REPEAT_ORDERS,
} from '../../actions/types';

const initialState = {
  users: null,
  user: null,
  orders: null,
  recent_orders: null,
  recent_repeat_orders: null,
  address: null,
};

function adminUserReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_USERS:
      return {
        ...state,
        users: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };

    case USER_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case USER_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: null,
        user: null,
      };
    case CLEAR_ADMIN_USER:
      return {
        ...state,
        user: null,
      };
    case RECENT_ORDERS:
      return {
        ...state,
        recent_orders: payload,
      };
    case RECENT_REPEAT_ORDERS:
      return {
        ...state,
        recent_repeat_orders: payload,
      };
    default:
      return state;
  }
}

export default adminUserReducer;
