import axios from 'axios';
import {
  ALL_DUSTERS,
  ALL_USERS,
  ALL_ORDER_TOTAL,
  SET_USER,
  RATE_CARD,
  CLEAR_RATE,
  THE_MOTHER_CARD,
  ALL_INVOICES,
  ORDER_INVOICE,
  INVOICE,
  CLEAR_ORDER_INVOICE,
  PAYING_DUSTERS,
  PAYSLIP,
  CLEAR_PAYSLIP,
  TOTAL_HOURS,
  TOTAL_REPEAT_HOURS,
  MONTH_END_ORDERS,
  CUSTOM_PAYCYLE,
  RECURRING_ORDER_,
  THE_RANGE,
  CREATE_PAYSLIP,
  REMOVE_PAYSLIP_ITEM,
  UPDATE_HOURS,
  UPDATE_REHOURS,
  GET_DUSTER_PAYSLIP,
  EMPLOYEES,
  ALL_REPEAT_ORDERS,
  GET_CARPET_MOTHER,
  GLOBAL_DUSTER_RATING,
} from '../types';

import { setNote } from '../note';

export const numberDusters = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/all-dusters');
    dispatch({ type: ALL_DUSTERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const numberUsers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/all-users');
    dispatch({ type: ALL_USERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const ordersTotal = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/all-order-total');
    dispatch({ type: ALL_ORDER_TOTAL, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const monthlyAcc =
  ({ data, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ data, userId });
    try {
      const res = await axios.put(
        '/api/admin/main/toggle-monthly',
        body,
        config
      );
      dispatch({ type: SET_USER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////
//Get The MotherCard//
//////////////////////
export const theMotherCard = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/the-mother-card');
    dispatch({ type: THE_MOTHER_CARD, payload: res.data });
  } catch (error) {
    console.errror(error.message);
  }
};

//Edit The Mother Card//
export const editMotherCard =
  ({
    motherCard,
    four,
    fourp,
    five,
    fivep,
    six,
    sixp,
    seven,
    sevenp,
    eight,
    nine,
    ten,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
      nine,
      ten,
    });
    try {
      const res = await axios.put(
        `/api/admin/main/the-mother-card/${motherCard}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(theMotherCard());
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////////
///Create Custom Rate/////
//////////////////////////
export const customRate =
  ({ four, five, six, seven, eight, nine, ten, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      four,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      userId,
    });
    try {
      const res = await axios.post(
        '/api/admin/main/custom-rate-card',
        body,
        config
      );

      dispatch({ type: SET_USER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////////
//Edit User Rate Card////
/////////////////////////
export const editRateCard =
  ({ four, five, six, seven, eight, nine, ten, rateCard }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      four,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      rateCard,
    });
    try {
      const res = await axios.put(
        `/api/admin/main/user-rate-card/${rateCard}`,
        body,
        config
      );
      dispatch({ type: SET_USER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////////
///Get Users Rate Card///
/////////////////////////
export const userRate = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_RATE });
    const res = await axios.get(`/api/admin/main/user-rate-card/${userId}`);
    dispatch({ type: RATE_CARD, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
//Create Carpet mother//
//////////////////////
export const createCarpetMother =
  ({
    mainBed,
    bed,
    longPassage,
    shortPassage,
    staircases,
    landing,
    famRoom,
    lounge,
    diningRoom,
    study,
    dressingRoom,
    otherRoom,
    smallRug,
    medRug,
    lgRug,
    tubChair,
    wingBack,
    oneSeaterSofa,
    twoSeaterSofa,
    threeSeaterSofa,
    elShapedThree,
    elShapedFour,
    elShapedFive,
    singleMattress,
    doubleMattress,
    queenMattress,
    kingMattress,
    smallOttoman,
    mediumOttoman,
    largeOttoman,
    chairBase,
    chairEverything,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      mainBed,
      bed,
      longPassage,
      shortPassage,
      staircases,
      landing,
      famRoom,
      lounge,
      diningRoom,
      study,
      dressingRoom,
      otherRoom,
      smallRug,
      medRug,
      lgRug,
      tubChair,
      wingBack,
      oneSeaterSofa,
      twoSeaterSofa,
      threeSeaterSofa,
      elShapedThree,
      elShapedFour,
      elShapedFive,
      singleMattress,
      doubleMattress,
      queenMattress,
      kingMattress,
      smallOttoman,
      mediumOttoman,
      largeOttoman,
      chairBase,
      chairEverything,
    });
    try {
      const res = await axios.post(
        '/api/admin/main/create-carpet-mother',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(getCarpetMother());
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////
//get Carpet moter//
////////////////////
export const getCarpetMother = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/get-carpet-mother');
    dispatch({ type: GET_CARPET_MOTHER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const editCarpetMother =
  ({
    mainBed,
    bed,
    longPassage,
    shortPassage,
    staircases,
    landing,
    famRoom,
    lounge,
    diningRoom,
    study,
    dressingRoom,
    otherRoom,
    smallRug,
    medRug,
    lgRug,
    tubChair,
    wingBack,
    oneSeaterSofa,
    twoSeaterSofa,
    threeSeaterSofa,
    elShapedthree,
    elShapedFour,
    elShapedFive,
    singleMattress,
    doubleMattress,
    queenMattress,
    kingMattress,
    smallOttoman,
    mediumOttoman,
    largeOttoman,
    chairBase,
    chairEverything,
    carpetId,
  }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({
        mainBed,
        bed,
        longPassage,
        shortPassage,
        staircases,
        landing,
        famRoom,
        lounge,
        diningRoom,
        study,
        dressingRoom,
        otherRoom,
        smallRug,
        medRug,
        lgRug,
        tubChair,
        wingBack,
        oneSeaterSofa,
        twoSeaterSofa,
        threeSeaterSofa,
        elShapedthree,
        elShapedFour,
        elShapedFive,
        singleMattress,
        doubleMattress,
        queenMattress,
        kingMattress,
        smallOttoman,
        mediumOttoman,
        largeOttoman,
        chairBase,
        chairEverything,
      });

      const res = await axios.put(
        `/api/admin/main/edit-carpet-mother/${carpetId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(getCarpetMother());
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////////////
//Get Global Duster Rating//
////////////////////////////
export const getGlobalDusterRating = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/get-global-duster-rate');
    dispatch({ type: GLOBAL_DUSTER_RATING, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////
//Create Global Duste Rating//
//////////////////////////////
export const createGlobalDusterRate =
  ({ oneStar, twoStar, threeStar, fourStar, fiveStar }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      oneStar,
      twoStar,
      threeStar,
      fourStar,
      fiveStar,
    });
    try {
      const res = await axios.post(
        '/api/admin/main/create-global-duster-rate',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch(getGlobalDusterRating());
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////////////
//Edit Global Duster Rating//
/////////////////////////////
export const editGlobalDusterRating =
  ({ oneStar, twoStar, threeStar, fourStar, fiveStar, ratingId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      oneStar,
      twoStar,
      threeStar,
      fourStar,
      fiveStar,
    });
    try {
      const res = await axios.put(
        `/api/admin/main/edit-global-duster-rate/${ratingId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getGlobalDusterRating());
    } catch (error) {
      console.error(error.message);
    }
  };
/////////////////////
//get  all invoices//
/////////////////////
export const fetchInvoices = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/invoices');
    dispatch({ type: ALL_INVOICES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Order Invoice//
export const orderInvoice = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_ORDER_INVOICE });
  try {
    const res = await axios.get(`/api/admin/main/order-invoice/${orderId}`);
    dispatch({ type: ORDER_INVOICE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Invoice//
export const getInvoice = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/main/invoice/${orderId}`);
    dispatch({ type: INVOICE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get dusters to be paid this cycle//
export const payingDusters = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/paying-dusters');

    dispatch({ type: PAYING_DUSTERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get duster Buster Payslip//
export const DusterPayslip = (dusterId) => async (dispatch) => {
  dispatch({ type: CLEAR_PAYSLIP });
  try {
    const res = await axios.get(`/api/admin/main/payslip/${dusterId}`);
    dispatch({ type: PAYSLIP, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get custom payslip//
export const CustomPaySlip =
  ({ start, end, dusterId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const body = JSON.stringify({ start, end });
      const res = await axios.put(
        `/api/admin/main/custom-payslip/${dusterId}`,
        body,
        config
      );
      console.log(res.body);
    } catch (error) {
      console.error(error.message);
    }
  };

//Calculate total for one time cleans//
export const calculateTotalHours = (num) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_HOURS, payload: num });
  } catch (error) {
    console.error(error.message);
  }
};

//update total hours//
export const updateTotalHours =
  (totalOnceOffHours, hours, hoursWorked) => (dispatch) => {
    try {
      let loot =
        totalOnceOffHours - parseFloat(hours) + parseFloat(hoursWorked);
      dispatch({ type: UPDATE_HOURS, payload: loot });
    } catch (error) {
      console.error(error.message);
    }
  };

//update total repeat hours//
export const updateTotalReHours =
  (totalRepeatHours, hours, hoursWorked) => (dispatch) => {
    try {
      let loot = totalRepeatHours - parseFloat(hours) + parseFloat(hoursWorked);
      dispatch({ type: UPDATE_REHOURS, payload: loot });
    } catch (error) {
      console.error(error.message);
    }
  };

//Calculate total for repeat cleans//
export const calculateRepeatHours = (num) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_REPEAT_HOURS, payload: num });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Orders on account//
export const ordersOnAccount = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/orders-on-account');
    dispatch({ type: MONTH_END_ORDERS, payload: res.data });
  } catch (error) {
    console.log(error.message);
  }
};

//Get custom pay range//
export const customPayRange =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ startDate, endDate });
    const data = { startDate, endDate };
    try {
      const res = await axios.put(
        '/api/admin/main/custom-pay-cycle',
        body,
        config
      );
      dispatch({ type: CUSTOM_PAYCYLE, payload: res.data });
      dispatch({ type: THE_RANGE, payload: data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Create Payslip item for duster//
export const createPayslip = (payslip) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PAYSLIP, payload: payslip });
    return true;
  } catch (error) {
    console.error(error.message);
  }
};

//Remove payslip item//
export const removePayslipItem = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_PAYSLIP_ITEM, payload: id });
    return false;
  } catch (error) {
    console.error(error.message);
  }
};

//Find a cleaners  payslip//
export const getDusterPayslip = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/main/get-duster-payslip/${dusterId}`
    );
    dispatch({ type: GET_DUSTER_PAYSLIP, payload: res.data });
  } catch (error) {
    console.error(error.meesage);
  }
};

//Create a payslip for a Duster//
export const createDusterPayslip =
  ({ currentSlip, today, dusterId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ today, currentSlip });
    try {
      const res = await axios.post(
        `/api/admin/main/create-duster-payslip/${dusterId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch({ type: GET_DUSTER_PAYSLIP, payload: res.data.payslip });
    } catch (error) {
      console.error(error.message);
    }
  };

//recurring order management//
export const recurringOrder = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/recurring-order-management');
    dispatch({ type: RECURRING_ORDER_, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch all repreat orders//
export const fetchAllReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/fetch-all-repeat-orders');
    dispatch({ type: ALL_REPEAT_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//run recurring function//
export const runRepeatFunctions = () => async (dispatch) => {
  try {
    const res = await axios.put('/api/admin/main/run-recurring-function');

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(recurringOrder());
  } catch (error) {
    console.error(error.message);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};

//Fetch employees//
///////////////////
export const fetchEmployees = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/fetch-employees');

    dispatch({ type: EMPLOYEES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Set Employee department//
///////////////////////////
export const setDepartment =
  ({ adminId, department }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ department });
      const res = await axios.put(
        `/api/admin/main/employee-department/${adminId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(fetchEmployees());
    } catch (error) {
      console.error(error.message);
    }
  };
