import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  Form,
  InputGroup,
  Button,
  Alert,
  ListGroup,
  Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  fetchQuery,
  setupQuerySocket,
  fetchQueryTexts,
  fetchTheCleaner,
  closeQuery,
  claimQuery,
} from '../../../actions/admin/admin_queries';
import QueryTexts from './QueryTexts';

const OrderQuery = ({
  admin_queries: { query, socket, texts, duster },
  fetchQuery,
  setupQuerySocket,
  fetchQueryTexts,
  admin: { _id, name },
  fetchTheCleaner,
  closeQuery,
  note,
  claimQuery,
}) => {
  const [text, setText] = useState('');
  const [qtxt, setQtxt] = useState([]);
  const [order, setOrder] = useState(null);
  const [user, setUser] = useState(null);
  const [userTel, setUserUserTel] = useState(null);
  const [cleaner, setCleaner] = useState(false);
  const [checkOut, setCheckout] = useState(false);
  const [dusterComing, setDusterComing] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [dusterId, setDusterId] = useState(null);
  const [checkIn, setCheckin] = useState(false);
  const [show, setShow] = useState(false);
  const [queryComplete, setQueryComplete] = useState(null);
  const [orderLink, setOrderLink] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { queryId } = useParams();
  const navigate = useNavigate();
  const bottomRef = useRef(null);

  useEffect(() => {
    if (texts) {
      setQtxt(texts);
    }
  }, [texts]);
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);

  useEffect(() => {
    if (qtxt) {
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  useEffect(() => {
    fetchQuery(queryId);
    setupQuerySocket(queryId);
    fetchQueryTexts(queryId);
  }, [fetchQuery, setupQuerySocket, fetchQueryTexts]);

  useEffect(() => {
    if (query) {
      setUser(query.user.local);
      setUserUserTel(query.user.cellphone);
      setQueryComplete(query.completed);

      if (query.order) {
        let orderId = query.order._id;
        setOrder(query.order);
        setOrderLink(`/admin/order-management/once-off/order/${orderId}`);
        if (query.order.team) {
          setCleaner(true);
        }
      }

      if (query.reorder) {
        let orderId = query.reorder._id;
        setOrder(query.reorder);
        setOrderLink(`/admin/order-management/repeat-orders/order/${orderId}`);
        if (query.reorder.team) {
          setCleaner(true);
        }
      }
    }
  }, [query]);

  useEffect(() => {
    if (user && query) {
      let admin = query.assigned;
      let deviceToken = query.user.deviceToken;
      if (admin) {
        setAdmin(query.assigned);
      } else {
        let queryId = query._id;
        let email = user.email;
        let name = user.name;
        claimQuery({ queryId, deviceToken, email, name });
      }
    }
  }, [user, query, claimQuery]);

  useEffect(() => {
    if (order) {
      setCheckin(order.checkIn);
      setCheckout(order.checkOut);
      setDusterComing(order.onMyWay);
    }
  }, [order]);

  useEffect(() => {
    if (cleaner) {
      let dusterId = order.team;
      setDusterId(order.team);
      fetchTheCleaner(dusterId);
    }
  }, [cleaner, fetchTheCleaner]);

  useEffect(() => {
    if (socket) {
      socket.on('sendMsg', (msg) => {
        setQtxt((qtxt) => [...qtxt, msg]);
      });
    }
  }, [socket]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [qtxt]);

  if (query === null || socket === null || texts === null || order === null)
    return <Spinner variant="primary" animation="grow" />;

  const onSubmit = async (e) => {
    e.preventDefault();
    const id = uuidv4();
    const sentBy = _id;
    const sentByName = name;
    const timestamp = moment().format();
    const txt = {
      _id: id,
      query: query._id,
      sentBy: sentBy,
      sentByName: sentByName,
      text: text,
      timestamp: timestamp,
    };

    setQtxt((qtxt) => [...qtxt, txt]);
    socket.emit('sendMsg', {
      query: query._id,
      sentBy: sentBy,
      sentByName: sentByName,
      text: text,
      timestamp: timestamp,
    });
    setText('');
  };

  const navAway = () => {
    const id = queryId;
    socket.emit('leave_query', { queryId: id });
    socket.disconnect();
    navigate(-1);
  };

  const weDoneHere = () => {
    let deviceToken = query.user.deviceToken;
    let name = user.name;
    let email = user.email;
    let queryId = query._id;
    closeQuery({ queryId, deviceToken, name, email });
  };

  let time = moment(order.orderTime).format('ddd MMM D, h:mm a');

  return (
    <Container>
      <Row>
        <Col>
          <Alert variant="info text-center">
            <Button
              variant="outline-info float-left"
              size="sm"
              onClick={navAway}
            >
              <i className="fa-solid fa-angle-left"></i>
            </Button>
            Order info
          </Alert>
          <ListGroup.Item>
            User Name: {user && user.name} {user && user.surname} - {userTel}
            <Button
              variant="outline-info float-right"
              size="sm"
              as={Link}
              to={orderLink}
            >
              Go to order
            </Button>
          </ListGroup.Item>
          <ListGroup.Item>
            Cleaner Name:
            {cleaner
              ? `${duster && duster.name} ${duster && duster.surname} - ${
                  duster && duster.cellphone
                }`
              : 'Not Assigned'}
            {cleaner && (
              <Button
                variant="outline-info float-right"
                size="sm"
                as={Link}
                to={`/admin/team-member/${dusterId}`}
              >
                Go to cleaner
              </Button>
            )}
          </ListGroup.Item>
          <ListGroup.Item>
            Order Address: {order && order.address}
          </ListGroup.Item>
          <ListGroup.Item>Order Time: {time}</ListGroup.Item>
          <ListGroup.Item>
            Order Status:
            {checkOut
              ? ' Order Complete'
              : checkIn
              ? ' Clean In Progress'
              : dusterComing
              ? ' Duster on their way'
              : ' Nothing has been done here...'}
          </ListGroup.Item>
        </Col>
        <Col className="text-center">
          <Card style={{ width: '30rem' }} xs={12} md={6}>
            <Form onSubmit={onSubmit}>
              <Card.Header className="bg-light text-info" align="center">
                {query.headline}
                {queryComplete ? (
                  <Button size="sm" variant="success float-right">
                    Complete
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    variant="outline-success float-right"
                    onClick={handleShow}
                  >
                    Close
                  </Button>
                )}
              </Card.Header>
              <ListGroup.Item>Query Description: {query.desc}</ListGroup.Item>

              <Card.Body style={{ height: '450px', overflowY: 'auto' }}>
                {qtxt.length === 0
                  ? 'Send your first message...'
                  : qtxt.map((text) => (
                      <QueryTexts key={text._id} text={text} adminId={_id} />
                    ))}
                <div ref={bottomRef}></div>
              </Card.Body>

              <hr />
              <Form.Group className="m-2">
                <InputGroup>
                  <Form.Control
                    required
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Type Text"
                  />
                  <InputGroup.Append>
                    <Button variant="info text-white" type="submit">
                      Send<i className="far fa-paper-plane ml-2"></i>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">
          Are you sure you want to close this query
        </Alert>
        <Modal.Body>
          Make sure the query is resolved before you click Yes!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={weDoneHere}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_queries: state.admin_queries,
  admin: state.admin.admin,
  note: state.note,
});
export default connect(mapStateToProps, {
  fetchQuery,
  setupQuerySocket,
  fetchQueryTexts,
  fetchTheCleaner,
  closeQuery,
  claimQuery,
})(OrderQuery);
