import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Spinner } from 'react-bootstrap';
import { fromEmail, myInt } from '../../../actions/team';
import moment from 'moment';
import './SignUp.css';
import { useParams } from 'react-router-dom';

const CompleteApplication = ({
  fromEmail,

  team: { team, int },
  myInt,
}) => {
  const { dusterId } = useParams();
  useEffect(() => {
    fromEmail(dusterId);
  }, [fromEmail, dusterId]);

  useEffect(() => {
    if (team) {
      let dusterId = team._id;
      myInt(dusterId);
    }
  }, [team, myInt]);

  if (team === null || int === null)
    return <Spinner variant="primary" animation="grow" />;

  let time = moment(int.start).format('ddd MMM D, h:mm a');

  return (
    <div className="container" align="center" id="signup">
      <Card bg="info">
        <Card.Header className="text-white">
          Welcome <b>{team.name}</b>
        </Card.Header>
        <Card.Body>
          <Card.Title className="text-white">
            Congratulations on filling out your application
          </Card.Title>

          <Card.Text className="text-white">
            The last step is to come in for an interveiw on <b>{time}</b>.
            Please rememeber to bring your supporting documentation with you to
            the interview
            <hr />
            We have sent an email to your address with confirmation of your
            interview appointment
            <hr />
            Address: Unit 7, 355 Oak Avenue, Ferndale, Randburg
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-white">
          We look forward to welcoming you to our team!
        </Card.Footer>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.team,
});

export default connect(mapStateToProps, { fromEmail, myInt })(
  CompleteApplication
);
