import React, { useEffect, useState } from 'react';
import { Container, Alert, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { verifyBusiness } from '../../../actions/business';

const VerifyBusiness = ({ note, verifyBusiness }) => {
  const [result, setResult] = useState(null);
  const { token } = useParams();
  useEffect(() => {
    verifyBusiness(token);
  }, [token, verifyBusiness]);

  useEffect(() => {
    if (note.length > 0) {
      setResult(note[0]);
    }
  }, [note, setResult]);

  if (result === null) return <Spinner animation="grow" variant="primary" />;
  const noteType = result.noteType;
  const msg = result.msg;
  return (
    <Container>
      <Alert variant={noteType === 'danger' ? 'danger' : 'info'}>{msg}</Alert>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { verifyBusiness })(VerifyBusiness);
