import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { profileOrders } from '../../../../actions/user/order';
import {
  getCoupon,
  setCouponTotal,
  clearCoupon,
  enterCoupon,
  confirmCouponUse,
} from '../../../../actions/user/coupons';
import CouponItem from './CouponItem';
import moment from 'moment';

const Coupon = ({
  getCoupon,
  coupons: {
    coupon,
    couponTotal,
    couponError,
    optionalTotal,
    optionalCouponId,
  },
  bookings: { invoice, regInvoice },
  setCouponTotal,
  SetCoupon,
  clearCoupon,
  profileOrders,
  orders,
  enterCoupon,
  invoiceHours,
  setCouponUsed,
  confirmCouponUse,
}) => {
  const [show, setShow] = useState(false);
  const [couponId, SetCouponId] = useState('');
  const [percentage, SetPercentage] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [nya, setNya] = useState(null);
  const [secondClean, setSecondClean] = useState(null);
  const [couponValid, setCouponValid] = useState(null);

  useEffect(() => {
    if (orders && orders.length >= 2) {
      setSecondClean(true);
    } else {
      setSecondClean(false);
    }
  }, [orders, secondClean]);
  useEffect(() => {
    profileOrders();
  }, [profileOrders]);
  useEffect(() => {
    if (couponId.length > 0) {
      getCoupon(couponId);
    }
  }, [getCoupon, couponId]);

  useEffect(() => {
    if (coupon && secondClean !== null) {
      let purse = coupon.percArray;
      let ed = moment(coupon.expirydate);
      let today = moment();
      let promoType = coupon.promoType;
      if (promoType === 'Sign Up') {
        let whichClean = coupon.whichClean;

        if (secondClean === false && whichClean === 'Second Clean') {
          setCouponValid(false);
          setNya(true);
        } else {
          let result = ed.isAfter(today);
          if (result) {
            if (invoiceHours <= 4.9) {
              SetPercentage(purse.fourPer);
            } else if (invoiceHours <= 5.9) {
              SetPercentage(purse.fivePer);
            } else if (invoiceHours <= 6.9) {
              SetPercentage(purse.sixPer);
            } else if (invoiceHours >= 7.9) {
              SetPercentage(purse.sevenPer);
            } else if (invoiceHours <= 8.9) {
              SetPercentage(purse.eightPer);
            }

            setExpiryDate(coupon.expirydate);
            setCouponValid(result);
          } else {
            setCouponValid(result);
          }
        }
      } else {
        let result = ed.isAfter(today);
        if (result) {
          if (invoiceHours <= 4.9) {
            SetPercentage(purse.fourPer);
          } else if (invoiceHours <= 5.9) {
            SetPercentage(purse.fivePer);
          } else if (invoiceHours <= 6.9) {
            SetPercentage(purse.sixPer);
          } else if (invoiceHours >= 7.9) {
            SetPercentage(purse.sevenPer);
          } else if (invoiceHours <= 8.9) {
            SetPercentage(purse.eightPer);
          }

          setExpiryDate(coupon.expirydate);
          setCouponValid(result);
        } else {
          setCouponValid(result);
        }
      }
    } else {
      SetPercentage(null);
      setExpiryDate(null);
    }
  }, [SetPercentage, coupon, invoiceHours, secondClean, setNya]);

  useEffect(() => {
    if (percentage) {
      if (percentage === 100) {
        return setCouponTotal(0);
      }
      let price = parseFloat(invoice);
      let fp = parseFloat(`.${percentage}`);
      let calcTotal = (price - price * fp).toFixed(2);
      setCouponTotal(calcTotal);
    }
  }, [percentage, invoice, setCouponTotal]);

  const handleClose = () => {
    SetCouponId('');
    setNya(null);
    setShow(false);
  };
  const handleShow = () => {
    clearCoupon();
    setShow(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    SetCoupon(couponId);
    SetCouponId('');
    setShow(false);
  };

  const submitCoupon = async () => {
    let coupon = couponCode;
    enterCoupon({
      invoiceHours,
      invoice,
      coupon,
      secondClean,
    });
  };

  const useCoupon = () => {
    setCouponTotal(optionalTotal);
    setCouponUsed(optionalCouponId);
    confirmCouponUse(optionalCouponId);
    SetCouponId('');
    setCouponCode('');
    setShow(false);
  };
  return (
    <div className="mb-2">
      <Button variant="info" onClick={handleShow} block>
        I have a coupon!
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={onSubmit}>
          <Alert variant="info">
            <h4 className="text-center">Enter / Select Coupon</h4>
          </Alert>
          <Modal.Body>
            <div className="input-field">
              <select
                name="coupon"
                value={couponId}
                className="browser-default"
                style={{ width: '100%' }}
                onChange={(e) => SetCouponId(e.target.value)}
              >
                <option value="" disabled>
                  Select Coupon
                </option>
                <CouponItem />
              </select>
            </div>

            {nya ? (
              <div className="text-center text-info">
                <b>This coupon will only be available on your second clean</b>
              </div>
            ) : couponValid ? (
              <div className="text-center text-info">
                <b>
                  This coupon will give you {percentage}% discount it will make
                  your current total R{couponTotal}, Coupon is valid until{' '}
                  {moment(expiryDate).format('ll')}
                </b>
              </div>
            ) : (
              <p className="text-center">
                Select a coupon to view the discount
              </p>
            )}

            <Form.Group>
              <Form.Label>Or enter coupon below</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Coupon"
                name="coupon"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </Form.Group>
            {couponError && <Alert variant="danger">{couponError}</Alert>}
            {optionalTotal && (
              <Alert variant="success">
                The new price for your order is; R{optionalTotal}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Dismiss
            </Button>
            {nya ? (
              <Button variant="warning" disabled>
                Coupon unavailable...
              </Button>
            ) : couponCode.length > 0 ? (
              optionalTotal ? (
                <Button variant="success" onClick={useCoupon}>
                  Use Coupon R{optionalTotal}
                </Button>
              ) : (
                <Button variant="success" onClick={submitCoupon}>
                  Submit Coupon
                </Button>
              )
            ) : couponValid ? (
              <Button variant="primary" type="submit">
                Use Coupon
              </Button>
            ) : (
              <Button variant="danger">Coupon no longer valid</Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  coupons: state.coupons,
  bookings: state.bookings,
  orders: state.orders.myOrders,
});

export default connect(mapStateToProps, {
  getCoupon,
  setCouponTotal,
  clearCoupon,
  profileOrders,
  enterCoupon,
  confirmCouponUse,
})(Coupon);
