import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const AppPeachPayment = () => {
  const [payload, setPayload] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    setPayload(id);
  }, [id]);
  useEffect(() => {
    if (payload) {
      const script = document.createElement('script');
      script.src = `https://${process.env.REACT_APP_PEACH_BASE_URL}/v1/paymentWidgets.js?checkoutId=${payload}`;
      document.body.append(script);

      window.scrollTo(0, 0);
    }
  }, [payload]);

  if (payload === null) {
    return (
      <div className="text-center">
        <h4>Preparing Payment...</h4>
        <Spinner animation="border" variant="primary" size="lg" />
      </div>
    );
  }
  return (
    <div>
      <form
        action={`${process.env.REACT_APP_BASE_URL}payments/card-response/`}
        className="paymentWidgets"
        data-brands="VISA MASTER AMEX"
      ></form>
    </div>
  );
};

export default AppPeachPayment;
