import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { assignCleaner } from '../../../actions/admin/orders';
import moment from 'moment';

const Cleaner = ({
  ac,
  orderId,
  assignCleaner,
  orderTime,
  setLoading,
  invoiceHours,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const assignToOrder = () => {
    let dusterId = ac._id;
    let start = moment(orderTime).format();
    let end = moment(start).add(invoiceHours, 'hours').format();

    assignCleaner({ orderId, dusterId, start, end });
    setLoading(true);
    setShow(false);
  };

  return (
    <>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <b>Dust Buster:</b> {ac.name} {ac.surname}
          <Button
            variant="outline-secondary float-right"
            size="sm"
            onClick={handleShow}
          >
            Assign to Order
          </Button>
        </ListGroup.Item>
      </ListGroup>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign {ac.name} to the order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> Are you sure you want to assign {ac.name} to this order; </p>
          <p>
            Details; {ac.cellphone} | {ac.email}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            No
          </Button>
          <Button variant="success" onClick={assignToOrder}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, { assignCleaner })(Cleaner);
