import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const SuspendDuster = ({ dustBuster, blacklistDuster, susBtn }) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState('');

  const handleClose = () => {
    setReason('');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const addToBlack = () => {
    blacklistDuster({ reason });
  };
  return (
    <>
      <Button
        variant="outline-danger ml-2"
        className="float-right"
        size="sm"
        onClick={handleShow}
      >
        <i className="fa-solid fa-ban"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Black list {dustBuster.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            name="reason"
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Why are you blacklisting the duster...?"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          {reason.length > 4 ? (
            <Button variant="danger" onClick={addToBlack} ref={susBtn}>
              Blacklist Duster <i className="fas fa-trash"></i>
            </Button>
          ) : (
            <Button variant="light" disabled>
              Type reason
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuspendDuster;
