import React from 'react';

import { Col, ListGroup } from 'react-bootstrap';

const RedFlags = ({ flag }) => {
  return (
    <Col>
      <ListGroup.Item>
        {flag.auth} : {flag.text}
      </ListGroup.Item>
    </Col>
  );
};

export default RedFlags;
