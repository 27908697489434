import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { cellphoneDetails } from '../../../actions/user';

const Cellphone = ({ cellphone, cellphoneDetails }) => {
  const [show, setShow] = useState(false);
  const [cell, setCell] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (cellphone) {
      setCell(cellphone);
    }
  }, [cellphone]);

  const cellInfo = () => {
    cellphoneDetails({ cell });
    setShow(false);
  };

  return (
    <>
      <Button variant="warning float-right" onClick={handleShow} size="sm">
        Edit/Add
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Cellphone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Cellphone Number:</Form.Label>
            <Form.Control
              type="number"
              placeholder={cell}
              name="cell"
              value={cell}
              onChange={(e) => setCell(e.target.value)}
            />
            <Form.Text className="text-muted">
              Please enter your cellphone number
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          <Button variant="primary" onClick={cellInfo}>
            Update Cellphone
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, { cellphoneDetails })(Cellphone);
