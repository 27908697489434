import axios from 'axios';
import {
  TEAM_LOADED,
  TEAM_REGISTERED,
  TEAM_FAIL,
  TEAM_LOGIN,
  TEAM_LOGOUT,
  CLEAR_TEAM,
  CLEAR_PROFILE,
  CLEAR_SKILLS,
  APPLICATION,
  HR_APP,
  CLEAR_APP,
  MY_INT,
} from '../types';

import setUserToken from '../../utils/setUserToken';
import { setNote } from '../note';
import { myTeamProfile } from './team_profile';
import { getSkills } from './team_skills';

//Current Team Member//
export const teamMember = () => async (dispatch) => {
  if (localStorage.token) {
    setUserToken(localStorage.token);
  }
  try {
    const res = await axios.get('/api/team');
    dispatch({ type: TEAM_LOADED, payload: res.data });
    dispatch(myTeamProfile());
    dispatch(getSkills());
  } catch (error) {
    dispatch({ type: TEAM_FAIL });
  }
};

//HR Calender//
export const hr =
  ({ date }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_APP });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ date });
    try {
      const res = await axios.put('/api/team/hr-calendar', body, config);
      dispatch({ type: HR_APP, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Step One//
export const stepOne = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/team/step-one', body, config);
    dispatch({ type: APPLICATION, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};

//Step Two//
export const stepTwo = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/team/step-two', body, config);
    dispatch({ type: APPLICATION, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};

//Step Three//
export const stepThree = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/team/step-three', body, config);
    dispatch({ type: APPLICATION, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};

//Step Three NO DOCS//
export const stepThreeNoDocs = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/team/step-three-no-docs', body, config);
    dispatch({ type: APPLICATION, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//fetch application on email click//
export const fromEmail = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/team/continue-app/${dusterId}`);
    dispatch({ type: APPLICATION, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//My interview//
export const myInt = (dusterId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/team/my-interview/${dusterId}`);
    dispatch({ type: MY_INT, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Verify Team member account//
export const verifyMember = (token) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(`/api/team/verify/${token}`, token, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch({ type: TEAM_REGISTERED });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: TEAM_FAIL, payload: errors });
  }
};

//Log in the member//
export const loginMember = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    dispatch({ type: CLEAR_TEAM });
    const res = await axios.post('/api/team/signin', body, config);
    dispatch({ type: TEAM_LOGIN, payload: res.data });
    dispatch(teamMember());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: TEAM_FAIL, payload: errors });
  }
};

//Request Password Reset//
export const forgotPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post('/api/team/forgot-password', email, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setNote(error.msg, 'danger'));
      });
    }
  }
};

//Reset User Password//
export const resetPassword =
  ({ newPassword, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ newPassword, token });
    try {
      const res = await axios.post(
        `/api/team/reset-password/${token}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Set the team member as local or foreign//
export const setLocal = (citizen) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    await axios.put('/api/team/south-african/', citizen, config);
    dispatch(teamMember());
  } catch (error) {
    console.error(error.message);
  }
};

//Logout Member//
export const teamLogout = () => (dispatch) => {
  dispatch({ type: TEAM_LOGOUT });
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: CLEAR_SKILLS });
};
