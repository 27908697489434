import React, { useEffect, useState, useRef } from 'react';
import {
  Spinner,
  Row,
  Col,
  Alert,
  ListGroup,
  Card,
  Button,
  Modal,
} from 'react-bootstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  GetOrder,
  deleteOrder,
  fetchDuster,
  cleanDuster,
  fetchOrderComments,
  createComment,
} from '../../../actions/user/order';
import { orderQueries } from '../../../actions/user/query';
import { peachPayment } from '../../../actions/user/payment';
import { fetchOrderReview } from '../../../actions/user/review';
import { getInvoice } from '../../../actions/user/invoice';
import moment from 'moment';
import ExtraItem from '../Booking/Payments/ExtraItem';
import Coupon from '../Booking/Payments/Coupon';
import Bottom from '../../Layout/Bottom';
import DeleteOrder from './DeleteOrder';
import ReviewDuster from './ReviewDuster';
import EditReview from './EditReview';
//import ChangeTheDate from './ChangeTheDate';
import AddComment from './AddComment';
import CreateAQuery from './CreateAQuery';
import CancelMyOrder from './CancelMyOrder';

const Order = ({
  GetOrder,
  orders: { order, bookedCleaner, paymentUrl, peachLoad, comments },
  deleteOrder,
  note,
  fetchDuster,
  cleanDuster,
  review,
  fetchOrderReview,
  peachPayment,
  fetchOrderComments,
  createComment,
  getInvoice,
  invoice,
  queries,
  orderQueries,
}) => {
  const { orderId } = useParams();
  useEffect(() => {
    GetOrder(orderId);
    fetchOrderReview(orderId);
    fetchOrderComments(orderId);
    getInvoice(orderId);
    orderQueries(orderId);
  }, [
    GetOrder,
    fetchOrderReview,
    fetchOrderComments,
    orderQueries,
    getInvoice,
    orderId,
  ]);
  const [nte, setMsg] = useState(note);
  const [show, setShow] = useState(false);
  const [mrx, SetMrx] = useState([]);
  const [it, SetIT] = useState(null);
  const [bed, SetBed] = useState('');
  const [bath, SetBath] = useState('');
  const [inv, SetIV] = useState('');
  const [invoiceHours, SetIH] = useState('');
  const [code, SetCode] = useState('');
  const [address, SetAdress] = useState('');
  const [complex, SetComplex] = useState(null);
  const [assigned, SetAssigned] = useState(null);
  const [remove, setRemove] = useState(false);
  const [allowedToCancel, setAllowedToCancel] = useState(false);
  const [cancelRequestSent, setCancelRequestSent] = useState(false);
  const [complete, SetComplete] = useState(null);
  const [orderReview, setOrderReview] = useState(null);
  const [checkInTime, SetCheckInTime] = useState(null);
  const [inProgress, setInProgress] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [paymentRef, setPaymentRef] = useState(null);

  useEffect(() => {
    if (it) {
      const today = moment();
      const closingDate = moment(it)
        .subtract(1, 'days')
        .set('hours', 12)
        .set('minute', 0);

      const diff = today.diff(closingDate, 'minutes');

      if (diff < 0) {
        setAllowedToCancel(true);
      } else {
        setAllowedToCancel(false);
      }
    }
  }, [it]);

  useEffect(() => {
    if (order) {
      SetMrx(order.mrx);
      SetIT(order.orderTime);
      SetBed(order.bedroom);
      SetBath(order.bathroom);
      SetIV(order.invoice.toFixed(2));
      SetIH(order.invoiceHours);
      SetCode(order.code);
      SetAdress(order.address);
      SetComplex(order.complex);
      SetAssigned(order.team);
      SetComplete(order.checkOut);
      SetCheckInTime(order.checkIn);
      setMonthly(order.monthEnd);
      setPaymentRef(order.paymentRef);
      if (order.cancelRequest) {
        setCancelRequestSent(true);
      } else {
        setCancelRequestSent(false);
      }
    }
  }, [SetMrx, SetIT, order]);

  const navigate = useNavigate();
  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  });

  useEffect(() => {
    if (peachLoad) {
      const script = document.createElement('script');
      script.src = `https://${process.env.REACT_APP_PEACH_BASE_URL}/v1/paymentWidgets.js?checkoutId=${peachLoad.id}`;
      document.body.append(script);

      window.scrollTo(0, 0);
      setShow(true);
    }
  }, [peachLoad]);

  useEffect(() => {
    if (note && delBtn.current) {
      setMsg(note);
      delBtn.current.removeAttribute('disabled');
      navigate('/user/my-profile');
    }
  }, [note, nte, setMsg, navigate]);

  useEffect(() => {
    if (complete === undefined && checkInTime !== undefined) {
      setInProgress(true);
    }
  }, [checkInTime, complete]);

  useEffect(() => {
    if (complete) {
      setInProgress(null);
    }
  }, [complete]);

  useEffect(() => {
    if (it) {
      let tomorrow = moment().add(1, 'day').local().format();
      if (tomorrow < it) {
        setRemove(true);
      }
    }
    return () => {
      setRemove(false);
    };
  }, [it, assigned]);

  useEffect(() => {
    if (assigned) {
      let dusterId = assigned;
      fetchDuster(dusterId);
    }
    return () => {
      cleanDuster(null);
    };
  }, [assigned, fetchDuster, cleanDuster]);

  useEffect(() => {
    if (review) {
      setOrderReview(review);
    }
    return () => {
      setOrderReview(null);
    };
  }, [review]);

  // let btn = useRef();
  let peachBtn = useRef();
  let delBtn = useRef();

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  const invoiceTime = moment(it).format('ddd MMM D, h:mm a');

  if (order === null || comments === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  const delOrder = () => {
    deleteOrder(orderId);
    if (delBtn.current) {
      delBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  // const ozow = () => {
  //   let _id = orderId;
  //   let invoice = inv;

  //   ozowPay({ _id, invoice });
  //   if (btn.current) {
  //     btn.current.setAttribute('disabled', 'disabled');
  //   }
  // };

  const createPeach = () => {
    let amount = parseFloat(inv).toFixed(2);
    peachPayment({ amount, orderId });
    if (peachBtn.current) {
      peachBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const handleClose = () => {
    setShow(false);
    peachBtn.current.removeAttribute('disabled');
  };

  const inTime = moment(checkInTime).format('ddd MMM D, h:mm a');
  const outTime = moment(complete).format('ddd MMM D, h:mm a');
  const where = address.split(',');

  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        autoFocus={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form
            action={`${process.env.REACT_APP_BASE_URL}payments/card-response/`}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </Modal.Body>
      </Modal>
      <div className="container">
        <Row>
          <Col className="mt-3">
            <Alert variant="primary">
              <h5>
                Thank you for your order
                {!code ? (
                  <DeleteOrder delOrder={delOrder} delBtn={delBtn} />
                ) : invoice ? (
                  <>
                    <Button
                      as={Link}
                      to={`/user/my-order-invoice/${orderId}`}
                      variant="info float-right"
                      size="sm"
                    >
                      Invoice
                    </Button>
                  </>
                ) : (
                  ''
                )}
              </h5>
            </Alert>
            <ListGroup variant="flush" className="mt-2">
              <ListGroup.Item variant="info">
                {invoiceHours}hr Order Summary!{' '}
                <p className="float-right">Date: {invoiceTime}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                {bed} Bedroom/s {bath} Bathroom/s{' '}
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div
                        className="float-right"
                        key={x.id}
                        style={{ width: '20rem' }}
                      >
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                Address:
                <p className="float-right">
                  {where[0]} {where[1]} {complex && `| ${complex}`}
                </p>
              </ListGroup.Item>
              <ListGroup.Item variant="warning">
                Invoice Total; <b className="float-right">R{inv}</b>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <ChangeTheDate />
              </ListGroup.Item> */}
            </ListGroup>

            {allowedToCancel ? (
              cancelRequestSent ? (
                <div className="text-center">
                  <Button variant="warning mt-2">Cancel request sent...</Button>
                </div>
              ) : (
                <CancelMyOrder />
              )
            ) : (
              ''
            )}

            <CreateAQuery orderId={orderId} />
            {queries && queries.length === 0 ? (
              <p className="text-center mt-2">No queries</p>
            ) : (
              queries.map((query) => (
                <Button
                  variant="outline-warning mt-2"
                  key={query._id}
                  as={Link}
                  to={`/user/order-query/${query._id}`}
                  block
                >
                  {query.headline}
                </Button>
              ))
            )}

            <AddComment
              createComment={createComment}
              orderId={orderId}
              note={note}
            />
            {comments && comments.length === 0 ? (
              <p className="text-center mt-2">No instructions</p>
            ) : (
              comments.map((comment) => (
                <ListGroup.Item className="mt-2" key={comment._id}>
                  {comment.text}
                </ListGroup.Item>
              ))
            )}
          </Col>
          <Col align="center">
            {code ? (
              <>
                {monthly && paymentRef === undefined && (
                  <Card style={{ width: '20rem', marginBottom: '5%' }}>
                    <Alert variant="info">Make payment for your order</Alert>
                    <Card.Body>
                      <b>***Please Note***</b>
                      <p>
                        You will be re-directed when you select a payment
                        method.
                      </p>

                      {/* <Button variant="success" onClick={ozow} ref={btn} block>
                        Ozow Instant EFT R{inv}
                        <i className="fas fa-cash-register float-right"></i>
                      </Button> */}
                      <Button
                        variant="success mt-2"
                        onClick={createPeach}
                        ref={peachBtn}
                        block
                      >
                        Secure Card Payment R{inv}
                      </Button>
                    </Card.Body>
                  </Card>
                )}
                <Card style={{ width: '20rem' }}>
                  <Card.Img variant="top" src={code} />
                  <Card.Body>
                    <Card.Title className="text-center">
                      Please download the qr code
                    </Card.Title>
                    <Button variant="primary" onClick={downloadCode}>
                      Download!
                    </Button>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <Card style={{ width: '20rem' }}>
                <Alert variant="danger">Your payment failed!</Alert>
                <Card.Body>
                  <Card.Title className="text-center">
                    <Alert variant="warning">Please try pay again</Alert>
                  </Card.Title>
                  <Coupon />
                  <hr />
                  <b>***Please Note***</b>
                  <p>
                    You will be re-directed when you select a payment method.
                  </p>
                  {/* <Button variant="success" onClick={ozow} ref={btn} block>
                    Ozow Instant EFT R{inv}
                    <i className="fas fa-cash-register float-right"></i>
                  </Button> */}
                  <Button
                    variant="success mt-2"
                    onClick={createPeach}
                    ref={peachBtn}
                    block
                  >
                    Secure Card Payment R{inv}
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
          {bookedCleaner ? (
            <Col>
              <Alert variant="info">
                Assigned Duster
                <i className="fas fa-user-check float-right"></i>
              </Alert>
              <ListGroup>
                <ListGroup.Item>
                  <b> Duster Name:</b>
                  <p className="float-right">
                    {bookedCleaner.name} {bookedCleaner.surname}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Star Rating: </b>
                  <p className="float-right">3 Star Duster</p>
                </ListGroup.Item>
                {checkInTime ? (
                  <>
                    <ListGroup.Item>
                      <b>Check in time:</b>
                      <p className="float-right">{inTime}</p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Check out time:</b>

                      <p className="float-right">
                        {inProgress ? (
                          <Button variant="warning" size="sm">
                            Cleaning In Progress...
                          </Button>
                        ) : (
                          <>{outTime}</>
                        )}
                      </p>
                    </ListGroup.Item>
                  </>
                ) : (
                  <Button variant="info" disabled block>
                    Dust Buster has not arrived yet
                  </Button>
                )}
              </ListGroup>
              {orderReview ? (
                <EditReview
                  orderReview={orderReview}
                  bookedCleaner={bookedCleaner}
                />
              ) : complete ? (
                <ReviewDuster bookedCleaner={bookedCleaner} orderId={orderId} />
              ) : (
                ''
              )}
            </Col>
          ) : (
            <Col>
              <Alert variant="warning text-center">Cleaner not assigned</Alert>
            </Col>
          )}
        </Row>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  note: state.note,
  review: state.user_reviews.review,
  invoice: state.invoice.invoice,
  queries: state.user_queries.queries,
});

export default connect(mapStateToProps, {
  GetOrder,
  deleteOrder,
  fetchDuster,
  cleanDuster,
  fetchOrderReview,
  peachPayment,
  fetchOrderComments,
  createComment,
  getInvoice,
  orderQueries,
})(Order);
