import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fetchCleanerRating } from '../../../../actions/admin/team_members';

const TeamItem = ({
  team: { name, surname, email, _id, cellphone },
  fetchCleanerRating,
}) => {
  const [star, setStar] = useState(0);
  useEffect(() => {
    async function fetchStar() {
      const star = await fetchCleanerRating(_id);
      setStar(star);
    }
    fetchStar();
  }, [fetchCleanerRating, _id]);
  return (
    <tr>
      <td>{name}</td>
      <td>{surname}</td>
      <td>{cellphone}</td>
      <td>{email}</td>
      <td>
        <i
          className="fa-solid fa-star"
          style={{
            color: star >= 1 ? 'yellowGreen' : '',
          }}
        ></i>
        <i
          className="fa-solid fa-star"
          style={{
            color: star >= 2 ? 'yellowGreen' : '',
          }}
        ></i>
        <i
          className="fa-solid fa-star"
          style={{
            color: star >= 3 ? 'yellowGreen' : '',
          }}
        ></i>
        <i
          className="fa-solid fa-star"
          style={{
            color: star >= 4 ? 'yellowGreen' : '',
          }}
        ></i>
        <i
          className="fa-solid fa-star"
          style={{
            color: star === 5 ? 'yellowGreen' : '',
          }}
        ></i>
      </td>
      <td>
        <Button
          as={Link}
          to={`/admin/team-member/${_id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  rating: state,
});

export default connect(mapStateToProps, { fetchCleanerRating })(TeamItem);
