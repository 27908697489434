import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MyCarpertCleanItem = ({ carpert_clean }) => {
  const [paid, setPaid] = useState(false);
  const [complete, setcomplete] = useState(false);
  const [address, setAddress] = useState('');
  useEffect(() => {
    let address = carpert_clean.address.split(',');
    setAddress(address[0]);

    if (carpert_clean.code !== undefined) {
      setPaid(true);
    } else {
      setPaid(false);
    }
    if (carpert_clean.checkOut === undefined) {
      setcomplete(false);
    } else {
      setcomplete(true);
    }
  }, [carpert_clean, setPaid]);
  return (
    <tr>
      <td>{carpert_clean.whatToDo.length} Cleaning items</td>
      <td>{moment(carpert_clean.orderDate).format('MMM Do YY')}</td>
      <td>{address}</td>
      <td>{paid ? 'Paid' : 'Not paid'}</td>
      <td>{complete ? 'Complete' : 'Incomplete'}</td>
      <td>
        <Button
          as={Link}
          to={`/user/my-carpert-clean/${carpert_clean._id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

export default MyCarpertCleanItem;
