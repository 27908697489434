import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateFloorData } from '../../../../../actions/admin/office_booking';
import { Form } from 'react-bootstrap';

const Floor = ({ floor, removeFloor, updateFloorData }) => {
  const [floorData, setFloorData] = useState({
    id: floor.id,
    hardFloor: floor.hardFloor,
    softFloor: floor.softFloor,
    toilets: floor.toilets,
    corridors: floor.corridors,
    total: floor.total,
  });
  useEffect(() => {
    if (floorData) {
      const jam =
        floorData.hardFloor +
        floorData.softFloor +
        floorData.toilets +
        floorData.corridors;
      floorData.total = jam;
    }
  }, [floorData]);

  const { id, hardFloor, softFloor, toilets, corridors } = floorData;
  const onChange = (e) =>
    setFloorData({ ...floorData, [e.target.name]: parseInt(e.target.value) });

  updateFloorData(floorData);
  return (
    <tr>
      <td>{floor.Floor}</td>
      <td>
        <Form.Control
          type="number"
          placeholder="sqm"
          name="hardFloor"
          value={hardFloor}
          onChange={(e) => onChange(e)}
        />
      </td>
      <td>
        <Form.Control
          type="number"
          placeholder="sqm"
          name="softFloor"
          value={softFloor}
          onChange={(e) => onChange(e)}
        />
      </td>
      <td>
        <Form.Control
          type="number"
          placeholder="sqm"
          name="toilets"
          value={toilets}
          onChange={(e) => onChange(e)}
        />
      </td>
      <td>
        <Form.Control
          type="number"
          placeholder="sqm"
          name="corridors"
          value={corridors}
          onChange={(e) => onChange(e)}
        />
      </td>
      <td>
        {id !== 0 && (
          <i
            className="fa-solid fa-minus"
            onClick={() => removeFloor(floor.id)}
          ></i>
        )}
      </td>
    </tr>
  );
};

export default connect(null, { updateFloorData })(Floor);
