import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import QrReader from 'react-qr-reader';

const QRScanner = ({ checkIn, currentOrder }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const QRScanError = (error) => {
    console.log('Hello: ', error);
  };

  const QRScanSuccess = (result) => {
    if (result) {
      let qrOrderId = result;
      let orderId = currentOrder;

      checkIn({ qrOrderId, orderId });
      setShow(false);
    }
  };
  return (
    <div className="mb-3">
      <Button variant="primary" onClick={handleShow}>
        Scan QR Code To Check In
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>QR Code Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            delay={300}
            style={{ width: '100%' }}
            onError={QRScanError}
            onScan={QRScanSuccess}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(null)(QRScanner);
