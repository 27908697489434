import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { fromEmail } from '../../../actions/team';

const EmailApply = ({ fromEmail, duster }) => {
  const { dusterId } = useParams();
  useEffect(() => {
    fromEmail(dusterId);
  }, [fromEmail, dusterId]);

  if (duster === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  if (duster) {
    return <Navigate to="/team/signup" />;
  }
};

const mapStateToProps = (state) => ({
  duster: state.team.team,
});

export default connect(mapStateToProps, { fromEmail })(EmailApply);
