import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';
import moment from 'moment';

const OrderItems = ({ order }) => {
  let time = moment(order.orderTime).format('ddd MMM D, h:mm a');
  let address = order.address.split(',');
  return (
    <ListGroup.Item>
      Address: {address[0]} {address[1]} {order.complex && `| ${order.complex}`}{' '}
      Order Time {time}
      <Button
        variant="outline-primary float-right"
        to={`/admin/order-management/once-off/order/${order._id}`}
        as={Link}
        size="sm"
      >
        View Order
      </Button>
    </ListGroup.Item>
  );
};

export default OrderItems;
