import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';

const IntCalender = ({ int, whoInterview, setIntDate }) => {
  const dusterInt = (e) => {
    let date = moment(e.event._instance.range.start)
      .format()
      .split('T')
      .shift();
    setIntDate(date);
    whoInterview(e.event._def.extendedProps.dusterId);
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={int}
        displayEventEnd="true"
        eventClick={dusterInt}
      />
    </>
  );
};

export default IntCalender;
