import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchInvoices } from '../../../../../actions/admin/main';
import { Spinner, Container, Col, Row, Button, Table } from 'react-bootstrap';
import InvoiceItem from './InvoiceItem';

const Invoices = ({ fetchInvoices, inv }) => {
  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  if (inv === null) return <Spinner animation="grow" variant="primary" />;
  return (
    <Container>
      <Row>
        <Col>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>
          <h4>All Invoices</h4>
        </Col>
        <Col>
          <Button variant="outline-secondary float-right" size="sm">
            {inv.num} Invoice/s
          </Button>
        </Col>
      </Row>

      <hr />
      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>Client name</th>
            <th>Order address</th>
            <th>Order status</th>
            <th>Duster</th>
            <th>Invoice date</th>
            <th>Invoice amount</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {inv.invoices.map((invoice) => (
            <InvoiceItem key={invoice._id} invoice={invoice} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  inv: state.main.invoices,
});

export default connect(mapStateToProps, { fetchInvoices })(Invoices);
