import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { myCoupons } from '../../../../actions/user/coupons';

const CouponItem = ({ coupons: { coupons, loading }, myCoupons }) => {
  useEffect(() => {
    myCoupons();
  }, [myCoupons]);
  return (
    !loading &&
    coupons !== null &&
    coupons.map((c) => (
      <option key={c._id} value={`${c._id}`}>
        {c.coupon}
      </option>
    ))
  );
};
const mapStateToProps = (state) => ({
  coupons: state.coupons,
});
export default connect(mapStateToProps, { myCoupons })(CouponItem);
