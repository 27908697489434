import axios from 'axios';
import {
  USER_LOADED,
  CLEAR_USER,
  USER_REGISTERED,
  USER_FAIL,
  USER_LOGIN,
  USER_LOGOUT,
  MY_ADDRESSES,
  CLEAR_ADDRESSES,
  MY_RATE_CARD,
  MOTHER_CARD,
  BUSINESS_LOGOUT,
} from '../types';

import setUserToken from '../../utils/setUserToken';
import { setNote } from '../note';
import { signInBusiness } from '../business';

//Find Current User//
export const currentUser = () => async (dispatch) => {
  if (localStorage.token) {
    setUserToken(localStorage.token);
  }
  dispatch({ type: CLEAR_USER });
  try {
    const res = await axios.get('/api/user');
    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (error) {
    dispatch({ type: USER_FAIL });
  }
};

//Create user account//
export const signupUser = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const email = formData.email;
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/user/signup', body, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch({ type: USER_REGISTERED, payload: email });
    return true;
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setNote(error.msg, 'danger'));
      });
    }
    dispatch({ type: USER_FAIL, payload: errors });
  }
};

//Friend Sign up//
export const signUpFriend =
  ({
    name,
    surname,
    address,
    cellphone,
    complex,
    coordinates,
    email,
    password,
    userId,
    addressType,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      name,
      surname,
      address,
      cellphone,
      complex,
      coordinates,
      email,
      password,
      addressType,
    });
    try {
      const res = await axios.post(
        `/api/user/friend-signup/${userId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch({ type: USER_REGISTERED, payload: email });
      return true;
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
      dispatch({ type: USER_FAIL, payload: errors });
    }
  };

//Promo Sign Up//
export const promoCouponSignUp = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const email = formData.email;
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post(
      '/api/user/promo-coupon-sign-up',
      body,
      config
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch({ type: USER_REGISTERED, payload: email });
    return true;
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setNote(error.msg, 'danger'));
      });
    }
    dispatch({ type: USER_FAIL, payload: errors });
  }
};

//Promo Sign up//
export const duster51signUp =
  ({
    name,
    surname,
    address,
    cellphone,
    complex,
    coordinates,
    email,
    password,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      name,
      surname,
      address,
      cellphone,
      complex,
      coordinates,
      email,
      password,
    });
    try {
      const res = await axios.post(`/api/user/promo-signup`, body, config);
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch({ type: USER_REGISTERED, payload: email });
      return true;
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
      dispatch({ type: USER_FAIL, payload: errors });
    }
  };

//Verify user account//
export const verifyUser = (token) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(`/api/user/verify/${token}`, token, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch({ type: USER_REGISTERED });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: USER_FAIL, payload: errors });
  }
};

//Log in the user//
export const signinUser = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/user/login', body, config);
    dispatch({ type: USER_LOGIN, payload: res.data });
    dispatch(currentUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        if (error.msg === 'Invalid Credentials') {
          dispatch(signInBusiness(formData));
        } else {
          dispatch(setNote(error.msg, 'danger'));
        }
      });
    }
    dispatch({ type: USER_FAIL, payload: errors });
  }
};

//////////////////////////////////
//Resend email verfication email//
//////////////////////////////////
export const resendEmailVerification = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(email);

  try {
    const res = await axios.post(
      '/api/user/resend-email-verification',
      body,
      config
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

//I veriefied//
export const emailIsVerified = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(email);
  try {
    const res = await axios.put('/api/user/email-is-verified', body, config);
    dispatch({ type: USER_LOGIN, payload: res.data });
    dispatch(currentUser());
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
//The Mother  Card//
////////////////////
export const getMother = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/the-mother-rate');
    dispatch({ type: MOTHER_CARD, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
////My Custom Rate///
/////////////////////
export const fetchMyRate = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/my-rate-card/${userId}`);
    dispatch({ type: MY_RATE_CARD, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Add Update CellPhone//
export const cellphoneDetails = (cell) => async (dispatch) => {
  try {
    const res = await axios.put('/api/user/my-cell-phone', cell);

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(currentUser());
  } catch (error) {
    console.error(error.message);
  }
};

//Update my address//
export const updateMyAddress =
  ({ text, coordinates, complex, addressType, addressId, main }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      text,
      coordinates,
      complex,
      addressType,
      addressId,
      main,
    });
    try {
      const res = await axios.put('/api/user/update-my-address', body, config);

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

export const updateComplex = (complex) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(complex);
  try {
    const res = await axios.put('/api/user/complex-update', body, config);

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(currentUser());
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch Addresses//
export const getAddress = () => async (dispatch) => {
  dispatch({ type: CLEAR_ADDRESSES });

  try {
    const res = await axios.get('/api/user/fetch-address');
    dispatch({ type: MY_ADDRESSES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Add An Address
export const newAddress =
  ({ text, coordinates }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_ADDRESSES });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ text, coordinates });

    try {
      const res = await axios.post('/api/user/create-address', body, config);
      dispatch({ type: MY_ADDRESSES, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

// Add complex to address//
export const addComplex =
  ({ adrId, complex }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_ADDRESSES });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ adrId, complex });
    try {
      const res = await axios.put('/api/user/address/complex', body, config);
      console.log(res.data);
      dispatch({ type: MY_ADDRESSES, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

// Remove complex
export const removeComplex = (adrId) => async (dispatch) => {
  dispatch({ type: CLEAR_ADDRESSES });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ adrId });
  try {
    const res = await axios.put(
      '/api/user/address/remove-complex',
      body,
      config
    );
    console.log(res.data);
    dispatch({ type: MY_ADDRESSES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Delete Address//
export const deleteAddress = (adrId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/user/address/delete/${adrId}`);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(getAddress());
  } catch (error) {
    console.error(error.message);
  }
};

//Request password reset///
export const forgotUserPassword =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email });
    try {
      const res = await axios.post('/api/user/forgot', body, config);
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Reset Password//
export const userPasswordReset =
  ({ token, newPassword }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ token, newPassword });
    try {
      const res = await axios.post(
        `/api/user/reset-password/${token}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Invite a friends//
export const inviteFriend =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email });

    try {
      const res = await axios.post('/api/user/invite-a-friend', body, config);
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Change Email//
export const changeEmail =
  ({ userId, newEmail }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ newEmail });

    try {
      const res = await axios.put(
        `/api/user/verify-new-email/${userId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };
//Logout Member//
export const userLogout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: BUSINESS_LOGOUT });
};
