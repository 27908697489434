import React from 'react';
import Bottom from '../Layout/Bottom';

const EndOfTenancy = () => {
  return (
    <div>
      <div className="container">
        <div>
          <h3 className="text-center">
            Effortless End of Tenancy Cleaning with Dusting Down's App and
            Website
          </h3>
          <div>
            <p>
              <b>1. Streamlined Booking Process:</b> Experience the convenience
              of Dusting Down's app and website when booking your end of tenancy
              cleaning. Our user-friendly platform allows you to schedule the
              cleaning service at your preferred date and time, all with just a
              few taps or clicks.
            </p>
            <p>
              <b>2. Tailored Cleaning Services:</b> Dusting Down understands the
              unique requirements of end of tenancy cleaning. Through our app
              and website, you can customize your cleaning checklist, specifying
              the areas and tasks that need special attention. Our platform
              ensures that your specific needs are met.
            </p>
            <p>
              <b>3. Trusted and Experienced Professionals:</b> With Dusting
              Down, you have access to a network of trusted and experienced
              cleaning professionals specializing in end of tenancy cleaning.
              Our app and website connect you with reliable cleaners who are
              skilled in delivering top-quality results.
            </p>
            <p>
              <b>4. Thorough and Meticulous Cleaning:</b> Our dedicated cleaning
              team follows a comprehensive approach, meticulously cleaning every
              nook and cranny of the property. Dusting Down's platform empowers
              them with the necessary tools and supplies to ensure a thorough
              and efficient end of tenancy cleaning.
            </p>
            <p>
              <b>5. Convenient Communication:</b> Dusting Down's app and website
              are designed to save you time and alleviate the stress of end of
              tenancy cleaning. You can easily manage the entire process online,
              eliminating the need for numerous phone calls or coordinating with
              multiple parties.
            </p>
            <p>
              <b>6. Time-saving and Stress-free:</b> Stay connected and informed
              throughout the entire process with Dusting Down's app and website.
              From real-time updates on the cleaning progress to seamless
              communication with your assigned cleaner, our platform enables
              convenient and transparent interactions.
            </p>
            <p>
              <b>7. Reliable and Secure:</b> Rest assured that Dusting Down
              prioritizes your safety and security. Our platform ensures that
              all cleaning professionals are vetted and screened, providing you
              with peace of mind. Your personal information and payment details
              are handled securely and confidentially.
            </p>
            <hr />
          </div>
        </div>
      </div>
      <div className="container">
        <p>
          <b>Discover the Convenience:</b> Dusting Down's app and website
          revolutionize the way you access end of tenancy cleaning services.
          Enjoy the convenience of effortless bookings, tailored cleaning
          services, and seamless communication. Experience a stress-free and
          convenient end of tenancy cleaning process with Dusting Down, right at
          your fingertips.
        </p>
      </div>
      <Bottom />
    </div>
  );
};

export default EndOfTenancy;
