import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

const Schedule = ({ sched, dayToChange, toChange }) => {
  let start = moment(sched.start).format('ddd MMM D');
  return (
    <>
      <Button
        variant={toChange === sched.start ? 'primary' : 'outline-primary'}
        block
        value={sched.start}
        onClick={(e) => dayToChange(e.target.value)}
      >
        {start}
      </Button>
    </>
  );
};

export default Schedule;
