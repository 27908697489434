import React, { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  levelOneNum,
  levelTwoNum,
  levelThreeNum,
  readyToWorkNum,
  getDailyNum,
  intCalendar,
  whoInterview,
  nullDuster,
} from '../../../../actions/admin/team_members';
import {
  Col,
  Row,
  Alert,
  Card,
  Button,
  Spinner,
  Modal,
  Container,
} from 'react-bootstrap';
import moment from 'moment';
import IntCalender from './IntCalender';
import FlushStageOne from './FlushStageOne';
import FlushStageTwo from './FlushStageTwo';
import DeleteOldBooking from './DeleteOldBooking';

const AdminDusters = ({
  isAdmin,
  readyToWorkNum,
  levelOneNum,
  levelTwoNum,
  levelThreeNum,
  intCalendar,
  whoInterview,
  nullDuster,
  getDailyNum,
  team_members: {
    stageOneNumber,
    stageTwoNumber,
    stageThreeNumber,
    dailyNum,
    readyNumber,
    interviews,
    dustBuster,
  },
  note,
}) => {
  const [int, setInt] = useState(null);
  const [show, setShow] = useState(false);
  const [intDate, setIntDate] = useState(null);
  const [missed, setMissed] = useState(false);
  const [name, setName] = useState(null);
  const [rtw, setRtw] = useState(null);
  const [dusterId, setDusterId] = useState(null);
  const handleClose = () => {
    nullDuster(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (intDate === null) {
      nullDuster(null);
    }
  }, [intDate, nullDuster]);
  useEffect(() => {
    if (dustBuster) {
      setDusterId(dustBuster._id);
      setName(dustBuster.name);
      setRtw(dustBuster.readyToWork);
      handleShow();
    } else {
      handleClose();
    }
  }, [dustBuster]);

  useEffect(() => {
    if (intDate) {
      let rightNow = moment().format().split('T').shift();
      if (intDate < rightNow) {
        if (rtw === false) {
          setMissed(true);
        } else {
          setMissed(false);
        }
      } else {
        setMissed('Future Interview');
      }
    }
  }, [intDate, rtw]);

  useEffect(() => {
    levelOneNum();
    levelTwoNum();
    levelThreeNum();
    readyToWorkNum();
    intCalendar();
    getDailyNum();
  }, [
    levelOneNum,
    levelTwoNum,
    levelThreeNum,
    readyToWorkNum,
    intCalendar,
    getDailyNum,
  ]);

  useEffect(() => {
    if (interviews) {
      const a = interviews.map((int) => {
        let c = Date.parse(int.start);
        return { title: 'interview', start: c, dusterId: int.team };
      });
      setInt(a);
    }
  }, [interviews]);

  if (!isAdmin) {
    return <Navigate to="/admin/signin" />;
  }

  if (int === null) {
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  }

  return (
    <>
      <div className="container" align="center">
        <Row>
          <Col>
            <h4>Dust Duster Dashboard</h4>
          </Col>
          <Col>
            <DeleteOldBooking note={note} />
            <Button
              size="sm"
              className="float-right"
              variant="outline-secondary mr-2"
              as={Link}
              to="/admin/team/blacklist"
            >
              Blacklist
            </Button>
            <Button
              size="sm"
              className="float-right"
              variant="outline-primary mr-2"
              as={Link}
              to="/admin/add-new-duster"
            >
              Add Duster
            </Button>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{ fontSize: '3em' }}
                    className="float-left mr-2 text-danger"
                  >
                    <i className="fas fa-envelope"></i>
                  </span>
                  Step 1
                </Card.Title>
                <Card.Subtitle className="text-muted">
                  {stageOneNumber === null ? (
                    <Spinner animation="grow" variant="primary" />
                  ) : (
                    <b className="display-4">{stageOneNumber}</b>
                  )}
                </Card.Subtitle>
                <Card.Text>Dust Busters in Step 1</Card.Text>
                <Button
                  as={Link}
                  to="/admin/team/stage-one"
                  variant="outline-secondary"
                >
                  View Dust Busters
                </Button>
                <FlushStageOne note={note} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{ fontSize: '3em' }}
                    className="float-left mr-2 text-warning"
                  >
                    <i className="fas fa-info-circle"></i>
                  </span>
                  Step 2
                </Card.Title>
                <Card.Subtitle className=" text-muted">
                  {stageTwoNumber === null ? (
                    <Spinner variant="primary" animation="grow" />
                  ) : (
                    <b className="display-4">{stageTwoNumber}</b>
                  )}
                </Card.Subtitle>
                <Card.Text>Dust Busters in Step 2</Card.Text>
                <Button
                  as={Link}
                  to="/admin/team/stage-two"
                  variant="outline-secondary"
                >
                  View Duster Busters
                </Button>

                <FlushStageTwo note={note} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{ fontSize: '3em' }}
                    className="float-left mr-2 text-secondary"
                  >
                    <i className="fas fa-calendar-alt"></i>
                  </span>
                  Step 3
                </Card.Title>
                <Card.Subtitle className="text-muted">
                  {stageThreeNumber === null ? (
                    <Spinner variant="primary" animation="grow" />
                  ) : (
                    <b className="display-4">{stageThreeNumber}</b>
                  )}
                </Card.Subtitle>
                <Card.Text>Dust Busters in Step 3</Card.Text>
                <Button
                  as={Link}
                  to="/admin/team/stage-three"
                  variant="outline-secondary"
                >
                  View Duster Busters
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{ fontSize: '3em' }}
                    className="float-left mr-2 text-info"
                  >
                    <i className="fa-solid fa-broom"></i>
                  </span>
                  Step 4
                </Card.Title>
                <Card.Subtitle className="text-muted">
                  {dailyNum === null ? (
                    <Spinner variant="primary" animation="grow" />
                  ) : (
                    <b className="display-4">{dailyNum}</b>
                  )}
                </Card.Subtitle>
                <Card.Text>Daily Dusters</Card.Text>
                <Button
                  as={Link}
                  to="/admin/team/daily-dusters"
                  variant="outline-secondary"
                >
                  View Duster Busters
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{ fontSize: '3em' }}
                    className="float-left mr-2 text-success"
                  >
                    <i className="fas fa-user-check"></i>
                  </span>
                  Working
                </Card.Title>
                <Card.Subtitle className="text-muted">
                  <b className="display-4">{readyNumber}</b>
                </Card.Subtitle>
                <Card.Text>Dust Busters working</Card.Text>
                <Button
                  as={Link}
                  to="/admin/team/ready-to-work"
                  variant="outline-secondary"
                >
                  View Duster Busters
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4 mt-4">
          <Col></Col>
          <Col md={6}>
            <Alert variant="secondary">Interview Calender</Alert>
            <IntCalender
              int={int}
              whoInterview={whoInterview}
              setIntDate={setIntDate}
            />
          </Col>
          <Col></Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Interview for {name} | {intDate}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {missed === 'Future Interview' ? (
              <Button variant="info" disabled>
                Future Interview
              </Button>
            ) : missed === true ? (
              <Button variant="danger" disabled>
                Cleaner Missed the interview
              </Button>
            ) : (
              <Button variant="success" disabled>
                Cleaner Arrived for interview
              </Button>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              as={Link}
              to={`/admin/team-member/${dusterId}`}
              onClick={handleClose}
            >
              View Duster
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.admin.isAdmin,
  team_members: state.team_members,
  note: state.note,
});

export default connect(mapStateToProps, {
  levelOneNum,
  levelTwoNum,
  levelThreeNum,
  readyToWorkNum,
  intCalendar,
  whoInterview,
  getDailyNum,
  nullDuster,
})(AdminDusters);
