import React from 'react';
import bedafter from '../../../../images/BedAfter.png';
import bedbefore from '../../../../images/BedBefore.png';
import ironafter from '../../../../images/IronAfter.png';
import ironbefore from '../../../../images/IronBefore.png';
import kitchenafter from '../../../../images/KitchenAfter.png';
import kitchenbefore from '../../../../images/KitchenBefore.png';
import stoveafter from '../../../../images/StoveAfter.png';
import stovebefore from '../../../../images/StoveBefore.png';
import fridgeafter from '../../../../images/FridgeAfter.png';
import fridgbefore from '../../../../images/FridgeBefore.png';
import windowafter from '../../../../images/WindowsAfter.png';
import windowbefore from '../../../../images/WindowsBefore.png';
import { Button, Card, Col, Row } from 'react-bootstrap';

const Extras = ({
  bedroom,
  myBedding,
  ironing,
  kitchenCubs,
  insideOven,
  myWindows,
  myFridge,
  laundreyBasket,
  basketToggle,
  setNote,
  changeMyBedding,
  ironingPlz,
  kitchenPlz,
  windowsPlz,
  fridgePlz,
  ovenPlz,
  hours,
  mother,
  mrx,
  jamOrderExtras,
}) => {
  const iron = () => {
    if (ironing) {
      if (laundreyBasket >= 8) {
        let invoiceHours = hours - 2;
        let newBasket = 0;
        let ironing = false;
        let newX = mrx.filter((x) => x.name !== 'Ironing & Laundrey');
        jamOrderExtras(newX);

        ironingPlz({ newBasket, invoiceHours, ironing, mother });
      } else {
        let newBasket = 0;
        let ironing = false;
        let invoiceHours = hours - 1;
        let newX = mrx.filter((x) => x.name !== 'Ironing & Laundrey');
        jamOrderExtras(newX);
        ironingPlz({ newBasket, invoiceHours, ironing, mother });
      }
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let newBasket = laundreyBasket + 4;
      let invoiceHours = hours + 1;
      let ironing = true;
      let newX = mrx;
      let text = { name: 'Ironing & Laundrey' };
      newX.push(text);
      jamOrderExtras(newX);
      ironingPlz({ newBasket, invoiceHours, ironing, mother });
    }
  };

  const addBasket = () => {
    if (laundreyBasket < 8) {
      if (hours >= 8) {
        setNote(
          'Please call the office to book over 8 hours: + 27 87 550 1147',
          'warning'
        );
      } else {
        let newKGS = laundreyBasket + 4;
        let invoiceHours = hours + 1;
        basketToggle({ invoiceHours, newKGS, mother });
      }
    } else {
      setNote('8kg is the maximum', 'warning');
    }
  };

  const removeBasket = () => {
    if (laundreyBasket === 4) {
      return setNote('4kg is the minimun', 'warning');
    } else {
      let newKGS = laundreyBasket - 4;
      let invoiceHours = hours - 1;
      basketToggle({ invoiceHours, newKGS, mother });
    }
  };

  const bed = () => {
    if (bedroom === 0) {
      return setNote(
        'Please let us know how many bedrooms need changing',
        'warning'
      );
    } else {
      if (myBedding === true) {
        let bed = false;
        let hoursBed = bedroom * 0.25;
        let invoiceHours = hours - hoursBed;
        let newX = mrx.filter((x) => x.name !== 'Bedding Change');
        jamOrderExtras(newX);
        changeMyBedding({ invoiceHours, bed, mother });
      } else {
        if (hours === 8) {
          return setNote(
            'Please call the office to book over 8 hours: + 27 11 568 8077',
            'warning'
          );
        }
        let bed = true;
        let hoursBed = bedroom * 0.25;
        let invoiceHours = hours + hoursBed;
        let text = { name: 'Bedding Change' };
        let newX = mrx;
        newX.push(text);
        jamOrderExtras(newX);
        changeMyBedding({ invoiceHours, bed, mother });
      }
    }
  };

  const kitchen = () => {
    if (kitchenCubs) {
      let kit = false;
      let invoiceHours = hours - 0.5;
      let newX = mrx.filter((x) => x.name !== 'Kitchen Cupboards');
      jamOrderExtras(newX);
      kitchenPlz({ kit, invoiceHours, mother });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let kit = true;
      let invoiceHours = hours + 0.5;
      let newX = mrx;
      let text = { name: 'Kitchen Cupboards' };
      newX.push(text);
      jamOrderExtras(newX);
      kitchenPlz({ kit, invoiceHours, mother });
    }
  };

  const oven = () => {
    if (insideOven) {
      let oven = false;
      let invoiceHours = hours - 0.5;
      let newX = mrx.filter((x) => x.name !== 'Inside Oven');
      jamOrderExtras(newX);
      ovenPlz({ oven, invoiceHours, mother });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let oven = true;
      let invoiceHours = hours + 0.5;
      let text = { name: 'Inside Oven' };
      let newX = mrx;
      newX.push(text);
      jamOrderExtras(newX);
      ovenPlz({ oven, invoiceHours, mother });
    }
  };

  const fridge = () => {
    if (myFridge) {
      let fr = false;
      let invoiceHours = hours - 0.5;
      let newX = mrx.filter((x) => x.name !== 'Inside Fridge');
      jamOrderExtras(newX);
      fridgePlz({ fr, invoiceHours, mother });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let fr = true;
      let invoiceHours = hours + 0.5;
      let newX = mrx;
      let text = { name: 'Inside Fridge' };
      newX.push(text);
      fridgePlz({ fr, invoiceHours, mother });
    }
  };

  const windows = () => {
    if (myWindows) {
      let win = false;
      let invoiceHours = hours - 0.5;
      let newX = mrx.filter((x) => x.name !== 'Internal Windows');
      jamOrderExtras(newX);
      windowsPlz({ win, invoiceHours, mother });
    } else if (hours >= 8) {
      setNote(
        'Please call the office to book over 8 hours: + 27 87 550 1147',
        'warning'
      );
    } else {
      let win = true;
      let invoiceHours = hours + 0.5;
      let newX = mrx;
      let text = { name: 'Internal Windows' };
      newX.push(text);
      windowsPlz({ win, invoiceHours, mother });
    }
  };
  return (
    <div className="mt-2">
      <h2 className="text-center text-info mb-3">Choose Extras</h2>
      <Row align="center">
        <Col xs={12} md={4}>
          <Card.Header>Ironing& Laundry</Card.Header>
          {ironing ? (
            <div id="selected-img">
              <img
                src={ironafter}
                width={100}
                height={100}
                alt="1"
                onClick={iron}
              />
            </div>
          ) : (
            <div id="img" onClick={iron}>
              <img src={ironbefore} width={100} height={100} alt="1" />
            </div>
          )}
        </Col>
        <Col xs={12} md={4}>
          <Card.Header>Change The Bedding</Card.Header>
          {myBedding ? (
            <div id="selected-img" onClick={bed}>
              <img src={bedafter} width={100} height={100} alt="2" />
            </div>
          ) : (
            <div id="img" onClick={bed}>
              <img src={bedbefore} width={100} height={100} alt="2" />
            </div>
          )}
        </Col>
        <Col xs={12} md={4}>
          <Card.Header>Kitchen Cupboards</Card.Header>
          {kitchenCubs ? (
            <div id="selected-img" onClick={kitchen}>
              <img src={kitchenafter} width={100} height={100} alt="3" />
            </div>
          ) : (
            <div id="img" onClick={kitchen}>
              <img src={kitchenbefore} width={100} height={100} alt="3" />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        {ironing && (
          <Col md={4} xs={12}>
            <p className="text-center">
              {laundreyBasket}kg
              <i className="fa-solid fa-basket-shopping"></i>
            </p>
            <Row>
              <Col xs={4}>
                <Button
                  variant="outline-info"
                  size="sm"
                  className="float-left"
                  onClick={removeBasket}
                >
                  <i className="fas fa-minus"></i>
                </Button>
              </Col>
              <Col xs={4}>
                <p className="text-center">
                  <i className="fa-solid fa-basket-shopping"></i>
                </p>
              </Col>
              <Col xs={4}>
                <Button
                  variant="outline-success"
                  size="sm"
                  className="float-right"
                  onClick={addBasket}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row align="center">
        <Col xs={12} md={4}>
          <Card.Header>Clean Inside Oven</Card.Header>
          {insideOven ? (
            <div id="selected-img" onClick={oven}>
              <img src={stoveafter} width={100} height={100} alt="4" />
            </div>
          ) : (
            <div id="img" onClick={oven}>
              <img src={stovebefore} width={100} height={100} alt="4" />
            </div>
          )}
        </Col>
        <Col xs={12} md={4}>
          <Card.Header>Clean Inside Fridge</Card.Header>
          {myFridge ? (
            <div id="selected-img" onClick={fridge}>
              <img src={fridgeafter} width={100} height={100} alt="5" />
            </div>
          ) : (
            <div id="img" onClick={fridge}>
              <img src={fridgbefore} width={100} height={100} alt="5" />
            </div>
          )}
        </Col>
        <Col md={4} xs={12}>
          <Card.Header>Internal Windows</Card.Header>
          {myWindows ? (
            <div id="selected-img" onClick={windows}>
              <img src={windowafter} width={100} height={100} alt="6" />
            </div>
          ) : (
            <div id="img" onClick={windows}>
              <img src={windowbefore} width={100} height={100} alt="6" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Extras;
