import { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ReCleanItem = ({ o }) => {
  const [complete, setcomplete] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [nextCheckin, setNextCheckin] = useState(new Date());
  useEffect(() => {
    if (o.user !== null) {
      setClientName(o.user.name);
    }
    if (o.cleans === o.checkOut.length) {
      setcomplete(true);
    } else if (o.cleans >= o.checkIn.length) {
      let sortedSchedule = o.schedule.sort((x, y) => x.start - y.start);
      let checkInLength = o.checkIn.length;
      let nextCheckIn = sortedSchedule[checkInLength];
      if (nextCheckIn) {
        setNextCheckin(nextCheckIn.start);
        setcomplete(false);
      } else {
        setcomplete(false);
      }
    } else if (o.cleans <= o.checkIn.length) {
      setcomplete(false);
    }
  }, [o, setcomplete]);
  let address = o.address.split(',').shift();

  return (
    <tr>
      <td>{clientName && `${clientName}`}</td>
      <td>{address}</td>
      <td>
        {complete
          ? 'Complete'
          : complete === false
          ? 'Check this order'
          : `Next checkin: ${moment(nextCheckin).format('ddd MMM D')}`}
      </td>
      <td>
        <Button
          as={Link}
          to={`/admin/order-management/repeat-orders/order/${o._id}`}
          size="sm"
          variant="outline-dark ml-4"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </td>
    </tr>
  );
};

export default ReCleanItem;
