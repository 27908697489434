import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import { Row, Col, Button, Alert, Modal, Card, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import {
  IDUpload,
  workPermitUpload,
  bankLetterUpload,
  PAUpload,
  proPic,
} from '../../../actions/fileupload';
const moment = require('moment-timezone');
moment().tz('Africa/Johannesburg').format();

const UploadNow = ({
  note,
  dusterId,
  local,
  stepThree,
  file: { id_uploaded, wp_uploaded, bl_uploaded, pp_uploaded, pa_uploaded },
  IDUpload,
  bankLetterUpload,
  proPic,
  PAUpload,
  workPermitUpload,
  hr,
  app,
}) => {
  const [msg, setMsg] = useState(note);
  const [tomorrow, setTomorrow] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interviewTime, setInterviewTime] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [appointment, setAppoinment] = useState(null);
  const [slots, setSlots] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [idDoc, setIdDoc] = useState(null);
  const [poa, setPOA] = useState(null);
  const [bankLetter, setBankLetter] = useState(null);
  const [pic, setPic] = useState(null);
  const [workPermit, setWorkPermit] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  let IDbtn = useRef();
  let PAbtn = useRef();
  let BLbtn = useRef();
  let PPbtn = useRef();
  let WPbtn = useRef();
  let btn = useRef();
  let eight = useRef();
  let ten = useRef();
  let twelve = useRef();
  let two = useRef();

  useEffect(() => {
    if (local === true) {
      if (
        idDoc !== null &&
        bankLetter !== null &&
        pic !== null &&
        poa !== null
      ) {
        setUploaded(true);
      }
    } else if (local === false) {
      if (
        idDoc !== null &&
        bankLetter !== null &&
        pic !== null &&
        poa !== null &&
        workPermit !== null
      ) {
        setUploaded(true);
      }
    }
  }, [local, idDoc, poa, bankLetter, pic, workPermit, setUploaded]);

  //Set ID Doc//
  useEffect(() => {
    if (id_uploaded) {
      setIdDoc(id_uploaded);
    }
  }, [setIdDoc, id_uploaded]);

  //Set Proof Of Address//
  useEffect(() => {
    if (pa_uploaded) {
      setPOA(pa_uploaded);
    }
  }, [pa_uploaded, setPOA]);

  useEffect(() => {
    if (pp_uploaded) {
      setPic(pp_uploaded);
    }
  }, [pp_uploaded, setPic]);

  //Set Bank Letter//
  useEffect(() => {
    if (bl_uploaded) {
      setBankLetter(bl_uploaded);
    }
  }, [setBankLetter, bl_uploaded]);

  //Set work Permit//
  useEffect(() => {
    if (wp_uploaded) {
      setWorkPermit(wp_uploaded);
    }
  }, [setWorkPermit, wp_uploaded]);

  useEffect(() => {
    if (slots) {
      if (slots.length > 0) {
        slots.forEach((slot) => {
          let start = slot.start.split(' ');
          let time = start[4].split(':').shift();
          if (time === '08') {
            eight.current.setAttribute('disabled', 'disabled');
          } else if (time === '10') {
            ten.current.setAttribute('disabled', 'disabled');
          } else if (time === '12') {
            twelve.current.setAttribute('disabled', 'disabled');
          } else if (time === '14')
            two.current.setAttribute('disabled', 'disabled');
        });
      }
    }
  }, [slots]);

  useEffect(() => {
    if (app) {
      setLoading(false);
      handleShow();
      setSlots(app);
    }
  }, [app]);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(8);
    setTomorrow(date);
  }, [setTomorrow]);

  const selectDay = (e) => {
    let d = e.date.toString().split(' ');
    let app = e.date;
    let date = d[0] + ' ' + d[1] + ' ' + d[2] + ' ' + d[3];
    hr({ date });
    setInterviewDate(app);
    setLoading(true);
  };
  const confirmTime = () => {
    let a = new Date(interviewDate).setHours(interviewTime);
    let appDate = moment(a).format();
    setAppoinment(appDate);
    handleClose();
  };

  //Upload ID document//
  useEffect(() => {
    if (note && IDbtn.current) {
      setMsg(note);
      IDbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);
  const uploadID = async (files) => {
    let formData = new FormData();

    formData.append('file', files[0]);

    IDUpload(formData);

    if (IDbtn.current) {
      IDbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Proof Of Address//
  useEffect(() => {
    if (note && PAbtn.current) {
      setMsg(note);
      PAbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);
  const uploadPA = async (files) => {
    let formData = new FormData();

    formData.append('file', files[0]);
    PAUpload(formData);

    if (PAbtn.current) {
      PAbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Bank Letter//
  useEffect(() => {
    if (note && BLbtn.current) {
      setMsg(note);
      BLbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadBL = async (files) => {
    let formData = new FormData();

    formData.append('file', files[0]);

    bankLetterUpload(formData);

    if (BLbtn.current) {
      BLbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Profile Picture//
  useEffect(() => {
    if (note && PPbtn.current) {
      setMsg(note);
      PPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPP = (files) => {
    let formData = new FormData();

    formData.append('file', files[0]);

    proPic(formData);

    if (PPbtn.current) {
      PPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Work Permit//
  useEffect(() => {
    if (note && WPbtn.current) {
      setMsg(note);
      WPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadWP = async (files) => {
    let formData = new FormData();

    formData.append('file', files[0]);

    workPermitUpload(formData);

    if (WPbtn.current) {
      WPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const reset = () => {
    setInterviewDate(null);
    setInterviewTime(null);
    setAppoinment(null);
  };
  const finishApp = (e) => {
    e.preventDefault();
    let et = moment(appointment).format('llll');
    stepThree({
      idDoc,
      poa,
      bankLetter,
      pic,
      workPermit,
      dusterId,
      appointment,
      et,
    });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (loading === true) {
    return <Spinner variant="primary" animation="grow" />;
  }
  return (
    <div>
      <Alert variant="info text-center mt-3">
        Upload files to book interview
      </Alert>
      <Col>
        {id_uploaded ? (
          <Button variant="success mb-3" disabled block>
            1. ID Uploaded <i className="fas fa-check-circle float-right"></i>
          </Button>
        ) : (
          <div>
            <Dropzone onDrop={uploadID}>
              {({ getRootProps, getInputProps }) => (
                <Button
                  variant="outline-secondary mb-3"
                  {...getRootProps()}
                  ref={IDbtn}
                  block
                >
                  <input {...getInputProps()} />
                  1. ID Document
                  <i className="fas fa-cloud-upload-alt float-right"></i>
                </Button>
              )}
            </Dropzone>
          </div>
        )}
      </Col>

      <Col>
        {pa_uploaded ? (
          <Button variant="success mb-3" disabled block>
            2. Proof of address
            <i className="fas fa-check-circle float-right"></i>
          </Button>
        ) : (
          <Dropzone onDrop={uploadPA}>
            {({ getRootProps, getInputProps }) => (
              <Button
                variant="outline-secondary mb-3"
                {...getRootProps()}
                ref={PAbtn}
                block
              >
                <input {...getInputProps()} />
                2. Proof of address
                <i className="fas fa-cloud-upload-alt float-right"></i>
              </Button>
            )}
          </Dropzone>
        )}
      </Col>
      <Col>
        {bl_uploaded ? (
          <Button variant="success mb-3" disabled block>
            3. Bank letter
            <i className="fas fa-check-circle float-right"></i>
          </Button>
        ) : (
          <Dropzone onDrop={uploadBL}>
            {({ getRootProps, getInputProps }) => (
              <Button
                variant="outline-secondary mb-3"
                {...getRootProps()}
                ref={BLbtn}
                block
              >
                <input {...getInputProps()} />
                3. Bank letter
                <i className="fas fa-cloud-upload-alt float-right"></i>
              </Button>
            )}
          </Dropzone>
        )}
      </Col>
      <Col>
        {pp_uploaded ? (
          <Button variant="success mb-3" disabled block>
            4. Profile Picture
            <i className="fas fa-check-circle float-right"></i>
          </Button>
        ) : (
          <Dropzone onDrop={uploadPP}>
            {({ getRootProps, getInputProps }) => (
              <Button
                variant="outline-secondary mb-3"
                {...getRootProps()}
                ref={PPbtn}
                block
              >
                <input {...getInputProps()} />
                4. Profile Picture
                <i className="fas fa-cloud-upload-alt float-right"></i>
              </Button>
            )}
          </Dropzone>
        )}
      </Col>
      {local === false && (
        <Col>
          {wp_uploaded ? (
            <Button variant="success mb-3" disabled block>
              5. Work Permit
              <i className="fas fa-check-circle float-right"></i>
            </Button>
          ) : (
            <Dropzone onDrop={uploadWP}>
              {({ getRootProps, getInputProps }) => (
                <Button
                  variant="outline-secondary mb-3"
                  {...getRootProps()}
                  ref={WPbtn}
                  block
                >
                  <input {...getInputProps()} />
                  5. Work Permit
                  <i className="fas fa-cloud-upload-alt float-right"></i>
                </Button>
              )}
            </Dropzone>
          )}
        </Col>
      )}

      {uploaded === true ? (
        appointment ? (
          <>
            <Alert variant="info mt-4">
              <Row>
                <Col className="float-left">
                  You have chosen your interview time to:
                  <b>{moment(appointment).format('llll')}</b>
                </Col>
                <Col>
                  <Button
                    variant="warning float-right"
                    size="sm"
                    onClick={reset}
                  >
                    Change Time
                  </Button>
                </Col>
              </Row>
            </Alert>
            <Card.Body>
              Please make sure you arrive on time @ Unit 7, 355 Oak Avenue
              Randburg.
            </Card.Body>
            <Button variant="primary" onClick={finishApp} ref={btn} block>
              Finish Application<i className="fas fa-sign-in-alt ml-3"></i>
            </Button>
          </>
        ) : (
          <>
            <Alert variant="secondary">Select a interview time</Alert>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
              initialView="dayGridMonth"
              dateClick={selectDay}
              validRange={{ start: tomorrow }}
              selectable={true}
            />

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className="text-center">
                  What time on{' '}
                  <span className="text-success">
                    {moment(interviewDate).format('ll')}
                  </span>{' '}
                  can you come to the interview?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Button
                      variant={interviewTime === 8 ? 'dark' : 'outline-dark'}
                      onClick={() => setInterviewTime(8)}
                      ref={eight}
                      block
                    >
                      8:00am
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant={interviewTime === 10 ? 'dark' : 'outline-dark'}
                      onClick={() => setInterviewTime(10)}
                      ref={ten}
                      block
                    >
                      10:00am
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant={interviewTime === 12 ? 'dark' : 'outline-dark'}
                      onClick={() => setInterviewTime(12)}
                      ref={twelve}
                      block
                    >
                      12:00pm
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant={interviewTime === 14 ? 'dark' : 'outline-dark'}
                      onClick={() => setInterviewTime(14)}
                      ref={two}
                      block
                    >
                      14:00pm
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="warning" onClick={handleClose}>
                  <i className="fas fa-times"></i>
                </Button>
                <Button variant="success" onClick={confirmTime}>
                  Confirm Time
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )
      ) : (
        <Alert variant="secondary">Upload documents to continue</Alert>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  file: state.file,
  note: state.note,
});

export default connect(mapStateToProps, {
  IDUpload,
  workPermitUpload,
  bankLetterUpload,
  proPic,
  PAUpload,
})(UploadNow);
