import { UPDATE_CLIENT_FLOOR } from '../types';

/////////////////////
//Update floor data//
/////////////////////
export const updateFloorData = (floor) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CLIENT_FLOOR, payload: floor });
  } catch (error) {
    console.error(error.message);
  }
};
