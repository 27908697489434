import {
  SET_DATE,
  BEDROOM,
  BEDTIME,
  BATHROOM,
  BATHTIME,
  BBHOURS,
  BBTOTAL,
  EXHOURS,
  EXTOTAL,
  ADD_EXTRA,
  REMOVE_EXTRA,
  INV_TOTAL,
  OVER_NINE,
  OVER_ALLHOURS,
  SET_SCHEDULE,
  WEEKDAYS,
  REG_INV_TOTAL,
  INITIAL_DATE,
  CREATE_SCHEDULE,
  CLEAR_SCHEDULE,
  REGULAR,
  BIG_RESET,
  NITTY_GRITTY,
  ORDER_ADDRESS,
  ORDER_COORDINATES,
  CHANGE_HOURS,
  WHY_LESS,
  ORDER_COMPLEX,
  ADD_ICON_EXTRA,
  REMOVE_ICON_EXTRA,
  STAIRWAYS,
  OFFICE_TYPE,
  OFFICE_FLOORS,
  LADIES_TOILETS,
  GENTS_TOILETS,
  UPDATE_FLOOR,
  REMOVE_FLOOR,
  TOTAL_SQM,
  DUSTER_CHOSEN,
  CHOSEN_DUSTER_SKILLS,
  BASKET_KGS,
  ADD_BOOKING_TIP,
  SELCET_IRONING,
  SELECT_CUBBOARDS,
  SELECT_OVEN,
  SELECT_WINDOWS,
  SELECT_FRIDGE,
  SELECT_BEDDING,
  ORDER_ADDRESS_TYPE,
  TOGGLE_ADDRESS_DIALOG,
} from '../types';
import moment from 'moment';
import axios from 'axios';
import { setNote } from '../note';

///////////////////////
//Set the office type//
///////////////////////
export const typeOfOffice = (officeType) => async (dispatch) => {
  try {
    dispatch({ type: OFFICE_TYPE, payload: officeType });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
//Office Stairways//
////////////////////
export const officeStairways = (stairways) => async (dispatch) => {
  try {
    dispatch({ type: STAIRWAYS, payload: stairways });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
//Office Floors//
/////////////////
export const officeFloors = (floors) => async (dispatch) => {
  try {
    dispatch({ type: OFFICE_FLOORS, payload: floors });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
//Update floor data//
/////////////////////
export const updateFloorData = (floor) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FLOOR, payload: floor });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//Whats the total sqm//
///////////////////////
export const whatsTheTotalSQM = (total) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_SQM, payload: total });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////
//Remove floor//
////////////////
export const removeFloor = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_FLOOR, payload: id });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////
//Ladies toilets//
//////////////////
export const ladiesToilets = (ladies) => async (dispatch) => {
  try {
    dispatch({ type: LADIES_TOILETS, payload: ladies });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
//Gents Toilets//
/////////////////
export const gentsToilets = (gents) => async (dispatch) => {
  try {
    dispatch({ type: GENTS_TOILETS, payload: gents });
  } catch (error) {
    console.error(error.message);
  }
};

//Toggle Address Dialog//
export const toggleAddressDialog = (addressDialog) => (dispatch) => {
  try {
    dispatch({ type: TOGGLE_ADDRESS_DIALOG, payload: addressDialog });
  } catch (error) {
    console.log(error.message);
  }
};

///////////////////////////
//Set the booking address//
///////////////////////////
export const setBookingAddress =
  ({ address, coordinates, complex, addressType }) =>
  (dispatch) => {
    dispatch({ type: ORDER_ADDRESS, payload: address });
    dispatch({ type: ORDER_COORDINATES, payload: coordinates });
    dispatch({ type: ORDER_COMPLEX, payload: complex });
    dispatch({ type: ORDER_ADDRESS_TYPE, payload: addressType });

    try {
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////
//Handle the bed//
//////////////////
export const handleBed = (newBeds) => (dispatch) => {
  dispatch({ type: BEDROOM, payload: newBeds });
  let bedtime = 0;
  try {
    if (newBeds === 0) bedtime = 0;
    if (newBeds === 1) bedtime = 30;
    if (newBeds === 2) bedtime = 60;
    if (newBeds === 3) bedtime = 120;
    if (newBeds === 4) bedtime = 180;
    if (newBeds === 5) bedtime = 180;
    if (newBeds === 6) bedtime = 180;
    dispatch({ type: BEDTIME, payload: bedtime });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////
//Handle the bath//
///////////////////

export const handleBath = (newBath) => (dispatch) => {
  dispatch({ type: BATHROOM, payload: newBath });
  let bathtime = 0;
  try {
    if (newBath === 0) bathtime = 0;
    if (newBath === 1) bathtime = 30;
    if (newBath === 2) bathtime = 30;
    if (newBath === 3) bathtime = 45;
    if (newBath === 4) bathtime = 60;
    dispatch({ type: BATHTIME, payload: bathtime });
  } catch (error) {
    console.log(error.messge);
  }
};

///////////////////////////
//Calculate  bed and bath//
///////////////////////////

export const calcBedBath = (bedroomTime, bathroomTime) => (dispatch) => {
  try {
    let totalTime = bedroomTime + bathroomTime;
    let hours = (totalTime / 60).toFixed(2);
    dispatch({ type: BBHOURS, payload: hours });

    let bedBathTotal = (hours * 53).toFixed(2);
    dispatch({ type: BBTOTAL, payload: bedBathTotal });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
//Remove extras//
/////////////////
export const removeExtra = (extraID) => (dispatch) => {
  try {
    dispatch({ type: REMOVE_EXTRA, payload: extraID });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////
///Add Extra///
//////////////////
export const addExtra = (extraID) => (dispatch) => {
  try {
    dispatch({ type: ADD_EXTRA, payload: extraID });
  } catch (error) {
    console.error(error.message);
  }
};

export const ironingPlz =
  ({ newBasket, newHrs, ironing, extraID }) =>
  (dispatch) => {
    try {
      //
      let id = extraID;
      dispatch({ type: BASKET_KGS, payload: newBasket });
      dispatch({ type: SELCET_IRONING, payload: ironing });
      dispatch({ type: EXHOURS, payload: newHrs });
      if (ironing) {
        dispatch(addIconExtra(id));
      } else {
        dispatch(removeIconExtra(id));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

export const changeMyBedding =
  ({ newExtra, bed, id }) =>
  (dispatch) => {
    try {
      dispatch({ type: EXHOURS, payload: newExtra });
      dispatch({ type: SELECT_BEDDING, payload: bed });
      if (bed) {
        dispatch(addIconExtra(id));
      } else {
        dispatch(removeIconExtra(id));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

export const basketToggle =
  ({ newHours, newKGS }) =>
  (dispatch) => {
    try {
      dispatch({ type: EXHOURS, payload: newHours });
      dispatch({ type: BASKET_KGS, payload: newKGS });
    } catch (error) {
      console.error(error.message);
    }
  };

export const kitchenPlz =
  ({ kit, newHours, id }) =>
  (dispatch) => {
    try {
      //
      dispatch({ type: SELECT_CUBBOARDS, payload: kit });
      dispatch({ type: EXHOURS, payload: newHours });
      if (kit) {
        dispatch(addIconExtra(id));
      } else {
        dispatch(removeIconExtra(id));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

export const ovenPlz =
  ({ oven, newHours, id }) =>
  (dispatch) => {
    try {
      dispatch({ type: SELECT_OVEN, payload: oven });
      dispatch({ type: EXHOURS, payload: newHours });
      if (oven) {
        dispatch(addIconExtra(id));
      } else {
        dispatch(removeIconExtra(id));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

export const windowsPlz =
  ({ win, newHours, id }) =>
  (dispatch) => {
    try {
      dispatch({ type: SELECT_WINDOWS, payload: win });
      dispatch({ type: EXHOURS, payload: newHours });
      if (win) {
        dispatch(addIconExtra(id));
      } else {
        dispatch(removeIconExtra(id));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

export const fridgePlz =
  ({ fr, newHours, id }) =>
  (dispatch) => {
    try {
      dispatch({ type: SELECT_FRIDGE, payload: fr });
      dispatch({ type: EXHOURS, payload: newHours });
      if (fr) {
        dispatch(addIconExtra(id));
      } else {
        dispatch(removeIconExtra(id));
      }
    } catch (error) {
      console.error(error.message);
    }
  };
//Add Icon Extra//
export const addIconExtra = (id) => (dispatch) => {
  dispatch({ type: ADD_ICON_EXTRA, payload: id });
};
//Remove Icon Extra//
export const removeIconExtra = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ICON_EXTRA, payload: id });
};

////////////////////////
//Calculate the extras//
////////////////////////
export const calcExtras = (totalMinutes) => (dispatch) => {
  try {
    let hours = (totalMinutes / 60).toFixed(2);
    let extraTotal = (hours * 53).toFixed(2);
    dispatch({ type: EXHOURS, payload: hours });
    dispatch({ type: EXTOTAL, payload: extraTotal });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
/////Set the date////
/////////////////////
export const setDate = (orderTime) => (dispatch) => {
  if (orderTime) {
    const chosenD = moment(orderTime).format();
    try {
      dispatch({ type: SET_DATE, payload: chosenD });
    } catch (error) {
      console.error(error.message);
    }
  } else {
    dispatch({ type: SET_DATE, payload: orderTime });
  }
};

////////////////////
///Clear Schedule///
////////////////////
export const clearSchedule = (data) => (dispatch) => {
  try {
    dispatch({ type: CLEAR_SCHEDULE, payload: data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
//On Which Days//
/////////////////
export const SetWeekDays = (weekdays) => (dispatch) => {
  try {
    dispatch({ type: WEEKDAYS, payload: weekdays });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////
///Set Schedule///
//////////////////
export const SetSchedule = (schedule) => (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE, payload: schedule });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////
//Set Regular///
////////////////
export const SetRegular = (data) => (dispatch) => {
  try {
    dispatch({ type: REGULAR, payload: data });
  } catch (error) {
    console.error(error.message);
  }
};

export const repeatCalender =
  ({ daysLeft, weekdays, hours }) =>
  async (dispatch) => {
    try {
      let data = [];
      const weekly = daysLeft.filter((dl) => {
        let string = dl.toString().split(' ');
        const match = weekdays.filter((dow) => {
          if (string[0] === dow) {
            return dl;
          }
        });
        if (match.length > 0) {
          return dl;
        }
      });
      function createSchedule(weekly, data) {
        weekly.forEach((w) => {
          let title = 'Cleaning';
          let start = moment(w).format();
          let end = moment(start).add(hours, 'hours').format();
          let event = { title, start, end };
          data.push(event);
        });
      }

      createSchedule(weekly, data);
      dispatch({ type: CREATE_SCHEDULE, payload: data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////////
/////Fulltime cleaner/////
//////////////////////////
export const fullTimeCleaner =
  ({ daysLeft, invoiceHours }) =>
  (dispatch) => {
    try {
      let data = [];
      const weekly = daysLeft.filter((dl, index) => {
        let string = dl.toString().split(' ');
        let DaysOfTheWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
        const match = DaysOfTheWeek.filter((dow, index) => {
          if (string[0] === dow) {
            return dl;
          }
        });
        if (match.length > 0) {
          return dl;
        }
      });

      function createSchedule(weekly, data) {
        weekly.forEach((w) => {
          let title = 'Cleaning';
          let start = w;
          let end = moment(start).add(invoiceHours, 'hours').format('llll');
          let event = { title, start, end };
          data.push(event);
        });
      }
      createSchedule(weekly, data);
      dispatch({ type: CREATE_SCHEDULE, payload: data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////
///Initial Date///
//////////////////
export const initalDate = (schedule) => (dispatch) => {
  try {
    let a = schedule.sort(function (a, b) {
      return a.start - b.start;
    });
    let date = a[0].start;
    dispatch({ type: INITIAL_DATE, payload: date });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////
///Remove From Schedule//
/////////////////////////
export const removeSchedule =
  ({ remove, schedule }) =>
  (dispatch) => {
    try {
      let newEvents = schedule.filter((s, index) => {
        let string = s.start.toString().split(' ');
        if (string[0] !== remove) {
          return s;
        }
      });
      dispatch({ type: CREATE_SCHEDULE, payload: newEvents });
    } catch (error) {
      console.error('Error Removing Schedule', error.message);
    }
  };

//////////////////
//The Nity Grity//
//////////////////
export const theNityGrity = (nityGrity) => async (dispatch) => {
  try {
    dispatch({ type: NITTY_GRITTY, payload: nityGrity });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////
//Select Duster for clean//
///////////////////////////
export const chooseDuster = (dusterId) => async (dispatch) => {
  try {
    //

    let id = dusterId.duster.dusterId;
    const res = await axios.get(`/api/user/orders/dust-buster-skills/${id}`);
    dispatch({ type: DUSTER_CHOSEN, payload: dusterId });
    dispatch({ type: CHOSEN_DUSTER_SKILLS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Remove duster from clean//
////////////////////////////
export const removeDuster = () => (dispatch) => {
  try {
    dispatch({ type: DUSTER_CHOSEN, payload: null });
    dispatch({ type: CHOSEN_DUSTER_SKILLS, payload: null });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////
//Add a tip//
/////////////
export const addTip =
  ({ tip, invoice, regular }) =>
  async (dispatch) => {
    try {
      let newInvTotal = (invoice + tip.tip).toFixed(2);
      dispatch({ type: ADD_BOOKING_TIP, payload: tip });

      if (regular) {
        dispatch({ type: REG_INV_TOTAL, payload: newInvTotal });
      } else {
        dispatch({ type: INV_TOTAL, payload: newInvTotal });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////
//Cancel Tip//
//////////////
export const cancelTip =
  ({ amt, invoice, regular }) =>
  async (dispatch) => {
    try {
      let newInvTotal = (invoice - amt).toFixed(2);
      dispatch({ type: ADD_BOOKING_TIP, payload: null });

      if (regular) {
        dispatch({ type: REG_INV_TOTAL, payload: newInvTotal });
      } else {
        dispatch({ type: INV_TOTAL, payload: newInvTotal });
      }
    } catch (error) {
      console.error(error.message);
    }
  };
///////////////////////
//////Rate Card////////
///////////////////////
export const rateCard =
  ({ invoiceHours, mother }) =>
  (dispatch) => {
    const { four, fourp, five, fivep, six, sixp, seven, sevenp, eight } =
      mother;
    try {
      //The Rate Card//
      if (invoiceHours <= 4) {
        let price = 4 * four;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 4 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 4.5) {
        let price = 4.5 * fourp;

        let invpri = (price + 35).toFixed(2);

        dispatch({ type: OVER_ALLHOURS, payload: 4.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5) {
        let price = 5 * five;
        let invpri = (price + 35).toFixed(2);

        dispatch({ type: OVER_ALLHOURS, payload: 5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5.5) {
        let price = 5.5 * fivep;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 5.5 });

        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6) {
        let price = 6 * six;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 6 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6.5) {
        let price = 6.5 * sixp;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 6.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7) {
        let price = 7 * seven;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 7 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7.5) {
        let price = 7.5 * sevenp;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 7.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 8) {
        let price = 8 * eight;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 8 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 8.5) {
        let price = 8.5 * eight;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 8 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours > 8) {
        return dispatch(
          setNote(
            'Please call the office to book over 8 hours: + 27 87 550 1147',
            'warning'
          )
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////
/////Custom Rate//////
//////////////////////
export const customRate =
  ({ myRateCard, invoiceHours }) =>
  (dispatch) => {
    try {
      const { four, five, six, seven, eight, nine, ten } = myRateCard;

      let jam = parseInt(invoiceHours);
      dispatch({ type: OVER_ALLHOURS, payload: jam });
      //The Rate Card//
      if (invoiceHours <= 4) {
        let price = invoiceHours * four;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 5) {
        let price = invoiceHours * five;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6) {
        let price = invoiceHours * six;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7) {
        let price = invoiceHours * seven;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 8) {
        let price = invoiceHours * eight;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 9) {
        let price = invoiceHours * nine;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_NINE, payload: false });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 10) {
        let price = invoiceHours * ten;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: OVER_NINE, payload: true });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours > 10) {
        let price = invoiceHours * ten;
        let invpri = (price + 35).toFixed(2);
        dispatch({ type: INV_TOTAL, payload: invpri });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////////////
//Client has changed the rate//
///////////////////////////////
export const changeRate =
  ({ invoiceHours, mother }) =>
  (dispatch) => {
    const { four, fourp, five, fivep, six, sixp, seven, sevenp, eight } =
      mother;
    try {
      let jam = parseInt(invoiceHours);
      dispatch({ type: CHANGE_HOURS, payload: jam });
      //The Rate Card//
      if (invoiceHours <= 4) {
        let price = invoiceHours * four;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 4.5) {
        let price = 4.5 * fourp;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 5) {
        let price = invoiceHours * five;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5.5) {
        let price = 5.5 * fivep;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6) {
        let price = invoiceHours * six;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6.5) {
        let price = 6.5 * sixp;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7) {
        let price = invoiceHours * seven;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7.5) {
        let price = 7.5 * sevenp;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 8) {
        let price = invoiceHours * eight;
        let invpri = (price + 35).toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////////
//Why are you changing//
////////////////////////
export const whyChangeHours = (whyLess) => (dispatch) => {
  try {
    dispatch({ type: WHY_LESS, payload: whyLess });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////
//Regular Rate Card///
//////////////////////
export const regularRate = (amount) => (dispatch) => {
  try {
    dispatch({ type: REG_INV_TOTAL, payload: amount });
  } catch (error) {
    console.error(error.message);
  }
};

export const resetBooking = () => (dispatch) => {
  dispatch({ type: BIG_RESET });
};
