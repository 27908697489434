import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { requestOnsite } from '../../../actions/team/team_work';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment-timezone';

const ConfirmOnsite = ({
  requestOnsite,
  currentLat,
  currentLon,
  currentOrder,
  note,
}) => {
  const [show, setShow] = useState(false);
  const [nte, setMsg] = useState(note);
  let btnSite = useRef();
  let btnSend = useRef();

  useEffect(() => {
    if (note && btnSend.current) {
      setMsg(note);
      btnSend.current.removeAttribute('disabled');
      handleClose();
    }
  }, [setMsg, note, nte]);

  const handleClose = () => {
    setShow(false);
    btnSite.current.removeAttribute('disabled');
  };
  const handleShow = () => {
    setShow(true);
    if (btnSite.current) {
      btnSite.current.setAttribute('disabled', 'disabled');
    }
  };

  const onsite = () => {
    let timestamp = moment().format();
    let officeRequest = { currentLat, currentLon, timestamp };
    requestOnsite({ officeRequest, currentOrder });
    if (btnSend.current) {
      btnSend.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <Button variant="outline-warning" ref={btnSite} onClick={handleShow}>
        Request Validation
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request onsite validation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you are onsite and need to be validated send message
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" ref={btnSend} onClick={onsite}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { requestOnsite })(ConfirmOnsite);
