import React, { useEffect, useState } from 'react';
import { ListGroup, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import ExtraItem from './ExtraItem';
import QRScanner from './QRScanner';
import OrderComplete from './OrderComplete';
import { Modal, Button } from 'react-bootstrap';
import {
  locationCheck,
  checkIn,
  checkOut,
  getBooking,
  completeBooking,
  onMyWay,
} from '../../../actions/team/team_work';
import Bottom from '../../Layout/Bottom';
import ConfirmOnsite from './ConfirmOnsite';
import CompleteBooking from './CompleteBooking';
import OnMyWay from './OnMyWay';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const MyBooking = ({
  team_work: { booking, distance },
  locationCheck,
  checkIn,
  checkOut,
  getBooking,
  completeBooking,
  dusterId,
  onMyWay,
  note,
}) => {
  const { orderId } = useParams();
  useEffect(() => {
    getBooking(orderId);
  }, [orderId, getBooking]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [currentLat, setCurrentLat] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentLon, setCurrentLng] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [checkedIn, setCheckedIn] = useState(null);
  const [checkedOut, setCheckedOut] = useState(null);
  const [checkingLocation, setCheckingLocation] = useState(false);
  const [request, setRequest] = useState(null);
  const [omw, setOMW] = useState(null);

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    setCurrentLat(crd.latitude);
    setCurrentLng(crd.longitude);
    setCheckingLocation(false);
  }
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (booking) {
      setCurrentOrder(booking._id);
      setCheckedIn(booking.checkIn);
      setCheckedOut(booking.checkOut);
      setUserId(booking.user);
      setRequest(booking.officeRequest);
      setOMW(booking.onMyWay);
    }
  }, [booking]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            //If granted then you can directly call your function here
            setCheckingLocation(true);
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === 'prompt') {
            //prompt user for permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
            setShow(true);
          } else if (result.state === 'denied') {
            //If denied then you have to show instructions to enable location
            setShow(true);
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert('Geo Location is not available');
    }
  }, [navigator]);

  useEffect(() => {
    if (currentLat && currentLon && booking) {
      let orderId = booking._id;
      locationCheck({ currentLat, currentLon, orderId });
      setShow(false);
    }
  }, [currentLat, currentLon, booking, locationCheck]);

  if (checkingLocation === true || booking === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  let time = moment(booking.orderTime)
    .format('ddd MMM D, h:mm a')
    .split(',')
    .shift();
  let today = moment().local().format('ddd MMM D, h:mm a').split(',').shift();

  return (
    <div>
      <div className="container">
        <Row>
          <Col>
            <Alert variant="warning text-center">Cleaning Brief:</Alert>
            <ListGroup variant="flush">
              <ListGroup.Item variant="secondary text-center">
                <b>Time & Location</b>
                <hr style={{ width: '18rem' }} />
                {time} | {booking.address}
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left">
                  {booking.bedroom} Bedroom/s and {booking.bathroom} Bathroom/s
                </p>
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {booking.mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  booking.mrx.map((x) => {
                    return (
                      <div key={x.id} style={{ width: '20rem' }}>
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>

              <ListGroup.Item>
                <p className="float-left">Total Time: </p>
                <p className="float-right">{booking.invoiceHours} hours</p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col align="center">
            {checkedOut ? (
              <OrderComplete currentOrder={currentOrder} userId={userId} />
            ) : time === today ? (
              distance < 0.05 ? (
                <>
                  {checkedIn ? (
                    <CompleteBooking
                      completeBooking={completeBooking}
                      dusterId={dusterId}
                      currentOrder={currentOrder}
                    />
                  ) : request ? (
                    <Button variant="outline-info mt-3" disabled>
                      Validation Request submitted!
                    </Button>
                  ) : (
                    <>
                      <QRScanner
                        currentOrder={currentOrder}
                        checkIn={checkIn}
                        checkOut={checkOut}
                        checkedIn={checkedIn}
                      />
                      <ConfirmOnsite
                        currentLat={currentLat}
                        currentLon={currentLon}
                        currentOrder={currentOrder}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <h5 className="text-center">
                    When you get to booking location you will be able to
                    check-in, if you are on-site click button below for
                    validation.
                  </h5>
                  {request ? (
                    <Button variant="outline-info" disabled>
                      Validation Request submitted!
                    </Button>
                  ) : (
                    <>
                      {omw == null ? (
                        <OnMyWay
                          onMyWay={onMyWay}
                          currentOrder={currentOrder}
                          note={note}
                        />
                      ) : (
                        <Button variant="info mb-2" size="sm" disabled>
                          Thank you for letting us know you are on your way :)
                        </Button>
                      )}

                      <ConfirmOnsite
                        currentLat={currentLat}
                        currentLon={currentLon}
                        currentOrder={currentOrder}
                      />
                    </>
                  )}
                </>
              )
            ) : (
              <>
                <h4 className="text-warning">
                  Today is not the day of the order
                </h4>
              </>
            )}
          </Col>
        </Row>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>We need to know your location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please allow us access to your location before you can see this
              page!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" disabled block>
                You can only see this page by giving us your device location
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  team_work: state.team_work,
  dusterId: state.team.team._id,
  note: state.note,
});

export default connect(mapStateToProps, {
  locationCheck,
  checkIn,
  checkOut,
  getBooking,
  completeBooking,
  onMyWay,
})(MyBooking);
