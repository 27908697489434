import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { currentUser } from '../../../../actions/user/';
import { confirmOrder } from '../../../../actions/user/order';
import { ozowPay, peachPayment } from '../../../../actions/user/payment';
import {
  Row,
  Col,
  ListGroup,
  Card,
  Button,
  Alert,
  Spinner,
  Modal,
} from 'react-bootstrap';
import moment from 'moment';
import ExtraItem from './ExtraItem';
import Schedule from '../../Profile/Schedule';
import Bottom from '../../../Layout/Bottom';

const SuccessResponse = ({
  confirmOrder,
  orders: { paymentUrl, order, peachLoad },
  currentUser,
  ozowPay,
  peachPayment,
}) => {
  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  });
  const [show, setShow] = useState(false);
  const [orderId, SetOrderId] = useState('');
  const [mrx, SetMrx] = useState([]);
  const [it, SetIT] = useState('');
  const [bed, SetBed] = useState('');
  const [bath, SetBath] = useState('');
  const [inv, SetIV] = useState('');
  const [invoiceHours, SetIH] = useState('');
  const [code, SetCode] = useState('');
  const [address, SetAdress] = useState('');
  const [cleans, SetCleans] = useState('');
  const [weekdays, SetWeekDays] = useState([]);
  const [schedule, SetSchedule] = useState([]);

  useEffect(() => {
    if (peachLoad) {
      const script = document.createElement('script');
      script.src = `https://${process.env.REACT_APP_PEACH_BASE_URL}/v1/paymentWidgets.js?checkoutId=${peachLoad.id}`;
      document.body.append(script);

      window.scrollTo(0, 0);
      setShow(true);
    }
  }, [peachLoad]);

  useEffect(() => {
    if (order) {
      SetOrderId(order._id);
      SetMrx(order.mrx);
      SetIT(order.orderTime);
      SetBed(order.bedroom);
      SetBath(order.bathroom);
      SetIV(order.invoice.toFixed(2));
      SetIH(order.invoiceHours);
      SetCode(order.code);
      SetAdress(order.address);
      SetCleans(order.cleans);
      SetWeekDays(order.weekdays);
      SetSchedule(order.schedule);
    }
  }, [SetMrx, SetIT, order]);

  useEffect(() => {
    let url = window.location.href;
    let jam = url.split('?').pop().split('&');
    let TransactionId = jam[1].split('=').pop();
    let Status = jam[4].split('=').pop();
    let Optional1 = jam[5].split('=').pop();

    confirmOrder({ Status, Optional1, TransactionId });
  }, [confirmOrder, currentUser]);

  let btn = useRef();
  let peachBtn = useRef();

  if (order === null) {
    return <Spinner animation="grow" variant="primary" />;
  }

  const tryAgainOzow = () => {
    const _id = orderId;
    let invoice = inv;
    ozowPay({ _id, invoice });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  const createPeach = () => {
    let amount = parseFloat(inv).toFixed(2);
    peachPayment({ amount, orderId });
    if (peachBtn.current) {
      peachBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  const invoiceTime = moment(it).format('ddd MMM D, h:mm a');

  return (
    <div>
      <Modal show={show} backdrop="static" keyboard={false} autoFocus={true}>
        <Modal.Body>
          <form
            action={`${process.env.REACT_APP_BASE_URL}payments/card-response/`}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </Modal.Body>
      </Modal>
      <div className="container">
        <Row>
          <Col className="mt-3">
            <Alert variant="primary">
              <h5>Thank you for your order</h5>
            </Alert>
            <ListGroup variant="flush" className="mt-2">
              <ListGroup.Item variant="info">
                {invoiceHours}hr Order Summary!
                <p className="float-right">Date: {invoiceTime}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                {bed} Bedroom/s {bath} Bathroom/s
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div
                        className="float-right"
                        key={x.id}
                        style={{ width: '20rem' }}
                      >
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                Address: <p className="float-right">{address}</p>
              </ListGroup.Item>
              {weekdays && weekdays.length > 0 && (
                <>
                  <ListGroup.Item>
                    <b>
                      The order has {cleans} cleans weekly on
                      {weekdays &&
                        weekdays.map((w) => (
                          <div className="float-right">{w}</div>
                        ))}
                    </b>
                  </ListGroup.Item>
                  <ListGroup.Item className="text-center">
                    On these dates:
                    <hr style={{ width: '10rem' }} />
                    {schedule &&
                      schedule.map((s) => <Schedule key={s.start} s={s} />)}
                  </ListGroup.Item>
                </>
              )}
              <ListGroup.Item variant="warning">
                Invoice Total; <b className="float-right">R{inv}</b>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col align="center">
            {order.code ? (
              <Card style={{ width: '15rem' }}>
                <Card.Img variant="top" src={code} />
                <Card.Body>
                  <Card.Title className="text-center">
                    Please download the qr code
                  </Card.Title>
                  <Button variant="primary" onClick={downloadCode}>
                    Download!
                  </Button>
                </Card.Body>
              </Card>
            ) : (
              <Card style={{ width: '20rem' }}>
                <Alert variant="danger">Your payment failed!</Alert>
                <Card.Body>
                  <Card.Title className="text-center">
                    <Alert variant="warning">Please try pay again</Alert>
                  </Card.Title>
                  <Button
                    variant="success"
                    onClick={tryAgainOzow}
                    ref={btn}
                    block
                  >
                    Ozow Instant EFT R{inv}
                    <i className="fas fa-cash-register float-right"></i>
                  </Button>
                  <Button
                    variant="success mt-2"
                    onClick={createPeach}
                    ref={peachBtn}
                    block
                  >
                    Secure Card Payment R{inv}
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
});

export default connect(mapStateToProps, {
  currentUser,
  confirmOrder,
  ozowPay,
  peachPayment,
})(SuccessResponse);
