import {
  ADMIN_GET_CARPERT_CLEANS,
  ADMIN_GET_CARPERT_CLEAN,
  ADMIN_NULL_CARPERT_CLEANS,
} from '../../actions/types';

const initState = {
  loading: true,
  carpert_cleans: null,
  carpert_clean: null,
};

function adminCarpertCleans(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_NULL_CARPERT_CLEANS:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_GET_CARPERT_CLEANS:
      return {
        ...state,
        carpert_cleans: payload,
        loading: false,
      };
    case ADMIN_GET_CARPERT_CLEAN:
      return {
        ...state,
        carpert_clean: payload,
      };
    default:
      return state;
  }
}

export default adminCarpertCleans;
