import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, ListGroup, Button, Alert } from 'react-bootstrap';
import { fetchQueries } from '../../../actions/user/query';
import { Link } from 'react-router-dom';

const OrderQueries = ({ fetchQueries, queries }) => {
  useEffect(() => {
    fetchQueries();
  }, [fetchQueries]);
  return (
    <Container>
      <Alert variant="info text-center">
        <Button
          variant="outline-info float-left"
          as={Link}
          to="/user/my-profile"
          size="sm"
        >
          <i className="fa-solid fa-backward-step"></i>
        </Button>
        Your queries
      </Alert>
      <ListGroup id="my-profile" style={{ width: '28rem' }}>
        {queries.length === 0
          ? 'No Queries'
          : queries.map((query) => (
              <ListGroup.Item key={query._id}>
                {query.headline}
                <Button
                  size="sm"
                  variant="primary float-right"
                  as={Link}
                  to={`/user/order-query/${query._id}`}
                >
                  View Query
                </Button>
              </ListGroup.Item>
            ))}
      </ListGroup>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  queries: state.user_queries.queries,
});

export default connect(mapStateToProps, { fetchQueries })(OrderQueries);
