import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';

const Calendar = ({
  tomorrow,
  setPrettyDay,
  setSelectedDay,
  prettyDay,
  selectedDay,
  orderId,
  changeDate,
  invoiceHours,
  duster,
}) => {
  const [show, setShow] = useState(false);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [dusterId, setDusterId] = useState(null);
  let btn = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (duster !== null) {
      setDusterId(duster._id);
    }
  }, [duster]);

  const selectDay = (e) => {
    let day = e.dateStr;
    let p = e.date.toString().split(' ');
    let pretty = p[0] + ' ' + p[1] + ' ' + p[2];
    setPrettyDay(pretty);
    setSelectedDay(day);
    handleShow();
  };

  const confirmTime = () => {
    let x = new Date(selectedDay).setHours(arrivalTime);
    let date = moment(x);
    let orderTime = date._d;
    changeDate({ orderTime, orderId, invoiceHours, dusterId });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div className="mt-4">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
        initialView="dayGridMonth"
        displayEventEnd="true"
        validRange={{ start: tomorrow }}
        selectable={true}
        dateClick={selectDay}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            What time would you like the cleaner to arrive on {prettyDay}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 8 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(8)}
                block
              >
                8:00am
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 10 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(10)}
                block
              >
                10:00am
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 12 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(12)}
                block
              >
                12:00pm
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 14 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(14)}
                block
              >
                14:00pm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          {arrivalTime ? (
            <Button variant="outline-success" onClick={confirmTime} ref={btn}>
              Confirm Arrival Time
            </Button>
          ) : (
            <Button variant="secondary" disabled>
              Select Arrival Time
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calendar;
