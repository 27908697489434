import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { setNote } from '../../../actions/note';
import { verifyMember } from '../../../actions/team/';
import { useParams } from 'react-router-dom';

const Verify = ({ verifyMember, setNote, msgs }) => {
  const [msg, setMsg] = useState(msgs);
  const { token } = useParams();
  let btn = useRef();

  useEffect(() => {
    if (msgs && btn.current) {
      setMsg(msgs);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, msg, msgs]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (token === '') {
      setNote('Please insert token', 'danger');
    } else {
      verifyMember({ token });

      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };
  return (
    <div className="container">
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="formBasicText">
          <Form.Label>Verification code</Form.Label>
          <Form.Control
            type="text"
            name="token"
            value={token}
            placeholder="Please Paste Verifcation Code Here"
            disabled
          />
        </Form.Group>
        <Button variant="primary btn btn-block" type="submit" ref={btn}>
          Verify Email <i className="fas fa-user-check ml-2"></i>
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  msgs: state.team.msgs,
});

export default connect(mapStateToProps, { verifyMember, setNote })(Verify);
