import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

const SelectSwapDay = ({
  sched,
  findForADay,
  orderId,
  swapDay,
  setSwapDay,
  findTheBooking,
}) => {
  const start = moment(sched.start).format('ddd MMM D');

  const fetchCleaners = () => {
    let start = sched.start;

    findForADay({ orderId, start });
    findTheBooking({ orderId, start });
    setSwapDay(sched.start);
  };
  return (
    <div>
      <Button
        variant={
          swapDay === sched.start ? 'primary mt-2' : 'outline-primary mt-2'
        }
        block
        value={sched.start}
        onClick={() => fetchCleaners()}
      >
        {start}
      </Button>
    </div>
  );
};

export default SelectSwapDay;
