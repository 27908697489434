import React from 'react';
import { Button } from 'react-bootstrap';

const Schedule = ({ s }) => {
  let date = s.start.split('T');
  return (
    <Button variant="info mr-2 mt-2" size="sm">
      {date[0]}
    </Button>
  );
};

export default Schedule;
