import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Modal,
  Form,
  Alert,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EmailVerifiedModal from './EmailVerifiedModal';
// import DusterCard from './DusterCard';
// import DusterSelector from './DusterSelector';

const NityGrity = ({
  isUser,
  regular,
  theNityGrity,
  nityGrity,
  getAvailableDusters,
  coordinates,
  orderTime,
  whoCleanedLast,
  dusterOptions,
  // lastDuster,
  getDusterRating,
  // dusterRating,
  // dusterId,
  // selectDusterForClean,
  // chosenDusterSkills,
  // removeDusterFromClean,
  newUserCreated,
  signUpNewUser,
  errors,
  jamming,
  jamCarpertGetIn,
  carpert_nity,
}) => {
  useEffect(() => {
    getAvailableDusters({ coordinates, orderTime });
    whoCleanedLast({ regular, orderTime });
  }, [getAvailableDusters, coordinates, orderTime, whoCleanedLast, regular]);
  const [options, setOptions] = useState(null);
  const [show, setShow] = useState();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [cellphone, setCellphone] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmP, setConfirmP] = useState('');
  const [error, setError] = useState(undefined);
  useEffect(() => {
    if (newUserCreated) {
      setShow(!newUserCreated);
    }
  }, [newUserCreated]);

  useEffect(() => {
    if (errors) {
      setError(errors[0].msg);
      return setTimeout(() => setError(undefined), 2000);
    }
  }, [errors]);

  // const [shufflingOptions, setShufflingoptions] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (dusterOptions) {
      function selectThreeRandomItems(arr) {
        if (!Array.isArray(arr) || arr.length < 3) {
          throw new Error('Input must be an array with at least 3 elements.');
        }

        const shuffled = arr.slice(); // Create a copy of the original array
        for (let i = shuffled.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Shuffle the elements
        }

        return shuffled.slice(0, 2); // Return the first 3 elements
      }

      if (dusterOptions.length > 2) {
        const random = selectThreeRandomItems(dusterOptions);
        setOptions(random);
      }
    }
  }, [dusterOptions]);
  useEffect(() => {
    if (options) {
      let id1 = options[0]._id;
      let id2 = options[1]._id;
      let dusterIds = [id1, id2];
      getDusterRating({ dusterIds });
    }
  }, [options, getDusterRating]);
  const [how, setHow] = useState(null);
  const [note, setNote] = useState('');
  const [moreInfo, setMoreInfo] = useState(null);

  useEffect(() => {
    if (nityGrity) {
      if (nityGrity.a) {
        setHow(nityGrity.a);
      } else {
        setHow(nityGrity);
      }
    }
  }, [nityGrity, setHow]);
  useEffect(() => {
    if (carpert_nity) {
      if (carpert_nity.a) {
        setHow(carpert_nity.a);
      } else {
        setHow(carpert_nity);
      }
    }
  }, [carpert_nity, setHow]);

  useEffect(() => {
    if (how) {
      if (jamming) {
        if (how === 'Some One Is At Home') {
          setMoreInfo('Some One Is At Home');
          setNote('');
          jamCarpertGetIn({ a: 'Some One Is At Home', b: '' });
        } else if (how === 'Key Safe') {
          setMoreInfo('What is the safe pin eg: 9876');
          setNote('');
          jamCarpertGetIn({ a: 'Key Safe', b: '' });
        } else if (how === 'Key Hidden') {
          setMoreInfo('Where is the key hidden');
          setNote('');
          jamCarpertGetIn({ a: 'Key Hidden', b: '' });
        } else if (how === 'Other') {
          setMoreInfo('Please let us know how to gain access for the clean');
          setNote('');
          jamCarpertGetIn({ a: 'Other', b: '' });
        }
      } else {
        if (how === 'Some One Is At Home') {
          setMoreInfo('Some One Is At Home');
          setNote('');
          theNityGrity({ a: 'Some One Is At Home', b: '' });
        } else if (how === 'Key Safe') {
          setMoreInfo('What is the safe pin eg: 9876');
          setNote('');
          theNityGrity({ a: 'Key Safe', b: '' });
        } else if (how === 'Key Hidden') {
          setMoreInfo('Where is the key hidden');
          setNote('');
          theNityGrity({ a: 'Key Hidden', b: '' });
        } else if (how === 'Other') {
          setMoreInfo('Please let us know how to gain access for the clean');
          setNote('');
          theNityGrity({ a: 'Other', b: '' });
        }
      }
    }
  }, [how, theNityGrity, setMoreInfo, jamCarpertGetIn, jamming]);

  useEffect(() => {
    if (note) {
      if (jamming) {
        let a = how;
        let b = note;
        let nityGrity = { a, b };
        jamCarpertGetIn(nityGrity);
      } else {
        let a = how;
        let b = note;
        let nityGrity = { a, b };
        theNityGrity(nityGrity);
      }
    }
  }, [note, theNityGrity, how, jamCarpertGetIn, jamming]);

  // const shuffleOptions = () => {
  //   setShufflingoptions(true);
  //   function selectThreeRandomItems(arr) {
  //     if (!Array.isArray(arr) || arr.length < 3) {
  //       throw new Error('Input must be an array with at least 3 elements.');
  //     }

  //     const shuffled = arr.slice(); // Create a copy of the original array
  //     for (let i = shuffled.length - 1; i > 0; i--) {
  //       const j = Math.floor(Math.random() * (i + 1));
  //       [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Shuffle the elements
  //     }

  //     return shuffled.slice(0, 2); // Return the first 3 elements
  //   }

  //   if (dusterOptions.length > 2) {
  //     const random = selectThreeRandomItems(dusterOptions);
  //     setOptions(random);
  //   }
  //   setShufflingoptions(false);
  // };

  // const cancelDuster = () => {
  //   removeDusterFromClean();
  // };

  // console.log(isUser);

  const signMeUp = async () => {
    if (password !== confirmP) {
      setError('Passwords do not match');
      return setTimeout(() => setError(null), 2000);
    } else {
      await signUpNewUser({
        name,
        surname,
        cellphone,
        email,
        password,
      });
    }
  };

  return (
    <div className="mb-3 mt-4 text-center">
      {/* {isUser !== null && (
        <Row>
          <Col xs={6}>
            <h5 style={{ marginTop: '10px', textAlign: 'center' }}>
              Would you like to select a Duster?
            </h5>
          </Col>
          <Col xs={6}>
            {shufflingOptions ? (
              ''
            ) : (
              <Button variant="outline-info" onClick={shuffleOptions}>
                Shuffle duster options
              </Button>
            )}
          </Col>
        </Row>
      )} */}

      <hr />
      {/* 
      {dusterId === null ? (
        isUser === null ? (
          ''
        ) : (
          <Row style={{ marginTop: '20px' }}>
            {lastDuster === null ? (
              <Col>
                <Spinner variant="primary" animation="grow" />
                <p>Fetching your your last duster...</p>
              </Col>
            ) : lastDuster === 'No Previous duster' ? (
              ''
            ) : (
              <DusterCard
                duster={lastDuster}
                lastDuster={true}
                selectDusterForClean={selectDusterForClean}
              />
            )}
            {dusterRating === null ? (
              <Col>
                <Spinner variant="primary" animation="grow" />
                <p>Fetching dusters in your area...</p>
              </Col>
            ) : (
              <DusterSelector
                dusterRating={dusterRating}
                selectDusterForClean={selectDusterForClean}
              />
            )}
          </Row>
        )
      ) : (
        <Row style={{ marginTop: '20px', textAlign: 'center' }}>
          <Col xs={4}>
            <h4 style={{ color: 'grey' }}>You have chosen</h4>
            <DusterCard duster={dusterId.duster} selected={true} />
          </Col>
          {chosenDusterSkills !== null && (
            <Col xs={8} style={{ marginTop: '25px' }}>
              <Alert variant="info">{dusterId.duster.name} info;</Alert>
              <Row>
                <Col xs={4}>
                  <b>Experience:</b>
                </Col>
                <Col xs={8}>{chosenDusterSkills.exp}</Col>
              </Row>
              <hr style={{ width: '70%' }} />
              <h5 style={{ marginTop: '10px' }}>Cleaning competencies</h5>

              <Row style={{ marginTop: '15px' }}>
                <Col>
                  <Button
                    variant={
                      chosenDusterSkills.office
                        ? 'outline-success'
                        : 'outline-danger'
                    }
                    disabled
                  >
                    Office
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={
                      chosenDusterSkills.hotel
                        ? 'outline-success'
                        : 'outline-danger'
                    }
                    disabled
                  >
                    Hotel
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={
                      chosenDusterSkills.home
                        ? 'outline-success'
                        : 'outline-danger'
                    }
                    disabled
                  >
                    Home
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={
                      chosenDusterSkills.medical
                        ? 'outline-success'
                        : 'outline-danger'
                    }
                    disabled
                  >
                    Medical
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={
                      chosenDusterSkills.retail
                        ? 'outline-sucess'
                        : 'outline-danger'
                    }
                    disabled
                  >
                    Retail
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={
                      chosenDusterSkills.eot
                        ? 'outline-success'
                        : 'outline-danger'
                    }
                    disabled
                    style={{ marginTop: '10px' }}
                  >
                    End of tenancy
                  </Button>
                </Col>
              </Row>
              <Button
                variant="warning"
                style={{ marginTop: '20px', color: 'white' }}
                onClick={cancelDuster}
              >
                Cancel Selection
              </Button>
            </Col>
          )}
        </Row>
      )} */}

      <h5 className="text-center" style={{ marginTop: '10px' }}>
        Please let us know how to access your property?
      </h5>
      <Row>
        <Col md={3} xs={12}>
          <Button
            variant={
              how === 'Some One Is At Home' ? 'info' : 'outline-secondary'
            }
            value="Some One Is At Home"
            block
            onClick={(e) => setHow(e.target.value)}
          >
            Someone at home
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Button
            variant={how === 'Key Safe' ? 'info' : 'outline-secondary'}
            value="Key Safe"
            block
            onClick={(e) => setHow(e.target.value)}
          >
            Key Safe
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Button
            variant={how === 'Key Hidden' ? 'info' : 'outline-secondary'}
            value="Key Hidden"
            onClick={(e) => setHow(e.target.value)}
            block
          >
            Key hidden
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Button
            variant={how === 'Other' ? 'info' : 'outline-secondary'}
            value="Other"
            onClick={(e) => setHow(e.target.value)}
            block
          >
            Other
          </Button>
        </Col>
      </Row>
      {moreInfo && how !== 'Some One Is At Home' && (
        <div className="mt-4" align="center">
          <h5 className="text-center">{how}</h5>
          <InputGroup>
            <FormControl
              as="textarea"
              placeholder={`${moreInfo}`}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </InputGroup>
        </div>
      )}

      {isUser ? (
        jamming ? (
          how === 'Some One Is At Home' ? (
            <Button
              variant="outline-success mt-3"
              as={Link}
              to="/booking/check-out-carpert-clean"
              block
            >
              Check out
            </Button>
          ) : note.length > 3 ? (
            <Button
              variant="outline-success mt-3"
              as={Link}
              to="/booking/check-out-carpert-clean"
              block
            >
              Check out
            </Button>
          ) : (
            <Button variant="secondary mt-3" block disabled>
              How do we get into your home to clean?
            </Button>
          )
        ) : regular ? (
          how === 'Some One Is At Home' ? (
            <Button
              variant="outline-success mt-3"
              as={Link}
              to="/booking/repeat-checkout"
              block
            >
              Check out <i className="ml-2 fas fa-shopping-cart"></i>
            </Button>
          ) : note.length > 3 ? (
            <Button
              variant="outline-success mt-3"
              as={Link}
              to="/booking/repeat-checkout"
              block
            >
              Check out <i className="ml-2 fas fa-shopping-cart"></i>
            </Button>
          ) : (
            <Button variant="secondary mt-3" block disabled>
              How do we get into your home to clean?
            </Button>
          )
        ) : how === 'Some One Is At Home' ? (
          <Button
            variant="outline-success mt-3"
            as={Link}
            to="/booking/check-out"
            block
          >
            Check out <i className="ml-2 fas fa-shopping-cart"></i>
          </Button>
        ) : note.length > 3 ? (
          <Button
            variant="outline-success mt-3"
            as={Link}
            to="/booking/check-out"
            block
          >
            Check out <i className="ml-2 fas fa-shopping-cart"></i>
          </Button>
        ) : (
          <Button variant="secondary mt-3" block disabled>
            How do we get into your home to clean?
          </Button>
        )
      ) : (
        <>
          {newUserCreated ? (
            <EmailVerifiedModal email={email} />
          ) : (
            <Button variant="warning mt-4" onClick={handleShow}>
              Please create account to continue
            </Button>
          )}

          <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Create account</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group>
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Surname:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Surname"
                  name="surname"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />

                <Form.Group>
                  <Form.Label>Cellphone:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Cellphone Number"
                    name="cellphone"
                    value={cellphone}
                    onChange={(e) => setCellphone(e.target.value)}
                  />
                </Form.Group>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email Address:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Please enter your email address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted">
                  We will send you a confirmation link, please enter correct
                  email
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Please don't make this password easy to guess"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Text className="text-muted">
                  Please enter a password that is longer than 7 characters
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm Password:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Please confirm your password"
                  name="password2"
                  value={confirmP}
                  onChange={(e) => setConfirmP(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            {error && <Alert variant="warning">{error}</Alert>}
            <Modal.Footer>
              <Button variant="outline-success" onClick={signMeUp}>
                Create Account
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default NityGrity;
