import {
  SET_MOTHER_RATE,
  SET_ORDER_ADDRESS,
  SET_ORDER_ADDRESSTYPE,
  SET_ORDER_COMPLEX,
  SET_ORDER_COORDINATES,
  SET_ORDER_BED,
  SET_ORDER_BATH,
  SET_OVERALL_HOURS,
  SET_ORDER_INVOICE,
  CHOOSE_IRONING,
  SET_LAUNDREY_BASKET,
  CHOOSE_BEDDING,
  CHOOSE_FRIDGE,
  CHOOSE_KITCHEN,
  CHOOSE_WINDOWS,
  CHOOSE_OVEN,
  SET_USER_ORDERTIME,
  SET_HOW_TO_GETIN,
  SET_ORDER_WEEKDAYS,
  SET_ORDER_SCHEDULE,
  SET_ORDER_FREQUENCY,
  CLEAR_ORDER_SCHEDULE,
  TOGGLE_ORDER_TYPE,
  SET_ORDER_RE_INVOICE,
  SET_ORDER_EXTRAS,
  USER_CREATED_ORDER,
  USER_CREATED_REORDER,
  CLEAR_CREATED_ORDERS,
} from '../../actions/types';
const initState = {
  mother: null,
  loading: true,
  orderHours: 4,
  invoice: 295,
  regInvoice: 0,
  text: null,
  complex: null,
  coordinates: null,
  addressType: null,
  bedroom: 0,
  bathroom: 0,
  myBedding: false,
  ironing: false,
  kitchenCubs: false,
  insideOven: false,
  myWindows: false,
  myFridge: false,
  mrx: [],
  laundreyBasket: 0,
  orderTime: null,
  how: { a: '', b: '' },
  weekdays: [],
  schedule: [],
  frequency: 0,
  createdOrder: null,
  createdReOrder: null,
};

function clientBookingReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MOTHER_RATE:
      return {
        ...state,
        loading: false,
        mother: payload,
      };
    case SET_OVERALL_HOURS:
      return {
        ...state,
        orderHours: payload,
      };
    case SET_ORDER_INVOICE:
      return {
        ...state,
        invoice: payload,
      };
    case SET_ORDER_RE_INVOICE:
      return {
        ...state,
        regInvoice: payload,
      };
    case SET_ORDER_ADDRESS:
      return {
        ...state,
        text: payload,
      };
    case SET_ORDER_COMPLEX:
      return {
        ...state,
        complex: payload,
      };
    case SET_ORDER_COORDINATES:
      return {
        ...state,
        coordinates: payload,
      };
    case SET_ORDER_ADDRESSTYPE:
      return {
        ...state,
        addressType: payload,
      };
    case SET_ORDER_BED:
      return {
        ...state,
        bedroom: payload,
      };
    case SET_ORDER_BATH:
      return {
        ...state,
        bathroom: payload,
      };
    case CHOOSE_IRONING:
      return {
        ...state,
        ironing: payload,
      };
    case SET_LAUNDREY_BASKET:
      return {
        ...state,
        laundreyBasket: payload,
      };
    case CHOOSE_BEDDING:
      return {
        ...state,
        myBedding: payload,
      };
    case CHOOSE_FRIDGE:
      return {
        ...state,
        myFridge: payload,
      };
    case CHOOSE_KITCHEN:
      return {
        ...state,
        kitchenCubs: payload,
      };
    case CHOOSE_WINDOWS:
      return {
        ...state,
        myWindows: payload,
      };
    case CHOOSE_OVEN:
      return {
        ...state,
        insideOven: payload,
      };
    case SET_USER_ORDERTIME:
      return {
        ...state,
        orderTime: payload,
      };
    case SET_HOW_TO_GETIN:
      return {
        ...state,
        how: payload,
      };
    case TOGGLE_ORDER_TYPE:
      return {
        ...state,
        regular: payload,
      };
    case SET_ORDER_WEEKDAYS:
      return {
        ...state,
        weekdays: payload,
      };
    case SET_ORDER_SCHEDULE:
      return {
        ...state,
        schedule: payload,
      };
    case SET_ORDER_FREQUENCY:
      return {
        ...state,
        frequency: payload,
      };
    case SET_ORDER_EXTRAS:
      return {
        ...state,
        mrx: payload,
      };
    case CLEAR_ORDER_SCHEDULE:
      return {
        ...state,
        schedule: [],
        frequency: 0,
      };
    case USER_CREATED_ORDER:
      return {
        ...state,
        createdOrder: payload,
      };
    case USER_CREATED_REORDER:
      return {
        ...state,
        createdReOrder: payload,
      };
    case CLEAR_CREATED_ORDERS:
      return {
        ...state,
        createdOrder: null,
        createdReOrder: null,
      };
    default:
      return state;
  }
}

export default clientBookingReducer;
