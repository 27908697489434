import { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  jamCarpertCleanLocation,
  createCarpertClean,
} from '../../../../actions/user/carpert_cleaning';
import { peachPayment } from '../../../../actions/user/payment';
import { connect } from 'react-redux';
import AddressButton from './AddressButton';
import CarpertCleaningReview from './CarpertCleaningReview';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Bottom from '../../../Layout/Bottom';

const CarpertCleaningCheckOut = ({
  user,
  carpert_cleaning: {
    minimum_call_out,
    carpert_invoice,
    carpert_address,
    carpert_address_type,
    carpert_complex,
    carpert_coordinates,
    carpert_cleaning_date,
    morning_shift,
    carpert_nity,
    main_bedroom,
    bedroom,
    long_passage,
    short_passage,
    stair_case,
    landing,
    family_room,
    lounge,
    dining_room,
    study,
    dressing_room,
    other_room,
    small_rug,
    med_rug,
    large_rug,
    tub_chair,
    wingback,
    one_seater_sofa,
    two_seater_sofa,
    three_seater_sofa,
    three_seater_el,
    four_seater_el,
    five_seater_el,
    single_mattress,
    double_mattress,
    queen_mattress,
    king_mattress,
    small_ottoman,
    medium_ottoman,
    large_ottoman,
    dining_chair_base_only,
    dining_chair_base_back_arms,
    carpert_clean,
  },
  jamCarpertCleanLocation,
  createCarpertClean,
  peachPayment,
  peachLoad,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [review, setReview] = useState([]);
  const [inv, setInv] = useState(0);
  const [show, setShow] = useState(false);
  const [carpertId, setCarpertId] = useState();
  const handleClose = () => {
    btn.current.removeAttribute('disabled');
    setShow(false);
  };
  useEffect(() => {
    if (carpert_invoice > minimum_call_out) {
      setInv(carpert_invoice);
    } else {
      setInv(minimum_call_out);
    }
  }, [carpert_invoice, minimum_call_out]);

  useEffect(() => {
    if (peachLoad) {
      const script = document.createElement('script');
      script.src = `https://${process.env.REACT_APP_PEACH_BASE_URL}/v1/paymentWidgets.js?checkoutId=${peachLoad.id}`;
      document.body.append(script);

      window.scrollTo(0, 0);
      setShow(true);
    }
  }, [peachLoad]);

  useEffect(() => {
    if (carpert_clean) {
      console.log('Jam peach payment');
      let orderId = carpert_clean._id;
      let invoice = carpert_clean.invoice;
      let amount = parseFloat(invoice).toFixed(2);
      setCarpertId(orderId);
      peachPayment({ amount, orderId });
    }
  }, [carpert_clean, peachPayment]);

  useEffect(() => {
    let jam = [];
    if (main_bedroom > 0) {
      let a = {
        name: 'Main Bedroom',
        num: main_bedroom,
      };
      jam.push(a);
    }
    if (bedroom > 0) {
      let a = {
        name: 'Bedroom',
        num: bedroom,
      };
      jam.push(a);
    }
    if (long_passage > 0) {
      let a = {
        name: 'Long passage',
        num: long_passage,
      };
      jam.push(a);
    }
    if (short_passage > 0) {
      let a = {
        name: 'Short passage',
        num: short_passage,
      };
      jam.push(a);
    }
    if (stair_case > 0) {
      let a = {
        name: 'Staircase',
        num: stair_case,
      };
      jam.push(a);
    }
    if (landing > 0) {
      let a = {
        name: 'Landing',
        num: landing,
      };
      jam.push(a);
    }
    if (family_room > 0) {
      let a = {
        name: 'Family Room',
        num: family_room,
      };
      jam.push(a);
    }
    if (lounge > 0) {
      let a = {
        name: 'Lounge',
        num: lounge,
      };
      jam.push(a);
    }
    if (dining_room > 0) {
      let a = {
        name: 'Dining room',
        num: dining_room,
      };
      jam.push(a);
    }
    if (study > 0) {
      let a = {
        name: 'Study',
        num: study,
      };
      jam.push(a);
    }
    if (dressing_room > 0) {
      let a = {
        name: 'Staircase',
        num: dressing_room,
      };
      jam.push(a);
    }
    if (other_room > 0) {
      let a = {
        name: 'Other room',
        num: other_room,
      };
      jam.push(a);
    }

    if (small_rug > 0) {
      let a = {
        name: 'Small rug',
        num: small_rug,
      };
      jam.push(a);
    }

    if (med_rug > 0) {
      let a = {
        name: 'Medium rug',
        num: med_rug,
      };
      jam.push(a);
    }

    if (large_rug > 0) {
      let a = {
        name: 'Large rug',
        num: large_rug,
      };
      jam.push(a);
    }

    if (tub_chair > 0) {
      let a = {
        name: 'Tub chair',
        num: tub_chair,
      };
      jam.push(a);
    }

    if (wingback > 0) {
      let a = {
        name: 'Wingback',
        num: wingback,
      };
      jam.push(a);
    }

    if (one_seater_sofa > 0) {
      let a = {
        name: 'One seater sofa',
        num: one_seater_sofa,
      };
      jam.push(a);
    }
    if (two_seater_sofa > 0) {
      let a = {
        name: 'Two seater sofa',
        num: two_seater_sofa,
      };
      jam.push(a);
    }

    if (three_seater_sofa > 0) {
      let a = {
        name: 'Three seater sofa',
        num: three_seater_sofa,
      };
      jam.push(a);
    }

    if (three_seater_el > 0) {
      let a = {
        name: 'Three seater El',
        num: three_seater_el,
      };
      jam.push(a);
    }

    if (four_seater_el > 0) {
      let a = {
        name: 'Four seater El',
        num: four_seater_el,
      };
      jam.push(a);
    }

    if (five_seater_el > 0) {
      let a = {
        name: 'Five seater El',
        num: five_seater_el,
      };
      jam.push(a);
    }

    if (single_mattress > 0) {
      let a = {
        name: 'Single mattress',
        num: single_mattress,
      };
      jam.push(a);
    }

    if (double_mattress > 0) {
      let a = {
        name: 'Double mattress',
        num: double_mattress,
      };
      jam.push(a);
    }

    if (queen_mattress > 0) {
      let a = {
        name: 'Queen mattress',
        num: queen_mattress,
      };
      jam.push(a);
    }

    if (king_mattress > 0) {
      let a = {
        name: 'King mattress',
        num: king_mattress,
      };
      jam.push(a);
    }
    if (small_ottoman > 0) {
      let a = {
        name: 'Small Ottoman',
        num: small_ottoman,
      };
      jam.push(a);
    }

    if (medium_ottoman > 0) {
      let a = {
        name: 'Medium Ottoman',
        num: medium_ottoman,
      };
      jam.push(a);
    }

    if (large_ottoman > 0) {
      let a = {
        name: 'Large Ottoman',
        num: large_ottoman,
      };
      jam.push(a);
    }

    if (dining_chair_base_only > 0) {
      let a = {
        name: 'Dining chair base only',
        num: dining_chair_base_only,
      };
      jam.push(a);
    }

    if (dining_chair_base_back_arms > 0) {
      let a = {
        name: 'Dining chair base, back & arms',
        num: dining_chair_base_back_arms,
      };
      jam.push(a);
    }

    setReview(jam);
  }, [
    main_bedroom,
    bedroom,
    long_passage,
    short_passage,
    stair_case,
    landing,
    family_room,
    lounge,
    dining_room,
    study,
    dressing_room,
    other_room,
    small_rug,
    med_rug,
    large_rug,
    tub_chair,
    wingback,
    one_seater_sofa,
    two_seater_sofa,
    three_seater_sofa,
    three_seater_el,
    four_seater_el,
    five_seater_el,
    single_mattress,
    double_mattress,
    queen_mattress,
    king_mattress,
    small_ottoman,
    medium_ottoman,
    large_ottoman,
    dining_chair_base_only,
    dining_chair_base_back_arms,
    setReview,
  ]);
  let btn = useRef();
  const ChangeAddress = (a) => {
    let address = a.text;
    let coordinates = a.coordinates;
    let complex = a.complex;
    let addressType = a.addressType;
    jamCarpertCleanLocation({ address, addressType, complex, coordinates });
  };

  const defaultAddress = () => {
    let address = user.address;
    let coordinates = user.coordinates;
    let complex = user.complex;
    let addressType = user.addressType;
    jamCarpertCleanLocation({ address, addressType, complex, coordinates });
  };

  const placeOrder = () => {
    let paymentMethod = 'peach';
    let from = 'web';
    let address = carpert_address;
    let complex = carpert_complex;
    let addressType = carpert_address_type;
    let coordinates = carpert_coordinates;
    let whatToDo = review;
    let invoice = inv;
    createCarpertClean({
      address,
      coordinates,
      complex,
      addressType,
      carpert_cleaning_date,
      morning_shift,
      carpert_nity,
      whatToDo,
      invoice,
      paymentMethod,
      from,
    });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        autoFocus={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form
            action={`${process.env.REACT_APP_BASE_URL}user/my-carpert-clean/${carpertId}/`}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </Modal.Body>
      </Modal>
      <Container>
        <Row>
          <Col md={7} xs={12}>
            <Alert variant="info">
              <Row>
                <Col>
                  <h3 className="text-info text-center">
                    Review booking and make payment
                  </h3>
                </Col>
                <Col>
                  <AddressButton
                    ChangeAddress={ChangeAddress}
                    defaultAddress={defaultAddress}
                    user={user}
                  />
                </Col>
              </Row>
            </Alert>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b> Date:</b>{' '}
                {moment(carpert_cleaning_date).format('MMMM Do YYYY')} @{' '}
                <b>Location:</b>
                {carpert_address}
              </ListGroup.Item>
              {review.length < 0
                ? 'Loading your carpert clean'
                : review.map((rev) => (
                    <CarpertCleaningReview key={rev.name} rev={rev} />
                  ))}
            </ListGroup>
            <Row className="mt-3">
              <Col>
                <Link to="/user/booking">
                  <Alert variant="secondary text-center">
                    <i className="mr-3 fas fa-step-backward"></i> Edit Order!
                  </Alert>
                </Link>
              </Col>
              <Col>
                <Alert variant="info text-info text-center text-white">
                  Your total is: <b>R{inv}</b>{' '}
                  <i className="far fa-credit-card"></i>
                </Alert>
              </Col>
            </Row>
          </Col>
          <Col md={5} xs={12}>
            <Card className="text-center">
              <Alert variant="success">
                <h5>
                  Pay Here: <i className="ml-3 fas fa-cash-register"></i>
                </h5>
              </Alert>
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '4em' }}>
                    <i className="fas fa-credit-card text-success"></i>
                  </span>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-success">
                  Thank you for considering our services
                </Card.Subtitle>
                <hr />
                <Button
                  variant="success mt-2"
                  onClick={placeOrder}
                  ref={btn}
                  block
                >
                  Secure Card Payment R{inv}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Bottom />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  peachLoad: state.orders.peachLoad,
  carpert_cleaning: state.carpert_cleaning,
});

export default connect(mapStateToProps, {
  jamCarpertCleanLocation,
  createCarpertClean,
  peachPayment,
})(CarpertCleaningCheckOut);
