import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  createCarpetMother,
  getCarpetMother,
  editCarpetMother,
} from '../../../../../actions/admin/main';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

const CarpetMother = ({
  carpet_mother,
  createCarpetMother,
  getCarpetMother,
  editCarpetMother,
  note,
}) => {
  const [carpetId, setCarpetId] = useState(null);
  const [show, setShow] = useState(false);
  const [mainBed, setMainBed] = useState();
  const [bed, setBed] = useState();
  const [longPassage, setLongPassage] = useState();
  const [shortPassage, setShortPassage] = useState();
  const [staircases, setStaircases] = useState();
  const [landing, setLanding] = useState();
  const [famRoom, setFamRoom] = useState();
  const [lounge, setLounge] = useState();
  const [diningRoom, setDiningRoom] = useState();
  const [study, setStudy] = useState();
  const [dressingRoom, setDressingRoom] = useState();
  const [otherRoom, setOtherRoom] = useState();
  const [smallRug, setSmallRug] = useState();
  const [medRug, setMedRug] = useState();
  const [lgRug, setLgRug] = useState();
  const [tubChair, setTubChair] = useState();
  const [wingBack, setWingBack] = useState();
  const [oneSeaterSofa, setOneSeaterSofa] = useState();
  const [twoSeaterSofa, setTwoSeaterSofa] = useState();
  const [threeSeaterSofa, setThreeSeaterSofa] = useState();
  const [elShapedThree, setElShapedThree] = useState();
  const [elShapedFour, setElShapedFour] = useState();
  const [elShapedFive, setElShapedFive] = useState();
  const [singleMattress, setSingleMattress] = useState();
  const [doubleMattress, setdoubleMattress] = useState();
  const [queenMattress, setQueenMattress] = useState();
  const [kingMattress, setKingMattress] = useState();
  const [smallOttoman, setSmallOttoman] = useState();
  const [mediumOttoman, setMediumOttoman] = useState();
  const [largeOttoman, setLargeOttoman] = useState();
  const [chairBase, setChairBase] = useState();
  const [chairEverything, setChairEverything] = useState();
  const [nte, setMsg] = useState(note);
  useEffect(() => {
    getCarpetMother();
  }, [getCarpetMother]);

  useEffect(() => {
    if (carpet_mother) {
      setCarpetId(carpet_mother._id);
      setMainBed(carpet_mother.mainBed);
      setBed(carpet_mother.bed);
      setLongPassage(carpet_mother.longPassage);
      setShortPassage(carpet_mother.shortPassage);
      setStaircases(carpet_mother.staircases);
      setLanding(carpet_mother.landing);
      setFamRoom(carpet_mother.famRoom);
      setLounge(carpet_mother.lounge);
      setDiningRoom(carpet_mother.diningRoom);
      setStudy(carpet_mother.study);
      setDressingRoom(carpet_mother.dressingRoom);
      setOtherRoom(carpet_mother.otherRoom);
      setSmallRug(carpet_mother.smallRug);
      setMedRug(carpet_mother.medRug);
      setLgRug(carpet_mother.lgRug);
      setTubChair(carpet_mother.tubChair);
      setWingBack(carpet_mother.wingBack);
      setOneSeaterSofa(carpet_mother.oneSeaterSofa);
      setTwoSeaterSofa(carpet_mother.twoSeaterSofa);
      setThreeSeaterSofa(carpet_mother.threeSeaterSofa);
      setElShapedThree(carpet_mother.elShapedThree);
      setElShapedFour(carpet_mother.elShapedFour);
      setElShapedFive(carpet_mother.elShapedFive);
      setSingleMattress(carpet_mother.singleMattress);
      setdoubleMattress(carpet_mother.doubleMattress);
      setQueenMattress(carpet_mother.queenMattress);
      setKingMattress(carpet_mother.kingMattress);
      setSmallOttoman(carpet_mother.smallOttoman);
      setMediumOttoman(carpet_mother.mediumOttoman);
      setLargeOttoman(carpet_mother.largeOttoman);
      setChairBase(carpet_mother.chairBase);
      setChairEverything(carpet_mother.chairEverything);
    }
  }, [carpet_mother]);

  useEffect(() => {
    if (note && editBtn.current) {
      setMsg(note);
      editBtn.current.removeAttribute('disabled');
      window.scrollTo(0, 0);
      handleClose();
    } else if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
      window.scrollTo(0, 0);
      handleClose();
    }
  }, [setMsg, note, nte]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();
  let editBtn = useRef();
  const createMother = () => {
    createCarpetMother({
      mainBed,
      bed,
      longPassage,
      shortPassage,
      staircases,
      landing,
      famRoom,
      lounge,
      diningRoom,
      study,
      dressingRoom,
      otherRoom,
      smallRug,
      medRug,
      lgRug,
      tubChair,
      wingBack,
      oneSeaterSofa,
      twoSeaterSofa,
      threeSeaterSofa,
      elShapedThree,
      elShapedFour,
      elShapedFive,
      singleMattress,
      doubleMattress,
      queenMattress,
      kingMattress,
      smallOttoman,
      mediumOttoman,
      largeOttoman,
      chairBase,
      chairEverything,
    });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  const editCarpetRate = (e) => {
    e.preventDefault();
    editCarpetMother({
      bed,
      longPassage,
      shortPassage,
      staircases,
      landing,
      famRoom,
      lounge,
      diningRoom,
      study,
      dressingRoom,
      otherRoom,
      smallRug,
      medRug,
      lgRug,
      tubChair,
      wingBack,
      oneSeaterSofa,
      twoSeaterSofa,
      threeSeaterSofa,
      elShapedThree,
      elShapedFour,
      elShapedFive,
      singleMattress,
      doubleMattress,
      queenMattress,
      kingMattress,
      smallOttoman,
      mediumOttoman,
      largeOttoman,
      chairBase,
      chairEverything,
      carpetId,
    });

    if (editBtn.current) {
      editBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <>
      <Button variant="outline-info float-right" size="sm" onClick={handleShow}>
        Carpet cleaning rate
      </Button>
      <Modal show={show} size="xl" onHide={handleClose}>
        <Form onSubmit={editCarpetRate}>
          <Modal.Header closeButton>
            <Modal.Title>Carpet cleaning rate card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Main Bedroom</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={mainBed}
                    name="mainBed"
                    value={mainBed}
                    onChange={(e) => setMainBed(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Bedroom</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={bed}
                    name="bed"
                    value={bed}
                    onChange={(e) => setBed(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Long passage</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={longPassage}
                    name="longPassage"
                    value={longPassage}
                    onChange={(e) => setLongPassage(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Short passage</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={shortPassage}
                    name="shortPassage"
                    value={shortPassage}
                    onChange={(e) => setShortPassage(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Staircase</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={staircases}
                    name="staircases"
                    value={staircases}
                    onChange={(e) => setStaircases(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Landing</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={landing}
                    name="landing"
                    value={landing}
                    onChange={(e) => setLanding(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Family Room</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={famRoom}
                    name="famRoom"
                    value={famRoom}
                    onChange={(e) => setFamRoom(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Lounge</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={lounge}
                    name="lounge"
                    value={lounge}
                    onChange={(e) => setLounge(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Dining Room</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={diningRoom}
                    name="diningRoom"
                    value={diningRoom}
                    onChange={(e) => setDiningRoom(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Study</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={study}
                    name="study"
                    value={study}
                    onChange={(e) => setStudy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Dressing Room</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={dressingRoom}
                    name="dressingRoom"
                    value={dressingRoom}
                    onChange={(e) => setDressingRoom(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Other Room</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={otherRoom}
                    name="otherRoom"
                    value={otherRoom}
                    onChange={(e) => setOtherRoom(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Small Rug</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={smallRug}
                    name="smallRug"
                    value={smallRug}
                    onChange={(e) => setSmallRug(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Medium Rug</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={medRug}
                    name="medRug"
                    value={medRug}
                    onChange={(e) => setMedRug(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Large Rug</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={lgRug}
                    name="lgRug"
                    value={lgRug}
                    onChange={(e) => setLgRug(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Tub Chair</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={tubChair}
                    name="tubChair"
                    value={tubChair}
                    onChange={(e) => setTubChair(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Wingback</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={wingBack}
                    name="wingBack"
                    value={wingBack}
                    onChange={(e) => setWingBack(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>One Seater Sofa</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={oneSeaterSofa}
                    name="oneSeaterSofa"
                    value={oneSeaterSofa}
                    onChange={(e) => setOneSeaterSofa(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Two Seater Sofa</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={twoSeaterSofa}
                    name="twoSeaterSofa"
                    value={twoSeaterSofa}
                    onChange={(e) => setTwoSeaterSofa(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Three Seater Sofa</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={threeSeaterSofa}
                    name="threeSeaterSofa"
                    value={threeSeaterSofa}
                    onChange={(e) => setThreeSeaterSofa(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Single Mattress</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={singleMattress}
                    name="singleMattress"
                    value={singleMattress}
                    onChange={(e) => setSingleMattress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Double Mattress</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={doubleMattress}
                    name="doubleMattress"
                    value={doubleMattress}
                    onChange={(e) => setdoubleMattress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Queen Mattress</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={queenMattress}
                    name="queenMattress"
                    value={queenMattress}
                    onChange={(e) => setQueenMattress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>King Mattress</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={kingMattress}
                    name="kingMattress"
                    value={kingMattress}
                    onChange={(e) => setKingMattress(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>L Shaped 3 seater</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={elShapedThree}
                    name="elShapedThree"
                    value={elShapedThree}
                    onChange={(e) => setElShapedThree(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>L Shaped 4 seater</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={elShapedFour}
                    name="elShapedFour"
                    value={elShapedFour}
                    onChange={(e) => setElShapedFour(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>L Shaped 5 seater</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={elShapedFive}
                    name="elShapedFive"
                    value={elShapedFive}
                    onChange={(e) => setElShapedFive(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Small Ottoman</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={smallOttoman}
                    name="smallOttoman"
                    value={smallOttoman}
                    onChange={(e) => setSmallOttoman(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Medium Ottoman</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={mediumOttoman}
                    name="mediumOttoman"
                    value={mediumOttoman}
                    onChange={(e) => setMediumOttoman(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Large Ottoman</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={largeOttoman}
                    name="largeOttoman"
                    value={largeOttoman}
                    onChange={(e) => setLargeOttoman(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Base Only</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={chairBase}
                    name="chairBase"
                    value={chairBase}
                    onChange={(e) => setChairBase(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Base, back & arms</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={chairEverything}
                    name="chairEverything"
                    value={chairEverything}
                    onChange={(e) => setChairEverything(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleClose}>
              Cancel
            </Button>

            {carpetId ? (
              <Button variant="outline-success" type="submit" ref={editBtn}>
                Edit Carpert Pricing
              </Button>
            ) : (
              <Button
                variant="outline-success"
                onClick={() => createMother()}
                ref={btn}
              >
                Create Carpert Pricing
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  carpet_mother: state.main.carpet_mother,
});

export default connect(mapStateToProps, {
  createCarpetMother,
  getCarpetMother,
  editCarpetMother,
})(CarpetMother);
