import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getTheOrder,
  getDusterPool,
  validateCheckin,
  acceptCheckingRequest,
  changeDate,
  dusterCheckIn,
  dusterCheckOut,
  deleteOrder,
  markBookingComplete,
} from '../../../actions/admin/orders';
import {
  Alert,
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
  Image,
  Button,
} from 'react-bootstrap';
import moment from 'moment';
import Cleaner from './Cleaner';
import Duster from './Duster';
import ReSchedule from './ReSchedule';
import Validate from './Validate';
// import DeleteOrder from './DeleteOrder';

const OrderAdmin = ({
  getTheOrder,
  admin_orders: { order, cleaners, validationDistance },
  getDusterPool,
  validateCheckin,
  acceptCheckingRequest,
  dusterCheckIn,
  dusterCheckOut,
  note,
  changeDate,
  deleteOrder,
  markBookingComplete,
}) => {
  const { orderId } = useParams();
  const [customer, SetCustomer] = useState(null);
  const [customerEmail, SetCustomerEmail] = useState(null);
  const [cellphone, SetCellphone] = useState(null);
  const [code, setCode] = useState('');
  const [nityGrity, setNitygrity] = useState(null);
  const [duster, SetDuster] = useState(undefined);
  const [orderTime, SetOrderTime] = useState(null);
  const [invoiceHours, setInvoiceHours] = useState(null);
  const [areaCleaners, SetAreaCleaners] = useState([]);
  const [validate, setValidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkOut, setCheckout] = useState(null);
  const [checkIn, setCheckin] = useState(null);
  const [tomorrow, setTomorrow] = useState(null);
  const [prettyDay, setPrettyDay] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [address, setAddress] = useState(null);
  const [addressType, setAddressType] = useState(null);
  const [complex, setComplex] = useState(null);
  const [omw, setOMW] = useState(null);
  const [extras, setExtras] = useState(null);
  const [checkOutCleaner, setCheckOutCleaner] = useState();
  const [checkInCleaner, setCheckInCleaner] = useState();
  const navigate = useNavigate();
  let deleteBtn = useRef();
  let mb = useRef();

  useEffect(() => {
    if (note && deleteBtn.current) {
      navigate(-1);
    }
  }, [note, deleteBtn, navigate]);

  useEffect(() => {
    if (note && mb.current) {
      mb.current.removeAttribute('disabled');
    }
  }, [note, mb]);

  useEffect(() => {
    if (note.length > 0) {
      getTheOrder(orderId);
    }
  }, [getTheOrder, note, orderId]);

  useEffect(() => {
    if (orderTime) {
      let checkInTime = new Date(orderTime);
      let rightnow = new Date();

      if (rightnow > checkInTime) {
        setCheckInCleaner(true);
      } else {
        setCheckInCleaner(false);
      }
    }
  }, [orderTime, setCheckInCleaner]);

  useEffect(() => {
    if (orderTime) {
      let checkoutTime = new Date(orderTime);
      checkoutTime.setHours(checkoutTime.getHours() + invoiceHours);
      let rightnow = new Date();

      if (rightnow > checkoutTime) {
        setCheckOutCleaner(true);
      } else {
        setCheckOutCleaner(false);
      }
    }
  }, [orderTime, invoiceHours]);

  useEffect(() => {
    getTheOrder(orderId);
  }, [getTheOrder, orderId]);

  useEffect(() => {
    if (validate) {
      validateCheckin(orderId);
    }
  }, [validate, validateCheckin, orderId]);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    setTomorrow(date);
  }, [setTomorrow]);

  useEffect(() => {
    if (order) {
      SetOrderTime(order.orderTime);
      setInvoiceHours(order.invoiceHours);
      SetDuster(order.team);
      setValidate(order.officeRequest);
      setCheckout(order.checkOut);
      setCheckin(order.checkIn);
      setOMW(order.onMyWay);
      SetCustomer(order.user.name);
      SetCustomerEmail(order.user.email);
      SetCellphone(order.user.cellphone);
      setCode(order.code);
      setNitygrity(order.nityGrity);
      setAddress(order.address.split(',').shift());
      setComplex(order.complex);
      if (order.address === order.user.address) {
        setAddressType(order.user.addressType);
      }
      setLoading(false);
      setExtras(order.mrx);
    }
    if (!order) {
      setLoading(true);
    }
  }, [order]);

  useEffect(() => {
    if (orderId !== null) {
      getDusterPool(orderId);
    }
  }, [getDusterPool, orderId]);

  useEffect(() => {
    if (cleaners) {
      SetAreaCleaners(cleaners);
    }
  }, [cleaners]);

  if (loading === true || order === null) {
    return (
      <Container>
        <Spinner variant="primary" animation="border" />
      </Container>
    );
  }
  const time = moment(orderTime).format('ddd MMM D, h:mm a');

  // const delOrder = () => {
  //   deleteOrder(orderId);
  //   if (deleteBtn.current) {
  //     deleteBtn.current.setAttribute('disabled', 'disabled');
  //   }
  // };

  const orderIsComplete = () => {
    const checkOut = moment(orderTime).add(invoiceHours, 'hours').format();
    let checkIn = orderTime;
    markBookingComplete({ orderId, checkIn, checkOut });

    if (mb.current) {
      mb.current.setAttribute('disabled', 'disabled');
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>
      <h4 style={{ color: '#06a5dd' }}>Order management</h4>
      <b>Customer Name:</b> {customer} | <b>Customer Email:</b> {customerEmail}{' '}
      | <b>Cellphone: </b> {cellphone} | <b>Creation Date: </b>{' '}
      {moment(order.timestamp).format('MMMM Do YYYY, h:mm')}
      <hr />
      <Row>
        <Col md={5} xs={12}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <b>Order address:</b> {address}{' '}
              {complex && ` | Complex Name: ${complex}`}
            </ListGroup.Item>
            <ListGroup.Item>Address Type: {addressType}</ListGroup.Item>
            <ListGroup.Item>
              {order.bedroom} Bedroom/s {order.bathroom} Bathroom/s
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Order Time:</b> {time} | {invoiceHours} hrs clean
            </ListGroup.Item>
            <ListGroup.Item>
              <b>How to get in:</b> {nityGrity.a}{' '}
              {nityGrity.b && `${nityGrity.b}`}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Extras :</b>{' '}
              {extras.length === 0
                ? 'No extras'
                : extras.map((extra) => <p key={extra.id}>{extra.name} </p>)}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Created From:</b> {order.from}{' '}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Payment Method:</b> {order.paymentMethod}
            </ListGroup.Item>
            {order.code === undefined ? (
              <b style={{ color: 'red' }}>Order not paid for!</b>
            ) : (
              <Image src={code} style={{ height: '250px', width: '250px' }} />
            )}
          </ListGroup>

          <ReSchedule
            orderTime={orderTime}
            tomorrow={tomorrow}
            setPrettyDay={setPrettyDay}
            setSelectedDay={setSelectedDay}
            prettyDay={prettyDay}
            selectedDay={selectedDay}
            orderId={orderId}
            changeDate={changeDate}
            invoiceHours={invoiceHours}
            duster={duster}
          />

          {checkOut === undefined && (
            <Button onClick={() => orderIsComplete()} ref={mb} block>
              Mark as complete
            </Button>
          )}
        </Col>
        <Col>
          <h5>Duster Buster details</h5>
          <hr />

          {duster === undefined ||
            (duster === null && (
              <>
                <h6 style={{ color: 'red', fontWeight: 'bold' }}>
                  Booking does not have a Duster
                </h6>
                <hr />
                <b>Available Dusters below: </b>
              </>
            ))}

          {duster === undefined || duster === null ? (
            areaCleaners.length > 0 ? (
              areaCleaners.map((ac) => (
                <Cleaner
                  key={ac._id}
                  ac={ac}
                  orderId={orderId}
                  orderTime={orderTime}
                  setLoading={setLoading}
                  invoiceHours={invoiceHours}
                />
              ))
            ) : (
              <Alert variant="danger">
                No Dusters Avavailable for this order!
              </Alert>
            )
          ) : (
            <>
              {validate && (
                <Validate
                  validationDistance={validationDistance}
                  acceptCheckingRequest={acceptCheckingRequest}
                  orderId={orderId}
                  note={note}
                />
              )}
              <Duster
                duster={duster}
                orderId={orderId}
                setLoading={setLoading}
                checkOut={checkOut}
                checkIn={checkIn}
                checkInCleaner={checkInCleaner}
                checkOutCleaner={checkOutCleaner}
                invoiceHours={invoiceHours}
                orderTime={orderTime}
                dusterCheckIn={dusterCheckIn}
                dusterCheckOut={dusterCheckOut}
                note={note}
                omw={omw}
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_orders: state.admin_orders,
  note: state.note,
});

export default connect(mapStateToProps, {
  getTheOrder,

  getDusterPool,
  validateCheckin,
  acceptCheckingRequest,
  changeDate,
  dusterCheckIn,
  dusterCheckOut,
  deleteOrder,
  markBookingComplete,
})(OrderAdmin);
