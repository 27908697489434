import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DusterPayslip,
  calculateTotalHours,
  calculateRepeatHours,
  getDusterPayslip,
  createDusterPayslip,
} from '../../../../../actions/admin/main';
import { getDuster, getRate } from '../../../../../actions/admin/team_members';
import {
  Container,
  Alert,
  Spinner,
  Table,
  Button,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PayslipBookings from './PayslipBookings';
import moment from 'moment';

const Payslip = ({
  DusterPayslip,
  main: {
    payslip,
    totalOnceOffHours,
    totalRepeatHours,
    loading,
    currentSlip,
    howManyOnceOff,
    howManyRepeat,
    duster_payslip,
  },
  getDuster,
  team_members: { dustBuster, rate },
  calculateTotalHours,
  calculateRepeatHours,
  getRate,
  getDusterPayslip,
  createDusterPayslip,
  note,
}) => {
  const [cRate, setRate] = useState(23);
  const [savePayslip, setSavePayslip] = useState(false);
  const [complete, setComplete] = useState(null);
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { dusterId } = useParams();

  useEffect(() => {
    if (note.length > 0) {
      handleClose();
    }
  }, [note]);

  useEffect(() => {
    if (complete === currentSlip.length) {
      setSavePayslip(true);
    } else {
      setSavePayslip(false);
    }
  }, [complete, currentSlip]);

  useEffect(() => {
    DusterPayslip(dusterId);
    getDuster(dusterId);
    getRate(dusterId);
    getDusterPayslip(dusterId);
  }, [DusterPayslip, getRate, getDuster, getDusterPayslip]);

  useEffect(() => {
    if (howManyOnceOff || howManyRepeat) {
      let num = howManyOnceOff + howManyRepeat;
      setComplete(num);
    }
  }, [howManyOnceOff, howManyRepeat]);

  useEffect(() => {
    if (rate) {
      setRate(rate.rate);
    } else {
      setRate(23);
    }
  }, [rate]);
  const navigate = useNavigate();

  useEffect(() => {
    let totalHours = totalOnceOffHours + totalRepeatHours;
    setTotal(totalHours * cRate);
  }, [totalOnceOffHours, totalRepeatHours, cRate]);

  if (loading || dustBuster === null)
    return <Spinner animation="grow" variant="primary" />;

  const goBack = () => {
    navigate(-1);
  };

  const approvePayslip = () => {
    const today = moment().set('date', 1).format('L');
    let dusterId = dustBuster._id;
    createDusterPayslip({ currentSlip, today, dusterId });
    setSavePayslip(false);
  };
  return (
    <Container>
      <Alert variant="info text-center">
        Payslip remittance for {dustBuster.name} {dustBuster.surname}
        <Button size="sm" variant="secondary float-left" onClick={goBack}>
          <i className="fa-solid fa-backward-step"></i>
        </Button>
        {savePayslip && (
          <Button size="sm" variant="warning float-right" onClick={handleShow}>
            Save Payslip
          </Button>
        )}
        {duster_payslip && (
          <p className="float-right">The cleaner has a payslip in this range</p>
        )}
      </Alert>
      <Table bordered>
        <thead>
          <tr>
            <th>Ref</th>
            <th>Date</th>
            <th>Customer</th>
            <th>Rate</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Actual Hours</th>
            <th>Expected Hours</th>
            <th>Tips</th>
            <th>Pay</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {payslip.map((pay, index) => (
            <PayslipBookings
              duster_payslip={duster_payslip}
              currentSlip={currentSlip}
              key={pay._id}
              order={pay}
              rate={cRate}
              calculateRepeatHours={calculateRepeatHours}
              calculateTotalHours={calculateTotalHours}
              totalOnceOffHours={totalOnceOffHours}
              totalRepeatHours={totalRepeatHours}
            />
          ))}
        </tbody>
      </Table>
      <hr />
      <Row>
        <Col>Payment Notice</Col>
        <Col>
          Rate of Pay <hr />R {cRate}
        </Col>
        <Col>
          Once Off Hours <hr />
          {totalOnceOffHours}
        </Col>
        <Col>
          Monthly Hours <hr /> {totalRepeatHours}
        </Col>
        <Col>
          Tips <hr />
        </Col>
        <Col>
          Total <hr />
          {total.toFixed(2)}
          <hr />
          <hr />
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">
          Do you want to save this payslip for {dustBuster.name}
          {dustBuster.surname}
        </Alert>
        <Modal.Body>
          {complete} cleans completed, R{total.toFixed(2)} payment
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" onClick={approvePayslip}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
  team_members: state.team_members,
  note: state.note,
});

export default connect(mapStateToProps, {
  DusterPayslip,
  getDuster,
  calculateTotalHours,
  calculateRepeatHours,
  getRate,
  getDusterPayslip,
  createDusterPayslip,
})(Payslip);
