import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  notBookedNum,
  runningLateNumber,
  inProgressNum,
  validateOrdersNum,
  missedOrdersNum,
  todaysOrdersNum,
  reNotBookedNum,
  lateRepeatCleanersNum,
  activeRepeatOrdersNum,
  validateReOrdersNum,
  missedReOrdersNum,
  repeatOrdersTodayNum,
  ordersNextTwoWeeksNum,
} from '../../../actions/admin/orders';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';

const AdminDashboard = ({
  admin_orders: {
    not_booked_num,
    running_late_num,
    in_progress_num,
    require_validation_num,
    missed_orders_num,
    todays_orders_num,
    re_orders_notbooked_num,
    re_orders_late_num,
    re_in_progress_num,
    repeat_validation_num,
    re_orders_missed_num,
    re_today_num,
    orders_next_two_num,
  },
  notBookedNum,
  runningLateNumber,
  inProgressNum,
  validateOrdersNum,
  missedOrdersNum,
  todaysOrdersNum,
  reNotBookedNum,
  lateRepeatCleanersNum,
  activeRepeatOrdersNum,
  validateReOrdersNum,
  missedReOrdersNum,
  repeatOrdersTodayNum,
  ordersNextTwoWeeksNum,
}) => {
  useEffect(() => {
    notBookedNum();
    runningLateNumber();
    inProgressNum();
    validateOrdersNum();
    missedOrdersNum();
    todaysOrdersNum();
    reNotBookedNum();
    lateRepeatCleanersNum();
    activeRepeatOrdersNum();
    validateReOrdersNum();
    missedReOrdersNum();
    repeatOrdersTodayNum();
    ordersNextTwoWeeksNum();
    setInterval(() => {
      console.log('This is my interval');
      notBookedNum();
      runningLateNumber();
      inProgressNum();
      validateOrdersNum();
      missedOrdersNum();
      todaysOrdersNum();
      reNotBookedNum();
      lateRepeatCleanersNum();
      activeRepeatOrdersNum();
      validateReOrdersNum();
      missedReOrdersNum();
      repeatOrdersTodayNum();
      ordersNextTwoWeeksNum();
    }, 1800000);
  }, [
    notBookedNum,
    runningLateNumber,
    inProgressNum,
    validateOrdersNum,
    missedOrdersNum,
    todaysOrdersNum,
    reNotBookedNum,
    lateRepeatCleanersNum,
    activeRepeatOrdersNum,
    validateReOrdersNum,
    missedReOrdersNum,
    repeatOrdersTodayNum,
    ordersNextTwoWeeksNum,
  ]);
  return (
    <div>
      <Container>
        <Alert variant="info text-center">Once Off Orders</Alert>
        <Row>
          <Col>
            <Card border="secondary" align="center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-times-circle text-warning"></i>
                  </span>
                  Orders Not Allocated
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{not_booked_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/once-off/not-allocated"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card border="secondary" className="mt-2 text-center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-clipboard-check text-warning"></i>
                  </span>
                  Orders that require validation
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{require_validation_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/once-off/require-validation"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-running text-danger"></i>
                  </span>
                  Cleaners Running Late
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{running_late_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/once-off/running-late"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card border="secondary" className="text-center mt-2">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-exclamation text-danger ml-2"></i>
                  </span>
                  Orders that have been missed
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{missed_orders_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/once-off/missed-orders"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card align="center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-hand-sparkles text-info"></i>
                  </span>
                  Currently Active Orders
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{in_progress_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/once-off/active-orders"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card className="text-center mt-2">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="far fa-calendar-alt text-primary"></i>
                  </span>
                  Orders booked for today
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{todays_orders_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/once-off/todays-orders"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-hands-helping text-info"></i>
                  </span>
                  Active Customer Queries
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>###</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/once-off/allocated"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card className="text-center mt-2">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="far fa-check-square text-success"></i>
                  </span>
                  Order for the next 2 weeks
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{orders_next_two_num}</b>
                </Card.Subtitle>

                <Button
                  as={Link}
                  to="/admin/order-management/once-off/next-two-weeks"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* /////////////////
        //Repeat Orders//
        ///////////////// */}

        <Alert variant="primary text-center mt-3">Repeat Orders</Alert>
        <Row>
          <Col>
            <Card border="secondary" align="center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-times-circle text-warning"></i>
                  </span>
                  Orders Not Allocated
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{re_orders_notbooked_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/not-allocated"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card border="secondary" className="mt-2 text-center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-clipboard-check text-warning"></i>
                  </span>
                  Orders that require validation
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{repeat_validation_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/require-validation"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="secondary" align="center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-running text-danger"></i>
                  </span>
                  Cleaners Running Late
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{re_orders_late_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/running-late"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card border="secondary" className="text-center mt-2">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-exclamation text-danger mr-2"></i>
                  </span>
                  Orders that have been missed
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{re_orders_missed_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/missed-reorders"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card align="center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-hand-sparkles text-info"></i>
                  </span>
                  Currently Active Orders
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{re_in_progress_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/in-progess"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card className="text-center mt-2">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="far fa-calendar-alt text-primary"></i>
                  </span>
                  Orders booked for today
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>{re_today_num}</b>
                </Card.Subtitle>
                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/today"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="fas fa-hands-helping text-info"></i>
                  </span>
                  Orders Assigned Dusters
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>###</b>
                </Card.Subtitle>

                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/allocated-re-orders"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
            <Card className="text-center mt-2">
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '2em' }} className="float-left">
                    <i className="far fa-check-square text-success"></i>
                  </span>
                  Completed Repeat Orders
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <b>###</b>
                </Card.Subtitle>

                <Button
                  as={Link}
                  to="/admin/order-management/repeat-orders/complete-orders"
                  variant="outline-secondary"
                >
                  Closer Look...
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin_orders: state.admin_orders,
});

export default connect(mapStateToProps, {
  notBookedNum,
  runningLateNumber,
  inProgressNum,
  validateOrdersNum,
  missedOrdersNum,
  todaysOrdersNum,
  reNotBookedNum,
  lateRepeatCleanersNum,
  activeRepeatOrdersNum,
  validateReOrdersNum,
  missedReOrdersNum,
  repeatOrdersTodayNum,
  ordersNextTwoWeeksNum,
})(AdminDashboard);
