import axios from 'axios';
import {
  ADMIN_GET_CARPERT_CLEANS,
  ADMIN_GET_CARPERT_CLEAN,
  ADMIN_NULL_CARPERT_CLEANS,
} from '../types';

//Get carpet cleans//
export const getTheCarpertCleans = () => async (dispatch) => {
  dispatch({ type: ADMIN_NULL_CARPERT_CLEANS });
  try {
    const res = await axios.get(
      '/api/admin/carpert-cleaning/all-carpert-cleans'
    );
    dispatch({ type: ADMIN_GET_CARPERT_CLEANS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get a carpert clean//
export const getACarpertClean = (carpertId) => async (dispatch) => {
  dispatch({ type: ADMIN_NULL_CARPERT_CLEANS });
  try {
    const res = await axios.get(
      `/api/admin/carpert-cleaning/a-carpert-clean/${carpertId}`
    );
    dispatch({ type: ADMIN_GET_CARPERT_CLEAN, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
