import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';

const ReCalendar = ({
  tomorrow,
  setPrettyDay,
  setSelectedDay,
  selectedDay,
  prettyDay,
  changing,
  toChange,
  reScheduleRepeat,
  orderId,
  invoiceHours,
  note,
  duster,
  setNote,
}) => {
  const [show, setShow] = useState(false);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [dusterId, setDusterId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();

  useEffect(() => {
    if (duster !== undefined) {
      setDusterId(duster._id);
    }
  }, [duster]);

  useEffect(() => {
    if (note.length > 0) {
      handleClose();
    }
  }, [note]);

  const selectDay = (e) => {
    let day = e.dateStr;
    let p = e.date.toString().split(' ');
    let pretty = p[0] + ' ' + p[1] + ' ' + p[2];
    setPrettyDay(pretty);
    setSelectedDay(day);
    handleShow();
  };

  const confirmTime = () => {
    let x = new Date(selectedDay).setHours(arrivalTime);
    let newDate = moment(x).format();
    let oldDate = toChange;
    let reOrderId = orderId;
    if (newDate.split('T').shift() === oldDate.split('T').shift()) {
      console.log('Old and New the same');
      return setNote('You chose the same day!!', 'danger');
    }

    reScheduleRepeat({ newDate, oldDate, reOrderId, invoiceHours, dusterId });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <div className="mt-3">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
        initialView="dayGridMonth"
        displayEventEnd="true"
        validRange={{ start: tomorrow }}
        selectable={true}
        dateClick={selectDay}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Changing {changing} to {prettyDay}?
            <hr />
            What time would you like the cleaner to arrive?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 8 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(8)}
                block
              >
                8:00am
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 10 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(10)}
                block
              >
                10:00am
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 12 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(12)}
                block
              >
                12:00pm
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 14 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(14)}
                block
              >
                14:00pm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          {arrivalTime ? (
            <Button variant="outline-success" onClick={confirmTime} ref={btn}>
              Confirm Arrival Time
            </Button>
          ) : (
            <Button variant="secondary" disabled>
              Select Arrival Time
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReCalendar;
