import React from 'react';
import { Button, Form, Table } from 'react-bootstrap';

const OfficeEquipment = ({ setStage }) => {
  return (
    <div>
      <h4 className="text-center">Special Equipement you may require</h4>
      <Table bordered>
        <thead>
          <th>Description</th>
          <th>Number of machines</th>
        </thead>
        <tbody>
          <tr>
            <td>Rotary Buffer - Standard Speed</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Rotary Buffer - High Speed</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Henry Vacuum Cleaner</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Upright Vac</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Wet & Dry Vac</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Scrubber Dryer 35cm Disc</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Pressure Washer - Cold Compact</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Steam Machine + Vac 4.5</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Compact Carpet Cleaner</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Lithium Battery Scrubber Dryer 11"</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
          <tr>
            <td>Machine admin incl PATS tests per item</td>
            <td>
              <Form.Control type="number" />
            </td>
          </tr>
        </tbody>
      </Table>
      <h4 className="text-center">Standard site equipment included</h4>
      <Table bordered>
        <thead>
          <th>Equipemt</th>
          <th>inc</th>
        </thead>
        <tbody>
          <tr>
            <td>Health and safety manual</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Health warning signage</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Mop buckets etc</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Janitor trolley</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Flat mop system</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Site PPE</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Extension cable</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Litter picking equipment</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
          <tr>
            <td>Internal window cleaning kit</td>
            <td>
              <i className="fa-solid fa-check text-success"></i>
            </td>
          </tr>
        </tbody>
      </Table>
      <Button variant="outline-info mt-3" block>
        Next <i className="fa-solid fa-angle-right ml-5"></i>
      </Button>
    </div>
  );
};

export default OfficeEquipment;
