import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  readyToWork,
  nullDuster,
} from '../../../../actions/admin/team_members';
import { Spinner, Row, Container, Table, Col } from 'react-bootstrap';
import TeamItem from './TeamItem';
import './AdminDusters.css';

const AllReady = ({ readyToWork, teams, nullDuster }) => {
  const [searchDusters, setSearchDusters] = useState('');
  useEffect(() => {
    readyToWork();
    nullDuster(null);
  }, [readyToWork, nullDuster]);
  const navigate = useNavigate();

  if (teams === null) {
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Row>
        <Col>
          {' '}
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>{' '}
          <h4>Ready for work</h4>
        </Col>
        <Col>
          {' '}
          <span className="float-right mr-5">
            <input
              className="text-center"
              placeholder="search name/email/cell"
              style={{ border: 'none' }}
              onChange={(e) => setSearchDusters(e.target.value)}
            />
          </span>
        </Col>
      </Row>

      <hr />

      <Row id="dust-buster">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Cellphone</th>
              <th>Email</th>
              <th>Star Rating</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {teams.length === 0 ? (
              <tr>
                <td>No ready dusters</td>
              </tr>
            ) : (
              teams
                .filter((val) => {
                  if (searchDusters === '') {
                    return val;
                  } else if (
                    val.email
                      .toLowerCase()
                      .includes(searchDusters.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(searchDusters.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.cellphone
                      .toLowerCase()
                      .includes(searchDusters.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((team) => <TeamItem team={team} key={team._id} />)
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  teams: state.team_members.ready,
});
export default connect(mapStateToProps, { readyToWork, nullDuster })(AllReady);
