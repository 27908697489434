import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Schedule from './Schedule';

const ReReSchedule = ({
  schedule,
  dayToChange,
  toChange,
  setChangeDay,
  setToChange,
}) => {
  const [reSchedule, setReSchedule] = useState(false);
  useEffect(() => {
    if (reSchedule === false) {
      setChangeDay(false);
      setToChange(null);
    }
  }, [reSchedule, setChangeDay, setToChange]);
  return (
    <>
      {reSchedule === true ? (
        <>
          <Button
            variant="outline-warning mt-2"
            onClick={() => setReSchedule(!reSchedule)}
            block
          >
            Cancel
          </Button>
          <h5 className="text-center mt-3">
            Which day would you like to reschedule
          </h5>
          {schedule.map((sched) => (
            <Schedule
              sched={sched}
              key={sched.start}
              dayToChange={dayToChange}
              toChange={toChange}
            />
          ))}
        </>
      ) : (
        <Button
          variant="outline-info mt-3"
          onClick={() => setReSchedule(!reSchedule)}
          block
        >
          Reschedule
        </Button>
      )}
    </>
  );
};

export default ReReSchedule;
