import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const TeamRoute = ({ component: Component, team: { isTeam, loading } }) => {
  if (loading) return <Spinner animation="border" variant="primary" />;
  if (isTeam) return <Component />;
  return <Navigate to="/" />;
};

TeamRoute.propTypes = {
  team: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  team: state.team,
});

export default connect(mapStateToProps)(TeamRoute);
