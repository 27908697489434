import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap';
import FooterLogo from '../../images/FooterLogo.png';

const Bottom = () => {
  return (
    <div className="mt-3">
      <footer className="text-center text-lg-start bg-light text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="mr-5 ml-5">
            <span>Get connected with us on social networks;</span>
          </div>

          <div>
            <a
              href="https://www.facebook.com/dustingdown"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'black' }}
            >
              <i className="mr-3 fab fa-facebook-f"></i>
            </a>
            <a
              href="https://twitter.com/dustingdown1"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'black' }}
            >
              <i className="mr-3 fab fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/dustingdown/"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'black' }}
            >
              <i className="mr-5 fab fa-instagram"></i>
            </a>
          </div>
        </section>

        <section className="container">
          <div className="text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <img src={FooterLogo} height="200" alt="Dusting Down" />
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h3>Download the app</h3>
                <Button variant="outline-secondary" block>
                  <a
                    href="https://apps.apple.com/za/app/dusting-down/id1625555154"
                    style={{ color: 'grey' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={{ fontSize: '1.5em' }}>
                      <i className="fa-brands fa-apple mr-3"></i>
                    </span>
                    App store
                  </a>
                </Button>
                <Button variant="outline-secondary mt-4" block>
                  <a
                    href="https://play.google.com/store/apps/details?id=co.za.callitservices.dustingdown"
                    style={{ color: 'grey' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={{ fontSize: '1.5em' }}>
                      <i className="fa-brands fa-google-play mr-3"></i>
                    </span>
                    Play store
                  </a>
                </Button>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <u>
                  <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                </u>
                <Dropdown>
                  <Dropdown.Toggle id="cleaner">Working for us</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/team/signin">
                      <i className="mr-2 fas fa-signature"></i> Sign In
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/team/signup">
                      <i className="mr-2 fas fa-user-plus"></i> Sign Up
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <p>
                  <Link to="/user/booking" className="text-reset">
                    Account Queries
                  </Link>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Our management team
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Online help
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <u>
                  <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                </u>
                <p>
                  <i className="fas fa-home me-3"></i>Unit 7, 355 Oak Avenue,
                  Ferndale, Randburg
                </p>
                <p>
                  <i className="fas fa-envelope me-3"></i>
                  info@dustingdown.co.za
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> + 27 87 550 1147
                </p>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Bottom;
