import React from 'react';
import { ListGroup } from 'react-bootstrap';

const ExtraItem = ({ x }) => {
  return (
    <div>
      <ListGroup.Item>{x.name}</ListGroup.Item>
    </div>
  );
};

export default ExtraItem;
