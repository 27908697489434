import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  getGlobalDusterRating,
  createGlobalDusterRate,
  editGlobalDusterRating,
} from '../../../../../actions/admin/main';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

const DusterRate = ({
  global_duster_rate,
  note,
  getGlobalDusterRating,
  createGlobalDusterRate,
  editGlobalDusterRating,
}) => {
  const [rateId, setRateId] = useState(null);
  const [oneStar, setOneStar] = useState(0);
  const [twoStar, setTwoStar] = useState(0);
  const [threeStar, setThreeStar] = useState(0);
  const [fourStar, setFourStar] = useState(0);
  const [fiveStar, setFiveStar] = useState(0);
  const [nte, setMsg] = useState(note);

  let btn = useRef();
  let editBtn = useRef();
  useEffect(() => {
    if (note && editBtn.current) {
      setMsg(note);
      editBtn.current.removeAttribute('disabled');
      window.scrollTo(0, 0);
      handleClose();
    } else if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
      window.scrollTo(0, 0);
      handleClose();
    }
  }, [setMsg, note, nte]);

  useEffect(() => {
    getGlobalDusterRating();
  }, [getGlobalDusterRating]);
  useEffect(() => {
    if (global_duster_rate) {
      setRateId(global_duster_rate._id);
      setOneStar(global_duster_rate.oneStar);
      setTwoStar(global_duster_rate.twoStar);
      setThreeStar(global_duster_rate.threeStar);
      setFourStar(global_duster_rate.fourStar);
      setFiveStar(global_duster_rate.fiveStar);
    }
  }, [global_duster_rate]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createGlobal = () => {
    createGlobalDusterRate({
      oneStar,
      twoStar,
      threeStar,
      fourStar,
      fiveStar,
    });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  const editGlobal = () => {
    editGlobalDusterRating({
      rateId,
      oneStar,
      twoStar,
      threeStar,
      fourStar,
      fiveStar,
    });

    if (editBtn.current) {
      editBtn.current.setAttribute('disabled', 'disabled');
    }
  };
  console.log(global_duster_rate);
  return (
    <>
      <Button
        variant="outline-secondary float-right"
        size="sm"
        onClick={handleShow}
      >
        Duster Rates
      </Button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>One Star</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={oneStar}
                  name="oneStar"
                  value={oneStar}
                  onChange={(e) => setOneStar(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Two Star</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={twoStar}
                  name="twoStar"
                  value={twoStar}
                  onChange={(e) => setTwoStar(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              {' '}
              <Form.Group>
                <Form.Label>Three Star</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={threeStar}
                  name="threeStar"
                  value={threeStar}
                  onChange={(e) => setThreeStar(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              {' '}
              <Form.Group>
                <Form.Label>Four Star</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={fourStar}
                  name="fourStar"
                  value={fourStar}
                  onChange={(e) => setFourStar(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Five Star</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={fiveStar}
                  name="fiveStar"
                  value={fiveStar}
                  onChange={(e) => setFiveStar(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {rateId ? (
            <Button onClick={() => editGlobal()} ref={editBtn}>
              Edit Rating
            </Button>
          ) : (
            <Button onClick={() => createGlobal()} ref={btn}>
              Create Rating
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  note: state.note,
  global_duster_rate: state.main.global_duster_rate,
});
export default connect(mapStateToProps, {
  getGlobalDusterRating,
  createGlobalDusterRate,
  editGlobalDusterRating,
})(DusterRate);
