import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { loginMember } from '../../../actions/team/';
import Bottom from '../../Layout/Bottom';

const SignIn = ({ team: { isTeam, msgs }, loginMember, note }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [nte, setMsg] = useState(note);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  let btn = useRef();

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    loginMember({ email, password });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (isTeam) {
    return <Navigate to="/team/dust-buster-profile" />;
  }
  return (
    <div>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Card className="card bg-primary text-white mb-2" align="center">
              <Card.Body>
                <Card.Title>Welcome To Dusting Down</Card.Title>
                <Card.Subtitle>
                  Sign in, book and complete orders to get paid.
                </Card.Subtitle>
                <span style={{ fontSize: '7em' }}>
                  <i className="fas fa-hand-sparkles"></i>
                </span>
                <p className="text-white">Sign in or create an account</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card.Header className="text-white bg-primary">
              <Row>
                <Col>
                  <h5 align="center">Team Sign In:</h5>
                </Col>
              </Row>
            </Card.Header>
            <Form className="mt-2" onSubmit={onSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Button variant="primary btn-block" type="submit" ref={btn}>
                Submit <i className="fas fa-comment ml-3"></i>
              </Button>
            </Form>
            <hr />
            <Row>
              <Col>
                <Link to="/team/signup">Join the team</Link>
              </Col>
              <Col>
                <Link to="/team/forgot-password">Reset Password</Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.team,
  note: state.note,
});
export default connect(mapStateToProps, { loginMember })(SignIn);
