import axios from 'axios';
import {
  AVAILABLE_WORK,
  MY_SCHEDULE,
  CLEAR_WORK,
  MY_BOOKING,
  MY_RE_BOOKING,
  CLEAR_BOOKING,
  SET_DISTANCE,
  MY_WORK,
  MY_RE_WORK,
  CLEAR_REBOOKING,
} from '../types';
import { setNote } from '../note';

//Find Dustbuster orders//
export const myWork = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/team_work');
    dispatch({ type: MY_WORK, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//On my way//
export const onMyWay =
  ({ currentOrder, eta }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ eta });

    try {
      const res = await axios.put(
        `/api/team_work/on-my-way/${currentOrder}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getBooking(currentOrder));
    } catch (error) {
      console.error(error.messgae);
    }
  };

//On My Way Repeat//
export const repeatOMW =
  ({ currentReOrder, eta }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ eta });
    try {
      const res = await axios.put(
        `/api/team_work/on-my-way-repeat/${currentReOrder}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getReBooking(currentReOrder));
    } catch (error) {
      console.error(error.message);
    }
  };

//Request validation//
export const requestOnsite =
  ({ officeRequest, currentOrder }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ officeRequest });
    try {
      const res = await axios.put(
        `/api/team_work/my-booking/validation/${currentOrder}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getBooking(currentOrder));
    } catch (error) {
      console.error(error.message);
    }
  };

//Request Repeat Order Onsite Validation//
export const requestReOnsite =
  ({ officeRequest, currentReOrder }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ officeRequest });
    try {
      const res = await axios.put(
        `/api/team_work/my-booking/repeat-validation/${currentReOrder}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getReBooking(currentReOrder));
    } catch (error) {
      console.error(error.message);
    }
  };

//Find Repeat Work//
export const myReWork = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/team_work/repeat-work');
    dispatch({ type: MY_RE_WORK, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Find available work
export const Work = () => async (dispatch) => {
  dispatch({ type: CLEAR_WORK });
  try {
    const res = await axios.get('/api/team_work/available-work');
    dispatch({ type: AVAILABLE_WORK, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Book a Job//
export const bookJob =
  ({ start, end, orderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ start, end, orderId });
    try {
      const res = await axios.post('/api/team_work/book_job', body, config);
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(Work());
      dispatch(myWork());
      dispatch(getSchedule());
    } catch (error) {
      console.error(error.message);
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Get My Bookings//
export const getSchedule = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/team_work/my_bookings');

    dispatch({ type: MY_SCHEDULE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get a booking//
export const getBooking = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_BOOKING });
  try {
    const res = await axios.get(
      `/api/team_work/my_booking/specific/${orderId}`
    );
    dispatch({ type: MY_BOOKING, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Repeat Booking//
export const getReBooking = (reOrderId) => async (dispatch) => {
  dispatch({ type: CLEAR_REBOOKING });
  try {
    const res = await axios.get(
      `/api/team_work/my_re_booking/specific/${reOrderId}`
    );
    dispatch({ type: MY_RE_BOOKING, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Access to checkin&out for dust buster//
export const locationCheck =
  ({ currentLat, currentLon, orderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ currentLat, currentLon, orderId });
    try {
      const res = await axios.post(
        '/api/team_work/my_booking/access-to-checkin',
        body,
        config
      );
      dispatch({ type: SET_DISTANCE, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Access to checkin&out for dust buster//
export const locationReCheck =
  ({ currentLat, currentLon, orderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ currentLat, currentLon, orderId });
    try {
      const res = await axios.post(
        '/api/team_work/my_booking/access-to-re-checkin',
        body,
        config
      );
      dispatch({ type: SET_DISTANCE, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Dustbuster checking in//
export const checkIn =
  ({ orderId, qrOrderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ qrOrderId, orderId });
    try {
      const res = await axios.put(
        '/api/team_work/my_booking/checking-in',
        body,
        config
      );
      dispatch({ type: MY_BOOKING, payload: res.data });
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Const complete order//
export const completeBooking =
  ({ checkOut, note, dusterId, currentOrder }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_BOOKING });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ checkOut, note, dusterId, currentOrder });
    try {
      const res = await axios.put(
        '/api/team_work/my_booking/complete-booking',
        body,
        config
      );

      dispatch({ type: MY_BOOKING, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Dustbuster check out//
export const checkOut =
  ({ orderId, qrOrderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ qrOrderId, orderId });
    try {
      const res = await axios.put(
        '/api/team_work/my_booking/checking-out',
        body,
        config
      );
      dispatch({ type: MY_BOOKING, payload: res.data });
      dispatch(getSchedule());
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Duster Checkin into regular order//
export const checkInRep =
  ({ qrOrderId, reOrderId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ qrOrderId, reOrderId });

    try {
      await axios.put('/api/team_work/my_re_booking/checking-in', body, config);
      dispatch(getReBooking(reOrderId));
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

//Checking Out of regular order//
export const checkOutRep =
  ({ note, dusterId, currentReOrder }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ note, dusterId, currentReOrder });
    try {
      await axios.put(
        '/api/team_work/my_re_booking/checking-out',
        body,
        config
      );
      dispatch(getReBooking(currentReOrder));
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };
