import React from 'react';
import { Button } from 'react-bootstrap';

const AffilaiteItem = ({ affiliate }) => {
  return (
    <tr>
      <td>{affiliate.name}</td>
      <td>{affiliate.email}</td>
      <td>{affiliate.coupon}</td>
      <td>{affiliate.address}</td>
      <td>
        <Button size="sm">view</Button>
      </td>
    </tr>
  );
};

export default AffilaiteItem;
