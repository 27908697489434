import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, InputGroup, Container } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { duster51signUp } from '../../../actions/user';
import { setNote } from '../../../actions/note';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';
import Bottom from '../../Layout/Bottom';

const SignUp = ({ note, duster51signUp, setNote, isUser }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [show, setShow] = useState(false);
  const [complex, setComplex] = useState(null);
  const [msg, setMsg] = useState(note);
  const [agree, setAgree] = useState(false);
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    cellphone: '',
    email: '',
    password: '',
    password2: '',
  });

  const handleClose = () => {
    setComplex(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  let btn = useRef();
  const { name, surname, cellphone, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const saveComplex = () => setShow(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setNote('Passwords do not match', 'danger');
    } else if (agree === false) {
      setNote('Please agree to our terms of services', 'warning');
    } else {
      duster51signUp({
        name,
        surname,
        address,
        cellphone,
        complex,
        coordinates,
        email,
        password,
      });

      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };

  if (isUser) {
    return <Navigate to="/user/booking" />;
  }
  return (
    <div>
      <Container style={{ width: '40rem' }}>
        <h3 className="text-center">
          Welcome to Dusting Down, Create an account to book a service!
        </h3>

        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Name"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter your name as it appears on your identification
              documents
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Surname:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Surname"
              name="surname"
              value={surname}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter your surname as it appears on your identification
              documents
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Cellphone:</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Your Cellphone Number"
              name="cellphone"
              value={cellphone}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter your cellphone number
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address:</Form.Label>
            <AddressAutocomplete
              value={address}
              setAddress={setAddress}
              setCoordinates={setCoordinates}
            />
          </Form.Group>
          <Form.Group>
            <InputGroup>
              <Button variant="dark" disabled>
                Do you live in complex?
              </Button>
              <InputGroup.Append>
                <Button
                  variant={complex ? 'info' : 'outline-info'}
                  onClick={handleShow}
                >
                  Yes
                </Button>
                <Button
                  variant={complex ? 'outline-primary' : 'primary'}
                  onClick={() => setComplex(null)}
                >
                  No
                </Button>
                {complex ? (
                  <Button variant="info" className="ml-3">
                    Your Complex details: {complex}
                  </Button>
                ) : (
                  ''
                )}
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Please enter you complex details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                placeholder="Please enter complex details"
                name="complex"
                value={complex}
                onChange={(e) => setComplex(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={handleClose}>
                Dont save
              </Button>
              <Button variant="primary" onClick={saveComplex}>
                Save Details
              </Button>
            </Modal.Footer>
          </Modal>
          <Form.Group>
            <Form.Label>Email Address:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Please enter your email address"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              We will need to confirm you own this address, please enter the
              correct email.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Please don't make this password easy to guess"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please enter a password that is longer than 7 characters
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Please confirm your password"
              name="password2"
              value={password2}
              onChange={(e) => onChange(e)}
            />
            <Form.Text className="text-muted">
              Please confirm your password
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Agree to our terms & Privacy Policy"
              checked={agree}
              value={agree}
              onChange={(e) => setAgree(!agree)}
            />
            <Form.Text className="text-muted">
              By ticking this box you agree to our{' '}
              <Link to="/user/agreement">terms of service</Link>
            </Form.Text>
          </Form.Group>
          <Button variant="success btn-block" type="submit" ref={btn}>
            Join the Family! <i className="fas fa-users ml-3"></i>
          </Button>
        </Form>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  isUser: state.team.isUser,
});

export default connect(mapStateToProps, { duster51signUp, setNote })(SignUp);
