import {
  FETCH_AFFILIATE,
  FETCH_AFFILIATES,
  SET_AFFILIATE_LOADING,
} from '../../actions/types';

const initState = {
  loading: true,
  affiliate: null,
  affiliates: null,
};

function affiliateReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_AFFILIATE:
      return {
        ...state,
        affiliate: payload,
        loading: false,
      };
    case FETCH_AFFILIATES:
      return {
        ...state,
        affiliates: payload,
        loading: false,
      };
    case SET_AFFILIATE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}

export default affiliateReducer;
