import { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';

const MarketingCoupon = ({
  coupon,
  fourPer,
  fivePer,
  sixPer,
  sevenPer,
  eightPer,
  expirydate,
  createMarketingCoupon,
  coupontype,
  note,
}) => {
  const [nte, setMsg] = useState(note);
  const [typeOfClean, setTypeOfClean] = useState(null);
  const [promoType, setPromoType] = useState();
  const [error, setError] = useState(null);
  const [whichClean, setWhichClean] = useState(null);
  let btn = useRef();
  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const jamCoupon = () => {
    if (coupon.length < 5) {
      setError('Enter a valid coupon');
      return setTimeout(() => setError(null), 2000);
    } else if (fourPer === 0) {
      setError('Enter 4 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (fivePer === 0) {
      setError('Enter 5 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sixPer === 0) {
      setError('Enter 6 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (sevenPer === 0) {
      setError('Enter 7 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (eightPer === 0) {
      setError('Enter 8 hour percentage');
      return setTimeout(() => setError(null), 2000);
    } else if (typeOfClean === null) {
      setError('Please select type of clean');
      return setTimeout(() => setError(null), 2000);
    } else if (promoType === 'Sign Up' && whichClean === null) {
      setError('First or Second Clean?');
      return setTimeout(() => setError(null), 2000);
    } else {
      const percArray = {
        fourPer: fourPer,
        fivePer: fivePer,
        sixPer: sixPer,
        sevenPer: sevenPer,
        eightPer: eightPer,
      };
      createMarketingCoupon({
        expirydate,
        coupon,
        percArray,
        coupontype,
        typeOfClean,
        promoType,
        whichClean,
      });

      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            variant={promoType === 'Sign Up' ? 'info' : 'outline-secondary'}
            onClick={() => setPromoType('Sign Up')}
            block
          >
            Sign up Promo
          </Button>
          {promoType === 'Sign Up' && (
            <>
              <hr />
              <Row>
                <Col>
                  <Button
                    variant={
                      whichClean === 'Second Clean'
                        ? 'info'
                        : 'outline-secondary'
                    }
                    onClick={() => setWhichClean('Second Clean')}
                  >
                    Second Clean
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={
                      whichClean === 'First Clean'
                        ? 'info'
                        : 'outline-secondary'
                    }
                    onClick={() => setWhichClean('First Clean')}
                  >
                    First clean
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col>
          <Button
            variant={promoType === 'Next Clean' ? 'info' : 'outline-secondary'}
            onClick={() => setPromoType('Next Clean')}
            block
          >
            Next Clean
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>Type of clean: </Col>
        <Col>
          <Button
            variant={typeOfClean === 'Once Off' ? 'info' : 'outline-secondary'}
            onClick={() => setTypeOfClean('Once Off')}
          >
            Once Off
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              typeOfClean === 'Reapeat Clean' ? 'info' : 'outline-secondary'
            }
            onClick={() => setTypeOfClean('Reapeat Clean')}
          >
            Repeat Clean
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              typeOfClean === 'Carpert Clean' ? 'info' : 'outline-secondary'
            }
            onClick={() => setTypeOfClean('Carpert Clean')}
          >
            Carpert Clean
          </Button>
        </Col>
      </Row>
      <hr />
      {error && <Alert variant="warning">{error}</Alert>}
      <Button
        variant="outline-success"
        onClick={() => jamCoupon()}
        ref={btn}
        block
      >
        Create Coupon
      </Button>
    </>
  );
};

export default MarketingCoupon;
