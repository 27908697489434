import React, { useState, useEffect } from 'react';
import { ListGroup, Button, Modal } from 'react-bootstrap';
import moment from 'moment';

const SwapDuster = ({
  swap,
  swapDay,
  orderId,
  swapForADay,
  note,
  findTheBooking,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0) {
      let start = swapDay;
      findTheBooking({ orderId, start });
      setShow(false);
    }
  }, [note, findTheBooking, orderId, swapDay]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const swapMe = () => {
    const dusterId = swap._id;
    swapForADay({ orderId, swapDay, dusterId });
  };

  return (
    <>
      <ListGroup>
        <ListGroup.Item>
          <b>Dust Buster:</b> {swap.name} {swap.surname}
          <Button variant="warning float-right" size="sm" onClick={handleShow}>
            Select Duster
          </Button>
        </ListGroup.Item>
      </ListGroup>

      <Modal show={show} onHide={handleClose} className="text-center">
        <Modal.Header closeButton>
          <Modal.Title>
            Assign {swap.name} to the {moment(swapDay).format('ddd MMM D, h a')}{' '}
            date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to assign {swap.name} to this date
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            No
          </Button>
          <Button variant="success" onClick={swapMe}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SwapDuster;
