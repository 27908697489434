import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert, ListGroup, Button, Spinner } from 'react-bootstrap';
import Sticky from 'react-sticky-el';
import Bottom from '../../Layout/Bottom';
import BedAndBath from './BedAndBath';
import DateAndTime from './DateAndTime';
import Time from './Time';
import WhereYou from './WhereYou';
import NityGrity from './NityGrity';
import {
  rateCard,
  regularRate,
  customRate,
  theNityGrity,
  changeRate,
  whyChangeHours,
  chooseDuster,
  removeDuster,
  basketToggle,
  toggleAddressDialog,
} from '../../../actions/user/bookings';
import {
  toggleCarpetCleaning,
  jamCarpertGetIn,
  jamCarpertCleanLocation,
} from '../../../actions/user/carpert_cleaning';
import {
  getAvailableDusters,
  whoCleanedLast,
  getDusterRating,
} from '../../../actions/user/order';
import { clearPay } from '../../../actions/user/payment';
import {
  fetchMyRate,
  getMother,
  getAddress,
  signupUser,
  updateMyAddress,
} from '../../../actions/user';
import moment from 'moment';
import IconExtras from './IconExtras';
import CarpertCleaning from './CarpertCleaning';

const Booking = ({
  rateCard,
  carpert_cleaning: {
    jamming,
    carpert_invoice,
    minimum_call_out,
    carpert_nity,
  },
  bookings: {
    orderTime,
    frequency,
    bedroom,
    address,
    coordinates,
    complex,
    bathroom,
    bedbathTotal,
    bedbathHours,
    extraTotal,
    extraHours,
    laundreyBasket,
    showAddressDialog,
    invoice,
    hours,
    regInvoice,
    regular,
    schedule,
    availableCleaners,
    nityGrity,
    iconExtras,
    dusterId,
    chosenDusterSkills,
    addressType,
    fromOffice,
  },
  user: { isUser, user, myRateCard, mother, myAddress, errors },
  clearPay,
  regularRate,
  customRate,
  fetchMyRate,
  getMother,
  getAddress,
  theNityGrity,
  changeRate,
  whyChangeHours,
  orders: { order, reOrder, dusterOptions, lastDuster, dusterRating },
  getAvailableDusters,
  whoCleanedLast,
  getDusterRating,
  chooseDuster,
  removeDuster,
  basketToggle,
  signupUser,
  toggleAddressDialog,
  updateMyAddress,
  note,
  toggleCarpetCleaning,
  jamCarpertGetIn,
  jamCarpertCleanLocation,
}) => {
  //Included Variables//
  const [inchours, SetIncHours] = useState(0);
  const [stage, setStage] = useState(1);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [prettyDay, setPrettyDay] = useState(null);
  const [page, setPage] = useState(null);
  const [change, setChange] = useState(null);
  const [time, setTime] = useState(null);
  const [whyLess, setWhyLess] = useState('');
  const [newUserCreated, setNewUserCreated] = useState(false);

  useEffect(() => {
    if (isUser) {
      setNewUserCreated(false);
    }
  }, [isUser]);

  useEffect(() => {
    if (order || reOrder) {
      clearPay();
    }
  }, [order, reOrder, clearPay]);

  useEffect(() => {
    if (whyLess.length > 0) {
      whyChangeHours(whyLess);
    }
  }, [whyLess, whyChangeHours]);

  useEffect(() => {
    getMother();
    getAddress();
  }, [getMother, getAddress]);

  useEffect(() => {
    clearPay();
  }, [clearPay]);

  useEffect(() => {
    if (user) {
      if (user.customRate === true) {
        let userId = user._id;
        fetchMyRate(userId);
      }
    }
  }, [user, fetchMyRate]);
  //Clear Changed time when recaulculating//
  useEffect(() => {
    if (bedroom || bathroom || extraTotal) {
      setChange(null);
    }
  }, [bedroom, bathroom, extraTotal]);

  //Calculating the Regular Clean//
  useEffect(() => {
    let juice = invoice;
    let a = schedule.length;
    let monthly = juice * a;
    let discount = monthly * 0.1;
    let amount = monthly - discount;

    if (myRateCard) {
      return regularRate(monthly.toFixed(2));
    }

    regularRate(amount.toFixed(2));
  }, [frequency, regularRate, invoice, schedule.length, myRateCard]);

  //Calculating The Once Off Clean//
  useEffect(() => {
    SetIncHours(parseFloat(4));
    let bbhours = parseFloat(bedbathHours);
    let exhours = parseFloat(extraHours);
    let tt = bbhours + exhours + inchours;
    let invoiceHours = parseFloat(tt);

    if (change) {
      let invoiceHours = change;
      return changeRate({ invoiceHours, mother });
    }

    if (myRateCard) {
      return customRate({ myRateCard, invoiceHours });
    }
    if (mother) {
      rateCard({ invoiceHours, mother });
    }
  }, [
    bedbathHours,
    extraHours,
    inchours,
    rateCard,
    frequency,
    myRateCard,
    customRate,
    mother,
    change,
    changeRate,
    hours,
  ]);

  const invoiceTime = moment(orderTime).format('ddd MMM D, h:mm a');

  if (mother === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const selectDusterForClean = ({ duster }) => {
    chooseDuster({ duster });
  };

  const removeDusterFromClean = () => {
    removeDuster();
  };

  const signUpNewUser = async ({
    name,
    surname,
    email,
    password,
    cellphone,
  }) => {
    const res = await signupUser({
      name,
      surname,
      cellphone,
      email,
      password,
      address,
      coordinates,
      complex,
      addressType,
    });

    if (res) {
      setNewUserCreated(true);
    }
  };

  const toggleAddressPopUp = (addressDialog) => {
    toggleAddressDialog(addressDialog);
  };

  const switchCleanType = (loot) => {
    toggleCarpetCleaning(loot);
  };

  return (
    <>
      <div className="container">
        <Row className="mb-5">
          <Col md={3} className="position-relative" id="invoice">
            {jamming ? (
              <Sticky
                stickyStyle={{
                  top: 58,
                  zIndex: 2,
                  backgroundColor: 'white',
                }}
              >
                <div>
                  <Alert variant="primary">
                    <i className="fas fa-shopping-cart"></i>
                    <p className="float-right">Carpet Clean</p>
                  </Alert>
                  <ListGroup variant="flush" id="order-details">
                    {orderTime && (
                      <ListGroup.Item variant="secondary">
                        <b>Order Date:</b> {invoiceTime}
                      </ListGroup.Item>
                    )}

                    <ListGroup.Item>
                      Call out fee: <p className="float-right">960.00</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      Service Fee <p className="float-right">R35.00</p>
                    </ListGroup.Item>
                  </ListGroup>
                  <hr />
                  <ListGroup.Item variant="warning">
                    Invoice Total;
                    <p className="float-right">
                      <b>
                        R
                        {carpert_invoice > minimum_call_out
                          ? `${carpert_invoice}`
                          : `${minimum_call_out}`}
                      </b>
                    </p>
                  </ListGroup.Item>
                </div>
              </Sticky>
            ) : (
              <Sticky
                stickyStyle={{
                  top: 58,
                  zIndex: 2,
                  backgroundColor: 'white',
                }}
              >
                <div>
                  <Alert variant="primary">
                    <i className="fas fa-shopping-cart"></i>
                    <p className="float-right">
                      {change ? `${change}` : `${hours}`} Hours
                    </p>
                  </Alert>
                  <ListGroup variant="flush" id="order-details">
                    {orderTime && (
                      <ListGroup.Item variant="secondary">
                        <b>Order Date:</b> {invoiceTime}
                      </ListGroup.Item>
                    )}

                    <ListGroup.Item>
                      {bedroom} Bed/s And {bathroom} Bath/s
                      <p className="float-right">R{bedbathTotal} </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Included Cost <p className="float-right">R212.00</p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Extras Cost <p className="float-right">R{extraTotal} </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Service Fee <p className="float-right">R35.00</p>
                    </ListGroup.Item>
                  </ListGroup>
                  <hr />
                  {regular ? (
                    <>
                      <ListGroup.Item variant="warning">
                        Invoice Total;
                        <p className="float-right">
                          <b>R{regInvoice}</b> this month
                        </p>
                      </ListGroup.Item>
                      <Alert variant="success">
                        10% discount applied on all regular cleans
                      </Alert>
                    </>
                  ) : (
                    <ListGroup.Item variant="warning">
                      Invoice Total;
                      <p className="float-right">
                        <b>R{invoice}</b>
                      </p>
                    </ListGroup.Item>
                  )}
                </div>
              </Sticky>
            )}
          </Col>

          <Col md={9} xs={12}>
            <Alert variant="info text-center">
              Step {stage} of booking a duster
            </Alert>
            <Row className="mb-3">
              <Col xs={12} md={3}>
                {stage === 1 ? (
                  <>
                    <Button variant="outline-info" block>
                      1. Cleaning Brief
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : (
                  <>
                    <Button variant="info" block onClick={() => setStage(1)}>
                      <i className="fas fa-check"></i> Cleaning Brief
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                )}
              </Col>
              <Col xs={12} md={3}>
                {stage === 2 ? (
                  <>
                    <Button
                      variant="outline-info"
                      onClick={() => setStage(2)}
                      block
                    >
                      2. Date And Time
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : stage > 2 ? (
                  <>
                    <Button variant="info" block onClick={() => setStage(2)}>
                      <i className="fas fa-check"></i> Date And Time
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : (
                  <>
                    <Button variant="outline-secondary" block>
                      2. Date And Time
                    </Button>
                  </>
                )}
              </Col>
              <Col xs={12} md={3}>
                {stage === 3 ? (
                  <>
                    <Button variant="outline-info" block>
                      3. Nitty Gritty
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : stage > 3 ? (
                  <Button variant="info" block>
                    <i className="fas fa-check"></i> Nitty Gritty
                  </Button>
                ) : (
                  <Button variant="outline-secondary" block>
                    3. Nitty Gritty
                  </Button>
                )}
              </Col>
              <Col xs={12} md={3}>
                <Button variant="outline-secondary" block>
                  4. Review & Pay
                </Button>
              </Col>
            </Row>
            {stage <= 1 && (
              <Row className="mb-3">
                <Col>
                  <Button
                    variant={jamming ? 'outline-secondary' : 'info'}
                    block
                    onClick={() => switchCleanType(false)}
                  >
                    Domestic Cleaning
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={jamming ? 'info' : 'outline-secondary'}
                    block
                    onClick={() => switchCleanType(true)}
                  >
                    Carpet Cleaning
                  </Button>
                </Col>
              </Row>
            )}
            <WhereYou
              jamming={jamming}
              jamCarpertCleanLocation={jamCarpertCleanLocation}
              user={user}
              myAddress={myAddress}
              availableCleaners={availableCleaners}
              address={address}
              coordinates={coordinates}
              complex={complex}
              addressType={addressType}
              fromOffice={fromOffice}
              showAddressDialog={showAddressDialog}
              toggleAddressPopUp={toggleAddressPopUp}
              updateMyAddress={updateMyAddress}
              note={note}
            />

            {stage === 1 ? (
              jamming ? (
                <CarpertCleaning setStage={setStage} />
              ) : (
                <>
                  <BedAndBath hours={hours} />

                  <hr />

                  <IconExtras
                    iconExtras={iconExtras}
                    hours={hours}
                    laundreyBasket={laundreyBasket}
                    basketToggle={basketToggle}
                  />

                  {/* <Extras /> */}
                  <hr />
                  <Time
                    hours={hours}
                    setChange={setChange}
                    change={change}
                    time={time}
                    setTime={setTime}
                    whyLess={whyLess}
                    setWhyLess={setWhyLess}
                  />
                  {hours > time ? (
                    whyLess.length > 0 ? (
                      <Button
                        variant="outline-info"
                        id="next"
                        onClick={() => {
                          if (!addressType) {
                            toggleAddressPopUp(true);
                          } else if (!complex) {
                            if (addressType === 'House') {
                              setStage(2);
                            } else {
                              toggleAddressPopUp(true);
                            }
                          } else {
                            setStage(2);
                          }
                        }}
                      >
                        Next Step <i className="fas fa-fast-forward ml-3"></i>
                      </Button>
                    ) : (
                      <Button variant="secondary" id="next">
                        Please tell us what to prioritise
                        <i className="fas fa-fast-forward ml-3"></i>
                      </Button>
                    )
                  ) : fromOffice <= 20 ? (
                    <Button
                      variant="outline-info"
                      id="next"
                      onClick={() => {
                        if (!addressType) {
                          toggleAddressPopUp(true);
                        } else if (!complex) {
                          if (addressType === 'House') {
                            setStage(2);
                          } else {
                            toggleAddressPopUp(true);
                          }
                        } else {
                          setStage(2);
                        }
                      }}
                    >
                      Next Step <i className="fas fa-fast-forward ml-3"></i>
                    </Button>
                  ) : (
                    <div className="text-center">
                      <Button
                        variant="warning"
                        onClick={() => toggleAddressPopUp(true)}
                      >
                        Unfortunatly we do not service your area
                      </Button>
                    </div>
                  )}
                </>
              )
            ) : stage === 2 ? (
              <>
                <DateAndTime
                  regular={regular}
                  setStage={setStage}
                  arrivalTime={arrivalTime}
                  setArrivalTime={setArrivalTime}
                  page={page}
                  setPage={setPage}
                  prettyDay={prettyDay}
                  setPrettyDay={setPrettyDay}
                  invoiceTime={invoiceTime}
                />
              </>
            ) : stage === 3 ? (
              <>
                <NityGrity
                  jamming={jamming}
                  jamCarpertGetIn={jamCarpertGetIn}
                  carpert_nity={carpert_nity}
                  isUser={isUser}
                  regular={regular}
                  theNityGrity={theNityGrity}
                  nityGrity={nityGrity}
                  getAvailableDusters={getAvailableDusters}
                  coordinates={coordinates}
                  orderTime={orderTime}
                  whoCleanedLast={whoCleanedLast}
                  dusterOptions={dusterOptions}
                  lastDuster={lastDuster}
                  getDusterRating={getDusterRating}
                  dusterRating={dusterRating}
                  dusterId={dusterId}
                  selectDusterForClean={selectDusterForClean}
                  chosenDusterSkills={chosenDusterSkills}
                  removeDusterFromClean={removeDusterFromClean}
                  newUserCreated={newUserCreated}
                  signUpNewUser={signUpNewUser}
                  errors={errors}
                />
              </>
            ) : (
              'stage 4'
            )}
          </Col>
        </Row>
      </div>

      <Bottom />
    </>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
  carpert_cleaning: state.carpert_cleaning,
  user: state.user,
  orders: state.orders,
  note: state.note,
});

export default connect(mapStateToProps, {
  rateCard,
  clearPay,
  regularRate,
  customRate,
  fetchMyRate,
  getMother,
  getAddress,
  theNityGrity,
  changeRate,
  whyChangeHours,
  getAvailableDusters,
  whoCleanedLast,
  getDusterRating,
  chooseDuster,
  removeDuster,
  basketToggle,
  signupUser,
  toggleAddressDialog,
  updateMyAddress,
  toggleCarpetCleaning,
  jamCarpertGetIn,
  jamCarpertCleanLocation,
})(Booking);
