import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { forgotUserPassword } from '../../../actions/user/';
import Bottom from '../../Layout/Bottom';

const ForgotUserPassword = ({ forgotUserPassword, note }) => {
  const [email, setEmail] = useState('');
  const [nte, setMsg] = useState(note);

  let btn = useRef();

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const onSubmit = async (e) => {
    e.preventDefault();

    forgotUserPassword({ email });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <div className="container">
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>
              <h4>Password Reset Request</h4>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={email}
              placeholder="Please enter your email here to reset your password"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="outline-warning btn btn-block"
            type="submit"
            ref={btn}
          >
            Send Passsword Reset Request{' '}
            <i className="fas fa-envelope-o ml-2"></i>
          </Button>
        </Form>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { forgotUserPassword })(
  ForgotUserPassword
);
