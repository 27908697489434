import React from 'react';
import { Dropdown } from 'react-bootstrap';

const AddressItem = ({ a, ChangeAddress }) => {
  let address = a.text.split(',');
  return (
    <Dropdown.Item onClick={() => ChangeAddress(a)}>
      {address[0]} {address[1]} {a.complex && `| ${a.complex}`}
    </Dropdown.Item>
  );
};

export default AddressItem;
