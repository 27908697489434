import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Alert, Spinner, ListGroup, Button } from 'react-bootstrap';
import {
  closedQueries,
  closedQueriesNum,
} from '../../../actions/admin/admin_queries';

const CompleteQueries = ({
  admin_queries: { closed, closedNumber },
  closedQueries,
  closedQueriesNum,
}) => {
  useEffect(() => {
    closedQueries();
    closedQueriesNum();
  }, [closedQueries, closedQueriesNum]);

  if ((closed === null) | (closedNumber === null))
    return <Spinner variant="primary" animation="grow" />;
  return (
    <Container>
      <Alert variant="success text-center">
        <Button
          disabled
          variant="warning float-left"
          size="sm"
          as={Link}
          to="/admin/all-queries"
        >
          Active Queries
        </Button>
        Closed queries{' '}
        <Button disabled variant="info float-right" size="sm">
          {closedNumber}
        </Button>
      </Alert>
      <ListGroup variant="flush text-center">
        {closed.length === 0 ? (
          <Alert>There are no closed queries</Alert>
        ) : (
          closed.map((query) => (
            <ListGroup.Item key={query._id}>
              Query Headline: {query.headline}
              <Button
                variant="outline-warning float-right"
                to={`/admin/order-query/${query._id}`}
                as={Link}
                size="sm"
              >
                View Order
              </Button>
            </ListGroup.Item>
          ))
        )}
      </ListGroup>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_queries: state.admin_queries,
});

export default connect(mapStateToProps, { closedQueries, closedQueriesNum })(
  CompleteQueries
);
