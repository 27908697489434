import React from 'react';
import Appointment from './Appointment';

const Recap = ({ schedule }) => {
  return schedule.map((sched) => (
    <Appointment key={sched.start} sched={sched} />
  ));
};

export default Recap;
