import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { orderInvoice, getInvoice } from '../../../actions/user/invoice';
import {
  Container,
  Row,
  Col,
  Spinner,
  ListGroup,
  ListGroupItem,
  Alert,
  Image,
  Button,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import DustingLogo from '../../../images/DustingLogo.png';
import moment from 'moment';

const Invoice = ({ orderInvoice, order: { order, invoice }, getInvoice }) => {
  const [billTo, setBillTo] = useState(null);
  const { orderId } = useParams();
  const [repeat, setRepeat] = useState(null);
  const [extras, setExtras] = useState(null);
  const [cleans, setCleans] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [duster, setDuster] = useState(null);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    orderInvoice(orderId);
    getInvoice(orderId);
  }, [orderInvoice, getInvoice, orderId]);

  useEffect(() => {
    if (order) {
      setDuster(order.team);
      setCheckIn(order.checkIn);
      setCheckOut(order.checkOut);
      if (order.user.local) setBillTo(order.user.local.name);
      if (order.user.facebook) setBillTo(order.user.facebook.name);
      if (order.user.google) setBillTo(order.user.google.name);
      if (order.schedule) {
        setRepeat(order.schedule);
        setCleans(order.schedule.length);
      } else {
        setRepeat(null);
      }

      if (order.orderTime) setPaymentDate(order.orderTime);
      if (order.firstDate) setPaymentDate(order.firstDate);
      setExtras(order.mrx);
    }
  }, [order]);

  if (order === null) return <Spinner animation="grow" variant="primary" />;

  const goBack = () => {
    if (repeat) {
      navigate(`/user/my-re-order/${orderId}`);
    } else {
      navigate(`/user/my-order/${orderId}`);
    }
  };

  return (
    <Container>
      <Alert variant="secondary text-center">
        This invoice was created by Dusting Down (Pty) Ltd on behalf of;{' '}
        {duster ? <b>{duster.name}</b> : <b>No Duster assigned</b>}
      </Alert>
      <Row className="mb-4">
        <Col>
          <ListGroup variant="flush">
            <Alert variant="info">
              {repeat ? 'Repeat clean' : 'Once off clean'}{' '}
              <Button
                size="sm"
                variant="secondary float-right"
                onClick={goBack}
              >
                <i className="fa-solid fa-backward-step"></i>
              </Button>
            </Alert>
            <ListGroupItem>Copyright 2022 Dusting Down</ListGroupItem>
            <ListGroupItem>
              {repeat ? ` Repeat clean` : ' Once off clean'}
            </ListGroupItem>
            <ListGroupItem>Dusting Down Pty Ltd</ListGroupItem>
            <ListGroupItem>VAT: 4460287461</ListGroupItem>
            <ListGroupItem>
              Unit 7, 355 Oak Avenue,Ferndale, Randburg
            </ListGroupItem>
            <ListGroupItem>Phone: +27 11 5688077</ListGroupItem>
            <ListGroupItem>Website: www.dustingdown.co.za</ListGroupItem>
            <ListGroupItem>
              <b>Bill to:</b>
            </ListGroupItem>
            <ListGroupItem>{billTo}</ListGroupItem>
            <ListGroupItem>Booking Address: {order.address}</ListGroupItem>
          </ListGroup>
        </Col>
        <Col className="text-right">
          <Image src={DustingLogo} alt="Dusting Loggo" />
          <Alert variant="info mt-3"> Invoice Number: {invoice.invoice}</Alert>
          <Alert variant="info mt-3">
            Invoice Date: <b>{moment(paymentDate).format('MMMM Do YYYY')}</b>
          </Alert>
        </Col>
      </Row>
      <hr />
      <Row bg="blue" className="text-center">
        <Col md={8}>
          <b>Cleaning Description</b>
        </Col>
        <Col>
          <b>Hours</b>
        </Col>
        <Col>
          <b>Amount</b>
        </Col>
      </Row>
      <hr />

      <Row className="text-center">
        <Col md={8}>
          {repeat
            ? ` Repeat clean with ${cleans} cleans and `
            : ' Once-off clean with'}
          {extras && extras.length > 0 ? ' we have extras' : ' no extras'}
          <hr />
          {duster ? <b>{duster.name}</b> : <b>No Duster assigned</b>}
          <hr />
          {repeat ? checkOut.length : checkOut ? 1 : 0} completed cleans
          <hr />
          <b>Cleaner Checked In:</b>
          {repeat ? (
            <ListGroup>
              {checkIn.length > 0
                ? checkIn.map((time) => (
                    <ListGroupItem key={time}>
                      {moment(time).format('dd Do, h:mm a')}
                    </ListGroupItem>
                  ))
                : 'Cleaner has not checkedIn'}
            </ListGroup>
          ) : (
            <>
              <b>
                {checkIn && moment(checkIn).format('MMMM Do YYYY, h:mm:ss a')}
              </b>
            </>
          )}
          <hr />
          <b>Cleaner Checked Out:</b>
          {repeat ? (
            <ListGroup>
              {checkOut.map((time) => (
                <ListGroupItem key={time}>
                  {moment(time).format('dd Do, h:mm a')}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <>
              <b>
                {checkOut && moment(checkOut).format('MMMM Do YYYY, h:mm:ss a')}
              </b>
            </>
          )}
        </Col>
        <Col>{order.invoiceHours}</Col>
        <Col>R {order.invoice}</Col>
      </Row>
      <hr />
      <Row className="mt-3 text-right">
        <Col md={{ span: 4, offset: 8 }}>
          <ListGroupItem>
            <b className="float-left">Total:</b> R {order.invoice}
          </ListGroupItem>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  order: state.invoice,
});

export default connect(mapStateToProps, { orderInvoice, getInvoice })(Invoice);
