import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const WhitelistDuster = ({ dustBuster, moveFromBlack, unsusBtn }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        variant="outline-success ml-2"
        className="float-right"
        size="sm"
        onClick={handleShow}
      >
        <i className="fa-regular fa-flag"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Whitelist {dustBuster.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b className="text-center">Are you sure!</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          <Button variant="success" onClick={moveFromBlack} ref={unsusBtn}>
            Whitelist Duster <i className="fas fa-trash"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WhitelistDuster;
