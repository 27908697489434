import moment from 'moment';
import { connect } from 'react-redux';
import { setDepartment } from '../../../../../actions/admin/main';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';

const EmployeeItem = ({ setDepartment, employee }) => {
  const changeTheDepartment = (department) => {
    let adminId = employee._id;
    setDepartment({ adminId, department });
  };

  return (
    <tr>
      <td>{employee.name}</td>
      <td>{employee.email}</td>
      <td>
        <Dropdown as={ButtonGroup}>
          <Button variant="outline-secondary" size="sm">
            {employee.department && `${employee.department}`}
          </Button>
          <Dropdown.Toggle split variant="outline-secondary"></Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => changeTheDepartment('HR')}>
              HR
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeTheDepartment('Operations')}>
              Operations
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeTheDepartment('Finance')}>
              Finance
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td>{moment(employee.lastLogin).format('ddd MMM D')}</td>
    </tr>
  );
};

export default connect(null, { setDepartment })(EmployeeItem);
