import React from 'react';
import { ListGroup } from 'react-bootstrap';

const AddressItem = ({ userAdr }) => {
  return (
    <>
      <ListGroup.Item>
        {userAdr.text} - {userAdr.addressType} -{' '}
        {userAdr.addressType !== 'House' && `${userAdr.complex}`}
      </ListGroup.Item>
    </>
  );
};

export default AddressItem;
