import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setNote } from '../../../actions/note';
import Calender from './Calender';
import Schedule from './Schedule';
import DaysOfTheWeek from './DaysOfTheWeek';
import { Alert, Button } from 'react-bootstrap';
import moment from 'moment';
import ConfirmOrderSchedule from './ConfirmOrderSchedule';

const Regular = ({
  tomorrow,
  setPrettyDay,
  regular,
  prettyDay,
  setArrivalDay,
  arrivalDay,
  setArriveNow,
  setStep,
  setNote,
}) => {
  const [onceAWeek, setOnceAWeek] = useState(true);
  const [twiceAWeek, setTwiceAWeek] = useState(false);
  const [threeAWeek, setThreeAWeek] = useState(false);
  const [fullTime, setFullTime] = useState(false);
  const [Mon, setMon] = useState(false);
  const [Tue, setTue] = useState(false);
  const [Wed, setWed] = useState(false);
  const [Thu, setThu] = useState(false);
  const [Fri, setFri] = useState(false);
  const [Sat, setSat] = useState(false);
  const [Sun, setSun] = useState(false);
  const [weekdays, setWeekdays] = useState([]);
  const [frequency, setFrequency] = useState(1);
  const [daysLeft, setDaysLeft] = useState(null);
  const [orderSchedule, setOrderSchedule] = useState([]);

  useEffect(() => {
    if (daysLeft) {
      let data = [];
      const weekly = daysLeft.filter((dl, index) => {
        let string = dl.toString().split(' ');
        const match = weekdays.filter((dow, index) => {
          if (string[0] === dow) {
            return dl;
          }
        });
        if (match.length > 0) {
          return dl;
        }
      });
      function createSchedule(weekly, data) {
        weekly.forEach((w) => {
          let title = 'Cleaning';
          let start = moment(w).format();
          let end = moment(w).add(4, 'hours').format();
          let event = { title, start, end };
          data.push(event);
        });
      }

      createSchedule(weekly, data);
      setOrderSchedule(data);
    }
  }, [daysLeft]);

  useEffect(() => {
    if (fullTime) {
      if (Sat) setSat(false);
      if (Sun) setSun(false);
      setMon(true);
      setTue(true);
      setWed(true);
      setThu(true);
      setFri(true);
    }
  }, [fullTime]);

  const once = () => {
    if (twiceAWeek === true) setTwiceAWeek(false);
    if (threeAWeek === true) setThreeAWeek(false);
    if (fullTime === true) setFullTime(false);
    setOnceAWeek(true);
    setFrequency(1);
  };

  const twice = () => {
    if (onceAWeek === true) setOnceAWeek(false);
    if (threeAWeek === true) setThreeAWeek(false);
    if (fullTime === true) setFullTime(false);
    setTwiceAWeek(true);
    setFrequency(2);
  };

  const three = () => {
    if (onceAWeek === true) setOnceAWeek(false);
    if (twiceAWeek === true) setTwiceAWeek(false);
    if (fullTime === true) setFullTime(false);
    setThreeAWeek(true);
    setFrequency(3);
  };
  const full = () => {
    if (onceAWeek === true) setOnceAWeek(false);
    if (twiceAWeek === true) setTwiceAWeek(false);
    if (threeAWeek === true) setThreeAWeek(false);
    setFullTime(true);
    setFrequency(5);
  };

  function validateWeek(validate) {
    if (onceAWeek) {
      if (weekdays.length >= 1) {
        return setNote(
          'If you would like more than one day please change "How Often?" option',
          'warning'
        );
      }
    }
    if (twiceAWeek) {
      if (weekdays.length >= 2) {
        return setNote(
          'If you would like more than 2 days please change "How Often?" option',
          'warning'
        );
      }
    }
    if (threeAWeek) {
      if (weekdays.length >= 3) {
        return setNote(
          'If you would like more than 3 days please choose the Full Time option',
          'warning'
        );
      }
    }

    validate = true;
    return validate;
  }

  const monday = () => {
    let currentdays = weekdays;

    if (Mon) {
      let newDays = currentdays.filter((day) => day !== 'Mon');
      setWeekdays(newDays);
      return setMon(false);
    }

    let validate = false;
    const result = validateWeek(validate);
    if (result) {
      let currentdays = weekdays;
      let match = 'Mon';
      currentdays.push(match);
      setWeekdays(currentdays);
      return setMon(true);
    }
  };
  const tuesday = () => {
    let currentdays = weekdays;

    if (Tue) {
      let newDays = currentdays.filter((day) => day !== 'Tue');
      setWeekdays(newDays);
      return setTue(false);
    }

    let validate = false;
    const result = validateWeek(validate);
    if (result) {
      let currentdays = weekdays;
      let match = 'Tue';
      currentdays.push(match);
      setWeekdays(currentdays);
      return setTue(true);
    }
  };
  const wednesday = () => {
    let currentdays = weekdays;

    if (Wed) {
      let newDays = currentdays.filter((day) => day !== 'Wed');
      setWeekdays(newDays);
      return setWed(false);
    }

    let validate = false;
    const result = validateWeek(validate);
    if (result) {
      let currentdays = weekdays;
      let match = 'Wed';
      currentdays.push(match);
      setWeekdays(currentdays);
      return setWed(true);
    }
  };
  const thursday = () => {
    let currentdays = weekdays;

    if (Thu) {
      let newDays = currentdays.filter((day) => day !== 'Thu');
      setWeekdays(newDays);
      return setThu(false);
    }

    let validate = false;
    const result = validateWeek(validate);
    if (result) {
      let currentdays = weekdays;
      let match = 'Thu';
      currentdays.push(match);
      setWeekdays(currentdays);
      return setThu(true);
    }
  };
  const friday = () => {
    let currentdays = weekdays;

    if (Fri) {
      let newDays = currentdays.filter((day) => day !== 'Fri');
      setWeekdays(newDays);
      return setFri(false);
    }

    let validate = false;
    const result = validateWeek(validate);
    if (result) {
      let currentdays = weekdays;
      let match = 'Fri';
      currentdays.push(match);
      setWeekdays(currentdays);
      return setFri(true);
    }
  };
  const saturday = () => {
    let currentdays = weekdays;

    if (Sat) {
      let newDays = currentdays.filter((day) => day !== 'Sat');
      setWeekdays(newDays);
      return setSat(false);
    }

    let validate = false;
    const result = validateWeek(validate);
    if (result) {
      let currentdays = weekdays;
      let match = 'Sat';
      currentdays.push(match);
      setWeekdays(currentdays);
      return setSat(true);
    }
  };
  const sunday = () => {
    let currentdays = weekdays;

    if (Sun) {
      let newDays = currentdays.filter((day) => day !== 'Sun');
      setWeekdays(newDays);
      return setSun(false);
    }

    let validate = false;
    const result = validateWeek(validate);
    if (result) {
      let currentdays = weekdays;
      let match = 'Sun';
      currentdays.push(match);
      setWeekdays(currentdays);
      return setSun(true);
    }
  };

  const reset = () => {
    setOnceAWeek(true);
    setTwiceAWeek(false);
    setThreeAWeek(false);
    setFullTime(false);
    setMon(false);
    setTue(false);
    setWed(false);
    setThu(false);
    setFri(false);
    setSat(false);
    setSun(false);
    setWeekdays([]);
    setFrequency(1);
    setOrderSchedule([]);
  };

  return (
    <div className="mt-4">
      {orderSchedule.length > 0 ? (
        <>
          <Alert variant="warning mt-4">
            Confirm order schedule
            <Button variant="secondary float-right" size="sm" onClick={reset}>
              Restart
            </Button>
          </Alert>
          <ConfirmOrderSchedule orderSchedule={orderSchedule} />
          <Button variant="outline-info mt-3" onClick={() => setStep(3)} block>
            Next <i className="fa-solid fa-angle-right ml-5"></i>
          </Button>
        </>
      ) : (
        <>
          <h4 className="text-center">
            How often would you like to have your office cleaned?
          </h4>
          <Schedule
            once={once}
            onceAWeek={onceAWeek}
            twice={twice}
            twiceAWeek={twiceAWeek}
            three={three}
            threeAWeek={threeAWeek}
            full={full}
            fullTime={fullTime}
          />
          <DaysOfTheWeek
            monday={monday}
            Mon={Mon}
            tuesday={tuesday}
            Tue={Tue}
            wednesday={wednesday}
            Wed={Wed}
            thursday={thursday}
            Thu={Thu}
            friday={friday}
            Fri={Fri}
            saturday={saturday}
            Sat={Sat}
            sunday={sunday}
            Sun={Sun}
          />
          {frequency === weekdays.length ? (
            <>
              <h4 className="text-center mt-4">
                When would you the cleaning to start
              </h4>
              <Calender
                tomorrow={tomorrow}
                setPrettyDay={setPrettyDay}
                regular={regular}
                prettyDay={prettyDay}
                setArrivalDay={setArrivalDay}
                arrivalDay={arrivalDay}
                setArriveNow={setArriveNow}
                setDaysLeft={setDaysLeft}
              />
            </>
          ) : (
            <Alert variant="dark text-center mt-4">
              Please let us know how often and on which days
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default connect(null, { setNote })(Regular);
