import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Card,
  Form,
  Button,
  Spinner,
  ListGroup,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import {
  newAddress,
  getAddress,
  addComplex,
  removeComplex,
  deleteAddress,
  updateComplex,
} from '../../../actions/user/';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';
import AddressItem from './AddressItem';
import './MyProfile.css';

const Address = ({
  newAddress,
  myAddress,
  getAddress,
  addComplex,
  removeComplex,
  deleteAddress,
  user,
  updateComplex,
}) => {
  const [addForm, setAddForm] = useState(false);

  useEffect(() => {
    getAddress();
  }, [getAddress]);
  const [adrs, setAdrs] = useState([]);
  const [address, setAddress] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [complex, setComplex] = useState(user.complex);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (myAddress) {
      setAdrs(myAddress);
    }
  }, [myAddress]);

  const addAddress = (e) => {
    e.preventDefault();
    let text = address;
    newAddress({ text, coordinates });
    setAddress(null);
    setAddForm(false);
  };
  if (myAddress === null) {
    return <Spinner animation="grow" variant="dark" />;
  }

  let mainAddress = user.address.split(',');

  const handleShow = () => {
    setShow(true);
  };

  const clearComplex = () => {
    let complex = null;
    updateComplex({ complex });
  };

  const handleClose = () => {
    setShow(false);
  };

  const saveComplex = () => {
    updateComplex({ complex });
    setShow(false);
  };

  return (
    <div className="mt-4">
      <Card>
        <Alert variant="light">
          My Addresses!
          {addForm === false ? (
            <Button
              variant="outline-primary"
              className="float-right"
              onClick={() => setAddForm(true)}
            >
              Add address
            </Button>
          ) : (
            <Button
              variant="outline-danger"
              className="float-right"
              onClick={() => setAddForm(false)}
            >
              Cancel
            </Button>
          )}
        </Alert>
        {addForm === false ? (
          <ListGroup className="mb-2" id="my-profile">
            <ListGroup.Item>
              <h5>
                {mainAddress[0]}, {mainAddress[1]}
              </h5>
              <InputGroup>
                <Button variant="dark" disabled>
                  Do you live in complex?
                </Button>
                <InputGroup.Append>
                  <Button
                    variant={complex ? 'info' : 'outline-info'}
                    onClick={handleShow}
                  >
                    Yes
                  </Button>
                  <Button
                    variant={complex ? 'outline-primary' : 'primary'}
                    onClick={clearComplex}
                  >
                    {complex ? 'Clear' : 'No'}
                  </Button>
                  {complex ? (
                    <Button variant="info" className="ml-3">
                      Your Complex details: {complex}
                    </Button>
                  ) : (
                    ''
                  )}
                </InputGroup.Append>
              </InputGroup>
            </ListGroup.Item>
            {adrs.length === 0 ? (
              <ListGroup.Item>You only have a main address</ListGroup.Item>
            ) : (
              adrs.map((adr) => (
                <AddressItem
                  key={adr._id}
                  adr={adr}
                  addComplex={addComplex}
                  removeComplex={removeComplex}
                  deleteAddress={deleteAddress}
                />
              ))
            )}
          </ListGroup>
        ) : (
          <Form
            onSubmit={addAddress}
            style={{ width: '28rem' }}
            id="my-profile"
          >
            <Form.Group>
              <Form.Label>Add Address:</Form.Label>
              <AddressAutocomplete
                value={address}
                setAddress={setAddress}
                setCoordinates={setCoordinates}
              />
            </Form.Group>
            <Button type="submit" className="mb-2" disabled={!address} block>
              Add New Address
            </Button>
          </Form>
        )}
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please enter you complex details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Please enter complex details"
            name="complex"
            value={complex}
            onChange={(e) => setComplex(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Dont save
          </Button>
          <Button variant="primary" onClick={saveComplex}>
            Save Details
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  myAddress: state.user.myAddress,
});

export default connect(mapStateToProps, {
  newAddress,
  getAddress,
  addComplex,
  removeComplex,
  deleteAddress,
  updateComplex,
})(Address);
