import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteReOrder = ({ delBtn, deleteOrder }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="outline-danger float-right ml-2"
        size="sm"
        onClick={handleShow}
      >
        <i className="fas fa-trash-alt"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you to delete this order</Modal.Title>
        </Modal.Header>
        <Modal.Body>You cannot undo this action!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" ref={delBtn} onClick={deleteOrder}>
            Delete Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteReOrder;
