import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  getTheCarpertClean,
  checkPaymentStatus,
} from '../../../actions/user/carpert_cleaning';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import CarpertCleaningReview from '../Booking/Payments/CarpertCleaningReview';
import Bottom from '../../Layout/Bottom';

const MyCarpertClean = ({
  carpert_clean,
  getTheCarpertClean,
  checkPaymentStatus,
}) => {
  const { carpertId } = useParams();
  useEffect(() => {
    let url = window.location.href;
    let urlLength = url.split('/').length;
    if (urlLength <= 6) {
      getTheCarpertClean(carpertId);
    } else {
      let id = url.split('=')[1].split('&').shift();
      checkPaymentStatus({ id, carpertId });
    }
  }, [carpertId, getTheCarpertClean, checkPaymentStatus]);
  let btn = useRef();

  if (carpert_clean === null)
    return (
      <Container>
        <Spinner variant="primary" animation="grow" />
      </Container>
    );

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = carpert_clean.code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  const payForOrder = () => {};
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Alert variant="primary">
              <h5>
                Carpert cleaning summary
                {/* {carpert_clean.code ? (
                <>
                  <Button
                    as={Link}
                    to={`/user/my-order-invoice/${carpertId}`}
                    variant="info float-right"
                    size="sm"
                  >
                    Invoice
                  </Button>
                </>
              ) : (
                ''
              )} */}
              </h5>
            </Alert>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b> Date:</b>{' '}
                {moment(carpert_clean.orderDate).format('MMMM Do YYYY')} @{' '}
                <b>Location:</b>
                {carpert_clean.address}
              </ListGroup.Item>
              {carpert_clean.whatToDo.length < 0
                ? 'Loading your carpert clean'
                : carpert_clean.whatToDo.map((rev) => (
                    <CarpertCleaningReview key={rev.name} rev={rev} />
                  ))}
            </ListGroup>
          </Col>
          <Col>
            {carpert_clean.code ? (
              <Card style={{ width: '20rem' }}>
                <Alert variant="success">Payment Successful</Alert>

                <Card.Img variant="top" src={carpert_clean.code} />

                <Card.Body>
                  <Card.Title className="text-center">
                    Please download the qr code
                  </Card.Title>
                  <Button variant="primary" onClick={downloadCode}>
                    Download!
                  </Button>
                </Card.Body>
              </Card>
            ) : (
              <Card style={{ width: '20rem' }}>
                <Alert variant="danger">Your payment failed!</Alert>
                <Card.Body>
                  <Card.Title className="text-center">
                    <Alert variant="warning">Please try pay again</Alert>
                  </Card.Title>

                  <Button
                    variant="success mt-2"
                    onClick={payForOrder}
                    ref={btn}
                    block
                  >
                    Secure Card Payment R{carpert_clean.invoice}
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <Bottom />
    </>
  );
};
const mapStateToProps = (state) => ({
  carpert_clean: state.carpert_cleaning.carpert_clean,
});
export default connect(mapStateToProps, {
  getTheCarpertClean,
  checkPaymentStatus,
})(MyCarpertClean);
