import React from 'react';
import { Button } from 'react-bootstrap';
import SelectSwapDay from './SelectSwapDay';

const SwapOutCleaner = ({
  swapCleaner,
  setSwapCleaner,
  schedule,
  duster,
  findForADay,
  orderId,
  swapDay,
  setSwapDay,
  findTheBooking,
}) => {
  return (
    <>
      {swapCleaner === true ? (
        <>
          <Button
            variant="warning"
            block
            onClick={() => setSwapCleaner(!swapCleaner)}
          >
            Cancel swap-out
          </Button>
          <h5 className="text-center mt-2">Select a day to swap dusters</h5>
          {schedule.map((sched) => (
            <SelectSwapDay
              key={sched.start}
              sched={sched}
              duster={duster}
              findForADay={findForADay}
              orderId={orderId}
              swapDay={swapDay}
              setSwapDay={setSwapDay}
              findTheBooking={findTheBooking}
            />
          ))}
        </>
      ) : (
        <Button
          variant="outline-secondary"
          block
          onClick={() => setSwapCleaner(!swapCleaner)}
        >
          Swap out cleaner
        </Button>
      )}
    </>
  );
};

export default SwapOutCleaner;
