import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TeamItem from './TeamItem';
import {
  getBlacklist,
  nullDuster,
} from '../../../../actions/admin/team_members';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './AdminDusters.css';

const Blacklist = ({ getBlacklist, blacklist, nullDuster }) => {
  const [searchDuster, setSearchDuster] = useState('');
  useEffect(() => {
    getBlacklist();
    nullDuster(null);
  }, [getBlacklist, nullDuster]);
  const navigate = useNavigate();

  if (blacklist === null) {
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  }

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Row>
        <Col>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>{' '}
          <h4>Blacklisted</h4>
        </Col>
        <Col>
          <span className="float-right mr-5">
            <input
              className="text-center"
              placeholder="search name/email/cell"
              style={{ border: 'none' }}
              onChange={(e) => setSearchDuster(e.target.value)}
            />
          </span>
          <h6>{blacklist.num}: Black listed</h6>
        </Col>
      </Row>
      <Row id="dust-buster">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Cellphone</th>
              <th>Email</th>
              <th>Star Rating</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {blacklist.dusters.length === 0 ? (
              <tr>
                <td>No stage one dusters</td>
              </tr>
            ) : (
              blacklist.dusters
                .filter((val) => {
                  if (searchDuster === '') {
                    return val;
                  } else if (
                    val.email.toLowerCase().includes(searchDuster.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(searchDuster.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.cellphone
                      .toLowerCase()
                      .includes(searchDuster.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((team) => <TeamItem team={team} key={team._id} />)
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  blacklist: state.team_members.blacklist,
});

export default connect(mapStateToProps, { getBlacklist, nullDuster })(
  Blacklist
);
