import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  ListGroup,
  Alert,
  Button,
  Col,
  Spinner,
  Image,
  Container,
} from 'react-bootstrap';
import {
  getRepeatOrder,
  getCleanersForReOrder,
  validateReCheckin,
  acceptReCheckingRequest,
  reScheduleRepeat,
  delReOrder,
  checkOutDuster,
  checkInDuster,
  findForADay,
  setDayCleaners,
  swapForADay,
  swapIn,
  findTheBooking,
} from '../../../actions/admin/orders';
import { setNote } from '../../../actions/note';
import moment from 'moment';
import OrderSchedule from './OrderSchedule';
import ReCleaner from './ReCleaner';
import ReDuster from './ReDuster';
import ReValidate from './ReValidate';
import ReReSchedule from './ReReSchedule';
import ReCalendar from './ReCalendar';
import DeleteReOrder from './DeleteReOrder';
import SwapOutCleaner from './SwapOutCleaner';
import { useNavigate, useParams } from 'react-router-dom';
import SwapDuster from './SwapDuster';
import NewDuster from './NewDuster';

const ReOrderAdmin = ({
  getRepeatOrder,
  getCleanersForReOrder,
  validateReCheckin,
  acceptReCheckingRequest,
  reScheduleRepeat,
  delReOrder,
  checkOutDuster,
  checkInDuster,
  findForADay,
  findTheBooking,
  setDayCleaners,
  swapForADay,
  swapIn,
  admin_orders: {
    re_order,
    cleaners,
    validationDistance,
    swap_cleaners,
    bookedDuster,
  },
  note,
  setNote,
}) => {
  const { orderId } = useParams();
  useEffect(() => {
    getRepeatOrder(orderId);
  }, [getRepeatOrder, orderId]);
  const [nte, setMsg] = useState(note);
  const [customer, SetCustomer] = useState(null);
  const [customerEmail, SetCustomerEmail] = useState(null);
  const [cellphone, SetCellphone] = useState(null);
  const [code, setCode] = useState('');
  const [nityGrity, setNitygrity] = useState(null);
  const [extras, setExtras] = useState(null);
  const [address, SetAddress] = useState(null);
  const [addressType, setAddressType] = useState(null);
  const [complex, SetComplex] = useState(null);
  const [duster, SetDuster] = useState(undefined);
  const [initialDate, SetInitialDate] = useState(null);
  const [areaCleaners, SetAreaCleaners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cleans, SetCleans] = useState('');
  const [weekdays, SetWeekDays] = useState([]);
  const [schedule, SetSchedule] = useState([]);
  const [checkOut, SetCheckOut] = useState([]);
  const [checkIn, SetCheckIn] = useState([]);
  const [validate, setValidate] = useState(null);
  const [completedCleans, setCompletedCleans] = useState(null);
  const [nextClean, setNextClean] = useState(undefined);
  const [nextCheckIn, setNextCheckIn] = useState(undefined);
  const [inProgress, setInprogress] = useState(false);
  const [checkOutCleaner, setCheckOutCleaner] = useState(true);
  const [checkInCleaner, setCheckInCleaner] = useState(true);
  const [changeDay, setChangeDay] = useState(false);
  const [tomorrow, setTomorrow] = useState(null);
  const [prettyDay, setPrettyDay] = useState(null);
  const [toChange, setToChange] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [invoiceHours, setInvoiceHours] = useState(null);
  const [swapCleaner, setSwapCleaner] = useState(false);
  const [swapDay, setSwapDay] = useState(null);
  const [omw, setOMW] = useState(null);
  const navigate = useNavigate();
  let delBtn = useRef();

  useEffect(() => {
    if (note && delBtn.current) {
      setMsg(note);
      delBtn.current.removeAttribute('disabled');
      navigate(-1);
    }
  }, [note, nte, setMsg, navigate]);

  useEffect(() => {
    if (nextCheckIn) {
      let checkInTime = new Date(nextCheckIn.start);
      let rightnow = new Date();
      if (rightnow > checkInTime) {
        setCheckInCleaner(true);
      } else {
        setCheckInCleaner(false);
      }
    }
  }, [nextCheckIn]);

  useEffect(() => {
    if (nextClean) {
      let checkoutTime = new Date(nextClean.end);

      let rightnow = new Date();

      if (rightnow > checkoutTime) {
        setCheckOutCleaner(true);
      } else {
        setCheckOutCleaner(false);
      }
    }
  }, [nextClean]);

  useEffect(() => {
    if (toChange) {
      let start = toChange;
      findTheBooking({ orderId, start });
      findForADay({ orderId, start });
      setChangeDay(true);
    }
  }, [toChange, findForADay, findTheBooking, orderId]);

  useEffect(() => {
    if (swapCleaner === false) {
      setSwapDay(null);
      setDayCleaners(null);
    }
  }, [swapCleaner, setDayCleaners]);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    setTomorrow(date);
  }, [setTomorrow]);

  useEffect(() => {
    if (checkIn.length > checkOut.length) {
      setInprogress(true);
    } else {
      setInprogress(false);
    }
  }, [setInprogress, checkIn.length, checkOut.length]);

  useEffect(() => {
    if (note.length > 0) {
      getRepeatOrder(orderId);
    }
  }, [getRepeatOrder, note, orderId]);

  useEffect(() => {
    if (validate) {
      validateReCheckin(orderId);
    }
  }, [validate, validateReCheckin, orderId]);

  useEffect(() => {
    if (re_order) {
      SetInitialDate(re_order.firstDate);
      SetDuster(re_order.team);
      SetAddress(re_order.address.split(',').shift());
      SetComplex(re_order.complex);
      setExtras(re_order.mrx);
      SetCleans(re_order.cleans);
      SetWeekDays(re_order.weekdays);
      SetSchedule(re_order.schedule);
      SetCheckOut(re_order.checkOut);
      setCompletedCleans(re_order.checkOut.length);
      SetCheckIn(re_order.checkIn);
      setValidate(re_order.officeRequest);
      setInvoiceHours(re_order.invoiceHours);
      setOMW(re_order.onMyWay);
      setCode(re_order.code);
      setNitygrity(re_order.nityGrity);
      SetCustomer(re_order.user.name);
      SetCellphone(re_order.user.cellphone);
      SetCustomerEmail(re_order.user.email);
      if (re_order.address === re_order.user.address) {
        setAddressType(re_order.user.addressType);
      }
      setLoading(false);
    }
    if (!re_order) {
      setLoading(true);
    }
  }, [re_order]);

  useEffect(() => {
    let sortedSchedule = schedule.sort((x, y) => x.start - y.start);
    let checkOutLength = checkOut.length;
    let checkInLength = checkIn.length;
    let nextCheckIn = sortedSchedule[checkInLength];
    let nextDay = sortedSchedule[checkOutLength];
    setNextClean(nextDay);
    setNextCheckIn(nextCheckIn);
  }, [schedule, checkOut.length, setNextClean, checkIn.length]);

  useEffect(() => {
    if (orderId !== null) {
      getCleanersForReOrder(orderId);
    }
  }, [getCleanersForReOrder, orderId]);

  useEffect(() => {
    if (cleaners) {
      SetAreaCleaners(cleaners);
    }
  }, [cleaners]);

  if (re_order === null || loading === true) {
    return (
      <Container>
        <Spinner variant="primary" animation="border" />
      </Container>
    );
  }

  const time = moment(initialDate).format('ddd MMM D, h:mm a');

  const dayToChange = (e) => {
    setToChange(e);
  };
  let changing = moment(toChange).format('ddd MMM D');

  const deleteOrder = () => {
    delReOrder(orderId);
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Row>
        <Col>
          {' '}
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>
          <h4 style={{ color: '#06a5dd' }}>Order management</h4>
        </Col>
        <Col>
          <DeleteReOrder delBtn={delBtn} deleteOrder={deleteOrder} />
        </Col>
      </Row>
      <b>Customer Name:</b> {customer} | <b>Customer Email:</b> {customerEmail}{' '}
      | <b>Cellphone: </b> {cellphone} | <b>Creation Date: </b>{' '}
      {moment(re_order.timestamp).format('MMMM Do YYYY, h:mm')}
      <hr />
      <Row>
        <Col md={5} xs={12}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <b>Order address:</b> {address}{' '}
              {complex && ` | Complex Name: ${complex}`}
            </ListGroup.Item>
            <ListGroup.Item>Address Type: {addressType}</ListGroup.Item>
            <ListGroup.Item>
              {re_order.bedroom} Bedroom/s {re_order.bathroom} Bathroom/s
            </ListGroup.Item>
            <ListGroup.Item>
              <b>First Time:</b> {time}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Weekdays:</b>
              {weekdays && weekdays.map((w) => <p key={w}>{w}</p>)}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Extras :</b>{' '}
              {extras.length === 0
                ? 'No extras'
                : extras.map((extra) => <p key={extra.id}>{extra.name} </p>)}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Created From:</b> {re_order.from}{' '}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Payment Method:</b> {re_order.paymentMethod}
            </ListGroup.Item>
            <ListGroup.Item>
              {cleans} cleans on these dates: <b>{invoiceHours}</b> hr/clean
              <hr style={{ width: '10rem' }} />
              {schedule &&
                schedule.map((s) => <OrderSchedule key={s.start} s={s} />)}
            </ListGroup.Item>
            <ListGroup.Item>
              <b> How to get in:</b> {nityGrity.a}{' '}
              {nityGrity.b && `${nityGrity.b}`}
            </ListGroup.Item>
            {re_order.code === undefined ? (
              <b style={{ color: 'red' }}>Order not paid for!</b>
            ) : (
              <Image src={code} style={{ height: '250px', width: '250px' }} />
            )}
          </ListGroup>
          <ReReSchedule
            schedule={schedule}
            dayToChange={dayToChange}
            toChange={toChange}
            setChangeDay={setChangeDay}
            setToChange={setToChange}
          />
          <SwapOutCleaner
            swapCleaner={swapCleaner}
            setSwapCleaner={setSwapCleaner}
            swapDay={swapDay}
            setSwapDay={setSwapDay}
            schedule={schedule}
            duster={duster}
            findForADay={findForADay}
            orderId={orderId}
            findTheBooking={findTheBooking}
          />
        </Col>
        <Col>
          <h5>Duster Buster details</h5>
          <hr />
          {validate && (
            <ReValidate
              validationDistance={validationDistance}
              acceptReCheckingRequest={acceptReCheckingRequest}
              orderId={orderId}
              note={note}
            />
          )}
          {duster === null && (
            <>
              <h6 style={{ color: 'red', fontWeight: 'bold' }}>
                Booking does not have a Duster
              </h6>
              <hr />
              <b>Available Dusters below: </b>
            </>
          )}

          {duster === null ? (
            areaCleaners.length > 0 ? (
              areaCleaners.map((ac) => (
                <ReCleaner
                  key={ac._id}
                  ac={ac}
                  schedule={schedule}
                  orderId={orderId}
                  setLoading={setLoading}
                />
              ))
            ) : (
              <Alert variant="danger">
                No Dusters Avavailable for this order!
              </Alert>
            )
          ) : (
            <ReDuster
              duster={duster}
              orderId={orderId}
              setLoading={setLoading}
              completedCleans={completedCleans}
              inProgress={inProgress}
              checkOutCleaner={checkOutCleaner}
              checkInCleaner={checkInCleaner}
              nextClean={nextClean}
              nextCheckIn={nextCheckIn}
              checkOutDuster={checkOutDuster}
              checkInDuster={checkInDuster}
              invoiceHours={invoiceHours}
              note={note}
              omw={omw}
            />
          )}

          {changeDay === true && (
            <>
              <h4 className="text-center mt-4">
                Reschedule <b>{changing}</b>
              </h4>
              {bookedDuster === 'No Booking' ? (
                <>
                  <Alert variant="warning">Order Date not have a cleaner</Alert>
                  <Alert variant="info">Cleaners in the area below</Alert>
                  {swap_cleaners === null ? (
                    <Spinner variant="primary" animation="grow" />
                  ) : swap_cleaners.length === 0 ? (
                    'No Cleaners'
                  ) : (
                    swap_cleaners.map((swap) => (
                      <NewDuster
                        key={swap._id}
                        swap={swap}
                        toChange={toChange}
                        orderId={orderId}
                        swapIn={swapIn}
                        findTheBooking={findTheBooking}
                        invoiceHours={invoiceHours}
                        note={note}
                      />
                    ))
                  )}

                  <ReCalendar
                    tomorrow={tomorrow}
                    setPrettyDay={setPrettyDay}
                    setSelectedDay={setSelectedDay}
                    prettyDay={prettyDay}
                    changing={changing}
                    selectedDay={selectedDay}
                    toChange={toChange}
                    reScheduleRepeat={reScheduleRepeat}
                    orderId={orderId}
                    invoiceHours={invoiceHours}
                    note={note}
                    duster={duster}
                    setNote={setNote}
                  />
                </>
              ) : (
                <>
                  {bookedDuster && bookedDuster._id !== duster._id && (
                    <Button variant="warning" size="sm" disabled>
                      Clean assigned to another duster
                    </Button>
                  )}
                  <ReCalendar
                    tomorrow={tomorrow}
                    setPrettyDay={setPrettyDay}
                    setSelectedDay={setSelectedDay}
                    prettyDay={prettyDay}
                    changing={changing}
                    selectedDay={selectedDay}
                    toChange={toChange}
                    reScheduleRepeat={reScheduleRepeat}
                    orderId={orderId}
                    invoiceHours={invoiceHours}
                    note={note}
                    duster={duster}
                    setNote={setNote}
                  />
                </>
              )}
            </>
          )}
          {swapCleaner === true && (
            <>
              <Alert variant="warning mt-4 text-center">
                Please select a day to find available cleaners
              </Alert>
              {swapDay && (
                <h4 className="text-center">
                  Swap Day: {moment(swapDay).format('ddd MMM D, h:mm a')}
                  {bookedDuster && bookedDuster._id === duster._id ? (
                    <Button variant="success float-right" size="sm" disabled>
                      Same Cleaner
                    </Button>
                  ) : (
                    <Button variant="secondary float-right" size="sm" disabled>
                      Clean swapped with{' '}
                      {bookedDuster && `${bookedDuster.name}`}
                    </Button>
                  )}
                </h4>
              )}

              {swap_cleaners === null ? (
                <Spinner variant="primary" animation="grow" />
              ) : swap_cleaners.length === 0 ? (
                'No Cleaners'
              ) : (
                swap_cleaners.map((swap) => (
                  <SwapDuster
                    key={swap._id}
                    swap={swap}
                    swapDay={swapDay}
                    orderId={orderId}
                    swapForADay={swapForADay}
                    findTheBooking={findTheBooking}
                    note={note}
                  />
                ))
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_orders: state.admin_orders,
  note: state.note,
});
export default connect(mapStateToProps, {
  getRepeatOrder,
  getCleanersForReOrder,
  validateReCheckin,
  acceptReCheckingRequest,
  reScheduleRepeat,
  delReOrder,
  checkOutDuster,
  findForADay,
  findTheBooking,
  setDayCleaners,
  swapForADay,
  swapIn,
  setNote,
  checkInDuster,
})(ReOrderAdmin);
