import {
  FILE_UPLOAD_ERROR,
  ID_UPLOAD,
  WP_UPLOAD,
  BL_UPLOAD,
  PP_UPLOAD,
  PA_UPLOAD,
  CLEAR_FILE,
} from '../actions/types';

const initialState = {
  id_uploaded: null,
  wp_uploaded: null,
  bl_uploaded: null,
  pp_uploaded: null,
  pa_uploaded: null,
  loading: false,
  error: null,
};

function uploadReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ID_UPLOAD:
      return {
        ...state,
        id_uploaded: payload,
        loading: false,
      };
    case PP_UPLOAD:
      return {
        ...state,
        pp_uploaded: payload,
      };
    case PA_UPLOAD:
      return {
        ...state,
        pa_uploaded: payload,
      };
    case WP_UPLOAD:
      return {
        ...state,
        wp_uploaded: payload,
        loading: false,
      };
    case BL_UPLOAD:
      return {
        ...state,
        bl_uploaded: payload,
        loading: false,
      };
    case FILE_UPLOAD_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_FILE:
      return {
        ...state,
        id_uploaded: null,
        wp_uploaded: null,
        bl_uploaded: null,
        pp_uploaded: null,
        loading: false,
      };
    default:
      return state;
  }
}

export default uploadReducer;
