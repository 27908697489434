import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { inviteFriend } from '../../../actions/user';

const InviteAFriend = ({ note, inviteFriend }) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  let btn = useRef();

  useEffect(() => {
    if (note.length > 0 && btn.current) {
      setEmail('');
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const invite = async () => {
    inviteFriend({ email });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <>
      <Button
        variant="warning float-right ml-3 text-white"
        onClick={handleShow}
        size="sm"
      >
        Invite a friend
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">
          Get R100 of your next order, when your friend signs up!
        </Alert>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Friends email address:</Form.Label>
            <Form.Control
              type="email"
              placeholder={email}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              Please enter your friends email address
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={invite} ref={btn}>
            Send invite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { inviteFriend })(InviteAFriend);
