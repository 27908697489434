import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { requestPasswordReset } from '../../../actions/business';
import { Form, Button } from 'react-bootstrap';
import Bottom from '../../Layout/Bottom';

const RequestBusinessReset = ({ requestPasswordReset }) => {
  const [email, setEmail] = useState();
  let btn = useRef();
  const onSubmit = (e) => {
    e.preventDefault();

    requestPasswordReset({ email });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <div>
      <div className="container">
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>
              <h4>Business Password Reset Request</h4>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={email}
              placeholder="Please enter your email here to reset your password"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="outline-warning btn btn-block"
            type="submit"
            ref={btn}
          >
            Send Passsword Reset Request
            <i className="fas fa-envelope-o ml-2"></i>
          </Button>
        </Form>
      </div>
      <Bottom />
    </div>
  );
};

export default connect(null, { requestPasswordReset })(RequestBusinessReset);
