import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import {
  createCoupon,
  createAffiliateCoupon,
  createMarketingCoupon,
} from '../../../../../actions/admin/coupon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../DustBusters/AdminDusters.css';
import moment from 'moment';
import ClientCoupon from './ClientCoupon';
import OneTimeCoupon from './OneTimeCoupon';
import AffiliateCoupon from './AffiliateCoupon';
import MarketingCoupon from './MarketingCoupon';

const CreateCoupon = ({
  createCoupon,
  createAffiliateCoupon,
  createMarketingCoupon,
  note,
}) => {
  const [coupontype, setCouponType] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [expirydate, setExpiryDate] = useState(null);
  const [fourPer, setFourPer] = useState(0);
  const [fivePer, setFivePer] = useState(0);
  const [sixPer, setSixPer] = useState(0);
  const [sevenPer, setSevenPer] = useState(0);
  const [eightPer, setEightPer] = useState(0);
  const [coupon, setCoupon] = useState('');

  let navigate = useNavigate();

  useEffect(() => {
    if (startDate) {
      let today = moment().format('MMM Do YY');
      let selectedDate = moment(startDate).format('MMM Do YY');
      if (today === selectedDate) {
        setExpiryDate(null);
      } else {
        let newDate = moment(startDate).format();
        setExpiryDate(newDate);
      }
    }
  }, [startDate]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {' '}
      <i
        className="fa-solid fa-chevron-left float-left mt-1 mr-2"
        onClick={goBack}
      ></i>{' '}
      <h4>Create coupon</h4>
      <hr />
      <Row>
        <Col>
          <Button
            variant={
              coupontype === 'Client Coupon' ? 'success' : 'outline-secondary'
            }
            onClick={() => setCouponType('Client Coupon')}
          >
            Client Coupon
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              coupontype === 'One Time' ? 'success' : 'outline-secondary'
            }
            onClick={() => setCouponType('One Time')}
          >
            One Time Coupon
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              coupontype === 'Affiliate' ? 'success' : 'outline-secondary'
            }
            onClick={() => setCouponType('Affiliate')}
          >
            Affiliate Coupon
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              coupontype === 'Marketing' ? 'success' : 'outline-secondary'
            }
            onClick={() => setCouponType('Marketing')}
          >
            Marketing Coupon
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={4}>
          <b className="mt-3">Expiry Date: </b>
        </Col>
        <Col xs={4}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          {expirydate && `${moment(expirydate).format('MMMM Do YYYY')}`}
        </Col>
        <Col xs={4}>
          {expirydate ? (
            <>
              <Form.Group>
                <Row>
                  <Col xs={4}>
                    <Form.Label className="mt-2">Coupon Code</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      placeholder="Enter coupon code"
                      name="code"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </>
          ) : (
            'Select a exprity date...'
          )}
        </Col>
      </Row>
      <hr />
      <h5>Enter discount %</h5>
      <Row>
        <Col>4 hrs</Col>
        <Col>5 hrs</Col>
        <Col>6 hrs</Col>
        <Col>7 hrs</Col>
        <Col>8 hrs</Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder=" %"
              name="fourPer"
              value={fourPer}
              onChange={(e) => setFourPer(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder=" %"
              name="fivePer"
              value={fivePer}
              onChange={(e) => setFivePer(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder=" %"
              name="sixPer"
              value={sixPer}
              onChange={(e) => setSixPer(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder=" %"
              name="sevenPer"
              value={sevenPer}
              onChange={(e) => setSevenPer(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder=" %"
              name="eightPer"
              value={eightPer}
              onChange={(e) => setEightPer(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      {coupontype === 'Client Coupon' ? (
        <ClientCoupon
          coupon={coupon}
          fourPer={fourPer}
          fivePer={fivePer}
          sixPer={sixPer}
          sevenPer={sevenPer}
          eightPer={eightPer}
          expirydate={expirydate}
          createCoupon={createCoupon}
          coupontype={coupontype}
          note={note}
        />
      ) : coupontype === 'One Time' ? (
        <OneTimeCoupon />
      ) : coupontype === 'Affiliate' ? (
        <AffiliateCoupon
          coupon={coupon}
          fourPer={fourPer}
          fivePer={fivePer}
          sixPer={sixPer}
          sevenPer={sevenPer}
          eightPer={eightPer}
          expirydate={expirydate}
          createAffiliateCoupon={createAffiliateCoupon}
          coupontype={coupontype}
          note={note}
        />
      ) : coupontype === 'Marketing' ? (
        <MarketingCoupon
          coupon={coupon}
          fourPer={fourPer}
          fivePer={fivePer}
          sixPer={sixPer}
          sevenPer={sevenPer}
          eightPer={eightPer}
          expirydate={expirydate}
          createMarketingCoupon={createMarketingCoupon}
          coupontype={coupontype}
          note={note}
        />
      ) : (
        'Select coupon type'
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, {
  createCoupon,
  createAffiliateCoupon,
  createMarketingCoupon,
})(CreateCoupon);
