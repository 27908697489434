import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setNote } from '../../../actions/note';
import { Link } from 'react-router-dom';
import { Form, Button, Col } from 'react-bootstrap';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';

const YourInfo = ({ stepOne, setNote, stage, note }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [msg, setMsg] = useState(note);
  const [agree, setAgree] = useState(false);
  const [legal, setLegal] = useState(false);
  const [local, setLocal] = useState(null);
  const [ic, setIC] = useState(false);
  const [criminal, setCriminal] = useState(false);
  const [female, setFemale] = useState(false);
  const [male, setMale] = useState(null);

  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    cellphone: '',
    idNum: '',
    email: '',
  });

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  let btn = useRef();
  const { name, surname, cellphone, idNum, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(cellphone);
    console.log(cellphone.length);

    if (cellphone.length !== 12) {
      return setNote(
        'Please enter a valid South African cellphone number',
        'warning'
      );
    }

    if (male === null && female === null) {
      return setNote('Please tell us your gender', 'warning');
    }
    if (ic === false) {
      return setNote(
        'You have to understand that you are an independant contractor',
        'warning'
      );
    }
    if (local === null) {
      return setNote('Where do you come from?', 'warning');
    }
    if (criminal === false) {
      return setNote('Eish Askies, You cannot work for us', 'warning');
    } else if (legal === false) {
      return setNote('Eish Askies, You cannot work for us', 'warning');
    } else if (agree === false) {
      return setNote('Please accept our terms of services', 'warning');
    }

    stepOne({
      name,
      surname,
      cellphone,
      local,
      idNum,
      email,
      female,
      address,
      stage,
      coordinates,
    });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  const SA = () => {
    setLocal(true);
  };

  const Foreign = () => {
    setLocal(false);
  };

  const iAmMale = () => {
    if (female) {
      setFemale(false);
      return setMale(true);
    }
    setMale(true);
  };

  const iAmFemale = () => {
    if (male) {
      setMale(false);
      return setFemale(true);
    }
    setFemale(true);
  };

  return (
    <div>
      <h5 className="text-center">1. What is your info</h5>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Your Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter your name as it appears on your identification
            documents
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Surname:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Your Name"
            name="surname"
            value={surname}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter your surname as it appears on your identification
            documents
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Street Address:</Form.Label>
          <AddressAutocomplete
            value={address}
            setAddress={setAddress}
            setCoordinates={setCoordinates}
          />
          <Form.Text className="text-muted">
            <b>Please enter your South African home address</b>
          </Form.Text>
        </Form.Group>
        <div className="text-center">
          <h5>Are you South African?</h5>
          <div className="mb-3">
            <Button
              variant={
                local === true ? 'primary mr-4' : 'outline-secondary mr-4'
              }
              onClick={() => SA()}
            >
              Local (South African)
            </Button>
            <Button
              variant={local === false ? 'info' : 'outline-secondary'}
              onClick={() => Foreign()}
            >
              Foreign National
            </Button>
          </div>
          <h5>Gender</h5>

          <div className="mb-3">
            <Button
              variant={male ? 'primary mr-4' : 'outline-secondary mr-4'}
              onClick={() => iAmMale()}
            >
              Male
            </Button>
            <Button
              variant={female ? 'info' : 'outline-secondary'}
              onClick={() => iAmFemale()}
            >
              Female
            </Button>
          </div>
        </div>

        <Form.Group>
          <Form.Label>Cellphone: eg; +27719876543</Form.Label>
          <Form.Control
            type="number"
            placeholder="+27719876543"
            name="cellphone"
            value={cellphone}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter your South African cellphone number
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>ID Number:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Please enter your ID number"
            name="idNum"
            value={idNum}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            Please enter your ID as it appears on your identification documents
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Please enter your email address"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
          />
          <Form.Text className="text-muted">
            We will need to confirm you own this address, please enter the
            correct email.
          </Form.Text>
        </Form.Group>
        <Col align="center">
          <h4>Please Confirm</h4>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="You do not have a criminal record"
              checked={criminal}
              value={criminal}
              onChange={(e) => setCriminal(!criminal)}
            />
            <Form.Text className="text-muted">
              You do not have a criminal record
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="You are legally allowed to work in South Africa"
              checked={legal}
              value={legal}
              onChange={(e) => setLegal(!legal)}
            />
            <Form.Text className="text-muted">
              You are legally allowed to work in South Africa
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="You understand that you are an independant contractor"
              checked={ic}
              value={ic}
              onChange={(e) => setIC(!ic)}
            />
            <Form.Text className="text-muted">
              You understand that you are an independant contractor
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="You Agree to our terms & Privacy Policy"
              checked={agree}
              value={agree}
              onChange={(e) => setAgree(!agree)}
            />
            <Form.Text className="text-muted">
              By ticking this box you agree to our
              <Link to="/team/agreement"> terms of service</Link>
            </Form.Text>
          </Form.Group>
        </Col>
        <Button variant="primary btn-block" type="submit" ref={btn}>
          Next Step <i className="fas fa-sign-in-alt ml-3"></i>
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, { setNote })(YourInfo);
