import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { deleteCoupon } from '../../../../../actions/admin/coupon';
import { ListGroup, Button, Modal, Alert, Col, Row } from 'react-bootstrap';
import moment from 'moment';

const CouponItem = ({ coupon, deleteCoupon, note }) => {
  const [show, setShow] = useState(false);
  const [expired, setExpired] = useState('');
  let btn = useRef();
  useEffect(() => {
    if (coupon) {
      if (coupon.expirydate) {
        setExpired(moment(coupon.expirydate).format('ll'));
      } else {
        const timestamp = coupon.timestamp;
        const validity = coupon.validity;
        const expiryDate = moment(timestamp)
          .add(validity, 'weeks')
          .format('ll');
        setExpired(expiryDate);
      }
    }
  }, [coupon, setExpired]);
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = coupon.user;

  const sayGoodbye = () => {
    const couponId = coupon._id;
    deleteCoupon(couponId);

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <>
      <tr>
        <td>{coupon.coupon}</td>
        <td>{moment(coupon.timestamp).format('ll')}</td>
        <td>{user ? `${user.name}` : 'No client'}</td>
        <td>{expired}</td>
        <td>
          <Button variant="outline-danger" onClick={handleShow}>
            <i className="fa-solid fa-trash-can"></i>
          </Button>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">Delete coupon</Alert>

        <Modal.Body>Coupon Code: ${coupon.coupon}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </Button>
          <Button variant="danger" size="sm" onClick={sayGoodbye} ref={btn}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { deleteCoupon })(CouponItem);
