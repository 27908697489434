//Animation//
export const HOW_WE_DO = 'HOW_WE_DO';
export const CONTACT = 'CONTACT';
export const CLEAR_ANIMATION = 'CLEAR_ANIMATION';

//notifications//
export const SET_NOTE = 'SET_NOTE';
export const REMOVE_NOTE = 'REMOVE_NOTE';

//team actions//
export const MY_INT = 'MY_INT';
export const APPLICATION = 'APPLICATION';
export const TEAM_LOADED = 'TEAM_LOADED';
export const TEAM_REGISTERED = 'TEAM_REGISTERED';
export const TEAM_LOGIN = 'TEAM_LOGIN';
export const TEAM_FAIL = 'TEAM_FAIL';
export const TEAM_LOGOUT = 'TEAM_LOGOUT';
export const CLEAR_TEAM = 'CLEAR_TEAM';
export const AVAILABLE_WORK = 'AVAILABLE_WORK';
export const AVAILABLE_RE_WORK = 'AVAILABLE_RE_WORK';
export const MY_SCHEDULE = 'MY_SCHEDULE';
export const CLEAR_WORK = 'CLEAR_WORK';
export const MY_BOOKING = 'MY_BOOKING';
export const MY_RE_BOOKING = 'MY_RE_BOOKING';
export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const CLEAR_REBOOKING = 'CLEAR_REBOOKING';
export const SET_DISTANCE = 'SET_DISTANCE';
export const MY_WORK = 'MY_WORK';
export const MY_RE_WORK = 'MY_RE_WORK';
export const HR_APP = 'HR_APP';
export const INTERVIEW = 'INTERVIEW';
export const CLEAR_APP = 'CLEAR_APP';

//user actions//
export const USER_LOADED = 'USER_LOADED';
export const CLEAR_USER = ' CLEAR_USER';
export const USER_REGISTERED = 'USER_REGISTERED';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_FAIL = 'USER_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const MY_COUPONS = 'MY_COUPONS';
export const CLEAR_COUPONS = 'CLEAR_COUPONS';
export const CLEAR_COUPON = 'CLEAR_COUPON';
export const COUPON = 'COUPON';
export const COUPON_TOTAL = 'COUPON_TOTAL';
export const MY_ADDRESSES = 'MY_ADDRESSES';
export const THIS_ADR = 'THIS_ADR';
export const CLEAR_ADDRESSES = 'CLEAR_ADDRESSES';
export const MY_RATE_CARD = 'MY_RATE_CARD';
export const MOTHER_CARD = 'MOTHER_CARD';
export const MY_ORDER_INV = 'MY_ORDER_INV';
export const MY_INVOICE = 'MY_INVOICE';

//Booking Actions//
export const FROM_THE_OFFICE = 'FROM_THE_OFFICE';
export const ORDER_ADDRESS = 'ORDER_ADDRESS';
export const ORDER_COORDINATES = 'ORDER_COORDINATES';
export const ORDER_COMPLEX = 'ORDER_COMPLEX';
export const ORDER_ADDRESS_TYPE = 'ORDER_ADDRESS_TYPE';
export const TOGGLE_ADDRESS_DIALOG = 'TOGGLE_ADDRESS_DIALOG';
export const CLEAR_PAY = 'CLEAR_PAY';
export const DUSTERS_NEAR_ME = 'DUSTERS_NEAR_ME';
export const HANDLE_PAYMENT = 'HANDLE_PAYMENT';
export const HANDLE_PEACH = 'HANDLE_PEACH';
export const CLEAR_HANDLE = 'CLEAR_HANDLE';
export const SET_DATE = 'SET_DATE';
export const BEDTIME = 'BEDTIME';
export const BEDROOM = 'BEDROOM';
export const BATHTIME = 'BATHTIME';
export const BATHROOM = 'BATHROOM';
export const BBHOURS = 'BBHOURS';
export const BBTOTAL = 'BBTOTAL';
export const ADD_EXTRA = 'ADD_EXTRA';
export const REMOVE_EXTRA = 'REMOVE_EXTRA';
export const ADD_ICON_EXTRA = 'ADD_ICON_EXTRA';
export const REMOVE_ICON_EXTRA = 'REMOVE_ICON_EXTRA';
export const SELCET_IRONING = 'SELCET_IRONING';
export const SELECT_BEDDING = 'SELECT_BEDDING';
export const SELECT_CUBBOARDS = 'SELECT_CUBBOARDS';
export const SELECT_OVEN = 'SELECT_OVEN';
export const SELECT_WINDOWS = 'SELECT_WINDOWS';
export const SELECT_FRIDGE = 'SELECT_FRIDGE';
export const BASKET_KGS = 'BASKET_KGS';
export const EXHOURS = 'EXHOURS';
export const EXTOTAL = 'EXTOTAL';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const WEEKDAYS = 'WEEKDAYS';
export const OVER_NINE = 'OVER_NINE';
export const OVER_ALLHOURS = 'OVER_ALLHOURS';
export const DEFAULT_FREQUENCY = 'DEFAULT_FREQUENCY';
export const INV_TOTAL = 'INV_TOTAL';
export const REG_INV_TOTAL = 'REG_INV_TOTAL';
export const INITIAL_DATE = 'INITIAL_DATE';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';
export const REGULAR = 'REGULAR';
export const NITTY_GRITTY = 'NITTY_GRITTY';
export const CHANGE_HOURS = 'CHANGE_HOURS';
export const WHY_LESS = 'WHY_LESS';
export const BIG_RESET = 'BIG_RESET';
export const WHO_CLEAN_LAST = 'WHO_CLEAN_LAST';
export const DUSTER_OPTIONS = 'DUSTER_OPTIONS';
export const DUSTER_OPTION_RATING = 'DUSTER_OPTION_RATING';
export const DUSTER_CHOSEN = 'DUSTER_CHOSEN';
export const CHOSEN_DUSTER_SKILLS = 'CHOSEN_DUSTER_SKILLS';
export const ADD_BOOKING_TIP = 'ADD_BOOKING_TIP';

//Office TYPES//
export const OFFICE_TYPE = 'OFFICE_TYPE';
export const STAIRWAYS = 'STAIRWAYS';
export const OFFICE_FLOORS = 'OFFICE_FLOORS';
export const UPDATE_FLOOR = 'UPDATE_FLOOR';
export const TOTAL_SQM = 'TOTAL_SQM';
export const LADIES_TOILETS = 'LADIES_TOILETS';
export const GENTS_TOILETS = 'GENTS_TOILETS';
export const REMOVE_FLOOR = 'REMOVE_FLOOR';
export const COUPON_ERROR = 'COUPON_ERROR';
export const REMOVE_COUPON_ERROR = 'REMOVE_COUPON_ERROR';
export const COUPON_OPTIONS = 'COUPON_OPTIONS';

//Order Actions//
export const GET_ORDER = 'GET_ORDER';
export const ASSIGNED_DUSTER = 'ASSIGNED_DUSTER';
export const GET_RE_ORDER = 'GET_RE_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const ALL_ORDERS = 'ALL_ORDERS';
export const ALL_RE_ORDERS = 'ALL_RE_ORDERS';
export const GET_RE_ORDERS = 'GET_RE_ORDERS';
export const PAID_ORDERS = 'PAID_ORDERS';
export const UNPAID_ORDERS = 'UNPAID_ORDERS';
export const UNPAID_REORDERS = 'UNPAID_REORDERS';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const NO_CLEANER = 'NO_CLEANER';
export const CLEANER_ALLOCATED = 'CLEANER_ALLOCATED';
export const RECLEANER_ALLOCATED = 'RECLEANER_ALLOCATED';
export const NO_RECLEANER = 'NO_RECLEANER';
export const COMPLETED_ORDERS = 'COMPLETED_ORDERS';
export const COMPLETED_REORDERS = 'COMPLETED_REORDERS';
export const ACTIVE_ORDERS = 'ACTIVE_ORDERS';
export const ACTIVE_REORDERS = 'ACTIVE_REORDERS';
export const REPEAT_ORDER = 'REPEAT_ORDER';
export const GET_OZOW_ORDER = 'GET_OZOW_ORDER';
export const GET_PEACH_ORDER = 'GET_PEACH_ORDER';
export const GET_REPEACH_ORDER = 'GET_REPEACH_ORDER';
export const ORDER_COMMENTS = 'ORDER_COMMENTS';
export const RE_ORDER_COMMENTS = 'RE_ORDER_COMMENTS';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';

//Carpert Cleaning Actions//
export const SWITCH_TO_CARPERT_CLEANING = 'SWITCH_TO_CARPERT_CLEANING';
export const CARPERT_CLEANING_INVOICE = 'CARPERT_CLEANING_INVOICE';
export const JAM_CARPERT_ADDRESS = 'JAM_CARPERT_ADDRESS';
export const JAM_CARPERT_ADDRESS_TYPE = 'JAM_CARPERT_ADDRESS_TYPE';
export const JAM_CARPERT_COMLEX = 'JAM_CARPERT_COMLEX';
export const JAM_CARPERT_COORDINATES = 'JAM_CARPERT_COORDINATES';
export const JAM_MAIN_BEDROOM = 'JAM_MAIN_BEDROOM';
export const JAM_BEDROOM = 'JAM_BEDROOM';
export const JAM_STAIRCASE = 'JAM_STAIRCASE';
export const JAM_LANDING = 'JAM_LANDING';
export const JAM_LONG_PASSAGE = 'JAM_LONG_PASSAGE';
export const JAM_SHORT_PASSAGE = 'JAM_SHORT_PASSAGE';
export const JAM_FAMILY_ROOM = 'JAM_FAMILY_ROOM';
export const JAM_LOUNGE = 'JAM_LOUNGE';
export const JAM_DINING_ROOM = 'JAM_DINING_ROOM';
export const JAM_STUDY = 'JAM_STUDY';
export const JAM_DRESSING_ROOM = 'JAM_DRESSING_ROOM';
export const JAM_OTHER_ROOM = 'JAM_OTHER_ROOM';
export const JAM_SMALL_RUG = 'JAM_SMALL_RUG';
export const JAM_LARGE_RUG = 'JAM_LARGE_RUG';
export const JAM_MEDIUM_RUG = 'JAM_MEDIUM_RUG';
export const JAM_TUB_CHAIR = 'JAM_TUB_CHAIR';
export const JAM_WINGBACK = 'JAM_WINGBACK';
export const JAM_ONESEATER_SOFA = 'JAM_ONESEATER_SOFA';
export const JAM_TWOSEATER_SOFA = 'JAM_TWOSEATER_SOFA';
export const JAM_THREESEATER_SOFA = 'JAM_THREESEATER_SOFA';
export const JAM_THREESEATER_EL = 'JAM_THREESEATER_EL';
export const JAM_FOURSEATER_EL = 'JAM_FOURSEATER_EL';
export const JAM_FIVESEATER_EL = 'JAM_FIVESEATER_EL';
export const JAM_SINGLE_MATTRESS = 'JAM_SINGLE_MATTRESS';
export const JAM_DOUBLE_MATTRESS = 'JAM_DOUBLE_MATTRESS';
export const JAM_QUEEN_MATTRESS = 'JAM_QUEEN_MATTRESS';
export const JAM_KING_MATTRESS = 'JAM_KING_MATTRESS';
export const JAM_SMALL_OTTOMAN = 'JAM_SMALL_OTTOMAN';
export const JAM_MEDIUM_OTTOMAN = 'JAM_MEDIUM_OTTOMAN';
export const JAM_LARGE_OTTOMAN = 'JAM_LARGE_OTTOMAN';
export const JAM_CHAIRS_BASE_ONLY = 'JAM_CHAIRS_BASE_ONLY';
export const JAM_CHAIRS_BASE_BACK_ARMS = 'JAM_CHAIRS_BASE_BACK_ARMS';
export const JAM_CARPERT_SHIFT = 'JAM_CARPERT_SHIFT';
export const JAM_CARPERT_CLEANING_DATE = 'JAM_CARPERT_CLEANING_DATE';
export const JAM_CARPERT_GET_IN = 'JAM_CARPERT_GET_IN';
export const GET_MY_CARPERT_CLEANS = 'GET_MY_CARPERT_CLEANS';
export const GET_CARPERT_CLEAN = 'GET_CARPERT_CLEAN';
export const CARPET_MOTHER = 'CARPET_MOTHER';

//User query actions//
export const ORDER_QUERIES = 'ORDER_QUERIES';
export const IN_QUERY = 'IN_QUERY';
export const REMOVE_QUERIES = 'REMOVE_QUERIES';
export const IN_QUERY_TEXTS = 'IN_QUERY_TEXTS';
export const USER_QUERY_SOCKET = 'USER_QUERY_SOCKET';
export const REMOVE_SOCKET = 'REMOVE_SOCKET';

//Business Actions//
export const BUSINESS_LOGIN = 'BUSINESS_LOGIN';
export const BUSINESS_LOADED = 'BUSINESS_LOADED';
export const BUSINESS_LOGOUT = 'BUSINESS_LOGOUT';

//Review Actions//
export const DUSTER_REVIEW = 'DUSTER_REVIEW';
export const USER_REVIEW = 'USER_REVIEW';

//Admin login actions//
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_FAIL = 'ADMIN_FAIL';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

//Main Admin Actions//
export const ALL_DUSTERS = 'ALL_DUSTERS';
export const ALL_USERS = 'ALL_USERS';
export const ALL_ORDER_TOTAL = 'ALL_ORDER_TOTAL';
export const THE_MOTHER_CARD = 'THE_MOTHER_CARD';
export const ALL_INVOICES = 'ALL_INVOICES';
export const ORDER_INVOICE = 'ORDER_INVOICE';
export const CLEAR_ORDER_INVOICE = 'CLEAR_ORDER_INVOICE';
export const INVOICE = 'INVOICE';
export const PAYING_DUSTERS = 'PAYING_DUSTERS';
export const PAYSLIP = 'PAYSLIP';
export const CLEAR_PAYSLIP = 'CLEAR_PAYSLIP';
export const TOTAL_HOURS = 'TOTAL_HOURS';
export const TOTAL_REPEAT_HOURS = 'TOTAL_REPEAT_HOURS';
export const MONTH_END_ORDERS = 'MONTH_END_ORDERS';
export const CUSTOM_PAYCYLE = 'CUSTOM_PAYCYLE';
export const RECURRING_ORDER_ = 'RECURRING_ORDER_';
export const THE_RANGE = 'THE_RANGE';
export const CREATE_PAYSLIP = 'CREATE_PAYSLIP';
export const REMOVE_PAYSLIP_ITEM = 'REMOVE_PAYSLIP_ITEM';
export const UPDATE_HOURS = 'UPDATE_HOURS';
export const UPDATE_REHOURS = 'UPDATE_REHOURS';
export const GET_DUSTER_PAYSLIP = 'GET_DUSTER_PAYSLIP';
export const ALL_COUPONS = 'ALL_COUPONS';
export const EMPLOYEES = 'EMPLOYEES';
export const COUPON_NUM = 'COUPON_NUM';
export const ALL_REPEAT_ORDERS = 'ALL_REPEAT_ORDERS';
export const GET_CARPET_MOTHER = 'GET_CARPET_MOTHER';
export const GLOBAL_DUSTER_RATING = 'GLOBAL_DUSTER_RATING';

//Admin user management//
export const SET_USERS = 'SET_USERS';
export const CLEAR_ADMIN_USER = 'CLEAR_ADMIN_USER';
export const SET_USER = 'SET_USER';
export const USER_ORDERS = 'USER_ORDERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const RATE_CARD = 'RATE_CARD';
export const CLEAR_RATE = 'CLEAR_RATE';
export const RECENT_ORDERS = 'RECENT_ORDERS';
export const RECENT_REPEAT_ORDERS = 'RECENT_REPEAT_ORDERS';

//Admin Query Types//
export const ALL_QUERIES = 'ALL_QUERIES';
export const ALL_QUERIES_NUM = 'ALL_QUERIES_NUM';
export const UN_ASSIGNED_QUERIES = 'UN_ASSIGNED_QUERIES';
export const ASSIGNED_QUERIES = 'ASSIGNED_QUERIES';
export const QUERIES_IM_ON = 'QUERIES_IM_ON';
export const ORDER_QUERY = 'ORDER_QUERY';
export const ORDER_QUERY_DUSTER = 'ORDER_QUERY_DUSTER';
export const CLEAR_QUERIES = 'CLEAR_QUERIES';
export const SETUP_QUERY_SOCKET = 'SETUP_QUERY_SOCKET';
export const QUERY_TEXTS = 'QUERY_TEXTS';
export const CLOSED_QUERIES = 'CLOSED_QUERIES';
export const CLOSED_QUERIES_NUM = 'CLOSED_QUERIES_NUM';
export const CLEAR_QUERY_SOCKET = 'CLEAR_QUERY_SOCKET';

//Admin Order Actions//
export const ORDERS_TWO_WEEKS = 'ORDERS_TWO_WEEKS';
export const ORDERS_TWO_WEEKS_NUM = 'ORDERS_TWO_WEEKS_NUM';
export const ALLOCATED_ORDERS = 'ALLOCATED_ORDERS';
export const ALLOCATED_ORDERS_NUM = 'ALLOCATED_ORDERS_NUM';
export const ADMIN_ORDERS = 'ADMIN_ORDERS';
export const ADMIN_REORDERS = 'ADMIN_REORDERS';
export const NOT_BOOKED = 'NOT_BOOKED';
export const NOT_BOOKED_NUM = 'NOT_BOOKED_NUM';
export const RUNNING_LATE_NUM = 'RUNNING_LATE_NUM';
export const RUNNING_LATE = 'RUNNING_LATE';
export const ORDER_IN_PROGRESS_NUM = 'ORDER_IN_PROGRESS_NUM';
export const ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS';
export const TODAYS_ORDERS = 'TODAY_ORDERS';
export const TODAYS_ORDERS_NUM = 'TODAYS_ORDERS_NUM';
export const RE_NOT_BOOKED = 'RE_NOT_BOOKED';
export const RE_NOT_BOOKED_NUM = 'RE_NOT_BOOKED_NUM';
export const RE_IN_PROGRESS = 'RE_IN_PROGRESS';
export const RE_IN_PROGRESS_NUM = 'RE_IN_PROGRESS_NUM';
export const REPEAT_TODAY_NUM = 'REPEAT_TODAY_NUM';
export const REPEAT_TODAY = 'REPEAT_TODAY';
export const COMPLETE_ORDERS = 'COMPLETE_ORDERS';
export const COMPLETE_ORDERS_NUM = 'COMPLETE_ORDERS_NUM';
export const ADMIN_ORDER = 'ADMIN_ORDER';
export const AREA_CLEANERS = 'AREA_CLEANERS';
export const CLEAR_ADMIN_ORDER = 'CLEAR_ADMIN_ORDER';
export const MISSED_ORDERS = 'MISSED_ORDERS';
export const MISSED_ORDERS_NUM = 'MISSED_ORDERS_NUM';
export const REQUIRE_VALIDATION = 'REQUIRE_VALIDATION';
export const REQUIRE_VALIDATION_NUM = 'REQUIRE_VALIDATION_NUM';
export const MISSED_RE_ORDER = 'MISSED_RE_ORDER';
export const MISSED_RE_ORDERS_NUM = 'MISSED_RE_ORDERS_NUM';
export const ADMIN_REPEAT_ORDER = 'ADMIN_REPEAT_ORDER';
export const LATE_REORDERS_NUM = 'LATE_REORDERS_NUM';
export const RE_RUNNING_LATE = 'RE_RUNNING_LATE';
export const VALIDATE_DISTANCE = 'VALIDATE_DISTANCE';
export const REPEAT_VALIDATION_NUM = 'REPEAT_VALIDATION_NUM';
export const COMPLETE_RE_ORDERS = 'COMPLETE_RE_ORDER';
export const COMPLETE_RE_ORDERS_NUM = 'COMPLETE_RE_ORDERS_NUM';
export const REPEAT_VALIDATION = 'REPEAT_VALIDATION';
export const TO_COMPLETE = 'TO_COMPLETE';
export const TO_COMPLETE_NUM = 'TO_COMPLETE_NUM';
export const SWAP_CLEANERS = 'SWAP_CLEANERS';
export const CLEAR_SWAP_CLEANERS = 'CLEAR_SWAP_CLEANERS';
export const DUSTER_ON_BOOKING = 'DUSTER_ON_BOOKING';
export const ORDERS_THIS_MONTH = 'ORDERS_THIS_MONTH';
export const REORDERS_THIS_MONTH = 'REORDERS_THIS_MONTH';

//Booking for client actions//
export const SET_MOTHER_RATE = 'SET_MOTHER_RATE';
export const SET_ORDER_ADDRESS = 'SET_ORDER_ADDRESS';
export const SET_ORDER_COORDINATES = 'SET_ORDER_COORDINATES';
export const SET_ORDER_COMPLEX = 'SET_ORDER_COMPLEX';
export const SET_ORDER_ADDRESSTYPE = 'SET_ORDER_ADDRESSTYPE';
export const SET_ORDER_BED = 'SET_ORDER_BED';
export const SET_ORDER_BATH = 'SET_ORDER_BATH';
export const SET_OVERALL_HOURS = 'SET_OVERALL_HOURS';
export const SET_ORDER_INVOICE = 'SET_ORDER_INVOICE';
export const SET_ORDER_RE_INVOICE = 'SET_ORDER_RE_INVOICE';
export const CHOOSE_IRONING = 'CHOOSE_IRONING';
export const SET_LAUNDREY_BASKET = 'SET_LAUNDREY_BASKET';
export const SET_ORDER_EXTRAS = 'SET_ORDER_EXTRAS';
export const CHOOSE_BEDDING = 'CHOOSE_BEDDING';
export const CHOOSE_KITCHEN = 'CHOOSE_KITCHEN';
export const CHOOSE_OVEN = 'CHOOSE_OVEN';
export const CHOOSE_FRIDGE = 'CHOOSE_FRIDGE';
export const CHOOSE_WINDOWS = 'CHOOSE_WINDOWS';
export const SET_USER_ORDERTIME = 'SET_USER_ORDERTIME';
export const SET_HOW_TO_GETIN = 'SET_HOW_TO_GETIN';
export const SET_ORDER_WEEKDAYS = 'SET_ORDER_WEEKDAYS';
export const SET_ORDER_SCHEDULE = 'SET_ORDER_SCHEDULE';
export const SET_ORDER_FREQUENCY = 'SET_ORDER_FREQUENCY';
export const CLEAR_ORDER_SCHEDULE = 'CLEAR_ORDER_SCHEDULE';
export const TOGGLE_ORDER_TYPE = 'TOGGLE_ORDER_TYPE';
export const USER_CREATED_ORDER = 'USER_CREATED_ORDER';
export const USER_CREATED_REORDER = 'USER_CREATED_REORDER';
export const CLEAR_CREATED_ORDERS = 'CLEAR_CREATED_ORDERS';

//Office Booking For Client//
export const UPDATE_CLIENT_FLOOR = 'UPDATE_CLIENT_FLOOR';
export const CLIENT_FLOORS = 'CLIENT_FLOORS';

//Admin Carpert actions//
export const ADMIN_NULL_CARPERT_CLEANS = 'ADMIN_NULL_CARPERT_CLEANS';
export const ADMIN_GET_CARPERT_CLEANS = 'ADMIN_GET_CARPERT_CLEANS';
export const ADMIN_GET_CARPERT_CLEAN = 'ADMIN_GET_CARPERT_CLEAN';

//Admin team-member actions
export const TEAM_PROFILE = 'TEAM_PROFILE';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const CHECK_PROFILE = 'CHECK_PROFILE';
export const USER_ADDRESS = 'USER_ADDRESS';
export const CLEAR_MEMBER = 'CLEAR_MEMBER';
export const READY_TEAM = 'READY_TEAM';
export const BLACK_LISTED = 'BLACK_LISTED';
export const BLACK_LIST_DATA = 'BLACK_LIST_DATA';
export const READY_TEAM_NUMBER = 'READY_TEAM_NUMBER';
export const FETCH_SKILLS = 'FETCH_SKILLS';
export const CLEAR_SKILLS = 'CLEAR_SKILLS';
export const DUSTER_ORDERS = 'DUSTER_ORDERS';
export const WORKING_NUM = 'WORKING_NUM';
export const STAGE_ONE = 'STAGE_ONE';
export const STAGE_ONE_NUMBER = 'STAGE_ONE_NUMBER';
export const STAGE_TWO_NUMBER = 'STAGE_TWO_NUMBER';
export const STAGE_TWO = 'STAGE_TWO';
export const STAGE_THREE = 'STAGE_THREE';
export const STAGE_THREE_NUMBER = 'STAGE_THREE_NUMBER';
export const DAILY_DUSTERS = 'DAILY_DUSTERS';
export const DAILY_DUSTERS_NUM = 'DAILY_DUSTERS_NUM';
export const DUST_BUSTER = 'DUST_BUSTER';
export const DUSTER_BOOKINGS = 'DUSTER_BOOKINGS';
export const THE_BOOKING = 'THE_BOOKING';
export const CLEAR_THE_BOOKING = 'CLEAR_THE_BOOKING';
export const DUSTER_RE_ORDERS = 'DUSTER_RE_ORDERS';
export const CLEAR_DUSTERS = 'CLEAR_DUSTERS';
export const DUSTER_RATE = 'DUSTER_RATE';
export const DUSTER_REVIEWS = 'DUSTER_REVIEWS';

//Admin team-admin actions
export const DELETE_ID = 'DELETE_ID';
export const DELETE_WP = 'DELETE_WP';
export const DELETE_BL = 'DELETE_BL';
export const DELETE_CL = 'DELETE_CL';
export const INTERVIEW_CALENDER = 'INTERVIEW_CALENDER';

//File Upload//
export const ID_UPLOAD = 'ID_UPLOAD';
export const WP_UPLOAD = 'WP_UPLOAD';
export const BL_UPLOAD = 'BL_UPLOAD';
export const PP_UPLOAD = 'PP_UPLOAD';
export const PA_UPLOAD = 'PA_UPLOAD';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const CLEAR_FILE = 'CLEAR_FILE';

//ADMIN File Upload//
export const ADMIN_ID_UPLOAD = 'ADMIN_ID_UPLOAD';
export const ADMIN_WP_UPLOAD = 'ADMIN_WP_UPLOAD';
export const ADMIN_BL_UPLOAD = 'ADMIN_BL_UPLOAD';
export const ADMIN_POA_UPLOAD = 'ADMIN_POA_UPLOAD';
export const ADMIN_PP_UPLOAD = 'ADMIN_PP_UPLOAD';
export const ADMIN_EC_UPLOAD = 'ADMIN_EC_UPLOAD';
export const ADMIN_FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const ADMIN_CLEAR_FILE = 'ADMIN_CLEAR_FILE';

//Team Profile Actions//
export const MY_TEAM_PROFILE = 'MY_TEAM_PROFILE';
export const ADD_TEAM_PROFILE = 'ADD_TEAM_PROFILE';
export const FIX_TEAM_PROFILE = 'FIX_TEAM_PROFILE';
export const TEAM_PROFILE_ERROR = 'TEAM_PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

//SKILLS ACTIONS
export const ADD_SKILLS = 'ADD_SKILLS';
export const MYSKILLS = 'MYSKILLS';
export const SKILLS_ERROR = 'SKILLS_ERROR';

//Affilate Types//
export const FETCH_AFFILIATES = 'FETCH_AFFILIATES';
export const FETCH_AFFILIATE = 'FETCH_AFFILIATE';
export const SET_AFFILIATE_LOADING = 'SET_AFFILIATE_LOADING';
