import { Button } from 'react-bootstrap';

const ListAffiliates = ({ affiliate, setAflt }) => {
  return (
    <tr>
      <td>{affiliate.name}</td>
      <td>{affiliate.email}</td>
      <td>{affiliate.coupon}</td>
      <td>{affiliate.coupon}</td>
      <td>{affiliate.address}</td>
      <td>
        <Button
          size="sm"
          variant="outline-dark ml-4"
          onClick={() => setAflt(affiliate)}
        >
          Select
        </Button>
      </td>
    </tr>
  );
};

export default ListAffiliates;
