import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Button, Row, Col } from 'react-bootstrap';

const OrderItem = ({ order }) => {
  return (
    <div className="container mt-3">
      <ListGroup>
        <ListGroup.Item variant="info">
          {order.address} {order.complex && `| ${order.complex}`}
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col>
              {order.bedroom} bed/s {order.bathroom} bath/s for R{order.invoice}
            </Col>
            <Col>
              <Link to={`/user/my-order/${order._id}`}>
                <Button variant="outline-info float-right">View</Button>
              </Link>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default OrderItem;
