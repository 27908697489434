import { useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

const AddCustomTip = ({ myOwnTip }) => {
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const saveTip = () => {
    myOwnTip(amount);
  };
  return (
    <>
      <Button variant="outline-info" onClick={handleShow}>
        Custom
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Howe much would you like to tip?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={1}>
              <h4 style={{ marginTop: '5px' }}>R</h4>
            </Col>
            <Col xs={11}>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder="Enter amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  min="0"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveTip}>
            Save tip
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCustomTip;
