import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  numberDusters,
  numberUsers,
  ordersTotal,
  fetchEmployees,
} from '../../../../actions/admin/main';
import { couponsNum } from '../../../../actions/admin/coupon';
import { Row, Col, Card, Spinner, Button, Container } from 'react-bootstrap';

const Main = ({
  main: { allUsers, allDusters, orderTotal, employees },
  coupon_num,
  numberDusters,
  numberUsers,
  ordersTotal,
  fetchEmployees,
  couponsNum,
}) => {
  useEffect(() => {
    numberDusters();
    numberUsers();
    ordersTotal();
    fetchEmployees();
    couponsNum();
  }, [numberUsers, numberDusters, ordersTotal, fetchEmployees, couponsNum]);

  if (allUsers === null || allDusters === null || orderTotal === null) {
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <h4>Management view</h4>
      </Row>
      <hr />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-coins text-success"></i>
              </span>
              <Card.Title>Accounts</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                R<b className="display-4">{orderTotal}</b>
              </Card.Subtitle>
              <Card.Text>
                In total Orders Transactions on Dusting Down
              </Card.Text>
              <Button
                as={Link}
                to="/admin/admin-accounts/"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: '4em' }} className="float-left">
                  <i className="fas fa-hand-sparkles text-primary"></i>
                </span>
                Dust Busters
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">{allDusters}</b>
              </Card.Subtitle>
              <Card.Text>Dust Busters have created accounts</Card.Text>
              <Button
                as={Link}
                to="/admin/admin-dusters"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-user-tag text-info"></i>
              </span>
              <Card.Title>Customers</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">{allUsers}</b>
              </Card.Subtitle>
              <Card.Text>
                Customers have created accounts on Dusting Down
              </Card.Text>
              <Button
                as={Link}
                to="/admin/admin_users/"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-brands fa-teamspeak"></i>
              </span>
              <Card.Title>Employees</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">{employees && `${employees.num}`}</b>
              </Card.Subtitle>
              <Card.Text>Employees on the system...</Card.Text>
              <Button
                as={Link}
                to="/admin/employee-dash"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-gopuram"></i>
              </span>
              <Card.Title>Templates</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">{coupon_num}</b>
              </Card.Subtitle>
              <Card.Text>Templates</Card.Text>
              <Button
                as={Link}
                to="/admin/template-dashboard"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fa-solid fa-ticket"></i>
              </span>
              <Card.Title>Coupons</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">{coupon_num}</b>
              </Card.Subtitle>
              <Card.Text>Coupons created</Card.Text>
              <Button
                as={Link}
                to="/admin/coupon-page"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
  coupon_num: state.admin_coupons.coupon_num,
});

export default connect(mapStateToProps, {
  numberDusters,
  numberUsers,
  ordersTotal,
  fetchEmployees,
  couponsNum,
})(Main);
