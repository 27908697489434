import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import {
  repeatOrdersThisMonth,
  reNotBooked,
  missedReOrders,
  repeatOrdersToday,
  validateReOrders,
  lateRepeatCleaners,
  activeRepeatOrdersNum,
} from '../../../actions/admin/orders';
import { Link } from 'react-router-dom';
import ReOrderItem from './ReOrderItem';

const RepeatBookingDash = ({
  repeatOrdersThisMonth,
  reNotBooked,
  missedReOrders,
  repeatOrdersToday,
  validateReOrders,
  lateRepeatCleaners,
  activeRepeatOrdersNum,
  admin_orders: {
    reOrdersMonth,
    re_orders_notbooked,
    re_orders_missed,
    repeat_validation,
    re_orders_late,
    re_today,
    re_in_progress_num,
  },
}) => {
  useEffect(() => {
    repeatOrdersThisMonth();
    reNotBooked();
    missedReOrders();
    repeatOrdersToday();
    validateReOrders();
    lateRepeatCleaners();
    activeRepeatOrdersNum();
  }, [
    repeatOrdersThisMonth,
    reNotBooked,
    missedReOrders,
    repeatOrdersToday,
    validateReOrders,
    lateRepeatCleaners,
    activeRepeatOrdersNum,
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <h4 style={{ color: '#06a5dd' }}>Manage Repeat Bookings</h4>
        </Col>
        <Col>
          <Button
            as={Link}
            to="/admin/order-management/repeat-orders/not-allocated"
            variant="outline-secondary mr-2"
            size="sm"
          >
            <span className="btn-label">
              {re_orders_notbooked && `${re_orders_notbooked.num}`}
            </span>{' '}
            Unassigned
          </Button>
          <Button
            as={Link}
            to="/admin/order-management/repeat-orders/missed-reorders"
            variant="outline-secondary mr-2"
            size="sm"
          >
            <span className="btn-label">
              {re_orders_missed && `${re_orders_missed.num}`}
            </span>{' '}
            Missed
          </Button>
          <Button
            as={Link}
            to="/admin/order-management/repeat-orders/require-validation"
            variant="outline-secondary mr-2"
            size="sm"
          >
            <span className="btn-label">
              {repeat_validation && `${repeat_validation.num}`}
            </span>{' '}
            Validate
          </Button>
          <Button
            as={Link}
            to="/admin/order-management/repeat-orders/in-progess"
            variant="outline-secondary "
            size="sm"
          >
            <span className="btn-label">
              {re_in_progress_num && `${re_in_progress_num}`}
            </span>{' '}
            Active
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Row>
          <Col>
            <h5 style={{ fontWeight: 'bold' }}>Todays bookings</h5>
          </Col>
          <Col className="float-right">
            {re_today && `${re_today.num}: Booking/s for today`}
            <Button
              as={Link}
              to="/admin/order-management/repeat-orders/today"
              variant="outline-secondary"
              size="sm"
              className="float-right"
            >
              View All
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Client Name</th>
              <th>First checkin</th>
              <th>Address</th>
              <th>Payment Status</th>
              <th>Cleaner assigned</th>
              <th>Booking Status</th>
              <th>Next checkin</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {re_today === null ? (
              <Spinner animation="border" variant="primary" />
            ) : re_today.orders.length === 0 ? (
              'No Orders this month'
            ) : (
              re_today.orders.map((o) => <ReOrderItem key={o._id} o={o} />)
            )}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Row>
          <Col>
            <h5 style={{ fontWeight: 'bold' }}>Running late</h5>
          </Col>
          <Col className="float-right">
            {re_orders_late && `${re_orders_late.num}: Duster/s running late`}
            <Button
              as={Link}
              to="/admin/order-management/repeat-orders/running-late"
              variant="outline-secondary"
              size="sm"
              className="float-right"
            >
              View All
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Client Name</th>
              <th>First checkin</th>
              <th>Address</th>
              <th>Payment Status</th>
              <th>Cleaner assigned</th>
              <th>Booking Status</th>
              <th>Next checkin</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {re_orders_late === null ? (
              <Spinner animation="border" variant="primary" />
            ) : re_orders_late.orders.length === 0 ? (
              'No Orders this month'
            ) : (
              re_orders_late.orders.map((o) => (
                <ReOrderItem key={o._id} o={o} />
              ))
            )}
          </tbody>
        </Table>
      </Row>

      <Row>
        <Row>
          <Col>
            <h5 style={{ fontWeight: 'bold' }}>For the month</h5>
          </Col>
          <Col className="float-right">
            {reOrdersMonth &&
              `${reOrdersMonth.num}: Booking/s placed this month`}
            <Button
              as={Link}
              to="/admin/order-management/repeat-orders/this-month"
              variant="outline-secondary"
              size="sm"
              className="float-right"
            >
              View All
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Client Name</th>
              <th>First checkin</th>
              <th>Address</th>
              <th>Payment Status</th>
              <th>Cleaner assigned</th>
              <th>Booking Status</th>
              <th>Next checkin</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {reOrdersMonth === null ? (
              <Spinner animation="border" variant="primary" />
            ) : reOrdersMonth.orders.length === 0 ? (
              'No Orders this month'
            ) : (
              reOrdersMonth.orders.map((o) => <ReOrderItem key={o._id} o={o} />)
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin_orders: state.admin_orders,
});

export default connect(mapStateToProps, {
  repeatOrdersThisMonth,
  reNotBooked,
  missedReOrders,
  repeatOrdersToday,
  validateReOrders,
  lateRepeatCleaners,
  activeRepeatOrdersNum,
})(RepeatBookingDash);
