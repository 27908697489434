import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  payingDusters,
  customPayRange,
} from '../../../../../actions/admin/main';
import { setNote } from '../../../../../actions/note';
import { Container, Spinner, Col, Row, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListDuster from './ListDuster';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const PayingDusters = ({
  payingDusters,
  main: { paying_dusters, custom_pay_cycle },
  customPayRange,
  setNote,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (custom_pay_cycle) {
      btn.current.removeAttribute('disabled');
    }
  }, [custom_pay_cycle]);

  useEffect(() => {
    payingDusters();
  }, [payingDusters]);

  const navigate = useNavigate();
  let btn = useRef();

  const goBack = () => {
    navigate(-1);
  };

  if (paying_dusters === null)
    return (
      <Container>
        <Spinner animation="grow" variant="primary" />
      </Container>
    );

  const fetchDateRange = () => {
    if (startDate > endDate) {
      return setNote('Start date is after end date', 'warning');
    }
    console.log({ startDate, endDate });
    customPayRange({ startDate, endDate });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <i
            className="fa-solid fa-chevron-left float-left mt-1 mr-2"
            onClick={goBack}
          ></i>
          <h4>Payroll</h4>
        </Col>
        <Col>
          <Row>
            <Col>
              <b className="mr-3">Start Date: </b>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Col>
            <Col>
              <b className="mr-4">End Date: </b>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Col>

            <Col>
              <Button
                variant="outline-secondary mt-4"
                onClick={fetchDateRange}
                size="sm"
                ref={btn}
              >
                <i className="fa-solid fa-check"></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <hr />

      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Cellphone</th>
              <th>Email</th>
              <th>Star Rating</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {paying_dusters.dusters.length === 0 ? (
              <tr>
                <td>No dusters to pay</td>
              </tr>
            ) : (
              paying_dusters.dusters.map((duster) => (
                <ListDuster key={duster._id} duster={duster} />
              ))
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
});
export default connect(mapStateToProps, {
  payingDusters,
  customPayRange,
  setNote,
})(PayingDusters);
