import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';

const CompletedClean = ({ c }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let date = moment(c).format('ddd MMM D');
  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} block>
        Clean completed on: {date}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompletedClean;
