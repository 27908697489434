import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { deleteOldBookings } from '../../../../actions/admin/team_members';
import { Button, Modal } from 'react-bootstrap';

const DeleteOldBooking = ({ deleteOldBookings, note }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let deleteBtn = useRef();

  useEffect(() => {
    if (note.length > 0) {
      handleClose();
    }
  }, [note]);

  const remove = () => {
    deleteOldBookings();
    if (deleteBtn.current) {
      deleteBtn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <>
      <Button
        variant="secondary float-right text-white"
        size="sm"
        onClick={handleShow}
      >
        Old Bookings
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete All Old Bookings.</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning text-white" onClick={remove} ref={deleteBtn}>
            Delete old bookings
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, { deleteOldBookings })(DeleteOldBooking);
