import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import {
  stepOne,
  stepTwo,
  stepThree,
  stepThreeNoDocs,
  hr,
} from '../../../actions/team/';
import Bottom from '../../Layout/Bottom';
import YourInfo from './YourInfo';
import WorkExperience from './WorkExperience';
import BookAppointment from './BookAppointment';
import './SignUp.css';

const SignUp = ({
  note,
  duster: { isTeam, team, app },
  stepOne,
  stepTwo,
  stepThree,
  stepThreeNoDocs,
  hr,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stage, setStage] = useState(1);
  const [dusterId, setDusterId] = useState(null);
  const [local, setLocal] = useState(null);
  useEffect(() => {
    if (team) {
      setStage(team.stage + 1);
      setLocal(team.local);
      setDusterId(team._id);
    }
  }, [team]);
  const [msg, setMsg] = useState(note);

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  let btn = useRef();

  if (stage === 4) {
    return <Navigate to={`/team/complete-application/${dusterId}`} />;
  }

  if (isTeam) {
    return <Navigate to="/team/teamboard" />;
  }

  return (
    <div>
      <div className="container" id="signup">
        <h3 className="text-center">
          Welcome to Dusting Down, We are happy you would like to join our team!
        </h3>
        <Row className="mb-4">
          <Col md={4} xs={12}>
            {stage === 1 ? (
              <>
                <Button variant="outline-info" block>
                  1.Personal Info
                </Button>
                <hr color="#5bc0de" style={{ height: '2px' }} />
              </>
            ) : (
              stage > 1 && (
                <div align="center">
                  <Button variant="success" disabled>
                    <i className="far fa-check-square"></i>
                  </Button>
                  <hr color="#5cb85c" style={{ height: '2px' }} />
                </div>
              )
            )}
          </Col>
          <Col md={4} xs={12}>
            {stage === 2 ? (
              <>
                <Button variant="outline-info" block>
                  2.Work Experience
                </Button>
                <hr color="#5bc0de" style={{ height: '2px' }} />
              </>
            ) : stage < 2 ? (
              <>
                <Button variant="outline-secondary" block>
                  2.Work Experience
                </Button>
                <hr color="#f7f7f7" style={{ height: '2px' }} />
              </>
            ) : (
              stage > 2 && (
                <div align="center">
                  <Button variant="success" disabled>
                    <i className="far fa-check-square"></i>
                  </Button>
                  <hr color="#5cb85c" style={{ height: '2px' }} />
                </div>
              )
            )}
          </Col>
          <Col md={4} xs={12}>
            {stage < 3 ? (
              <>
                <Button variant="outline-secondary" block>
                  3. Book Interview
                </Button>
                <hr color="#f7f7f7" style={{ height: '2px' }} />
              </>
            ) : (
              stage === 3 && (
                <>
                  <Button variant="outline-secondary" block>
                    3. Book Interview
                  </Button>
                  <hr color="#f7f7f7" style={{ height: '2px' }} />
                </>
              )
            )}
          </Col>
        </Row>
        <Alert variant="info text-center">Cleaner Application</Alert>
        {stage === 1 ? (
          <YourInfo stepOne={stepOne} stage={stage} note={note} />
        ) : stage === 2 ? (
          <WorkExperience stepTwo={stepTwo} dusterId={dusterId} note={note} />
        ) : (
          stage === 3 && (
            <BookAppointment
              local={local}
              dusterId={dusterId}
              stepThree={stepThree}
              stepThreeNoDocs={stepThreeNoDocs}
              hr={hr}
              app={app}
            />
          )
        )}
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  duster: state.team,
});

export default connect(mapStateToProps, {
  stepOne,
  stepTwo,
  stepThree,
  stepThreeNoDocs,
  hr,
})(SignUp);
