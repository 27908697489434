import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { myOrders, myReOrders } from '../../../actions/user/order';
import { Alert, Row, Col, Spinner } from 'react-bootstrap';
import OrderItem from './OrderItem';
import ReOrderItem from './ReOrderItem';

const OrderHistory = ({
  myOrders,
  myReOrders,
  orders: { allOrders, allReOrders },
}) => {
  useEffect(() => {
    myOrders();
    myReOrders();
  }, [myOrders, myReOrders]);

  if (allOrders === null || allReOrders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return (
    <div className="container">
      <h4 className="text-center">Order History</h4>
      <Row>
        <Col>
          <Alert variant="secondary text-center">Once Off Orders</Alert>
          {allOrders.length === 0
            ? 'No Orders'
            : allOrders.map((order) => (
                <OrderItem order={order} key={order._id} />
              ))}
        </Col>

        <Col>
          <Alert variant="secondary text-center">Repeat Orders</Alert>
          {allReOrders.length === 0
            ? 'No Orders'
            : allReOrders.map((order) => (
                <ReOrderItem order={order} key={order._id} />
              ))}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
});

export default connect(mapStateToProps, {
  myOrders,
  myReOrders,
})(OrderHistory);
