import React from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';

const StepCounter = ({ step, setStep }) => {
  return (
    <div className="mb-3">
      <Alert variant="info text-center">
        Step {step} of booking a clean for your office
      </Alert>
      <Row>
        <Col>
          <Button
            variant={step === 1 ? 'outline-info' : 'success'}
            onClick={() => setStep(1)}
          >
            Office info
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              step === 2
                ? 'outline-info'
                : step < 2
                ? 'outline-secondary'
                : 'success'
            }
            onClick={() => setStep(2)}
          >
            Date/time
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              step === 3
                ? 'outline-info'
                : step < 3
                ? 'outline-secondary'
                : 'success'
            }
            onClick={() => setStep(3)}
          >
            Activity list
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              step === 4
                ? 'outline-info'
                : step < 4
                ? 'outline-secondary'
                : 'success'
            }
            onClick={() => setStep(4)}
          >
            Equipment
          </Button>
        </Col>
        <Col>
          <Button
            variant={
              step === 5
                ? 'outline-info'
                : step < 5
                ? 'outline-secondary'
                : 'success'
            }
            onClick={() => setStep(5)}
          >
            Confirmation
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default StepCounter;
