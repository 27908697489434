import { useState } from 'react';
import OfficeInfo from './OfficeInfo';
import OfficeActivity from './OfficeActivity';
import OfficeEquipment from './OfficeEquipment';
import { Button, Col, Row } from 'react-bootstrap';

const OfficeBooking = () => {
  const [stage, setStage] = useState(1);
  return (
    <>
      <Row>
        <Col>
          <Button
            variant={stage === 1 ? 'info' : 'outline-secondary'}
            onClick={() => setStage(1)}
            block
          >
            Stage 1
          </Button>
        </Col>
        <Col>
          <Button
            variant={stage === 2 ? 'info' : 'outline-secondary'}
            onClick={() => setStage(2)}
            block
          >
            Stage 2
          </Button>
        </Col>
        <Col>
          <Button
            variant={stage === 3 ? 'info' : 'outline-secondary'}
            onClick={() => setStage(3)}
            block
          >
            Stage 3
          </Button>
        </Col>
      </Row>
      <hr />
      {stage === 1 ? (
        <OfficeInfo setStage={setStage} />
      ) : stage === 2 ? (
        <OfficeActivity setStage={setStage} />
      ) : stage === 3 ? (
        <OfficeEquipment setStage={setStage} />
      ) : (
        ''
      )}
    </>
  );
};

export default OfficeBooking;
